/**
 *09/22/2023->SUDAM B CHAVAN =>TEXTING-1729: Option to Scheudule the message during offhours  
 */
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Typography
} from "@mui/material";
import React from "react";

export const ConfirmationComponent = ((props) => {
    return (
        <Dialog
            open={props.showConfirmationDialog}
            onClose={(event, reason) => {
                if (reason === "clickaway") {
                    return;
                }
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth="md"
        >
            <DialogTitle>Confirmation</DialogTitle>
            <DialogContent>
                <div style={{ display: "flex", padding: "5px" }}>
                    <Typography gutterBottom>{props.confirmationDialogContent}</Typography>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={()=>props.handleConfirmation(true)}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <span>
                            <strong>Schedule</strong>
                        </span>
                    </div>
                </Button>
                <Button onClick={()=>props.handleConfirmation(false)}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <span>
                            <strong>Do Not Send</strong>
                        </span>
                    </div>
                </Button>
            </DialogActions>
        </Dialog>
    );
});
