/**************************
 ** 12-27-2022 => SUDAM => Added loader for InActive Conv Count Api Call
 *12-26-2023 => ONKAR => TEXTING-1935 Templates Approval Flow - UI
                          1.Home page should be shown with 'Pending approval' Templates count badge in home page for ‘Reviewer’ role user on Admin, Texting UI
                          2.'Approve' Button Can be removed in templates list page. 
                          3.'Is active' option is not updated to read only for reviewer role user.
 *12-26-2023 => ONKAR => TEXTING-1935 Templates Approval Flow - UI
                          If Reviewer role user is not part of any team, then Templates badge is not showing on home screen.
 *01-08-2023 => ONKAR =>  TEXTING-1941 Templates page should be shown in Setup menu dropdown instead of Home screen.
 *01-08-2023 => ONKAR =>  TEXTING-1939 Inactive conversations badge count color is changed to orange from yellow.
 */
import React, { useContext, useEffect, useState } from "react";
import { Avatar, Paper, Grid, Tooltip, Badge} from "@mui/material";
import SmsIcon from "@mui/icons-material/Sms";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import { AuthContext } from "../../AuthContext";
import apiConfig from "../../api/apiConfig";
import { useTheme } from "@mui/material/styles";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import { useDispatch, useSelector } from "react-redux";
import {
    notificationSelector
} from "../../storage/slices/notificationSlice";
import {
    conversationsSelector,
} from "../../storage/slices/messageSlice";
import { mapConversation } from "../../extensions/Util";
import DocuSignMessage from "../docuSign/DocuSignMessage";
import { useNavigate } from "react-router-dom";



const DashboardCards = (props) => {
    const { setNotificationFilter, notiFicationFilter, setOpenDrawer, setHeaderNotification, setupCollapse, setSetupCollapse, setCollapseInactive, crmEmbededView, setOpenConversationDrawer, setSelectedMenu } = useContext(AuthContext);
    const theme = useTheme();
    const navigate=useNavigate();
    console.log("props.filterName", props.filterName)
    return (

        <Grid item xs={2} sm={4} md={4}>
            <Tooltip title={props.title}>
                <Paper
                    elevation={4}
                    style={{ borderRadius: "20px" }}
                    onClick={(event) => {

                        if (crmEmbededView == 'false' || !crmEmbededView) {

                            setOpenDrawer(true);
                            if (props.filterName === "Missed Conversations") {
                                if (props.conversationCount > 0) {
                                    setHeaderNotification(true);
                                    setNotificationFilter(!notiFicationFilter);
                                    setSelectedMenu("unassigned")
                                    setOpenConversationDrawer(true);
                                    setSetupCollapse(false)
                                }
                            } else if (props.filterName === "Active Conversations") {
                                if (props.conversationCount > 0) {
                                    setHeaderNotification(false);
                                    setCollapseInactive(false);
                                    setNotificationFilter(!notiFicationFilter);
                                    setSelectedMenu("active")
                                    setOpenConversationDrawer(true);
                                    setSetupCollapse(false)
                                }
                            }
                            else if (props.filterName === "Inactive Conversations") {
                                props.getInActiveConversations();
                                if (props.conversationCount > 0) {
                                    setCollapseInactive(true);
                                    setHeaderNotification(false);
                                    setNotificationFilter(!notiFicationFilter);
                                    setSelectedMenu("inactive")
                                    setOpenConversationDrawer(true);
                                    setSetupCollapse(false)
                                }
                            }else if(props.filterName==="Pending Templates For Approval"){
                                if (props.conversationCount > 0) {
                                    setSetupCollapse(true)
                                    navigate("/templates")
                                }
                            }
                        }
                    }}
                >
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div
                            style={theme.customStyle.dashboardCard}
                        >
                            <div className={props.filterName == "Inactive Conversations" && "orangeColor"}>
                                <Badge
                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    showZero
                                    overlap="circular"
                                    sx={{ "& .MuiBadge-badge": { fontSize: 15, height: 25, minWidth: 25, backgroundColor: props.filterName === "Inactive Conversations" && "orange" }, }}
                                    badgeContent={props.conversationCount}
                                    color={
                                        props.filterName === "Active Conversations" ? "primary" :
                                            props.filterName === "Missed Conversations" ? "error" : "warning"
                                    }
                                >
                                    <Avatar sx={theme.customStyle.dashboardAvatar}>
                                        {props.iconData}
                                    </Avatar>
                                </Badge>
                            </div>
                            <div style={theme.customStyle.dashboardTextCenter}>
                                <span style={theme.customStyle.dashboardContent}>
                                    {props.filterName} 
                                </span>
                            </div>
                        </div>
                        {/*<div style={theme.customStyle.dashboardConvCountDiv}>*/}
                        {/*    <span style={theme.customStyle.dashboardConvCount}>*/}
                        {/*        {props.conversationCount}*/}
                        {/*    </span>*/}
                        {/*</div>*/}
                    </div>
                </Paper>
            </Tooltip>
        </Grid >

    );
};

function HomePage(props) {
    const { conversations } = useSelector(conversationsSelector);
    const { unAssignedNotifList } = useSelector(notificationSelector);
    const { userInfo, inactiveConvCount, setInactiveConvCount, setInactiveConversations, showDocuSignMessage, setShowDocuSignMessage, crmEmbededView, setConvLoading, setOpenConversationDrawer, setSelectedMenu } = useContext(AuthContext);
    const userId = userInfo.userId;
    const [dashboardCounts,setDashboardCounts]=useState({
        templatesForApprovalCount:0,
    })

    useEffect(() => {
        if (userInfo != null && userInfo.userId != null && userInfo.role != null) {

            getDashboardCounts();
        }
    }, [userInfo]);

    const getDashboardCounts = () => {
        if (userInfo != null && userInfo.userId != null && userInfo.role != null) {
            InvokePostServiceCall(apiConfig.GET_CONVERSATIONS_DASHBOARD_SUMMARY, {
                userId: userId,
                role: userInfo.role,
            })
                .then((data) => {
                    console.log(data, "data");
                    setInactiveConvCount(data.data.dashboardCounts.inactiveConversationsCount)
                    setDashboardCounts({templatesForApprovalCount:data.data.dashboardCounts.templatesForApprovalCount})
                    if (data.data.dashboardCounts.inactiveConversationsCount === 0)
                        setInactiveConversations([])

                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const getInActiveConversations = () => {
        setConvLoading(true); // set loading true before calling the api
        let requestBody = {
            teamId: userInfo.teamId,
        };
        InvokePostServiceCall(apiConfig.GET_INACTIVE_CONVERSATIONS, requestBody)
            .then((data) => {
                setConvLoading(false);
                console.log(data, "InActiveConversationCounts");
                let inactiveConversationsArr = [];
                for (var i = 0; i < data?.data?.conversations?.length; i++) {
                    let obj = mapConversation(data?.data?.conversations[i], data?.data?.conversations[i].teamPhoneNumber);
                    inactiveConversationsArr.push(obj);
                }
                setInactiveConversations(inactiveConversationsArr)
                setInactiveConvCount(inactiveConversationsArr.length);
                if (inactiveConversationsArr.length === 0 ) {
                    setSelectedMenu("");
                }
            })
            .catch((error) => {
                setConvLoading(false);
                console.log(error);
            });
    }

    return (
        <>
            {!showDocuSignMessage ? <div style={{ margin: "15px" }} >
                {(!userInfo.teams || userInfo.teams.length == 0 || crmEmbededView ) && userInfo.role !== "Reviewer" ?
                    (
                        <>
                            {crmEmbededView ? <h5 style={{ justifyContent: "center", display: "flex", padding: "10px", color: "rgb(0, 20, 51)" }}>Welcome To Enterprise Texting</h5> : <h7 style={{ justifyContent: "center", display: "flex", padding: "10px", color: "rgb(0, 20, 51)" }}>Welcome To Enterprise Texting</h7>}
                        </>
                    )
                    :
                    <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 2, sm: 8, md: 12 }}

                    >
                        {userInfo.role !== "Reviewer" && <DashboardCards
                            filterName={"Active Conversations"}
                            conversationCount={conversations.length}
                            iconData={<SmsIcon />}
                            title={conversations.length + " Active conversions"}
                        />}
                        {userInfo.role === "Supervisor" || userInfo.role === "Admin" ? (
                            <DashboardCards
                                filterName={"Missed Conversations"}
                                conversationCount={unAssignedNotifList.length}
                                title={unAssignedNotifList.length + " Missed conversions"}
                                iconData={<AssignmentIndIcon
                                />}
                            />
                        ) : null}
                        {userInfo.role === "Supervisor" || userInfo.role === "Admin" ? (
                            <DashboardCards
                                filterName={"Inactive Conversations"}
                                conversationCount={inactiveConvCount}
                                title={inactiveConvCount + " Inactive conversions"}
                                getInActiveConversations={getInActiveConversations}
                                iconData={<PendingOutlinedIcon
                                />}
                            />
                        ) : null}
                    </Grid>
                }
            {userInfo.role === "Reviewer" ? (
            <div style={{ margin: "15px" }} >
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 2, sm: 8, md: 12 }}
            >

                <DashboardCards
                  filterName={"Pending Templates For Approval"}
                  conversationCount={dashboardCounts.templatesForApprovalCount}
                  title={
                    dashboardCounts.templatesForApprovalCount +
                    " Pending Templates For Approval"
                  }
                  iconData={<PendingOutlinedIcon />}
                />
            </Grid>
            </div>
             ) : null}
            </div> :
                <>
                    <DocuSignMessage />
                </>}
        </>
    );
}
export default HomePage;