/*
11/23/2022=>Texting-850 loading added 
*/


import React from "react";
import { Avatar, CircularProgress, Divider, ListItem, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ChatIcon from '@mui/icons-material/Chat';
import TaskAltTwoToneIcon from '@mui/icons-material/TaskAltTwoTone';
export const ConversationListComp = (props) => {
  const theme = useTheme();
  return (
    <>
      <Divider style={{ backgroundColor: "white" }} />
      {
        props && props.convLoading ?
          ""
          :
          props.conversationList.length > 0 ? (
            props.conversationList.map((conv) => {
              return (
                <>
                  {conv.customerName.toLowerCase().includes(props.convFilterValue.toLowerCase()) && <> <ListItem
                    className={
                      props.selectedConv != null &&
                        conv.conversationId.toLowerCase() ===
                        props.selectedConv.conversationId.toLowerCase()
                        ? "conversationitem"
                        : ""
                    }
                    button
                    key={conv.conversationId}
                    sx={{
                      paddingLeft: "12px",
                    }}
                    selected={
                      props.selectedConv != null
                        ? conv.conversationId.toLowerCase() ===
                        props.selectedConv.conversationId.toLowerCase()
                        : false
                    }
                    onClick={props.conversationClick(conv, props.convStatus)}
                  >
                    <div style={{ display: "flex" }}>
                      <Tooltip title={conv.customerName}>
                                  {conv?.channel === 'Chat' ?
                                      (<ChatIcon color="primary" sx={{ marginTop: "2px" }} />)
                                      : (<PhoneIphoneIcon color="primary" />)}
                      </Tooltip>

                      <div style={{ marginLeft: 15, minWidth: 176 }}>
                        <div className="userName" style={{ display: "flex" }}>{conv.customerName}
                          {(conv?.channel === 'Chat' && conv?.authenticationStatus === 'Authenticated')
                            && <Tooltip title="Authenticated">
                              <TaskAltTwoToneIcon fontSize = {"small"} color="success" sx={{ marginLeft: "3px" }} />
                            </Tooltip>}
                        </div>
                        <div className="phoneNumber">
                                      {conv.customerPhoneNumber ? conv.customerPhoneNumber :conv.email}
                        </div>
                      </div>
                    </div>
                  </ListItem>
                    <Divider
                      key={"dvdr" + conv.conversationId}
                      style={{ backgroundColor: "white" }}
                    />
                  </>}
                </>
              );
            })
          ) : (
            <div style={{ textAlign: "center", paddingTop: 10 }}>
              No Conversations
            </div>
          )}
    </>
  );
};
