import React from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    CircularProgress,
    DialogTitle,
    Divider,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell
} from "@mui/material";
import { useEffect, useState } from "react";
import { InvokePostServiceCall } from "../api/serviceUtil";
import apiConfig from "../api/apiConfig";

import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import CloseIcon from "@mui/icons-material/Close";

export default function VideoChat(props) {

    const [joinUrl, setJoinUrl] = useState('');
    const [hostUrl, setHostUrl] = useState('');
    const [showloader, setShowloader] = useState('');
    const [customerName, setCustomerName] = useState('Customer');


    useEffect(() => {
        var roomName = props.state.conversation.customerPhoneNumber;
       // setJoinUrl(window.location.origin + '?room=' + roomName);  // Twilio URL
        if (props.state.conversation.customerName != ' ' && props.state.conversation.customerName!=null) {
            setCustomerName(props.state.conversation.customerName);
        }
    }, []);

    const joinMeeting = () => {
        props.sendVideoChatUrl(joinUrl);
        window.open(hostUrl);
    }

    const createMeetingLink = () => {
        setShowloader(true);
        var vidReq = {
            conversationId: props.state.conversation.conversationId
        }

                                                                    

       InvokePostServiceCall(apiConfig.START_VIDEO_CONF, vidReq)
            .then((data) => {
                setJoinUrl(data.data.join_url);
                setHostUrl(data.data.start_url);
                setShowloader(false);
            })
            .catch((error) => {
                debugger;
                setShowloader(false);
                console.log(error.message);
            });
        
    }

    return (
        <Dialog
            open={props.state.showVideoChat}
            onClose={props.handleVideoModalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={props.dialogFullWidth}
        >
            <DialogTitle><Box m={1.7}><strong>Video Chat with {customerName}</strong></Box></DialogTitle>
            <Divider />
            <DialogContent>
                {showloader ? (
                    <Box sx={{ display: "flex", marginLeft: "40%" }}>
                        <CircularProgress />
                    </Box>
                ) : (
                   <></>
                )}
                <div style={{}}>
                    <div>
                    <label>
                         Host Link  
                        </label>
                        <input style={{}}
                            value={hostUrl} style={{ width: "200px", margin: "5px" }} />
                    </div><div>
                    <label>
                       
                         Join Link  
                        </label>
                        <input 
                            value={joinUrl} style={{ width: "200px", margin: "9px" }} />
                    <label>
                        
                        On click of join, LINK will be automatically sent to {customerName}.
                </label>
                        </div>
                </div>
            </DialogContent>
            <DialogActions>
                {joinUrl == '' &&(
                    <Button value={joinUrl} onClick={createMeetingLink} autoFocus>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <strong> Create Meeting</strong>
                        </div>
                    </Button>
                )}
                {joinUrl != '' && (
                    <Button value={joinUrl} onClick={joinMeeting} autoFocus>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <DoneOutlinedIcon size={20} color="primary" /><span><strong>Join</strong></span>
                        </div>
                    </Button>
                )}
                <Button onClick={props.handleVideoModalClose} >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <CloseIcon size={20} color="primary" /><span><strong> Close</strong></span>
                    </div>
                </Button>
            </DialogActions>
        </Dialog>
    )
}