import React,{useState} from 'react'
import { InvokePostServiceCall } from '../api/serviceUtil';
import apiConfig from '../api/apiConfig';
import FileSaver from 'file-saver';


const  useFileDownload=()=> {
    const [downloading, setDownloading] = useState({
        isDownloading:false,
        isRefreshStatus:false,
        id:''
    });
    const [fileDownloadError,setFileDownloadError]=useState("")
    const downloadFile = (msgObj) => {
        setDownloading({
            isDownloading:true,
            id: msgObj.documentId
        });
        var req = {
            documentStoreId: msgObj.documentId
        };
        InvokePostServiceCall(apiConfig.GET_DOCUMENT_FROM_STORE, req)
            .then((data) => {
                if (data.data.status === "FAILED") {
                    setFileDownloadError(data.data.statusDescription)
                } else {
                    //openBase64NewTab(data.data)
                    FileSaver.saveAs("data:" + data.data.fileExtension + ";base64," + data.data.documentBase64, msgObj.body);
                }
                setDownloading({
                    isDownloading: false,
                    id: msgObj.documentId
                });
            })
            .catch((error) => {
                console.log(error.message);
            }).finally(() => {
                setDownloading({
                    isDownloading: false,
                    id: msgObj.documentId
                });
            });
    }

  return{
    downloadFile,
    downloading,
    setDownloading,
    fileDownloadError,
    setFileDownloadError

  }
}

export default useFileDownload