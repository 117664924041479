/**
 * 08-04-2023 => SUDAM B CHAVAN => reset page index to 1 page on filter data
 * 08-29-2023 => ONKAR => TEXTING-1578 -REACT Upgrade  
 * 08-30-2023 => ONKAR => TEXTING-1578 -REACT Upgrade  
*/
import React, { useState, useEffect, useContext } from "react";
import { Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import apiConfig from "../../api/apiConfig";
import { AuthContext } from "../../AuthContext";
import { useTheme } from "@mui/material/styles";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import moment from "moment";
import SnackBar from "../../components/SnackBar";
import { Checkbox, InputAdornment } from "@mui/material";
import { TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import { StyledDataGrid } from "../../components/styledComponents/StyledDataGrid";

const columns = [
    {
        field: "holidayName",
        headerName: "Holiday Name",
        flex: 1,
        minWidth: 100,
        headerClassName: "super-app-theme--header",
    },
    {
        field: "holidayDate",
        headerName: "Holiday Date",
        flex: 1,
        minWidth: 100,
        headerClassName: "super-app-theme--header",
    },
    {
        field: "createdDate",
        headerName: "Created Date",
        flex: 1,
        minWidth: 100,
        headerClassName: "super-app-theme--header",
    },
    {
        field: "lastModifiedDate",
        headerName: "Modified Date",
        flex: 1,
        minWidth: 100,
        headerClassName: "super-app-theme--header",
    },
    {
        field: "isActive",
        headerName: "Is Active",
        flex: 1,
        minWidth: 50,
        filterable: false,

        headerClassName: "super-app-theme--header",
        renderCell: (params) => {
            return (
                <div style={{ marginLeft: "5px" }}>
                    <Checkbox disabled checked={params.row.isActive} />
                </div>
            );
        },
    },
];

function Holidays() {
    const theme = useTheme();
    const navigate = useNavigate();
    const { messageApiToken } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [holidays, setHolidays] = useState([]);
    const [tempArr, setTempArr] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackBarMsg] = useState("");
    const [page, setPage] = useState(0);
    useEffect(() => {
        if (messageApiToken) {
            setLoading(true);
            InvokePostServiceCall(apiConfig.GET_HOLIDAYS, {})
                .then((data) => {
                    debugger;

                    let tempArr = [];
                    data.data.holidayDetails.forEach((item, index) => {
                        item.holiday.id = index;
                        item.holiday.holidayDate = moment(
                            item.holiday.holidayDate
                        )
                            .local()
                            .format("MM-DD-YYYY");
                        item.holiday.createdDate = moment(
                            item.holiday.createdDate
                        )
                            .local()
                            .format("MM-DD-YYYY");
                        item.holiday.lastModifiedDate = moment(
                            item.holiday.lastModifiedDate
                        )
                            .local()
                            .format("MM-DD-YYYY");
                        tempArr.push(item.holiday);
                    });
                    setHolidays(tempArr);
                    setTempArr(tempArr);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    setSnackBarMsg(error.message);
                    setOpenSnackbar(true);
                    console.log(error, "Error Message");
                });
        }
    }, [messageApiToken]);
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };
    const filterTable = (value) => {
        let usArr = [];
        let val = value?.toLowerCase();
        setPage(0);
        tempArr.forEach((item) => {

            if (
                item.holidayName?.toLowerCase().includes(val) ||
                item.holidayDate?.toLowerCase().includes(val) ||
                item.createdDate?.toLowerCase().includes(val) ||
                item.lastModifiedDate?.toLowerCase().includes(val)
            ) {
                usArr.push(item);
            }
        });
        setHolidays(usArr);
    }

    return (
        <>
            <SnackBar
                openSnackbar={openSnackbar}
                handleCloseSnackbar={handleCloseSnackbar}
                severity={"error"}
                userMessage={snackbarMsg}
            />
            <div>
                <Box sx={theme.customStyle.tableRow}>
                    <h2 style={{ marginLeft: 10 }}>Holidays</h2>
                </Box>
            </div>
            <div style={{ display: "flex", alignItems: "flex-start", paddingLeft: 10 }}>
                <Box sx={theme.customStyle.tableRow}>
                    <Button
                        sx={theme.customStyle.modalbtn}
                        onClick={() => {
                            navigate("/addUpdateHolidays", {
                                state: { isAddHoliday: true },
                            });
                        }}
                    >
                        <AddIcon />
                    </Button>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <TextField
                            sx={{ marginRight: "10px", marginBottom: "10px" }}
                            size="small"
                            type="search"
                            variant="outlined"
                            margin="normal"
                            id="outlined-search"
                            label=""
                            placeholder="Holidays Search"
                            autoComplete="off"
                            onChange={(e) => {
                                if (e.target.value) {
                                    filterTable(e.target.value);
                                }
                                else {
                                    setHolidays(tempArr);
                                }

                            }}
                            InputLabelProps={{
                                shrink: false,
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                </Box>
            </div>
            <Box sx={theme.customStyle.tableHead}>
                <StyledDataGrid
                    loading={loading}
                    rows={holidays}
                    columns={columns}
                    pageSize={10}
                    page={page}
                    onPageChange={(page) => setPage(page)}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                    components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                    }}
                    onRowDoubleClick={(e) => {
                        navigate("/addUpdateHolidays/" + e.row.holidaysId, {
                            state: {
                                isAddHoliday: false,
                                HolidayDetails: e.row,
                            },
                        });
                    }}
                    // initialState={{
                    //     sorting: {
                    //         sortModel: [{ field: "lastModifiedDate", sort: "desc" }],
                    //     },
                    // }}
                />
            </Box>
        </>
    );
}

export default Holidays;
