/*  
 =========================================
* 3/11/2022 Texting:718- if no users showing no rows as per other compnent
* 11/11/2022 Texting:780- if no users showing no users found 
* 22/11/2022   Texting:827- no rectords found after no data 
// 07-25-2023 => ONKAR => TEXTING-1444 - Omnichannel Conversations UI should show Sentiment on the card.
// 08-11-2023 => SUDAM B CHAVAN => TEXTING-1520 - Omni Channel Enhancements
// 08-14-2023 => SUDAM B CHAVAN => TEXTING-1520 -Reopen Iteams
// 08-23-2023 => PRABHAT => TEXTING-1609 Authenticated icon in Omni channel
//10-19-2023 => ONKAR =>TEXTING-1815 -Number 0 is showing on omni channel, History tab instead of Neutral emoji If overall sentiment of conversation is '0'.
//12-20-2023 => ONKAR =>TEXTING-1924 -Member Initials for Customer messages avatar is not showing in conversation opened from Omni channel, History tab, Search messages.
*02/07-2024 => SUDAM B CHAVAN => TEXTING-2018 Show Unassigned and Preassigned conversations in Omni channel view.
*/

import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import ExpandMore from "@mui/icons-material/ExpandMore";
import Brightness1TwoToneIcon from '@mui/icons-material/Brightness1TwoTone';
import { useTheme } from "@emotion/react";
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CustomNoRowsOverlay from './CustomNoRowsOverlay';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ChatIcon from '@mui/icons-material/Chat';
import {Tooltip} from '@mui/material'
import moment from "moment";
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import TaskAltTwoToneIcon from '@mui/icons-material/TaskAltTwoTone';
import SentimentEmoji from './SentimentEmoji';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#505F79",
        color: theme.palette.common.white,
        fontWeight: "bold",
        padding: "10px 16px !important"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: "10px 16px !important",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#eeeeee",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
function descendingComparator(a, b, orderBy) {
    switch (orderBy) {
        case "Name":
            if (b.user["UserName"] < a.user["UserName"]) {
                return -1;
            }
            if (b.user["UserName"] > a.user["UserName"]) {
                return 1;
            }
            return 0;
        case "Status":
            if (b.user["Status"] < a.user["Status"]) {
                return -1;
            }
            if (b.user["Status"] > a.user["Status"]) {
                return 1;
            }
            return 0;
        case "Conversations":

            if (b.conversations.length < a.conversations.length) {
                return -1;
            }
            if (b.conversations.length > a.conversations.length) {
                return 1;
            }
            return 0;
        case "Capacity":
            let aCapacity = a.capacity ? a.capacity : 0
            let bCapacity = b.capacity ? b.capacity : 0
            if (bCapacity < aCapacity) {
                return -1;
            }
            if (bCapacity > aCapacity) {
                return 1;
            }
            return 0;
        default:
            return 0
    }


}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'Name',
        numeric: false,
        disablePadding: true,
        label: 'Name',
    },
    {
        id: 'Status',
        numeric: true,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'Conversations',
        numeric: true,
        disablePadding: false,
        label: 'Conversations',
    },
    {
        id: 'Workload',
        numeric: true,
        disablePadding: false,
        label: 'Workload',
    },
    {
        id: 'Capacity',
        numeric: true,
        disablePadding: false,
        label: 'Capacity',
    },
    {
        id: 'Teams',
        numeric: true,
        disablePadding: false,
        label: 'Teams',
    },
];
function EnhancedTableHead(props) {
    const theme = useTheme();
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead >
            <TableRow>
                {headCells.map((headCell, index) => {
                    return (<>

                        <StyledTableCell
                            key={"headCell" + index}
                        >
                            <Box sx={theme.customStyle.headerText} onClick={(headCell.label != "Workload" || headCell != "Teams") && createSortHandler(headCell.id)} >
                                {headCell.label == "Name" ?
                                    <TableSortLabel
                                        direction={orderBy === headCell.id ? order : 'asc'}

                                        sx={{ minWidth: "300px" }}
                                    >
                                        <span>&nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;</span>
                                        {headCell.label}
                                    </TableSortLabel>
                                    :
                                    headCell.label == "Workload"
                                        ?
                                        <>
                                            {headCell.label}
                                            <Box component="span" sx={visuallyHidden}>

                                            </Box>
                                        </>

                                        :

                                        <TableSortLabel
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                        >

                                            {headCell.label}
                                        </TableSortLabel>
                                }
                                |</Box>
                        </StyledTableCell>

                    </>)
                }
                )}

            </TableRow>
        </TableHead >
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};



export default function UsersTable(props) {
    let { setPageNO, pageNo, userList, setSelectedConverstion, setShowConvDialog, setUserList, getMesseges, loading } = props
    const [order, setOrder] = React.useState();
    const [orderBy, setOrderBy] = React.useState();
    const [selected, setSelected] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const theme = useTheme();
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleChangePage = (event, newPage) => {
        setPageNO(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPageNO(0);
    };
    const calcActiveTimeInHumanize = (activeStartTimestamp) => (moment.duration(moment().diff(activeStartTimestamp))).humanize();//return 1 day, 2days
    const calculateActivityTooltipMsg = (agentMsgDate, customerMsgDate, action) => {
        let msgFrom = "";
        // debugger
        if (agentMsgDate && customerMsgDate) {
            const dtAgentMsgDate = moment(agentMsgDate);
            const dtCustomerMsgDate = moment(customerMsgDate);
            // Compare datetime values
            if (dtAgentMsgDate.isAfter(dtCustomerMsgDate)) {
                msgFrom = "Agent";
                console.log('Agent Msg Date is more recent.');
            } else if (dtAgentMsgDate.isBefore(dtCustomerMsgDate)) {
                msgFrom = "Customer";
                console.log('Customer Msg Date is more recent.');
            } else {
                console.log('Both date times are the same.');
            }
        }
        else if (agentMsgDate) {
            msgFrom = "Agent";
        }
        else if (customerMsgDate) {
            msgFrom = "Customer";
        }
        if (msgFrom && action === "label"){
            
            return moment(msgFrom === "Agent" ? agentMsgDate : customerMsgDate).from(moment());//return => 1 day ago, 2 hours ago
        }
        if (msgFrom && msgFrom ==="Agent"){
                return `Last message sent by ${msgFrom} on ${moment(agentMsgDate).format("lll")}`;
        }
        if (msgFrom && msgFrom === "Customer") {
            return `Last message sent by ${msgFrom} on ${moment(customerMsgDate).format("lll")}`;
        }
    }
    // Avoid a layout jump when reaching the last page with empty rows.


    return (
        <Box sx={{ width: '100%', }}>


            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={userList.length}
                        />
                        {
                            userList.length > 0 ?
                                <TableBody>
                                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}

                                    {
                                        stableSort(userList, getComparator(order, orderBy))
                                            .slice(pageNo * rowsPerPage, pageNo * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                let isNotAgentRow = ["In Routing", "Missed"].includes(row.user.UserName);
                                                return (
                                                    <>
                                                        <StyledTableRow
                                                            sx={{ ...isNotAgentRow && row.conversations?.length > 0 && { textShadow: "0 0 black", backgroundColor:"#ff9800 !important"}}}
                                                            onClick={(event) => {
                                                                let userData = userList.map(data => {
                                                                    if (data.user && row.user && row.user.UserId === data.user.UserId) {
                                                                        return { ...data, colapse: !data.colapse }
                                                                    }
                                                                    else
                                                                        return { ...data, colapse: false }
                                                                })
                                                                setUserList(userData)
                                                            }}
                                                            tabIndex={-1}
                                                            key={"row" + index}
                                                        >
                                                            <TableCell align="left" sx={{ ...theme.customStyle.CustomStyledCell, minWidth: "300px" }}>{(row.conversations.length > 0) ?
                                                                (row.colapse ? <ExpandMore /> : <ChevronRightIcon />) : <span style={{ marginLeft: "1.5rem" }}></span>}
                                                                <span>
                                                                    {row.user && row.user.Status == "Available" &&
                                                                        <Brightness1TwoToneIcon sx={{ ...theme.customStyle.statusIcon }} style={theme.customStyle.available} />
                                                                    }
                                                                    {row.user && row.user.Status == "Busy" &&
                                                                        <Brightness1TwoToneIcon sx={{ ...theme.customStyle.statusIcon }} style={theme.customStyle.busy} />
                                                                    }
                                                                    {row.user && row.user.Status == "Offline" &&
                                                                        <Brightness1TwoToneIcon sx={{ ...theme.customStyle.statusIcon }} style={theme.customStyle.Offline} />
                                                                    }
                                                                    {row.user && !row.user.Status &&
                                                                        < Brightness1TwoToneIcon sx={{ ...theme.customStyle.statusIcon,visibility:"hidden" }} style={theme.customStyle.Offline} />
                                                                    }
                                                                </span>
                                                                <span> {row.user && row.user.UserName}</span></TableCell>
                                                            <TableCell align="left">{row.user.Status}
                                                                {/* {row.user && row.user.Status ? row.user.Status : "Offline"} */}
                                                            </TableCell>
                                                            <TableCell sx={theme.customStyle.CustomStyledCell} align="left">{row.conversations.length}</TableCell>
                                                            <TableCell sx={theme.customStyle.CustomStyledCell} align="left">{!isNotAgentRow &&(row.conversations.length + "/" + (row.user.MaxConversations ? row.user.MaxConversations : 0))}</TableCell>
                                                            <TableCell sx={theme.customStyle.CustomStyledCell} align="left"> {!isNotAgentRow && ((row.capacity ? row.capacity : 0) +'%')}</TableCell>
                                                            <TableCell sx={theme.customStyle.CustomStyledCell} align="left">{row.teams && row.teams.map(data => { return data.team && data.team.TeamName }).join(",")}</TableCell>
                                                        </StyledTableRow>
                                                        {row.colapse &&
                                                            row.conversations.map((conversation, index) => {
                                                                return (
                                                                    <StyledTableRow
                                                                        hover
                                                                        tabIndex={-1}
                                                                        key={"conversion" + index}
                                                                    >
                                                                        <TableCell align="left" sx={{ ...theme.customStyle.CustomNestedStyledTableCell, minWidth: "300px" }}></TableCell>
                                                                        <TableCell sx={theme.customStyle.CustomNestedStyledTableCell} align="right">
                                                                            {conversation?.Channel === 'Chat' ?
                                                                                (<ChatIcon color="primary" sx={{ marginTop: "2px" }} />)
                                                                                : (<PhoneIphoneIcon color="primary" />)}
                                                                        </TableCell>
                                                                        <TableCell sx={theme.customStyle.CustomNestedStyledTableCell} align="left">
                                                                            <Link to="#" 
                                                                            onClick={() => {
                                                                                let tempConv={...conversation}
                                                                                // Check if customerName exists and is not "Unknown" 
                                                                                 if (tempConv?.CustomerName && tempConv?.CustomerName !="Unknown") {
                                                                                 // Split the CustomerName into an array of words
                                                                                    let nameArray = tempConv?.CustomerName?.split(" ");
                                                                                    // Check if there is at least one word in the nameArray
                                                                                    //// Assign the first character of the first word to customerNameInitials
                                                                                    if (nameArray.length > 0) {
                                                                                        tempConv.CustomerNameInitials = nameArray[0].charAt(0);
                                                                                    }
                                                                                    if (nameArray.length > 1){
                                                                                        tempConv.CustomerNameInitials += "" + nameArray[1].charAt(0);
                                                                                    }
                                                                                 }
                                                                                 
                                                                                setSelectedConverstion(tempConv);
                                                                                getMesseges(conversation,"usersTable"); 
                                                                                setShowConvDialog(true) 
                                                                                }}>
                                                                            <div className="userName" style={{ display: "flex" }}>
                                                                                {conversation.CustomerName === " " ? "Unknown" : conversation.CustomerName}
                                                                                {(conversation?.Channel === 'Chat' && conversation?.AuthenticationStatus === 'Authenticated')
                                                                                    && <Tooltip title="Authenticated">
                                                                                        <TaskAltTwoToneIcon fontSize="small" color="success" sx={{ marginLeft: "3px" }} />
                                                                                    </Tooltip>}
                                                                      
                                                                            </div>
                                                                            <div className="phoneNumber">{conversation.CustomerPhoneNumber}</div>
                                                                            {conversation?.AssignedDate &&
                                                                                <div div className="fs-12">
                                                                                    <Tooltip followCursor arrow title=" Conversation Duration">
                                                                                        <span>Assigned: {calcActiveTimeInHumanize(conversation?.AssignedDate)}</span>
                                                                                    </Tooltip>
                                                                                </div>}
                                                                            {(conversation?.RecentAgentRespondedDate || conversation?.RecentCustomerRespondedDate) &&
                                                                                <div div className="fs-12">
                                                                                    <Tooltip followCursor arrow title={calculateActivityTooltipMsg(conversation?.RecentAgentRespondedDate, conversation?.RecentCustomerRespondedDate, "tooltip")}>
                                                                                        <span>Activity: {calculateActivityTooltipMsg(conversation?.RecentAgentRespondedDate, conversation?.RecentCustomerRespondedDate, "label")}</span></Tooltip>
                                                                                </div>
                                                                            }
                                                                        </Link>
                                                                       
                                                                        </TableCell>
                                                                        <TableCell sx={theme.customStyle.CustomNestedStyledTableCell} align="left"> 
                                                                       {(conversation?.OverallSentiment || conversation?.OverallSentiment===0 ) && props.enableSentimentCalculation==="Y"&&
                                                                          <SentimentEmoji overallSentiment={conversation?.OverallSentiment} styles={{right: "35px"}}/>
                                                                          }
                                                                            {conversation?.ShowNotification && 
                                                                            <Tooltip title="There are Un-Read Messages in the conversation">
                                                                             <AnnouncementOutlinedIcon color='warning' />
                                                                            </Tooltip>
                                                                            }
                                                                          </TableCell>
                                                                        <TableCell sx={theme.customStyle.CustomNestedStyledTableCell} align="left"> </TableCell>
                                                                        <TableCell sx={theme.customStyle.CustomNestedStyledTableCell} align="left">{conversation.TeamName}</TableCell>
                                                                    </StyledTableRow>
                                                                )
                                                            })
                                                        }

                                                    </>

                                                );
                                            })


                                    }
                                </TableBody>
                                : <div style={{ height: "400px" }}>
                                    <p style={{ position: "absolute", left: "46%", top: "465px" }}> No Records Found</p>
                                </div>
                        }
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={userList.length}
                    rowsPerPage={rowsPerPage}
                    page={pageNo}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                // labelRowsPerPage="Per page:"
                />
            </Paper>



        </Box>
    );
}
