////11-11-2022 => Texting-800 calling api to get missed conversations on click of missed menu.
////05-12-2022 => ONKAR => Texting-1263 - Click on missed icon in the left panel from the active conversation page is making the active conversation page blank.
////10-05-2022 => ONKAR => Texting-1775 - New conversation panel is moving to Missed conversation list panel when missed icon in left panel is auto highlighting.
import React, { useContext, useEffect, useState } from "react";
import { ListItemButton, ListItemIcon, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AuthContext } from "../AuthContext";
import AssignmentIndTwoToneIcon from "@mui/icons-material/AssignmentIndTwoTone";
import { useSelector } from "react-redux";
import { notificationSelector } from "../storage/slices/notificationSlice";

function UnassignedConversationsNew(props) {
  const { unAssignedNotifList } = useSelector(notificationSelector);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const {
    notiFicationFilter,
    headerNotification,
    setCollapseInactive,
    collapseInactive,
    setOpenConversationDrawer,
    setNotificationFilter,
    setHeaderNotification,
    matches
  } = useContext(AuthContext);

  useEffect(() => {
    if (props.openActive) {
      setOpen(false);
    }
  }, [props.openActive]);

  useEffect(() => {
    if (collapseInactive) {
      setOpen(false);
    }
  }, [collapseInactive]);

  useEffect(() => {
    if (headerNotification) {
      setOpen(true);
      props.setOpenActive(false);
      setCollapseInactive(false);
    }
  }, [notiFicationFilter]);
  return (
    <>
      <ListItemButton
        title="Missed Conversations"
        selected={props.selectedMenu === "unassigned"}
        sx={theme.customStyle.listItemIconDrawerClosedNew}
        onClick={(e) => {
          setOpen(!open);
          props.setOpenActive(false);
          setCollapseInactive(false);
          props.setSelectedMenu("unassigned");
          setOpenConversationDrawer(true);
          setHeaderNotification(true);
          setNotificationFilter(!notiFicationFilter);
        }}
      >
        <ListItemIcon sx={theme.customStyle.listItemIcon}>
          <div style={{ textAlign: "left" }}>
            <props.IconBadge
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              badgeContent={unAssignedNotifList.length}
              color="error"
            >
              <AssignmentIndTwoToneIcon
                iconSize={theme.customStyle.iconSize}
                sx={
                  props.selectedMenu === "unassigned"
                    ? theme.customStyle.activeDrawerMenuDrawerClosed
                    : theme.customStyle.inActiveDrawerMenuDrawerClosed
                }
              />
            </props.IconBadge>
          </div>
          <Box sx={{ ...theme.customStyle.listItemLabelDiv, left: "-4px" }}>
            <span style={{ fontSize: "10px" }}>Missed</span>
          </Box>
        </ListItemIcon>
      </ListItemButton>
    </>
  );
}

export default UnassignedConversationsNew;
