// 08-29-2023 => ONKAR => TEXTING-1578 -REACT Upgrade
// 08-30-2023 => ONKAR => TEXTING-1578 -REACT Upgrade
//01-12-2024 => TEXTING - 1943 Contact List UI - observations
//01-17-2024 => coomm list search by status
import React, { useState, useEffect, useContext } from "react";
import { Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import apiConfig from "../../api/apiConfig";
import { AuthContext } from "../../AuthContext";
import { useTheme } from "@mui/material/styles";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import moment from "moment";
import SnackBar from "../../components/SnackBar";
import { Checkbox,InputAdornment } from "@mui/material";
import { TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import { StyledDataGrid } from "../../components/styledComponents/StyledDataGrid";


const columns = [
    {
        field: "communicationListName",
        headerName: "Communication Name",
        flex: 1,
        minWidth: 100,
        headerClassName: "super-app-theme--header",
    },
    {
        field: "communicationListCode",
        headerName: "Communication Code",
        flex: 1,
        minWidth: 100,
        headerClassName: "super-app-theme--header",
    },
    {
        field: "createdDate",
        headerName: "Created Date",
        flex: 1,
        minWidth: 100,
        headerClassName: "super-app-theme--header",
    },
    {
        field: "lastModifiedDate",
        headerName: "Modified Date",
        flex: 1,
        minWidth: 100,
        headerClassName: "super-app-theme--header",
    },
    {
        field: "status",
        headerName: "Status",
        flex: 1,
        minWidth: 100,
        headerClassName: "super-app-theme--header",
    },
    {
        field: "isActive",
        headerName: "Is Active",
        flex: 1,
        minWidth: 50,
        filterable: false,

        headerClassName: "super-app-theme--header",
        renderCell: (params) => {
            return (
                <div style={{ marginLeft: "5px" }}>
                    <Checkbox disabled checked={params.row.isActive} />
                </div>
            );
        },
    },
];

function Communications() {
    const theme = useTheme();
    const navigate = useNavigate();
    const { messageApiToken } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [communicationList, setCommunicationList] = useState([]);
    const [tempArr, setTempArr] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackBarMsg] = useState("");

    useEffect(() => {
        if (messageApiToken) {
            setLoading(true);
            InvokePostServiceCall(apiConfig.GET_COMMUNICATION_LIST, {})
                .then((data) => {
                    let tempArr = [];
                    data.data.communicationListDetails.forEach((item, index) => {
                        item.communicationList.id = index;
                        item.communicationList.createdDate = moment(
                            item.communicationList.createdDate
                        )
                            .local()
                            .format("MM-DD-YYYY");
                        item.communicationList.lastModifiedDate = moment(
                            item.communicationList.lastModifiedDate
                        )
                            .local()
                            .format("MM-DD-YYYY");
                        tempArr.push(item.communicationList);
                    });
                    setCommunicationList(tempArr);
                    setTempArr(tempArr);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    setSnackBarMsg(error.message);
                    setOpenSnackbar(true);
                    console.log(error, "Error Message");
                });
        }
    }, [messageApiToken]);
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };
    const filterTable = (value) => {
        let usArr = [];
        let val = value?.toLowerCase();
        tempArr.forEach((item) => {

            if (
                item.communicationListName?.toLowerCase().includes(val) ||
                item.communicationListCode?.toLowerCase().includes(val) ||
                item.status?.toLowerCase().includes(val) ||
                item.createdDate?.toLowerCase().includes(val) ||
                item.lastModifiedDate?.toLowerCase().includes(val)
            ) {
                usArr.push(item);
            }
        });
        setCommunicationList(usArr);
    }


    return (
        <>
            <SnackBar
                openSnackbar={openSnackbar}
                handleCloseSnackbar={handleCloseSnackbar}
                severity={"error"}
                userMessage={snackbarMsg}
            />
            <div>
                <Box sx={theme.customStyle.tableRow}>
                    <h2 style={{ marginLeft: 10 }}>Communication Lists</h2>
                </Box>
            </div>
            <div style={{ display: "flex", alignItems: "flex-start", paddingLeft: 10 }}>
                <Box sx={theme.customStyle.tableRow}>
                    <Button
                        sx={theme.customStyle.modalbtn}
                        onClick={() => {
                            navigate("/addUpdateCommunications", {
                                state: { isAddCommunication: true },
                            });
                        }}
                    >
                        <AddIcon />
                    </Button>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <TextField
                            sx={{ marginRight: "10px", marginBottom: "10px" }}
                            size="small"
                            type="search"
                            variant="outlined"
                            margin="normal"
                            id="outlined-search"
                            label=""
                            placeholder="List Search"
                            autoComplete="off"
                            onChange={(e) => {
                                if (e.target.value) {
                                    filterTable(e.target.value);
                                }
                                else {
                                    setCommunicationList(tempArr);
                                }

                            }}
                            InputLabelProps={{
                                shrink: false,
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                </Box>
            </div>
            <Box sx={theme.customStyle.tableHead}>
                <StyledDataGrid
                    loading={loading}
                    rows={communicationList}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                    components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                    }}
                    onRowDoubleClick={(e) => {
                        navigate("/addUpdateCommunications/" + e.row.communicationListId, {
                            state: {
                                isAddCommunication: false,
                                communicationDetails: e.row,
                            },
                        });
                    }}
                    // initialState={{
                    //     sorting: {
                    //         sortModel: [{ field: "lastModifiedDate", sort: "desc" }],
                    //     },
                    // }}
                />
            </Box>
        </>
    );
}

export default Communications;