////07-10-2023 => SUDAM B CHAVAN =>  TEXTING-1417 - GPT Component Pending observations.
////08-03-2023 => SUDAM B CHAVAN =>  TEXTING-1461 - Critical: Messages tab in active conversation page should be Freezed
////08-08-2023 => SUDAM B CHAVAN =>  TEXTING-1461 - Observation

import React, { useState, useEffect, useContext } from "react";
import TabList from "@mui/lab/TabList";
import { styled, useTheme } from "@mui/material/styles";
import { Box, IconButton, Tab, Menu, MenuItem } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AuthContext } from "../AuthContext";

function CustomTabList(props) {
    const theme = useTheme();
    const { tabList, showTabsCount, handleChangeTab } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [tabsToShow, setTabsToShow] = useState(tabList || []);
    const [menuItem, setMenuItem] = useState([]);
    const [selectedMenuValue, setSelectedMenuValue] = useState(props.selectedValue);
    const open = Boolean(anchorEl);
    const { selectedConv } = useContext(AuthContext);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMenuSelection = (selectedMenu) => {
        setAnchorEl(null);
        //tabsToShow.push(selectedMenu)
        var removedElement;
        setSelectedMenuValue(selectedMenu.value)
        let removedMenuArray = menuItem.filter(m => m.value !== selectedMenu.value);
        let sortedTabs = tabsToShow.sort((a, b) => a.displayOrder - b.displayOrder); // b - a for reverse sort
        // if (sortedTabs[sortedTabs.length - 1].displayOrder === selectedMenu.displayOrder) {
        //     removedElement = sortedTabs.shift();
        // }
        // else {
        removedElement = sortedTabs.pop()
        sortedTabs.push(selectedMenu)
        // }

        if (removedElement){
            removedMenuArray.push(removedElement)
            removedMenuArray.sort((a, b) => a.displayOrder - b.displayOrder);
            setMenuItem(removedMenuArray);
        }
        setTabsToShow(sortedTabs);
        handleChangeTab(null, selectedMenu.value)
    };
    

    useEffect(() => { //first time when component get mounted
        initTabs() 
    },[])
    
    useEffect(() => { // when conversation changed..
        initTabs()
    }, [selectedConv?.conversationId]);
    const initTabs=()=>{
        if (showTabsCount && tabList?.length > Number(showTabsCount)){
            const tabs = tabList.slice(0, showTabsCount);
            const menu = tabList.slice(showTabsCount, tabList?.length);
            setTabsToShow(tabs);
            setMenuItem(menu);
        }     
    }
    return (
        <Box sx={theme.customStyle.tabContextBox}>
            <TabList
                onChange={handleChangeTab}
                aria-label="lab API tabs example"
                centered={true}
                variant="fullWidth"
                sx={theme.customStyle.tabStyle}
            >
                {tabsToShow && tabsToShow.map((tab)=>{
                    console.log("selectedMenuValue ", selectedMenuValue)
                    return <Tab
                        sx={theme.customStyle.tabStyle}
                        label={tab.label}
                        value={tab.value}
                    />
                })}
                {menuItem?.length > 0 && <IconButton
                    id="basic-button"
                    onClick={handleClick}
                >
                    <ExpandMoreIcon></ExpandMoreIcon>
                </IconButton>}
            </TabList>
            <div>

                {menuItem && <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    {menuItem.map((menu) => {
                        return <MenuItem onClick={()=> handleMenuSelection(menu)}>{menu.label}</MenuItem>
                    })}
                </Menu>}
            </div>
        </Box>
    );
}

export default CustomTabList;
