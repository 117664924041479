/***************
*11-11/2022 TextField:766=>disabled auto suggetion
*06-28/2022 - SUDAM B CHAVAN - Update contacts UI to take inputs for state (dropdown with all US states) and timezone (dropdown with all US timezones)
*07/31/2023 -PRABHAT - TEXTING-1495 - Required fields are not marked with * in Admin UI contact page.  
*08/07/2023 -PRABHAT - TEXTING-1420 - Admin UI contact observations.   
*08-29/2022 - SUDAM B CHAVAN - TEXTING-1625 - Start conversation from Admin UI contact is not working
*09-04-2022 - Prabhat - TEXTING-1625 - Select team is not working when creating the Admin UI contact.
***********/
import React, { useState, useEffect, useContext } from "react";
import { Box, Tooltip, TextField, Button, Typography, Collapse, Select, InputLabel, FormControl } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import apiConfig from "../../api/apiConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../AuthContext";
import CommentIcon from "@mui/icons-material/Comment";
import CircularProgress from "@mui/material/CircularProgress";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import { useTheme } from "@mui/material/styles";
import SnackBar from "../../components/SnackBar";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import ChatHistory from "../../components/ChatHistory";
import JSONInput from "react-json-editor-ajrm";
import moment from "moment";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {states, timezones} from './states';
import CustomTabList from "../../components/CustomTabList";
import Chip from "@mui/material/Chip";

export default function AddUpdateContact() {
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const { userInfo, messageApiToken } = useContext(AuthContext);
    const userId = userInfo.azureAdid;
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [emailErr, setEmailErr] = useState(false);
    const [teams, setTeams] = useState("");
    const [addTeam, setAddTeam] = useState([]);
    const [tabValue, setTabValue] = useState("1");
    const [severity, setSeverity] = useState("success");
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [open, setOpen] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState(userInfo.teamId);
    const [contactTeams, setContactTeams] = useState([]);
    const [isReadOnly, setIsReadOnly] = useState(false);

    const isAddContact = location.state ? location.state.isAddContact : true;
    const [userData, setUserData] = useState({
        contactAttributes: "",
        contactId: "",
        conversations: [],
        createdBy: "",
        createdByName: "",
        createdByNavigation: "",
        createdDate: "",
        email: "",
        firstName: "",
        lastModifiedBy: "",
        lastModifiedByName: "",
        lastModifiedByNavigation: "",
        lastModifiedDate: "",
        lastName: "",
        mobileNumber: location.state.isAddContact
            ? ""
            : location.state.contactDetails.mobileNumber,
        name: "",
        teamToContacts: [],
        teams: [],
        stateCode:"",
        timezone:"",
    });
    const [contactAttJson, setContactAttJson] = useState("");
    const [collapse, setCollapse] = useState(false)
    const [jsonError, setJsonError] = useState(false);
    const [teamList, setTeamList] = useState([]);
    const [contTeamList, setContTeamList] = useState([]);
    const AddContact = "Add Contact";
    const UpdateContact = "Update Contact";

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        if (severity === "success") {
            navigate("/contacts", { replace: true });
        }
        setOpenSnackbar(false);
    };


    useEffect(() => {
        if (isAddContact) {
            setIsReadOnly(false);
            setContactTeams([]); // Set to an empty array when adding a new contact
            setAddTeam([]);
        } else if (userData.teamToContacts) {
            setIsReadOnly(true); 
            // Set selectedTeam to the teams associated with the contact when updating
            const associatedTeams = userData.teamToContacts.map((teamToContact) => teamToContact.teamId);
            setContactTeams(associatedTeams);
            let userPreferredTeam = {
                team: {
                    teamId: userInfo.teamId, 
                },
                actionType: "Add",
                users: [],
            };
            setAddTeam([userPreferredTeam]);
        }
    }, [isAddContact, userData.teamToContacts]);



    useEffect(() => {
        if (messageApiToken) {
            if (userInfo.teams) {
                setTeamList(userInfo.teams);
                setContTeamList(userInfo.role == "Admin" ? userInfo.allTeams : userInfo.teams);
            }
            const associatedTeams = userData.teamToContacts.map((teamToContact) => teamToContact.teamId);
            setTeams(associatedTeams);
        }
    }, [messageApiToken])
    useEffect(() => {
        if (messageApiToken) {
            if (!isAddContact) {
                setLoading(true);
                let requestBody = {
                    contactId: location.state.contactDetails.contactId,
                };

                InvokePostServiceCall(apiConfig.GET_CONTACTS, requestBody)
                    .then((data) => {
                        console.log(data.data, "!!!!!!!!!!!!!!!!!!!!!!! DAta");
                        let contactDetails = data.data.contactdetails[0].contact;
                        setUserData({
                            ...userData,
                            contactAttributes: contactDetails.contactAttributes,
                            contactId: contactDetails.contactId,
                            conversations: contactDetails.conversations,
                            createdBy: contactDetails.createdBy,
                            createdByName: contactDetails.createdByName,
                            createdByNavigation: contactDetails.createdByNavigation,
                            createdDate: moment(contactDetails.createdDate).local().format("LLL"),
                            email: contactDetails.email,
                            firstName: contactDetails.firstName,
                            lastModifiedBy: contactDetails.lastModifiedBy,
                            lastModifiedByName: contactDetails.lastModifiedByName,
                            lastModifiedByNavigation: contactDetails.lastModifiedByNavigation,
                            lastModifiedDate: moment(contactDetails.lastModifiedDate).local().format("LLL"),
                            lastName: contactDetails.lastName,
                            mobileNumber: contactDetails.mobileNumber,
                            name: contactDetails.name,
                            teamToContacts: contactDetails.teamToContacts,
                            teams: data.data.contactdetails[0].teams,
                            stateCode: contactDetails.stateCode,
                            timezone: contactDetails.timezone,
                        });
                        setContactAttJson(contactDetails.contactAttributes);
                        if (data.data.contactdetails[0].teams.length > 0) {
                            setTeams(data.data.contactdetails[0].teams[0].team.teamId);
                        }
                        setLoading(false);

                        var tempTeamsArr = []
                        data.data.contactdetails[0].teams.forEach((team) => {
                            debugger;

                            tempTeamsArr.push(team.team.teamId);
                        });

                        setContactTeams(tempTeamsArr);
                    })
                    .catch((error) => {
                        setSeverity("error");
                        setSnackbarMsg(error.message);
                        console.log(error.message);
                        setOpenSnackbar(true);
                        setLoading(false);
                    });
            }
        }
    }, [messageApiToken]);

    const handleChange = (event) => {
        setTeams(event.target.value);
    };
    const saveContact = () => {

        let chkmobile = false;
        let chkemail = false;
        const PHONE_REGEX = /^[+][0-9]{11}$/i
        let PHONE_US = /^\([0-9]{3}\) |[0-9]{3}-[0-9]{3}-[0-9]{4}$/i;
        const PHONE_INDIAN_REGEX = /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/i;
        const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!EMAIL_REGEX.test(userData.email)) {
            setEmailErr(true)
        }
        else {
            chkemail = true;
        }

        if (PHONE_REGEX.test(userData.mobileNumber)) {
            chkmobile = true;
        } else if (PHONE_US.test(userData.mobileNumber)) {
            chkmobile = true;
        } else if (PHONE_INDIAN_REGEX.test(userData.mobileNumber)) {
            chkmobile = true;
        }
        var tempTeamsArr = []
        contactTeams.forEach((teamId) => {
            let team = {
                team: {
                    teamId: teamId,
                },
                actionType: "Add",
            };
            tempTeamsArr.push(team);
        });
        if (chkmobile && chkemail) {
            let requestBody = {
                contactDetail: {
                    actionType: isAddContact ? "Add" : "Update",
                    contact: {
                        firstName: userData.firstName,
                        lastName: userData.lastName,
                        mobileNumber: userData.mobileNumber,
                        email: userData.email,
                        conversations: userData.conversations,
                        teamToContacts: userData.teamToContacts,
                        contactAttributes: userData.contactAttributes,
                        stateCode: userData.stateCode,
                        timezone: userData.timezone,
                        //teamId: selectedTeam,
                    },
                    // teamId: selectedTeam[0],
                    teams: tempTeamsArr,
                },
            };
            if (!isAddContact) {
                requestBody.contactDetail.contact.contactId = userData.contactId;
            }
            InvokePostServiceCall(apiConfig.SAVE_CONTACT, requestBody)
                .then((data) => {
                    setSeverity("success");
                    setSnackbarMsg(isAddContact ? "Contact Added Successfully" : "Contact Updated Successfully");
                    setOpenSnackbar(true);
                })
                .catch((err) => {
                    setSeverity("error");
                    setSnackbarMsg(err.message);
                    console.log(err, "Error Message");
                    setOpenSnackbar(true);
                });
        } else {
            setSeverity("error")
            if (!chkemail)
                setSnackbarMsg("Invalid Email Id")
            else
                setSnackbarMsg("Invalid Mobile Number")
            setOpenSnackbar(true);
        }
    };
    function startConversation() {
        debugger;
        setIsLoading(true);
        let requestBody = {
            conversation: {
                agentId: userId,
                customerPhoneNumber: userData.mobileNumber,
                conversationAttributes: `{ \"CustomerName\": \"${userData.firstName + " " + userData.lastName
                    }\",\"ContactId\": \"${userData.contactId}\"}`,
                source: "Manual",
            },

            contact: {
                FirstName: userData.firstName,
                LastName: userData.lastName,
                ContactAttributes: userData.contactAttributes,
            },
            TeamId: userInfo.teams && userInfo.teams.length == 1 ? userInfo.teams[0].teamId : selectedTeam
        };
        InvokePostServiceCall(apiConfig.START_CONVERSATION, requestBody)
            .then((data) => {
                setIsLoading(false);
                setSeverity("success");
                if (data.data.status === "FAILED") {
                    setSnackbarMsg(data.data.errorDescription)
                    setSeverity("warning");
                    setOpenSnackbar(true);
                    setOpen(false);
                } else {
                    navigate("/conversations");
                }
            })
            .catch((error) => {
                setSeverity("error");
                setSnackbarMsg(error.message);
                console.log(error);
                setIsLoading(false);
                setOpenSnackbar(true);
            });
    }
    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleTeamChange = (event) => {
        // Update the userData.teams state with the selected teamIds
        setSelectedTeam(event.target.value);
    };
    const handleTeamChangeSelect = (event) => {
        setContactTeams(event.target.value);
    }
    const tabList = [
        { label: "Contact", value: "1", displayOrder: 1 },
        { label: "Conversations", value: "2", displayOrder: 2 },
    ]
    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth="100%"
            >
                <DialogContent>
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="demo-simple-select-label">Team</InputLabel>
                        <Select
                            labelId="select-agent"
                            id="select-agent"
                            label="Team"
                            onChange={handleTeamChange}
                            value={selectedTeam}
                        >
                            {teamList?.map((a) => {
                                let badgeColor = "#b8bfb3";
                                return (
                                    <MenuItem key={a.teamId} value={a.teamId}>
                                        <div>
                                            <div>
                                                <div
                                                // style={{
                                                //     position: "relative",
                                                //     width: "10px",
                                                //     height: "10px",
                                                //     borderRadius: "6px",
                                                //     backgroundColor: badgeColor,
                                                //     display: "inline-block",
                                                // }}
                                                ></div>
                                                <span style={{ marginLeft: "10px" }}>
                                                    {a.teamName}
                                                </span>
                                            </div>

                                        </div>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            debugger;
                            if (selectedTeam) {
                                startConversation();
                            }
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <span>
                                <strong> Start</strong>
                            </span>
                        </div>
                    </Button>
                    <Button onClick={handleClose}>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <span>
                                <strong> Cancel</strong>
                            </span>
                        </div>
                    </Button>
                </DialogActions>
            </Dialog>
            <SnackBar
                openSnackbar={openSnackbar}
                handleCloseSnackbar={handleCloseSnackbar}
                severity={severity}
                userMessage={snackbarMsg}
            />
            <TabContext value={tabValue}>
                {!isAddContact &&
                    <CustomTabList tabList={tabList} showTabsCount={3} handleChangeTab={(event, selectedTabValue) => {
                        setTabValue(selectedTabValue)
                    }}></CustomTabList>
                }
                <TabPanel value="1">
                    <div style={{ marginLeft: 20, marginRight: 20 }}>
                        {loading && !isAddContact ? (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "15%",
                                }}
                            >
                                <CircularProgress />
                            </div>
                        ) : (
                            <>
                                <div>
                                    <Box sx={theme.customStyle.tableRow}>
                                        <h5>
                                            {!isAddContact
                                                ? `${userData.mobileNumber}- ${userData.firstName + " " + userData.lastName
                                                }`
                                                : AddContact}
                                        </h5>
                                        <div>
                                            {!isAddContact ? (
                                                <div style={{ position: "relative" }}>
                                                    <Tooltip title="Start Conversation">
                                                        <Button
                                                            sx={theme.customStyle.detailsModalbtn}
                                                            onClick={() => {
                                                                if (userInfo.teams && userInfo.teams.length == 1) {
                                                                    startConversation();
                                                                }
                                                                else if (userInfo.teams && userInfo.teams.length > 1){
                                                                    setOpen(true);
                                                                }
                                                                else {
                                                                    setSeverity("warning");
                                                                    setSnackbarMsg("Can't start a conversation as you are not part of any team!");
                                                                    setOpenSnackbar(true);
                                                                }
                                                            }}
                                                        >
                                                            <CommentIcon />
                                                        </Button>
                                                    </Tooltip>
                                                    {isLoading && (
                                                        <CircularProgress
                                                            size={60}
                                                            sx={{
                                                                color: "#106cc8"[10],
                                                                position: "absolute",
                                                                top: "50%",
                                                                left: "50%",
                                                                marginTop: "-30px",
                                                                marginLeft: "-33px",
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            ) : null}
                                        </div>
                                    </Box>
                                </div>
                                <Box sx={theme.customStyle.textRow}>
                                    <TextField
                                        margin="dense"
                                        value={userData.email}
                                        label="Email Id"
                                        required
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        id="updateUpadateContactemail"
                                        inputProps={{ //not show suggetions

                                            autoComplete: 'new-password',
                                            form: {
                                                autocomplete: 'off',
                                            },
                                        }}
                                        onChange={(e) => {
                                            setEmailErr(false)
                                            setUserData({ ...userData, email: e.target.value });
                                        }}
                                    />

                                    {/* {isAddContact ? (
              <Button
                size="small"
                disabled={lookup}
                onClick={() => {
                  handleLookup(userEmail);
                }}
                sx={styles.modalbtn02}
              >
                Look-Up
              </Button>
            ) : null} */}
                                </Box>
                                <Box sx={theme.customStyle.textRow}>
                                    <TextField
                                        margin="dense"
                                        // disabled={true}
                                        id="updateUpadateContactfirstName"
                                        value={userData.firstName}
                                        label="First Name"
                                        required
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{ //not show suggetions

                                            autoComplete: 'new-password',
                                            form: {
                                                autocomplete: 'off',
                                            },
                                        }}
                                        onChange={(e) => {
                                            setUserData({ ...userData, firstName: e.target.value });
                                        }}
                                    />
                                </Box>

                                <Box sx={theme.customStyle.textRow}>
                                    <TextField
                                        margin="dense"
                                        id="updateUpadateContactlastName"
                                        value={userData.lastName}
                                        label="Last Name"
                                        required
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{ //not show suggetions

                                            autoComplete: 'new-password',
                                            form: {
                                                autocomplete: 'off',
                                            },
                                        }}
                                        onChange={(e) => {
                                            setUserData({ ...userData, lastName: e.target.value });
                                        }}
                                    />
                                </Box>

                                <Box sx={theme.customStyle.textRow}>
                                    <TextField
                                        margin="dense"
                                        id="updateUpadateContactmobileNumber"
                                        value={userData.mobileNumber}
                                        label="Phone Number"
                                        required
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => {
                                            setUserData({
                                                ...userData,
                                                mobileNumber: e.target.value,
                                            });
                                        }}
                                        inputProps={{ //not show suggetions
                                            autoComplete: 'new-password',
                                            form: {
                                                autocomplete: 'off',
                                            },
                                        }}
                                    />
                                </Box>
                                    <Box sx={{ ...theme.customStyle.textRow, marginTop: 2.5 }}>
                                        <FormControl variant="standard" fullWidth margin="dense">
                                            <InputLabel shrink={true} required>
                                                Teams
                                            </InputLabel>
                                            <Select
                                                labelId="team-label"
                                                id="team-select"
                                                multiple
                                                value={contactTeams}
                                                onChange={handleTeamChangeSelect}
                                                disabled={isReadOnly}
                                                renderValue={(selected) => (
                                                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                                                        {selected.map((teamId) => {
                                                            const team = teamList.find((team) => team.teamId === teamId);
                                                            return team ? (
                                                                <Chip key={team.teamId} label={team.teamName} style={{ margin: 2 }} />
                                                            ) : null;
                                                        })}
                                                    </div>
                                                )}
                                            >
                                                {teamList.map((team) => (
                                                    <MenuItem key={team.teamId} value={team.teamId}>
                                                        {team.teamName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>


                                <Box sx={{ ...theme.customStyle.textRow, marginTop: 2.5 }}>
                                    <FormControl variant="standard" sx={{ width: "100%" }}>
                                        <InputLabel shrink={true} required>State</InputLabel>
                                        <Select
                                            onChange={(e) => {
                                                setUserData({ ...userData, stateCode: e.target.value });
                                            }}
                                            value={userData.stateCode}
                                            label="State"
                                            helperText="Please select State."
                                        >
                                            {states && states.map((option, index) => (
                                                <MenuItem key={index} value={option.code}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box sx={{ ...theme.customStyle.textRow, marginTop: 2.5 }}>
                                    <FormControl variant="standard" sx={{ width: "100%" }}>
                                        <InputLabel shrink={true} required>TimeZone</InputLabel>
                                        <Select
                                            onChange={(e) => {
                                                setUserData({ ...userData, timezone: e.target.value });
                                            }}
                                            value={userData.timezone}
                                            label="State"
                                            helperText="Please select TimeZone."
                                        >
                                            {timezones && timezones.map((option, index) => (
                                                <MenuItem key={index} value={option.name}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                    </FormControl>
                                </Box>
                                {/* <Box sx={theme.customStyle.textRow}>
                  <TextField
                    size="small"
                    margin="dense"
                    value={userData.contactAttributes}
                    label="Contact Attributes"
                    type="text"
                    fullWidth
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <Button
                    size="small"
                    onClick={() => {
                      setCollapse(!collapse);
                    }}
                    sx={{
                      ...theme.customStyle.modalbtn02,
                      height: "35px",
                      width: "50px",
                      position: "relative",
                      top: "6px",
                    }}
                  >
                    {collapse ? "close" : "Edit"}
                  </Button>
                </Box>
                <Collapse in={collapse}>
                  <div>
                    <Typography
                    variant="body2"
                    gutterBottom
                    sx={{
                      fontSize: "12px",
                      color: "#00000099",
                      fontWeight: 400,
                    }}
                  >
                    Contact Attributes
                  </Typography>
                    <JSONInput
                      placeholder={
                        contactAttJson !== ""
                          ? JSON.parse(contactAttJson)
                          : null
                      }
                      style={{
                        body: { fontSize: "14px" },
                        container: {
                          borderRadius: "10px",
                        },
                      }}
                      width="100%"
                      height="200px"
                      onChange={(e) => {
                        console.log(e, "JSONINPUT");
                        setUserData({ ...userData, contactAttributes: e.json });
                        if (e.error !== false) {
                          setJsonError(true);
                        } else {
                          setJsonError(false);
                        }
                      }}
                    />
                  </div>
                </Collapse> */}


                                {/*  Removed Team Dropdown By Mohit 02/12/2022 asked by srinath */}
                                {/* { <Box sx={{ ...theme.customStyle.textRow, marginTop: 3 }}>
                                    <Select
                                        id="standard-select-currency"
                                        multiple
                                        select
                                        label="Select"
                                        defaultValue={selectedTeam}
                                        fullWidth
                                        onChange={handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{ //not show suggetions
                                            autoComplete: 'new-password',
                                            form: {
                                                autocomplete: 'off',
                                            },
                                        }}
                                        helperText="Please select team."
                                        variant="standard"
                                    >
                                        {contTeamList && contTeamList.map((option, index) => (
                                           
                                            <MenuItem key={index} value={option.teamId}>
                                                {option.teamName}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                </Box>} */
                                }


                                {!isAddContact && (
                                    <>
                                        <Box sx={theme.customStyle.textRow}>
                                            <TextField
                                                disabled
                                                id="updateUpadateContactcreatedByName"
                                                margin="dense"
                                                value={`${userData.createdByName + " (" + userData.createdDate + ")"}`}
                                                label="Created By"
                                                type="text"
                                                fullWidth
                                                variant="standard"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{ //not show suggetions
                                                    autoComplete: 'new-password',
                                                    form: {
                                                        autocomplete: 'off',
                                                    },
                                                }}
                                            />
                                        </Box>
                                        <Box sx={theme.customStyle.textRow}>
                                            <TextField
                                                disabled
                                                id="updateUpadateContactlastModifiedByName"
                                                margin="dense"
                                                value={`${userData.lastModifiedByName + " (" + userData.lastModifiedDate + ")"}`}
                                                label="Modified By"
                                                type="text"
                                                fullWidth
                                                variant="standard"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{ //not show suggetions
                                                    autoComplete: 'new-password',
                                                    form: {
                                                        autocomplete: 'off',
                                                    },
                                                }}
                                            />
                                        </Box>
                                    </>
                                )}
                                <Button
                                    sx={theme.customStyle.bottomButton}
                                    variant="contained"
                                    onClick={() => {
                                        navigate("/contacts");
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    sx={{ ...theme.customStyle.bottomButton, marginLeft: 2 }}
                                    variant="contained"
                                    disabled={
                                        userData.email !== null && jsonError !== true
                                            ? !userData.email ? true : !userData.firstName ? true : !userData.lastName ? true : !userData.mobileNumber ? true : !userData.stateCode ? true : !userData.timezone ? true : false
                                            : true
                                    }
                                    onClick={() => {
                                        saveContact();
                                    }}
                                >
                                    {!isAddContact ? UpdateContact : AddContact}
                                </Button>
                            </>
                        )}
                    </div>
                </TabPanel>
                <TabPanel value="2" disabled={isAddContact ? true : false}>
                    <ChatHistory conversation={userData.mobileNumber} />
                </TabPanel>
            </TabContext>
        </>
    );
}