import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Button, CircularProgress, Divider } from "@mui/material";
import apiConfig from "../api/apiConfig";
import { InvokePostServiceCall } from "../api/serviceUtil";


function VisitorInfo(props) {
    const { setTitle, isOpen, setIsOpen, conversationId } = props;
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [visitorInfo, setVisitorInfo] = useState({});
    const getVisitorInfo = () => {
        setLoading(true); // set loading true before calling the api
        InvokePostServiceCall(apiConfig.GET_VISITOR_INFO_BY_CONVERSATION_ID, {
            ConversationId: conversationId
        })
            .then((data) => {
                setLoading(false);
                setVisitorInfo(data.data.visitorInfo ?? {});
            })
            .catch((error) => {
                setLoading(false);
                console.log(error, "error message");
            });
    }
{console.log("loaded visitor info")}
    return (
       
        <>
            <div
                style={{
                    ...theme.customStyle.infoListStyle,
                    paddingTop: "0px",
                    marginBottom: isOpen ? "10px" : "0px",
                }}
            >
                

                {!isOpen && (
                <><div style={theme.customStyle.infoListLabel}>
                    Visitor Info:
                </div>
                    <div>
                        <Button
                            size="small"
                            style={{ textTransform: "capitalize", float: "right" }}
                            onClick={() => {setIsOpen(true);
                                setTitle("Visitor Info");
                                getVisitorInfo();

                            }}
                            autoFocus
                        >
                            <span>
                                <strong> view </strong>
                            </span>
                        </Button>
                    </div>
                    </>
                )}
            </div>
            {isOpen && (
                <>
                    {
                        loading ? (
                            <Box sx={{ display: "flex", marginLeft: "40%" }} >
                                <CircularProgress />
                            </Box >
                        ) : null}
                        
                        
                        
                <div>
                    <div style={{ ...theme.customStyle.infoListStyle, paddingTop: "0px" }}>
                        <div style={theme.customStyle.infoListLabel}>Visitor Name:</div>
                        <div>{visitorInfo.visitorName}</div>
                    </div>
                    <Divider />
                    <div style={theme.customStyle.infoListStyle}>
                        <div style={theme.customStyle.infoListLabel}>Country:</div>
                        <div>{visitorInfo.country}</div>
                    </div>
                    <Divider />
                    <div style={theme.customStyle.infoListStyle}>
                        <div style={theme.customStyle.infoListLabel}>City:</div>
                         <div>{visitorInfo.city}</div>
                    </div>
                    <Divider />
                    <div style={theme.customStyle.infoListStyle}>
                        <div style={theme.customStyle.infoListLabel}>Device:</div>
                        <div>{visitorInfo.device}</div>
                    </div>
                    <Divider />
                    <div style={theme.customStyle.infoListStyle}>
                        <div style={theme.customStyle.infoListLabel}>Operating System:</div>
                         <div>{visitorInfo.operatingSystem}</div>
                    </div>
                    <Divider />
                    <div style={theme.customStyle.infoListStyle}>
                        <div style={theme.customStyle.infoListLabel}>Browser:</div>
                         <div>{visitorInfo.browser}</div>
                    </div>
                    <Divider />
                    <div style={theme.customStyle.infoListStyle}>
                        <div style={theme.customStyle.infoListLabel}>Referrer:</div>
                         <div>{visitorInfo.referrer}</div>
                    </div>
                    <Divider />
                    <div style={theme.customStyle.infoListStyle}>
                        <div style={theme.customStyle.infoListLabel}>IP Address:</div>
                        <div>{visitorInfo?.ipAddress}</div>
                    </div>
                    <Divider />
                    <Button
                        size="small"
                        style={{
                            textTransform: "capitalize",
                            float: "right",
                            marginTop: "10px",
                        }}
                        onClick={() =>{ 
                            setTitle("");
                            setIsOpen(false)}}
                        autoFocus
                    >
                        <span>
                            <strong> back</strong>
                        </span>
                    </Button>
                </div>
                </>)
                }

        </>
    );
}

export default VisitorInfo;
