import React, { useContext } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../AuthContext";

function LandingPage() {
    const navigate = useNavigate();
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 80px)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "60px",
            border: "1px solid #001433",
            borderRadius: "20px",
          }}
        >
          <h1 style={{ marginBottom: "20px" }}>
            Welcome to Click To Message Application
          </h1>

        </div>
      </div>
    </>
  );
}

export default LandingPage;
