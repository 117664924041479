/***************
* 11-11/2022 TextField:766=>disabled auto suggetion
***********/
import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  TextField,
  Button,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import { useLocation, useNavigate } from "react-router-dom";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import apiConfig from "../../api/apiConfig";
import { AuthContext } from "../../AuthContext";
import moment from "moment";
import SnackBar from "../../components/SnackBar";

function AddUpdatePhoneNumber() {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { messageApiToken } = useContext(AuthContext);
  const isAddPhoneNumber = location.state
    ? location.state.isAddPhoneNumber
    : true;
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [snackbarMsg, setSnackBarMsg] = useState("");
  const [phoneErr, setPhoneErr] = useState(false);
  const [descriptionErr, setDescriptionErr] = useState(false);
  const [dateErr, setDateErr] = useState({
    holidayDateErr: false

  });
  const [PhoneNumberData, setPhoneNumberData] = useState({
    id: "0",
    PhoneNumber: "",
    description: "",
    isActive: true,
  });
  const [modifiedBy, setModifiedBy] = useState({
    lastModifiedByName: "",
    lastModifiedDate: "",
  });
  const [createdBy, setCreatedBy] = useState({
    createdByName: "",
    createdDate: "",
  });

  useEffect(() => {
    if (!isAddPhoneNumber) {

      setLoading(true);
      if (messageApiToken) {
        InvokePostServiceCall(apiConfig.GET_WHITE_LISTED_PHONE_NUMBER, {
          Id:
            location.state.phoneNumberDetails.id,
        })
          .then((data) => {
            debugger;
            let WhitelistedPhoneNumberDetails = data.data.whitelistedPhoneNumberDetails[0];
            setPhoneNumberData({
              id:
                WhitelistedPhoneNumberDetails.whitelistedPhoneNumber.id,
              phoneNumber:
                WhitelistedPhoneNumberDetails.whitelistedPhoneNumber.phoneNumber,
              description: WhitelistedPhoneNumberDetails.whitelistedPhoneNumber.description,

              isActive: WhitelistedPhoneNumberDetails.whitelistedPhoneNumber.isActive,
            });
            setModifiedBy({
              lastModifiedByName:
                WhitelistedPhoneNumberDetails.whitelistedPhoneNumber.lastModifiedByName,
              lastModifiedDate: moment(
                WhitelistedPhoneNumberDetails.whitelistedPhoneNumber.lastModifiedDate
              )
                .local()
                .format("LLL"),
            });
            setCreatedBy({
              createdByName:
                WhitelistedPhoneNumberDetails.whitelistedPhoneNumber.createdByName,
              createdDate: moment(
                WhitelistedPhoneNumberDetails.whitelistedPhoneNumber.createdDate
              )
                .local()
                .format("LLL"),
            });
            setLoading(false);
          })
          .catch((error) => {
            setSnackBarMsg(error.message);
            setSeverity("error");
            setOpenSnackbar(true);
            setLoading(false);
            console.log(error, "Error Message");
          });
      }
    }
  }, [messageApiToken]);

  const handleAddUpdate = () => {
    let chkmobile = false;
    let chkDuplicatemobile = true;
    debugger;
    const PHONE_REGEX = /^[+][0-9]{11}$/i;
    let PHONE_US = /^\([0-9]{3}\) |[0-9]{3}-[0-9]{3}-[0-9]{4}$/i;
    const PHONE_INDIAN_REGEX = /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/i;
    // /^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[789]\d{9}|(\d[ -]?){10}\d$/i;

    if (PHONE_REGEX.test(PhoneNumberData.phoneNumber)) {
      chkmobile = true;
    } else if (PHONE_US.test(PhoneNumberData.phoneNumber)) {
      chkmobile = true;
    } else if (PHONE_INDIAN_REGEX.test(PhoneNumberData.phoneNumber)) {
      chkmobile = true;
    }
    if (!PhoneNumberData.description) {
      setDescriptionErr(true);
      return;
    }
    if (chkmobile) {

      setLoadingBtn(true);
      let requestBody = {
        WhitelistedPhoneNumber: {
          id: PhoneNumberData.id,
          phoneNumber: PhoneNumberData.phoneNumber,
          description: PhoneNumberData.description,
          isActive: PhoneNumberData.isActive,
        },
        actionType: isAddPhoneNumber ? "Add" : "Update",
      };

      InvokePostServiceCall(apiConfig.SAVE_WHITE_LISTED_PHONE_NUMBER, requestBody)
        .then((data) => {
          setLoadingBtn(false);
          if (data.data.status == 'Sucess') {
            setSnackBarMsg(
              isAddPhoneNumber
                ? "Phone Number Added Successfully"
                : "Phone Number Updated Successfully"
            );
            setSeverity("success");
            setOpenSnackbar(true);
          } else if (data.data.status == 'Failed') {
            setLoadingBtn(false);
            setSnackBarMsg(data.data.errorDescription);
            setSeverity("error");
            setOpenSnackbar(true);
          }
        })
        .catch((error) => {
          setLoadingBtn(false);
          setSnackBarMsg(error.message);
          setSeverity("error");
          setOpenSnackbar(true);
          console.log(error, "Error Message");
        });
    } else {
      setPhoneErr(true);
      setSnackBarMsg("Invalid Phone Number");
      setSeverity("error");
      setOpenSnackbar(true);
    }
  };

  const handleChange = (event) => {
    setPhoneNumberData({
      ...PhoneNumberData,
      isActive: event.target.checked,
    });
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    if (severity === "success") {
      navigate("/phoneNumbers", { replace: true });
    }
    setOpenSnackbar(false);
  };

  return (
    <>
      <SnackBar
        openSnackbar={openSnackbar}
        handleCloseSnackbar={handleCloseSnackbar}
        severity={severity}
        userMessage={snackbarMsg}
      />
      <div style={{ marginLeft: 20, marginRight: 20 }}>
        {loading && !isAddPhoneNumber ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15%",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <h2>
              {isAddPhoneNumber
                ? "Add White Listed Phone Number"
                : "Update White Listed Phone Number"}
            </h2>
            <Box sx={theme.customStyle.textRow}>
              <TextField
                size="small"
                margin="dense"
                label="Phone Number"
                type="text"
                required
                error={phoneErr}
                fullWidth
                value={PhoneNumberData.phoneNumber}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
                id="updateUserphoneNumber"
                inputProps={{ //not show suggetions
                  autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                onChange={(e) => {
                  setPhoneErr(false);
                  setPhoneNumberData({
                    ...PhoneNumberData,
                    phoneNumber: e.target.value,
                  });
                }}
                helperText={phoneErr ? "Invalid Mobile Number" : ""}
              />
            </Box>
              <Box sx={theme.customStyle.textRow}>
                <TextField
                  size="small"
                  margin="dense"
                  label="Description"
                  type="text"
                  required
                  error={descriptionErr}
                  fullWidth
                  value={PhoneNumberData.description}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id="updateDescription"
                  inputProps={{ //not show suggetions
                    autoComplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                  onChange={(e) => {
                    setDescriptionErr(false);
                    setPhoneNumberData({
                      ...PhoneNumberData,
                      description: e.target.value,
                    });
                  }}
                  helperText={descriptionErr ? "Please enter description" : ""}
                />
              </Box>

            <Box>
              <span>Is Active</span>
              <Checkbox
                checked={PhoneNumberData.isActive}
                onChange={handleChange}
              />
            </Box>
            {!isAddPhoneNumber && (
              <>
                <Box sx={theme.customStyle.textRow}>
                  <TextField
                    size="small"
                    margin="dense"
                    disabled
                    value={`${createdBy.createdByName +
                      " (" +
                      createdBy.createdDate +
                      ")"
                      }`}
                    label="Created By"
                    type="text"
                    fullWidth
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="updateUsercreatedBy"
                    inputProps={{ //not show suggetions
                      autoComplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                </Box>
                <Box sx={theme.customStyle.textRow}>
                  <TextField
                    size="small"
                    margin="dense"
                    disabled
                    value={`${modifiedBy.lastModifiedByName +
                      " (" +
                      modifiedBy.lastModifiedDate +
                      ")"
                      }`}
                    label="Modified By"
                    type="text"
                    fullWidth
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="updateUsermodifiedBy"
                    inputProps={{ //not show suggetions
                      autoComplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                </Box>
              </>
            )}
            <div style={{ marginTop: "20px", marginBottom: "20px" }}>
              <LoadingButton
                disabled={

                    PhoneNumberData.phoneNumber && PhoneNumberData.description
                    ? false
                    : true
                }
                loading={loadingBtn}
                variant="contained"
                onClick={() => {
                  handleAddUpdate();
                }}
              >
                {isAddPhoneNumber ? "Add" : "Update"}
              </LoadingButton>
              <Button
                sx={{ marginLeft: 2 }}
                variant="contained"
                onClick={() => {
                  navigate("/phoneNumbers");
                }}
              >
                Cancel
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default AddUpdatePhoneNumber;
