/***************
* 11-11/2022 TextField:766=>disabled auto suggetion
***********/
import React, { useEffect, useState } from "react";
import { Button, TextField, Box, Checkbox } from "@mui/material";
import apiConfig from "../../api/apiConfig";
import { useNavigate, useLocation } from "react-router-dom";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import SnackBar from "../../components/SnackBar";
import moment from "moment";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTheme } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 600,
    fontSize: "13px",
    // backgroundColor: theme.palette.common.white,
    // color: 'rgba(0, 0, 0, 0.87)',
    // boxShadow: theme.shadows[1],
  },
}));
export default function AddUpdateToken() {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const isAddToken = location.state ? location.state.isAddToken : true;
  const [errors, setErrors] = useState({
    tokenName: false,
    tableName: false,
    fieldName: false,
  });
  let errorText = "please remove spaces";

  const [formValues, setFormValues] = useState({
    tokenName: "",
    tableName: "",
    fieldName: "",
    isActive: isAddToken ? true : location.state.tokenDetails.isActive,
  });
  const [severity, setSeverity] = useState("success");
  const [modifiedBy, setModifiedBy] = useState({
    lastModifiedByName: "",
    lastModifiedDate: "",
  });
  const [createdBy, setCreatedBy] = useState({
    createdByName: "",
    createdDate: "",
  })

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    if (severity === "success") {
      navigate("/tokens", { replace: true });
    }
    setOpenSnackbar(false);
  };

  //get data related to the token selected from the table (works only for update token)
  useEffect(() => {
    if (!isAddToken) {
      let tokenDetails = location.state.tokenDetails;
      setFormValues({
        ...formValues,
        tokenName: tokenDetails.tokenName,
        tableName: tokenDetails.tableName,
        fieldName: tokenDetails.fieldName,
      });
      setModifiedBy({
        lastModifiedByName: tokenDetails.lastModifiedByName,
        lastModifiedDate: moment(tokenDetails.lastModifiedDateTime).local().format("LLL"),
      })
      setCreatedBy({
        createdByName: tokenDetails.createdByName,
        createdDate: moment(tokenDetails.createdDateTime).local().format("LLL")
      })
    }
  }, []);

  function handleValidations() {
    setErrors({
      ...errors,
      tokenName: formValues.tokenName?.indexOf(" ") >= 0 ? true : false,
      tableName: formValues.tableName?.indexOf(" ") >= 0 ? true : false,
      fieldName: formValues.fieldName?.indexOf(" ") >= 0 ? true : false,
    });
    if (
      formValues.tokenName?.indexOf(" ") >= 0 ||
      formValues.tableName?.indexOf(" ") >= 0 ||
      formValues.fieldName?.indexOf(" ") >= 0 ||
      formValues.tokenName === "" ||
      formValues.tableName === "" ||
      formValues.fieldName === ""
    ) {
      setSeverity("error");
      setOpenSnackbar(true);
      setSnackbarMsg("please enter correct data");
    } else {
      saveToken();
    }
  }

  const saveToken = () => {
    setBtnLoading(true)
    const requestBody = {
      actionType: isAddToken ? "Add" : "Update",
      tokens: [
        {
          tokenName: formValues.tokenName,
          filterType: null,
          isActive: formValues.isActive,
          tableName: formValues.tableName,
          fieldName: formValues.fieldName,
        },
      ],
    };
    if (!isAddToken) {
      requestBody.tokens[0].tokenId = location.state.tokenDetails.tokenId;
    }
    InvokePostServiceCall(apiConfig.SAVE_TOKEN, requestBody)
      .then((data) => {
        setBtnLoading(false)
        setSeverity("success")
        setOpenSnackbar(true);
        setSnackbarMsg(isAddToken ? "Token Added Successfully." : "Token Updated Successfully");
      })
      .catch((err) => {
        setBtnLoading(false)
        setSeverity("error")
        setSnackbarMsg(err.message);
        console.log(err, "error Message");
        setOpenSnackbar(true);
      });
  };
  const handleChange = (event) => {
    setFormValues({ ...formValues, isActive: event.target.checked });
  };
  return (
    <>
      <SnackBar
        openSnackbar={openSnackbar}
        handleCloseSnackbar={handleCloseSnackbar}
        severity={severity}
        userMessage={
          snackbarMsg
        }
      />
      <div style={{ marginLeft: 20, marginRight: 20 }}>
        <h2>{isAddToken ? "Add Token" : "Update Token"}</h2>
        <Box sx={theme.customStyle.textRow}>
          <TextField
            margin="dense"
            id="tokenName"
            label="Token Name *"
            type="text"
            fullWidth
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
            autoComplete="off"
            value={formValues.tokenName}
            error={errors.tokenName}
            helperText={errors.tokenName ? errorText : ""}
            onChange={(e) => {
              setFormValues({
                ...formValues,
                tokenName: e.target.value,
              });
              setErrors({
                ...errors, tokenName: false
              })
            }}
          />
        </Box>
        <Box sx={theme.customStyle.textRow}>
          <CustomWidthTooltip placement="top-start"
            title="If table is CRM table, Please enter table name as Context else enter actual table name. E.g. Context (for CRM objects), Users">
            <TextField
              margin="dense"
              id="tableName"
              label="Table Name *"
              type="text"
              fullWidth
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
              value={formValues.tableName}
              error={errors.tableName}
              helperText={errors.tableName ? errorText : ""}
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  tableName: e.target.value,
                });
                setErrors({
                  ...errors, tableName: false
                })
              }}
            />
          </CustomWidthTooltip>
        </Box>
        <Box sx={theme.customStyle.textRow}>
          <CustomWidthTooltip placement="top-start"
            title="If CRM field, Please prefix the table name delimited by DOT operator with fieldname.For Context eg.use Opportunity.Name,wcu_memberprofile.wcu_firstname.For Users eg. UserAttributes.nmls,FirstName">
            <TextField
              margin="dense"
              id="fieldName"
              label="Field Name *"
              type="text"
              fullWidth
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
              value={formValues.fieldName}
              error={errors.fieldName}
              helperText={errors.fieldName ? errorText : ""}
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  fieldName: e.target.value,
                });
                setErrors({
                  ...errors, fieldName: false
                })
              }}
            />
          </CustomWidthTooltip>
        </Box>

        <div>
          <span>Is Active</span>
          <Checkbox checked={formValues.isActive} onChange={handleChange} />
        </div>
        {!isAddToken && (
          <>
            <Box sx={theme.customStyle.textRow}>
              <TextField
                size="small"
                margin="dense"
                disabled
                variant="standard"
                value={`${createdBy.createdByName + " (" + createdBy.createdDate + ")"}`}
                fullWidth
                label="Created By"
                InputLabelProps={{
                  shrink: true,
                }}
                id="updateTokencreatedBy"
                autoComplete="off"
              />
            </Box>
            <Box sx={theme.customStyle.textRow}>
              <TextField
                size="small"
                margin="dense"
                disabled
                variant="standard"
                value={`${modifiedBy.lastModifiedByName + " (" + modifiedBy.lastModifiedDate + ")"}`}
                fullWidth
                label="Modified By"
                id="updateTokenmodifiedBy"
                autoComplete="off"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </>
        )}
        <LoadingButton
          loading={btnLoading}
          variant="contained"
          disabled={
            !formValues.tokenName ? true : !formValues.tableName ? true : !formValues.fieldName ? true : false
          }
          onClick={() => {
            handleValidations();
          }}
        >
          {isAddToken ? "Add Token" : "Update Token"}
        </LoadingButton>
        <LoadingButton
          variant="contained"
          sx={{ marginLeft: "10px" }}
          onClick={() => {
            navigate("/tokens");
          }}
        >
          Cancel
        </LoadingButton>
      </div>
    </>
  );
}

