import { configureStore } from '@reduxjs/toolkit';
import  conversationsReducer  from './slices/messageSlice';
import notificationsReducer from './slices/notificationSlice';


export const chatStore = configureStore({
    reducer: {
        conversations: conversationsReducer,
        notifications : notificationsReducer
    }
})
export default chatStore;