/***************
*03/17/2023 =>SUDAM B CHAVAN => TEXTING - 1092 - Show upload document page as mobile card view..
***********/

import * as React from 'react';
import {
    Box,
    Card,
    CardContent,
    MenuItem,
    TextField,
    Typography,
    IconButton,
    CircularProgress,
 Stack,
} from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export default function CardView(props) {
    console.log("sudam log card view ::",props)
    return (
        <Stack spacing={1} marginTop={"20px"}>
            {props.uploadedDocuments && props.uploadedDocuments.map((doc,index)=>{
        return(<Card>
            <CardContent >
                <Box sx={{ display: 'flex', alignItems: "center" }}>
                <TextField
                    size="small"
                    select
                    defaultValue={doc.type}
                    value={doc.type}
                    fullWidth
                    id="docType"
                    onChange={(e) => {
                        let tempArr = props.uploadedDocuments;
                        tempArr[doc.id - 1].type = e.target.value;
                        props.setUploadedDocuments([...tempArr]);
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="standard"
                >
                    {props.teamDocumentTypes?.map((item, index) => {
                        return (
                            <MenuItem value={item}>
                                {item}
                            </MenuItem>
                        );
                    })}
                </TextField>
                    <>
                        {
                            doc.status === "Completed"
                                ? <span style={{ marginLeft: "5px" }} title="Document uploaded" >
                                    <DoneIcon />
                                </span>
                                : doc.status === "uploading"
                                    ? <span style={{ marginLeft: "5px" }} title="Uploading document" >
                                        <CircularProgress size={15} />
                                    </span>
                                    : <>
                                        {doc.document ?<>
                                            {doc.status === "Failed"
                                                && <span style={{ marginLeft: "5px" }} title="Failed to upload document" >
                                                    <ErrorOutlineIcon color={"error"} size={15} />
                                                </span>}
                                                
                                            <IconButton
                                                title="Clear file"
                                                onClick={(e) => {
                                                    props.SetOpenConfirmationDialog(
                                                        {
                                                            open: true,
                                                            title: "Are you sure to clear uploaded file?",
                                                            mode: "clear",
                                                            id: doc.id
                                                        }
                                                    )

                                                }}
                                            >
                                                <ClearIcon />
                                            </IconButton> </>: <IconButton
                                                title="Upload file"
                                                component="label"
                                            >
                                                <OpenInBrowserIcon />
                                                <input hidden accept="image/*,.pdf,.doc,.docx" type="file" name={doc.name + "_" + doc.id} onChange={props.handleFileChange} />
                                            </IconButton>}

                                        <IconButton
                                            title="Delete row"
                                            onClick={(e) => {

                                                props.SetOpenConfirmationDialog(
                                                    {
                                                        open: true,
                                                        title: "Are you sure to delete row?",
                                                        mode: "delete",
                                                        id: doc.id
                                                    }
                                                )

                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </>
                        }
                    </>
                </Box>
                {doc.document && <Typography sx={{ fontSize: 14, wordWrap: 'break-word' }} color="text.secondary" gutterBottom>
                    {doc.name} - {doc.size +"MB"}
                </Typography>}
                
            </CardContent>
        </Card>)}) }
        </Stack>
    );
}