import './App.scss';
import React, { Component } from 'react';
import Room from './Room';
import queryString from 'query-string'

const { connect } = require('twilio-video');


class VideoApp extends Component {
    constructor(props) {
        super(props)
        this.inputRef = React.createRef();
        var name = this.getQueryVariable('name');
        this.state = {
            identity: name != false ? name.split('%')[0]:'',
            room: null,
            customerView: false,
            joinUrl: "",
            copyStatus : 'Click To Copy'
        }
        

        this.joinRoom = this.joinRoom.bind(this);
        this.returnToLobby = this.returnToLobby.bind(this);
        this.updateIdentity = this.updateIdentity.bind(this);
        this.removePlaceholderText = this.removePlaceholderText.bind(this);
    }


    async joinRoom() {
        try {
            var value = this.getQueryVariable('room');
            var roomName = 'default';
            if (value != false) {
                roomName = value;
                this.state.customerView = true;

            }           

            else {
                this.setState({
                    joinUrl: window.location + '?room=' + roomName

                });
            }
          
           
            
            
            const response = await fetch(`https://messageservicesapidev.azurewebsites.net/Auth/getTwilioToken?identity=${this.state.identity}&room=${roomName}`);
            const data = await response.json();
            const room = await connect(data.token, {
                name: roomName,
                audio: true,
                video: true
            });

            this.setState({ room: room });
        } catch (err) {
            console.log(err);
        }
    }

    getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    console.log(query)//"app=article&act=news_content&aid=160990"
    var vars = query.split("&");
    console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
        if (pair[0] == variable) { return pair[1]; }
    }
    return (false);
}

   

    returnToLobby() {
        this.setState({ room: null });
    }
    removePlaceholderText() {
        this.inputRef.current.placeholder = '';
    }
    copyToClipboard = (e) => {
        var textField = document.createElement('textarea');
        textField.innerText = this.state.joinUrl;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        this.setState({
            copyStatus : 'Copied!'
        }); 

    }

    updateIdentity(event) {
        this.setState({
            identity: event.target.value,

        });
    }

    render() {
        const disabled = this.state.identity === '' ? true : false;
        return (
            <div className="appVideoChat">
                {
                    this.state.room === null
                        ? <div className="lobby">
                            <input className= "inputVideoChat"
                                value={this.state.identity}
                                onChange={this.updateIdentity}
                                ref={this.inputRef}
                                onClick={this.removePlaceholderText}
                                placeholder="What's your name?" />
                            <button className="buttonVideoChat" disabled={disabled} onClick={this.joinRoom}>Start</button>
                        </div>
                        :<div> <Room returnToLobby={this.returnToLobby} room={this.state.room} />
                            <div>                {
                                this.state.customerView == false ? <div style={{ marginTop: "30px", marginLeft: "10px"  }}><label>
                                    Copy this url and send it to customer:
                                    <input className="inputVideoChat"
                                        value={this.state.joinUrl} style={{ width: "300px" }} />
                                    <button className="videoVideoChat" onClick={this.copyToClipboard} style={{ display: "inherit", marginLeft:"10px" }} >{this.state.copyStatus}</button>
                                  
                                </label>
                                    
                               </div> : <></>
                }
                            </div>
                            </div>

                }
               
                    
                

            </div>





        );
    }

}

export default VideoApp;
