/***************
* 03-17-2023 => RAJESH => Implemented the attachments for webClient
* 04-14-2023 => Sudam B Chavan =>TEXTING-1085 - Auto Response message when agent not available
* 04-24-2023 => ONKAR =>TEXTING-1115 -Webchat UI support in mobile view issues fixed.
* 04-25-2023 => ONKAR =>TEXTING-1115 - textfield top border issue fixed.
* 04-27-2023 => ONKAR =>TEXTING-1229 - WEB_CHAT: Attachment icon in Web Chat document upload preview screen should be reduced.
* 04-28-2023 => ONKAR =>TEXTING-1234 - Refactor the web Chat code.
* 04-28-2023 => ONKAR =>TEXTING-1225 - webChat mobile zoom in issue fixed.
* 04-28-2023 => ONKAR =>TEXTING-1212 - Non business hours screen logic added.
* 05-01-2023 => SUDAM B CHAVAN =>TEXTING-1227 - In download chat, Email chat virtual agent to be shown as Bot instead of agent for chat bot.    
* 05-01-2023 => ONKAR =>Texting-1244 - Bot prompts (Mortgage, Chat with agent, etc.) are not disappearing when bot conversation is auto closed.
* 05-08-2023 => SUDAM B CHAVAN =>Texting-1220 - Sensitive info restriction should be implemented for Web Chat
* 05-10-2023 => ONKAR => Texting-1243 When member is in different tab and when agent send the message, then message is shown as seen issue fixed.
* 05-10-2023 => ONKAR => When Chat UI is minimized and Member is in another tab.Agent unread messages are updating to read, when member redirects to the web chat tab. -fixed
* 05-15-2023 => ONKAR => Texting-1267 Single Tick (Message delivered Status) is not showing for uploaded document on Member UI.
* 05-18-2023 => ONKAR => Texting-1248 Show new Message notification on  the webchat parent site.
* 05-18-2023 => SUDAM B CHAVAN => TEXTING-1272 - Implement the webtranscript download with base64 formate
* 05-23-2023 => ONKAR => TEXTING-1284 - If document is uploaded when chat with bot, only single tick is showing for messages.
* 05-25-2023 => SUDAM B CHAVAN => TEXTING-1285 - When uploading the documents, Document uploaded message is covering up instead of moving to bottom in conversation page.
* 05-25-2023 => ONKAR => TEXTING-1277 - when browser reconnect, outbound and inbound messages are not working on Chat UI.
* 05-26-2023 => SUDAM B CHAVAN => For the IOS issues No 8, add close action to hide the action speed dial on email/download attachment is pressed
* 05-29-2023 => ONKAR => Texting-1290 In missed conversation, Single Tick is showing for agent unavailable system message on Agent UI - Not consistent.
* 05-31-2023 => ONKAR => Texting-1308 IOS - Prompts, and auto response messages are overlapping with input message box.
* 06-01-2023 => ONKAR => Texting-1307 If conversation is auto closed when member UI is minimized state, Maximizing the UI is showing start chat page instead of conversation closed page.
* 06-01-2023 => ONKAR => show queue position and wait time after refresh (mobile issue).
* 06-01-2023 => ONKAR => Texting-1310 IOS- When minimizing the chat and opening the chat, the member messages are shown as unread and inbound messages from agent are not shown in member chat page.
* 06-02-2023 => ONKAR => Texting-1309 IOS, Android- Multiple chat bot messages without prompts when minimizing chat and reopening.
* 06-02-2023 => SUDAM B CHAVAN => TEXTING-1315 => Android, IOS - Conversation auto closed message is showing instead of conversation ended by agent message.
* 06-05-2023 => ONKAR => Texting-1308 IOS - Prompts, and auto response messages are overlapping with input message box.
* 06-06-2023 => SUDAM B CHAVAN => TEXTING-1311 => Android - Auto close timer is disappearing from conversation page.
* 06-07-2023 => ONKAR => Moved getOs logic to parent component.
* 06-07-2023 => SUDAM B CHAVAN => TEXTING-1260 => Webchat Chatbot is not launching after conversation is started.
* 06-09-2023 => ONKAR => TEXTING-1277 => when browser reconnect, outbound and inbound messages are not working on Chat UI.(calling getwebchatmessages api when socket reconnects).
* 06-09-2023 => ONKAR => TEXTING-1325 => WebChat - Auto Timer should start only when the conversation is assigned to user
* 06-20-2023 => SUDAM B CHAVAN => TEXTING-1083 - webChat - Agent/Member typing preview
* 06-27-2023 => ONKAR => TEXTING-1372 Bot avatar should persist for bot messages after member connected with agent.
*06-30-2023 => ONKAR => TEXTING-1370 Show information message to member when agent is assigned to webchat
*07-10-2023 => ONKAR => TEXTING-1371 Take member details from parent site to start the conversation.
*07-10-2023 => PRABHAT => TEXTING-1437 Warning popup on customer close of chat.
*07-19-2023 => SUDAM B CHAVAN =>TEXTING-1414 - Banking avatar is not showing for first agent message after conversation is assigned to agent.
*07-21-2023 => ONKAR =>TEXTING-1441 - After chat with bot conversation is assigned to the agent. Reloading the Member UI is showing bot responses for customer messages instead of delivering to Agent.
*07-26-2023 => ONKAR => TEXTING-1463 Animated pic for the bot.
*07-27-2023 => ONKAR =>TEXTING-1373 - Client Requirement: Agent image should be displayed instead of company logo based on config.
*07-27-2023 => ONKAR =>TEXTING-1442 - Show Agent Wait Time and Queue Position in a Card instead of inline messages
*07-28-2023 => ONKAR =>TEXTING-1443 - Audio Message Notifications to the Client
*07-28-2023 => ONKAR =>TEXTING-1504 - Modification of Queue message card on Member UI.
*07-28-2023 => ONKAR =>TEXTING-1501 - Queue message card is covering up the last message in the webchat conversation page.
*08-01-2023 => ONKAR =>TEXTING-1502 - Web chat name and logo logic changes.
*08-04-2023 => ONKAR =>TEXTING-1494 - Webchat ADA testing Issues.
*08-07-2023 => ONKAR => Texting-1543 Refactor webChat UI with new JSON Response.
*08-08-2023 => ONKAR =>TEXTING-1494 - Webchat ADA testing Issues.
*08-09-2023 => ONKAR =>TEXTING-1532 - Queue message card is overlapping with message bubble in the webchat conversation page.
*08-09-2023 => ONKAR => Texting-1544 webChat UI - Get all the historical messages inline for the Authenticated webChat client conversation.
*08-09-2023 => ONKAR => Texting-1564 Info Card Text Color config is applying only for queue position message only.
*08-10-2023 => ONKAR => Texting-1563 When webchat UI is minimized, New message notification is not showing on start chat icon.
*08-14-2023 => SUDAM B CHAVAN =>TEXTING-1571 - For SMS conversations Auto close on server side is not happening.
*08-17-2023 => PRABAHT =>TEXTING-1601 - Webchat: Confirmation message not required when conversation is closed on click of close button in member UI.
*08-18-2023 => PRABAHT =>TEXTING-1595 - Webchat conversation: Input message box is overlapping with member message.
*08-18-2023 => PRABAHT =>TEXTING-1598 - Webchat: Info message format should be in sync b/w Queue Position and Conversation Closed message.
*08-22-2023 => SUDAM B CHAVAN => TEXTING - 1600 - Conversation assignment / reassignment
*08-23-2023 => PRABHAT =>Texting-1605 - WebChat buttons visibility
*08-26-2023 => SUDAM B CHAVAN => TEXTING-1623 - Webchat - Assignment info card observations.
*08-28-2023 => SUDAM B CHAVAN => TEXTING-1615 - Webchat - Client side close observations on reload of Webchat UI
*08-29-2023 => SUDAM B CHAVAN => TEXTING-1623 - Webchat - Assignment info card observations.
*08-31-2023 => ONKAR => TEXTING-1576 - Webchat: Ability for member to change font size.
*08-31-2023 => SUDAM B CHAVAN => TEXTING - 1634 - Standardize overall Webchat look and feel
*09-01-2023 => SUDAM B CHAVAN =>TEXTING-1648 - Webchat - Assigning the Agent card is not showing for webchat conversation without Bot.
*09-04-2023 => ONKAR => TEXTING-1576 - Webchat: Ability for member to change font size.
*09-06-2023 => ONKAR => TEXTING-1651 - Minimize and Maximize the Member UI is not moving to bottom of the conversation page..
*09-06-2023 => ONKAR => TEXTING-1635 - Conversation History Enhancements.
*09-11-2023 => ONKAR =>TEXTING-1537 - When webchat conversation is assigned between agents, latest agent image is updating for all agent messages after member UI refresh.
*09-18-2023 => ONKAR =>TEXTING-1700 - Webchat layout changes.
*09-21-2023 => ONKAR =>TEXTING-1700 - Webchat layout changes.
*10-09-2023 => ONKAR =>TEXTING-1752 - Webchat - New incoming messages are not updating to read automatically when webchat conversation page screen is Active.
*10-13-2023 => ONKAR =>TEXTING-1752 - Webchat - New incoming messages are not updating to read automatically when webchat conversation page screen is Active.
*10-13-2023 => ONKAR =>TEXTING-1744 - Web chat layouts pending observations.
*10-17-2023 => ONKAR =>TEXTING-1803 - Auto close timer of inactive webchat conversation is getting struck when browser tab is not active.
*10-18-2023 => ONKAR =>TEXTING-1752 - Webchat - New incoming messages are not updating to read automatically when webchat conversation page screen is Active.
*10-26-2023 => ONKAR =>TEXTING-1822 - Mobile browser - Floating date and move to bottom buttons are not removing from conversation page.
*11-03-2023 => SUDAM B CHAVAN =>TEXTING-1833 - For chat without bot conversation - Assigning an agent card is not showing when conversation request is routing.
*10-26-2023 => ONKAR =>Texting-1823   Mobile browser - if minimize and maximize UI then webchat conversation is auto closing immediately after receiving the member message. 
*11-08-2023 => ONKAR =>Texting-1845   0: Webchat - When conversation is connected and still active with live agent, sending the 'Agent' as text message is creating another inbound conversation. 
*11-10-2023 => ONKAR =>Texting-1846   0: Auto close timer is getting struck on webchat UI when browser is minimized. 
*11-10-2023 => SUDAM B CHAVAN =>TEXTING-1849 - Webchat - On refresh, assigning an agent card is not showing on member UI.
*11-13-2023 => ONKAR =>TEXTING-1851 - On refresh member UI, muted option is updating to unmute.
*11-24-2023 => ONKAR => Removed settings and email from session storage.
*11-28-2023 => ONKAR => Texting-1877 Android - webchat application observations.
                         2.If webchat conversation is ended when member UI is in minimized state then maximizing member UI is showing loader only.

*11-28-2023 => ONKAR => Texting-1876 Chat- Session Continuity Across Tabs & Devices                         7.After Minimize and maximized the member UI is resetting the selected font size and mute options to default.           
*11-29-2023 => ONKAR => Texting-1877 Android - webchat application observations.
                         2.If webchat conversation is ended when member UI is in minimized state then maximizing member UI is showing loader only.  
*12-01-2023 => ONKAR => Texting -1876 Chat- Session Continuity Across Tabs & Devices
                         1.When chatting with bot, welcome bot message is showing every time when opening the chat in different tabs.
                         2.Messages sent from one member tab is not showing sent messages in all the remaining tabs.  
                         4.Ending the conversation from member in one tab is not closing the conversation in all the tabs.
 *12-01-2023 => SUDAM B CHAVAN =>TEXTING-1881- Chat Survey Webchat UI
 *12-04-2023 => ONKAR =>TEXTING-1887 - Session expired popup is coming automatically once conversation is auto closed due to inactivity.
 *12-04-2023 => SUDAM B CHAVAN =>TEXTING-1896 Survey: Member UI, Admin UI pending observations.
 *12-05-2023 => ONKAR => Texting -1876 Chat- Session Continuity Across Tabs & Devices
                         1.When chatting with bot, welcome bot message is showing every time when opening the chat in different tabs.
 *12-05-2023 => ONKAR =>TEXTING-1899 - Duplicate member message is showing along with bot welcome message.
 *12-07-2023 => SUDAM B CHAVAN =>TEXTING-1896 - 0: Survey: Member UI, Admin UI pending observations.
 *12-12-2023 => SUDAM B CHAVAN => chat window is not closing when survey is disabled and conv ended by member
 *12-12-2023 => SUDAM B CHAVAN => TEXTING-1896 0: Survey: Member UI, Admin UI new observations.
 *12-13-2023 => SUDAM B CHAVAN => TEXTING-1903 Multiple clicks on bot prompts is creating multiple messages.
 *12-15-2023 => SUDAM B CHAVAN => TEXTING-1896 0: Survey: Member UI, Admin UI pending observations.
 *12-18-2023 => ONKAR =>TEXTING-1890 - 0: Webchat Skip initial form for Authenticated Members.
 *12-18-2023 => ONKAR =>TEXTING-1869 - Only loader is showing on member UI when webchat conversation session is expired
 *12-28-2023 => ONKAR =>TEXTING-1869 - Only loader is showing on member UI when webchat conversation session is expired(generate new wsURL)
 *01-02-2024 => ONKAR =>TEXTING-1890 - 0: Webchat Skip initial form for Authenticated Members.
                                       1.Only welcome message is showing for some time on conversation page before loading the previous history.
                                       3.Assigning an agent card is showing along with hardcoded welcome message.
                                       5.Conversation is auto closing due to inactivity when stay idle.
                                       6.Not able to close the webchat member UI.
*01-05-2023 => ONKAR =>TEXTING-1869 - Only loader is showing on member UI when webchat conversation session is expired(generate new wsURL)
*01-05-2024 => ONKAR =>TEXTING-1890 - 0: Webchat Skip initial form for Authenticated Members.
                                      5.Conversation is auto closing due to inactivity when stay idle.
*01-16-2024 => ONKAR =>TEXTING-1947 - Start webchat conversation with chatbot observations.
*01-16-2024 => ONKAR =>TEXTING-1927 - Session continuity: Disable previous sessions when new window is opened for authanticated conversation.
*01-22-2024 => Texting-1927 Session continuity: Disable previous sessions when new window is opened for authanticated conversation.
               1. Duplicate Chat Bot welcome message is showing on every new chat session.
*02-14-2024 => SUDAM B CHAVAN => Chat bot welcome message is not coming until member sends the message for authanticated conversation with chat bot
*02-19-2024 =>   1.Auto close due to inactivity card is not coming when Member continues chat in different device/browser after agent’s message.
*                2.Able to see Attachments option on Authenticated chat and able to upload document before assigning chat to agent. Once chat is assigned to some agent, agent can see the document with refresh icon. But on click of it, its not changing to download. - Disable attachment icon until chat connected to an agent
*02-20-2024 => SUDAM B CHAVAN => Able to see Attachments option on Authenticated chat and able to upload document before assigning chat to agent. Once chat is assigned to some agent, agent can see the document with refresh icon. But on click of it, its not changing to download. - Disable attachment icon until chat connected to an agent
*02-21-2024 => ONKAR => Websocket connection status should be closed on end webchat conversation.
*02-23-2024 => ONKAR =>  Minimze and maximize the member UI and stay idle when chatting with bot is auto closing the conversation.
*02-23-2024 => SUDAM B CHAVAN => Typing Preview should be shown on member UI when chat bot response is creating
*02-26-2024 => ONKAR => Android, mobile - Customer Message is not showing as sent on member UI until refresh when network is disconnected and reconnected
*02-26-2024 => ONKAR => Typing Preview should be shown on member UI when chat bot response is creating
*02-27-2024 => ONKAR => Android, mobile - Customer Message is not showing as sent on member UI until refresh when network is disconnected and reconnected
*02-28-2024 => ONKAR => Android, mobile - Customer Message is not showing as sent on member UI until refresh when network is disconnected and reconnected
*03-01-2024 => ONKAR => Once Out of business hrs ends, Reloading the member UI is showing  conversation page with assigning an agent card only.
*03-08-2024 => SUDAM B CHAVAN => Changed related to handle multiple continuous msg's from bot
*03-13-2024 => RAMAKANT => Reduced spacing between options and chat with agent option
*03-15-2024 => SUDAM B CHAVAN => Chat Bot - If new session is opened for authenticated webchat conversation, Bot welcome message with prompts are showing on every new session.
*03-20-2024 => ONKAR => Grey loader screen issue on Member UI.
*03-20-2024 => SUDAM B CHAVAN =>Bot response message with prompts are not coming on start unauthanticated webchat conversation.
*03-20-2024 => ONKAR => Welcome message is not coming on start authanticated webchat conversation with chat Bot 
*03-22-2024 => RAMAKANT => Attachments option is not available on Authenticated Member issue and Auto close due to inactivity card is not coming when Member continues chat in different device/browser after agent’s message.
*03-28-2024 => RAMAKANT => Webchat - On webchat new session in different tab, Refreshing the member UI is creating duplicate messages - Not consistent.
*03-29-2024 => SUDAM B CHAVAN => When click on bot prompt in new session - Welcome message is coming with the bot response
*04-09-2024 => SUDAM B CHAVAN => changes related to Implement jwt Token for webchat services
***********/
// Importing required modules and components
import React, { useState, useEffect, useRef } from "react";
import ChatMessage from "./ChatMessage";
import SendIcon from "@mui/icons-material/Send";
import apiConfig from "../../api/apiConfig";
import { styled, useTheme } from "@mui/material/styles";
import MinimizeIcon from "@mui/icons-material/Minimize";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { clearInterval, setInterval } from 'worker-timers';
import { ReactComponent as AgentConnected } from './assets/AgentConnected.svg'
import {
    Grid,
    Paper,
    Box,
    InputAdornment,
    IconButton,
    TextField,
    Tooltip,
    Chip,
    Avatar,
    CircularProgress,
    Typography,
    Backdrop,
    Stack,
    Snackbar,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    Button,
    ListItemIcon,
} from "@mui/material";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import EmailIcon from "@mui/icons-material/Email";
import DownloadIcon from "@mui/icons-material/Download";
import { SendNotificationToParent } from "./ChatUtils"
import MuiAlert from "@mui/material/Alert";
import { InvokePostServiceCallWithoutToken, InvokePostWebChatServiceCall } from "../../api/serviceUtil";
import AttachDocumetsDialog from "./components/AttachDocumetsDialog";
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import NotificationSound from "../../assets/NewConversation.wav"
import WebChatInfoCard from "./WebChatInfoCard";
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
// Defining a forwardRef function - Alert
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Defining the Chat component using a functional component and hooks

function Chat(props) {
    // Declaring state variables
    const {
        token,
        textSize,
        settings: rawtheamSettingStrObj,
        muteNotifications,
        handleMuteUnmuteNotifications,
        setCustomAttributes,
        startConvWithoutDesc,
        updatecustomermessage,
        startConversation,
        isAssignedToAgent,
        setIsAssignedToAgent,
        reloadChatCompOnStartChatWithoutBot,
        pvaBotDetailsAttribute,
        isMobileDevice,
        isShowNonBusinessHoursMsg
    } = props;
    // const [assignedToAgent, setAssignedToAgent] = useState(isAssignedToAgent);
    const AgentMessage = "AgentMessage";
    const CustomerMessage = "CustomerMessage";
    const ConversationEndedByMember = "ConversationEndedByMember"
    const MessageRead = "MessageRead";
    const Notification = "Notification";
    const WaitTime = "WaitTime";
    const ConversationEndedByAgent = "ConversationEndedByAgent";
    const ConversationEndedBySystem = "ConversationEndedBySystem";
    const ConversationAssignedToAgent = "ConversationAssignedToAgent";
    const OutBoundBotMessage = "OutBoundBotMessage";
    const Agent = true;
    const Member = false;
    const IntervalId = "intervalId";
    const ConversationId = "conversationid";
    const TypingPreviewIn = "TypingPreviewIn";
    const TypingPreviewOut = "TypingPreviewOut";
    const pvaBotDetails = "pvaBotDetails";
    const SurveyCreated = "SurveyCreated";
    const ConversationStartedOnNewWindow = "ConversationStartedOnNewWindow";


    const theme = useTheme();
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const [open, setOpen] = React.useState(false);
    const [docName, setDocName] = useState("");
    const [document, setDocument] = useState("");
    const [loading, setLoading] = useState(false);
    const [msgAutoSuggestions, setMsgAutoSuggestions] = useState([]);
    const [theamSettingsStrObj, setTheamSettingsStrObj] = useState(rawtheamSettingStrObj);
    const [chatCustomStyleCss, setChatCustomStyleCss] = useState(rawtheamSettingStrObj?.customStyleCss ? JSON.parse(rawtheamSettingStrObj?.customStyleCss):{});
    const [botAvatar, setBotAvatar] = useState(rawtheamSettingStrObj?.enableChatBot === "Y" ? true : false);
    const [dimensions, setDimension] = useState(window.innerWidth);
    const [isMobileView, setIsMobileView] = useState(false);
    const [debug, setDebug] = useState(false);
    const [opens, setOpens] = React.useState(false);
    const [isSessionExpired, setIsSessionExpired] = useState(props.isSessionExpired)
    const [showAgentAssignMsg, setShowAgentAssignMsg] = useState(props.showAgentAssignMsg)
    const [showSnackBar, setShowSnackBar] = useState({
        open: false,
        msg: "",
        severity: "success",
    });
    const [waitTimeObj, setWaitTimeObj] = useState({
        show: false,
        userQueueCount: 0,
        convAvgWaittimeInSec: 0,
        message: "",
    });
    const [showAutoCloseMessage, setShowAutoCloseMessage] = useState({
        show: false,
        msgWithPlaceholder: "",
        closeTimeInMin: 0,
        idleTimeInMin: 0,
    });
    const [autoClosetimmer, setAutoClosetimmer] = useState({
        min: 0,
        sec: 0,
    });
    const [showDialogtoReconnect, setShowDialogtoReconnect] = useState(false);
    const [showDialogtoReconnectMsg, setShowDialogtoReconnectMsg] = useState("");
    const [isConversationEnded, setIsConversationEnded] = useState("");
    const [showNonBusinessHoursMsg, setShowNonBusinessHoursMsg] = useState(isShowNonBusinessHoursMsg);
    const [isSocketConnected, setIsSocketConnected] = useState(true);
    const [openSpeedDial, setOpenSpeedDial] = useState(false);
    const [timeOutId, setTimeOutId] = useState(0);
    const [showTypingPreview, setShowTypingPreview] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorChatOptionsEl, setAnchorChatOptionsEl] = React.useState(null);
    const openFontSizeMenu = Boolean(anchorEl);
    const openChatOptionsMenu = Boolean(anchorChatOptionsEl);
    const [bottom, setBottom] = useState(false);
    const [isSurveyEnabled, setIsSurveyEnabled] = useState(false);
    const [showSurveyDialog, setShowSurveyDialog] = useState(false);
    const [endingConversation, setEndingConversation] = useState(false);
    const [surveyId, setSurveyId] = useState("");
    const [disabledAutoSuggestionChip, setDisabledAutoSuggestionChip] = useState(null);
    const [showChatWithAgentBtn, setShowChatWithAgentBtn] = useState(false);
    const [showConvStartedOnNewWindowDialog, setShowConvStartedOnNewWindow] = useState(false);
    const [sendMessageBtnLoading, setSendMessageBtnLoading] = useState(false);
    const [disableLoadingForChatWithoutBot, setDisableLoadingForChatWithoutBot] = useState(false);
    var wSocket = {};
    // Creating a reference object using useRef hook and initializing it to null
    const messagesEndRef = useRef(null);

    const UpdateConversationAsRead = (conversationId) => {
        if (!conversationId)//if conversation id is not available
        {
            console.log("Conversation id is not available", conversationId)
            return;
        }
        let messagesStr = sessionStorage.getItem("messages");//get all the messages from sessionStorage
        let messageList = messagesStr ? JSON.parse(messagesStr) : []
        if (messageList.length) {
            let unreadMsgIndex = messageList.findIndex((item) => item.status === "delivered" && item.isAgentMessage === true)
            if (unreadMsgIndex > -1) { //if messageList contains any unread message then call api.
                let requestBody = {
                    isCallFromCustomer: true,
                    conversationId: conversationId
                };
                InvokePostWebChatServiceCall(apiConfig.UPDATE_CONVERSATION_AS_READ, requestBody)
                    .then((res) => {
                        let messages = [...messageList]
                        for (let i = 0; i < messages.length; i++) {
                            //update delivered messages to seen in object.
                            if (messages[i].isAgentMessage === true && messages[i].status === 'delivered') {
                                messages[i].status = 'seen'
                            }
                        }
                        let msgStr = JSON.stringify(messages);
                        sessionStorage.setItem("messages", msgStr);
                        setMessages([...messageList])
                    })
                    .catch((err) => {
                        console.log(err, "Error Message", "UpdateConversationAsRead");
                    });
            }
        }
    }
    //remove conversation assigning loader
    const removeAssigningAgentLoaded = () => {
        setShowAgentAssignMsg(false);
        sessionStorage.removeItem("showAgentAssignMsg");
    }
    ////Adding conversation assigning loader
    const showAssigningAgentLoaded = () => {
        setShowAgentAssignMsg(true);
        sessionStorage.setItem("showAgentAssignMsg", true);
    }

    // Function to retrieve the current conversationId from sessionStorage
    const getConversationId = () => {
        // Retrieve the conversationId from sessionStorage
        let conversationId = sessionStorage.getItem(ConversationId);

        // Check if conversationId exists in sessionStorage
        if (conversationId) {
            return conversationId; // Return the conversationId if it exists
        } else {
            return false; // Return false if conversationId does not exist
        }
    };

    useEffect(() => {
        sessionStorage.setItem("tabIsActive", true);
        if (!!window.Android || !!window.webkit) {
            setIsMobileView(true);
        }
        if (!!window.Android || !!window.webkit) {
            sessionStorage.setItem("tabIsActive", true);
            if (sessionStorage.getItem("tabIsActive") === "true" && sessionStorage.getItem("chatStatus") === "true") {
                UpdateConversationAsRead(sessionStorage.getItem(ConversationId))
            }
        }
    }, [])
    useEffect(() => {
        if (surveyId && endingConversation) {
            console.log("UseEffect executed!")
            handleEndChatForSurvey();
        }

    }, [surveyId, endingConversation])
    //check tab is active or not in browser.
    window.onblur = function () {
        sessionStorage.setItem("tabIsActive", false)
        console.log("browser tab is changed </br>")
    };

    // If users come back to the current tab again, the below function will invoke
    window.onfocus = function () {
        sessionStorage.setItem("tabIsActive", true)
        console.log("Browser tab is again active! </br>")
        // Check if the socket is not connected
        if (!isSocketConnected &&
            props.isConversationEnded != true &&
            !isConversationEnded) {
            // Retrieve WebSocket URL from session storage
            generateNewWsUrlAndConnectToSocket()
        }
    };
    window.onload = function () {

        //  debugger
    };

    // Defining a function to establish a WebSocket connection with a given url
    const connectToWebSocket = async (url) => {
        return new Promise(function (onResolve, onReject) {
            // creating a new WebSocket object with given url and protocol
            wSocket = new WebSocket(url, "json.webpubsub.azure.v1");

            // logging the readyState of WebSocket object before event handling
            console.log("wSocket.readyStatus Before: " + wSocket.readyState);

            wSocket.onopen = (conn) => {
                setIsSocketConnected(true);
                let isConversationEnded = sessionStorage.getItem("ConversationEnded");
                let hasMessages = !!sessionStorage.getItem("messages"); // if current conversation has already messages loaded then avoid bellow getMessages call
                if (!window.Android && !window.webkit && isConversationEnded != "true" && getConversationId() && !hasMessages) {
                    props.getMessagesWeb(setMessages);
                }

                wSocket.onclose = (conn) => {
                    setIsSocketConnected(false);
                }


                // event listener for incoming messages on the WebSocket connection
                wSocket.onmessage = (event) => {
                    var notificationSound = new Audio(NotificationSound);
                    var reposne = event.data;
                    var data = JSON.parse(reposne);
                    if (data.data != null) {
                        try {
                            data = JSON.parse(data.data);
                        } catch (e) { }
                    }
                    console.log(data, "adadasd");
                    switch (data.messageType != null && data.messageType) {
                        case AgentMessage:
                            SendNotificationToParent({ event: "newMessageSwiftUI", data: "newMessageSwiftUI", targetOrigin: rawtheamSettingStrObj.parentSite });
                            // Retrieving previous chat messages from sessionStorage and adding the new outbound message to it
                            let initialmsgStr = sessionStorage.getItem("messages");
                            if (sessionStorage.getItem('muteNotifications') != "true") {
                                notificationSound.play();
                            }
                            let objmsg = {};
                            if (!!initialmsgStr) {
                                objmsg = JSON.parse(initialmsgStr);
                            }
                            var msgs = objmsg;
                            var msg = {};
                            msg.body = data.message.messageBody;
                            msg.createdDate = Date.now();
                            msg.isAgentMessage = Agent;
                            msg.type = data.message?.messageType;
                            msg.fromId = data.message.fromId;
                            msg.status = "delivered"
                            msgs.push(msg);
                            // Updating the state with the new messages list and storing it in sessionStorage
                            setMessages(msgs);





                            let msgStr = JSON.stringify(msgs);
                            sessionStorage.setItem("messages", msgStr);
                            if (
                                !sessionStorage.getItem("isAgentUnavailable") &&
                                data.message?.messageType === "OUTBOUND"
                            ) {
                                //will initiate conversation autoClose timer when it's assigned to Agent
                                //checking the msg is send by agent.
                                resetTimmer();
                            }
                            let chatStatus = sessionStorage.getItem("chatStatus")
                            let tabIsActive = sessionStorage.getItem("tabIsActive")
                            let parentTabIsActive = sessionStorage.getItem("parentTabIsActive")
                            //to update conversation as read -
                            //for web - check chat screen is not minimized and tab is focused/selected.  
                            //for mobile - check chat screen is not minimized.  
                            if (((chatStatus === "true" && (tabIsActive === "true" || parentTabIsActive === "true")) && isMobileView == false) || (isMobileView == true && tabIsActive === "true")) {
                                UpdateConversationAsRead(data.message.conversationId)
                            }

                            if (((window.Android || window.webkit) && tabIsActive === "false")) {
                                SendNotificationToParent({ event: "newMessageMobile", data: "newMessage", targetOrigin: rawtheamSettingStrObj.parentSite });
                            }
                            else if ((!window.Android && !window.webkit) && (sessionStorage.getItem("chatStatus") !== "true" || !sessionStorage.getItem("chatStatus"))) {
                                SendNotificationToParent({ event: "newMessage", data: "newMessage", targetOrigin: rawtheamSettingStrObj.parentSite });
                            }

                            break;

                        case CustomerMessage: {
                            // Clear auto-suggestions, hide chat with agent button, and close the timer
                            setSendMessageBtnLoading(false);
                            setMsgAutoSuggestions([]);
                            setDisabledAutoSuggestionChip(false)
                            setShowChatWithAgentBtn(false);
                            closeTimer();


                            let msg = {};
                            let msgBody = data.message.messageBody;
                            let initialmsgStr = sessionStorage.getItem("messages");

                            let messagesArr = [];

                            // Retrieve previous messages from session storage
                            if (!!initialmsgStr) {
                                messagesArr = JSON.parse(initialmsgStr);
                            }

                            if (
                                sessionStorage.getItem("isBotActive") === "Y" &&
                                msgBody.toLocaleLowerCase() !== "chat with agent" &&
                                msgBody.toLocaleLowerCase() !== "agent"
                            ) {
                                setShowTypingPreview(true);
                                // Check if the last message in messagesArr is marked as the first message. (fix for TEXTING-1899 - Duplicate member message is showing along with bot welcome message.)
                                if (messagesArr[messagesArr?.length - 1]?.isFirstMessage === true) {
                                    // Remove the last message from messagesArr
                                    messagesArr.pop();
                                }
                                // Process messages from the bot
                                msg.type = "INBOUND"
                                msg.status = "seen";
                                msg.body = msgBody;
                                msg.createdDate = Date.now();
                                msg.isAgentMessage = false;

                                // Update messages array and session storage
                                messagesArr.push(msg);
                                setMessages([...messagesArr]);
                                let msgStr = JSON.stringify(messagesArr);
                                sessionStorage.setItem("messages", msgStr);

                                //reset new message input, and scroll to the bottom
                                setNewMessage("");
                                scrollToBottom();
                            } else {
                                // Process messages related to connecting with an agent
                                if (
                                    (msgBody.toLocaleLowerCase() === "agent" ||
                                        msgBody.toLocaleLowerCase() === "chat with agent") &&
                                    sessionStorage.getItem("isBotActive") === "Y"
                                ) {
                                    if (showDialogtoReconnectMsg) {
                                        // Return if the conversation is closed and the user clicks on chat with agent
                                        return
                                    }
                                    //ConversationRouteToAgent(); // cousing msg switch in time
                                    setBotAvatar(false);
                                    showAssigningAgentLoaded();

                                    // Set message status and type for inbound message
                                    msg.status = "delivered";
                                    msg.type = "INBOUND"
                                }

                                // Set common message properties
                                msg.body = msgBody;
                                msg.createdDate = Date.now();
                                msg.isAgentMessage = false;

                                // If the bot is inactive, set message status and type for inbound message
                                if (sessionStorage.getItem("isBotActive") === "N") {
                                    msg.status = "delivered";
                                    msg.type = "INBOUND"
                                }

                                // Update messages array and session storage
                                setNewMessage("");
                                messagesArr.push(msg)
                                setMessages([...messagesArr]);
                                let msgStr = JSON.stringify(messagesArr);
                                sessionStorage.setItem("messages", msgStr);

                                // Process agent-related actions(Assign to agent)
                                if (
                                    (msgBody.toLocaleLowerCase() === "agent" ||
                                        msgBody.toLocaleLowerCase() === "chat with agent") &&
                                    sessionStorage.getItem("isBotActive") === "Y"
                                ) {
                                    // Update bot activity status, route the conversation to the agent, and reset new message input
                                    sessionStorage.setItem("isBotActive", "N");
                                    ConversationRouteToAgent();
                                }

                                setNewMessage("");
                                scrollToBottom();

                            }
                        }

                            break;

                        case ConversationEndedByMember: {
                            // Set a flag in session storage indicating that the conversation has ended
                            sessionStorage.setItem("ConversationEnded", true);

                            // Send a notification to the parent with information about the ended session
                            SendNotificationToParent({
                                event: "endSession",
                                data: "endSession",
                                targetOrigin: props.settings?.parentSite
                            });

                            // Retrieve the timer interval ID from session storage
                            let timerIntervalId = sessionStorage.getItem(IntervalId);

                            // If a timer interval ID exists, clear the interval
                            if (timerIntervalId) {
                                clearInterval(JSON.parse(timerIntervalId));
                            }

                            // End the chat by calling the endChat function from props
                            // props.endChat();//causing issue when on event received this line is closing chat window
                        }

                            break;


                        case Notification:
                            if (data.convNotification.notificationType === WaitTime) {
                                setAvgWaitTimeMessage(data);
                            } else if (data.convNotification.notificationType === MessageRead) {
                                updateMessageRead();
                            }
                            else if (data.convNotification.notificationType === TypingPreviewIn) {
                                setShowTypingPreview(true);
                            }
                            else if (data.convNotification.notificationType === TypingPreviewOut) {
                                setShowTypingPreview(false);
                            }
                            break;
                        case SurveyCreated:
                            if (data.surveyId) {
                                // isSurveyEnabled = true;
                                // surveyId =data.SurveyId;
                                setSurveyId(prevSurveyId => data.surveyId);
                                setIsSurveyEnabled(prevIsSurveyEnabled => true);
                            }
                            break;
                        case ConversationAssignedToAgent:
                            removeAssigningAgentLoaded();
                            if (sessionStorage.getItem('muteNotifications') != "true") {
                                notificationSound.play();
                            }
                            if (data.messageType === ConversationAssignedToAgent) {
                                props.getAgentImage(data.conversation.agentId);
                                let initialmsgStr = sessionStorage.getItem("messages");
                                let objmsg = {};
                                if (!!initialmsgStr) {
                                    objmsg = JSON.parse(initialmsgStr);
                                }
                                // setAssignedToAgent(true);
                                setCustomAttributes(data.conversation?.conversationAttributes)
                                sessionStorage.setItem("isAssignedToAgent", JSON.stringify(true))
                                setIsAssignedToAgent(true);  //when agent is assigned set isAssignedToAgent = true
                                let msgs = objmsg;
                                let msg = {};
                                msg.body = data.conversation.agentName;
                                msg.createdDate = Date.now();
                                msg.isAgentMessage = Agent;
                                msg.type = "INFO";
                                msg.subType = "ASSIGNED";
                                msgs.push(msg);
                                // Updating the state with the new messages list and storing it in sessionStorage
                                setMessages(msgs);
                                let msgStr = JSON.stringify(msgs);
                                sessionStorage.setItem("messages", msgStr);
                            }
                            // Removing session storage values related to agent unavailability and wait time notification
                            sessionStorage.removeItem("agentUnavailableMessage");
                            sessionStorage.removeItem("showAgentUnavailableMessage");
                            sessionStorage.removeItem("isAgentUnavailable");
                            setWaitTimeObj({ ...waitTimeObj, show: false, message: "" });
                            break;
                        case ConversationEndedBySystem:
                        case ConversationEndedByAgent:
                            sessionStorage.setItem("ConversationEnded", true);
                            setIsConversationEnded(true);
                            //To clear the auto close conversation timmer
                            let timmerIntervalId = sessionStorage.getItem(IntervalId);
                            if (timmerIntervalId) {
                                clearInterval(JSON.parse(timmerIntervalId));
                            }
                            let oldMsgs = sessionStorage.getItem("messages");
                            let msgList = {};
                            if (!!oldMsgs) {
                                msgList = JSON.parse(oldMsgs);
                            }
                            let newMsg = {};
                            newMsg.body = "Your conversation is ended by agent";
                            if (data.messageType === ConversationEndedBySystem) {
                                newMsg.body = "Your conversation is auto closed due to inactivity";
                            }
                            newMsg.createdDate = Date.now();
                            newMsg.isAgentMessage = Agent;
                            newMsg.type = "INFO";
                            newMsg.subType = "CONV_ENDED";
                            msgList.push(newMsg);
                            setMessages(msgList);
                            let msgListStr = JSON.stringify(msgList);
                            sessionStorage.setItem("messages", msgListStr);
                            wSocket.close();
                            break;
                        case OutBoundBotMessage:
                            // Checking if incoming bot message contains "No good match found in KB"
                            // If not, adding the bot message to messages state and setting auto-suggestions from prompts array
                            if (
                                !data.message.messageBody?.includes("No good match found in KB")
                            ) {
                                // Retrieve messages and message history from session storage
                                let initialmsgStr = sessionStorage.getItem("messages");
                                let initialmsgHistoryStr = sessionStorage.getItem("messagesHistory");

                                // Initialize arrays to store parsed messages and message history
                                let objmsg = [];
                                let objmsgHistory = [];

                                // Parse the messages if they exist in session storage
                                if (!!initialmsgStr) {
                                    objmsg = JSON.parse(initialmsgStr);
                                }

                                // Parse the message history if it exists in session storage
                                if (initialmsgHistoryStr) {
                                    objmsgHistory = JSON.parse(initialmsgHistoryStr);
                                }

                                // Check conditions based on the last messages and message history and set the bot message if required or else only show bot options chip.
                                // if(
                                //    // Check if objmsgHistory has fewer than 1 item and the last messages are not from the agent
                                //   (objmsgHistory.length<1 && 
                                //   messages[messages?.length-1]?.isAgentMessage!==Agent && 
                                //   objmsg[objmsg?.length-1]?.isAgentMessage!==Agent ) || 
                                //   (
                                //    // Check if the last message in objmsg is empty and the last message in objmsgHistory is not a bot message
                                //    ((objmsg[objmsg?.length-1]?.body==="" && 
                                //       objmsgHistory[objmsgHistory.length-1].type!=="BOTMSG") 
                                //       || 
                                //       // Check if the last message in objmsg is not empty and is not from the agent
                                //      (objmsg[objmsg?.length-1]?.body!=="" && 
                                //       objmsg[objmsg?.length-1]?.isAgentMessage!==Agent)
                                //    )
                                //   )
                                // ){
                                let botMsg = {};
                                botMsg.body = data.message.messageBody.trim();
                                botMsg.createdDate = Date.now();
                                botMsg.isAgentMessage = Agent;
                                botMsg.type = data.message?.messageType;
                                botMsg.messagePrompts = data.prompts?.join(",");
                                setNewMessage("");
                                if (((!window.Android && !window.webkit) && sessionStorage.getItem("chatStatus") !== "true")) {
                                    SendNotificationToParent({ event: "newMessage", data: "newMessage", targetOrigin: rawtheamSettingStrObj.parentSite });
                                }
                                let tabIsActive = sessionStorage.getItem("tabIsActive");
                                if (((window.Android || window.webkit) && tabIsActive === "false")) {
                                    // SendNotificationToParent({ event: "newMessageMobile", data: "newMessage",targetOrigin: props.settings?.parentSite });
                                }

                                setMessages((prevMsg) => [...prevMsg, botMsg]);
                                let msgs = objmsg;
                                msgs.push(botMsg)
                                let msgStr = JSON.stringify(msgs);
                                sessionStorage.setItem("messages", msgStr);
                                // }
                                let suggestionsArr = [];
                                // debugger;
                                data.prompts.forEach((item) => {
                                    if (item?.toLocaleLowerCase() === "chat with agent") {
                                        setShowChatWithAgentBtn(true);
                                        return;
                                    }
                                    suggestionsArr.push(item);
                                });
                                setMsgAutoSuggestions(suggestionsArr);
                                scrollToBottom();
                            }
                            break;
                        case ConversationStartedOnNewWindow: {
                            setShowConvStartedOnNewWindow(true);
                            sessionStorage.clear();
                            wSocket.close();
                            break
                        }
                        default:
                    }
                };
                onResolve();
            };
        })
    };

    // A function that sets average wait time message according to data object passed
    const setAvgWaitTimeMessage = async (data) => {
        // getting AgentUnavailableMessage from settings if available and calculating wait time message with user count and convAvgWaittimeInSec fields
        let agentUnavailableMessage =
            rawtheamSettingStrObj?.AgentUnavailableMessage;
        if ((!!agentUnavailableMessage || props.agentUnavailableMessage) && data) {
            let showMessage = false; // set show message and message text to member

            sessionStorage.setItem("showAgentUnavailableMessage", "true");
            if (data.message?.messageBody) {
                //if the calculated time exceeds the working hr for the day
                //will get "Agents are not available for today" from service that to display.
                agentUnavailableMessage = data.message?.messageBody;
                showMessage = true;
            } else if (data?.userQueueCount > 0) {
                showMessage = true;
                const minutes = Math.floor(data.convAvgWaittimeInSec / 60);
                agentUnavailableMessage = agentUnavailableMessage?.replace(
                    "{0}",
                    data.userQueueCount
                );
                if (minutes > 0) {
                    agentUnavailableMessage = agentUnavailableMessage?.replace(
                        "{1}",
                        minutes
                    );
                } else {
                    agentUnavailableMessage = agentUnavailableMessage?.substr(
                        0,
                        agentUnavailableMessage?.indexOf("position") + 8
                    );
                }
            }
            else {
                showAssigningAgentLoaded();
            }
            if (showMessage) {
                sessionStorage.setItem(
                    "agentUnavailableMessage",
                    agentUnavailableMessage
                );
                setWaitTimeObj({
                    ...waitTimeObj,
                    show: true,
                    message: agentUnavailableMessage,
                });
                scrollToBottom();
                removeAssigningAgentLoaded();
            }
        }
    };

    // Initializing auto close function to end the conversation
    const initAutoClose = () => {
        // Getting timmerIntervalId from session storage and clearing it if present
        let timmerIntervalId = sessionStorage.getItem(IntervalId);
        if (timmerIntervalId) {
            clearInterval(JSON.parse(timmerIntervalId));
        }

        // Checking if tempTheamSettingsStrObj is truthy and setting auto close message with time difference, warning message, idle time and show status
        if (rawtheamSettingStrObj) {
            let timeDiff =
                Number(rawtheamSettingStrObj.CustomerIdleTimeInMinsToEnd) -
                Number(rawtheamSettingStrObj.CustomerIdleTimeInMinsForWarning);
            if (timeDiff) {
                setShowAutoCloseMessage((prevShowAutoCloseMessage) => {
                    return {
                        ...prevShowAutoCloseMessage,
                        closeTimeInMin: timeDiff,
                        msgWithPlaceholder: rawtheamSettingStrObj.CustomerIdleWarningMsg,
                        idleTimeInMin:
                            rawtheamSettingStrObj.CustomerIdleTimeInMinsForWarning,
                        show: false,
                    };
                });

                // Setting auto close timer with time difference between idle end time and warning time
                setAutoClosetimmer((prevAutoClosetimmer) => {
                    return {
                        ...prevAutoClosetimmer,
                        min: timeDiff,
                    };
                });

                // Setting interval for closing the conversation if isInitAutoClose is true in session storage
                let isInitAutoClose = sessionStorage.getItem("initAutoClose");
                if (isInitAutoClose === "true") {
                    setInservalForCloseConv((
                        Number(rawtheamSettingStrObj.CustomerIdleTimeInMinsForWarning) * 60)//converting min to sec
                    );
                }
            }
        }
    };
    // Function to reset auto close timer by setting initAutoClose in session storage to true and resetting the timer to 0
    const resetTimmer = () => {
        sessionStorage.setItem("initAutoClose", true);
        setAutoClosetimmer((prevAutoClosetimmer) => {
            return {
                ...prevAutoClosetimmer,
                min: 0,
                sec: 0,
            };
        });
        // Initializing auto close function again
        initAutoClose();
    };

    // Function to stop auto close timer by clearing it from session storage if present and resetting the timer to 0
    const closeTimer = () => {
        sessionStorage.removeItem("initAutoClose");
        let timmerIntervalId = sessionStorage.getItem(IntervalId);
        if (timmerIntervalId) {
            clearInterval(JSON.parse(timmerIntervalId));
            sessionStorage.removeItem(IntervalId);
        }
        setShowAutoCloseMessage((prevShowAutoCloseMessage) => {
            return {
                show: false,
            };
        });
        setAutoClosetimmer((prevAutoClosetimmer) => {
            return {
                min: 0,
                sec: 0,
            };
        });
    };

    // Function to set interval for closing conversation after specified minutes
    const setInservalForCloseConv = (sec) => {
        // Getting close date time based on current time and specified time in seconds
        let closeDateTime = new Date(new Date().getTime() + sec * 1000);
        console.log("timer will start on :: ", closeDateTime);
        let isTimerRunning = true;
        // setInterval to check if auto close message needs to be shown and to update timer
        let interval = setInterval(() => {
            sessionStorage.setItem(IntervalId, JSON.stringify(interval));
            if (new Date(closeDateTime) < new Date()) {
                setShowAutoCloseMessage((prevShowAutoCloseMessage) => {
                    return {
                        ...prevShowAutoCloseMessage,
                        show: true,
                    };
                });
                setAutoClosetimmer((prevAutoClosetimmer) => {
                    if (prevAutoClosetimmer?.sec > 0) {
                        if (
                            prevAutoClosetimmer.min === 0 &&
                            prevAutoClosetimmer.sec === 1
                        ) {
                            console.log(prevAutoClosetimmer.sec, "sec update-insi");
                            // Checking timer to end conversation and setting dialog to reconnect with message
                            if (isTimerRunning) {
                                isTimerRunning = false;
                                clearInterval(interval);
                                endConversation("timer");//calling from timer when timer gets ended.
                                setOpens(false);
                                setIsConversationEnded(true);
                                // setShowDialogtoReconnect(true);
                                // setShowDialogtoReconnectMsg(
                                //   "Your conversation is auto closed due to inactivity"
                                // );
                            }
                        }
                        return {
                            ...prevAutoClosetimmer,
                            sec: prevAutoClosetimmer.sec - 1,
                        };
                    } else if (prevAutoClosetimmer?.min > 0) {
                        return {
                            min: prevAutoClosetimmer.min - 1,
                            sec: 59,
                        };
                    }
                });
            }
        }, 1000);
    };

    // Function to get bot messages from API endpoint using axios post request
    async function getBotMessages(custMsg, initialPrompt) {
        let convId = getConversationId();
        let botDetails = pvaBotDetailsAttribute ? pvaBotDetailsAttribute : sessionStorage.getItem(pvaBotDetails)
        let botObj = null;
        if (botDetails) {
            botObj = JSON.parse(botDetails)
        }
        if (!botObj) {
            await createVirtualBotConversation(convId, custMsg, initialPrompt)
        }
        else {
            sendMessageToBot(custMsg, initialPrompt, botObj)
        }


    }
    const sendMessageToBot = (custMsg, initialPrompt, botObj) => {
        sessionStorage.setItem("isBotActive", "Y");
        InvokePostWebChatServiceCall(apiConfig.GET_AI_CONVERSATIONAL_BOT_MESSAGES, {
            userMessage: "User Prompt",
            isInitialPromptMessageFlag: initialPrompt,
            customerMessage: custMsg,
            conversationId: getConversationId(),
            // isRefresh: props.isRefresh,  //removed temp change for hanlde multiple messages from bot
            botConversationId: botObj.botConversationId,
            token: botObj.botToken
        })
            .then((response) => {
                setShowTypingPreview(false);
                console.log(response, "response");
                props.setIsRefresh(false);
            })
            .catch((error) => {
                setShowTypingPreview(false);
                console.log(error);
                if (error.response && error.response.status === 401) {
                    setIsSessionExpired(true);
                }
            });
    }
    const createVirtualBotConversation = async (conversationId, custMsg, initialPrompt) => {  // Call API to update conversation as read
        setShowTypingPreview(true);
        let requestBody = {
            conversationId: conversationId
        };
        InvokePostWebChatServiceCall(apiConfig.CREATE_PVA_BOT_CONVERSATION, requestBody)
            .then((res) => {
                // debugger
                console.log("CREATE_PVA_BOT_CONVERSATION ", res);
                if (res.data.status === "SUCCESS") {
                    let obj = {
                        botConversationId: res.data.botConversationId,
                        botToken: res.data.token,
                    }
                    let objJsonString = JSON.stringify(obj);
                    sessionStorage.setItem(pvaBotDetails, objJsonString);
                    sendMessageToBot(custMsg, initialPrompt, obj)
                }
            })
            .catch((err) => {
                console.log(err, "Error Message", "CREATE_PVA_BOT_CONVERSATION");
                setShowTypingPreview(false);
                if (err.response && err.response.status === 401) {
                    setIsSessionExpired(true);
                }
            });
    }
    const scrollToBottom = () => {
        if (!!messagesEndRef && !!messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView();
        }
    };

    // Async function to upload document by converting it to base64 and sending it in request body
    async function uploadDocument(files) {
        setLoading(true);
        // Clearing auto close timer to prevent conversation from closing while uploading document
        closeTimer();
        var reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = async function () {
            // Converting file to base64 string and updating state variables for document name, type and data
            let fileData = reader.result.split(",");
            setDocument(fileData[1]);
            setDocName(files[0].name);
            // Creating request body with document details and conversation id
            let requestBody = {
                conversationId: getConversationId(),
                Source: "WebChat",
                documentRecord: {
                    DocumentType: "Web Chat", // To be changed
                    DocumentName: files[0].name,
                    FileExtension: files[0].type,
                    BaseDocument: fileData[1],
                },
            };
            // Sending post request to upload document using axios
            await InvokePostServiceCallWithoutToken(
                apiConfig.UPLOAD_DOCUMENTS_WEBCHAT,
                requestBody
            )
                .then((response) => {
                    // Adding success or failure message based on response status and updating loading variable
                    if (!!response.data.status && response.data.status == "SUCCESS") {
                        let msg = files[0].name + " uploaded succesfully";
                        addMessage(msg);
                    } else {
                        let msg = files[0].name + " upload failed";
                        addMessage(msg);
                    }
                    setLoading(false);
                })
                .catch((err) => {
                    console.log("Failed" + err);
                    setLoading(false);
                    if (err.response && err.response.status === 401) {
                        setIsSessionExpired(true);
                    }
                    let msg = files[0].name + " upload failed";
                    addMessage(msg);
                });
        };
        reader.onerror = function (error) {
            console.log("Error", error);
        };
    }

    // Function to add new message to messages array
    function addMessage(txt) {
        var msgs = messages; // Get current messages array
        var msg = {}; // Create new message object with text, date and isAgentMessage
        msg.body = txt;
        msg.createdDate = Date.now();
        msg.isAgentMessage = Member;
        msg.status = sessionStorage.getItem("isBotActive") === "Y" ? "seen" : "delivered";
        msgs.push(msg); // Add new message to messages array
        let msgStr = JSON.stringify(msgs);
        sessionStorage.setItem("messages", msgStr); // Save updated messages array to session storage
        setNewMessage(""); // Clear new message input field
        scrollToBottom(); // Scroll to bottom of messages container
        setMessages([...msgs]);
    }

    // Handler function to close attachment dialog
    const handleClose = (event) => {
        setOpen(false);
    };

    // Handler function to minimize chat window
    const minimizeChat = (event) => {
        sessionStorage.setItem("chatStatus", false);
        sessionStorage.setItem("tabIsActive", false);
        SendNotificationToParent({ event: "clearNewMessageNotification", data: "clearNewMessageNotification", targetOrigin: props.settings?.parentSite });
        SendNotificationToParent({ event: "minimizeWindow", data: "minimizeWindow", targetOrigin: props.settings?.parentSite });
        props.setIsChatOpen(false)
    };

    // Handler function to open attachment dialog
    const showAttachmentDialog = (event) => {
        setOpen(true);
        setOpenSpeedDial(!openSpeedDial);
    };

    const setMessage = (event) => {
        if (startConvWithoutDesc) {// Check if the conversation should start without a description
            updatecustomermessage(event);
            setNewMessage(event.target.value);
        } else {
            scrollToBottom();
            setNewMessage(event.target.value);
            handleTypingPreview()
        }
    };

    const handleInputOnClick = (event) => {
        scrollToBottom();
        UpdateConversationAsRead(sessionStorage.getItem(ConversationId))
    }
    const handleKeyDown = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            sendMessage(newMessage.trim());
        }

    }

    // Handler function to open and close chat dialog
    const handleCloses = (e) => {
        setOpens(false);
    };



    const endConversation = async (event) => {
        setLoading(true);
        setOpens(true);
        closeTimer();
        let convId = getConversationId();
        if (!convId) {
            props.endChat();
            return
        }
        let url = apiConfig.END_WEBCHAT_CONVERSATION;
        let msgReq = {
            conversation: {
                conversationId: convId,
                conversationEndedDate: new Date().toISOString(),
                // conversationEndedById: userId,
            },
            isManualEnd: event === "manual",
            // teamId: teamId,
            // userId: userId,
        };
        await InvokePostWebChatServiceCall(url, JSON.stringify(msgReq))
            .then((resp) => {
                setLoading(false);
                if (resp.data.surveyId) {
                    // isSurveyEnabled = true;
                    setIsSurveyEnabled(prevIsSurveyEnabled => true);
                    console.log("i am On ", isSurveyEnabled)
                    // surveyId = resp.data.surveyId;
                    //survey popup should not show when the conv ended on timer end's
                    if (event !== "timer") {
                        setEndingConversation(true);
                    }
                    setSurveyId(prevSurveyId => resp.data.surveyId);
                }
                //for survey is disable then close window directly except if conv close due to inactivity of member
                else if (event !== "timer") {
                    handleEndChatForSurvey();
                }
                console.log("I am executed form end conv")
                // sessionStorage.setItem("ConversationEnded", true);
                SendNotificationToParent({ event: "endSession", data: "endSession", targetOrigin: props.settings?.parentSite });
            })
            .catch((err) => {
                setLoading(false);
                if (err.response && err.response.status === 401) {
                    // setIsSessionExpired(true);
                    handleEndChatForSurvey();//close conv on member if session is expired
                }
                resetTimmer();
                console.log(err, "Error Message");
            });
    };
    const emailOrDownloadChat = async (isDownload) => {
        setOpenSpeedDial(!openSpeedDial);
        setLoading(true);
        // initAutoClose();
        var convid = getConversationId();

        let requestBody = {
            conversationid: convid,
            isDownloadTranscript: isDownload,
        };
        await InvokePostWebChatServiceCall(
            apiConfig.SEND_CONVERSATION_TRANSCRIPT_TO_CUSTOMER,
            requestBody
        )
            .then((response) => {
                if (response.data.status === "SUCCESS") {
                    if (response.data.base64MessagesData) {
                        var fileBase64 = "data:text/plain;base64," + response.data.base64MessagesData
                        SendNotificationToParent({ event: "downloadFile", data: response.data.base64MessagesData, targetOrigin: props.settings?.parentSite });
                        // FileSaver.saveAs(fileBase64, "chatTranscript.txt");
                        // document.location.href = fileBase64
                        // window.open(fileBase64, "chatTranscript.txt")
                        fetch(fileBase64)
                            .then(resp => resp.blob())
                            .then(fileBlob => {
                                const url = URL.createObjectURL(fileBlob);
                                // window.open(url, "chatTranscript.txt")
                                const downloadLink = window.document.createElement('a');
                                downloadLink.href = url//window.URL.createObjectURL(new Blob([this.response], { type: contentTypeHeader }));
                                downloadLink.download = "chatTranscript.txt";
                                window.document.body.appendChild(downloadLink);
                                downloadLink.click();
                                window.document.body.removeChild(downloadLink);
                            })
                    } else {
                        setShowSnackBar({
                            open: true,
                            msg: response.data.statusDescription,
                            severity: "success",
                        });
                    }
                } else {
                    console.log("Failed" + response.data.statusDescription);
                    setShowSnackBar({
                        open: true,
                        msg: response.data.statusDescription,
                        severity: "error",
                    });
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.log("Failed" + err.toString());
                if (err.response && err.response.status === 401) {
                    setIsSessionExpired(true);
                }
            });
    };
    const handleTypingPreview = (e) => {
        if (timeOutId === 0) {
            SendPubSubNotificationForWebChat(TypingPreviewIn)
        }
        else {
            clearTimeout(timeOutId)
        }
        let localTimeOutId = setTimeout(() => {
            SendPubSubNotificationForWebChat(TypingPreviewOut)
            clearTimeout(localTimeOutId)
            setTimeOutId(0)
        }, 20000)
        setTimeOutId(localTimeOutId)
    }

    const SendPubSubNotificationForWebChat = (notificationType) => {
        var convid = getConversationId();
        if (!convid) {
            console.log("Conversation Id is not available ", convid);
            return;
        }
        let requestBody = {
            conversationid: convid,
            notificationType: notificationType,
            isCallFromMember: true,
        };
        InvokePostWebChatServiceCall(
            apiConfig.SEND_PUBSUB_NOTIFICATION_FOR_WEBCHAT,
            requestBody
        )
            .then((response) => {
                if (response.data.status === "SUCCESS") {
                    console.log("pubsub event published successfully");
                } else {
                    console.log("failed to send pubsub event :" + response.data.statusDescription);
                }
            })
            .catch((err) => {
                console.log("Failed" + err.toString());
            });
    };
    const sendMessage = async function (msgBody = "") {
        if (sendMessageBtnLoading) {
            return false;//when send messages is in progress
        }
        setNewMessage("");
        if (!!msgBody && msgBody.trim().length > 0) {
            setSendMessageBtnLoading(true)
            //for authenticated conversation without bot, start conversation on click of send message button.
            if (startConvWithoutDesc) {
                startConversation(setNewMessage)
            } else {
                if (!isSocketConnected) {
                    await generateNewWsUrlAndConnectToSocket();
                }
                debugger
                if (
                    sessionStorage.getItem("isBotActive") === "Y" &&
                    msgBody.toLocaleLowerCase() !== "chat with agent" &&
                    msgBody.toLocaleLowerCase() !== "agent"
                ) {
                    getBotMessages(msgBody);
                } else {
                    var convid = getConversationId();

                    let requestBody = {
                        conversationid: convid,
                        messageBody: msgBody.trim(),
                    };
                    /*debugger;
                            if (newMessage == null && newMessage == "") {
                                return;
                                if (newMessage.trim().length == 0) {
                                    return;
                                }
                            }*/

                    await InvokePostWebChatServiceCall(
                        apiConfig.SEND_MESSAGE_WEBCLIENT,
                        requestBody
                    )
                        .then((response) => {
                            if (!!response.data.wsURL) {
                                connectToWebSocket(response.data.wsURL);
                                sessionStorage.setItem(
                                    ConversationId,
                                    response.data.conversationId
                                );
                                sessionStorage.setItem("wsUrl", response.data.wsURL);
                            }
                        })
                        .catch((err) => {
                            console.log("Failed" + err);
                            setSendMessageBtnLoading(false);
                            removeAssigningAgentLoaded();
                            if (err.response && err.response.status === 401) {
                                setIsSessionExpired(true);
                            }
                        });
                }
            }
        }

    };


    const ConversationRouteToAgent = async function () {
        var convid = getConversationId();

        let requestBody = {
            conversationid: convid,
        };
        await InvokePostWebChatServiceCall(
            apiConfig.AUTO_ASSIGN_CONVERSATION,
            requestBody
        )
            .then((response) => {
                console.log("ConversationRouteToAgent-Response :: ", response);
                if (response.data?.convesationAvgWaitTimeResp) {
                    //set message with queue position & Wait time on auto routing..
                    setAvgWaitTimeMessage(response.data?.convesationAvgWaitTimeResp);
                }
                if (response.data.isNonBussinessHours === true && response.data.status === "FAILED") {// check nonbusiness hours and set state to show nonbusiness hours message.
                    setShowNonBusinessHoursMsg(true);
                    // sessionStorage.clear();
                }
            })
            .catch((err) => {
                console.log("Failed" + err);
                removeAssigningAgentLoaded();
                if (err.response && err.response.status === 401) {
                    setIsSessionExpired(true);
                }
            });
    };
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setShowSnackBar({
            open: false,
        });
    };

    const getDimension = () => {
        setDimension(window.innerWidth);
    };

    function updateMessageRead() {
        //this function will update all the messages status as seen
        let messageList = JSON.parse(sessionStorage.getItem("messages"));
        messageList.forEach((item) => {
            item.status = "seen"
            item.showNotification = false
        })
        setMessages([...messageList])
        sessionStorage.setItem("messages", JSON.stringify(messageList))
    }
    const handleAutoCloseTimmerOnRefresh = (theamSettings, convAutoCloseTimerStartInSec, convCloseTimeInMin, convCloseTimeInSec) => {
        // Getting timmerIntervalId from session storage and clearing it if present
        let timmerIntervalId = sessionStorage.getItem(IntervalId);
        if (timmerIntervalId) {
            clearInterval(JSON.parse(timmerIntervalId));
        }
        // Checking if theamSettings is truthy and setting auto close message with time difference, warning message, idle time and show status
        if (theamSettings) {
            let timeDiff =
                Number(theamSettings.CustomerIdleTimeInMinsToEnd) -
                Number(theamSettings.CustomerIdleTimeInMinsForWarning);
            if (timeDiff) {
                setShowAutoCloseMessage((prevShowAutoCloseMessage) => {
                    return {
                        ...prevShowAutoCloseMessage,
                        closeTimeInMin: timeDiff,
                        msgWithPlaceholder: theamSettings.CustomerIdleWarningMsg,
                        idleTimeInMin:
                            theamSettings.CustomerIdleTimeInMinsForWarning,
                        show: false,
                    };
                });
                if (convAutoCloseTimerStartInSec && convAutoCloseTimerStartInSec < 0) {
                    setAutoClosetimmer((prevAutoClosetimmer) => {
                        return {
                            ...prevAutoClosetimmer,
                            min: convCloseTimeInMin ? convCloseTimeInMin : 0,
                            sec: convCloseTimeInSec ? convCloseTimeInSec : 0,
                        };
                    });
                }
                else {
                    // Setting auto close timer with time difference between idle end time and warning time
                    setAutoClosetimmer((prevAutoClosetimmer) => {
                        return {
                            ...prevAutoClosetimmer,
                            min: timeDiff,
                        };
                    });
                }
                // Setting interval for closing the conversation if isInitAutoClose is true in session storage
                let isInitAutoClose = sessionStorage.getItem("initAutoClose");
                if (isInitAutoClose === "true") {
                    setInservalForCloseConv(
                        Number(convAutoCloseTimerStartInSec)
                    );
                }
            }
        }
    };
    // useEffect hook with some side effects like scrolling to bottom of chat, establishing WebSocket connection for webchat and getting messages from session storage
    useEffect(() => {
        if (reloadChatCompOnStartChatWithoutBot) {
            setDisableLoadingForChatWithoutBot(true)
        }
        if (!reloadChatCompOnStartChatWithoutBot) {
            const asyncFunction = async () => {
                try {
                    if (!disableLoadingForChatWithoutBot) {
                        setLoading(true);
                    }
                    scrollToBottom();
                    //Establish WebSocket connection for the webchat
                    let wsUrl = sessionStorage.getItem("wsUrl");
                    if (!!wsUrl) {
                        await generateNewWsUrlAndConnectToSocket();
                    }
                    if (!!window.Android || !!window.webkit) {
                        props.connectToSocket(await generateNewWsUrlAndConnectToSocket)
                    }
                    let msgStr = sessionStorage.getItem("messages");
                    if (!!msgStr) {
                        let objmsg = JSON.parse(msgStr);
                        setMessages(objmsg);
                        // Calling bot messages on every reload if enableChatBot in settings is "Y" and isBotActive is not "N"
                        let isBotActive = sessionStorage.getItem("isBotActive");
                        if (rawtheamSettingStrObj.enableChatBot === "Y" && isBotActive !== "N" && props.isConversationEnded === false && !isAssignedToAgent) {
                            //get bot message on first time when conversation is create
                            if (props.loadBotMessages === true) {
                                let msgBody = objmsg[0]?.body ? objmsg[0].body : ""
                                getBotMessages(msgBody, "Y");
                            }
                            // if(objmsg.length==1){
                            //   getBotMessages(objmsg[0].body, "Y");
                            // }
                            // else if(objmsg.length==2){
                            //   // below code is commented  for handle multiple continuous bot msg's
                            //   // getBotMessages(objmsg[objmsg.length - 1].body, "Y");
                            // }
                            // else if (objmsg[objmsg.length - 1]?.isAgentMessage === Member) {
                            //   getBotMessages(objmsg[objmsg.length - 1].body)
                            //   }
                            //   else if(objmsg.length>=2 && objmsg[objmsg.length - 1].isAgentMessage === Agent){
                            //   //below code is commented  for handle multiple continuous bot msg's
                            //     // getBotMessages(objmsg[objmsg.length - 2].body);
                            // } else{
                            //   //getBotMessages("User Prompt", "Y");
                            //   getBotMessages("", "Y");//to get bot messages user promp is not required casing initial user message error.
                            // }
                            setBotAvatar(true);
                            sessionStorage.setItem("isBotActive", "Y");
                        }
                        debugger
                        let lastMsg = objmsg ? objmsg[objmsg.length - 1] : {}
                        if (objmsg.length === 0 && props.messagesHistory?.length > 0) {

                            if (props.messagesHistory?.length > 0) {
                                lastMsg = props.messagesHistory[props.messagesHistory.length - 1];
                            }

                        }
                        //checking if last message already set or not
                        if (Object.keys(lastMsg).length === 0){
                            let messagesHistoryStr = sessionStorage.getItem("messagesHistory");
                            if (!!messagesHistoryStr) {
                                let objMessagesHistoryStr = JSON.parse(messagesHistoryStr);
                                lastMsg = objMessagesHistoryStr[objMessagesHistoryStr.length - 1];
                            }
                        }

                        //set prompts on last message is from bot & having prompts on 1st time conv 
                        if (lastMsg && lastMsg.type === "BOTMSG" && !!lastMsg.messagePrompts && props.isRefresh){
                            let prompts = lastMsg.messagePrompts?.split(',');
                            let isShowAgentBtn = prompts.some(p => p.toLocaleLowerCase() === "chat with agent");
                            setShowChatWithAgentBtn(isShowAgentBtn);
                            let suggestions = prompts.filter(p => p.toLocaleLowerCase() !== "chat with agent");
                            setMsgAutoSuggestions(suggestions);
                        }
                        //setting auto close timer on refresh page and last msg from agent  when the conv it not with Bot..
                        debugger
                        if (props.isConversationEnded === false
                            && getConversationId() // before setting timer checking is conv id/conv is created or not
                            && props.isRefresh
                            && lastMsg
                            && lastMsg.isAgentMessage === Agent
                            && lastMsg.type !== "INFO"
                            && lastMsg.type !== "BOTMSG"
                            && isBotActive !== "Y") {
                            let lastMsgWarningTime = new Date(moment(lastMsg.createdDate).local() + ((rawtheamSettingStrObj.CustomerIdleTimeInMinsForWarning) * 60000))//calculate warning time with ideal time for auto close timmer
                            let currentDT = new Date();
                            let convEndDeadLine = new Date(moment(lastMsg.createdDate).local() + (rawtheamSettingStrObj.CustomerIdleTimeInMinsToEnd * 60000))
                            if ((currentDT < lastMsgWarningTime || (currentDT > lastMsgWarningTime && currentDT < convEndDeadLine)) && !startConvWithoutDesc) {
                                var momentWarningDTDiffInSec = moment(lastMsgWarningTime).diff(moment(new Date()), 'seconds')
                                //time pending to close conv timer
                                sessionStorage.setItem("initAutoClose", true);
                                if (lastMsgWarningTime > new Date()) {
                                    handleAutoCloseTimmerOnRefresh(rawtheamSettingStrObj, momentWarningDTDiffInSec)
                                } else {
                                    var momentEndConvDTDiff = moment.duration(moment(convEndDeadLine, "HH:mm:ss a").diff(moment(new Date(), "HH:mm:ss a")))
                                    console.log(`${momentEndConvDTDiff.minutes()} minutes ${momentEndConvDTDiff.seconds()} seconds`);
                                    handleAutoCloseTimmerOnRefresh(rawtheamSettingStrObj, momentWarningDTDiffInSec, momentEndConvDTDiff.minutes(), momentEndConvDTDiff.seconds())
                                }
                            }
                        }
                    }
                    //for new missed conv, to show avg wait time message for 1st time only below block will execute to set time.
                    //2nd onwords we'll get message from session
                    let initialAvgWaitTime = sessionStorage.getItem("initialAvgWaitTime");
                    if (initialAvgWaitTime) {
                        let initialAvgWaitTimeStr = sessionStorage.getItem("initialAvgWaitTime");
                        let initialAvgWaitTimeS = JSON.parse(initialAvgWaitTimeStr);
                        setAvgWaitTimeMessage(initialAvgWaitTimeS);
                        sessionStorage.removeItem("initialAvgWaitTime");
                    }
                    //checking whether the conversation is in process of assigning to agent if yes the show assigning loader
                    var isAssigning = sessionStorage.getItem("showAgentAssignMsg") == "true";
                    if (isAssigning || props.showAgentAssignMsg) {
                        showAssigningAgentLoaded();
                    }
                    // Checking if showAgentUnavailableMessage is true in session storage and setting wait time object with agentUnavailableMessage
                    else if (sessionStorage.getItem("showAgentUnavailableMessage") =="true") {
                        let agentUnavailableMessage = sessionStorage.getItem(
                            "agentUnavailableMessage"
                        );
                        setWaitTimeObj({
                            ...waitTimeObj,
                            show: true,
                            message: agentUnavailableMessage,
                        });
                        scrollToBottom();
                    }

                    // Checking if initAutoClose is true in session storage and calling initAutoClose function
                    let isInitAutoClose = sessionStorage.getItem("initAutoClose");
                    if (isInitAutoClose === "true" && !props.isRefresh && !startConvWithoutDesc) {
                        //it will work in regular case but not in refresh page
                        initAutoClose();
                    }
                    setLoading(false);
                    setSendMessageBtnLoading(false)
                    setDisableLoadingForChatWithoutBot(false);
                }
                catch (err) {
                    console.log("Error in useEffect of ChatWindow :: ", err);
                    setLoading(false);
                    setDebug(err.toString());
                }
            }
            asyncFunction();
        }
    }, [reloadChatCompOnStartChatWithoutBot]);

    // Effect hook to scroll to bottom of messages container when messages state is updated
    useEffect(() => {
        scrollToBottom();
    }, [messages, props.messagesHistory]);

    // Effect hook to get screen dimensions
    useEffect(() => {
        window.addEventListener("resize", getDimension);

        return () => {
            window.removeEventListener("resize", getDimension);
        };
    }, [dimensions]);

    // Asynchronous function to generate a new WebSocket URL and connect to the socket
    async function generateNewWsUrlAndConnectToSocket() {
        try {
            if (getConversationId()) {
                // Invoke a POST service call to generate a new WebSocket URL
                let result = await InvokePostWebChatServiceCall(apiConfig.GENERATE_WS_URL,
                    {
                        conversationId: getConversationId(),
                    }
                )
                let wsURL = result.data.wsURL;

                // Check if the WebSocket URL is valid
                if (!!wsURL) {
                    // Store the WebSocket URL in the session storage
                    sessionStorage.setItem("wsUrl", wsURL);

                    // Connect to the WebSocket using the obtained URL
                    await connectToWebSocket(wsURL);
                }
                return true;
            }
        } catch (error) {
            console.log("generateNewWsURL Error")

        }
    }



    useEffect(() => {
        if (
            getConversationId() &&
            !isSocketConnected &&
            props.isConversationEnded != true &&
            !isConversationEnded
        ) {
            generateNewWsUrlAndConnectToSocket();
        }
        scrollToBottom();
        if (window.scrollY === 0) {//check scroll bar present or not
            setBottom(true)
        }
    }, [props.isChatOpen])

    useEffect(() => {
        //when conversation auto closed due to inactivity this useeffect will run (mobile issue)
        if (props.isConversationEnded) {
            sessionStorage.setItem("ConversationEnded", true);
            setIsConversationEnded(true);
            // setShowDialogtoReconnect(true);
            // setShowDialogtoReconnectMsg(
            //   props.conversationEndedBy === "Agent" ? "Your conversation is ended by agent" :
            //     "Your conversation is auto closed due to inactivity"
            // );
        }
    }, [props.isConversationEnded])

    useEffect(() => {
        //to show queue position and wait time after refresh (mobile issue).
        if (props.convesationAvgWaitTimeRespObj) {
            setAvgWaitTimeMessage(props.convesationAvgWaitTimeRespObj)
        }
    }, [props.agentUnavailableMessage])


    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleFontSizeMenuClose = () => {
        setAnchorEl(null);
        setAnchorChatOptionsEl(null);
    };

    const handleScroll = (e) => {//check if scroll is at bottom or not
        const bottom = Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 1;
        setBottom(bottom)
    };
    const handleEndChatForSurvey = () => {
        console.log("I am executed form survey - ", surveyId)
        // debugger
        if (isSurveyEnabled) {
            setShowSurveyDialog(true);
        }
        else {
            props.endChat();
        }
    }
    const handleCloseConvStartedOnNewWindowDialog = () => {
        props.endChat();
        setShowConvStartedOnNewWindow(false);
    }
    return (
        <>
            <div
                style={{
                    display: "grid",
                }}
            >
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={showSnackBar.open}
                    autoHideDuration={2000}
                    onClose={handleCloseSnackbar}
                    sx={{ top: "60px", zIndex: 1600 }}
                >
                    <Alert
                        onClose={handleCloseSnackbar}
                        severity={showSnackBar.severity}
                        sx={{ width: "100%", paddingTop: 1 }}
                    >
                        <span>{showSnackBar.msg}</span>
                    </Alert>
                </Snackbar>
                {loading ? (
                    <Backdrop
                        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <Stack gap={1} justifyContent="center" alignItems="center">
                            <CircularProgress color="inherit" />
                            <Typography>Loading...</Typography>
                        </Stack>
                    </Backdrop>
                ) : (
                    <AttachDocumetsDialog
                        open={open}
                        handleClose={handleClose}
                        loading={loading}
                        setOpen={setOpen}
                        docName={docName}
                        uploadDocument={uploadDocument}
                        theamSettingsStrObj={theamSettingsStrObj}
                        document={document}
                        setDocument={setDocument}
                        setDocName={setDocName}
                    />
                )}

                <Box sx={{ ...theme.customStyle.chatHeaderDiv, ...chatCustomStyleCss.chatHeader, ...(isMobileDevice ? { padding: "0px",borderRadius:"0px" } : {})}}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Tooltip title="Menu">
                            <IconButton
                                id="menu-button"
                                sx={{ padding: "0px", display: "none"}}
                                aria-controls={openChatOptionsMenu ? 'menu-button' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openChatOptionsMenu ? 'true' : undefined}
                                color="inherit"
                                onClick={(e) => {
                                    //open chat options menu
                                    setAnchorChatOptionsEl(e.currentTarget);
                                }}
                            >
                                <MoreVertIcon />
                            </IconButton>
                        </Tooltip>
                        {isMobileDevice && <Tooltip >
                            <IconButton
                                color="inherit"
                                onClick={minimizeChat}
                            >
                                <KeyboardArrowLeftIcon sx={{ fontSize: 35 }} />
                            </IconButton>
                        </Tooltip>}
                        {!isMobileDevice && theamSettingsStrObj?.companyLogo &&
                            <Avatar sx={{ width: "36px", height: "36px" }} src={theamSettingsStrObj.companyLogo}>
                                {/* <SmartToyOutlinedIcon /> */}
                            </Avatar>}
                    </div>
                    <div>
                        <Tooltip title="Font Size">
                            <IconButton
                                id="basic-button"
                                aria-controls={openFontSizeMenu ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openFontSizeMenu ? 'true' : undefined}
                                color="inherit"
                                onClick={(e) => { handleClickListItem(e) }}
                            >
                                <FormatSizeIcon />
                            </IconButton>
                        </Tooltip>
                        {!isMobileDevice && <Tooltip title="Minimize Chat">
                            <IconButton
                                color="inherit"
                                onClick={minimizeChat}
                                sx={{ marginTop: "-20px" }}
                            >
                                <MinimizeIcon />
                            </IconButton>
                        </Tooltip>}
                        <Tooltip title="End Chat">
                            <IconButton
                                color="inherit"
                                onClick={async () => {
                                    setEndingConversation(true);
                                    if (!sessionStorage.getItem("ConversationEnded")) { // Check if conversation is not already ended
                                        setOpens(true); // Show the confirmation dialog
                                    } else {
                                        //  await endChat(); // Close the chat directly if already ended
                                        handleEndChatForSurvey(); //Close the chat directly if already ended
                                    }
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openFontSizeMenu}
                            onClose={handleFontSizeMenuClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                                sx: { paddingTop: "0px", paddingBottom: "0px" }
                            }}
                        >
                            <MenuItem selected={props.textSize === 'Small'} onClick={() => { props.setFontSize('Small'); handleFontSizeMenuClose(); }}>Small</MenuItem>
                            <MenuItem selected={props.textSize === 'Medium'} onClick={() => { props.setFontSize('Medium'); handleFontSizeMenuClose(); }}>Medium</MenuItem>
                            <MenuItem selected={props.textSize === 'Large'} onClick={() => { props.setFontSize('Large'); handleFontSizeMenuClose(); }}>Large</MenuItem>
                        </Menu>
                        <Menu
                            id="menu-button"
                            anchorEl={anchorChatOptionsEl}
                            open={openChatOptionsMenu}
                            onClose={handleFontSizeMenuClose}
                            MenuListProps={{
                                'aria-labelledby': 'menu-button',
                                sx: { paddingTop: "0px", paddingBottom: "0px" }
                            }}
                        >
                            {(!token && props.messagesHistory.length < 1) && <> <MenuItem onClick={() => { emailOrDownloadChat(false); handleFontSizeMenuClose(); }}>
                                <ListItemIcon>
                                    <EmailIcon fontSize="small" />
                                </ListItemIcon>Email Chat
                            </MenuItem>
                                <MenuItem onClick={() => { emailOrDownloadChat(true); handleFontSizeMenuClose(); }}>
                                    <ListItemIcon>
                                        <DownloadIcon fontSize="small" />
                                    </ListItemIcon>Download Chat
                                </MenuItem></>}
                            {(isAssignedToAgent && !isConversationEnded) && <MenuItem onClick={() => { showAttachmentDialog(); handleFontSizeMenuClose(); }}>
                                <ListItemIcon>
                                    <AttachFileIcon fontSize="small" />
                                </ListItemIcon>Attachment
                            </MenuItem>}
                            <MenuItem onClick={() => {
                                // Toggle mute/unmute notifications based on the current state (muteNotifications)
                                if (muteNotifications === "true") {
                                    // If notifications are currently muted, unmute them
                                    handleMuteUnmuteNotifications(
                                        "false"
                                    );
                                } else {
                                    // If notifications are currently unmuted, mute them
                                    handleMuteUnmuteNotifications(
                                        "true"
                                    );
                                }
                                handleFontSizeMenuClose();
                            }}>
                                <ListItemIcon>
                                    {muteNotifications === "true" ? <VolumeOffIcon /> :
                                        <VolumeUpIcon />}
                                </ListItemIcon>{muteNotifications === "false" ? "Mute" : "Unmute"}
                            </MenuItem>
                        </Menu>
                        {sessionStorage.getItem("ConversationEnded") ? null : (
                            <Dialog
                                open={opens}
                                onClose={handleCloses}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                fullWidth={props.dialogFullWidth}
                            >
                                <DialogTitle>End Chat</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description" sx={{ color: "#202020" }}>
                                        Are you sure you want to end the chat?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloses} sx={{ color: "#0065d1" }}>no</Button>
                                    <Button sx={{ color: "#0065d1" }} onClick={async () => {
                                        let timmerIntervalId = sessionStorage.getItem(IntervalId);
                                        if (timmerIntervalId) {
                                            clearInterval(JSON.parse(timmerIntervalId));
                                        }
                                        if (!sessionStorage.getItem("ConversationEnded")) {
                                            await endConversation("manual");
                                        }
                                        setEndingConversation(true);
                                        //  handleEndChatForSurvey();
                                        handleCloses();
                                    }}>yes</Button>
                                </DialogActions>
                            </Dialog>
                        )}
                    </div>
                </Box>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        height: "100%",
                    }}
                    onClick={() => {
                        UpdateConversationAsRead(sessionStorage.getItem(ConversationId))
                    }}
                >
                    <div>
                        <>
                            <Grid>
                                <Box style={theme.customStyle.chatBoxStyle}>
                                    <Box className="wcu_box_padding">
                                        <Paper
                                            variant="outlined"
                                            style={{
                                                ...styles.convStyle,
                                                height: `calc(100vh - ${isConversationEnded ? '65px' : '170px'})`,
                                                paddingBottom: props.os === "iOS" ? "32px" : showTypingPreview ? "7px" : "0px",
                                                position: "relative",
                                                // msgAutoSuggestions.length > 0
                                                //     ? "calc(100vh - 161px)" : "calc(100vh - 132px)",
                                            }}
                                            sx={{ pt: 1, borderWidth: 0 }}
                                        >
                                            <div
                                                className="customScroll"
                                                style={{ overflow: "auto", display: "grid" }}
                                                onScroll={handleScroll}
                                            >
                                                {messages &&
                                                    [...props.messagesHistory, ...messages].map((msg, index) => {
                                                        if (!!msg.body) {
                                                            let DateCmp = "";
                                                            if (index !== 0) {
                                                                if (
                                                                    moment(msg.createdDate).format(" MMM Do YY") !==
                                                                    moment([...props.messagesHistory, ...messages][index - 1].createdDate).format(" MMM Do YY")
                                                                ) {
                                                                    DateCmp =
                                                                        moment(msg.createdDate)
                                                                            .local()
                                                                            .format("Do MMMM YY") ===
                                                                            moment(new Date()).format("Do MMMM YY")
                                                                            ? "Today"
                                                                            : moment(msg.createdDate)
                                                                                .local()
                                                                                .format("Do MMMM YY");
                                                                } else {
                                                                    DateCmp = "";
                                                                }
                                                            }
                                                            return (
                                                                <>
                                                                    {index === 0 && (
                                                                        <div style={{
                                                                            ...theme.customStyle.displayFlexCenter,
                                                                            ...token && !bottom && theme.customStyle.stickyDate
                                                                        }}>
                                                                            <Chip
                                                                                label={
                                                                                    moment(msg.createdDate)
                                                                                        .local()
                                                                                        .format("Do MMMM YY") ===
                                                                                        moment(new Date()).format("Do MMMM YY")
                                                                                        ? "Today"
                                                                                        : moment(msg.createdDate)
                                                                                            .local()
                                                                                            .format("Do MMMM YY")
                                                                                }
                                                                                style={{
                                                                                    ...theme.customStyle.msgDateStyle,
                                                                                    ...textSize === "Medium"
                                                                                        ? theme.customStyle.webChatMd11
                                                                                        : textSize === "Large"
                                                                                            ? theme.customStyle.webChatLg11
                                                                                            : theme.customStyle.webChatSm11,
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {DateCmp !== "" && (
                                                                        <div style={{
                                                                            ...theme.customStyle.displayFlexCenter,
                                                                            ...token && !bottom && theme.customStyle.stickyDate
                                                                        }}>
                                                                            <Chip
                                                                                label={DateCmp}
                                                                                style={{
                                                                                    ...theme.customStyle.msgDateStyle,
                                                                                    ...textSize === "Medium"
                                                                                        ? theme.customStyle.webChatMd11
                                                                                        : textSize === "Large"
                                                                                            ? theme.customStyle.webChatLg11
                                                                                            : theme.customStyle.webChatSm11
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    )}

                                                                    <ChatMessage
                                                                        settings={rawtheamSettingStrObj}
                                                                        side={`${msg.isAgentMessage === true ? "left" : "right"
                                                                            }`}
                                                                        message={msg}
                                                                        botAvatar={botAvatar}
                                                                        customerName={sessionStorage.getItem("name") || props.memberName}
                                                                        customerNameInitials={sessionStorage.getItem(
                                                                            "name"
                                                                        ) || props.memberName}
                                                                        agentNm={sessionStorage.getItem("name")}
                                                                        showName={
                                                                            index == 0 ||
                                                                                (index != 0
                                                                                    && ([...props.messagesHistory, ...messages][index - 1].isAgentMessage != [...props.messagesHistory, ...messages][index].isAgentMessage //for msg isAgentMessage is not same
                                                                                        || [...props.messagesHistory, ...messages][index - 1].type === 'INFO'))//for prev msg is info 
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        agentImage={props.agentImages[msg.fromId] ? props.agentImages[msg.fromId] : ""}
                                                                        textSize={props.textSize}
                                                                    />
                                                                </>
                                                            );

                                                        }
                                                    })}
                                                {!bottom && token &&
                                                    <IconButton
                                                        sx={theme.customStyle.scrollToBottomBtn}
                                                        size="small"
                                                        title="Scroll to bottom"
                                                        onClick={scrollToBottom}
                                                    >
                                                        <KeyboardDoubleArrowDownIcon />
                                                    </IconButton>}
                                                <div />
                                                {theamSettingsStrObj.enableChatBot === "Y" && msgAutoSuggestions.length > 0 && (
                                                    <div style={styles.autoSuggestMsgDiv}>
                                                        {msgAutoSuggestions.map((item, index) => {
                                                            return (
                                                                <Chip
                                                                    key={index}
                                                                    label={item}
                                                                    color="primary"
                                                                    variant="outlined"
                                                                    clickable
                                                                    size="small"
                                                                    disabled={disabledAutoSuggestionChip ?? false}
                                                                    sx={{
                                                                        ...theme.customStyle.autoSuggestMsgChip,
                                                                        ...textSize === "Medium"
                                                                            ? theme.customStyle.webChatMd12
                                                                            : textSize === "Large"
                                                                                ? theme.customStyle.webChatLg12
                                                                                : theme.customStyle.webChatSm12
                                                                    }}
                                                                    onClick={() => {
                                                                        setDisabledAutoSuggestionChip(true)
                                                                        sendMessage(item);
                                                                    }}
                                                                />
                                                            );
                                                        })}
                                                    </div>
                                                )}
                                                {showChatWithAgentBtn &&
                                                    <div
                                                        style={{ ...styles.autoSuggestMsgDiv, ...msgAutoSuggestions.length > 0 && { paddingTop: 0 } }}
                                                    >
                                                        <Button
                                                            disabled={disabledAutoSuggestionChip ?? false}
                                                            variant="outlined" style={{ textTransform: 'none' }}
                                                            startIcon={<AgentConnected height={"25px"} width={"25px"} />}
                                                            onClick={() => {
                                                                setDisabledAutoSuggestionChip(true)
                                                                sendMessage("Chat With Agent")
                                                            }}>
                                                            Chat With Agent
                                                        </Button>
                                                    </div>}

                                                <div ref={messagesEndRef} />
                                            </div>

                                            {showAgentAssignMsg &&
                                                <WebChatInfoCard
                                                    cardType="ASSIGNING"
                                                    message={theamSettingsStrObj?.agentRequestMessage}
                                                    settings={theamSettingsStrObj}
                                                ></WebChatInfoCard>
                                            }

                                            {waitTimeObj.show &&
                                                <WebChatInfoCard
                                                    cardType="WAITTIME"
                                                    message={waitTimeObj.message}
                                                    settings={theamSettingsStrObj}
                                                ></WebChatInfoCard>
                                            }
                                            {showAutoCloseMessage.show && showAutoCloseMessage.msgWithPlaceholder && !isConversationEnded &&
                                                <WebChatInfoCard
                                                    cardType="AUTOCLOSE"
                                                    message={showAutoCloseMessage.msgWithPlaceholder
                                                        ?.replace(
                                                            "{0}",
                                                            showAutoCloseMessage.idleTimeInMin
                                                        )
                                                        ?.replace(
                                                            "{1}",
                                                            autoClosetimmer.min +
                                                            ":" +
                                                            (autoClosetimmer.sec < 10
                                                                ? `0${autoClosetimmer.sec}`
                                                                : autoClosetimmer.sec)
                                                        )}
                                                    settings={theamSettingsStrObj}
                                                ></WebChatInfoCard>
                                            }
                                            {showDialogtoReconnect && !loading && (
                                                <WebChatInfoCard
                                                    cardType="RECONNECT"
                                                    message={showDialogtoReconnectMsg}
                                                    settings={theamSettingsStrObj}
                                                ></WebChatInfoCard>
                                            )}
                                            {showNonBusinessHoursMsg &&
                                                <WebChatInfoCard
                                                    cardType="NONBUSINESSHOURS"
                                                    message={theamSettingsStrObj.outofBusinessHoursMessage}
                                                    settings={theamSettingsStrObj}
                                                    textSize={textSize}
                                                ></WebChatInfoCard>
                                            }
                                        </Paper>
                                    </Box>
                                    {showTypingPreview && <div style={{ height: "52px", position: 'relative' }}><svg version="1.1" id="L4" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                        viewBox="0 0 64 64" enable-background="new 0 0 0 0" xmlSpace="preserve" style={{ height: "40px", width: "40px", position: "absolute", bottom: "43px", left: "22px" }}>
                                        <circle fill={theamSettingsStrObj.membermsgbgColor} stroke="none" cx="6" cy="50" r="6">
                                            <animate
                                                attributeName="opacity"
                                                dur="1s"
                                                values="0;1;0"
                                                repeatCount="indefinite"
                                                begin="0.1" />
                                        </circle>
                                        <circle fill={theamSettingsStrObj.membermsgbgColor} stroke="none" cx="26" cy="50" r="6">
                                            <animate
                                                attributeName="opacity"
                                                dur="1s"
                                                values="0;1;0"
                                                repeatCount="indefinite"
                                                begin="0.2" />
                                        </circle>
                                        <circle fill={theamSettingsStrObj.membermsgbgColor} stroke="none" cx="46" cy="50" r="6">
                                            <animate
                                                attributeName="opacity"
                                                dur="1s"
                                                values="0;1;0"
                                                repeatCount="indefinite"
                                                begin="0.3" />
                                        </circle>
                                    </svg></div>}
                                </Box>

                            </Grid>
                        </>
                    </div>
                </div>
                {!isConversationEnded && (
                    <Box sx={theme.customStyle.inputMsgTextfieldDiv}>

                        <TextField
                            placeholder="Input Message"
                            disabled={showNonBusinessHoursMsg}
                            type="text"
                            variant="outlined"
                            sx={{
                                width: "100vw",
                                "& .MuiOutlinedInput-notchedOutline legend": {
                                    display: "none",
                                },
                            }}
                            value={newMessage}
                            onChange={setMessage}
                            onClick={handleInputOnClick}
                            onKeyDown={handleKeyDown}
                            onBlur={() => {
                                if (timeOutId > 0) {
                                    //onblur event will always fired when focus-off from textbox
                                    //this block will execute only when we have typed someting & set the timeout
                                    clearTimeout(timeOutId);
                                    setTimeOutId(0);
                                    SendPubSubNotificationForWebChat(TypingPreviewOut)
                                }
                            }}
                            multiline
                            rows={3}
                            maxRows={3} // Maximum number of rows
                            inputProps={{
                                maxLength: 512,
                                style: {
                                    ...textSize === "Medium" && (dimensions > 600 ? theme.customStyle.webChatMd12 : theme.customStyle.webChatMd16),
                                    ...textSize === "Large" && (dimensions > 600 ? theme.customStyle.webChatLg12 : theme.customStyle.webChatLg16),
                                    ...textSize === "Small" && (dimensions > 600 ? theme.customStyle.webChatSm12 : theme.customStyle.webChatSm16)
                                },
                            }}
                            InputLabelProps={{
                                shrink: false,
                                hidden: true,
                            }}
                            InputProps={{
                                sx: {...chatCustomStyleCss.chatInputTextfield,
                                    padding: "8px 4px 12.5px 12px",
                                },
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {sendMessageBtnLoading ? (
                                            <CircularProgress />
                                        ) : (
                                            <IconButton
                                                aria-label="Send Button"
                                                title="Send Message"
                                                disabled={showNonBusinessHoursMsg}
                                                onClick={() => {
                                                    sendMessage(newMessage.trim());
                                                }} //this will call the function on click of button instead of icon.
                                                sx={{
                                                    color: "white",
                                                    backgroundColor: "#001433",
                                                    "&:hover": {
                                                        backgroundColor: "#001433bd",
                                                    },
                                                }}
                                            >
                                                <SendIcon />
                                            </IconButton>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                )}
                {
                    isSessionExpired &&
                    <Dialog
                        open={true}
                        onClose={(event, reason) => {
                            if (reason === "clickaway") {
                                return;
                            }
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Your session is expired!
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() =>
                                handleEndChatForSurvey()
                            }>Ok</Button>

                        </DialogActions>
                    </Dialog>
                }
                {
                    showSurveyDialog && <>
                        <Dialog
                            open={true}
                            onClose={(event, reason) => {
                                if (reason === "clickaway") {
                                    return;
                                }
                            }}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            fullWidth={props.dialogFullWidth}
                        >
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description" sx={{ color: "#202020" }}>
                                    We appreciate your feedback! Kindly take a moment to share your thoughts by participating in a brief survey
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() =>
                                    props.endChat()
                                } sx={{ color: "#0065d1" }}>Not Now</Button>
                                <Button sx={{ color: "#0065d1" }} onClick={() =>
                                    props.endChat("Survey", { surveyId })}>Continue</Button>
                            </DialogActions>
                        </Dialog>
                    </>
                }
                {showConvStartedOnNewWindowDialog && <>
                    <Dialog
                        open={showConvStartedOnNewWindowDialog}
                        onClose={() => { handleCloseConvStartedOnNewWindowDialog() }}
                    >
                        <DialogContent>
                            <DialogContentText>
                                This conversation is started on different device.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() =>
                                handleCloseConvStartedOnNewWindowDialog()
                            }>Ok</Button>
                        </DialogActions>
                    </Dialog>
                </>

                }
            </div>
        </>
    );
}

const styles = {
    convStyle: {
        // height: "calc(100vh - 132px)",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        fontSize: "16px",
        // �backgroundColor: "#E9E9E9"
    },
    autoSuggestMsgDiv: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        paddingTop: "18px",
        paddingBottom: "5px"
    },
};

export default Chat;
