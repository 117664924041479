/*
* 29-08-2023 => PRABHAT => TEXTING - 1633 - Modification of Profile picture button layout in Agent profile CRM View.
* 01-11-2024 => SUDAM B CHAVAN => TEXTING-1943 Contact List UI-Upload file changes
* 01-12-2024 => SUDAM B CHAVAN => TEXTING-1943 Contact List UI-show/hide Icon name on width changes 
                               => Attachment icon size changes
*/

import React from "react";
import IconButton from "@mui/material/IconButton";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import DeleteIcon from "@mui/icons-material/Delete";
import { DropzoneDialog } from "mui-file-dropzone";
import styles from "../docusignCss.module.css"

const AvatarFile = ({
  title,
  label,
  returnFormat,
  onChange,
  acceptedFiles,
  maxFileSize,
  disabled,
  style,
  iconName,
  isMobileView,
  name, value }) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const maxUploadFileSize = maxFileSize ?? 1;

  const handleFileChange = async (files) => {
    console.log(`${label} Uploaded..`, files);
    if (files.length > 0) {
      const file = files[0];
      const maxSize = maxUploadFileSize * 1024 * 1024; // 1MB in bytes
      if (file.size > maxSize) {
        console.log(`File size exceeds the maximum limit of ${maxUploadFileSize}MB.`);
        return;
      }
      if (returnFormat === 'file') {
        onChange(file);
      }
      else {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
          // Converting file to base64 string and updating the logo field in the parent component
          const base64String = reader.result;
          onChange(base64String, name);
          setOpenDialog(false);
        };
      }
      setOpenDialog(false);
    }
  };

  const handleRemoveImage = () => {
    onChange("", name); // Set the value to an empty string to remove the image
  };

  return (
    <div style={style ?? { display: 'flex', justifyContent: 'center' }}>
      {value ? (
        <>
          <div>
            <IconButton onClick={() => setOpenDialog(true)}>
              <PhotoCameraIcon />
            </IconButton>
            <IconButton onClick={handleRemoveImage}>
              <DeleteIcon />
            </IconButton>
          </div>
        </>
      ) : (
        <IconButton disabled={disabled}
            value={iconName}
        onClick={() => setOpenDialog(true)}>
            {!isMobileView && <span
              style={{ fontfamily: "Roboto,Helvetica,Arial,sans-serif",fontWeight: 500,fontSize: "0.875rem",padding:"5px"}}
            >{iconName}</span>} <PhotoCameraIcon />
        </IconButton>
      )}
      <DropzoneDialog
        dialogTitle={title ?? "Maximum limit 1MB"}
        open={openDialog}
        onSave={handleFileChange}
        acceptedFiles={acceptedFiles ?? ["image/*"]}
        showPreviews={true}
        filesLimit={1}
        maxFileSize={maxUploadFileSize * 1024 * 1024}
        onClose={() => setOpenDialog(false)}
        previewGridClasses={{
          // added styles to reduce the preview size.
          item: styles.docusignImg,
          image: styles.docusignImg,
        }}
      />
    </div>
  );
};

export default AvatarFile;
