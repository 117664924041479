
import React, { Component } from 'react';
import { CircularProgress } from '@mui/material';
import { Box} from '@mui/material';
import QuestionsWizard from './questionsWizard';

class SurveyCustomerApp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            surveyId: this.getQueryVariable('surveyId'),
        }
    }

    getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        console.log(query)//"app=article&act=news_content&aid=160990"
        var vars = query.split("&");
        console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
            if (pair[0] == variable) { return pair[1]; }
        }
        return (false);
    }

    render() {
        return (
            <>
                <header id="header">
                    <div id="main-header" style={{ "width": "1348px;" }}>
                        <div className="header-wrapper small" style={{ "min-height": "83px" }}>
                            <div id="logo-container">
                                <div id="logo">
                                    {/* <a href="https://ucla.wescom.org/" title="Wescom Home">
                                        <img aria-label="Wescom Credit Union Home Page" src="https://wescom.org/-/media/Marketing/Feature/PageContent/Base-Page/Header/Wescom-Logo.ashx" alt="Wescom Home" className="desktop" />
                                    </a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
               {
                this.state.surveyId &&
                    <QuestionsWizard
                     surveyId={this.state.surveyId}
                     parentApp={"CustomerApp"}></QuestionsWizard>
               }
                {
                    this.state.showInitLoader == true ?
                        <Box sx={{ display: 'flex', marginLeft: "50%", marginTop: "5%" }}>
                            <CircularProgress />
                        </Box> : <></>
                }
                <footer>
                    <div id="footer">
                        <div className="footer-wrapper">
                            <div className="footer-content">
                                <div className="footer-topbar" style={{ "min-height": "50px" }}>{/**min- height added to adjest footer height */}
                                    {/* <a href="https://ucla.wescom.org/" rel="noopener noreferrer" title="Learn more about this partnership" target="_blank">
                                        <img src="https://wescom.org/-/media/Marketing/Feature/PageContent/Base-Page/Footer/Footer-Badges/Wescom-UCLA-Badge/Ucla_Wescom_logo.ashx?la=en&hash=F0BCC2136D728463EBF6C19EA526D1FB" alt="UCLA | Wescom" />
                                    </a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

            </>
        );
    }

}

export default SurveyCustomerApp;

