/**
 * 12-01-2023 => SUDAM B CHAVAN =>TEXTING-1881- Chat Survey Webchat UI
 * 12-05-2023 => SUDAM B CHAVAN =>TEXTING-1891- Survey : Update Questions Admin UI to add options and save , separated in backend
 * 12-06-2023 => SUDAM B CHAVAN =>TEXTING-1873- Chat Survey Customer Portal with form for Survey Response
 * 12-07-2023 => SUDAM B CHAVAN =>TEXTING-1896 - 0: Survey: Member UI, Admin UI pending observations.
 * 12-08-2023 => SUDAM B CHAVAN =>TEXTING-1896 - Issue 4th font size
 * 12-18-2023 => SUDAM B CHAVAN =>survey link validation
 * 04-09-2024 => SUDAM B CHAVAN => changes related to Implement jwt Token for webchat services
 */
import React, { useState, useEffect } from 'react';
import { InvokePostServiceCall, InvokePostServiceCallWithoutToken, InvokePostWebChatServiceCall } from "../../api/serviceUtil";
import SnackBar from "../../components/SnackBar";
import {
  Box,
  TextField,
  MobileStepper,
  Paper,
  Typography,
  Button,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  CircularProgress,
  IconButton,
  Tooltip,
  Avatar,
  Menu,
} from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import apiConfig from '../../api/apiConfig';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import { SendNotificationToParent } from '../webChat/ChatUtils';
import MinimizeIcon from "@mui/icons-material/Minimize";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

export default function QuestionsWizard(props) {
  const { surveyId, textSize, settings, parentApp, isMobileDevice } = props;
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [serveyResponseArr, setServeyResponseArr] = useState([]);
  const [customerResponse, setCustomerResponse] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [loading, setLoading] = useState(true);
  const [maxSteps, setMaxSteps] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorChatOptionsEl, setAnchorChatOptionsEl] = React.useState(null);
  const openFontSizeMenu = Boolean(anchorEl);
  const openChatOptionsMenu = Boolean(anchorChatOptionsEl);
  const [surveyStatus, setSurveyStatus] = useState("Draft");
  const [successMsg, setSuccessMsg] = useState("");
  const [isSurveySubmitted, setIsSurveySubmitted] = useState(false);
  const [chatCustomStyleCss, setChatCustomStyleCss] = useState(settings?.customStyleCss ? JSON.parse(settings?.customStyleCss) : {});


  useEffect(() => {
    setLoading(true);
    InvokePostWebChatServiceCall(apiConfig.GET_SURVEY_QUESTIONS_BY_SURVEYID, {
      surveyId: surveyId
    })
      .then((data) => {
        console.log(data.data, "survey question for customer data");
        if (data.data.status === "FAILED" && data.data.statusDescription){
          setSuccessMsg(data.data.statusDescription);
        }
        else if (data.data.surveyQuestions.length > 0) {
          setMaxSteps(data.data.surveyQuestions.length)
          setServeyResponseArr(data.data.surveyQuestions);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "errorData");
        setSnackbarMsg(err.message);
        setOpenSnackbar(true);
        setLoading(false);
      });
  }, []);
  // useEffect(() => {
  //   return () => {
  //     console.log("cleaned up :: ", surveyStatus);
  //     if (surveyStatus === "Draft"){
  //     handleSubmitCustomerResponse("Draft");
  //   }
  //   };
  // }, []);
  const handleNext = () => {
    debugger
    saveCustomerResponse();
    if (activeStep === maxSteps - 1) {
      handleSubmitCustomerResponse("Submitted");
    }
    else {
      setCustomerResponse(serveyResponseArr[activeStep + 1].responseBody ?? "")// customer response for next quesrion if available
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    saveCustomerResponse();
    setCustomerResponse(serveyResponseArr[activeStep - 1].responseBody)// customer response for pre quesrion if available
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    console.log("rednfkjsndv :", serveyResponseArr)
  };
  const saveCustomerResponse = () => {
    serveyResponseArr[activeStep].responseBody = customerResponse;
    setServeyResponseArr(serveyResponseArr);
  }
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    if (severity === "success" && parentApp === "WebChat") {
      props.endChat();
    }
    setOpenSnackbar(false);
  };
  const handleSubmitCustomerResponse = (status) => {
    setIsSurveySubmitted(true)
    console.log("function Executed")
    setLoadingBtn(true);
    setSurveyStatus(status)
    debugger
    let requestBody = {
      SurveyId: serveyResponseArr[0].surveyId,
      SurveyStatus: status,
      SurveyResponse: serveyResponseArr
    };
    console.log("SAVE_SURVEY_RESPONSE -> requestBody", requestBody);
    InvokePostWebChatServiceCall(
      apiConfig.SAVE_SURVEY_RESPONSE,
      requestBody
    )
      .then((data) => {
        setLoadingBtn(false);
        if (status === "Draft" && parentApp === "WebChat") {
          props.endChat();
        }
        else{
        setSeverity("success");
        setSnackbarMsg(`Survey submitted successfully`);
        setSuccessMsg("Survey submitted successfully, you can close this window");
        setOpenSnackbar(true);
      }
      })
      .catch((err) => {
        setIsSurveySubmitted(false)
        setSeverity("error")
        setSnackbarMsg(err.message)
        console.log(err.message, "ERROR DATA");
        setOpenSnackbar(true);
        setLoadingBtn(false);
      });
  }
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFontSizeMenuClose = () => {
    setAnchorEl(null);
    setAnchorChatOptionsEl(null);
  };
  // Handler function to minimize chat window
  const minimizeChat = (event) => {
    sessionStorage.setItem("chatStatus", false);
    sessionStorage.setItem("tabIsActive", false);
    SendNotificationToParent({ event: "clearNewMessageNotification", data: "clearNewMessageNotification", targetOrigin: props.settings?.parentSite });
    SendNotificationToParent({ event: "minimizeWindow", data: "minimizeWindow", targetOrigin: props.settings?.parentSite });
    props.setIsChatOpen(false)
  };
  return (
    <>
      <SnackBar
        openSnackbar={openSnackbar}
        handleCloseSnackbar={handleCloseSnackbar}
        severity={severity}
        userMessage={snackbarMsg}
        {...parentApp === "WebChat" && {
        marginTop:6}}
      />
      {
        loading === true ?
          <Box sx={{ display: 'flex', marginLeft: "50%", marginTop: "5%" }}>
            <CircularProgress />
          </Box> :
          serveyResponseArr.length > 0 &&
          <> 
          
            {parentApp === "WebChat" &&
              <Box sx={{ ...theme.customStyle.chatHeaderDiv, ...chatCustomStyleCss.chatHeader,...(isMobileDevice ? { padding: "0px",borderRadius:"0px" } : {})}}>
                {/* for mobile device show minimize chat as back btn */}
                {isMobileDevice && <Tooltip >
                  <IconButton
                    color="inherit"
                    onClick={minimizeChat}
                  >
                    <KeyboardArrowLeftIcon sx={{ fontSize: 35 }} />
                  </IconButton>
                </Tooltip>}
                {!isMobileDevice&& //for mobile device not show avatar
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {settings?.companyLogo &&
                      <Avatar sx={{ width: "36px", height: "36px" }} src={settings.companyLogo}>
                        {/* <SmartToyOutlinedIcon /> */}
                      </Avatar>}
                  </div>}
            <div>
              <Tooltip title="Font Size">
                <IconButton
                  id="basic-button"
                  aria-controls={openFontSizeMenu ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openFontSizeMenu ? 'true' : undefined}
                  color="inherit"
                  onClick={(e) => { handleClickListItem(e) }}
                >
                  <FormatSizeIcon />
                </IconButton>
              </Tooltip>
                  {!isMobileDevice && //for mobile device not show minimize chat btn
              <Tooltip title="Minimize Chat">
                <IconButton
                  color="inherit"
                  onClick={minimizeChat}
                  sx={{ marginTop: "-20px" }}
                >
                  <MinimizeIcon />
                </IconButton>
              </Tooltip>}
              <Tooltip title="End Chat">
                <IconButton
                  color="inherit"
                  onClick={async () => {
                    handleSubmitCustomerResponse("Draft");
                      // props.endChat();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openFontSizeMenu}
                onClose={handleFontSizeMenuClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                  sx: { paddingTop: "0px", paddingBottom: "0px" }
                }}
              >
                <MenuItem selected={props.textSize === 'Small'} onClick={() => { props.setFontSize('Small'); handleFontSizeMenuClose(); }}>Small</MenuItem>
                <MenuItem selected={props.textSize === 'Medium'} onClick={() => { props.setFontSize('Medium'); handleFontSizeMenuClose(); }}>Medium</MenuItem>
                <MenuItem selected={props.textSize === 'Large'} onClick={() => { props.setFontSize('Large'); handleFontSizeMenuClose(); }}>Large</MenuItem>
              </Menu>
              <Menu
                id="menu-button"
                anchorEl={anchorChatOptionsEl}
                open={openChatOptionsMenu}
                onClose={handleFontSizeMenuClose}
                MenuListProps={{
                  'aria-labelledby': 'menu-button',
                  sx: { paddingTop: "0px", paddingBottom: "0px" }
                }}
              >
              </Menu>
            </div>
          </Box>}
            <Box 
              style={{
                ...theme.customStyle?.chatBoxStyle,
                ...textSize === "Medium"
                  ? theme.customStyle?.webChatMd11
                  : textSize === "Large"
                    ? theme.customStyle?.webChatLg11
                    : theme.customStyle?.webChatSm11,
              }}
            >
              {!successMsg &&
              <Box className="wcu_box_padding" sx={{padding:"0px"}} >
                <Box sx={{ height: "calc(100vh - 22vh)", flexGrow: 1, px:2}}>
                    <h3 style={{
                      ...textSize === "Medium" ? theme.customStyle?.webChatMd16
                        : textSize === "Large" ? theme.customStyle?.webChatLg16 : theme.customStyle?.webChatSm16, textAlign:"center"}}>{`Survey - ${activeStep + 1}/${maxSteps}`}</h3>
                    <Box sx={styles.textRow}>
                      <MobileStepper
                        variant="progress"
                        steps={maxSteps + 1}
                        position="static"
                        activeStep={activeStep + 1}
                        sx={{ ...styles.surveyProgressBar, flexGrow: 2}}
                    />
                    </Box>
                  <Paper
                    square
                    elevation={0}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: 50,
                      bgcolor: 'background.default',
                    }}
                  >
                      <Typography
                        style={{
                          ...textSize === "Medium"
                            ? theme.customStyle?.webChatMd11
                            : textSize === "Large"
                              ? theme.customStyle?.webChatLg11
                              : theme.customStyle?.webChatSm11,
                        }}
                      >
                        {serveyResponseArr[activeStep].answerRequired === true?'*':''} {serveyResponseArr[activeStep].questionBody}</Typography>
                  </Paper>
                  {/* <Box sx={{ height: 255, maxWidth: 400, width: '100%', p: 2 }}>
        {serveyResponseArr[activeStep].questionBody}
      </Box> */}
                  {serveyResponseArr[activeStep].questionType === "Text" &&
                    <Box sx={styles.textRow}>
                      <TextField
                        margin="dense"
                        value={customerResponse}
                        type="text"
                        fullWidth
                        variant="standard"
                        size="small"
                        id="txtResponseBody"
                          sx={{
                            ...textSize === "Medium" ? theme.customStyle?.webChatMd11
                              : textSize === "Large" ? theme.customStyle?.webChatLg11 : theme.customStyle?.webChatSm11,
                          }}
                        inputProps={{ //not show suggetions
                          autoComplete: 'new-password',
                          form: {
                            autocomplete: 'off',
                          },
                            style:{
                          ...textSize === "Medium"? theme.customStyle?.webChatMd11
                            : textSize === "Large"? theme.customStyle?.webChatLg11: theme.customStyle?.webChatSm11,
                        }
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => {
                          setCustomerResponse(e.target.value);
                        }}
                      />
                    </Box>}
                  {serveyResponseArr[activeStep].questionType === "Radio" &&
                    <Box sx={(styles.textRow, styles.mrT)}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          defaultValue="individual"
                          name="radio-buttons-group"
                          value={customerResponse}
                          onChange={(e) => {
                            setCustomerResponse(e.target.value);
                          }}
                            sx={{
                              ...textSize === "Medium" ? theme.customStyle?.webChatMd11
                                : textSize === "Large" ? theme.customStyle?.webChatLg11 : theme.customStyle?.webChatSm11,
                            }}
                        >
                          {serveyResponseArr[activeStep].options.split("##SEP##").map((item, index) => {
                            const value = item.trim();
                            return (
                              <FormControlLabel
                                sx={{
                                  "& span": {...textSize === "Medium" ? theme.customStyle?.webChatMd11
                                    : textSize === "Large" ? theme.customStyle?.webChatLg11 : theme.customStyle?.webChatSm11,
                                }}
                              }
                                value={value}
                                control={<Radio />}
                                label={value}
                              />
                            );
                          })}
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  }
                  {serveyResponseArr[activeStep].questionType === "Dropdown" &&
                    <Box sx={{ ...styles.textRow, marginTop: 2.5 }}>
                      <TextField
                        size="small"
                        select
                        defaultValue={customerResponse}
                        value={customerResponse}
                        fullWidth
                        id="ddlResponseBody"
                          sx={{
                            "& .MuiInput-input": {
                              ...textSize === "Medium" ? theme.customStyle?.webChatMd11
                                : textSize === "Large" ? theme.customStyle?.webChatLg11 : theme.customStyle?.webChatSm11,

                            }
                          }}
                        inputProps={{ //not show suggetions
                          autoComplete: 'new-password',
                          form: {
                            autocomplete: 'off',
                          },
                          style: {
                            ...textSize === "Medium" ? theme.customStyle?.webChatMd11
                              : textSize === "Large" ? theme.customStyle?.webChatLg11 : theme.customStyle?.webChatSm11,
                          }
                        }}
                        onChange={(e) => {
                          setCustomerResponse(e.target.value);
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="standard"
                      >
                          {serveyResponseArr[activeStep].options.split("##SEP##").map((item, index) => {
                          const value = item.trim();
                          return (
                            <MenuItem value={value}
                              sx={{
                                ...textSize === "Medium" ? theme.customStyle?.webChatMd11
                                  : textSize === "Large" ? theme.customStyle?.webChatLg11 : theme.customStyle?.webChatSm11,
                              }}
                              style={{
                                ...textSize === "Medium"
                                  ? theme.customStyle?.webChatMd11
                                  : textSize === "Large"
                                    ? theme.customStyle?.webChatLg11
                                    : theme.customStyle?.webChatSm11,
                              }}
                            >
                              {value}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Box>}
                </Box>
                <MobileStepper
                  variant="text"
                  steps={maxSteps}
                  position="static"
                  activeStep={activeStep}
                  aria-disabled={isSurveySubmitted}
                  sx={{
                      position: "absolute",
                      width:"100%",
                      bottom: "50px"
                   }}
                  nextButton={
                    <Button
                      size="large"
                      onClick={handleNext}
                      sx={{
                        ...textSize === "Medium" ? theme.customStyle?.webChatMd11
                          : textSize === "Large" ? theme.customStyle?.webChatLg11 : theme.customStyle?.webChatSm11,
                      }}
                      disabled={(serveyResponseArr[activeStep].answerRequired === true && !customerResponse)//for required question
                        || isSurveySubmitted //for survey submitted 
                      }
                    >
                      {activeStep === maxSteps - 1 ? "Submit" : "Next"}
                      {theme.direction === 'rtl' ? (
                        <KeyboardArrowLeft />
                      ) : activeStep !== maxSteps - 1 && (
                        <KeyboardArrowRight />
                      )}
                    </Button>
                  }
                  backButton={
                    <Button size="large" onClick={handleBack} disabled={activeStep === 0 || isSurveySubmitted}
                      sx={{
                        ...textSize === "Medium" ? theme.customStyle?.webChatMd11
                          : textSize === "Large" ? theme.customStyle?.webChatLg11 : theme.customStyle?.webChatSm11,
                      }}
                    >
                      {theme.direction === 'rtl' ? (
                        <KeyboardArrowRight />
                      ) : (
                        <KeyboardArrowLeft />
                      )}
                      Back
                    </Button>
                  }
                />
                {/* <LoadingButton
              loading={loadingBtn}
              disabled={!(activeStep === maxSteps - 1)}

              sx={styles.bottomButton}
              variant="contained"
              onClick={() => {
                handleSubmitCustomerResponse();
              }}
            >
              Submit
            </LoadingButton> */}
              </Box>}
            </Box>
          </>
      }
      {parentApp === "CustomerApp" &&
        <Box sx={{ display: "flex", flexFlow: "row", justifyContent: "center", textAlign: 'center' }}>
          {successMsg && <h5 style={{ color: "green", textAlign: "center" }}>{successMsg}</h5>}
        </Box>}
    </>

  );
}
let styles = {
  surveyProgressBar: {
    "& span": {
      width: "100% !important",
    },
  },
  textRow: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 1.5,
    "& h2": {
      paddingLeft: "0",
    },
  },
  mrT: {
    marginTop: "10px",
  },
  bottomButton: {
    marginBottom: "20px",
  },
};