//// 05/05/2023 => ONKAR => Webchat to send page level information when conversation started
// 08-30-2023 => ONKAR => TEXTING-1578 -REACT Upgrade 
import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Divider } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";


function ConversationAttributesInfo(props) {
  const { conversation } = props;
  let convAttributes = conversation.conversationAttributes
    ? JSON.parse(conversation.conversationAttributes)
    : "";
  const theme = useTheme();
  let columns = Object.keys(convAttributes);
  let rows = Object.values(convAttributes);
  console.log(columns, "columns");

  return (
    <>
      {conversation.conversationAttributes ? (
        <>
          <div
            style={{
              ...theme.customStyle.infoListStyle,
              paddingTop: "0px",
              marginBottom: props.isViewMore ? "10px" : "0px",
            }}
          >
            <div style={theme.customStyle.infoListLabel}>
              Conversation Attributes:
            </div>

            {!props.isViewMore && (
              <div>
                <span
                  style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    display: "inline-block",
                    width: "calc(100% - 100px)",
                  }}
                >
                  {conversation.conversationAttributes}
                </span>
                <Button
                  size="small"
                  style={{ textTransform: "capitalize", float: "right" }}
                  onClick={() => props.setIsViewMore(!props.isViewMore)}
                  autoFocus
                >
                  <span>
                    <strong> view more</strong>
                  </span>
                </Button>
              </div>
            )}
          </div>
          {props.isViewMore && (
            <Box
              sx={{
                ...theme.customStyle.tableHead,
                height: "450px",
                padding: "0px",
              }}
            >
              <TableContainer component={Paper}>
                <Table aria-label="simple table" sx={{ minWidth: "382px" }}>
                  <TableHead sx={theme.customStyle.customTableTHead}>
                    <TableRow>
                      {columns.map((item) => {
                        return <TableCell key={item}>{item}</TableCell>;
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody sx={theme.customStyle.customTableTBody}>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {rows.map((item) => {
                        return (
                          <TableCell key={item} component="th" scope="row">
                            {item}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <div>
                <Button
                  size="small"
                  style={{
                    textTransform: "capitalize",
                    float: "right",
                    marginTop: "10px",
                  }}
                  onClick={() => props.setIsViewMore(!props.isViewMore)}
                  autoFocus
                >
                  <span>
                    <strong> view less</strong>
                  </span>
                </Button>
              </div>
            </Box>
          )}
          <Divider/>
        </>
      ) : null}
    </>
  );
}

export default ConversationAttributesInfo;
