
/***************
* 11/01/2022  TEXTING-740 ; 
* 11-11/2022 TextField:766=>disabled auto suggetion
* 11-22-2022 => ONKAR => Texting-806 Error while saving the JSON content on JSON editor (On user upadate and Settings Update), replace Json editor with normal textfield.
* 04-26-2023 => SUDAM B CHAVAN => TEXTING-1200 - Issue 1 - If user department is none then user is able to see templates with none department only.
* 07-21-2023 => SUDAM B CHAVAN => TEXTING-1468 - Store agent image on texting db
* 07-31-2023 => PRABHAT => TEXTING-1500 - Image upload in user details observations.
* 08-01-2023 => PRABHAT => TEXTING-1481 - On Add user page, when click on enter button on Email field with error - Admin UI page is becoming blank is fixed.
* 08-18-2023 => SUDAM B CHAVAN => TEXTING-1579 - Login History
* 29-08-2023 => PRABHAT => TEXTING - 1633 - Modification of Profile picture button layout in Agent profile CRM View.
* 08-29-2023 => ONKAR => TEXTING-1578 -REACT Upgrade
* 12-19-2023 => ONKAR => TEXTING-1935 Templates Approval Flow - UI
* 01-24-2024 => SUDAM B CHAVAN => TEXTING-1961 User preferred team is changing to first team in the dro
***********/
import React, { useState, useEffect, useContext } from "react";
import apiConfig from "../../api/apiConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import { useTheme } from "@mui/material/styles";
import SnackBar from "../../components/SnackBar";
import LoginHistory from "./LoginHistory";
import {
    Box,
    Checkbox,
    CircularProgress,
    Select,
    InputLabel,
    FormControl,
    TextField,
    Button,
    MenuItem,
    Collapse,
    Avatar,
    Label
} from "@mui/material";
import { AuthContext } from "../../AuthContext";
import JSONInput from "react-json-editor-ajrm";
import moment from "moment";
import LoadingButton from "@mui/lab/LoadingButton";

import AvatarFile from "../../components/AvatarFileComp";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


export default function AddUpdateUser() {
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const AddUser = "Add User";
    const UpdateUser = "Update User";
    const UserDetail = "User Details";
    let regEmail = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i;
    let numberRegex = /^(0|[1-9]\d*)$/;
    const isAddUser = location.state ? location.state.isAddUser : true;
    const isProfile = location.state ? location.state.isProfile : false;
    const userPId = isProfile ? location.state.userId : 0;
    const [teams, setTeams] = useState([]);
    const [teamData, setTeamData] = useState([]);
    const [teamsList, setTeamsList] = useState([]);
    const [teamList1, setteamList1] = useState([]);
    const [loading, setLoading] = useState(false);
    const [lookup, setLookup] = useState(false);
    const [addTeam, setAddTeam] = useState([]);
    const [severity, setSeverity] = useState("success");
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [userTeams, setUserTeams] = useState([]);
    const [openLoginHistory, setOpenLoginHistory] = useState(false);
    const [openUploadProfileImgDialog, setOpenUploadProfileImgDialog] = useState(false);
    const [ProfileImgBase64, setProfileImgBase64] = useState("");
    const { messageApiToken } = useContext(AuthContext);
    const {
        userInfo, setUserInfo,
        contactsFilterData,
        contactsListFilterData,
        usersFilterData,departments
    } = useContext(AuthContext);

    const [defTeamId, setdefTeamId] = useState(userInfo.teamId);
    const [userData, setUserData] = useState({
        applicationUserId: null,
        phoneNumber: null,
        clientFacingPhoneNum: null,
        userName: null,
        azureAdid: null,
        isActive: isAddUser ? true : location.state.isProfile ? true : location.state.userDetails.isActive,
        firstName: null,
        lastName: null,
        emailId: null,
        team: [],
        role: null,
        userAttributes: "",
        maxConversations: "",
        userPreferences: "",
        department : ""
    });
    const [modifiedBy, setModifiedBy] = useState({
        lastModifiedByName: "",
        lastModifiedDate: "",
    });
    const [createdBy, setCreatedBy] = useState({
        createdByName: "",
        createdDate: "",
    })
    const [userAttributesJson, setUserAttributesJson] = useState("");
    const [collapse, setCollapse] = useState(false)
    const [jsonError, setJsonError] = useState(false);
    const [validJson, setValidJson] = useState(false);
    const [emailErr, setEmailErr] = useState(false);
    const [phoneErr, setPhoneErr] = useState(false);
    const [clientFacingPhoneErr, setClientFacingPhoneErr] = useState(false);
    const [maxConvErr, setMaxConvErr] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [forceLookup, setForceLookup] = useState(false);

    //get data related to the user selected from the table (works only for update user)

    useEffect(() => {
        if (messageApiToken) {
            if (!isAddUser) {
                setLoading(true);
                let requestBody = {
                    userId: isProfile ? userPId : location.state.userDetails.userId,
                };
                InvokePostServiceCall(apiConfig.GET_USERS, requestBody)
                    .then((data) => {
                        let userDetails = data.data.userDetails[0].user;
                        console.log(userDetails, "()(()");
                        setUserData({
                            userId: userDetails.userId,
                            applicationUserId: userDetails.applicationUserId,
                            phoneNumber: userDetails.phoneNumber,
                            clientFacingPhoneNum: userDetails.clientFacingPhoneNum,
                            userName: userDetails.userName,
                            azureAdid: userDetails.azureAdid,
                            firstName: userDetails.firstName,
                            lastName: userDetails.lastName,
                            emailId: userDetails.emailId,
                            role: userDetails.role,
                            isActive: userDetails.isActive,
                            userAttributes: userDetails.userAttributes,
                            maxConversations: userDetails.maxConversations,
                            userPreferences: userDetails.userPreferences,
                            department: userDetails.department === "" ? "None" : userDetails.department,
                            lastLoginDate: userDetails?.lastLoginDate ? moment(userDetails?.lastLoginDate).local().format("LLL") : "",
                        });
                        setProfileImgBase64(userDetails.profileImg)
                        setModifiedBy({
                            lastModifiedByName: userDetails.lastModifiedByName,
                            lastModifiedDate: moment(userDetails.lastModifiedDate).local().format("LLL"),
                        })
                        setCreatedBy({
                            createdByName: userDetails.createdByName,
                            createdDate: moment(userDetails.createdDate).local().format("LLL")
                        })
                        setUserAttributesJson(userDetails.userAttributes);
                        try {
                            JSON.parse(userDetails.userAttributes);
                            setValidJson(true);
                        } catch (e) {
                            setValidJson(false);
                        }
                        if (data.data.userDetails[0].teams.length > 0) {
                            let teamArr = [];
                            data.data.userDetails[0].teams.forEach((item) => {
                                teamArr.push(item.team.teamId);
                            });
                            setTeams(teamArr);
                            setTeamData(teamArr);
                            setUserTeams(data.data.userDetails[0].teams);
                            var userpreferenceObj = isJsonString(userDetails.userPreferences) ? JSON.parse(userDetails.userPreferences) : null;
                            if (userpreferenceObj?.DefaultTeamId) {
                                setdefTeamId(userpreferenceObj.DefaultTeamId);
                            }
                            else if (userInfo.teams && userInfo.teams.length == 1) {
                                setdefTeamId(userInfo.teams[0].teamId);
                            }
                        }
                        setLoading(false);
                    })
                    .catch((error) => {
                        setSeverity("error");
                        setSnackbarMsg(error.message);
                        handleOpenSnackbar();
                        console.log(error.message);
                        setLoading(false);
                    });
            }
        }
    }, [isAddUser, messageApiToken]);
    const isJsonString = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    //get team list after render
    useEffect(() => {
        if (messageApiToken) {
            const teamsArr = [];
            InvokePostServiceCall(apiConfig.GET_TEAMS, { isActive: true, userId: userData.userId })
                .then((data) => {
                    let teamData = data.data.teamDetails;
                    if (teamData.length > 0) {
                        teamData.forEach((item, index) => {
                            teamsArr.push({
                                team: {
                                    teamId: item.team.teamId,
                                    teamName: item.team.teamName,
                                    type: item.team.type,
                                    msgSupportNumber: item.team.msgSupportNumber,
                                    voiceSupportNumber: item.team.voiceSupportNumber,
                                    shortCode: item.team.shortCode,
                                },
                                actionType: "Add",
                                users: item.users,
                            });
                        });
                    }
                    setTeamsList(teamsArr);
                    setteamList1(userInfo.teams);
                })
                .catch((error) => {
                    setSeverity("error");
                    setSnackbarMsg(error.message);
                    handleOpenSnackbar();
                    console.log("teamsApi", error);
                });
        }
    }, [messageApiToken]);

    useEffect(() => {
        if (!isAddUser && !isProfile) {
            if (location.state.userDetails.emailId !== userData.emailId) {
                setForceLookup(true);
            } else {
                setForceLookup(false);
            }
        } else {
            if (!isProfile)
                setForceLookup(true);
            else
                setForceLookup(false);
        }
    }, [userData.emailId])

    //function for multiple team select
    const handleChangeTeams = (event) => {
        let teamArr = [];
        const {
            target: { value },
        } = event;
        setTeams(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value
        );
        value.forEach((item) => {
            teamData.forEach((teamId) => {
                if (teamId !== item) {
                    teamArr.push({
                        team: {
                            teamId: item,
                        },
                        actionType: "Add",

                        users: [],
                    });
                }
            });
        });
        teamData.forEach((teamId) => {
            let isPresent = value.includes(teamId);
            if (!isPresent) {
                teamArr.push({
                    team: {
                        teamId: teamId,
                    },
                    actionType: "Delete",

                    users: [],
                });
            }
        });

        setAddTeam(teamArr);
    };
    const handleChangeRole = (event) => {
        setUserData({ ...userData, role: event.target.value });
    };

    const handleChangeDefaultTeam = (event) => {
        setdefTeamId(event.target.value);
        if (userInfo.userId == userData.userId) {     //Update userInfo if the loggedin user is updating data for himself
            userInfo.teamId = event.target.value;
            setUserInfo(userInfo);
        }
        let userpreference = JSON.stringify({
            ContactsFilter: {
                firstName: contactsFilterData.firstName,
                lastName: contactsFilterData.lastName,
                phone: contactsFilterData.phone,
                email: contactsFilterData.email,
                teamId: contactsFilterData.teamId,
            },
            UsersFilter: {
                firstName: usersFilterData.firstName,
                lastName: usersFilterData.lastName,
                phone: usersFilterData.phone,
                email: usersFilterData.email,
                teamId: usersFilterData.teamId,
                isActive: usersFilterData.isActive
            },
            ContactsListFilter: contactsListFilterData,
            DefaultTeamId: event.target.value,
        })

        setUserData({ ...userData, userPreferences: userpreference });
    };

    const handleLookup = (mail) => {
        setLookup(true);
        if (regEmail.test(mail)) {
            InvokePostServiceCall(apiConfig.LOOKUP_USER, { emailId: mail })
                .then((data) => {
                    setLookup(false);
                    setForceLookup(false);
                    setUserData({
                        ...userData,
                        applicationUserId: data.data.user.applicationUserId,
                        phoneNumber: data.data.user.phoneNumber !== null ? data.data.user.phoneNumber : "",
                        clientFacingPhoneNum: data.data.user.clientFacingPhoneNum !== null ? data.data.user.clientFacingPhoneNum : "",
                        userName: data.data.user.userName,
                        azureAdid: data.data.user.azureAdid,
                        firstName: data.data.user.firstName !== null ? data.data.user.firstName : "",
                        lastName: data.data.user.lastName !== null ? data.data.user.lastName : "",
                        emailId: data.data.user.emailId,
                        isActive: isAddUser ? true : userData.isActive,
                        department: userData.department == 'None' ? "" : userData.department,
                        maxConversations: isAddUser ? "" : userData.maxConversations
                    });
                })
                .catch((error) => {
                    setLookup(false);
                    setSeverity("error")
                    setSnackbarMsg("No User Found")
                    console.log("api response111", error);
                    handleOpenSnackbar();
                });
        } else {
            setLookup(false);
            setSeverity("error")
            setEmailErr(true)
            setSnackbarMsg("Invalid Data")
            handleOpenSnackbar();
        }
    };

    const handleAddUpdateUser = () => {
        let chkmobile = false;
        let validClientFacingPhone = false;
        const PHONE_REGEX = /^[+][0-9]{11}$/i
        let PHONE_US = /^\([0-9]{3}\) |[0-9]{3}-[0-9]{3}-[0-9]{4}$/i;
        const PHONE_INDIAN_REGEX = /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/i;
        if (userData.phoneNumber?.startsWith("+91")) {
            if (PHONE_INDIAN_REGEX.test(userData.phoneNumber)) {
                chkmobile = true;
            }
        }
        else {
            if (PHONE_REGEX.test(userData.phoneNumber)) {
                chkmobile = true;
            } else if (PHONE_US.test(userData.phoneNumber)) {
                chkmobile = true;
            }
        }
        if (userData.clientFacingPhoneNum?.length > 0) {
            if (userData.clientFacingPhoneNum?.startsWith("+91")) {
                if (PHONE_INDIAN_REGEX.test(userData.clientFacingPhoneNum)) {
                    validClientFacingPhone = true;
                }
            }
            else if (PHONE_REGEX.test(userData.clientFacingPhoneNum)) {
                validClientFacingPhone = true;
            }
            else if (PHONE_US.test(userData.clientFacingPhoneNum)) {
                validClientFacingPhone = true;
            }
        }
        else {
            validClientFacingPhone = true;
        }
        if (forceLookup) {
            setSnackbarMsg("There is a change in Email ID, please do Lookup user before saving the record.");
            setSeverity("warning");
            handleOpenSnackbar();
        } else {
            if ((userData.phoneNumber?.length ? chkmobile : true) && regEmail.test(userData.emailId) && validClientFacingPhone && numberRegex.test(userData.maxConversations) && (userData.userAttributes ? isJsonString(userData.userAttributes) : true)) {
                setLoadingBtn(true);
                if (userData.department === "None") {
                    userData.department = "";//update department if selected value is None 
                }
                let requestBody = {
                    userDetail: {
                        user: { ...userData, profileImg: ProfileImgBase64 },
                        actionType: isAddUser ? "Add" : "Update",
                        teams: addTeam,
                    },
                };
                InvokePostServiceCall(apiConfig.ADD_UPDATE_USER, requestBody)
                    .then((data) => {
                        if (data.data.status ==="FAILED"){
                            setSnackbarMsg(data.data.errorDescription);
                            setSeverity("error");
                        }
                        else{
                        setSnackbarMsg(isAddUser ? "User Added Successfully." : "User Updated Successfully");
                        setSeverity("success");
                    }
                        handleOpenSnackbar();
                        setLoadingBtn(false);
                    })
                    .catch((err) => {
                        setSeverity("error")
                        setSnackbarMsg(err.message);
                        console.log(err, "adduserError");
                        handleOpenSnackbar();
                        setLoadingBtn(false);
                    });
            }
            else {
                setLookup(false);
                setSeverity("error")
                if (userData.phoneNumber && !chkmobile) {
                    setPhoneErr(true)
                }
                if (!regEmail.test(userData.emailId)) {
                    setEmailErr(true)
                }
                if (!numberRegex.test(userData.maxConversations)) {
                    setMaxConvErr(true);
                }
                if (userData.userAttributes && !isJsonString(userData.userAttributes)) {
                    setJsonError(true);
                }
                if (!validClientFacingPhone) {
                    setClientFacingPhoneErr(true);
                }
                setSnackbarMsg("Invalid Data")
                handleOpenSnackbar();
            }
        }
    };
    const [openSnackbar, setOpenSnackbar] = React.useState(false);

    const handleOpenSnackbar = () => {
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        if (severity === "success") {
            navigate("/users", { replace: true });
        }
        setOpenSnackbar(false);

    };
    const handleChange = (event) => {
        setUserData({ ...userData, isActive: event.target.checked });
    };

    return (
        <>
            <SnackBar
                openSnackbar={openSnackbar}
                handleCloseSnackbar={handleCloseSnackbar}
                severity={severity}
                userMessage={
                    snackbarMsg
                }
            />
            <div style={{ marginLeft: 20, marginRight: 20 }}>
                {loading && !isAddUser ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "15%",
                        }}
                    >
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <h2>{isProfile ? UserDetail : isAddUser ? AddUser : UpdateUser}</h2>
                            <Box sx={{ ...theme.customStyle.textRow, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div>
                                    <InputLabel htmlFor="upload-avatar-pic">
                                        <Avatar
                                            alt="Remy Sharp"
                                            src={ProfileImgBase64} // Use the state variable to display the uploaded image
                                            sx={{ width: 140, height: 140 }}
                                        />
                                    </InputLabel>
                                    <AvatarFile
                                        name="avatar" // Provide a unique name for the field (optional)
                                        label="Profile Picture" // Label for the field (optional)
                                        value={ProfileImgBase64} // Pass the profile image value from the state
                                        onChange={(base64String) => setProfileImgBase64(base64String)} // Update the state with the uploaded image data
                                    />
                                </div>
                            </Box>
                        <Box sx={theme.customStyle.textRow}>
                            <TextField
                                size="small"
                                margin="dense"
                                required
                                disabled={isProfile}
                                value={userData.emailId}
                                error={emailErr}
                                label="Email Id"
                                type="text"
                                fullWidth
                                variant="standard"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                id="updateUseremailId"
                                inputProps={{ //not show suggetions
                                    autoComplete: 'new-password',
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                                    onChange={(e) => {
                                        setEmailErr(false);
                                        let value = e.target.value;
                                        setUserData((state) => ({
                                            ...state,
                                            emailId: value,
                                            forceLookup: state.emailId !== value,
                                        }));
                                    }}
                                helperText={emailErr ? "Invalid Email Id" : ""}
                            />
                            {!isProfile ?
                                <Button
                                    size="small"
                                    disabled={isProfile ? true : lookup}
                                    onClick={() => {
                                        handleLookup(userData.emailId);
                                    }}
                                    sx={theme.customStyle.modalbtn02}
                                >
                                    Look-Up
                                </Button> : null}
                        </Box>
                        <Box sx={theme.customStyle.textRow}>
                            <TextField
                                size="small"
                                margin="dense"
                                disabled={isProfile}
                                required
                                value={userData.firstName}
                                label="First Name"
                                type="text"
                                fullWidth
                                variant="standard"
                                id="updateUserfirstName"
                                inputProps={{ //not show suggetions
                                    autoComplete: 'new-password',
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => {
                                    setUserData({ ...userData, firstName: e.target.value });
                                }}
                            />
                        </Box>

                        <Box sx={theme.customStyle.textRow}>
                            <TextField
                                size="small"
                                margin="dense"
                                required
                                disabled={isProfile}
                                value={userData.lastName}
                                label="Last Name"
                                type="text"
                                fullWidth
                                variant="standard"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                id="updateUserlastName"
                                inputProps={{ //not show suggetions
                                    autoComplete: 'new-password',
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                                onChange={(e) => {
                                    setUserData({ ...userData, lastName: e.target.value });
                                }}
                            />
                        </Box>

                        <Box sx={theme.customStyle.textRow}>
                            <TextField
                                size="small"
                                margin="dense"
                                disabled={isProfile}
                                error={phoneErr}
                                value={userData.phoneNumber}
                                label="Phone Number"
                                type="text"
                                fullWidth
                                variant="standard"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => {
                                    setPhoneErr(false);
                                    setUserData({ ...userData, phoneNumber: e.target.value });
                                }}
                                helperText={phoneErr ? "Invalid Mobile Number" : ""}
                            />
                        </Box>

                        <Box sx={theme.customStyle.textRow}>
                            <TextField
                                size="small"
                                margin="dense"
                                disabled={isProfile}
                                error={clientFacingPhoneErr}
                                value={userData.clientFacingPhoneNum}
                                label="Client Facing Phone Number"
                                type="text"
                                fullWidth
                                variant="standard"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => {
                                    setClientFacingPhoneErr(false);
                                    setUserData({ ...userData, clientFacingPhoneNum: e.target.value });
                                }}
                                helperText={clientFacingPhoneErr ? "Invalid Client Facing Phone Number" : ""}
                            />
                        </Box>

                        <Box sx={theme.customStyle.textRow}>
                            <TextField
                                size="small"
                                margin="dense"
                                error={jsonError}
                                value={userData.userAttributes}
                                disabled={isProfile}
                                label="User Attributes"
                                type="text"
                                fullWidth
                                variant="standard"
                                id="updateUseruserAttributes"
                                inputProps={{ //not show suggetions
                                    autoComplete: 'new-password',
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                                InputLabelProps={{
                                    shrink: true,

                                }}
                                onChange={(e)=>{
                                    setJsonError(false);
                                    setUserData({ ...userData, userAttributes: e.target.value });}}
                                helperText={jsonError ? "Invalid JSON" : ""}
                            />
                        </Box>
                        <div>

                            <Box sx={theme.customStyle.textRow}>
                                <TextField
                                    size="small"
                                    required
                                    margin="dense"
                                    error={maxConvErr}
                                    value={userData.maxConversations}
                                    disabled={isProfile}
                                    label="MAX Conversations"
                                    type="number"
                                    fullWidth
                                    id="updateUsermaxConversations"
                                    inputProps={{ //not show suggetions
                                        autoComplete: 'new-password',
                                        form: {
                                            autocomplete: 'off',
                                        },
                                    }}
                                    variant="standard"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => {
                                        setMaxConvErr(false);
                                        setUserData({ ...userData, maxConversations: e.target.value });
                                    }}
                                    helperText={maxConvErr ? "Invalid Number" : ""}
                                />
                            </Box>

                            <span>Is Active</span>
                            <Checkbox disabled={
                                userInfo.userId === userData.userId ? true :
                                    isProfile} checked={userData.isActive} onChange={handleChange} />
                        </div>
                        {!isAddUser && <Box sx={{ ...theme.customStyle.textRow, marginTop: 2.5 }}>
                            <FormControl variant="standard" sx={{ width: "100%" }}>
                                <InputLabel shrink={true} disabled={true}>Teams</InputLabel>
                                <Select
                                    multiple
                                    required
                                    disabled={true}
                                    value={teams}
                                    onChange={handleChangeTeams}
                                    MenuProps={MenuProps}
                                    label="Teams"
                                    helperText="Please select team."
                                >
                                    {teamsList.map((option) => (
                                        <MenuItem
                                            key={option.team.teamId}
                                            value={option.team.teamId}
                                        >
                                            {option.team.teamName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>}
                        <Box sx={{ ...theme.customStyle.textRow, marginTop: 2.5 }}>
                            <TextField
                                size="small"
                                select
                                required
                                label="Role"
                                defaultValue={userData.role}
                                disabled={isProfile || (userData.role =="Admin" && userInfo.role == "Supervisor")}
                                value={userData.role}
                                fullWidth
                                onChange={handleChangeRole}
                                id="updateUserrole"
                                inputProps={{ //not show suggetions
                                    autoComplete: 'new-password',
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="standard"
                            >
                                {userInfo.role == "Admin"
                                    ? <MenuItem value={"Admin"}>Admin</MenuItem>
                                    : userInfo.role == "Supervisor" && userData.role == "Admin" ? <MenuItem value={"Admin"}>Admin</MenuItem>
                                        : null}
                                <MenuItem value={"Supervisor"}>Supervisor</MenuItem>
                                <MenuItem value={"Agent"}>Agent</MenuItem>
                                <MenuItem value={"Reviewer"}>Reviewer</MenuItem>
                            </TextField>
                        </Box>
                        {!isAddUser &&
                            <Box sx={{ ...theme.customStyle.textRow, marginTop: 2.5 }}>
                                <FormControl variant="standard" sx={{ width: "100%" }}>
                                    <InputLabel shrink={true} >Preferred Team</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        label="Team"
                                        value={defTeamId}
                                        onChange={handleChangeDefaultTeam}
                                    >
                                        {userTeams && userTeams.map((option, index) => (
                                            <MenuItem key={option.team.teamId} value={option.team.teamId}
                                                onClick={() => { console.log("teamanem") }}
                                            >
                                                {option.team.teamName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>}
                        { <Box sx={{ ...theme.customStyle.textRow, marginTop: 2.5 }}>
                            <FormControl variant="standard" sx={{ width: "100%" }}>
                                <InputLabel shrink={true} >Department</InputLabel>
                                <Select
                                    onChange={(e) => {
                                        setUserData({ ...userData, department: e.target.value });
                                    }}
                                    value={userData.department}
                                    MenuProps={MenuProps}
                                    label="Department"
                                    helperText="Please select Department."
                                >
                                    <MenuItem
                                        key="None"
                                        value="None"
                                    >
                                        None
                                    </MenuItem>
                                    {departments && departments.map((option) => (
                                        <MenuItem
                                            key={option}
                                            value={option}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>

                            </FormControl>
                        </Box>}
                        {/* <Box sx={{ ...styles.textRow, marginTop: 2.5 }}>
              <TextField
                size="small"
                id="standard-select-currency"
                select
                label="Select"
                defaultValue={team}
                value={team}
                fullWidth
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText="Please select team."
                variant="standard"
              >
                {teamsList.map((option, index) => (
                  <MenuItem key={index} value={option.team.teamId}>
                    {option.team.teamName}
                  </MenuItem>
                ))}
              </TextField>
            </Box> */}
                        {!isAddUser && !isProfile && (
                            <>
                                <Box sx={theme.customStyle.textRow}>
                                    <TextField
                                        size="small"
                                        margin="dense"
                                        disabled
                                        value={`${createdBy.createdByName + " (" + createdBy.createdDate + ")"}`}
                                        label="Created By"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        id="updateUsercreatedBy"
                                        inputProps={{ //not show suggetions
                                            autoComplete: 'new-password',
                                            form: {
                                                autocomplete: 'off',
                                            },
                                        }}
                                    />
                                </Box>
                                <Box sx={theme.customStyle.textRow}>
                                    <TextField
                                        size="small"
                                        margin="dense"
                                        disabled
                                        value={`${modifiedBy.lastModifiedByName + " (" + modifiedBy.lastModifiedDate + ")"}`}
                                        label="Modified By"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        id="updateUsermodifiedBy"
                                        inputProps={{ //not show suggetions
                                            autoComplete: 'new-password',
                                            form: {
                                                autocomplete: 'off',
                                            },
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Box>
                                    <Box sx={theme.customStyle.textRow} style={{
                                        display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "flex-end" }}>
                                        <TextField
                                            size="small"
                                            margin="dense"
                                            disabled
                                            value={userData.lastLoginDate}
                                            label="User Last Login"
                                            type="text"
                                            // fullWidth
                                            variant="standard"
                                            id="lastLoginDate"
                                            inputProps={{ //not show suggetions
                                                autoComplete: 'new-password',
                                                form: {
                                                    autocomplete: 'off',
                                                },
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        <Button
                                        onClick={()=>setOpenLoginHistory(true)}>Login History</Button>
                                    </Box>
                                    {openLoginHistory && <LoginHistory
                                        userId={userData.userId}
                                        handleModalClose={()=>setOpenLoginHistory(false)}
                                    />}
                            </>
                        )}
                        <div style={{ marginBottom: "20px" }}>
                            <LoadingButton
                                loading={loadingBtn}
                                disabled={
                                    !userData.lastName ? true :
                                        jsonError === true ? true :
                                            !userData.emailId ? true :
                                                !userData.firstName ? true :
                                                    userData.maxConversations === "" ? true :
                                                        userData.maxConversations === null ? true :
                                                            !userData.role ? true : false
                                }
                                variant="contained"
                                onClick={() => {
                                    handleAddUpdateUser();
                                }}
                            >
                                {isProfile ? "Save" : isAddUser ? AddUser : UpdateUser}
                            </LoadingButton>
                            {!isProfile ?
                                <Button
                                    sx={{ marginLeft: "10px" }}
                                    variant="contained"
                                    onClick={() => {
                                        navigate("/users");
                                    }}
                                >
                                    Cancel
                                </Button> : null}
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
