/**
 * 08-04-2023 => SUDAM B CHAVAN => reset page index to 1 page on filter data
 * 08-29-2023 => ONKAR => TEXTING-1578 -REACT Upgrade  
 * 08-30-2023 => ONKAR => TEXTING-1578 -REACT Upgrade  
*/
import React, { useEffect, useState, useContext } from "react";
import { Box, Button, Checkbox } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid } from "@mui/x-data-grid";
import { AuthContext } from "../../AuthContext";
import { useTheme } from "@mui/material/styles";
import {
    InvokeGetServiceCall,
    InvokePostServiceCall,
} from "../../api/serviceUtil";
import moment from "moment";
import SnackBar from "../../components/SnackBar";
import { useNavigate } from "react-router-dom";
import apiConfig from "../../api/apiConfig";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import HistoryIcon from '@mui/icons-material/History';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ObjectHistoryComp from "../teams/ObjectHistoryComp";
import { StyledDataGrid } from "../../components/styledComponents/StyledDataGrid";


function PhoneNumberOptOut() {
    const theme = useTheme();
    const navigate = useNavigate();
    const { messageApiToken } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [phoneNumberList, setPhoneNumberList] = useState([]);
    const [tempArr, setTempArr] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [openTemplateHistory, setOpenTemplateHistory] = React.useState(false);
    const [userDetails, setUserDetails] = React.useState([]);
    const [page, setPage] = useState(0);
    const columns = [
        {
            field: "customerPhoneNumber",
            headerName: "Customer Phone",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "teamPhoneNumber",
            headerName: "Team Phone",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "createdDate",
            headerName: "Created Date",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "lastModifiedDate",
            headerName: "Modified Date",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "doNotText",
            headerName: "Do Not Text",
            flex: 1,
            minWidth: 50,
            filterable: false,

            headerClassName: "super-app-theme--header",
            renderCell: (params) => {
                return (
                    <div style={{ marginLeft: "5px" }}>
                        <Checkbox disabled checked={params.row.doNotText} />
                    </div>
                );
            },
        }, {
            field: "history",
            headerName: "-",
            flex: 1,
            minWidth: 50,
            filterable: false,

            headerClassName: "super-app-theme--header",
            renderCell: (params) => {
                return (
                    <div style={{ marginLeft: "5px" }}>
                        <IconButton
                            //sx={theme.customStyle.HistoryIconBtn}
                            onClick={(e) => {
                                setUserDetails(params.row);
                                setOpenTemplateHistory(true);
                            }}
                        >
                            <HistoryIcon />
                        </IconButton>
                    </div>
                );
            }
        }
    ];
    const handleClose = () => {
        setOpenTemplateHistory(false);
    };

    useEffect(() => {
        if (messageApiToken) {
            InvokeGetServiceCall(apiConfig.GET_PHONE_NUMBER_OPT_OUT_LIST)
                .then((data) => {
                    console.log(data, "datatatat");
                    let tempArr = [];
                    data.data.phoneNumberOptOuts.forEach((item, index) => {
                        item.id = index;
                        item.createdDate = moment(item.createdDate)
                            .local()
                            .format("MM-DD-YYYY");
                        item.lastModifiedDate = moment(item.lastModifiedDate)
                            .local()
                            .format("MM-DD-YYYY");
                        tempArr.push(item);
                    });
                    setPhoneNumberList(tempArr);
                    setTempArr(tempArr);
                    setLoading(false);
                })
                .catch((error) => {
                    setSnackbarMsg(error.message)
                    setOpenSnackbar(true);
                    console.log(error, "Error Message");
                    setLoading(false);
                });
        }
    }, [messageApiToken]);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };
    const filterTable = (value) => {
        let usArr = [];
        let val = value?.toLowerCase();
        setPage(0);
        tempArr.forEach((item) => {

            if (
                item.customerPhoneNumber?.toLowerCase().includes(val) ||
                item.teamPhoneNumber?.toLowerCase().includes(val) ||
                item.createdDate?.toLowerCase().includes(val) ||
                item.lastModifiedDate?.toLowerCase().includes(val)
            ) {
                usArr.push(item);
            }
        });
        setPhoneNumberList(usArr);
    }

    return (
        <>
            <SnackBar
                openSnackbar={openSnackbar}
                handleCloseSnackbar={handleCloseSnackbar}
                severity={"error"}
                userMessage={snackbarMsg}
            />
            <div>
                <Box sx={theme.customStyle.tableRow}>
                    <h2 style={{ marginLeft: 10 }}>Phone Number Opt Out</h2>
                    {/* <div>
            <Button
              sx={theme.customStyle.modalbtn}
              onClick={() => {
                navigate("", {
                  state: { isAddBusinessHoursConfig: true },
                });
              }}
            >
              <AddIcon />
            </Button>
          </div> */}
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <TextField
                            sx={{ marginRight: "10px", marginBottom: "10px" }}
                            size="small"
                            type="search"
                            variant="outlined"
                            margin="normal"
                            id="outlined-search"
                            label=""
                            placeholder="OptOut Search"
                            autoComplete="off"
                            onChange={(e) => {
                                if (e.target.value) {
                                    filterTable(e.target.value);
                                }
                                else {
                                    setPhoneNumberList(tempArr);
                                }

                            }}
                            InputLabelProps={{
                                shrink: false,
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                </Box>
            </div>
            <Box sx={theme.customStyle.tableHead}>
                <StyledDataGrid
                    loading={loading}
                    rows={phoneNumberList}
                    columns={columns}
                    pageSize={10}
                    page={page}
                    onPageChange={(page) => setPage(page)}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                    components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                    }}
                    columnVisibilityModel={{ history: false }} //hide the columns from the grid
                    // initialState={{
                    //     sorting: {
                    //         sortModel: [{ field: "lastModifiedDate", sort: "desc" }],
                    //     },
                    // }}
                />
            </Box>
            <Dialog open={openTemplateHistory} onClose={handleClose} fullWidth maxWidth={"lg"}>
                <DialogContent>
                    <ObjectHistoryComp
                        open={true}
                        handleClose={handleClose}
                        objectName="OptOut"
                        objectKeyname={userDetails}
                        numOfRows={10}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
}

export default PhoneNumberOptOut;