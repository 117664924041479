//04/26/2023 => ONKAR => message autosuggest height changes. 
//06/21/2023 => ONKAR => Texting-1361 After click on auto suggestions, blank space is showing on auto suggestions place in active conversation page. 

import React, {useEffect, useState} from "react";
import { Chip } from "@mui/material";


function ChatAutoSuggest(props) {
  const { msgAutoSuggestions, sendMessage,marBottom } = props;
const [isDisabled,setIsDisabled] = useState(false);

useEffect(()=>{// get the height of message autosuggest div.
  const height=document.getElementById('msgAutoSuggest').clientHeight;
  props.msgAutoSuggestHeightHandler(height)

  return(()=>{
      props.msgAutoSuggestHeightHandler(0)
  })
},[msgAutoSuggestions])

  return (
    <>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            flexWrap: "wrap-reverse",
          }}
          id="msgAutoSuggest"
        >
          {msgAutoSuggestions.map((item, index) => {
            return (
                <Chip
                key={index}
                label={item}
                color="primary"
                variant="outlined"
                clickable
                size="small"
                sx={{
                  marginRight: "4px",
                    borderRadius: "4px",
                  fontSize:"12px",
                  marginBottom: marBottom,
                  marginLeft: index === 0 ? "4px" : "0px",
                  zIndex:"1001"
                }}
                disabled={isDisabled}
                onClick={() => {
                  sendMessage(item);
                }}
              />
            );
          })}
        </div>
    </>
  );
}

export default ChatAutoSuggest;
