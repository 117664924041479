/**************************
 ** 12-27-2022 => SUDAM => Added loader for InActive Conv Count Api Call and call api when ever selectedMenu === "inactive" for count mismatch issue on click of inactive conv menu..
 ** 05-12-2022 => ONKAR => Texting-1263 - Click on missed icon in the left panel from the active conversation page is making the active conversation page blank.

 */
import React, { useContext, useEffect, useState } from "react";
import {
  ListItemButton,
  ListItemIcon,
  Box
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AuthContext } from "../AuthContext";
import SmsFailedTwoToneIcon from '@mui/icons-material/SmsFailedTwoTone';
import { InvokePostServiceCall } from "../api/serviceUtil";
import apiConfig from "../api/apiConfig";
import { mapConversation } from "../extensions/Util";

function InactiveConversationsNew(props) {
  const theme = useTheme();
  const { collapseInactive, setCollapseInactive, userInfo, setInactiveConversations, setOpenConversationDrawer, matches, setInactiveConvCount, inactiveConvCount, setConvLoading } =
    useContext(AuthContext);
  useEffect(() => {
    if (props.selectedMenu === "inactive"){
      props.setOpenActive(false);
      props.setSelectedMenu('inactive');
      getInActiveConversations();
    }
  }, [collapseInactive]);

  const getInActiveConversations = () => {
    setConvLoading(true); // set loading true before calling the api
    let requestBody = {
      teamId: userInfo.teamId,
    };
    InvokePostServiceCall(apiConfig.GET_INACTIVE_CONVERSATIONS, requestBody)
      .then((data) => {
        setConvLoading(false);
        let inactiveConversationsArr = [];
        for (var i = 0; i < data?.data?.conversations?.length; i++) {
          var conv = data?.data?.conversations[i];
          conv.status = 'InActive';
          inactiveConversationsArr.push(mapConversation(conv, conv.teamPhoneNumber));
        }
        setInactiveConversations(inactiveConversationsArr)
        setInactiveConvCount(inactiveConversationsArr.length);
        if (inactiveConversationsArr.length === 0 ) {
          props.setSelectedMenu("");
        }
      })
      .catch((error) => {
        setConvLoading(false);
        console.log(error);
      });
  }
  let badgeBackgroundOrange = {
    "& .MuiBadge-badge": {
      color: "#fff",
      backgroundColor: "orange"
    }
  }
  return (
    <>
      <ListItemButton
        title="Inactive Conversations"
        selected={(props.selectedMenu === 'inactive')}
        sx={
          theme.customStyle.listItemIconDrawerClosedNew
        }

        onClick={(e) => {
          setCollapseInactive(!collapseInactive);
          props.setOpenActive(false);
          props.setSelectedMenu('inactive');
          setOpenConversationDrawer(true)
          // props.openDrawer();
          // props.toggleConvDrawer(true, true)(e);
        }}
      >
        <ListItemIcon sx={theme.customStyle.listItemIcon}>
          <div style={{ textAlign: 'left' }}>
            <props.IconBadge
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              badgeContent={inactiveConvCount} sx={badgeBackgroundOrange}  >
              <SmsFailedTwoToneIcon iconSize={theme.customStyle.iconSize}
                sx={
                  props.selectedMenu === "inactive"
                    ? theme.customStyle.activeDrawerMenuDrawerClosed
                    : theme.customStyle.inActiveDrawerMenuDrawerClosed
                } />
            </props.IconBadge>
          </div>
          <Box sx={{ ...theme.customStyle.listItemLabelDiv, left: "-5px" }}>
            <span style={{ fontSize: "10px" }}>Inactive</span>
          </Box>
        </ListItemIcon>

      </ListItemButton>
    </>
  );
}

export default InactiveConversationsNew;
