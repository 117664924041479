////11-02-2022 => Texting-732 Added DocuSign Account ID.
////08-02-2023 PRABHAT TEXTING-1517 Update TCPA Message when message sent on non TCPA hours.
////08-04-2023 => SUDAM B CHAVAN => reset page index to 1 page on filter data
////08-08-2023 PRABHAT TEXTING-1548 Service Provider on Teams Detail Page.
//// 08-29-2023 => ONKAR => TEXTING-1578 -REACT Upgrade
//// 08-30-2023 => ONKAR => TEXTING-1578 -REACT Upgrade
////11-27-2023 => SUDAM B CHAVAN => TEXTING-1872 Chat Survey Questions Configuration - Admin UI
import React, { useState, useEffect, useContext } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import apiConfig from "../../api/apiConfig";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../AuthContext";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import SnackBar from "../../components/SnackBar"
import { Checkbox, IconButton,InputAdornment } from "@mui/material";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import { TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import HistoryIcon from '@mui/icons-material/History';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ObjectHistoryComp from "../teams/ObjectHistoryComp";
import { StyledDataGrid } from "../../components/styledComponents/StyledDataGrid";


const TeamsPage = (props) => {
    const navigate = useNavigate();
    const { messageApiToken, userInfo } = useContext(AuthContext);
    const theme = useTheme();
    const [teamsData1, setTeamsData1] = useState([]);
    const [teamsDataCopy, setTeamsDataCopy] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [openTemplateHistory, setOpenTemplateHistory] = React.useState(false);
    const [teamsDetails, setTeamsDetails] = React.useState([]);
    const [page, setPage] = useState(0)

    const teamsArr1 = [];

    const columns = [
        // { field: "id", headerName: "ID", width: 70 },
        {
            field: "teamName",
            headerName: "Team Name",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "type",
            headerName: "Category",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "msgSupportNumber",
            headerName: "SMS Number",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "createdDateFormat",
            headerName: "Created Date",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "lastModifiedDateFormat",
            headerName: "Modified Date",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "isActive",
            headerName: "Is Active",
            flex: 1,
            minWidth: 50,
            filterable: false,

            headerClassName: "super-app-theme--header",
            renderCell: (params) => {
                return (
                    <div style={{ marginLeft: "5px" }}>
                        <Checkbox disabled checked={params.row.isActive} />
                    </div>
                );
            },
        }, {
            field: "history",
            headerName: "-",
            flex: 1,
            minWidth: 50,
            filterable: false,

            headerClassName: "super-app-theme--header",
            renderCell: (params) => {
                return (
                    <div style={{ marginLeft: "5px" }}>
                        <IconButton
                            //sx={theme.customStyle.HistoryIconBtn}
                            onClick={(e) => {
                                setTeamsDetails(params.row);
                                setOpenTemplateHistory(true);
                            }}
                        >
                            <HistoryIcon />
                        </IconButton>
                    </div>
                );
            }
        }
    ];

    useEffect(() => {
        if (messageApiToken) {
            const requestBody = {
                userId: userInfo.userId
            };
            setIsLoading(true);
            InvokePostServiceCall(apiConfig.GET_TEAMS, requestBody)
                .then((data) => {
                    let tempArr = [];
                    data.data.teamDetails.forEach((item, index) => {


                        tempArr.push({
                            callBackUrl: item.team.callBackUrl,
                            crmUrl: item.team.crmUrl,
                            createdBy: item.team.createdBy,
                            createdByName: item.team.createdByName,
                            createdByNavigation: item.team.createdByNavigation,
                            createdDateFormat: moment(item.team.createdDate).local().format("MM-DD-YYYY"),
                            createdDate: item.team.createdDate,
                            endConversationText: item.team.endConversationText,
                            inboundMsgAssignment: item.team.inboundMsgAssignment,
                            lastModifiedBy: item.team.lastModifiedBy,
                            lastModifiedByName: item.team.lastModifiedByName,
                            lastModifiedByNavigation: item.team.lastModifiedByNavigation,
                            lastModifiedDateFormat: moment(item.team.lastModifiedDate).local().format("MM-DD-YYYY"),
                            lastModifiedDate: item.team.lastModifiedDate,
                            msgSupportNumber: item.team.msgSupportNumber,
                            shortCode: item.team.shortCode,
                            teamId: item.team.teamId,
                            teamName: item.team.teamName,
                            teamToContacts: item.team.teamToContacts,
                            teamToUsers: item.team.teamToUsers,
                            templates: item.team.templates,
                            type: item.team.type,
                            voiceSupportNumber: item.team.voiceSupportNumber,
                            id: index,
                            isActive: item.team.isActive,
                            sensitiveDataRegExp: item.team.sensitiveDataRegExp,
                            businessHoursConfigId: item.team.businessHoursConfigId,
                            smsprovider: item.team.smsprovider,
                            nonBusHrsMessage: item.team.nonBusHrsMessage,
                            agentUnavailableMessage: item.team.agentUnavailableMessage,
                            nppistrippedMessage: item.team.nppistrippedMessage,
                            mediaContentMessage: item.team.mediaContentMessage,
                            nonTcpahrsMessage: item.team.nonTcpahrsMessage,
                            docuSignAccountId: item.team.docuSignAccountId,
                            teamAttributes: item.team.teamAttributes,
                            inActiveConversationHours: item.team.inActiveConversationHours,
                            notes: item.team.notes,
                            isStartConvWithTemplate: item.team.isStartConvWithTemplate,
                            enableDocuments: item.team.enableDocuments,
                            documentTypes: item.team.documentTypes,
                            enableConversationReadOnSeen: item.team.enableConversationReadOnSeen,
                            eligibleToTransferConversation: item.team.eligibleToTransferConversation,
                            eligibleToReceiveConversation: item.team.eligibleToReceiveConversation,
                            isSurveyEnabled: item.team.isSurveyEnabled,
                            surveyTemplateId: item.team.surveyTemplateId,

                        })
                    })
                    setTeamsData1(tempArr);
                    setTeamsDataCopy(tempArr);
                    setIsLoading(false);
                    console.log(data, "@@@@@@@@@@@");
                })
                .catch((error) => {
                    console.log("teamsApi", error);
                    setSnackbarMsg(error.message);
                    setOpenSnackbar(true);
                    setIsLoading(false);
                });
        }
    }, [messageApiToken]);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };
    const handleClose = () => {
        setOpenTemplateHistory(false);
    };

    const filterTable = (value) => {
        let usArr = [];
        let val = value?.toLowerCase();
        setPage(0);
        teamsDataCopy.forEach((item) => {

            if (
                item.teamName?.toLowerCase().includes(val) ||
                item.type?.toLowerCase().includes(val) ||
                item.msgSupportNumber?.toLowerCase().includes(val) ||
                item.createdDateFormat?.toLowerCase().includes(val) ||
                item.lastModifiedDateFormat?.toLowerCase().includes(val)
            ) {
                usArr.push(item);
            }
        });
        setTeamsData1(usArr);
    }

    return (
        <>
            <SnackBar
                openSnackbar={openSnackbar}
                handleCloseSnackbar={handleCloseSnackbar}
                severity={"error"}
                userMessage={snackbarMsg}
            />
            <div>
                <Box sx={theme.customStyle.tableRow}>
                    <h2 style={{ marginLeft: 10 }}>Teams</h2>
                </Box>
            </div>
            <div style={{ display: "flex", alignItems: "flex-start", paddingLeft: 10 }}>   
                <Box sx={theme.customStyle.tableRow}>
                        <Button
                            sx={theme.customStyle.modalbtn}
                            onClick={() => {
                                navigate("/addUpdateTeam", { state: { isAddTeam: true } });
                            }}
                        >
                            <AddIcon />
                        </Button>
                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <TextField
                                sx={{ marginRight: "10px", marginBottom: "10px" }}
                                size="small"
                                type="search"
                                variant="outlined"
                                margin="normal"
                                id="outlined-search"
                                label=""
                                placeholder="Teams Search"
                                autoComplete="off"
                                onChange={(e) => {
                                    if (e.target.value) {
                                        filterTable(e.target.value);
                                    } else {
                                        setTeamsData1(teamsDataCopy);
                                    }
                                }}
                                InputLabelProps={{
                                    shrink: false,
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Box>
                </Box>
                </div>

            <Box
                sx={theme.customStyle.tableHead}
            >
                <StyledDataGrid
                    loading={isLoading}
                    rows={teamsData1}
                    disableSelectionOnClick
                    components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                    }}
                    onRowDoubleClick={(e) => {
                        navigate("/addUpdateTeam/" + e.row.teamId, {
                            state: { isAddTeam: false, teamDetails: e.row },
                        });
                    }}
                    columns={columns}
                    pageSize={10}
                    page={page}
                    onPageChange={(page) => setPage(page)}
                    rowsPerPageOptions={[10]}
                    columnVisibilityModel={{ history: false }} //hide the columns from the grid
                    // initialState={{
                    //     sorting: {
                    //         sortModel: [{ field: 'lastModifiedDateFormat', sort: 'desc' }],
                    //     },
                    // }}
                />
            </Box>
            <Dialog open={openTemplateHistory} onClose={handleClose} fullWidth maxWidth={"lg"}>
                <DialogContent>
                    <ObjectHistoryComp
                        open={true}
                        handleClose={handleClose}
                        objectName="Teams"
                        objectKeyname={teamsDetails}
                        numOfRows={10}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default TeamsPage;