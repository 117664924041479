/**
*08-22-2023 => SUDAM B CHAVAN => TEXTING - 1600 - Conversation assignment / reassignment
*08-29/2022 - SUDAM B CHAVAN => Start conversation from Admin UI contact is not working
*09-04-2023 => ONKAR => TEXTING-1576 - Webchat: Ability for member to change font size.
 */
import React from "react";
import { ReactComponent as AgentConnected } from './assets/AgentConnected.svg'
import { ReactComponent as ClosedChat } from './assets/chat-cancel-icon.svg'
import {
    Box,
    CardContent,
    CircularProgress,
    useTheme
} from "@mui/material";


function WebChatInfoCard(props) {
    const { message, settings, cardType,textSize } = props;
    const theme = useTheme();
    return (
        <Box sx={{ display: 'flex', border: 0, justifyContent: "center" }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: "center",
                    boxShadow: 6,
                    p: 1,
                    m: 1,
                    borderRadius: 2,
                    textAlign: 'center',
                    bgcolor: (theme) => settings.infoCardColor,
                    color: (theme) => settings.infoCardTextColor,
                }}
            >
                {cardType === "ASSIGNED" && <CardContent >
                    <AgentConnected height={"40px"} width={"40px"} fill={settings.infoCardTextColor} />
                </CardContent>}
                {(cardType === "WAITTIME" || cardType === "ASSIGNING") && <CardContent >
                    <CircularProgress size={30} color="inherit" />
                </CardContent>}
                {cardType === "CONV_ENDED" && <CardContent >
                    <ClosedChat height={"40px"} width={"40px"} fill={settings.infoCardTextColor} />
                </CardContent>}
                <Box sx={{ display: 'flex', alignItems: 'center',
                ...textSize==="Medium"
                ?theme.customStyle.webChatMd16
                :textSize==="Large"
                ?theme.customStyle.webChatLg16
                :theme.customStyle.webChatSm16
             }}>
                    <strong>{
                        cardType === "ASSIGNED"
                            ? settings?.agentAssignedMessage.replace('{0}', message)
                            : message}
                    </strong>
                </Box>
            </Box>
        </Box>
    );
};
export default WebChatInfoCard;