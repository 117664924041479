/*
* 04-14-2023 => Sudam B Chavan =>TEXTING-1085 - Auto Response initial message when agent not available
* 04-25-2023 => ONKAR =>Header and start chat button color changes.
* 04-26-2023 => ONKAR =>Added fixed width for textfields.
* 04-28-2023 => ONKAR =>TEXTING-1212 - Non business hours screen logic added.
* 05-04-2023 => ONKAR => Texting-1243 UI for Message Read Status for Agent and Member.
* 08-04-2023 => ONKAR => Texting-1243 UI for Message Read Status for Agent and Member(bugs fixed).
* 05-15-2023 => ONKAR => Textin-1270 When chat bot disabled - First system user message in missed conversation is showing as unread.
* 05-19-2023 => ONKAR => Texting-1273 Load all the messages of conversation based on conversation id for webChat.
* 05-23-2023 => ONKAR => Texting-1273 Andriod apk issues fixed.(4. After minimize and reopen of chat UI, not able to receive new inbound messages on Member UI.
                         5. On Agent UI, Messages sent to member are not showing as read.
                         If we minimize and reopens the Chat UI, then messages are updating as read on Agent UI.)

* 05-25-2023 => ONKAR => TEXTING-1277 - when browser reconnect, outbound and inbound messages are not working on Chat UI.
* 05-31-2023 => ONKAR => TEXTING-1304 -IOS - Scroll bar is not shown in description box after three lines are entered.
* 05-31-2023 => ONKAR => TEXTING-1309 IOS, Android- Multiple chat bot messages without prompts when minimizing chat and reopening.
* 06-01-2023 => ONKAR => Texting-1307 If conversation is auto closed when member UI is minimized state, Maximizing the UI is showing start chat page instead of conversation closed page.
* 06-01-2023 => ONKAR => show queue position and wait time after refresh (mobile issue).
* 06-02-2023 => ONKAR => Texting-1309 IOS, Android- Multiple chat bot messages without prompts when minimizing chat and reopening.
* 06-02-2023 => SUDAM B CHAVAN => TEXTING-1315 => Android, IOS - Conversation auto closed message is showing instead of conversation ended by agent message.
* 06-07-2023 => ONKAR => TEXTING-1304 => IOS - Scroll bar is not shown in description box after three lines are entered.
* 06-09-2023 => ONKAR => TEXTING-1277 => when browser reconnect, outbound and inbound messages are not working on Chat UI.(calling getwebchatmessages api when socket reconnects).
* 06-22-2023 => ONKAR => Revert changes- TEXTING-1162- Admin UI JSON Generator
* 06-22-2023 => SUDAM B CHAVAN => TEXTING-1369- WebChat Config shoudl eb driven by TeamID rather than team name
* 06-30-2023 => ONKAR => TEXTING-1370 Show information message to member when agent is assigned to webchat
*07-10-2023 => ONKAR => TEXTING-1371 Take member details from parent site to start the conversation.
*07-12-2023 => ONKAR => TEXTING-1423 Start webchat conversation page refresh observations.
*07-14-2023 => ONKAR => TEXTING-1423  If chat bot is disabled - Reloading the start page is changing the how can we assist page back to start chat 
*07-20-2023 => ONKAR => TEXTING-1466,Texting1467  Priority 1: Salesforce: Blank screen on accepting inbound Webchat Conversation
*07-21-2023 => ONKAR =>TEXTING-1441 - After chat with bot conversation is assigned to the agent. Reloading the Member UI is showing bot responses for customer messages instead of delivering to Agent.
*07-27-2023 => ONKAR =>TEXTING-1373 - Client Requirement: Agent image should be displayed instead of company logo based on config.
*07-28-2023 => RAJESH =>Implemented JWT based authentication for webchat (Resolving member details)
*08-07-2023 => ONKAR => Texting-1543 Refactor webChat UI with new JSON Response.
*08-09-2023 => ONKAR => Texting-1544 webChat UI - Get all the historical messages inline for the Authenticated webChat client conversation.
*08-10-2023 => ONKAR => Texting-1562 webChat UI - After webchat conversation is assigned to Agent, Reloading the Member UI is showing bot responses.
*08-22-2023 => SUDAM B CHAVAN => TEXTING-1600 - Conversation assignment/reassignment
*08-23-2023 => PRABHAT =>Texting-1605 - WebChat buttons visibility
*08-25-2023 => SUDAM B CHAVAN => TEXTING-1594 - WebChat: Capture member login details
*08-25-2023 => ONKAR => TEXTING-1569 - Issues in conversation history of webchat conversations in Member UI
*08-26-2023 => SUDAM B CHAVAN => TEXTING-1623 - Webchat - Assignment info card observations.
*08-30-2023 => SUDAM B CHAVAN => TEXTING-1607 - If authenticated webchat UI is minimized, Inbound and outbound messages are not working on conversation.
*08-31-2023 => ONKAR => TEXTING-1576 - Webchat: Ability for member to change font size.
*08-31-2023 => Rajesh => TEXTING-1574 - Webchat: Implemneted token staorage in cookie.
*09-04-2023 => ONKAR => TEXTING-1576 - Webchat: Ability for member to change font size.
*09-04-2023 => SUDAM B CHAVAN =>TEXTING-1648 - Webchat - Assigning the Agent card is not showing for webchat conversation without Bot.
*09-11-2023 => ONKAR =>TEXTING-1537 - When webchat conversation is assigned between agents, latest agent image is updating for all agent messages after member UI refresh.
*09-18-2023 => ONKAR =>TEXTING-1708 - Refreshing the webchat page is making the Member UI blank.
*09-21-2023 => ONKAR =>TEXTING-1700 - Webchat layout changes.
*10-10-2023 => ONKAR =>TEXTING-1764 - For Chat without bot conversation - On reload member UI assigning an agent card is showing even after connected to agent.
*10-13-2023 => ONKAR =>TEXTING-1752 - Webchat - New incoming messages are not updating to read automatically when webchat conversation page screen is Active.
*10-16-2023 => ONKAR =>TEXTING-1791 - Without Chat bot - Giving multiple lines blank space in description on Member UI is showing blank space for messages on Agent UI.
*10-18-2023 => ONKAR =>TEXTING-1752 - Webchat - New incoming messages are not updating to read automatically when webchat conversation page screen is Active.
*10-20-2023 => ONKAR =>TEXTING-1802 - Floating date, move to bottom buttons are not showing after Member UI refresh.
*10-25-2023 => ONKAR =>TEXTING-1821 - Token handling in Cookie.
*10-27-2023 => ONKAR =>TEXTING-1821 - Token handling in Cookie.
*10-31-2023 => SUDAM B CHAVAN => TEXTING-1832 - Without chat bot opening the member UI on holiday/non business hrs is showing blank page.
*11-02-2023 => SUDAM B CHAVAN => TEXTING-1832 - Re-Open item=> Header bar and options like close and minimize the UI is not showing for Non chat bot conversation started in non-business hrs.
*11-03-2023 => SUDAM B CHAVAN => TEXTING-1835 - Enabling the bot in between the authenticated active webchat is conversation is showing bot reply for member messages.
*11-14-2023 => ONKAR => TEXTING-1850 -Click on start chat button without entering the member details is showing error on description box field only.
*11-24-2023 => ONKAR => Removed settings and email from session storage.
*11-27-2023 => ONKAR => Texting-1877 Android - webchat application observations.
                         3.Connected to agent card is disappearing when webchat UI is Minimize and maximized.
                         4.Assigning an agent card is disappearing when webchat UI is Minimize and maximized.
                         5.Attachments option is disappearing from menu options when webchat UI is Minimize and maximized.
                         6.Sending the messages after Minimize and maximized the member UI is not showing Message delivered (single tick) status.
*11-28-2023 => ONKAR => Texting-1877 Android - webchat application observations.
                         2.If webchat conversation is ended when member UI is in minimized state then maximizing member UI is showing loader only.
                         7.After Minimize and maximized the member UI is resetting the selected font size and mute options to default.           
*11-28-2023 => ONKAR => Texting-1876 Chat- Session Continuity Across Tabs & Devices                         7.After Minimize and maximized the member UI is resetting the selected font size and mute options to default.           
*11-29-2023 => ONKAR => Texting-1877 Android - webchat application observations.
                         2.If webchat conversation is ended when member UI is in minimized state then maximizing member UI is showing loader only.           
*12-01-2023 => ONKAR => Texting -1876 Chat- Session Continuity Across Tabs & Devices
                         6.For authenticated conversation without chatbot, assigning an agent card is showing when opening the conversation in different tab.
 *12-05-2023 => ONKAR =>TEXTING-1899 - Duplicate member message is showing along with bot welcome message.
 *12-18-2023 => ONKAR =>TEXTING-1890 - 0: Webchat Skip initial form for Authenticated Members.
 *01-02-2024 => ONKAR =>TEXTING-1890 - 0: Webchat Skip initial form for Authenticated Members.
                                       1.Only welcome message is showing for some time on conversation page before loading the previous history.
                                       3.Assigning an agent card is showing along with hardcoded welcome message.
                                       5.Conversation is auto closing due to inactivity when stay idle.
                                       6.Not able to close the webchat member UI.
  01-08-2024 => ONKAR =>TEXTINg-1942 - Webchat welcome message when chatbot is not enabled
  01-15-2024 => ONKAR =>TEXTING-1947 - Start webchat conversation with chatbot observations.
  01-17-2024 => ONKAR =>return messages array without internal messages.
*01-22-2024 => Texting-1927 Session continuity: Disable previous sessions when new window is opened for authanticated conversation.
               1. Duplicate Chat Bot welcome message is showing on every new chat session.
*02-09-2024 => SUDAM B CHAVAN => 	When new authenticated conversation started with new member I’d first time, Only loader is showing on member UI until refresh.
*02-10-2024 => SUDAM B CHAVAN =>  When start authanticated conversation without bot, Hardcoded welcome message is showing Instead of configured welcome message from chat config
*02-10-2024 => SUDAM B CHAVAN => changes modified- For chat with and with out Bot If new session is opened, member name is not showing for member first message.
*02-12-2024 => SUDAM B CHAVAN => Open Authanticated webchat conversation in Non business hrs or holiday is showing allignment issue on UI
*02-19-2024 =>   1.Auto close due to inactivity card is not coming when Member continues chat in different device/browser after agent’s message.
*                2.Able to see Attachments option on Authenticated chat and able to upload document before assigning chat to agent. Once chat is assigned to some agent, agent can see the document with refresh icon. But on click of it, its not changing to download. - Disable attachment icon until chat connected to an agent
*02-20-2024 => SUDAM B CHAVAN => Chat is not initiating sometimes when Authenticated Member ends the previous chat and initiates again.
*02-20-2024 => SUDAM B CHAVAN => Able to see Attachments option on Authenticated chat and able to upload document before assigning chat to agent. Once chat is assigned to some agent, agent can see the document with refresh icon. But on click of it, its not changing to download. - Disable attachment icon until chat connected to an agent
*02-21-2024 => ONKAR =>not able to start conversation in android app (uncommented the hardcoded name and email code)
*02-22-2024 => ONKAR => Android - Minimze and maximize the member UI and stay idle when chatting with bot is auto closing the conversation.
*02-23-2024 => ONKAR => Android - Minimze and maximize the member UI and stay idle when chatting with bot is auto closing the conversation.
*02-27-2024 => ONKAR => Android - If android conversation is ended when member UI is in minimized state, maximize the UI is showing loader and closing the UI automatically.
*02-28-2024 => ONKAR => Android - If android conversation is ended when member UI is in minimized state, maximize the UI is showing loader and closing the UI automatically.
*02-29-2024 => ONKAR => Assigning to Agent… card is showing up, If we refresh Member UI i when screen is having non Business hrs message.
*03-01-2024 => ONKAR => Once Out of business hrs ends, Reloading the member UI is showing  conversation page with assigning an agent card only.
*03-15-2024 => SUDAM B CHAVAN => Chat Bot - If new session is opened for authenticated webchat conversation, Bot welcome message with prompts are showing on every new session.-Reopen state issue
*03-18-2024 => ONKAR => For System message, Chat bot logo is not showing instead of agent logo.
*03-19-2024 => SUDAM B CHAVAN => For authenticated conversation without bot, name is not showing for member messages in previous history until member sends the first message.
*03-20-2024 => ONKAR => Grey loader screen issue on Member UI.
*03-28-2024 => SUDAM B CHAVAN => Auto close due to inactivity card is not coming when Member continues chat in different device/browser after agent’s message.
*03-29-2024 => SUDAM B CHAVAN => When click on bot prompt in new session - Welcome message is coming with the bot response
*04-09-2024 => SUDAM B CHAVAN => changes related to Implement jwt Token for webchat services
*/
import React, { Component } from "react";
import queryString from "query-string";
import apiConfig from "../../api/apiConfig";
import axios from "axios";
import "./ChatMessage.scss";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { SendNotificationToParent, GetToken } from "./ChatUtils";
import { ThemeProvider } from "@mui/material/styles";
import { InvokePostServiceCallWithoutToken, InvokePostServiceCallWithJWTToken, InvokePostWebChatServiceCall } from "../../api/serviceUtil";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import {
  IconButton,
  Typography,
  Backdrop,
  Stack,
  Tooltip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

import Chat from "./Chat";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MinimizeIcon from "@mui/icons-material/Minimize";
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import wcuTheme from "./wcuTheme";

import SendIcon from "@mui/icons-material/Send";
import QuestionsWizard from "../surveyCustomerPortal/questionsWizard";
import { SendToAllUserConnections } from "../../azure/pubSub";
import { webchatTokenData } from "../../api/authData"; 
import WebChatInfoCard from "./WebChatInfoCard";
const regEmail = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i;

function getCustomeAttributes(data) {
  alert(data);
}

export function getCustomeAttributesExport(data) {
  alert(data);
}

class WebChatCustomerApp extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      deploymentID: this.getQueryVariable("deploymentId"),
      customerName: "",
      email: "",
      msg: "",
      showStartConvScreen: false,
      serviceResponseMsg: "",
      showInitLoader: true,
      showInputTextBox: false,
      showOutOfBusinessHoursMsg: false,
      settings: {},
      mailError: false,
      descrError: false,
      nameError: false,
      customAttributes: "",
      wsURL: "",
      memberName: "",
      debugLog: "",
      IOSDebug: "",
      isChatOpen: true,
      isConversationEnded: false,
      convesationAvgWaitTimeRespObj: "",
      agentUnavailableMessage: "",
      isRefresh: false,
      conversationEndedBy: "",
      os: "",
      isNewConvWithAttributes: false,
      isSessionExpired: false,
      token: this.getCookie("token"),
      startConvWithName: false,
      messagesHistory:sessionStorage.getItem('messagesHistory')?JSON.parse(sessionStorage.getItem('messagesHistory')):[],
      agentImages:sessionStorage.getItem("agentImages")?JSON.parse(sessionStorage.getItem("agentImages")):{},
      startConvWithDescription:false,
      showPartialDataMsg: false,
      userAgent:"",
      sourceIP:"",
      lat:"",
      long:"",
      anchorEl:null,
      textSize:"Small",
      showAgentAssignMsg: false,
      muteNotifications:sessionStorage.getItem("muteNotifications")?sessionStorage.getItem("muteNotifications"):"false",
      showServeyWizard:false,
      surveyId:null,
      firstMessageForAuthCust:"Hello, how can I help you today?",
      startConvWithoutDesc:false,
      loading:false,
      isAssignedToAgent: sessionStorage.getItem("isAssignedToAgent") 
      ? JSON.parse(sessionStorage.getItem("isAssignedToAgent"))
      : false,
      loadBotMessages:false,
      reloadChatCompOnStartChatWithoutBot:false,
      pvaBotDetails: sessionStorage.getItem("pvaBotDetails") ?? "",
      isMobileDevice: false,
    };  




    var res = SendNotificationToParent({
      event: "getConvIdFromApp",
      data: "getConvIdFromApp", targetOrigin: this.state?.settings?.parentSite
    });
    if (!!res) {
      sessionStorage.setItem("conversationid", res);
    }
    

    this.receivedMessage = this.receivedMessage.bind(this);
    this.startConversation = this.startConversation.bind(this);
    this.updatecustomerName = this.updatecustomerName.bind(this);
    this.updatecustomerEmail = this.updatecustomerEmail.bind(this);
    this.updatecustomermessage = this.updatecustomermessage.bind(this);
    this.minimizeChat = this.minimizeChat.bind(this);
    this.endChat = this.endChat.bind(this);
    this.setCustomAttributes = this.setCustomAttributes.bind(this);
    this.receiveIOSConversationId = this.receiveIOSConversationId.bind(this);
    this.receiveIOSCustomAttributes = this.receiveIOSCustomAttributes.bind(this);
    this.connectToSocket = this.connectToSocket.bind(this);
    this.setIsChatOpen = this.setIsChatOpen.bind(this);
    this.setIsRefresh = this.setIsRefresh.bind(this);
    this.getOS = this.getOS.bind(this);
    this.getMessagesWeb = this.getMessagesWeb.bind(this);
    this.setStateFromChild=this.setStateFromChild.bind(this)
    this.getAgentImage=this.getAgentImage.bind(this)
    this.setStateFromChild = this.setStateFromChild.bind(this)
    this.getMessagesHistoryWeb = this.getMessagesHistoryWeb.bind(this)
    this.getUserIP = this.getUserIP.bind(this);
    this.getCurrentPositionSuccess = this.getCurrentPositionSuccess.bind(this);
    this.getCurrentPositionError = this.getCurrentPositionError.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.setFontSize = this.setFontSize.bind(this);
    this.setCustomAttributes=this.setCustomAttributes.bind(this);
    this.handleMuteUnmuteNotifications=this.handleMuteUnmuteNotifications.bind(this)
    this.filterInternalMessages = this.filterInternalMessages.bind(this)
    this.setIsAssignedToAgent = this.setIsAssignedToAgent.bind(this)
    this.getTheams();


  }
  handleClick = (event) => {
    this.setState({anchorEl:event.currentTarget});
  };
   handleClose = () => {
    this.setState({anchorEl:null});
  };

  // Function to set font size and update conversation attributes
  setFontSize = (textSize,convAttributes="") => {
    // Update component state with the new font size
    this.setState({ textSize: textSize });

    // Save font size to sessionStorage for persistence
    sessionStorage.setItem("textSize", textSize);
  
    // Update conversation attributes with new font size and current mute status
    this.updateConversationAttributes(textSize, this.state.muteNotifications,convAttributes);
  };

  // Function to handle mute/unmute notifications and update conversation attributes
  handleMuteUnmuteNotifications = (value,convAttributes="") => {
   // Update component state with the new mute status
   this.setState({ muteNotifications: value });
 
   // Save mute status to sessionStorage for persistence
   sessionStorage.setItem("muteNotifications", value);
 
   // Update conversation attributes with current font size and new mute status
   this.updateConversationAttributes(this.state.textSize, value, convAttributes);
 };

 setCustomAttributes=(value)=>{
  this.setState({
    customAttributes:value
  })
 }
 
  // Function to update conversation attributes on the server
  updateConversationAttributes = async (
    textSize,
    muteUnmuteNotificationVal,
    convAttributes=""
    
  ) => {
    try {
      // Parse existing custom attributes or initialize an empty object
      let conversationAttributes =convAttributes!==""?JSON.parse(this.state.convAttributes):this.state.customAttributes
        ? JSON.parse(this.state.customAttributes)
        : {};

      // Update custom attributes with new font size and mute status
      conversationAttributes.textSize = textSize;
      conversationAttributes.muteNotifications = muteUnmuteNotificationVal;

      // Prepare request body with updated conversation attributes
      let requestBody = {
        conversationId: sessionStorage.getItem("conversationid"),
        conversationAttributes: JSON.stringify(conversationAttributes),
      };

      // Make an API call to update conversation attributes
      let result = await InvokePostWebChatServiceCall(
        apiConfig.UPDATE_CONVERSATION_ATTRIBUTES,
        requestBody
      );
      console.log(result, "updateConversationAttributes result");
    } catch (error) {
      // Log any errors that occur during the update
      console.log(error, "updateConversationAttributes Error");
    }
  };

  componentDidMount() {
    let os=this.getOS()
    window.addEventListener('message', this.receivedMessage);
    window.addEventListener('iosCustomAttributes', this.receiveIOSCustomAttributes);  // This is for IOS to get custom attributes
    window.addEventListener('iosConversationId', this.receiveIOSConversationId);     // This is for IOS to get conversation id
    sessionStorage.setItem("chatStatus", true);
    if (os != "Windows") {   //Default Values when launched webchat from Mobile App
        this.state.customerName = "John Smith";
        this.state.email = "john.smith@test.com";
    }
    if (sessionStorage.getItem("ConversationEnded")) {
      this.endChat();
      return
    }
    if(sessionStorage.getItem('textSize')){
      this.setState({textSize:sessionStorage.getItem('textSize')})
    }
    if(this.state.token && !sessionStorage.getItem("conversationid")){
      this.getTheams();
    }
  }

  //This method is used to receive the token from the parenet site and start the conversation.
  //when token is not present , it passes null value
  async receivedMessage(event) {  
  
    // debugger;   
     console.log("Received message from parent origin: " + event.origin);
     if (event.origin !== this.state?.settings?.parentSite) return; // for security reasons if origin is different from  configured site then ignore 
    let isConversationExist = sessionStorage.getItem("conversationid");//is conversation already created..
    if (!!event.data && !!event.data.data && event.data.event == "accessToken" && !isConversationExist) {//for conv not created then only create..
      this.setState({ showInitLoader: false });
      console.log("Received token from parent:" + event.data.data);
        this.setState({ token: event.data.data });
       
      //sessionStorage.setItem('token',event.data.data);
      this.setCookie("token", event.data.data, 1);
      sessionStorage.setItem("isAuthenticated","true")
      if(!!event.data.data && this.state.settings.enableChatBot === "Y"){   // start conversation after getting token from parent
        this.setState({startConvWithDescription:false});  
        this.setState({startConvWithName:true});   
        this.startConversation();
      }     
      else if(this.state.settings.enableChatBot === "N" && !!event.data.data){ // start conversation with description from member when chat bot is disabled 
        this.setState({showInitLoader:true});   
        let convId= await this.getMessagesHistoryWeb("",event.data.data)
         let msgs = [];
         let msg = {};
         if(!convId){
         msg.body = this.state.settings?.defaultGreetingMessage ?? this.state.firstMessageForAuthCust;
         msg.createdDate = Date.now();
         msg.isAgentMessage = true;
         msg.type="SYSTEMMSG";
         msg.status = "delivered";
         msgs.push(msg);
         this.setState({showInitLoader:false});   
         }else{
          if(!sessionStorage.getItem("conversationid")){
          sessionStorage.setItem("conversationid",convId)
          this.startConversation();
          }
         }
         let msgStr = JSON.stringify(msgs);
         sessionStorage.setItem("messages", msgStr);
         this.setState({startConvWithDescription:false,showStartConvScreen:false,startConvWithoutDesc:convId?false:true,showInputTextBox:true,showAgentAssignMsg:false});
         sessionStorage.setItem("showAgentAssignMsg", false);
         sessionStorage.setItem("initAutoClose",false);
      }
    }
    else if(event.data.event == "accessToken"||event.data.event===undefined){
      this.setState({startConvWithName:true});    // when token is not present , start conversation with name
      this.setState({startConvWithDescription:false});
    }
    if(event.data.event==="openWebChat"){
      //moving this logic here to avoid multiple event listner (removed and added here from line no. 122 to 131)
      sessionStorage.setItem("chatStatus", true)
      this.setState({ isChatOpen: true });
      let convId = sessionStorage.getItem("conversationid");
      let messagesStr=sessionStorage.getItem("messages");
      let messageList=messagesStr?JSON.parse(messagesStr):[];
      if (convId && messageList.length>0) {
        let unreadMsgIndex=messageList.findIndex((item)=>item.status==="delivered" && item.isAgentMessage===true)
        if(unreadMsgIndex>-1){  //if messageList contains any unread message then call api.
          this.UpdateConversationAsRead(convId,messageList);
        }
      }
    }
    if(event.data.event==="parentTabIsActive"){
      //update value for parent tab is active or not.
      sessionStorage.setItem("parentTabIsActive", event.data.data.isTabActive)
    }
  }

    async receiveIOSCustomAttributes(event) {
        this.setState({ debugLog: this.state.debugLog + "<br/>" + JSON.stringify(event.detail.data) }); 
        //alert(JSON.stringify(event));
    if (!!event.detail && !!event.detail.data) {
      this.setState({ customAttributes: event.detail.data });
    }
  }

  async receiveIOSConversationId(event) {
    if (!!event.detail && !!event.detail.data) {
      this.setState({ IOSDebug: "-- Received IOS conv id" });
      this.setState({ IOSDebug: this.state.IOSDebug + "-- Received IOS conv id" + event.detail.data });

      sessionStorage.setItem("conversationid", event.detail.data);
    }

  }

  setCookie = async (name, value, days) => {
    const d = new Date();
    d.setTime(d.getTime() + (days*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    var encriptedValue = encodeURIComponent(value);
    document.cookie = name + "=" + encriptedValue + ";" + expires + ";SameSite=None; Secure;";
    }
  
    getCookie = (name) => {
      console.log("cookkie name"+name);
      // debugger;
   
    const cookieString = document.cookie;
    const cookies = cookieString.split(';');
    
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split('=');
      if (cookieName.trim() === name) {
        var t =  decodeURIComponent(cookieValue);
        let convId=t.split(",")
          if (!!t && typeof t === 'string' && convId[0]===sessionStorage.getItem("conversationid")){
          return t;
        }
        else{
          return t;//even we have token due to convId check in above if condition we are getting empty token, for that returning what value we have in cookie.
        }
      }
    }
    
    return "";
  
    }
  

    
  UpdateConversationAsRead = (conversationId,messageList="") => {  // Call API to update conversation as read
    let requestBody = {
      // userId: userInfo.userId,
      isCallFromCustomer: true,
      conversationId: conversationId
    };
     InvokePostWebChatServiceCall(apiConfig.UPDATE_CONVERSATION_AS_READ, requestBody)
      .then((res) => {

        console.log(res, "UpdateConversationAsRead");
        if(messageList!==""){
          let messages=[...messageList]
          for(let i=0;i<messages.length;i++){
            //update delivered messages to seen in object.
            if(messages[i].isAgentMessage===true && messages[i].status==='delivered'){
              messages[i].status='seen'
            }
          }
          let msgStr = JSON.stringify(messages);
          sessionStorage.setItem("messages", msgStr);
        }

      })
      .catch((err) => {


        console.log(err, "Error Message", "UpdateConversationAsRead");

      });
    return;
  }

  async endChat(event, data) {   // This method is used to end the chat
    if (event ==="Survey"){
      this.setState({
        ...this.state,
        showInputTextBox: false,
        showServeyWizard: true,
        showStartConvScreen: false,
        surveyId: data.surveyId
      });
    }
    else{
    SendNotificationToParent({ event: "endSession", data: "endSession", targetOrigin: this.state?.settings?.parentSite });
    SendNotificationToParent({
      event: "minimizeWindow",
      data: "minimizeWindow",
      targetOrigin: this.state?.settings?.parentSite
    });
    this.setState({
      ...this.state,
      showStartConvScreen: true,
      showInputTextBox: false,
      agentImages :{},
      msg: "",
      isChatOpen: false,
      isSessionExpired: false,
      messagesHistory:[],
      token:"",
      showServeyWizard:false,
      isAssignedToAgent:false,
      loadBotMessages:false,
      pvaBotDetails:""
    });
    //on end chat clear token cookies and session storage
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=None; Secure;";
    sessionStorage.clear();
  }
}

  async minimizeChat(event) {
    this.setState({ debugLog: "" });
    SendNotificationToParent({
      event: "minimizeWindow",
      data: "minimizeWindow", targetOrigin: this.state?.settings?.parentSite
    });
    this.setState({ isChatOpen: false });
    sessionStorage.clear();
  }

  setIsChatOpen(val) {
    this.setState({ isChatOpen: val })
  }
  setIsRefresh(val) {
    this.setState({ isRefresh: val })
  }
  setIsAssignedToAgent(val) {
    this.setState({ isAssignedToAgent: val })
  }
  setCustomAttributes(data) {  // set custom attributes from parent for android
    console.log("Data received from Android:" + data);

    this.setState({ customAttributes: data });
    this.startConversation();
    return "Received data from Android:" + data;
  }

  connectToSocket(fun) {
    if (this.state.wsURL) {
      fun(this.state.wsURL)
    }
  }

  filterInternalMessages(messagesArr){
    //return messages array without internal messages
      let filteredList=messagesArr.filter((message)=>message.subType!=="INTERNAL");
      return filteredList;
  }


  showStartConvScreen() {
    this.setState({ showInitLoader: false });
    this.setState({ startConvWithName: false });
    this.setState({ startConvWithDescription: false });
    this.setState({ showStartConvScreen: true });
    this.setState({ showInputTextBox: false });
  }
  async getMessages() {
    this.setState({ debugLog: this.state.debugLog + "-- entered getmessages" });
    this.setState({ showInitLoader: true });
    try {
      this.setState({ debugLog: this.state.debugLog + "-- entered try in  getmessages" });
      let requestBody = {
        conversationId: sessionStorage.getItem("conversationid"),
      };

      this.setState({ debugLog: this.state.debugLog + JSON.stringify(requestBody) });
      const response = await InvokePostWebChatServiceCall(apiConfig.GET_WEBCHAT_MESSAGES, requestBody);

          console.log("Messages-Response :: ", response);
          this.setState({ debugLog: this.state.debugLog + "-- Response for get messages" + JSON.stringify(response.data) });
          if (response.data?.messageInfoWebChat) {
            //set message with queue position & Wait time on auto routing..
            //iterate over the response.data?.messageInfoWebChat
            let messages = this.filterInternalMessages(response.data?.messageInfoWebChat)
           
            var msgsLst = []
            this.setState({ wsURL: response.data.wsURL, memberName: response.data.customerName, isRefresh: true })
            messages.forEach((message, index) => {
                msgsLst.push(message);
            });

            // Check if conversationAttributes exist in the response data
           if (response.data.conversationAttributes) {
              this.setCustomAttributes(response.data.conversationAttributes)
             // Parse conversationAttributes from the response data
              let conversationAttributes=JSON.parse(response.data.conversationAttributes);
             
              // Check if textSize is present in conversationAttributes
              if (conversationAttributes.textSize) {
                // Set the font size using the parsed textSize value
                this.setFontSize(conversationAttributes.textSize,response.data.conversationAttributes);
              }

              // Check if muteNotifications is present in conversationAttributes
              if (conversationAttributes.muteNotifications) {
                // Handle mute or unmute notifications based on the parsed value
                this.handleMuteUnmuteNotifications(conversationAttributes.muteNotifications,response.data.conversationAttributes);
              }
            }

           // Check if the conversation status is not "Active-Bot" in the response data
           if (response.data?.conversationStatus !== "Active-Bot") {
            // If true, set the session variable to indicate that the bot is not active
            sessionStorage.setItem("isBotActive", "N");
           } else if (response.data?.conversationStatus === "Active-Bot") {
             sessionStorage.setItem("isBotActive", "Y");
           }

           // Check if the conversation status is "Active"
           const isConversationActive =
           response.data?.conversationStatus === "Active";
           // Check if the agent is available (not unavailable)
           const isAgentAvailable = !response.data?.isAgentUnavailable;

           // If the conversation is active and the agent is available, mark the user as assigned to an agent
           if (isConversationActive && isAgentAvailable) {
             // Set a session variable to indicate that the user is assigned to an agent
             this.setState({ isAssignedToAgent: true });
           }

           // Check if the conversation status is "PreAssigned" in the response data
           if (response.data?.conversationStatus === "PreAssigned") {
             // If true, set the state to show the agent assignment message
             this.setState({ showAgentAssignMsg: true });
            }

            if (response.data?.conversationStatus === "Completed") {
              this.setState({
                isConversationEnded: true,
                conversationEndedBy: response.data?.conversationEndedBy,
              })
            }
            if (response.data?.convesationAvgWaitTimeResp && response.data?.agentUnavailableMessage) {
              this.setState({
                convesationAvgWaitTimeRespObj: response.data?.convesationAvgWaitTimeResp,
                agentUnavailableMessage: response.data?.agentUnavailableMessage
              })
            }
            if (msgsLst.length > 0) {
              sessionStorage.setItem("messages", JSON.stringify(msgsLst));
              this.setState({ showInputTextBox: true });
              this.setState({ showStartConvScreen: false });
              this.setState({ showInitLoader: false });
            }
          if (response.data?.agentId) {
            this.getAgentImage(response.data?.agentId)
          }
          }        
        else{
            this.setState({ showInputTextBox: false });
            this.setState({ showStartConvScreen: true });
            this.setState({ showInitLoader: false });
          }
  
        } catch (err) {
          console.log("Failed" + err);
          this.setState({ debugLog: this.state.debugLog + "Inside 1st catch" });
          this.setState({ debugLog: this.state.debugLog + err.toString() });
          if (err.response && err.response.status === 401) {
          //  this.endChat();
          this.setState({ ...this.state, isSessionExpired: true });
          }
        } finally {
          this.setState({ showInitLoader: false });
        }
  }

  async getMessagesWeb(setMessages) {
    if(!this.state.token){
    this.setState({ showInitLoader: false });
    try {
      let requestBody = {
        conversationId: sessionStorage.getItem("conversationid"),
      };

      await InvokePostWebChatServiceCall(
        apiConfig.GET_WEBCHAT_MESSAGES,
        requestBody
      )
        .then((response) => {
          console.log("Messages-Response :: ", response);
          if(response.data.conversationAttributes){
            //set converstaion attributes
            this.setCustomAttributes(response.data.conversationAttributes)
          }
          if (response.data?.messageInfoWebChat) {
            //set message with queue position & Wait time on auto routing..
            //iterate over the response.data?.messageInfoWebChat
            let messages = this.filterInternalMessages(response.data?.messageInfoWebChat)
            var msgsLst = []
            this.setState({ wsURL: response.data.wsURL, memberName: response.data.customerName, isRefresh: true })
            messages.forEach((message, index) => {
                msgsLst.push(message);
            });

           // Check if the conversation status is not "Active-Bot" in the response data
           if (response.data?.conversationStatus !== "Active-Bot") {
             // If true, set the session variable to indicate that the bot is not active
             sessionStorage.setItem("isBotActive", "N");
           }

           // Check if the conversation status is "Active"
           const isConversationActive =
           response.data?.conversationStatus === "Active";
           // Check if the agent is available (not unavailable)
           const isAgentAvailable = !response.data?.isAgentUnavailable;

           // If the conversation is active and the agent is available, mark the user as assigned to an agent
           if (isConversationActive && isAgentAvailable) {
             // Set a session variable to indicate that the user is assigned to an agent
             this.setState({ isAssignedToAgent: true });
           }

           // Check if the conversation status is "PreAssigned" in the response data
           if (response.data?.conversationStatus === "PreAssigned") {
            // If true, set the state to show the agent assignment message
            this.setState({ showAgentAssignMsg: true });
           }

           if (response.data?.agentId) {
              this.getAgentImage(response.data?.agentId)
           }
            if (response.data?.conversationStatus === "Completed") {
              this.setState({
                isConversationEnded: true,
                conversationEndedBy: response.data?.conversationEndedBy,
              })
            }
            if (response.data?.convesationAvgWaitTimeResp && response.data?.agentUnavailableMessage) {
              this.setState({
                convesationAvgWaitTimeRespObj: response.data?.convesationAvgWaitTimeResp,
                agentUnavailableMessage: response.data?.agentUnavailableMessage
              })
            }
            if (msgsLst.length > 0) {
              // debugger
              sessionStorage.setItem("messages", JSON.stringify(msgsLst));
              setMessages([...msgsLst])
            }

        }
          this.setState({ showInitLoader: false });
        })
        .catch((err) => {
          this.setState({ showInitLoader: false });
          console.log("Failed" + err);
          if (err.response && err.response.status === 401) {
            this.setState({ ...this.state, isSessionExpired: true });
          }
        });

    }
    catch (e) {
      console.log("Failed" + e);
    }
  }
  }

  async getMessagesHistoryWeb(setMessages = "",token="") {
    if (this.state.token || token!=="") {
      if(!this.state.startConvWithoutDesc){
        this.setState({loading:true});
      }
      let tokenVal=token!==""?token:this.state.token;
      let convId=""
      await InvokePostWebChatServiceCall(
        //This method is used to pass the parent jwt token with member details to the server.
        apiConfig.GET_MESSAGES_HISTORY_WEBCHAT,
        {},
        tokenVal
      )
        .then((data) => {// get all messages history.
          // setMessages([])
          if(data.data.conversationId){
            convId=data.data.conversationId
            //setting customer name for active conversation
            let custName = sessionStorage.getItem("name");
            if (!custName && data.data.customerName){
              sessionStorage.setItem("name", data.data.customerName);
            }
          //   let conversationStartedOnNewWindow = {
          //     messageType: "ConversationStartedOnNewWindow",
          //     conversation:{
          //     conversationId: data.data.conversationId}
          // };
          // SendToAllUserConnections(
          //   data.data.conversationId,
          //     JSON.stringify(conversationStartedOnNewWindow)
          // );
          }
          sessionStorage.setItem('messagesHistory',JSON.stringify(data.data.messageInfoWebChat))
          if(data.data.agentDetails?.length>0){  //create object for all agentImages { agentId : agentImage, agentId1 : agentImage1 }
            let agentDetails= data.data.agentDetails;
            let agentDetailsObj={}
            agentDetails.forEach((item)=>{
              agentDetailsObj[item.userId]=item.profileImg?item.profileImg:""
            })
            this.setState({agentImages:agentDetailsObj})
            sessionStorage.setItem("agentImages",JSON.stringify(agentDetailsObj))
          }
          // Check if the conversation status is "Active"
          const isConversationActive = data.data?.conversationStatus === "Active";
          // Check if the agent is available (not unavailable)
          const isAgentAvailable = !data.data?.isAgentUnavailable;
          // If the conversation is active and the agent is available, mark the user as assigned to an agent
          if (isConversationActive && isAgentAvailable) {
            // Set a variable to indicate that the user is assigned to an agent
            this.setState({ isAssignedToAgent: true });
            sessionStorage.setItem("isAssignedToAgent", JSON.stringify(true))

          }
          this.setState({messagesHistory:data.data.messageInfoWebChat, 
            memberName: data.data.customerName,
            loading:false
          })
        })
        .catch((error) => {
          console.log(error, "Error Message");
        });
        return convId;
    }
  }

  async getTheams() {
    try {
      let whbChatAuthData = webchatTokenData.getAuthData();
      if (!whbChatAuthData?.deploymentId) {
        whbChatAuthData.deploymentId = this.state.deploymentID;
        webchatTokenData.setAuthData(whbChatAuthData);
      }
    }
    catch (error) {
      console.log("Error while setting webchat auth data - ", error)
    }
    this.setState({ showStartConvScreen: false });
    let requestBody = {
      deploymentId: this.state.deploymentID,
    };
    await InvokePostWebChatServiceCall(apiConfig.GET_THEAMS, requestBody)
      .then(async (response) => {
        if (
          response != null &&
          response.data != null &&
          response.data.orgTheams != null
        ) {
          let theamSettingsStrObj = JSON.parse(response.data.orgTheams);
         
          if(theamSettingsStrObj.enableChatBot!=="Y" && this.state.token && !sessionStorage.getItem("conversationid")){
           await this.getMessagesHistoryWeb()
            this.setState({startConvWithoutDesc:true})
            this.setState({firstMessageForAuthCust:theamSettingsStrObj.defaultGreetingMessage})
            sessionStorage.setItem("showAgentAssignMsg", false);
            sessionStorage.setItem("initAutoClose",false);
            sessionStorage.setItem("showAgentUnavailableMessage","false")
          }
          
          if (!this.state.token) {
            SendNotificationToParent({
              event: "getToken",
              data: "getToken", targetOrigin: theamSettingsStrObj.parentSite
            });
          }
          this.setState({ settings: theamSettingsStrObj});
          if(theamSettingsStrObj.enableChatBot === "N"){
            //on refresh check chatbot disabled and showAgentUnavailableMessage is true to show agent assignment message.
               this.setState({
                showAgentAssignMsg: (theamSettingsStrObj.enableChatBot === "N" && sessionStorage.getItem("showAgentUnavailableMessage")==true )
               })
          }
          if (theamSettingsStrObj.enableChatBot === "Y" && !sessionStorage.getItem("conversationid")) {
            sessionStorage.setItem("isBotActive", "Y")
          }
          if (
            (!response.data.isBusinessHours == true &&
              theamSettingsStrObj.enableChatBot !== "Y") ||
            (sessionStorage.getItem("showAgentAssignMsg") &&
              !response.data.isBusinessHours)
          ) {
            //checking  chatbot enable/disable setting before showing the non business hours message.
             // this.setState({ showStartConvScreen: false });
            this.setState({ showOutOfBusinessHoursMsg: true });
            // this.setState({ showInitLoader: false });
            // sessionStorage.clear();
          } 
          // else {

            if (!!sessionStorage.getItem("conversationid") && !sessionStorage.getItem("messages")) {
              this.getMessages();
            }
            else {
              this.setState({ showInitLoader: false });
              this.setState({
                showStartConvScreen: !!sessionStorage.getItem("messages")
                  ? false
                  : true,
              });
              let hasMessages = !!sessionStorage.getItem("messages") ? true : false;
              this.setState({
                showInputTextBox: hasMessages,
                isRefresh: hasMessages,
              });
            }
          // }

        }
        else {
          this.setState({ showInitLoader: false });
        }

      })
      .catch((err) => {
        this.setState({ showInitLoader: false });
        this.setState({ showStartConvScreen: true });
        console.log("Failed" + err);
      });
  }
  async startConversation(setNewMessage="") {
    try {
        await this.getTheams();
        var log = this.state.console;
        log = log + "<br/> startConversation"
        //alert('startConversation')
        if (!!this.state.customerName || !!this.state.token) {
            log = log + "<br/> inside customername/token check"
            //alert('inside customername/token check');
        if(this.state.startConvWithoutDesc){
          this.setState({ showInitLoader: false, reloadChatCompOnStartChatWithoutBot:true });
        }
        this.setState({ showStartConvScreen: false });
         if(!this.state.startConvWithoutDesc){
            this.setState({ showInitLoader: true });
            await this.getMessagesHistoryWeb();// get conversation history.
         }
            // alert(this.state.customAttributes);

        var enableChatBot =
          this.state.settings.enableChatBot == "Y" ? true : false;
        let requestBody = {
          conversation: {
            source: "Inbound",
            channel: "Chat",
            agentId: "",
            customerPhoneNumber: "",
            ConversationAttributes: "",
            conversationBody: this.state.msg.trim(),
          },
          contact: {
            firstName: this.state.customerName,
            lastName: "",
            email: this.state.email,
            contactAttributes: "",
          },
          userDeviceInfo:{
            sourceIP:this.state.sourceIP,
            userAgent:this.state.userAgent,
            latitude:this.state.lat,
            longitude:this.state.long
          },
          teamName: this.state.settings.team,
          teamId: this.state.settings.teamId,
          enableChatBot: enableChatBot,
          deploymentId: this.state.deploymentID,
        };
      
          let endpoint = apiConfig.START_CONVERSATION_WEBCLIENT;
          // debugger;
            if (!!this.state.token && (typeof this.state.token === 'string' || this.state.token instanceof String)) {
                log = log + "<br/> Auth";
                //alert('auth');
            endpoint = apiConfig.START_CONVERSATION_WEBCLIENT_AUTHORIZED;   // Rajesh -> 07/26/2023 -> Added this to support the token based authentication.(on server side token is resolved to member details )
            }
            log = log + "---" + JSON.stringify(requestBody);
            this.setState({ debugLog: log });
          await InvokePostWebChatServiceCall(              //This method is used to pass the parent jwt token with member details to the server.
          endpoint,
          requestBody,this.state.token
        )
          .then((response) => {
            this.setState({startConvWithoutDesc:false});
            if(setNewMessage!==""){
              setNewMessage("")
            }
            // debugger;
            if( !response || (!!response  &&  response.status != 200) || (!response.data?.wsURL)){  // Rajesh -> 07/28/2023 -> If token failed to resolve the member details then we are showing the partial data message for member to input details
              this.showStartConvScreen();
              this.setState({ showPartialDataMsg: true });
              this.setState({ token: "" });
              return;
            }
            if (response.data.conversationAttributes) {//will get only the bot is active
              this.setState({ pvaBotDetails: response.data.conversationAttributes })
              sessionStorage.setItem("pvaBotDetails", response.data.conversationAttributes);
            }
            if(response.data.isConversationAlreadyActive){
              this.setState({ isRefresh: true})
            }
            //when the new conv created & bot is active load bot message & prompt
            else if (response.data?.conversationWithBot === "Y"){
                this.setState({ loadBotMessages: true });
            }
            if (!!response.data.wsURL) {

              sessionStorage.setItem(
                "conversationid",
                response.data.conversationId
              );
              if(this.state.token){
              let tokenWithConvId=`${response.data.conversationId},${this.state.token}`;
              this.setCookie("token",tokenWithConvId,1)
              }
              sessionStorage.setItem("wsUrl", response.data.wsURL);
              sessionStorage.setItem("name", this.state.customerName);
              if(response.data?.contact?.firstName || response.data?.contact?.lastName){
                //join the complete member name and show
                let memberName=`${response.data.contact.firstName} ${response.data.contact.lastName}`
                sessionStorage.setItem("name", memberName);
                this.setState({memberName:memberName})
              }
              SendNotificationToParent({
                event: "sendConvId",
                data: response.data.conversationId, targetOrigin: this.state?.settings?.parentSite
              });
            }
            // Check if conversationWithBot is not equal to "Y" in response data
            if (response.data?.conversationWithBot !== "Y") {
              // If the condition is true, set isBotActive in sessionStorage to "N"
              sessionStorage.setItem("isBotActive", "N");
            }           
            if (response.data?.isAgentUnavailable) {
              sessionStorage.setItem(
                "isAgentUnavailable",
                response.data?.isAgentUnavailable
              );
              // sessionStorage.setItem(
              //   "agentUnavailableConfigMessage",
              //   response.data?.agentUnavailableMessage
              // );
              sessionStorage.setItem(
                "initialAvgWaitTime",
                JSON.stringify(response.data?.convesationAvgWaitTimeResp)
              );
            }
            let tempMsg=sessionStorage.getItem("messages");
            let tempMsgArr=[];
            if(tempMsg){
                tempMsgArr=JSON.parse(tempMsg)
            }
            var msgs = [...tempMsgArr];
            var msg = {};
            // if(!!this.state.msg){
            msg.body = this.state.msg.trim();
            msg.createdDate = Date.now();
            msg.isAgentMessage = false;
            msg.type="INBOUND"

            if (enableChatBot) {
              msg.status = "seen";
              msg.isFirstMessage=true //using this field to avoid duplicate member message when conversation starts (TEXTING-1899).
            } else {
              msg.status = "delivered";
            }
            //for already active conv causing no customer name for first message 
            // creating empty message 
            if (this.state.msg.trim()){
            msgs.push(msg);
            }
            // }
            if (response.data?.messageBody) {
              var serverMsg = {};
              serverMsg.body = response.data?.messageBody;
              serverMsg.createdDate = Date.now();
              serverMsg.isAgentMessage = true;
              msgs.push(serverMsg);
            }
            let msgStr = JSON.stringify(msgs);
            sessionStorage.setItem("messages", msgStr);
            this.setState({ showInputTextBox: true });    
            if (!enableChatBot && response.data?.isAgentUnavailable) {
              this.UpdateConversationAsRead(response.data.conversationId);
            }
            this.setState({ showInitLoader: false });
            if (this.state.settings.enableChatBot !== "Y" && !response.data?.isAgentUnavailable) {
              //if chatbot disabled and Agent is Unavailable then show assigning agent message.
              this.setState({ showAgentAssignMsg: true });

              // Check if the 'response.data.contact' exists, and if it does, check if 'conversations' array has length
              // Also, check if the status of the first conversation is "Active"
              if(response.data.contact?.conversations?.length && response.data.contact.conversations[0].status==="Active"){
                this.setState({ showAgentAssignMsg: false });
              }
            }
            this.setState({reloadChatCompOnStartChatWithoutBot:false})

          })
          .catch((err) => {
            console.log("Failed" + err);
            this.showStartConvScreen();
            this.setState({ showPartialDataMsg: true });
            this.setState({token: ""  })
          });
      }
        this.setState({ debugLog: log });
    } finally {
      this.setState({ showInitLoader: false });
    }
  }

  getQueryVariable(variable) {
    /* SendNotificationToParent({
       event: "minimizeWindow",
       data: "minimizeWindow",targetOrigin: this.state?.settings?.parentSite
     });  */
    var query = window.location.search.substring(1);
    console.log(query); //"app=article&act=news_content&aid=160990"
    var vars = query.split("&");
    console.log(vars); //[ 'app=article', 'act=news_content', 'aid=160990' ]
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      console.log(pair); //[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  }

  updatecustomerName(event) {
    this.setState({
      customerName: event.target.value,
      nameError: false,
    });
  }
  updatecustomermessage(event) {
    this.setState({
      msg: event.target.value,
      descrError: false,
    });
  }
  updatecustomerEmail(event) {
    this.setState({
      email: event.target.value,
      mailError: false,
    });
  }

  getOS() {//get current os 
    var userAgent = window.navigator.userAgent,
      platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (/Linux/.test(platform)) {
      os = 'Linux';
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.getCurrentPositionSuccess, this.getCurrentPositionError);
    } else {
      console.log("Geolocation not supported");
    }
    this.getUserIP();
    this.setState({ os: os, isMobileDevice: (os === "Android" || os === "iOS"), userAgent: navigator?.userAgentData?.mobile ? 'Mobile' : "Browser", })
    return os;
  }
  async getUserIP() {
    try{
    const response = await fetch('https://geolocation-db.com/json/');
    const data = await response.json();
    this.setState({ ...this.state, sourceIP: data.IPv4 });
    }
    catch(e){
      console.log("Error while  getting user IP - ", e);
    }
  }
  getCurrentPositionSuccess(position) {
    this.setState({ lat: position.coords.latitude, long: position.coords.longitude });
  }
  getCurrentPositionError(err) {
    console.log("Unable to retrieve your location ::", err);
  }
  setStateFromChild(stateName) {
    this.setState(stateName)
  }

   getAgentImage (agentId) {
    if(this.state.settings.showAgentImage==="Y"){
    //if showAgentImage setting enable then this function will get the agent profile image from api when conversation is assigned to agent.
    let requestBody = {
      userId: agentId      
    };
    InvokePostWebChatServiceCall(apiConfig.GET_USER_PROFILE_IMAGE, requestBody)
      .then((res) => {
          let agentDetailsObj=this.state.agentImages
          agentDetailsObj[agentId]=res.data.profileImg?res.data.profileImg:""
          this.setState({agentImages:agentDetailsObj})
          sessionStorage.setItem("agentImages",JSON.stringify(agentDetailsObj))
      })
      .catch((err) => {
        console.log(err, "Error Message", "GetAgentImage");
      });
    }
  }
  render() {
      const isMemberDataSaved = !!this.state.customerName && !!this.state.email;
      const open = Boolean(this.state.anchorEl);
      const smallFontSize="100%";
      const mediumFontSize="110%";
      const largeFontSize="120%";
    return (
      <ThemeProvider theme={wcuTheme}>
        <div>


          {this.state.showInitLoader == true ? (
            <div>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={this.state.showInitLoader}
              >
                <Stack gap={1} justifyContent="center" alignItems="center">
                  <CircularProgress color="inherit" />
                  <Typography>Loading...</Typography>
                </Stack>
              </Backdrop>

            </div>
          ) : (
            <>
              <div>
                {this.state.showInputTextBox === false && this.state.showServeyWizard  === false? (
                  <>
                    <div className="chatHeader" style={(this.state.isMobileDevice? {padding:"0px"} : {})}>
                            {this.state.isMobileDevice &&
                              <Tooltip>
                                <IconButton
                                  color="inherit"
                                  onClick={this.minimizeChat}
                                >
                                  <KeyboardArrowLeftIcon
                                    sx={{ fontSize: 40, color: "white" }}
                                  />
                                </IconButton>
                              </Tooltip>
                           }
                      <div style={{ width: "100%", marginLeft: "50px" }}>
                        {" "}
                        <span
                          style={{
                            color: "white",
                            fontSize: this.state.textSize === "Medium" ? mediumFontSize : this.state.textSize === "Large" ? largeFontSize : smallFontSize
                          }}
                        >
                          Chat with us{" "}
                        </span>{" "}
                      </div>
                      <div
                        style={{
                          float: "right",
                          marginTop: "-30px",
                          display: "flex"
                        }}
                      >
                        <Tooltip title="Font Size">
                          <IconButton
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            color="inherit"
                            sx={{ marginTop: "16px", top: "5px" }}
                            onClick={(e) => { this.handleClick(e) }}
                          >
                            <FormatSizeIcon
                              sx={{
                                fontSize: 24, color: "white"
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                              {!this.state.isMobileDevice
                                && <Tooltip title="Minimize Chat">
                                  <IconButton
                                    color="inherit"
                                    onClick={this.minimizeChat}
                                  >
                                    <MinimizeIcon
                                      sx={{ fontSize: 40, color: "white" }}
                                    />
                                  </IconButton>
                                </Tooltip>}
                      </div>
                    </div>
                    <Menu
                      id="basic-menu"
                      anchorEl={this.state.anchorEl}
                      open={open}
                      onClose={this.handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem selected={this.state.textSize === 'Small'} onClick={() => { this.setFontSize('Small'); this.handleClose(); }}>Small</MenuItem>
                      <MenuItem selected={this.state.textSize === 'Medium'} onClick={() => { this.setFontSize('Medium'); this.handleClose(); }}>Medium</MenuItem>
                      <MenuItem selected={this.state.textSize === 'Large'} onClick={() => { this.setFontSize('Large'); this.handleClose(); }}>Large</MenuItem>
                    </Menu>
                  </>
                ) : (
                  <></>
                )}
                            {(isMemberDataSaved || this.state.showStartConvScreen === true) && (
                                <div>

                  {this.state.showStartConvScreen === true ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "30%",
                      }}
                    >
                      {this.state.showPartialDataMsg == true &&(
                        <div style={{textAlign:"center",fontSize:"large",color : "black"  }} > Sorry! we could not get your complete details.Please fill below form </div>)}  
                      {(this.state.startConvWithName === true && this.state.startConvWithDescription === false) &&( <>
                        <div class="formElement">
                          <TextField
                            required
                            id="outlined-required"
                            error={this.state.nameError}
                            helperText={
                              this.state.nameError ? "Please Enter name." : ""
                            }
                            label="Name"
                            onChange={this.updatecustomerName}
                            value={this.state.customerName}
                            size="small"
                            autoComplete="off"
                            marginTop="10px"
                            style={{
                              width: "280px",
                            }}
                            inputProps={{
                              style: { 
                              fontSize:this.state.textSize==="Medium"?mediumFontSize:this.state.textSize==="Large"?largeFontSize:smallFontSize,
                            }
                            }}
                          />
                        </div>
                        <div class="formElement">
                          <TextField
                            required
                            id="outlined-required"
                            error={this.state.mailError}
                            helperText={
                              this.state.mailError
                                ? "Please Enter Correct Email."
                                : ""
                            }
                            label="Email"
                            onChange={this.updatecustomerEmail}
                            value={this.state.email}
                            size="small"
                            marginTop="10px"
                            autoComplete="off"
                            style={{
                              width: "280px",
                            }}
                            inputProps={{
                              style: { 
                              fontSize:this.state.textSize==="Medium"?mediumFontSize:this.state.textSize==="Large"?largeFontSize:smallFontSize,
                            }
                            }}
                          />
                        </div>
                      </> )}
                       
                      {this.state.startConvWithDescription === true && (<div> <div>
                          <h5>How we can assist you?</h5>
                        </div>  </div>)}
                        <div class="formElement">
                        <TextField
                          required
                          id="outlined-required"
                          error={this.state.descrError}
                          helperText={
                            this.state.descrError
                              ? "Please Enter Description."
                              : ""
                          }
                          label="Description"
                          onChange={this.updatecustomermessage}
                          value={this.state.msg}
                          size="small"
                          marginTop="10px"
                          multiline
                          maxRows={this.state.os === "iOS" ? 5 : 3}
                          style={{
                            width: "280px",
                          }}
                          inputProps={{
                            style: { WebkitOverflowScrolling: "touch",
                            fontSize:this.state.textSize==="Medium"?mediumFontSize:this.state.textSize==="Large"?largeFontSize:smallFontSize,
                          }
                          }}
                          autoComplete="off"
                        />
                      </div>
                      <div class="formElement">
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: "#001433",
                            "&:hover": { backgroundColor: "#001433bd" },
                            fontSize:this.state.textSize==="Medium"?mediumFontSize:this.state.textSize==="Large"?largeFontSize:smallFontSize,
                          }}
                          disabled={this.state.showOutOfBusinessHoursMsg}
                          onClick={async () => {
                            this.setState({
                              isSessionExpired:false,
                              isConversationEnded: false,
                              convesationAvgWaitTimeRespObj: "",
                              agentUnavailableMessage: "",
                                conversationEndedBy: "",
                                debugLog: this.state.debugLog + "<br/>StartConversation"
                            })
                              if (!this.state.msg || !this.state.customerName || !this.state.email) {
                                //check if all fields are filled or not and if not then prevent conversation from starting
                                if(!this.state.msg){
                                  this.setState({ descrError: true });
                                }
                                if(!this.state.customerName){
                                  this.setState({ nameError: true });
                                }
                                if(!this.state.email){
                                  this.setState({ mailError: true });
                                }
                                  return; // Prevent conversation from starting
                              }
                            if (regEmail.test(this.state.email)) {
                              if (!!window.Android) {
                                  this.setState({ showInitLoader: true });
                                  var log = this.state.debugLog + "<br/> android start";
                                let res = SendNotificationToParent({
                                  event: "getCustomAttributes",
                                  data: "getCustomAttributes", targetOrigin: this.state?.settings?.parentSite
                                });
                                  this.setState({ debugLog: log + "<br/>" + JSON.stringify(res)});
                                if (!!res) {
                                    let strCustomAttributes = JSON.stringify(res);
                                    this.setState({ debugLog: this.state.debugLog + "<br/>cust:" + strCustomAttributes });
                                    // alert(strCustomAttributes);
                                  this.setState({ customAttributes: strCustomAttributes });
                                }
                                this.startConversation();
                              } else {
                                //this.setState({ showInitLoader: true });
                                this.startConversation();

                              }

                              //  window.parent.postMessage({ event:"requiredCustomAttributes", data:""}, '*');
                            } 
                            else if(!!this.state.token){
                              this.startConversation();
                            }
                            else {
                              if (!regEmail.test(this.state.email)) {
                                this.setState({ mailError: true });
                              }
                              if (
                                this.state.msg == null ||
                                this.state.msg == "" ||
                                this.state.msg == undefined
                              ) {
                                this.setState({ descrError: true });
                              }
                              if (
                                this.state.customerName == null ||
                                this.state.customerName == "" ||
                                this.state.customerName == undefined
                              ) {
                                this.setState({ nameError: true });
                              }
                            }
                          }}
                        >
                          Start Chat
                        </Button>
                                            </div>
                                            {/* <div>{this.state.debugLog}</div> */}
                          {this.state.showOutOfBusinessHoursMsg &&
                            <WebChatInfoCard
                              cardType="NONBUSINESSHOURS"
                              message={this.state.settings.outofBusinessHoursMessage}
                              settings={this.state.settings}
                              textSize={this.state.textSize}
                            ></WebChatInfoCard>}
                    </div>
                  ) : (
                    <></>
                  )}
                  </div>
                )}
              {this.state.showInputTextBox == true ? (
                <>
                {this.state.loading && (
                 <Backdrop
                   sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                   open={this.state.loading}
                 >
                   <Stack gap={1} justifyContent="center" alignItems="center">
                     <CircularProgress color="inherit" />
                     <Typography>Loading...</Typography>
                   </Stack>
                 </Backdrop>
                )}
                <Chat endChat={this.endChat} connectToSocket={this.connectToSocket} memberName={this.state.memberName} isChatOpen={this.state.isChatOpen} setIsChatOpen={this.setIsChatOpen}
                  isConversationEnded={this.state.isConversationEnded}
                  convesationAvgWaitTimeRespObj={this.state.convesationAvgWaitTimeRespObj}
                  agentUnavailableMessage={this.state.agentUnavailableMessage}
                  isRefresh={this.state.isRefresh}
                  setIsRefresh={this.setIsRefresh}
                  setIsAssignedToAgent={this.setIsAssignedToAgent}
                  isAssignedToAgent={this.state.isAssignedToAgent}
                  conversationEndedBy={this.state.conversationEndedBy}
                  os={this.state.os}
                  getMessagesWeb={this.getMessagesWeb}
                  isNewConvWithAttributes={this.state.isNewConvWithAttributes}
                  setStateFromChild={this.setStateFromChild}
                  isSessionExpired={this.state.isSessionExpired}
                  settings={this.state.settings}
                 agentImages={this.state.agentImages}
                 getAgentImage={this.getAgentImage}
                 messagesHistory={this.state.messagesHistory}
                 token={this.state.token}
                 textSize={this.state.textSize}
                 setFontSize={this.setFontSize}
                 showAgentAssignMsg={this.state.showAgentAssignMsg}
                 setCustomAttributes={this.setCustomAttributes}
                 muteNotifications={this.state.muteNotifications}
                 handleMuteUnmuteNotifications={this.handleMuteUnmuteNotifications}
                 startConversation={this.startConversation}
                 descriptionMessage={this.state.msg}
                 updatecustomermessage={this.updatecustomermessage}
                 startConvWithoutDesc={this.state.startConvWithoutDesc}
                 loadBotMessages={this.state.loadBotMessages}
                 reloadChatCompOnStartChatWithoutBot={this.state.reloadChatCompOnStartChatWithoutBot}
                 pvaBotDetailsAttribute={this.state.pvaBotDetails}
                 isMobileDevice={this.state.isMobileDevice}
                 isShowNonBusinessHoursMsg={this.state.showOutOfBusinessHoursMsg}
                />
                </>
              ) : (
                <></>
              )}
                {this.state.showServeyWizard === true ? (
                  <QuestionsWizard
                   surveyId={this.state.surveyId}
                    parentApp="WebChat"
                    endChat={this.endChat}
                    settings={this.state.settings}
                    textSize={this.state.textSize}
                    setFontSize={this.setFontSize}
                    isMobileDevice={this.state.isMobileDevice}
                  ></QuestionsWizard>
                ) : (
                  <></>
                )}
                {
                  this.state.isSessionExpired &&
                  <Dialog
                    open={true}
                    onClose={(event, reason) => {
                      if (reason === "clickaway") {
                        this.endChat();
                        return;
                      }
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Conversation ended!
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => {
                        this.endChat();
                      }
                      }>Ok</Button>

                    </DialogActions>
                  </Dialog>
                }
            </div>
            {/* } */}
              </>
          )}
        </div>
      </ThemeProvider>
    );
  }
}

export default WebChatCustomerApp;
