/**
 * 12-01-2023 => SUDAM B CHAVAN =>TEXTING-1881- Chat Survey Webchat UI
 */
import React from "react";
import { Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

function SnackBar(props) {
  const { openSnackbar, handleCloseSnackbar, userMessage, severity, isManualClose, marginTop }=props;
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        {...!isManualClose &&
        {autoHideDuration:2000}}
        onClose={handleCloseSnackbar}
        sx={{...marginTop && {marginTop: marginTop} }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          sx={{ width: "100%", paddingTop: 1 }}
        >
          <span style={{ fontWeight: "bold" }}>{userMessage}</span>
        </Alert>
      </Snackbar>
    </>
  );
}

export default SnackBar;
