import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../AuthContext";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import apiConfig from "../../api/apiConfig";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box'
import { SendToAllUserConnections } from "../../azure/pubSub";



function DocuSignMessage() {


    const {
        userInfo,
        showDocuSignMessage, setShowDocuSignMessage,
        isTokenAvailable
    } = useContext(AuthContext);

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        //debugger;

        if (isTokenAvailable == true) {
            var envelopeId = getQueryParameters("envelopeId");
            var code = getQueryParameters("code");
            if (envelopeId != false) {
                setShowDocuSignMessage(true);

                let requestBody = {
                    createRecipientViewRequest: {
                        email: getQueryParameters("email"),
                        userName: getQueryParameters("name"),
                        envelopeId: envelopeId,
                    },
                    userDetails: {
                        userId: getQueryParameters("userId"),
                        conversationId: getQueryParameters("convId"),
                        teamId: getQueryParameters("teamId"),
                    },
                };
                InvokePostServiceCall(apiConfig.SEND_SIGNINGURL, requestBody)
                    .then((data) => {
                        setShowSuccessMessage(true);
                        console.log(data, "recipientview data");
                        //  setSenderViewUrl("");
                        window.close();
                        setShowLoader(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        setShowLoader(false);
                    });

            }
            else if (code != false) {
                // Check the Userid passed from the return url of docusign
                var userId = getQueryParameters("state");
                let msgBody = {
                    messageType: "DocuSignCode",
                    code: code,
                };
                // send the code as pubsub msg to the parent window
                SendToAllUserConnections(
                    userId,
                    JSON.stringify(msgBody)
                );
                setTimeout(function () {
                    window.close();
                }, 2000);
              
            }
            else {
                setShowDocuSignMessage(false);
                setShowLoader(false);
            }
        }
    }, [isTokenAvailable]);

    const getQueryParameters = (variable) => {
        var query = window.location.search.substring(1);
        console.log(query)//"app=article&act=news_content&aid=160990"
        var vars = query.split("&");
        console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
            if (pair[0] == variable) { return pair[1]; }
        }
        return (false);
    };

    return (
        <>

            <div>
                {showSuccessMessage ?
                    <div style={{ textAlign: "center" }}>
                        <div style={{ marginTop: "5%" }}>
                            <h3>
                                Document Sent Successfully.
                            </h3>
                            <h3>
                                You can close the window now.
                            </h3>
                        </div>
                    </div> : <div style={{ textAlign: "center" }}>
                        <div style={{ marginTop: "5%" }}>
                            <h3>
                            </h3>

                        </div>
                    </div>}
            </div>
            {showLoader && (
                <Box sx={{ display: "flex", marginLeft: "50%", marginTop: "10%" }}>
                    <CircularProgress />
                </Box>
            )}



        </>

    )
}

export default DocuSignMessage