import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useTheme } from "@emotion/react";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#505F79",
        color: theme.palette.common.white,
        fontWeight: "bold"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#eeeeee",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
function descendingComparator(a, b, orderBy) {
    console.log("a", a)
    switch (orderBy) {
        case "Name":
            if (b["teamName"] < a["teamName"]) {
                return -1;
            }
            if (b["teamName"] > a["teamName"]) {
                return 1;
            }
            return 0;
        case "Agents":
            if (b["agents"] < a["agents"]) {
                return -1;
            }
            if (b["agents"] > a["agents"]) {
                return 1;
            }
            return 0;
        case "Available":
            if (b["agentsAvailable"] < a["agentsAvailable"]) {
                return -1;
            }
            if (b["agentsAvailable"] > a["agentsAvailable"]) {
                return 1;
            }
            return 0;
        case "Busy":
            if (b["agentsBusy"] < a["agentsBusy"]) {
                return -1;
            }
            if (b["agentsBusy"] > a["agentsBusy"]) {
                return 1;
            }
            return 0;
        case "Busy":
            if (b["agentsNotAvailable"] < a["agentsNotAvailable"]) {
                return -1;
            }
            if (b["agentsNotAvailable"] > a["agentsNotAvailable"]) {
                return 1;
            }
            return 0;
        default:
            break;
    }


}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'Name',
        numeric: false,
        disablePadding: true,
        label: 'Name',
    },
    {
        id: 'Agents',
        numeric: true,
        disablePadding: false,
        label: 'Agents',
    },
    {
        id: 'Available',
        numeric: true,
        disablePadding: false,
        label: 'Available',
    },
    {
        id: 'Busy',
        numeric: true,
        disablePadding: false,
        label: 'Busy',
    },
    {
        id: 'Not Available',
        numeric: true,
        disablePadding: false,
        label: 'Offline',
    },
];
function EnhancedTableHead(props) {
    const theme = useTheme();
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead >
            <TableRow>
                {headCells.map((headCell, index) => {
                    return (<>

                        <StyledTableCell
                            key={"headCell" + index}
                            align="left"
                        > <Box sx={theme.customStyle.headerText} onClick={createSortHandler(headCell.id)}>
                                <TableSortLabel
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}

                                </TableSortLabel>
                                |
                            </Box>
                        </StyledTableCell>

                    </>)
                }
                )}

            </TableRow>
        </TableHead >
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};



export default function TeamsTable(props) {
    let { teams } = props;
    const [order, setOrder] = React.useState();
    const [orderBy, setOrderBy] = React.useState('Name');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const theme = useTheme();
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.


    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={teams.length}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(teams, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    console.log("row", row)
                                    return (
                                        <>
                                            <StyledTableRow
                                                tabIndex={-1}
                                                key={"teamsrow" + index}
                                            >
                                                <TableCell align="left">{row.teamName}</TableCell>
                                                <TableCell align="left">{row.agents ? row.agents : 0}</TableCell>
                                                <TableCell align="left">{row.agentsAvailable ? row.agentsAvailable : 0}</TableCell>
                                                <TableCell align="left" >{row.agentsBusy ? row.agentsBusy : 0}</TableCell>
                                                <TableCell align="left"> {row.agentsNotAvailable ? row.agentsNotAvailable : 0}</TableCell>
                                            </StyledTableRow>
                                        </>

                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={teams.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Per page:"
                /> */}
            </Paper>
        </Box>
    );
}
