/***********************************
 * 08-11-2022 => Texting-759 - reseting the contactlist before api call
 * 11-16-2022 => ONKAR => Texting-788 => Team name added in chip for selected team in filter.
 * 11-18-2022 => ONKAR => Texting-788 => resetting the table page number to 0 after search or clear filter.
 * 22/11/2022   Texting:827- no rectords found after no data 
 * 04-08-2023 => SUDAM B CHAVAN => reset page index to 1 page on filter data
 * 08-29-2023 => ONKAR => TEXTING-1578 -REACT Upgrade 
 * 08-30-2023 => ONKAR => TEXTING-1578 -REACT Upgrade
 */

import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import { Button, IconButton } from "@mui/material";
import apiConfig from "../../api/apiConfig";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../AuthContext";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import FilterComponent from "../../components/FilterComponent";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Chip from "@mui/material/Chip";
import SnackBar from "../../components/SnackBar";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { StyledDataGrid } from "../../components/styledComponents/StyledDataGrid";


const ContactsPage = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const { userInfo, contactsFilterData, setContactsFilterData, messageApiToken, loadContactFilter } = useContext(AuthContext);
    const [contactsData, setContactsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showFilter, setShowFilter] = useState(false);
    const [filterChip, setFilterChip] = useState([]);
    const [contactsDataCopy, setContactsDataCopy] = useState([]);
    const [deletedChip, setDeletedChip] = useState("");
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [filteredUsersCopy, setFilteredUsersCopy] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [filterApplied, setFilterApplied] = useState(false);
    const [filterLoading, setFilterLoading] = useState(false);
    const [snackbarMsg, setSnackBarMsg] = useState("");
    const [currentpage, setCurrentPage] = useState(0);
    const [filterTeamName, setFilterTeamName] = useState("");

    const [page, setPage] = useState(0);

    const columns = [
        {
            field: "firstName",
            headerName: "First Name",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "lastName",
            headerName: "Last Name",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "mobileNumber",
            headerName: "Phone",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },

        {
            field: "createdDate",
            headerName: "Created Date",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "lastModifiedDate",
            headerName: "Modified Date",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
    ];

    const userArr = [];

    useEffect(() => {
        if (messageApiToken) {
            setContactsData([]); //reset the list before api call
            if (userInfo.teamId && !loadContactFilter) {
                let requestBody = { userId: userInfo.userId }
                setIsLoading(true);
                InvokePostServiceCall(apiConfig.GET_CONTACTS, requestBody)
                    .then((data) => {
                        let tempArr = [];
                        data.data.contactdetails.forEach((item, index) => {
                            tempArr.push({
                                contactAttributes: item.contact.contactAttributes,
                                actionType: item.actionType,
                                conversations: item.contact.conversations,
                                id: index,
                                contactId: item.contact.contactId,
                                createdBy: item.contact.createdBy,
                                createdByName: item.contact.createdByName,
                                createdDate: moment(item.contact.createdDate)
                                    .local()
                                    .format("MM-DD-YYYY"),
                                email: item.contact.email,
                                fullName: item.contact.firstName + " " + item.contact.lastName,
                                firstName: item.contact.firstName,
                                lastModifiedBy: item.contact.lastModifiedBy,
                                lastModifiedByName: item.contact.lastModifiedByName,
                                lastModifiedDate: moment(item.contact.lastModifiedDate)
                                    .local()
                                    .format("MM-DD-YYYY"),
                                lastName: item.contact.lastName,
                                mobileNumber: item.contact.mobileNumber,
                                name: item.contact.name,
                                teams: item.teams,
                                createdByNavigation: item.contact.createdByNavigation,
                                lastModifiedByNavigation: item.contact.lastModifiedByNavigation,
                                teamToContacts: item.contact.teamToContacts,
                                indexId: index,
                            })
                        })
                        console.log(data, "contactsData");
                        setContactsData(tempArr);
                        setContactsDataCopy(tempArr);
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        console.log("api response", error);
                        setSnackBarMsg(error.message)
                        setOpenSnackbar(true);
                        setIsLoading(false);
                    });
            }
            else {
                setIsLoading(false);
            }
        }
    }, [messageApiToken, userInfo.teamId, loadContactFilter]);


    function handleFilter() {
        setShowFilter(!showFilter);
    }
    const handleDelete = (chipToDelete) => () => {
        setFilterChip((chips) => chips.filter((chip) => chip !== chipToDelete));
        setDeletedChip(chipToDelete);

    };
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };
    const MsgCmp = () => {
        return (
            <p
                style={{
                    fontWeight: 500,
                    marginLeft: "10px",
                    marginTop: "5px",
                }}
            >
                *Only 100 contacts are displayed, please use filter option to refine the
                results further
            </p>
        );
    };
    const filterTable = (value) => {
        let usArr = [];
        let val = value?.toLowerCase();
        setPage(0);
        if (filterApplied && filterChip.length > 0) {
            filteredUsersCopy.forEach((item) => {

                if (
                    item.firstName?.toLowerCase().includes(val) ||
                    item.lastName?.toLowerCase().includes(val) ||
                    item.email?.toLowerCase().includes(val) ||
                    item.mobileNumber?.toLowerCase().includes(val) ||
                    item.createdDate?.toLowerCase().includes(val) ||
                    item.lastModifiedDate?.toLowerCase().includes(val)
                ) {
                    usArr.push(item);
                }
            });
            setFilteredUsers(usArr);

        } else {
            contactsDataCopy.forEach((item) => {

                if (
                    item.firstName?.toLowerCase().includes(val) ||
                    item.lastName?.toLowerCase().includes(val) ||
                    item.email?.toLowerCase().includes(val) ||
                    item.mobileNumber?.toLowerCase().includes(val) ||
                    item.createdDate?.toLowerCase().includes(val) ||
                    item.lastModifiedDate?.toLowerCase().includes(val)
                ) {
                    usArr.push(item);
                }
            });
            setContactsData(usArr);
        }

    }
    return (
        <>
            <SnackBar
                openSnackbar={openSnackbar}
                handleCloseSnackbar={handleCloseSnackbar}
                severity={"error"}
                userMessage={snackbarMsg}
            />
            <div>
                <Box sx={theme.customStyle.tableRow}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <h2 style={{ marginLeft: 10 }}>Contacts</h2>
                        <IconButton
                            sx={{ marginLeft: "5px" }}
                            onClick={() => {
                                handleFilter();
                            
                            }}
                        >
                            <FilterAltIcon />
                        </IconButton>
                        {filterChip.map((item) => {
                            return (
                                <div style={{ marginLeft: "5px" }}>
                                    <Chip label={item === "Team" ? "Team: " + filterTeamName : item} onDelete={handleDelete(item)} />
                                </div>

                            );
                        })}
                    </div>
                </Box>
            </div>
            <FilterComponent
                showFilter={showFilter}
                filterChip={filterChip}
                setFilterChip={setFilterChip}
                deletedChip={deletedChip}
                setDeletedChip={setDeletedChip}
                url={apiConfig.GET_CONTACTS_BY_FILTER}
                setShowFilter={setShowFilter}
                currentPage={"Contacts"}
                setFilteredUsers={setFilteredUsers}
                setFilterApplied={setFilterApplied}
                setFilteredUsersCopy={setFilteredUsersCopy}
                setIsLoading={setFilterLoading}
                isLoading={filterLoading}
                filterData={contactsFilterData}
                setFilterData={setContactsFilterData}
                setCurrentPage={setCurrentPage}
                filterTeamName={filterTeamName}
                setFilterTeamName={setFilterTeamName}
                setPage={setPage}
            />
            <div style={{ display: "flex", alignItems: "flex-start", paddingLeft: 10 }}>
                <Box sx={theme.customStyle.tableRow}>
                    <Button
                        sx={theme.customStyle.modalbtn}
                        onClick={() => {
                            navigate("/addUpdateContact", {
                                state: { isAddContact: true },
                            });
                        }}
                    >
                        <AddIcon />
                    </Button>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <TextField
                            sx={{ marginRight: "10px", marginBottom: "10px" }}
                            size="small"
                            type="search"
                            variant="outlined"
                            margin="normal"
                            id="outlined-search"
                            label=""
                            placeholder="Contacts Search"
                            autoComplete="off"
                            onChange={(e) => {
                                if (e.target.value) {
                                    filterTable(e.target.value);
                                } else {
                                    if (filterApplied && filterChip.length > 0) {
                                        setFilteredUsers(filteredUsersCopy)
                                    } else {
                                        setContactsData(contactsDataCopy);
                                    }
                                }
                            }}
                            InputLabelProps={{
                                shrink: false,
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                </Box>
            </div>
            <Box sx={theme.customStyle.tableHead}>
                <StyledDataGrid
                    loading={isLoading || filterLoading}
                    page={page}
                    onPageChange={(newPage) => setPage(newPage)}
                    rows={
                        filterApplied && filterChip.length > 0 ? filteredUsers : contactsData
                    }
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                    components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                    }}
                    onRowDoubleClick={(e) => {

                        navigate("/addUpdateContact/" + e.row.contactId, {
                            state: { isAddContact: false, contactDetails: e.row },
                        });
                    }}
                    // initialState={{
                    //     sorting: {
                    //         sortModel: [{ field: 'lastModifiedDate', sort: 'desc' }],
                    //     },
                    // }}
                />
            </Box>
            <div>
                {filterApplied && filterChip.length > 0
                    ? filteredUsers.length >= 100 && <MsgCmp />
                    : userArr.length >= 100 && <MsgCmp />}
            </div>
        </>
    );
};

export default ContactsPage;  