/***************
 * 01-09-2024 => SUDAM B CHAVAN => TEXTING-1943 Contact List UI
 * 01-12-2024 => SUDAM B CHAVAN =>TEXTING-1943 Contact List UI-New Observations
***********/
import React, { useState, useEffect, useContext } from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TemplatesComp from "../teams/TemplatesComp";

function AddTemplateToList(props) {
    const theme = useTheme();
    const [selectedTemplate, setSelectedTemplate] = useState({
        templateId: props.selectedTemplateId
    });
    return (

        <Dialog
            open={true}
            onClose={props.onCancel}
            fullWidth
            maxWidth={"lg"}
        >
            <DialogTitle>Templates</DialogTitle>
            <DialogContent>
                <Box sx={{ ...theme.customStyle.tableHead, height: 500 }}>
                    <TemplatesComp
                        onSelect={template =>setSelectedTemplate(template)}
                        teamId={props.teamId}
                         pageName="CommunicationList"
                        selectedTemplateId={props.selectedTemplateId}
                         />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={!selectedTemplate.templateId}
                onClick={() => {
                    props.onSelect(selectedTemplate)
                }}>Select</Button>
                <Button onClick={() => {
                    props.onCancel();
                }}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddTemplateToList;
