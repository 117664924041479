/***************
*11-11/2022 TextField:766=>disabled auto suggetion
* 01-09-2024 => SUDAM B CHAVAN => TEXTING-1943 Contact List UI
 * 01-10-2024 => SUDAM B CHAVAN => TEXTING-1943 Contact List UI-Upload file changes
  * 01-12-2024 => SUDAM B CHAVAN => TEXTING-1943 Contact List UI-observations
* 01-13-2024 => SUDAM B CHAVAN => TEXTING-1943 Contact List new status changes
* 01-17-2024 => SUDAM B CHAVAN => TEXTING-1943 new observations
*01-24-2024 => SUDAM B CHAVAN => TEXTING-1954 Contact list pending observations
*01-25-2024 => SUDAM B CHAVAN => TEXTING-1953 Communication List Cancel Functionality
***********/
import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  TextField,
  Button,
  Checkbox,
  CircularProgress,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import { useLocation, useNavigate } from "react-router-dom";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import apiConfig from "../../api/apiConfig";
import { AuthContext } from "../../AuthContext";
import moment from "moment";
import SnackBar from "../../components/SnackBar";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import CustomTabList from "../../components/CustomTabList";
import ContactList from "../../components/contactList";
import SearchIcon from '@mui/icons-material/Search';
import AddTemplateToList from "./AddTemplateToList";
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

function AddUpdateCommunications() {
  const location = useLocation();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = React.useState("1");
  const theme = useTheme();
  const { messageApiToken, userInfo } = useContext(AuthContext);
  const isAddCommunication = location.state
    ? location.state.isAddCommunication
    : true;
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [showTemplateSelection, setShowTemplateSelection] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [snackbarMsg, setSnackBarMsg] = useState("");
  const [contactListUploaded, setContactListUploaded] = useState(false);
  const [communicationData, setCommunicationData] = useState({
    communicationListId: "00000000-0000-0000-0000-000000000000",
    communicationListName: "",
    communicationListCode: "",
    teamId: "",
    templateId: "",
    templateName: "",
    isSchedule: false,
    scheduledDate:"",
    status:"",
    isActive: true,
  });
  const [teamList, setTeamList] = useState([]);
  const [isMobileView, setIsMobileView] = useState(window.screen.width < 600);
  const [modifiedBy, setModifiedBy] = useState({
    lastModifiedByName: "",
    lastModifiedDate: "",
  });
  const [createdBy, setCreatedBy] = useState({
    createdByName: "",
    createdDate: "",
  });
  const tabList = [
    { label: "Communication List Details", value: "1", displayOrder: 1 },
    { label: "Upload Contact List", value: "2", displayOrder: 2 }
  ]
  //status array to give modify communication list 
  const allowedStatusToModifyArr = ["New", "Upload Contacts", "Pending"]
  useEffect(() => {
    if (userInfo.teams) {
      setTeamList(userInfo.teams);
    }
    if (!isAddCommunication) {
      setLoading(true); 
      if (messageApiToken) {
        InvokePostServiceCall(apiConfig.GET_COMMUNICATION_LIST, {
          communicationListId:
            location.state.communicationDetails.communicationListId,
        })
          .then((data) => {
            let communicationDetails = data.data.communicationListDetails[0];
            setCommunicationData({
              communicationListId:
                communicationDetails.communicationList.communicationListId,
              communicationListName:
                communicationDetails.communicationList.communicationListName,
              communicationListCode:
                communicationDetails.communicationList.communicationListCode,
              teamId: communicationDetails.communicationList.teamId,
              templateId: communicationDetails.communicationList.templateId,
              isSchedule: communicationDetails.communicationList.isSchedule,
              scheduledDate: communicationDetails.communicationList.scheduledDate ? moment(communicationDetails.communicationList.scheduledDate)
              .local()
              .format("YYYY-MM-DD"):"" ,
              status: communicationDetails.communicationList.status,
              isActive: communicationDetails.communicationList.isActive,
              templateName: communicationDetails.communicationList.templateName,              
            });
            setIsReadOnly(!communicationDetails.communicationList.status || !allowedStatusToModifyArr.includes(communicationDetails.communicationList.status));
            setModifiedBy({
              lastModifiedByName:
                communicationDetails.communicationList.lastModifiedByName,
              lastModifiedDate: moment(
                communicationDetails.communicationList.lastModifiedDate
              )
                .local()
                .format("LLL"),
            });
            setCreatedBy({
              createdByName:
                communicationDetails.communicationList.createdByName,
              createdDate: moment(
                communicationDetails.communicationList.createdDate
              )
                .local()
                .format("LLL"),
            });
            setLoading(false);
          })
          .catch((error) => {
            setSnackBarMsg(error.message);
            setSeverity("error");
            setOpenSnackbar(true);
            setLoading(false);
            console.log(error, "Error Message");
          });
      }
    }
  }, [messageApiToken, contactListUploaded]);
  let resizeWindow = () => {
    setIsMobileView(window.screen.width < 600);
  };
  useEffect(() => { // added to show effect when screen gets resize
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);//remove event listener on component unmount
  }, []);
  const UpdateCommunicationListStatus = (status) => {
    setLoadingBtn(true);
    let requestBody = {
      communicationListId: communicationData.communicationListId,
      status: status
    }
    InvokePostServiceCall(apiConfig.UPDATE_COMMUNICATION_LIST_STATUS, requestBody)
      .then((data) => {
        setLoadingBtn(false);
        setIsReadOnly(true);
        setSnackBarMsg("Communication status Updated Successfully");
        setSeverity("success");
        setOpenSnackbar(true);
      })
      .catch((error) => {
        setLoadingBtn(false);
        setSnackBarMsg(error.message);
        setSeverity("error");
        setOpenSnackbar(true);
        console.log(error, "Error Message");
      });
  };
  const handleAddUpdate = () => {
    setLoadingBtn(true);
    let requestBody = {
      communicationList: {
        communicationListId: communicationData.communicationListId,
        communicationListName: communicationData.communicationListName,
        communicationListCode: communicationData.communicationListCode,
        isActive: communicationData.isActive,
          teamId: communicationData.teamId,
          templateId: communicationData.templateId,
          isSchedule: communicationData.isSchedule,
        scheduledDate: communicationData.scheduledDate,
        ...!isAddCommunication && {
          status: communicationData.status
        }
      },
      actionType: isAddCommunication ? "Add" : "Update",
    };
    InvokePostServiceCall(apiConfig.SAVE_COMMUNICATION_LIST, requestBody)
      .then((data) => {
        setLoadingBtn(false);
        setSnackBarMsg(
          isAddCommunication
            ? "Communication Added Successfully"
            : "Communication Updated Successfully"
        );
        setSeverity("success");
        setOpenSnackbar(true);
      })
      .catch((error) => {
        setLoadingBtn(false);
        setSnackBarMsg(error.message);
        setSeverity("error");
        setOpenSnackbar(true);
        console.log(error, "Error Message");
      });
  };

  const handleChange = (event) => {
    setCommunicationData({
      ...communicationData,
      isActive: event.target.checked,
    });
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    if (severity === "success") {
      navigate("/communications", { replace: true });
    }
    setOpenSnackbar(false);
  };

  return (
    <>
      <SnackBar
        openSnackbar={openSnackbar}
        handleCloseSnackbar={handleCloseSnackbar}
        severity={severity}
        userMessage={snackbarMsg}
      />
      <div style={{ marginLeft: 20, marginRight: 20 }}>
        {loading && !isAddCommunication ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15%",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
              <Box sx={theme.customStyle.tableRow}>
                <h2>
                  {isAddCommunication
                    ? "Add Communication"
                    : "Update Communication"}
                </h2>
                {!isAddCommunication  && <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    disabled={communicationData.status !== "Pending"}
                    onClick={() => UpdateCommunicationListStatus("Ready")}
                  >
                    {!isMobileView && "Start"}
                    <PlayCircleOutlineRoundedIcon fontSize="large" />
                  </Button>
                  <Button
                    disabled={['Ready','In Progress'].indexOf(communicationData.status) < 0} //disabled when not in givin status
                    onClick={() => UpdateCommunicationListStatus("Cancel")}
                  >
                    {!isMobileView && "Cancel"}
                    <HighlightOffIcon fontSize="large" />
                  </Button>
                </Box>}
              </Box>

              <TabContext value={tabValue}>
                <CustomTabList tabList={tabList} showTabsCount={3} handleChangeTab={(event, selectedTabValue) => {
                  setTabValue(selectedTabValue)
                }}></CustomTabList>
                <TabPanel value="1" sx={{ padding: "0px" }}>
            <Box sx={theme.customStyle.textRow}>
              <TextField
                disabled={isReadOnly}
                size="small"
                margin="dense"
                label="Communication Name"
                type="text"
                required
                fullWidth
                value={communicationData.communicationListName}
                id="updateCommunicationcommunicationListName"
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 255, autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                onChange={(e) => {
                  setCommunicationData({
                    ...communicationData,
                    communicationListName: e.target.value,
                  });
                }}
              />
            </Box>
            <Box sx={theme.customStyle.textRow}>
              <TextField
               disabled={isReadOnly}
                size="small"
                margin="dense"
                label="Communication Code"
                type="text"
                fullWidth
                required
                value={communicationData.communicationListCode}
                variant="standard"
                id="updateCommunicationcommunicationListCode"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 255, autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                onChange={(e) => {
                  setCommunicationData({
                    ...communicationData,
                    communicationListCode: e.target.value,
                  });
                }}
              />
            </Box>
                  <Box sx={theme.customStyle.textRow}>
                  <FormControl variant="standard" fullWidth margin="dense">
                    <InputLabel shrink={true} required>
                      Team
                    </InputLabel>
                    <Select
                     disabled={isReadOnly}
                    labelId="demo-simple-select-standard-label"
                        value={communicationData.teamId === "" ? "00000000-0000-0000-0000-000000000000" : communicationData.teamId}
                    onChange={(e) => {
                      setCommunicationData({
                        ...communicationData,
                        teamId: e.target.value,
                      });
                    }}
                    label="Team"

                  >
                        <MenuItem value="00000000-0000-0000-0000-000000000000" selected="true" >None</MenuItem>
                    {teamList && teamList.map((option, index) => (
                      <MenuItem key={option.teamId} value={option.teamId}>
                        {option.teamName}
                      </MenuItem>
                    ))}
                  </Select>
                  </FormControl>
              </Box>
                  <Box sx={theme.customStyle.textRow}>
                        <TextField
                          disabled
                          required
                          size="small"
                          margin="dense"
                          fullWidth
                          variant="standard"
                          value={communicationData.templateName}
                          InputLabelProps={{
                            shrink: true,
                            readOnly: true,
                          }}
                          onFocus={event => {
                            event.target.setAttribute('autocomplete', 'new-password');
                          }}
                          autoComplete="off"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  disabled={isReadOnly || !communicationData.teamId || communicationData.teamId === "00000000-0000-0000-0000-000000000000"}
                                  onClick={() => { setShowTemplateSelection(true) }}
                                  edge="end"
                                >
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          label="Template"
                        />
                  </Box>
                  {!isAddCommunication &&
                    <Box sx={theme.customStyle.textRow}>
                      <span>Status : {communicationData.status}</span>
                    </Box>}
                  <Box>
                    <span>Is Schedule</span>
                    <Checkbox
                     disabled={isReadOnly}
                      checked={communicationData.isSchedule}
                      onChange={(e) => {
                        setCommunicationData({
                          ...communicationData,
                          isSchedule: e.target.checked,
                        });
                      }}
                    />
                  </Box>
                  {communicationData.isSchedule && 
                    <Box sx={theme.customStyle.textRow}>
                    <TextField
                     disabled={isReadOnly}
                      variant="standard"
                      fullWidth
                      type={"date"}
                      id="scheduledDate"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onFocus={event => {
                        event.target.setAttribute('autocomplete', 'new-password');
                      }}
                      value={communicationData.scheduledDate}
                        label="schedule Date"
                      onChange={(e) => {
                        setCommunicationData({
                          ...communicationData,
                          scheduledDate: e.target.value,
                        });
                      }}
                    />
                  </Box>}
            <Box>
              <span>Is Active</span>
              <Checkbox
                disabled={isReadOnly}
                checked={communicationData.isActive}
                onChange={handleChange}
              />
            </Box>
            {!isAddCommunication && (
              <>
                <Box sx={theme.customStyle.textRow}>
                  <TextField
                    size="small"
                    margin="dense"
                    disabled
                    value={`${createdBy.createdByName +
                      " (" +
                      createdBy.createdDate +
                      ")"
                      }`}
                    label="Created By"
                    type="text"
                    fullWidth
                    variant="standard"
                    id="updateCommunicationcreatedByName"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ //not show suggetions

                      autoComplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                </Box>
                <Box sx={theme.customStyle.textRow}>
                  <TextField
                    size="small"
                    margin="dense"
                    disabled
                    value={`${modifiedBy.lastModifiedByName +
                      " (" +
                      modifiedBy.lastModifiedDate +
                      ")"
                      }`}
                    label="Modified By"
                    type="text"
                    fullWidth
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="updateCommunicationlastModifiedByName"
                    inputProps={{ //not show suggetions

                      autoComplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                </Box>
              </>
            )}
            <div style={{ marginTop: "20px", marginBottom: "20px" }}>
              <LoadingButton
                disabled={ !isReadOnly &&
                  communicationData.communicationListCode &&
                    communicationData.communicationListName &&
                    communicationData.teamId &&
                      communicationData.templateId &&
                        communicationData.teamId !== "00000000-0000-0000-0000-000000000000"
                    ? false
                    : true
                }
                loading={loadingBtn}
                variant="contained"
                onClick={() => {
                  handleAddUpdate();
                }}
              >
                {isAddCommunication ? "Add" : "Update"}
              </LoadingButton>
              <Button
                sx={{ marginLeft: 2 }}
                variant="contained"
                onClick={() => {
                  navigate("/communications");
                }}
              >
                Cancel
              </Button>
            </div>
                </TabPanel>
                <TabPanel value="2" sx={{ padding: "0px" }}>
                  {!isAddCommunication ? 
                    <ContactList
                    onContactListUpload={() => setContactListUploaded(true)}
                    status={communicationData.status}
                    isMobileView={isMobileView}
                    isReadOnly={isReadOnly}
                    communicationListId={communicationData.communicationListId} />
                    : <h5 style={{
                    color: "red",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center"
                  }}>Add Communication list first </h5>}

                </TabPanel>
              </TabContext>
          </>
        )}

        {showTemplateSelection && <AddTemplateToList
          teamId={communicationData.teamId}
          onCancel={() => setShowTemplateSelection(false)}
          onSelect={(template) => {
            setCommunicationData({
              ...communicationData,
              templateId: template.templateId,
              templateName: template.templateName,
            });
            setShowTemplateSelection(false);
          }}
          selectedTemplateId={communicationData.templateId}
        />}
      </div>
    </>
  );
}

export default AddUpdateCommunications;
