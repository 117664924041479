////11-02-2022 => Texting-731 Auto update email changes.
////11-03-2022 => Doucusign error handling.
////11-03-2022 => Texting-698,Texting-716 dropzone dialog ui issue fixed. mui-file-dropzone package added.
////11-03-2022 => Doucusign error handling.
////11-10-2022 => Removed autosuggetion
////11-10-2022 => Rajesh - Fixed issue with Teamid passed to docusign
/***************
*10-11/2022 TextField:766=>disabled auto suggetion
////29-11-2022 => YOGESH=>Texting-866=> Disabled auto suggection from all textfields
////21-12-2022 => Mohit=>set tokenCode to empty string in createEnvalep RequstBody

////07-11-2022 => ONKAR =>TEXTING-1350 Client env - DocuSign observations.
// 08-30-2023 => ONKAR => TEXTING-1578 -REACT Upgrade 
// 12-18-2023 => ONKAR => TEXTING-1920 -Attachments icon size in docu sign upload page should be adjusted. 
 ***********/
import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
  DialogTitle,
  Divider,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { InvokePostServiceCall } from "../api/serviceUtil";
import apiConfig from "../api/apiConfig";
import { v4 as uuidv4 } from "uuid";
import { AuthContext } from "../AuthContext";
import { DropzoneDialog } from 'mui-file-dropzone';
import styles from "../docusignCss.module.css"


function DocuSign(props) {
  const theme = useTheme();
  const { userInfo, selectedConv, crmMode, docuSignCode } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [openDocusignDialog, setOpenDocusignDialog] = useState(false);
  const [senderViewUrl, setSenderViewUrl] = useState("");
  const [open, setOpen] = React.useState(false);
  const [docName, setDocName] = useState("");
  let regEmail = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i;
  const [docusignDetails, setDocusignDetails] = useState({
    name: props.context === "dialog" ? props.state.conversation.customerName : selectedConv.customerName,
    email: props.context === "dialog" ? props.state.conversation.email : selectedConv.email,
    document: "",
  });


  useEffect(() => {
    setDocusignDetails({
      name: props.context === "dialog" ? props.state.conversation.customerName : selectedConv.customerName,
      email: props.context === "dialog" ? props.state.conversation.email : selectedConv.email,
      document: "",
    });
  }, [selectedConv, props.state.conversation]);
  const [mailError, setMailError] = useState(false);

  const createEnvelope = () => {
    setLoading(true);
    var convId = props.context === "dialog" ? props.state.conversation.conversationId : selectedConv.conversationId;
    var teamId = props.context === "dialog" ? props.state.conversation.teamId : selectedConv.teamId;
    var custNumber = props.context === "dialog" ? props.state.conversation.customerPhoneNumber : selectedConv.customerPhoneNumber;
    let requestBody = {
      // tokenCode: userInfo.generateDocuSignToken == true ? docuSignCode ? docuSignCode : "" : "",
      tokenCode:"",
      returnUrl: process.env.REACT_APP_REDIRECT_URI + "/?name=" + docusignDetails.name + "&userId=" + userInfo.userId + "&email=" + docusignDetails.email + "&teamId=" + teamId + "&convId=" + convId,
      userId: userInfo.userId,
      teamId: teamId,
      documents: [
        {
          documentId: custNumber.substring(custNumber.length - 5),
          fileExtension: "pdf",
          documentBase64: docusignDetails.document,
          name: "Application Consent",
        },
      ],

      status: "created",

      emailSubject: "Hello " + docusignDetails.name + ", Please sign the document",
      recipients: {
        signers: [
          {
            email: docusignDetails.email,
            name: docusignDetails.name,
            recipientId: custNumber.substring(custNumber.length - 9),
          },
        ],
      },
    };

    InvokePostServiceCall(apiConfig.CREATE_ENVELOPE, requestBody)
      .then((data) => {
        setDocusignDetails({
          name: props.context === "dialog" ? props.state.conversation.customerName : selectedConv.customerName,
          email: props.context === "dialog" ? props.state.conversation.email : selectedConv.email,
          document: ""
        });
        setDocName("");
        localStorage.setItem("codeVal", "EXPIRED");
        //  setDocuSignCodeVal("EXPIRED");
        console.log(data, "createEnvelope data");
        props.handleDocuSignModalClose()
        if (
          data != null &&
          data.data != null &&
          data.data.senderViewUrl != null
        ) {
          // window.open(data.data.senderViewUrl, "_self");
          if (crmMode === "true") {
            window.open(data.data.senderViewUrl, "Ratting", "width=1100 height=900 left=150,top=200");

          }
          else {
            setSenderViewUrl(data.data.senderViewUrl);
          }
        }
        else if (data.data.errorCode != "") {
          props.openMainSnackbar(data.data.message);
        }
        setLoading(false);
        // createRecipientView();
      })
      .catch((err) => {
        setLoading(false);
        props.openMainSnackbar(err);
        console.log(err, "Error Message");
      });
  };
  useEffect(() => {
    if (localStorage.getItem("envelopeId") !== "") {
      //  createRecipientView();
    }
  }, []);


  async function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      let fileData = reader.result.split(",");
      setDocusignDetails({ ...docusignDetails, document: fileData[1] });
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  const handleCloseDocusignDialog = () => {
    setOpenDocusignDialog(false);
  };

  useEffect(() => {
    if (senderViewUrl !== "") {
      setOpenDocusignDialog(true)

    }
  }, [senderViewUrl])
  return (
    <>
      <Dialog
        open={props.state.showDocuSign}
        onClose={props.handleDocuSignModalClose}
        fullWidth={props.dialogFullWidth}
      >
        <DialogTitle>DocuSign</DialogTitle>
        <Divider />
        <DialogContent>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "5%",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <Box sx={theme.customStyle.textRow}>
                <TextField
                  label="Name"
                  required
                  value={docusignDetails.name}
                  variant="standard"
                  type={"text"}
                  onFocus={event => {
                    event.target.setAttribute('autocomplete', 'new-password');
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ width: "100%" }}
                  onChange={(e) => {
                    setDocusignDetails({
                      ...docusignDetails,
                      name: e.target.value,
                    });
                  }}
                />
              </Box>
              <Box sx={theme.customStyle.textRow}>
                <TextField
                  label="Email"
                  required
                  error={mailError}
                  helperText={mailError ? "Please Enter Correct Email." : ""}
                  variant="standard"
                  value={docusignDetails.email}
                  type={"email"}
                  onFocus={event => {
                    event.target.setAttribute('autocomplete', 'new-password');
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ width: "100%" }}
                  onChange={(e) => {
                    setDocusignDetails({
                      ...docusignDetails,
                      email: e.target.value,
                    });
                    setMailError(false);
                  }}
                />
              </Box>
              <Box sx={theme.customStyle.textRow}>
                <div>

                  <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                    Add File
                  </Button>
                  <span style={{ fontWeight: "600", fontSize: "13px", marginLeft: "10px" }}>{docName !== "" ? docName : "No File Chosen"}</span>
                  <DropzoneDialog
                    dialogTitle={"Maximum limit 25MB"}
                    open={open}
                    onSave={(files) => {
                      getBase64(files[0]);
                      setOpen(false);
                      setDocName(files[0].name)
                    }}
                    acceptedFiles={['application/pdf']}
                    showPreviews={true}
                    maxFileSize={26214400}
                    filesLimit={1}
                    onClose={() => setOpen(false)}
                    previewGridClasses={{
                      // added styles to reduce the preview size.
                      item:  styles.docusignImg,
                      image: styles.docusignImg,
                    }}

                  />
                </div>


              </Box>



              {/* uncomment below code to see different style for file input  */}
              {/* <Button  component="label" sx={{width:"100%",border:"1px dashed #1976d2",marginTop:"10px"}}>
                       Choose File
                       <input 
                       onChange={(e)=>{
                         getBase64(e.target.files[0]);
                         setDocName(e.target.files[0].name)
                       }}
                        hidden  type="file" />
                     </Button>
                     <Box sx={{textAlign:"center",marginTop:"5px"}}>
                       <span style={{fontWeight:"600",fontSize:"13px"}}>{docName!==""?docName:"No File Chosen"}</span>
                     </Box> */}

            </Box>
          )}
        </DialogContent>

        <DialogActions>
          {!loading && (
            <>
              <Button
                onClick={() => {
                  if (regEmail.test(docusignDetails.email)) {
                    createEnvelope();
                  } else {
                    setMailError(true);
                  }

                }}
                disabled={docusignDetails.email && docusignDetails.name && docusignDetails.document ? false : true}
              >
                Submit
              </Button>
              <Button
                onClick={() => {
                  setDocusignDetails({ ...docusignDetails, document: "" });
                  props.handleDocuSignModalClose();
                }}
              >
                Cancel
              </Button>
            </>)}
        </DialogActions>

      </Dialog>


      <Dialog
        open={openDocusignDialog}
        onClose={handleCloseDocusignDialog}
        fullWidth
        maxWidth="xl"
      >
        <DialogContent sx={{ height: "80vh" }}>
          {/* <div style={{height:"60vh",width:"100%",border:"1px solid black"}} dangerouslySetInnerHTML={{__html:"<iframe title='docusign' width='100%' height='100%' src='https://www.google.com/webhp?igu=1&gws_rd=ssl'"}}/> */}
          <iframe style={{ width: "100%", height: "75vh" }} title="docusign" src={senderViewUrl} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { handleCloseDocusignDialog() }}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DocuSign;
