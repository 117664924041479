//*08-26-2023 => ONKAR => TEXTING-1578 -REACT Upgrade
// 08-29-2023 => ONKAR => TEXTING-1578 -REACT Upgrade
// 11-30-2023 => SUDAM B CHAVAN => TEXTING-1873 -Chat Survey Customer Portal with form for Survey Response

import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import App from './App';
import unregister from './registerServiceWorker';
import { PublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig, loginRequest } from "./azure/authConfig";
import reportWebVitals from './video/reportWebVitals';
import DocuSignCustomerApp from './pages/docuSign/DocuSignCustomerApp';
import DocuSignAgentApp from './pages/docuSign/DocuSignAgentApp';
import UploadDocCustomerApp from './pages/uploadDocuments/UploadDocCustomerApp';
import './globalStyle.css';
import WebChatCustomerApp from './pages/webChat/webChatCustomerApp';
import ReactDOM from 'react-dom/client';
import SurveyCustomerApp from './pages/surveyCustomerPortal/surveyCustomerApp';

var openDocuSignCustomerApp = false;
var openDocuSignAgentApp = false;
var openWebChatApp = false;
const msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(document.getElementById('root'));
openDocuSignCustomerApp = window.location.search.substring(1).includes("documentid");
openWebChatApp = window.location.search.substring(1).includes("webchat");
// when the url is having envelope or Code it should be navigated to docusign app
var openUploadDocCustomerApp = window.location.pathname === "/document";
var openSurveyCustomerApp = window.location.pathname === "/surveyportal";
openDocuSignAgentApp = window.location.search.substring(1).includes("envelopeId") || window.location.search.substring(1).includes("code");

const acquireAccessToken = async (msalInstance) => {
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();

    //No User in context - user logging in for the first time
    if (!activeAccount && accounts.length === 0) {
        //Silent login to SSO
        const loginResponse = msalInstance.ssoSilent(loginRequest).catch(err1 => {
            //If silent login fails show login popup 
            msalInstance.loginPopup(loginRequest).catch(e => {
                console.log(e);
            });
        });
    }
    //User logged in and only one user exists in context
    else if (accounts.length === 1) {
        //Acquire user token if active token doesn't exist login to SSO silently
        await msalInstance.acquireTokenSilent({ ...loginRequest, account: accounts[0]}).then((response) => {
            console.log("User token exists");
        }).catch(ex => {
            //Silent login to SSO
            const loginResponse = msalInstance.ssoSilent(loginRequest).catch(err1 => {
                //If silent login fails show login popup
                msalInstance.loginPopup(loginRequest).catch(e => {
                    console.log(e);
                });
            });
        });
    }
    //User logged in and more than one user account exists in context
    else {
        //Show login popup
        msalInstance.loginPopup(loginRequest).catch(e => {
            console.log(e);
        });
    }
};

if (window.location.hash !== '') {
    console.log("hash found" + window.location.hash);
}
else {
    if (openDocuSignCustomerApp == true) {

        root.render(
            <DocuSignCustomerApp />);

    }
    else if (openUploadDocCustomerApp) {
        root.render(
            <UploadDocCustomerApp />);
    }
    else if (openSurveyCustomerApp) {
        root.render(
            <SurveyCustomerApp />);
    }
    else if (openDocuSignAgentApp == true) {
        try {
            const loginResponse = msalInstance.ssoSilent(loginRequest).catch(err1 => {
                /*if (err1 instanceof InteractionRequiredAuthError) {
                    debugger;
                    */
                msalInstance.loginPopup(loginRequest).catch(e => {
                    console.log(e);
                });/*
        } else {
            debugger;
            console.log(err1);
            // handle error
        }*/
            });
        } catch (err) {
            console.log(err);
        }
        root.render(
            <MsalProvider instance={msalInstance} >
                <DocuSignAgentApp />
            </MsalProvider >);
    }
    else if (openWebChatApp == true) {
        root.render(
            <WebChatCustomerApp />);
    }
    else {
        try {
            acquireAccessToken(msalInstance);
        } catch (err) {
            console.log(err);
        }


        root.render(
            /*<BrowserRouter basename={baseUrl}>*/

            <MsalProvider instance={msalInstance}>
                <App />
            </MsalProvider>,
        /*</BrowserRouter>,*/);
    }
}

unregister();
reportWebVitals();

