/*
*08-31-2023 => PRABHAT =>TEXTING-1643 - Error page with placeholder to show error for any unhandled exception
*/
import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "./AuthContext";
import { useLocation, useNavigate } from "react-router-dom";


const UnAuthorized = () => {
    const location = useLocation();
    const message = location.state ? location.state.message : "";
    return (
        <>
            <div style={{ marginTop: "10px", textAlign: "center" }}>
                <h5 >
                    {message}
                </h5>
            </div>
        </>
    );
};

export default UnAuthorized;