/********************************************************************************************************************************************
Name: DrawerConvListNew
=============================================================================================================================================
Purpose:
Render drawer with New, Active, Missed & InActive Conversations menu.
History:
VERSION     AUTHOR              DATE            DETAILS
1.0         ONKAR               10/20/2022      Initial creation of header component
1.1         ONKAR               11/01/2022      Added time stamp for active conversation list
1.2         ONKAR               11/11/2022      Disabled auto suggestion and fix new and time overlap issue
1.3         SRINATH             11/13/2022      Fix blank screen issue for new outbound conversation
1.4         ONKAR               11/14/2022      Texting-750 New conversation page was not closing after auto rejecting the conversation request -fixed
1.5         ONKAR               11/21/2022      Texting-844 Auto reject timing update to 120 Secs
1.6         Yogesh              11/28/2022      Texting-850 loading added 
1.7         ONKAR               12/27/2022      Texting-969 focus conversation filter textfield. 
1.8         ONKAR               05/02/2023      Texting-1230 Red DOT treatment: Remove red dot for conversations if messages are read.
1.8         ONKAR               05/05/2023      Texting-1230 Red DOT treatment: Remove red dot for conversations if messages are read.
1.8         ONKAR               05/05/2023      Texting-1240 Message Authentication Status Visual Indicator
1.9         ONKAR               05/09/2023      Texting-1240 Message Authentication Status Visual Indicator alignment issue fixed.
1.10        ONKAR               05/10/2023      Texting-1224 Email should be shown with name on new conversation request page.
1.10        ONKAR               05/11/2023      Texting-1224 Long email going outside the drawer issue fixed.
1.11        ONKAR               05/12/2023      Texting-1263 - Click on missed icon in the left panel from the active conversation page is making the active conversation page blank.
1.11        ONKAR               05/12/2023      Texting-1266 -  When refresh/Reload the Agent UI, Red dot is coming back on conversation, hamburger icon.
1.12        ONKAR               05/19/2023      Texting-1276 -  When accepting the new conversation request, Conversation is getting assigned to agents preferred team.
1.13        ONKAR               05/19/2023      Texting-1172 -  4:Border Line Auto assign conversation observations.-issues fixed.
1.14        SUDAM B CHAVAN      06/07/2023      TEXTING-1317 - 'New' icon label is showing on ended conversation after close/Auto close the conversation.
1.15        ONKAR               06/15/2023      Texting-1347 Red is not disappearing after viewing the customer’s message on the conversation initiated from team that is not opted for start conv with templates and on conv from individual Team.
1.16        ONKAR               06/16/2023      Texting-1347 updated active conv count feature on drawer same as red dot.
1.16        ONKAR               06/16/2023      Texting-1359 If Nppi message is sent by customer, along with system user message 'New' badge on conversation is showing.
1.17        ONKAR               06/21/2023      Texting-1359 If Nppi message is sent by customer, along with system user message 'New' badge on conversation is showing.(reopened issue fixed.)
1.18        RAJESH              07/23/2023      Texting-1359 If Nppi message is sent by customer, along with system user message 'New' badge on conversation is showing.(reopened issue fixed.)
1.19        ONKAR               07/24/2023      TEXTING-1475 - Critical: Sentiment Optimization.
1.19        ONKAR               07/25/2023      TEXTING-1475 - Critical: Sentiment Optimization.
1.20        ONKAR               08/25/2023      Texting-1570 When reload Agent UI, Unread message count on left panel is showing for agent if agent message is unread by member.
1.21        SUDAM B CHAVAN      09/04/2023      TEXTING-1650 - Web Chat: Auto routing observation.
1.22        ONKAR               09/07/2023      TEXTING-1678 - Exists in Prod: On reload frame red dot for unread messages in conversation and hamburger icon is disappearing.
1.23        ONKAR               09/22/2023      TEXTING-1740 - 0: Conversation read observations.
1.24        ONKAR               09/25/2023      TEXTING-1745 - Conversation Reassignment observations.
1.25        ONKAR               10/12/2023      TEXTING-1796 - Sentiment Re-Calculate button to calculate sentiment with latest messages.
1.26        ONKAR               10/20/2023      TEXTING-1705 - Exists in prod - After page refresh New badge is showing along with unread count, dot on conversation.
1.27        ONKAR               11/08/2023      TEXTING-1839 - Adding the conversation I'd in URL should open the conversation page.
1.28        ONKAR               11/09/2023      TEXTING-1763 - Existing Prod Issue: On refresh New conversation request panel is going away.
1.29        ONKAR               11/10/2023      Texting-1857 Click on 'end' button is making the Texting screen blank,Texting-1839 Adding the conversation I'd in URL should open the conversation page.
1.30        ONKAR               11/13/2023      TEXTING-1839 - Adding the conversation I'd in URL should open the conversation page.
1.30        ONKAR               11/17/2023      TEXTING-1839 - Adding the conversation I'd in URL should open the conversation page.
1.31        ONKAR               12/04/2023      TEXTING-1860 -  Only conversation ended info message is showing on agent UI when webchat conversation is ended.
1.32        ONKAR               12/07/2023      TEXTING-1902 -  0: Texting, Admin UI page is becoming blank.
1.33        ONKAR               12/11/2023      TEXTING-1883 -  Click on reconnect is auto highlighting the active icon in left panel.
1.34        ONKAR               01/14/2023      TEXTING - 1936 - Request Assistance : UI
1.35        ONKAR               01/17/2023      TEXTING - 1936 - Request Assistance : UI
1.36        ONKAR               01/23/2023      TEXTING - 1936 - Request Assistance : UI
                                                3.Supervisor Text size alignment should be adjusted in left panel. 
                                                8.Enable/Disable Request assistance configuration should be added in Application settings.  
1.37        ONKAR               01/24/2023      TEXTING - 1959 - Enterprise Texting Enhancements for CIF 2.0 
1.37        ONKAR               01/25/2023      TEXTING - 1959 - Enterprise Texting Enhancements for CIF 2.0 
1.38        ONKAR               02/02/2023      TEXTING - 2012 - CIF 2.0 observations
1.39        ONKAR               02/04/2023      TEXTING - 2012 - CIF 2.0 observations
1.40        ONKAR               02/05/2023      TEXTING - 2012 CIF 2.0 observations.(bug fixes and code refactor)
1.41        SUDAM B CHAVAN      02/08/2023      Show missed conv bell changes to agent
1.42        Ramakant            03/20/2023      Code Refactor
********************************************************************************************************************************************/


import React, { useContext, useEffect, useState, useRef } from "react";
import SmsTwoToneIcon from "@mui/icons-material/SmsTwoTone";
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ChatIcon from '@mui/icons-material/Chat';
import TaskAltTwoToneIcon from '@mui/icons-material/TaskAltTwoTone';
import {
    Avatar,
    Badge,
    Box,
    CircularProgress,
    Divider,
    Drawer,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    TextField,
    Tooltip,
    Typography,
    Button,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled, useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import apiConfig from "../api/apiConfig";
import {
    getActiveConversationsAPI,
    getMessagesForConversationId,
    getSupervisorConversationsAPI,
    getUnassignedConversationAPI,
    updateSentimentInStore
} from "../api/messageServices";
import { AuthContext } from "../AuthContext";
import { dataService } from "../dataService";
import {    
    createNewSessionForActiveConversations,
} from "../extensions/cifUtil";
import {
    addConversationToList,
    conversationSelected,
    conversationsSelector,
    removeConversation,
    loadConversationMessages, upsertConversationtoList,updateConversationRead,
    stopNewConvTimer,
} from "../storage/slices/messageSlice";
import { SendToAllUserConnections } from "../azure/pubSub";
import { mapConversation, openCifWindow, sendEventToWebResource } from "../extensions/Util";
import { InvokePostServiceCall } from "../api/serviceUtil";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";
import InactiveConversationsNew from "./InactiveConversationsNew";
import UnassignedConversationsNew from "./UnassignedConversationsNew";
import { ConversationListComp } from "./ConversationListComp";
import NewConversationsNew from "../pages/conversations/NewConversationsNew";
import moment from 'moment';
import { createOrSearchTaskinCRM, refreshTokensData } from "../extensions/crmWrapper";

const StyledList = styled(List)({
    // selected and (selected + hover) states
    "&& .MuiListItem-button": {
        backgroundColor: "#D3D3D3",
        borderLeft: "4px solid #D3D3D3",
        "&, & .MuiListItemIcon-root": {
            color: "black",
        },
    },
    "&& .MuiListItem-button:hover": {
        backgroundColor: "#fff",
        borderLeft: "4px solid #fff",
        "&, & .MuiListItemIcon-root": {
            color: "black",
        },
    },
    "&& .Mui-selected, && .Mui-selected:hover": {
        backgroundColor: "#D3D3D3",
        borderLeft: "4px solid rgb(0, 102, 255)",
        "&, & .MuiListItemIcon-root": {
            color: "black",
        },
    },
    "& .MuiListItemButton-root": {
        // backgroundColor: "#D3D3D3",
        borderLeft: "4px solid #D3D3D3",
        "&, & .MuiListItemIcon-root": {
            color: "black",
        },
    },
    // hover states
    "& .MuiListItemButton-root:hover": {
        // backgroundColor: "#D3D3D3",
        "&, & .MuiListItemIcon-root": {
            color: "black",
        },
    },
    "& div .conversationitem": {
        backgroundColor: "#fff !important",
        borderLeft: "4px solid #fff !important",
        "&, & .MuiListItemIcon-root": {
            color: "black",
        },
    },
});
function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress size={34} variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">
                    {`${Math.round(props.newConvTimer)}`}
                </Typography>
            </Box>
        </Box>
    );
}

function DrawerConvListNew(props) {
    const navigate = useNavigate();
    const {
        conversations,
        isCompLoading,
        newConversations,
        unassignedConversations,
        supervisorConversations,
        crmModeV2
    } = useSelector(conversationsSelector);
    const theme = useTheme();
    const location=useLocation();
    const dispatch = useDispatch();
    const inputFilterRef=useRef(null);
    const [showCircularProgress, setShowCircularProgress] = useState(true);
    const [multiTabEndConversation, setMultiTabEndConversation] =
        React.useState();
    const [createConversation, setCreateConversation] = React.useState();
    const [convState, setConvState] = React.useState(false);
    const [showActive, setShowActive] = useState(false);
    const appContext = useContext(AuthContext);
    const [conversationFilter, setConversationFilter] = useState(false);
    const [convFilterValue, setConvFilterValue] = useState("");
    const [activeConvCount, setActiveConvCount] = useState(0);
    const [saveAction, setSaveAction] = useState("");
    const [openConvWithId, setOpenConvWithId] = useState(false);
    const [activeConvLoaded, setActiveConvLoaded] = useState(false);

    const {
        setSearchConvOpen,
        userInfo,
        setSelectedConvId,
        selectedConv,
        setSelectedConv,
        newConvDetails,
        setNewConvDetails,
        headerNotification,
        notiFicationFilter,
        setHeaderNotification,
        setShowNotificationIndicator,
        statusChanged,
        setStatusChanged,
        setDecision,
        inactiveConversations,
        setInactiveConversations,
        setCollapseInactive,
        collapseInactive,
        openDrawer,
        setOpenDrawer,
        activityDueDataHours,
        crmEmbededView,
        assignedConvId,
        setAssignedConvId,
        crmMode,
        openActive,
        setOpenActive,
        setConvLoading,
        openConversationDrawer,
        setOpenConversationDrawer,
        setSelectedMenu,
        hideNewConvMenu,
        convLoading,
        inactiveConvCount,
        enableSentimentCalculation,
        applicationSettings,
    } = useContext(AuthContext);
    const EnableRequestAssistance=applicationSettings?.EnableRequestAssistance==="Y"?true:false;
    const User = "User";
    const Customer = "Customer";
    const drawerWidth = "49px";
    
    //load only firest time to register event listener
    useEffect(() => {
        window.addEventListener("message", receivedMessageFromWebResource);
        return () => {
            window.removeEventListener('message', receivedMessageFromWebResource);
        };
    }, [])
    const receivedMessageFromWebResource = (event) => {
        const {eventName, data} = event.data;
        if (eventName) {
            console.log("webresource:receivedMessageFromWebResource - event :", event.data);
        }
        switch (eventName) {
            case "createdNewSessionForConversation":
                debugger
                console.log("createdNewSessionForConversation params-", event.data.data);
                dispatch(upsertConversationtoList(data)) //passing conversation 
                updateConversationAttributes(data.conversationId, data.conversationAttributes)
                break;
         
            default:
                break;
        }
    }
    //UseEffect - OnLoad
    useEffect(() => {
        if (isCompLoading === false) {
            setShowCircularProgress(false);
        }
    }, [isCompLoading]);

    async function handleAcceptRejectConv(decision, conversationId, convTeamId) {
        setSaveAction(decision);
        let requestBody = {
            userId: userInfo.userId,
            teamId: convTeamId,
            decision: decision, // "Accepted/Rejected",
            conversationId: conversationId,
        };
    
        try {
            dispatch(stopNewConvTimer(conversationId));
    
            const res = await InvokePostServiceCall(apiConfig.ACCEPT_REJECT_CONVERSATION, requestBody);
            console.log(res);
    
            if (decision === "Accepted") {
                setConvLoading(true);
            } else if (decision === "Rejected") {
                setStatusChanged(!statusChanged);
                setDecision("Rejected");
            }
    
            let removeNewConversationFromList = {
                messageType: "RemoveNewConversationFromList",
                conversation: {
                    conversationId: conversationId,
                }
            };
    
            SendToAllUserConnections(
                userInfo.userId,
                JSON.stringify(removeNewConversationFromList)
            );
    
            sessionStorage.removeItem("newConversations");
            setOpenConversationDrawer(false);
            setSaveAction("");
        } catch (err) {
            console.log(err, "Error Message");
            setSaveAction("");
        }
    }
    

    useEffect(() => {
        //Login to set Hanburger Indication
        if (conversations.length === 0) {
            setShowNotificationIndicator(false); //If No conversations  remove the indicator on hamburger icon
        }
        if(conversations.length>0){
            let showBadge=false;
            for(let i=0;i<conversations.length;i++){
                let msgList = [...conversations[i]?.messageList].reverse();
                let enableConversationReadOnSeen= userInfo?.teams?.filter(t => t.teamId === conversations[i].teamId)[0]?.enableConversationReadOnSeen;
                if(enableConversationReadOnSeen){
                    if(conversations[i].showNotification===true && (conversations[i]?.messageList[conversations[i]?.messageList.length - 1]
                        ?.fromObjectType === Customer || msgList[0]?.messages[msgList[0]?.messages.length-1].messageType === "SYSTEMMSG"|| conversations[i].lastMsgReceivedFrom==="Customer")){
                            showBadge=true
                        break;
                    }
                }else{
                      //Identify when the messages are loaded to show or hide the indicator on hamburger
                if (msgList.length > 0) {
                    let msg = msgList.find((x) => x.fromObjectType === User);
                    if ((!!msg && msg.fromId !== appContext.userInfo.userId) || !msg) {
                        showBadge=true;
                        break
    
                    } else {
                        showBadge=false;
                    }
    
                    // Getting the Last message from the conversation and checking the last message is from user or customer
                    if (
                        conversations[i].messageList[conversations[i].messageList.length - 1]
                            .fromObjectType === Customer
                    ) {
                        showBadge=true;
                        break;
                    } else {
                        showBadge=false;
                    }
                } // On Load, no messages are loaded , so taking information from server
                else if (
                    conversations[i].lastMsgReceivedFrom !== User ||
                    (conversations[i].lastMsgReceivedFrom === User &&  !conversations[i].lastMsgReceivedFromName.toLowerCase().includes("system") &&   // ignoring the the system messages which are sent at the last (like NPPI and media content)
                        conversations[i].lastMsgFromId !== conversations[i].agentId)
                ) {
                    showBadge=true;
                    break;
                }
            }       
            }
            setShowNotificationIndicator(showBadge) 
        }
        //logic for active conversation counts with red dots.
        let count = 0;
        if (conversations.length > 0) {
            conversations.forEach((conv) => {
                let newConv = false;
                let updateNewConvVal =
                  (conv?.lastMsgReceivedFrom === User &&
                    !conv?.lastMsgReceivedFromName
                      .toLowerCase()
                      .includes("system") && //ignoring the the system messages which are sent at the last (like NPPI and media content)
                    conv?.lastMsgFromId !== conv.agentId) ||
                  conv?.lastMsgFromId === "";
                if (conv?.messageList.length > 0) {
                    let msgList = [...conv?.messageList].reverse();
                    let msg = msgList.find((x) => x.fromObjectType === User);
                    if (
                        ((!!msg &&
                            msg.fromId !== conv.agentId &&
                            msgList[0].fromObjectType === User) ||
                            (!msg && updateNewConvVal)) && conv.status !== "Completed"
                    ) {
                        newConv = true;
                    }
                }
                //If no messages onload, taking info  from server about last message
                else if (updateNewConvVal) {
                    newConv = true;
                }
                 let msgList = [...conv?.messageList].reverse();
                if(msgList[0]?.messages[msgList[0]?.messages.length-1]?.messageType === "SYSTEMMSG"){
                    newConv = false;
                }

                let enableConversationReadOnSeen= userInfo?.teams?.filter(t => t.teamId === conv.teamId)[0]?.enableConversationReadOnSeen;

                if ((conv.messageList[conv.messageList.length - 1]?.fromObjectType === Customer && !enableConversationReadOnSeen) ||
                    newConv || (conv.messageList.length === 0 && conv?.lastMsgReceivedFrom !== User && !enableConversationReadOnSeen)) {  

                        count++;
                    
                }
                if (conv.showNotification===true && enableConversationReadOnSeen && !newConv && 
                   (conv?.lastMsgReceivedFrom !== User|| msgList[0]?.messages[msgList[0]?.messages.length-1].messageType === "SYSTEMMSG" 
                   || conv.messageList[conv.messageList.length - 1]?.fromObjectType === Customer)) {//check is last messag received from cutomer or last message is system message and increase count
                        count++;
            }
            })
        }
        setActiveConvCount(count);
        if (conversations.length > 0 && enableSentimentCalculation === "Y") {// check sentiment calculation count and make api call
            var convWithoutSentiment = conversations.filter((x) => (x.overallSentiment == null || x.overallSentiment == ''));
            //Calculate sentiment for conversations that don't have sentiment
            if (convWithoutSentiment) {
                convWithoutSentiment.forEach((item, index) => {
                dispatch(updateSentimentInStore({ conversationId: item.conversationId, messageList:item.messageList })); 
                })
            }
        }
    }, [conversations]);

    //UseEffect - When UserID available get active converstations
    useEffect(() => {
        if (appContext.userInfo.azureAdid != null) {
            dataService.getData().subscribe((message) => {
                // console.log(message.value, "messageValue");
                if (message.value != null) {
                    if (message.value.convDrawer != null) {
                        //Onclick of Header icon button Show / hide Conversation drawer
                        //debugger;
                        setOpenDrawer(message.value.convDrawer);
                    } else if (message.value.inboundMessage != null) {
                        //When inbound message received from customer
                        var tempMsg = message.value.inboundMessage.message;
                        var msg = {
                            conversationId: tempMsg.ConversationId,
                            fromId: tempMsg.FromId,
                            messageDate: tempMsg.MessageDate,
                            fromPhoneNumber: tempMsg.FromPhoneNumber,
                        };

                        //setInboundMessageData(msg);
                    } else if (message.value.outboundMessage != null) {
                        //setOutboundMessage(null);
                        //setOutboundMessageData(message.value.outboundMessage);
                    } else if (message.value.multiTabEndConversation != null) {
                        setMultiTabEndConversation(message.value.multiTabEndConversation);
                    } else if (message.value.createConversation != null) {
                        setCreateConversation(
                            mapConversation(
                                message.value.createConversation.conversation,
                                message.value.createConversation.conversation.teamPhoneNumber
                            )
                        );
                    } else if (message.value.selectedConversation != null) {
                        //when clicked on conversation which is active with same user

                        setSelectedConversation(
                            mapConversation(
                                message.value.selectedConversation.conversation,
                                message.value.selectedConversation.conversation.teamPhoneNumber
                            )
                        );
                    } else if (message.value.addConversation != null) {
                        addConversationtoList(message.value.addConversation);
                    } else if (message.value.updateConversation != null) {
                        //Updates if conv exists or insert the conv to store and sets the selected conversation
                        if (message.value.updateConversation.status == 'Active') {
                            dispatch(upsertConversationtoList(message.value.updateConversation));
                            appContext.setSelectedConv(message.value.updateConversation);
                            setSelectedConv(message.value.updateConversation);
                            dispatch(conversationSelected(message.value.updateConversation));
                            navigate("/conversations");     //To avoid blank screen on New Conversation, navigate to conversations page only after new conversation process completed
                            setSelectedMenu("active")
                            setOpenActive(true);
                            setConvLoading(false);
                        }
                    }
                    else if (message.value.updateContextConv != null) {
                        //Updates if conv exists or insert the conv to store
                        if (message.value.updateContextConv.status == 'Active') {
                            dispatch(upsertConversationtoList(message.value.updateContextConv));
                            appContext.setSelectedConv(message.value.updateContextConv);
                        }
                    }
                }
            });
            loadActiveConversations();
            loadUnassignedConversations();
            if(EnableRequestAssistance){
                dispatch(getSupervisorConversationsAPI())
            }
            var query = window.location.search.substring(1);
            console.log(query, "queryyyyyyy"); //"app=article&act=news_content&aid=160990"
            // if(query.split("="))

        }
    }, [appContext.userInfo.azureAdid]);

    useEffect(() => {
        if (openActive) {
            props.setSelectedMenu("active");
        }
    }, [openActive]);

    useEffect(() => {
        if (multiTabEndConversation != null) {
            dispatch(removeConversation(multiTabEndConversation.conversationId));

            // Check if the current location pathname includes "conversations"
            if(location.pathname.includes("conversations")){
                // Split the pathname into an array using "/"
                let pathName = location.pathname.split("/");

                // Check if the conversationId from multiTabEndConversation matches the one in the pathname
                if(multiTabEndConversation.conversationId===pathName[2]){
                    // If there is a match, reset the selected conversation and navigate to the home page
                    setSelectedConv(null);
                    navigate("/");
                } 
            }
            setSelectedConvId("");
            setConvState(!convState);
            setOpenActive(false);
        }
    }, [multiTabEndConversation]);
    //UseEffect - When a new conversation created
    //********************************************************///
    useEffect(() => {
        if (createConversation != null) {
            if (appContext.crmMode === "true") {
                if (window.sforce != null && appContext.crmProvider == 'S') {
                    createOrSearchTaskinCRM(
                        createConversation,
                        appContext.userInfo.crmMemberFields,
                        true,
                        activityDueDataHours,
                        "CreateConversation",
                        appContext.userInfo.teams, appContext.crmProvider
                    );
                }
                else {
                    sendEventToWebResource("createOrSearchTaskinCRM", { conv: createConversation, createTask: true, activityDueDataHours: activityDueDataHours, context: 'CreateConversation', eventFrom: "DrawerConvListNew" })
                } 
            }
        }
    }, [createConversation]);

    //useEffect for header notification functionality
    useEffect(() => {
        if (headerNotification === true) {
            loadUnassignedConversations();
            setOpenActive(false);
            setSearchConvOpen(true);
            setOpenDrawer(true);
            dataService.setData({ headerDrawer: true });
        }
        if (collapseInactive) {
            openAllDrawer();
        }
        if (
            headerNotification === false &&
            collapseInactive === false &&
            showActive
        ) {
            setOpenActive(true);
        }
        if (headerNotification === false && collapseInactive === true) {
            setOpenActive(false);
        }
        setShowActive(true);
    }, [notiFicationFilter]);

    //close conversation list drawer
    useEffect(() => {
        if (appContext.crmMode === "true" && openDrawer === true) {
            setOpenDrawer(false);
            dataService.setData({ headerDrawer: false });
        }
        if (selectedConv == null && !crmEmbededView && location.pathname?.includes("conversations")) {
            let pathName = location.pathname.split("/");
            if(pathName.length < 3){
             navigate("/");
            }
        }
    }, [selectedConv]);

    //Toggle showing drawer that shows list of conversations
    const toggleConvDrawer = (convDrawerState, headerDrawerState) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        if (appContext.crmMode === "false") {
            setOpenDrawer(convDrawerState);
            dataService.setData({ headerDrawer: headerDrawerState });
        }
    };

    const openActiveConversationById = () => {
      let pathName = location.pathname.split("/");
      if (
        location.pathname?.includes("conversations") &&
        pathName.length >= 2
      ) {
        let activeConvPresent=false;
        let missedConvPresent=false;
        for(let i=0;i<conversations.length;i++){
             //if conversationId from url matches with conversationId in list then open conversation.
          if (conversations[i].conversationId === pathName[2]) {
            setConvLoading(true);
            navigate(`/conversations/${conversations[i].conversationId}`);
            let selectedConversation = conversations[i];
         //get messages for selected conversation
            dispatch(
              getMessagesForConversationId(
                conversations[i].conversationId,
                setConvLoading,
                true
              )
            );
            setSelectedConversation(selectedConversation);
            setTimeout(()=>{//when actie conversation found setSelectedmenu=="active"
                setSelectedMenu("active")
            },[1000])
            activeConvPresent=true;
            break;
          }

        }
  
        if(activeConvPresent===false){
            for(let i=0;i<unassignedConversations.length;i++){
                  //if conversationId from url matches with conversationId in missed list then open conversation.
                  if (unassignedConversations[i].conversationId === pathName[2]) {
                    setConvLoading(true);
                    navigate(`/conversations/${unassignedConversations[i].conversationId}`);
                    let selectedConversation = unassignedConversations[i];
                 //get messages for selected conversation
                    dispatch(
                      getMessagesForConversationId(
                        unassignedConversations[i].conversationId,
                        setConvLoading,
                        true
                      )
                    );
                    setSelectedConversation(selectedConversation);
                    setTimeout(()=>{//when actie conversation found setSelectedmenu=="unassigned"
                        setSelectedMenu("unassigned")
                    },[1000])
                    missedConvPresent=true;
                    break;
                  }

            }
        }
        if(activeConvPresent===false && missedConvPresent===false){
            navigate("/")
        }
      }
      if (
        location.pathname?.includes("supervisorChat") &&
        pathName.length >= 2
      ){
        let supervisorConvPresent=false;
        for(let i=0;i<supervisorConversations.length;i++){
            //if conversationId from url matches with conversationId in list then open conversation.
         if (supervisorConversations[i].conversationId === pathName[2]) {
           setConvLoading(true);
           navigate(`/supervisorChat/${supervisorConversations[i].conversationId}`);
           let selectedConversation = supervisorConversations[i];
        //get messages for selected conversation
           dispatch(
             getMessagesForConversationId(
               supervisorConversations[i].conversationId,
               setConvLoading,
               true
             )
           );
           setSelectedConversation(selectedConversation);
           setTimeout(()=>{
               setSelectedMenu("supervisorActive")
           },[1000])
           supervisorConvPresent=true
           break;
         }
       }
       if(supervisorConvPresent===false){
        navigate("/")
        }
      }
      setOpenConvWithId(false);
    };

    async function updateConversationAttributes(conversationId,conversationAttributes){
        try{
          // Prepare request body with updated conversation attributes
          let requestBody = {
            conversationId: conversationId,
            conversationAttributes: conversationAttributes,
          };
    
          // Make an API call to update conversation attributes
          let result = await InvokePostServiceCall(
            apiConfig.UPDATE_CONVERSATION_ATTRIBUTES,
            requestBody
          );
         
        }catch(error){
          console.log(error,"UpdateConversationAttributes error")
        }
    }

    useEffect(()=>{
        if (openConvWithId) {
            setActiveConvLoaded(true);
            openActiveConversationById()
        }
    }, [openConvWithId])

    useEffect(() => {
        debugger
        if (activeConvLoaded && crmModeV2) {
            sendEventToWebResource("createNewSessionForActiveConversations", conversations)
            // createNewSessionForActiveConversations(crmModeV2, conversations, userInfo, updateConversationAttributes, dispatch, upsertConversationtoList);
        }
    }, [activeConvLoaded, crmModeV2]);
    
    const loadActiveConversations = () => {
        //API Request
        dispatch(
            getActiveConversationsAPI(
                appContext.userInfo.userId,
                appContext.userInfo.msgSupportNumber,
                setOpenConvWithId
            )
        );
    };

    const loadUnassignedConversations = () => {

        dispatch(
            getUnassignedConversationAPI(appContext)
        );
    };

    const addConversationtoList = (conversation) => {
        var conv = mapConversation(
            conversation,
            conversation.userPhoneNumber
        );
        dispatch(addConversationToList(conv));
        appContext.setSelectedConv(conv);
        setSelectedConversation(conv);
        setConvState(!convState);
    };

    const setSelectedConversation = (conv) => {
        setSelectedConv(conv);
        setSelectedConvId(conv.conversationId);
        if (appContext.crmMode === "true") {
            setOpenDrawer(false);
            setOpenConversationDrawer(false);
            dataService.setData({ headerDrawer: false });
        }
    };

    const conversationClick = (conv, status) => (event) => {
        if(status!=="Active"){
            props.setSelectedConvNone();
        }
        setConvLoading(true);
        navigate(`/conversations/${conv.conversationId}`);
        props.setSelectedMenu(status.toLowerCase());
        if (appContext.crmMode === "true") {
            openCifWindow();
        }
        let selectedConversation = null;
        if (status == "InActive") {
            selectedConversation = inactiveConversations.find(
                (x) => x.conversationId === conv.conversationId
            );
            if (selectedConversation != null) {
                selectedConversation.isReadOnly = true;
                selectedConversation.status = "InActive";
            }
        } else if (status == "Active") {
            if (conv.requiredConvAttrUpdate == true) {
                let conversation = Object.assign({}, conv);
                conversation.showNotification = false;
                if (window.sforce != null && appContext.crmProvider == 'S') {
                    refreshTokensData(conv, appContext.userInfo.crmMemberFields, appContext.crmProvider);
                }
                else {
                    sendEventToWebResource("openRecordinCRM", { conv: conversation, openRecord: false })
                }
            }
            selectedConversation = conversations.find(
                (x) => x.conversationId === conv.conversationId
            );
            if (
                selectedConversation?.messageList[
                    selectedConversation.messageList.length - 1
                ]?.isNewMsg == true
            ) {
                var msgList = [];
                selectedConversation.messageList.forEach(function (val, index, arr) {
                    var obj = { ...arr[index] };
                    obj.isNewMsg = false;
                    msgList.push(obj);
                });
                var payLoad = { ...selectedConversation };
                payLoad.messageList = msgList;
                dispatch(loadConversationMessages(payLoad));
            }            
        } else {
            selectedConversation = unassignedConversations.find(
                (x) => x.conversationId === conv.conversationId
            );
        }

        let newConvArr = newConvDetails;
        newConvDetails.forEach((item, index) => {
            if (conv.conversationId === item) {
                let indexId = newConvArr.indexOf(item);
                newConvArr.splice(indexId, 1);
                setNewConvDetails(newConvArr);
            }
        });
        
        if (selectedConversation?.messageList?.length === 0) {
            var isCheckMsgShowNotification = false;
            if (selectedConversation?.status == 'Active') {
                isCheckMsgShowNotification = true;
            }
            dispatch(
                getMessagesForConversationId(conv.conversationId, setConvLoading, isCheckMsgShowNotification)
            );
        } else {  
             // everytime there are messages in conv on client side, this gets executed
            // Find the index of the first message in the 'selectedConversation' messageList array that meets the specified conditions
            let newMessageInfoIndex = selectedConversation?.messageList.findIndex((conversation) => {
                // Check if the conversation is from the customer and contains a message with 'showNotification' set to true
                // or if it contains a message with 'messageSubType' equal to "CONV_ENDED"
                return (
                  (conversation.fromObjectType === 'Customer' && conversation.messages.find(message => message.showNotification === true)) ||
                  conversation.messages.find(message => message.messageSubType === "CONV_ENDED")
                );
            });            

             //Get the latest messages from server  we switch to new conversation
            if (newMessageInfoIndex !== -1) {
                dispatch(getMessagesForConversationId(conv.conversationId, setConvLoading,true));
            }
            setConvLoading(false);
            dispatch(conversationSelected(selectedConversation));
        }

        setSelectedConversation(selectedConversation);
        let index = conversations.findIndex(
            (x) => x.conversationId === conv.conversationId
          );
         if (index > -1 ) {
            if(conversations[index].showNotification===true){//check unread message is present and mark it as read.
              let tempConv= {...conv}
              tempConv.showNotification=false;
              dispatch(updateConversationRead(tempConv));// calling this method to update showNotification value and show/hide red dot on ui.
            }}
    };

    const openAllDrawer = () => {
        setOpenDrawer(true);
        setOpenConversationDrawer(true);
        dataService.setData({ headerDrawer: true });
    };

    const StyledBadge = styled(Badge)(({ theme }) => ({
        "& .MuiBadge-badge": {
            backgroundColor: "#dc3545",
            color: "#dc3545",
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            "&::after": {
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                border: "1px solid currentColor",
                content: '""',
            },
        },
    }));
    const IconBadge = styled(Badge)(({ theme }) => ({
        "& .MuiBadge-badge": {
            top: "7px",
            right: "3px",
        },
    }));

    useEffect(() => {
        if (assignedConvId) {
            if (assignedConvId?.conversationId === selectedConv?.conversationId) {
                setSelectedConv(null);
                navigate("/")
                setAssignedConvId("")
            }
        }
    }, [assignedConvId]);

    //useEffect for clearing filter on menu change
    useEffect(() => {
        setConversationFilter(false);
        setConvFilterValue("");
    }, [props.selectedMenu])

    const ActiveConversations = () => {
        return (
            <>
                {isCompLoading && showCircularProgress ? (
                    <div style={theme.customStyle.convCircularProgress}>
                        <CircularProgress />
                    </div>
                ) : conversations.length > 0 ? (
                    conversations.map((conv) => {
                        let newConv = false;
                        let updateNewConvVal =
                        (conv?.lastMsgReceivedFrom === User &&
                          !conv?.lastMsgReceivedFromName
                            .toLowerCase()
                            .includes("system") && //ignoring the the system messages which are sent at the last (like NPPI and media content)
                          conv?.lastMsgFromId !== conv.agentId) ||
                        conv?.lastMsgFromId === "";
                        let msgList = [...conv?.messageList].reverse();
                        if (conv?.messageList.length > 0) {
                            let msg = msgList.find((x) => x.fromObjectType === User);
                            if (
                                ((!!msg &&
                                    msg.fromId !== conv.agentId &&
                                    msgList[0].fromObjectType === User) ||
                                    (!msg && updateNewConvVal)) && conv.status !== "Completed"
                            ) {
                                newConv = true;
                            }
                        }
                        //If no messages onload, taking info  from server about last message
                        else if (updateNewConvVal  && conv.status !== "Completed") {
                            newConv = true;
                        }
                        if (conv?.messageList.length > 0) {
                            if (
                                msgList[0].messages[msgList[0].messages.length-1]?.messageType === "SYSTEMMSG"
                            ) {
                                newConv = false;
                            }
                        }
                        let enableConversationReadOnSeen= userInfo?.teams?.filter(t => t.teamId === conv.teamId)[0]?.enableConversationReadOnSeen;

                        return (
                            <React.Fragment key={conv.conversationId}>
                                {conv.customerName.toLowerCase().includes(convFilterValue.toLowerCase()) ? (
                                    <>
                                        {" "}
                                        <ListItem
                                            className={
                                                selectedConv != null &&
                                                    conv.conversationId.toLowerCase() ===
                                                    selectedConv.conversationId.toLowerCase()
                                                    ? "conversationitem"
                                                    : ""
                                            }
                                            button
                                            key={conv.conversationId}
                                            selected={
                                                selectedConv != null
                                                    ? conv.conversationId.toLowerCase() ===
                                                    selectedConv.conversationId.toLowerCase()
                                                    : false
                                            }
                                            onClick={ conversationClick(conv, "Active")}
                                            sx={{
                                                paddingLeft: "4px",
                                                paddingRight: "4px",
                                                backgroundColor:
                                                    conv?.messageList[conv.messageList.length - 1]
                                                        ?.isNewMsg == true
                                                        ? "azure !important"
                                                        : "initial !important",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Tooltip title={conv.customerName}>
                                                    {conv?.channel === 'Chat' ?
                                                        (<ChatIcon color="primary" sx={{marginTop:"2px"}}  />)
                                                        : (<PhoneIphoneIcon color="primary" />)}
                                                </Tooltip>
                                                 {!enableConversationReadOnSeen &&(conv?.messageList[conv.messageList.length - 1]
                                                    ?.fromObjectType === Customer ||
                                                    newConv ||
                                                    (conv.messageList.length === 0 &&
                                                        conv?.lastMsgReceivedFrom !== User) ? (
                                                    <StyledBadge
                                                        style={{
                                                            position: "relative",
                                                            left: "-5px",
                                                            bottom: "15px",
                                                        }}
                                                        color="error"
                                                        variant="dot"
                                                        overlap="circular"
                                                    ></StyledBadge>
                                                ) : null)} 
                                                 {enableConversationReadOnSeen && conv?.showNotification && (conv?.messageList[conv.messageList.length - 1]
                                                    ?.fromObjectType === Customer || msgList[0]?.messages[msgList[0]?.messages.length-1]?.messageType === "SYSTEMMSG" || conv?.lastMsgReceivedFrom==="Customer") && (
                                                    <StyledBadge
                                                        style={{
                                                            position: "relative",
                                                            left: "-5px",
                                                            bottom: "15px",
                                                        }}
                                                        color="error"
                                                        variant="dot"
                                                        overlap="circular"
                                                    ></StyledBadge>
                                                ) }

                                                <>
                                                    <div
                                                        style={{
                                                            marginLeft: 13,
                                                            minWidth: 176,
                                                        }}
                                                    >
                                                        <div className="userName" style={{ display: "flex" }}>{conv.customerName}
                                                            {(conv?.channel === 'Chat' && conv?.authenticationStatus === 'Authenticated')
                                                                && <Tooltip title="Authenticated">
                                                                    <TaskAltTwoToneIcon fontSize={"small"} color="success" sx={{ marginLeft: "3px" }} />
                                                                </Tooltip>}
                                                        </div>
                                                        <div className="phoneNumber" style={{wordBreak: "break-all",marginRight:"5px"}}>
                                                            {conv.customerPhoneNumber ? conv.customerPhoneNumber :conv.email}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {newConv ? (
                                                            <Badge
                                                                style={{
                                                                    position: "relative",
                                                                    left: "15px",
                                                                    bottom: "12px"
                                                                }}
                                                                badgeContent={"New"}
                                                                color="error"
                                                            />
                                                        ) : null}
                                                    </div>
                                                    <Box sx={{ color: "#808080", fontSize: "11px", position: 'relative', top: "12px",minWidth:"54px" }}>
                                                        {moment().isAfter(conv.recentActivityDate, "day") ? "Yesterday" : moment().isSame(conv.recentActivityDate, "day") ? moment(conv.recentActivityDate).format("LT") : moment(conv.recentActivityDate).format("MM/DD/YY")}
                                                    </Box>
                                                </>
                                            </div>
                                        </ListItem>
                                        <Divider
                                            key={"dvdr" + conv.conversationId}
                                            style={{ backgroundColor: "white" }}
                                        />
                                    </>
                                ) : (
                                    <></>
                                )}
                            </React.Fragment>
                        );
                    })
                ) : (
                    <div
                        style={{
                            textAlign: "center",
                            paddingTop: 10,
                            paddingBottom: 10,
                        }}
                    >
                        No Active Conversations
                    </div>
                )}
            </>
        );
    };
    const NewConversationsCmp = () => {
        return (
            <>
                {newConversations.length > 0 ? (
                    newConversations.map((data) => {
                        let timeVal;
                        if (data.newConvTimer) {
                            timeVal = (100 * data.newConvTimer) / 120;
                        }
                        return (
                            <>
                                {data.newConvTimer !== 0 && (
                                    <>
                                        <ListItem
                                            sx={{ paddingLeft: "12px" }}
                                            button
                                            onClick={(e) => {
                                                toggleConvDrawer(true, true)(e);
                                                if (crmMode === "true") {
                                                    openCifWindow();
                                                }
                                            }}
                                        >
                                            <div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Avatar
                                                        sx={{
                                                            ...theme.customStyle.convAvatar,
                                                            marginTop: "8px",
                                                            marginBottom: "8px",
                                                            width: 30,
                                                            height: 30,
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        {data.customerNameInitials}
                                                    </Avatar>
                                                    <StyledBadge
                                                        style={{
                                                            position: "relative",
                                                            left: "-5px",
                                                            bottom: "15px",
                                                        }}
                                                        color="error"
                                                        variant="dot"
                                                        overlap="circular"
                                                    ></StyledBadge>
                                                    <div style={{ marginLeft: 15, minWidth: 176 }}>
                                                    <div className="userName" style={{ display: "flex" }}>{data.customerName}
                                                        {(data?.channel === 'Chat' && data?.authenticationStatus === 'Authenticated')
                                                          && <Tooltip title="Authenticated">
                                                            <TaskAltTwoToneIcon fontSize = {"small"} color="success" sx={{ marginLeft: "3px" }} />
                                                          </Tooltip>}
                                                        </div>
                                                        <div style={{ fontSize: 14,wordBreak: "break-all" }}>
                                                            {data?.channel === 'Chat'?data.email:data.customerPhoneNumber}
                                                        </div>
                                                        <div>
                                                            <LoadingButton
                                                                loading={saveAction === "Accepted"}
                                                                disabled={(saveAction === "Accepted" || saveAction === "Rejected")}
                                                                color="success"
                                                                variant="contained"
                                                                size="small"
                                                                disableElevation
                                                                sx={theme.customStyle.btnStyle}
                                                                onClick={() => {
                                                                    handleAcceptRejectConv(
                                                                        "Accepted",
                                                                        data.conversationId,
                                                                        data.teamId
                                                                    );
                                                                }}
                                                            >
                                                                Accept
                                                            </LoadingButton>
                                                            <LoadingButton
                                                                loading={saveAction === "Rejected"}
                                                                disabled={(saveAction === "Accepted" || saveAction === "Rejected")}
                                                                color="error"
                                                                variant="contained"
                                                                size="small"
                                                                disableElevation
                                                                sx={{
                                                                    ...theme.customStyle.btnStyle,
                                                                    marginLeft: "4px",
                                                                }}
                                                                onClick={() => {
                                                                    handleAcceptRejectConv(
                                                                        "Rejected",
                                                                        data.conversationId,
                                                                        data.teamId
                                                                    );
                                                                }}
                                                            >
                                                                Reject
                                                            </LoadingButton>
                                                        </div>
                                                    </div>

                                                    <CircularProgressWithLabel
                                                        value={timeVal}
                                                        newConvTimer={data.newConvTimer}
                                                    />
                                                </div>
                                            </div>
                                        </ListItem>
                                        <Divider key={"dvdr" + data.conversationId} />
                                    </>
                                )}
                            </>
                        );
                    })
                ) : (
                    <div style={{ textAlign: "center", paddingTop: 10 }}>
                        No Conversations
                    </div>
                )}
            </>
        );
    };
    useEffect(()=>{
        if(conversationFilter){
            inputFilterRef.current.focus();
        }
    },[conversationFilter])
    return (
        <>
            {!crmEmbededView ? (
                <Box role="presentation">
                    <StyledList sx={{ paddingTop: "0px", paddingBottom: "0px" }}>
                        {newConversations.length > 0 && !hideNewConvMenu && (
                            <NewConversationsNew
                                newConversations={newConversations}
                                convDrawer={openDrawer}
                                setSelectedMenu={props.setSelectedMenu}
                                selectedMenu={props.selectedMenu}
                                toggleConvDrawer={toggleConvDrawer}
                                openDrawer={openAllDrawer}
                            />
                        )}
                        {appContext.userInfo.teams.length != 0 && (
                            <div>
                                <ListItemButton
                                    selected={props.selectedMenu === "active"}
                                    sx={theme.customStyle.listItemIconDrawerClosedNew}
                                    onClick={(e) => {
                                        setOpenActive(!openActive);
                                        setSearchConvOpen(false);
                                        setHeaderNotification(false);
                                        setCollapseInactive(false);
                                        props.setSelectedMenu("active");
                                        setOpenConversationDrawer(true);
                                    }}
                                    title="Active Conversations"
                                >
                                    <ListItemIcon sx={theme.customStyle.listItemIcon}>
                                        <div style={{ textAlign: "left" }}>
                                            <IconBadge
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "right",
                                                }}
                                                badgeContent={activeConvCount}
                                                color="primary"
                                            >
                                                <SmsTwoToneIcon
                                                    iconSize={theme.customStyle.iconSize}
                                                    sx={
                                                        props.selectedMenu === "active"
                                                            ? theme.customStyle.activeDrawerMenuDrawerClosed
                                                            : theme.customStyle.inActiveDrawerMenuDrawerClosed
                                                    }
                                                />
                                            </IconBadge>
                                        </div>
                                        <Box
                                            sx={{
                                                ...theme.customStyle.listItemLabelDiv,
                                                left: "-2px",
                                            }}
                                        >
                                            <span style={{ fontSize: "10px" }}>Active</span>
                                        </Box>
                                    </ListItemIcon>
                                </ListItemButton>
                            </div>
                        )}
                        {
                          supervisorConversations.length >0 && appContext.userInfo.role === "Supervisor" && EnableRequestAssistance &&  <div>
                             <ListItemButton
                                 selected={props.selectedMenu === "supervisorActive"}
                                 sx={theme.customStyle.listItemIconDrawerClosedNew}
                                 onClick={(e) => {
                                     setOpenActive(false);
                                     setSearchConvOpen(false);
                                     setHeaderNotification(false);
                                     props.setSelectedMenu("supervisorActive");
                                     setOpenConversationDrawer(true);
                                 }}
                                 title="Supervisor Conversations"
                             >
                                 <ListItemIcon sx={theme.customStyle.listItemIcon}>
                                     <div style={{ textAlign: "left" }}>
                                         <IconBadge
                                             anchorOrigin={{
                                                 vertical: "top",
                                                 horizontal: "right",
                                             }}
                                             badgeContent={supervisorConversations.length}
                                             color="primary"
                                         >
                                             <SmsTwoToneIcon
                                                 iconSize={theme.customStyle.iconSize}
                                                 sx={
                                                     props.selectedMenu === "supervisorActive"
                                                         ? theme.customStyle.activeDrawerMenuDrawerClosed
                                                         : theme.customStyle.inActiveDrawerMenuDrawerClosed
                                                 }
                                             />
                                         </IconBadge>
                                     </div>
                                     <Box
                                         sx={{
                                             ...theme.customStyle.listItemLabelDiv,
                                             left: "-10.10px",
                                         }}
                                     >
                                         <span style={{ fontSize: "9.5px" }}>Supervisor</span>
                                     </Box>
                                 </ListItemIcon>
                             </ListItemButton>
                         </div>
                        }
                        {appContext.userInfo.role === "Agent" ||
                        appContext.userInfo.role === "Supervisor" ||
                            appContext.userInfo.role === "Admin" ? (
                            unassignedConversations.length > 0 && (
                                <UnassignedConversationsNew
                                    selectedConv={selectedConv}
                                    conversationClick={conversationClick}
                                    toggleConvDrawer={toggleConvDrawer}
                                    unassignedConversations={unassignedConversations}
                                    openActive={openActive}
                                    setOpenActive={setOpenActive}
                                    convDrawer={openDrawer}
                                    openDrawer={openAllDrawer}
                                    setSelectedMenu={props.setSelectedMenu}
                                    selectedMenu={props.selectedMenu}
                                    IconBadge={IconBadge}
                                />
                            )
                        ) : (
                            <></>
                        )}
                        {
                        appContext.userInfo.role === "Supervisor" ||
                            appContext.userInfo.role === "Admin" ? (
                                    (inactiveConversations.length > 0 || inactiveConvCount > 0)&& (
                                <InactiveConversationsNew
                                    selectedConv={selectedConv}
                                    conversationClick={conversationClick}
                                    toggleConvDrawer={toggleConvDrawer}
                                    setOpenActive={setOpenActive}
                                    openActive={openActive}
                                    convDrawer={openDrawer}
                                    inactiveConversations={inactiveConversations}
                                    setInactiveConversations={setInactiveConversations}
                                    openDrawer={openAllDrawer}
                                    setSelectedMenu={props.setSelectedMenu}
                                    selectedMenu={props.selectedMenu}
                                    IconBadge={IconBadge}
                                />
                            )
                        ) : (
                            <></>
                        )}
                    </StyledList>
                </Box>
            ) : null}

            <Drawer
                sx={{
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: openConversationDrawer ? 280 : 0,
                        boxSizing: "border-box",
                        backgroundColor: "#efefef",
                        marginLeft: drawerWidth,
                    },
                }}
                anchor={"left"}
                variant="persistent"
                open={true}
                onClose={() => {
                    setOpenConversationDrawer(false);
                }}
            >
                <Box sx={{ marginTop: "70px" }}>
                    {!conversationFilter && (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "1px 5px 1px 5px",
                            }}
                        >
                            <h5>{`${props.selectedMenu === "new"
                                ? "New"
                                : props.selectedMenu === "active"
                                    ? "Active"
                                    : props.selectedMenu === "inactive"
                                        ? "Inactive"
                                        : props.selectedMenu === "unassigned"
                                            ? "Missed"
                                        : props.selectedMenu === "supervisorActive"
                                            ? "Supervisor"
                                            : "Active"
                                } Conversations`}</h5>
                            <IconButton
                                onClick={() => {
                                    setConversationFilter(true);
                                }}
                            >
                                <FilterAltIcon />
                            </IconButton>
                        </Box>
                    )}
                    {conversationFilter && (
                        <TextField
                            inputRef={inputFilterRef}
                            variant="standard"
                            placeholder="  Filter by name"
                            fullWidth
                            type="text"
                            sx={{ marginTop: "10px" }}
                            onChange={(e) => {
                                setConvFilterValue(e.target.value);
                            }}
                            id="DrawerfilterFirstName"
                            inputProps={{
                                style: {
                                  paddingLeft: 5
                                }
                            }}
                            InputProps={{
                                autoComplete: 'new-password',
                                form: {
                                    autocomplete: 'off',
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            sx={{ position: "relative", bottom: "4px", right: "4px" }}
                                            onClick={() => {
                                                setConversationFilter(false);
                                                setConvFilterValue("");
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                    <Divider />
                </Box>
                <>
                    {props.selectedMenu === "new" ? (
                        <NewConversationsCmp />
                    ) : props.selectedMenu === "unassigned" ? (
                        <ConversationListComp
                            selectedConv={selectedConv}
                            conversationClick={conversationClick}
                            conversationList={unassignedConversations}
                            convFilterValue={convFilterValue}
                            convStatus={"UnAssigned"}
                                convLoading={convLoading}  // component loading symbol
                        />
                    ) : props.selectedMenu === "inactive" ? (
                        <ConversationListComp
                            selectedConv={selectedConv}
                            convFilterValue={convFilterValue}
                            conversationClick={conversationClick}
                            conversationList={inactiveConversations}
                            convStatus={"InActive"}
                        />
                    ) : props.selectedMenu === "supervisorActive" ? (
<>
                {isCompLoading && showCircularProgress ? (
                  <div style={theme.customStyle.convCircularProgress}>
                    <CircularProgress />
                  </div>
                ) : supervisorConversations.length > 0 ? (
                    supervisorConversations.map((conv) => {
                    return (
                      <React.Fragment key={conv.conversationId}>
                        {conv.customerName
                          .toLowerCase()
                          .includes(convFilterValue.toLowerCase()) ? (
                          <>
                            {" "}
                            <ListItem
                              className={
                                selectedConv != null &&
                                conv.conversationId.toLowerCase() ===
                                  selectedConv.conversationId.toLowerCase()
                                  ? "conversationitem"
                                  : ""
                              }
                              button
                              key={conv.conversationId}
                              selected={
                                selectedConv != null
                                  ? conv.conversationId.toLowerCase() ===
                                    selectedConv.conversationId.toLowerCase()
                                  : false
                              }
                              onClick={()=>{
                                setConvLoading(true);
                                navigate(`/supervisorChat/${conv.conversationId}`);
                                setTimeout(()=>{props.setSelectedMenu("supervisorActive");},1000)
                                let selectedConversation = null;
                                selectedConversation = supervisorConversations.find(
                                    (x) => x.conversationId === conv.conversationId
                                );
                                dispatch(
                                    getMessagesForConversationId(conv.conversationId, setConvLoading, "supervisorActive")
                                );
                                // }
                                setSelectedConversation(selectedConversation);
                              }}
                              sx={{
                                paddingLeft: "4px",
                                paddingRight: "4px",
                                backgroundColor:
                                  conv?.messageList[conv.messageList.length - 1]
                                    ?.isNewMsg == true
                                    ? "azure !important"
                                    : "initial !important",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Tooltip title={conv.customerName}>
                                  {conv?.channel === "Chat" ? (
                                    <ChatIcon
                                      color="primary"
                                      sx={{ marginTop: "2px" }}
                                    />
                                  ) : (
                                    <PhoneIphoneIcon color="primary" />
                                  )}
                                </Tooltip>
                                <>
                                  <div
                                    style={{
                                      marginLeft: 13,
                                      minWidth: 176,
                                    }}
                                  >
                                    <div
                                      className="userName"
                                      style={{ display: "flex" }}
                                    >
                                      {conv.customerName}
                                      {conv?.channel === "Chat" &&
                                        conv?.authenticationStatus ===
                                          "Authenticated" && (
                                          <Tooltip title="Authenticated">
                                            <TaskAltTwoToneIcon
                                              fontSize={"small"}
                                              color="success"
                                              sx={{ marginLeft: "3px" }}
                                            />
                                          </Tooltip>
                                        )}
                                    </div>
                                    <div
                                      className="phoneNumber"
                                      style={{
                                        wordBreak: "break-all",
                                        marginRight: "5px",
                                      }}
                                    >
                                      {conv.customerPhoneNumber
                                        ? conv.customerPhoneNumber
                                        : conv.email}
                                    </div>
                                  </div>
                                  <Box
                                    sx={{
                                      color: "#808080",
                                      fontSize: "11px",
                                      position: "relative",
                                      top: "12px",
                                      minWidth: "54px",
                                    }}
                                  >
                                    {moment().isAfter(
                                      conv.recentActivityDate,
                                      "day"
                                    )
                                      ? "Yesterday"
                                      : moment().isSame(
                                          conv.recentActivityDate,
                                          "day"
                                        )
                                      ? moment(conv.recentActivityDate).format(
                                          "LT"
                                        )
                                      : moment(conv.recentActivityDate).format(
                                          "MM/DD/YY"
                                        )}
                                  </Box>
                                </>
                              </div>
                            </ListItem>
                            <Divider
                              key={"dvdr" + conv.conversationId}
                              style={{ backgroundColor: "white" }}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </React.Fragment>
                    );
                  })
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                      paddingTop: 10,
                      paddingBottom: 10,
                    }}
                  >
                    No Supervisor Conversations
                  </div>
                )}
              </>
                    ):(
                        <ActiveConversations />
                    )}
                </>
            </Drawer>
        </>
    );
}

export default DrawerConvListNew;