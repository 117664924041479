/***************
 * Yogesh 28-11/2022 Texting:850=>new state added for loading before getting the list of conversions
 *  12/09/2022 => ONKAR,Sudam =>Texting912,Texting-913 Prompt the Templates to the User before start the conversation
 *12-15-2022 => ONKAR => Texting-926 set crmMode in app level based on url.
 *06-07-2023 => ONKAR => TEXTING-1314 => Icons in left panel is not highlighting automatically, after reject/auto reject the new message request.(added one state in this file to save previous selected menu)
 *08-10-2023 => PRABHAT => TEXTING-1561 Agent profile picture should be shown in Texting UI view.
 *08-18-2023 => SUDAM B CHAVAN => TEXTING-1579 - Login History
 *08-28-2023 => SUDAM B CHAVAN =>TEXTING-1594 - Reloading the Texting UI frame is also updating login date of user.
 *08-31-2023 => PRABHAT =>TEXTING-1643 - Error page with placeholder to show error for any unhandled exception
 *09-05-2023 => ONKAR =>TEXTING-1668- React Upgrade : End conversation error.
 *09-05-2023 => ONKAR =>TEXTING-1661- Switch back conversation history for unauthenticated & SMS conversations to old view.
 *10-17-2023 => SUDAM B CHAVAN => TEXTING-1646 Reporting - Realtime Reports Design(Added settings to show/hide comp)
 *10-20-2023 => SUDAM B CHAVAN => TEXTING-1799 Click on pub sub reconnect button is not refreshing the conversations count on Texting UI.
 *10-27-2023 => ONKAR => enableSentiment state value issue fixed.
 *11-02-2023 => SUDAM B CHAVAN => TEXTING-1829 - Client requirement: On login, user status should be updated to available.
 *11-07-2023 => SUDAM B CHAVAN => login and location details changes
 *12-12-2023 => SUDAM B CHAVAN => TEXTING-1916 0: Configuration to Enable Survey in appsettings
 *12-12-2023 => SUDAM B CHAVAN => TEXTING-1916 object key name changes
 *12-16-2023 => SUDAM B CHAVAN => TEXTING-1926 Click on reconnect also updating the log in history - Not consistent
 *12-19-2023 => ONKAR => TEXTING-1935 Templates Approval Flow - UI
 *12-26-2023 => ONKAR => TEXTING-1935 Templates Approval Flow - UI
 *01-03-2023 => SUDAM B CHAVAN =>  TEXTING-1934 Templates Approval Flow
 *01-08-2023 => ONKAR =>  TEXTING-1941 Templates page should be shown in Setup menu dropdown instead of Home screen.
 *01-16-2024 => SUDAM B CHAVAN => TEXTING-1943 Contact List UI - Contacts list filter 
 *01-24-2024 => SUDAM B CHAVAN => TEXTING-1956 Contact List Filter
*/
import React, { createContext, useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { InvokePostServiceCall } from "./api/serviceUtil";
import apiConfig from "./api/apiConfig";
import { Helmet } from "react-helmet";
import useMediaQuery from '@mui/material/useMediaQuery';
import tokenData from "./api/authData";
import { useNavigate } from "react-router-dom";
import { dataService } from "./dataService";
import UnAuthorized from "./UnAuthorized";
import jwt from 'jsonwebtoken';


export const AuthContext = createContext({});

const AuthContextProvider = (props) => {
    const [setupCollapse, setSetupCollapse] = useState(false);
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState({});
    const [isTokenAvailable, setIsTokenAvailable] = useState(false);
    const { instance, accounts } = useMsal();
    const [newConvDetails, setNewConvDetails] = useState([]);
    const [crmMode, setCrmMode] = useState(String(props.crmView));
    const [crmEmbededView, setCrmEmbededView] = useState(false);
    const [messageApiToken, setMessageApiToken] = useState("");
    const [crmProvider, setCrmProvider] = useState("D");
    const [searchConvOpen, setSearchConvOpen] = useState(false);
    const [selectedConvId, setSelectedConvId] = useState("");
    const [selectedConv, setSelectedConv] = useState(null);
    const [selectedMsgId, setSelectedMsgId] = useState("");
    const [drawerNotificationCount, setDrawerNotificationCount] = useState(0);
    const [headerNotification, setHeaderNotification] = useState(false);
    const [notiFicationFilter, setNotificationFilter] = useState(false);
    const [unAssignedConvData, setunAssignedConvData] = useState([]);
    const [showNotificationIndicator, setShowNotificationIndicator] = useState(false);
    const matches = useMediaQuery('(min-width:600px)');
    const [statusChanged, setStatusChanged] = useState(false);
    const [decision, setDecision] = useState("");
    const [msgLoadingState, setMsgLoadingState] = useState(false);
    const [inactiveConversations, setInactiveConversations] = useState([]);
    const [collapseInactive, setCollapseInactive] = useState(false);
    const [inactiveConvCount, setInactiveConvCount] = useState(0);
    const [loadUserFilter, setLoadUserFilter] = useState(false);
    const [loadContactFilter, setLoadContactFilter] = useState(false);
    const [loadContactsListFilter, setLoadContactsListFilter] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [showDrawer, setShowDrawer] = useState(false);
    const [assignedConvId, setAssignedConvId] = useState("");
    const [openActive, setOpenActive] = useState(false);
    const [activityDueDataHours, setActivityDueDateHours] = useState(0);
    const [departments, setDepartments] = useState([]);
    const [convLoading, setConvLoading] = useState(false);
    const [openConversationDrawer, setOpenConversationDrawer] = useState(false);
    const [showDocuSignMessage, setShowDocuSignMessage] = useState(false);
    const [templatesData, setTemplatesData] = useState(false);
    const [tokens, setTokens] = useState([]);
    const [selectedMenu, setSelectedMenu] = useState("");
    const [prevSelectedMenu,setPrevSelectedmenu]=useState("")
    const [docuSignCode, setDocuSignCode] = useState("");
    const [ProfileImgBase64, setProfileImgBase64] = useState("");
    const [hideNewConvMenu, setHideNewConvMenu] = useState(false);   //state added to hide new conversation menu.
    const [applicationSettings, setApplicationSettings] = useState({

    });
    const [enableVideoCall, setEnableVideoCall] = useState("");
    const [enableAutoSuggestion, setEnableAutoSuggestion] = useState("N");
    const [sentiment,setSentiment]=useState("");
    const [enableSentimentCalculation,setEnableSentimentCalculation]=useState("N");
    const [sentimentCalculateEveryXMessages, setSentimentCalculateEveryXMessages] = useState(5);
    const [contactsFilterData, setContactsFilterData] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        teamId: "",
    });
    const [contactsListFilterData, setContactsListFilterData] = useState({
        status: "",
        firstName: "",
        lastName: "",
        phone: "",
        fromDate: "",
        toDate: ""
    });
    const [usersFilterData, setUsersFilterData] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        isActive: "",
        teamId: "",
    });
    const [inactiveConvCountByTeams, setInactiveConvCountByTeams] = useState([]);
    const UnAssignedAdd = 'UnAssignedAdd';
    const [, updateState] = useState();
    const [conversationSummaryWithGpt, setConversationSummaryWithGpt] = useState([]);
    const [location, setLocation] = useState({
        lat: null,
        long: null
    });
    const [clientIPAddr, setClientIPAddr] = useState(null);
    const [clientCountry, setClientCountry] = useState(null);
    const [proceedForLogin, setProceedForLogin] = useState(false);
    useEffect(() => {
        if (accounts[0]?.localAccountId != null) {
            // updateappContext();
            getUserCountryAndIP();
        }
       
    }, [accounts[0]?.localAccountId]);
    useEffect(() => {
        //state values for location,IP change this will execute. 
        if (proceedForLogin) {
            updateappContext();
        }
    }, [proceedForLogin]);
    useEffect(() => {
        if (window.location.href.includes("dynamics")) {
            setCrmProvider("D");

        }
        else {
            setCrmProvider("S");
        }
    }, []);
    const success = (position) => {
        setLocation(prevLocation => {
            return {
                ...prevLocation,
                lat: position.coords.latitude,
                long: position.coords.longitude,
            }
        }
        )
        setProceedForLogin(true); // after got a success response.
    }
    const error = () => {
        console.log("Unable to retrieve your location");
        setProceedForLogin(true);//if any error occured while fetching location details
    }
///below function will fetch loaction details and log after login success fully into databas
    const getUserCountryAndIP = async () => {
        try {
            const response = await fetch('https://ipapi.co/json/');
            const data = await response.json();
            setClientIPAddr(prevClientIPAddr => { return data.ip });
            setClientCountry(prevClientCountry => { return data.country_name });
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(success, error);
            } else {
                console.log("Geolocation not supported");
                setProceedForLogin(true);//if user didn't allow to share location on browser.
            }
        }
        catch (e) {
            //If failed for any reason proceed with login
            setProceedForLogin(true);
        }
    }

    const isTokenExpired = (token)=> {
        try {
            const decodedToken = jwt.decode(token);
            if (decodedToken.exp) { //token expiry
                const userId = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
                const currentTimestamp = Math.floor(Date.now() / 1000); // Convert to seconds
                if (decodedToken.exp > currentTimestamp) { //token is valid
                    console.log("Valid token")
                    tokenData.setToken(token);
                    setIsTokenAvailable(true);
                    loadUserDetails(userId, token) //loading initial data
                    return false //token is valid, not yet expired 
                }
                else {
                     return true; // Token is expired
                }
            }
            else {
                return true; // Token doesn't have an expiration claim
            }
        } catch (error) {
            console.log("validate token error - ", error)
            return true; // Token is invalid or malformed
        }
    }
    const updateappContext = async () => {
        const loginRequest = {
            scopes: [apiConfig.API_AD_SCOPE],
        };
        const tokenRequest = { loginRequest, account: accounts[0] };
        let loginType = null;
        if (window.location.href.includes("dynamics") || window.location.href.includes("salesforce")) {
            loginType = "CRM";
        }
        let logInReq = {
            userDetail: {
                user: {
                    azureAdid: accounts[0]?.localAccountId.toLowerCase(),
                },
            },
            country: clientCountry,
            sourceIp: clientIPAddr,
            latitude: location.lat,
            longitude: location.long,
            userAgent: navigator?.userAgentData?.mobile ? 'Mobile' : "Browser",
            loginType: loginType ? loginType : "Standalone",
            isReload: performance.getEntriesByType("navigation")[0].type === "reload",
            logHistory: true//this flag will specify wether to update the location details in login history or not
        };
        console.log(tokenRequest);
        instance.acquireTokenSilent(tokenRequest).then((response) => {
            var token = localStorage.getItem("jwtToken");
            var makeLoginCall = true;
            if (token) { //if token available check for expiration
                makeLoginCall = isTokenExpired(token);
            }
            if (makeLoginCall) { //token not available or invalid token
                console.log('Token has expired or is invalid.');
                InvokePostServiceCall(apiConfig.LOGIN, logInReq, response.accessToken)
                    .then(async (apiresponse) => {
                        if (apiresponse == null || apiresponse.data == null || apiresponse.data == "") {
                            dataService.setData({ headerDrawer: false });
                            navigate("/UnAuthorized", { state: { message: "You are not Authorized to use this Application!!" } });
                        }
                        else {
                            tokenData.setToken(apiresponse.data.token);
                            setIsTokenAvailable(true);
                            localStorage.setItem("jwtToken", apiresponse.data.token);
                            //sessionStorage.setItem("MessageApiToken", apiresponse.data.token); // this store to local is for test purpose - we need to etend interceptors and calling logic
                            loadUserDetails(apiresponse.data.userId, apiresponse.data.token)//loading initial data
                        }
                    })
                    .catch((e) => {
                        console.log("Error in AuthContxt" + e.message);
                        dataService.setData({ headerDrawer: false });
                        navigate("/UnAuthorized", { state: { message: "Something went wrong, please contact your system administrator" } });
                    });}
        });
    };
const loadUserDetails = async(userId, token)=>{
    if (props.context === "ClickToMessage") {
        try {
            let settingsResponse = await InvokePostServiceCall(apiConfig.GET_NONADMIN_SETTINGS, { isActive: true });
            console.log(settingsResponse, "settings data")
            let settingsObj = {};
            for (let i = 0; i < settingsResponse.data.settings.length; i++) {
                settingsObj[settingsResponse.data.settings[i].settingsKey] = settingsResponse.data.settings[i].settingsValue;
                if (settingsResponse.data.settings[i].settingsKey === "ActivityDueDateHours" && settingsResponse.data.settings[i].isActive === true) {
                    setActivityDueDateHours(settingsResponse.data.settings[i].settingsValue);
                }
                if (settingsResponse.data.settings[i].settingsKey === "Departments" && settingsResponse.data.settings[i].isActive === true) {
                    if (settingsResponse.data.settings[i].settingsValue != null || settingsResponse.data.settings[i].settingsValue != undefined) {
                        setDepartments(settingsResponse.data.settings[i].settingsValue.split(","));
                    }
                    
                }
                if (settingsResponse.data.settings[i].settingsKey === "EnableAutoSuggestion") {
                    setEnableAutoSuggestion(settingsResponse.data.settings[i].settingsValue)
                }                                    
                if (settingsResponse.data.settings[i].settingsKey === "EnableVideoCall") {
                    setEnableVideoCall(settingsResponse.data.settings[i].settingsValue)
                }
                if (settingsResponse.data.settings[i].settingsKey === "EnableSentimentCalculation") {
                    setEnableSentimentCalculation(settingsResponse.data.settings[i].settingsValue)
                }
                if (settingsResponse.data.settings[i].settingsKey === "SentimentCalculateEveryXMessages") {
                    setSentimentCalculateEveryXMessages(settingsResponse.data.settings[i].settingsValue)
                }
            }
            setApplicationSettings(settingsObj)
        } catch (error) {
            console.log(error, "Error Message");
        }

        var requestBody = {
            userId: userId.toLowerCase(), //"525d38a8-fad8-468e-8d4b-315728a9f045"
        };
        console.log(requestBody);
        InvokePostServiceCall(
            apiConfig.GET_USER_INFO,
            requestBody,
            token
        )
            .then((data) => {
                if (data == null || data.data == null || data.data.azureAdid == null) {
                    dataService.setData({ headerDrawer: false });
                    navigate("/UnAuthorized", { state: { message: "You are not Authorized to use this Application!!" } });
                }
                else {
                    if (window.Microsoft != null && window.Microsoft.CIFramework != null) {
                        window.Microsoft.CIFramework.getEnvironment().then((result) => {
                            var data = JSON.parse(result);
                            if (data != null && data.customParams == 'CRM') {
                                setCrmMode("true");
                            }
                        }, (error) => { });

                    }
                    setTemplatesData(data.data.templates);
                    setTokens(data.data.tokens)
                    // navigate("/");
                    var userInfo = {
                        azureAdid: data.data.azureAdid, // AzureId of Logged in user
                        userId: data.data.userId,
                        teamId: data.data.teamId, // Loggedin user's team id
                        msgSupportNumber: data.data.msgSupportNumber, // Phone number used for sending and receiving messages , this number belongs to the user's team.
                        userName: data.data.userName, // Logged in user Name,
                        firstName: data.data.firstName,
                        lastName: data.data.lastName,
                        wsURL: data.data.wsURL, // Url sent from Azure to connect to pubsub
                        role: data.data.role, // Role of the logged in user
                        teamName: data.data.teamName, // Name of the Loggedin user's team
                        scrmUrl: data.data.scrmURL,//Url for the loading CIF
                        crmUrl: data.data.crmURL, //Url for the loading CIF
                        status: data.data.userStatus,
                        teams: data.data.teams,
                        allTeams: data.data.allTeams,
                        systemUserId: data.data.systemUserId,
                        crmMemberFields: new Map(Object.entries(data.data.crmMemberFields)),
                        generateDocuSignToken: data.data.generateDocuSignToken,
                        userAttributes: data.data.userAttributes,
                        department: data.data.department != undefined ? data.data.department : "",
                        isGPTSummaryEnabled: data.data.isGPTSummaryEnabled,
                        isDashboardEnabled: data.data.isDashboardEnabled,
                        profileImg: data.data.profileImg,
                        isSurveyEnabledAppSettings: data.data.isSurveyEnabled, //enabled survey settings
                        isTemplateApprovalEnabled: data.data.isTemplateApprovalEnabled, //enabled Template approval flow settings
                    };

                    var messageApiToken = token;
                    var preferredTeam = null;
                    setMessageApiToken(messageApiToken);
                    if (data.data.userPreferences !== "") {
                        try {
                            let userPreferences = JSON.parse(data.data.userPreferences);
                            if (userPreferences != null) {
                                setUsersFilterData({
                                    firstName: userPreferences.UsersFilter.firstName,
                                    lastName: userPreferences.UsersFilter.lastName,
                                    phone: userPreferences.UsersFilter.phone,
                                    email: userPreferences.UsersFilter.email,
                                    isActive: userPreferences.UsersFilter.isActive,
                                    teamId: userPreferences.UsersFilter.teamId,
                                });
                                setContactsFilterData({
                                    firstName: userPreferences.ContactsFilter.firstName,
                                    lastName: userPreferences.ContactsFilter.lastName,
                                    phone: userPreferences.ContactsFilter.phone,
                                    email: userPreferences.ContactsFilter.email,
                                    teamId: userPreferences.ContactsFilter.teamId,
                                });
                                //contacts list filter setting state value
                                setContactsListFilterData({
                                    status: userPreferences.ContactsListFilter.status,
                                    firstName: userPreferences.ContactsListFilter.firstName,
                                    lastName: userPreferences.ContactsListFilter.lastName,
                                    phone: userPreferences.ContactsListFilter.phone,
                                    fromDate: userPreferences.ContactsListFilter.fromDate,
                                    toDate: userPreferences.ContactsListFilter.toDate
                                });
                                var Preferteamexist = userInfo.teams.filter((item) => item.teamId === userPreferences.DefaultTeamId)
                                if (Preferteamexist.length > 0)
                                    preferredTeam = userPreferences.DefaultTeamId;
                                else
                                    preferredTeam = userInfo.teams[0]?.teamId;
                                if (!isObjectEmpty(userPreferences.UsersFilter)) {
                                    setLoadUserFilter(true);
                                }
                                if (!isObjectEmpty(userPreferences.ContactsFilter)) {
                                    setLoadContactFilter(true);
                                }
                                if (!isObjectEmpty(userPreferences.ContactsListFilter)) {
                                    setLoadContactsListFilter(true);
                                }
                            }
                        } catch (e) {
                            setUsersFilterData({
                                firstName: "",
                                lastName: "",
                                phone: "",
                                email: "",
                                isActive: "",
                                teamId: "",
                            });
                            setContactsFilterData({
                                firstName: "",
                                lastName: "",
                                phone: "",
                                email: "",
                                teamId: "",
                            });
                            //contacts list filter resetting state
                            setContactsListFilterData({
                                status: "",
                                firstName: "",
                                lastName: "",
                                phone: "",
                                fromDate: "",
                                toDate: ""
                            });
                        }
                    }
                    if (preferredTeam != null) {    //If user have multiple teamspreferred team
                        userInfo.teamId = preferredTeam;
                    }

                    if(userInfo.role==="Reviewer"){
                        navigate("/")
                        setSetupCollapse(false);
                    }
                    setUserInfo({ ...userInfo });
                }

            })
            .catch((error) => {
                console.log("Error in AuthContxt" + error.message);
            });
        InvokePostServiceCall(
            apiConfig.GET_NOTIFICATIONS,
            requestBody,
            token
        )
            .then((data) => {
                let unAssignedList = [];
                data.data.notifications.forEach((item, index) => {
                    if (item.notificationType === UnAssignedAdd) {
                        unAssignedList.push(item);
                    }
                })
                setunAssignedConvData(unAssignedList);
            })
            .catch((error) => {
                console.log(error, "notification error");
            });
    }
}
    const isObjectEmpty = (obj) => {
        if (obj != null) {
            for (var i = 0;
                i < Object.values(obj).length;
                i++) {
                if (Object.values(obj)[i] != "" && Object.values(obj)[i] != null) {
                    return false;
                }
            }
        }
        return true;
    };

    return (
        <AuthContext.Provider
            value={{
                ProfileImgBase64, setProfileImgBase64,
                setupCollapse, setSetupCollapse,
                userInfo,
                updateappContext,
                crmMode, setCrmMode,
                crmEmbededView, setCrmEmbededView,
                messageApiToken,
                setMessageApiToken,
                searchConvOpen,
                setSearchConvOpen,
                selectedConvId,
                setSelectedConvId,
                selectedConv,
                setSelectedConv,
                selectedMsgId, setSelectedMsgId,
                newConvDetails,
                setNewConvDetails,
                drawerNotificationCount,
                setDrawerNotificationCount,
                headerNotification,
                setHeaderNotification,
                notiFicationFilter,
                setNotificationFilter,
                matches,
                unAssignedConvData,
                setunAssignedConvData,
                showNotificationIndicator,
                setShowNotificationIndicator,
                statusChanged, setStatusChanged,
                decision, setDecision,
                msgLoadingState, setMsgLoadingState,
                inactiveConversations, setInactiveConversations,
                collapseInactive, setCollapseInactive,
                inactiveConvCount, setInactiveConvCount,
                contactsFilterData, setContactsFilterData,
                contactsListFilterData, setContactsListFilterData,
                usersFilterData, setUsersFilterData,
                loadUserFilter, setLoadUserFilter,
                loadContactFilter, setLoadContactFilter,
                loadContactsListFilter, setLoadContactsListFilter,
                openDrawer, setOpenDrawer,
                activityDueDataHours, showDrawer, setShowDrawer,
                assignedConvId, setAssignedConvId,
                openActive, setOpenActive,
                convLoading, setConvLoading,
                userInfo, setUserInfo,
                showDocuSignMessage, setShowDocuSignMessage,
                applicationSettings, setApplicationSettings,
                openConversationDrawer, setOpenConversationDrawer,
                isTokenAvailable, setIsTokenAvailable,
                selectedMenu, setSelectedMenu,
                prevSelectedMenu,setPrevSelectedmenu,
                templatesData,
                setTemplatesData,
                docuSignCode, setDocuSignCode,
                hideNewConvMenu, setHideNewConvMenu,
                tokens, setTokens,
                crmProvider, setCrmProvider,
                inactiveConvCountByTeams, setInactiveConvCountByTeams,
                departments, setDepartments,
                enableAutoSuggestion,enableVideoCall,
                sentiment,setSentiment,
                enableSentimentCalculation,setEnableSentimentCalculation,
                sentimentCalculateEveryXMessages,setSentimentCalculateEveryXMessages,
                conversationSummaryWithGpt, setConversationSummaryWithGpt,
            }}
            //
        >

            {props.children}
            <div>
                
                {(window.self !== window.top && crmProvider === "S") ?

                    <Helmet>
                        <script type="text/javascript" src={userInfo.scrmUrl} ></script>
                    </Helmet>
                    :
                    <Helmet>

                    </Helmet>
                }
                
            </div>
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;