/***************
* 11-11/2022 TextField:766=>disabled auto suggetion
***********/
import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  TextField,
  Button,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import { useLocation, useNavigate } from "react-router-dom";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import apiConfig from "../../api/apiConfig";
import { AuthContext } from "../../AuthContext";
import moment from "moment";
import SnackBar from "../../components/SnackBar";

function AddUpdateHoliday() {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { messageApiToken } = useContext(AuthContext);
  const isAddHoliday = location.state
    ? location.state.isAddHoliday
    : true;
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [snackbarMsg, setSnackBarMsg] = useState("");
  const [dateErr, setDateErr] = useState({
    holidayDateErr: false

  });
  const [holidayData, setHolidayData] = useState({
    HolidaysId: "00000000-0000-0000-0000-000000000000",
    HolidayName: "",
    HolidayDate: "",
    isActive: true,
  });
  const [modifiedBy, setModifiedBy] = useState({
    lastModifiedByName: "",
    lastModifiedDate: "",
  });
  const [createdBy, setCreatedBy] = useState({
    createdByName: "",
    createdDate: "",
  });

  useEffect(() => {
    if (!isAddHoliday) {
      setLoading(true);
      if (messageApiToken) {
        InvokePostServiceCall(apiConfig.GET_HOLIDAYS, {
          HolidayId:
            location.state.HolidayDetails.holidaysId,
        })
          .then((data) => {
            debugger;
            let holidayDetails = data.data.holidayDetails[0];
            setHolidayData({
              HolidaysId:
                holidayDetails.holiday.holidaysId,
              HolidayName:
                holidayDetails.holiday.holidayName,
              HolidayDate: moment(holidayDetails.holiday.holidayDate).format("YYYY-MM-DD"),
              isActive: holidayDetails.holiday.isActive,
            });
            setModifiedBy({
              lastModifiedByName:
                holidayDetails.holiday.lastModifiedByName,
              lastModifiedDate: moment(
                holidayDetails.holiday.lastModifiedDate
              )
                .local()
                .format("LLL"),
            });
            setCreatedBy({
              createdByName:
                holidayDetails.holiday.createdByName,
              createdDate: moment(
                holidayDetails.holiday.createdDate
              )
                .local()
                .format("LLL"),
            });
            setLoading(false);
          })
          .catch((error) => {
            setSnackBarMsg(error.message);
            setSeverity("error");
            setOpenSnackbar(true);
            setLoading(false);
            console.log(error, "Error Message");
          });
      }
    }
  }, [messageApiToken]);

  const handleAddUpdate = () => {
    setLoadingBtn(true);
    let requestBody = {
      Holiday: {
        HolidaysId: holidayData.HolidaysId,
        HolidayName: holidayData.HolidayName,
        HolidayDate: holidayData.HolidayDate,
        isActive: holidayData.isActive,
      },
      actionType: isAddHoliday ? "Add" : "Update",
    };

    InvokePostServiceCall(apiConfig.SAVE_HOLIDAYS, requestBody)
      .then((data) => {
        setLoadingBtn(false);
        setSnackBarMsg(
          isAddHoliday
            ? "Holiday Added Successfully"
            : "Holiday Updated Successfully"
        );
        setSeverity("success");
        setOpenSnackbar(true);
      })
      .catch((error) => {
        setLoadingBtn(false);
        setSnackBarMsg(error.message);
        setSeverity("error");
        setOpenSnackbar(true);
        console.log(error, "Error Message");
      });
  };

  const handleChange = (event) => {
    setHolidayData({
      ...holidayData,
      isActive: event.target.checked,
    });
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    if (severity === "success") {
      navigate("/holidays", { replace: true });
    }
    setOpenSnackbar(false);
  };

  return (
    <>
      <SnackBar
        openSnackbar={openSnackbar}
        handleCloseSnackbar={handleCloseSnackbar}
        severity={severity}
        userMessage={snackbarMsg}
      />
      <div style={{ marginLeft: 20, marginRight: 20 }}>
        {loading && !isAddHoliday ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15%",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <h2>
              {isAddHoliday
                ? "Add Holiday"
                : "Update Holiday"}
            </h2>
            <Box sx={theme.customStyle.textRow}>
              <TextField
                size="small"
                margin="dense"
                label="Holiday Name"
                type="text"
                required
                fullWidth
                id="HolydayName"
                value={holidayData.HolidayName}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 255, autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                onChange={(e) => {
                  setHolidayData({
                    ...holidayData,
                    HolidayName: e.target.value,
                  });
                }}
              />
            </Box>
            <Box sx={theme.customStyle.textRow}>


              <TextField
                error={dateErr.holidayDateErr}
                variant="standard"
                fullWidth
                size="small"
                type={"date"}
                id="HolidayDate"
                value={holidayData.HolidayDate}
                InputLabelProps={{
                  shrink: true,
                  max: "9999-12-31"
                }}
                inputProps={{ //not show suggetions
                  autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                label="Holiday Date *"
                onChange={(e) => {
                  if (e.target.value == "") {
                    setHolidayData({
                      ...holidayData,
                      HolidayDate: "",
                    });
                  }
                  const hoydate = new Date(e.target.value)
                  const limit = new Date("9999-12-31")
                  if (hoydate < limit) {
                    setHolidayData({
                      ...holidayData,
                      HolidayDate: e.target.value,
                    });
                    setDateErr({ ...dateErr, holidayDateErr: false });
                  }
                }}
                helperText={dateErr.holidayDateErr ? "Enter Holiday Date" : ""}
              />
            </Box>
            <Box>
              <span>Is Active</span>
              <Checkbox
                checked={holidayData.isActive}
                onChange={handleChange}
              />
            </Box>
            {!isAddHoliday && (
              <>
                <Box sx={theme.customStyle.textRow}>
                  <TextField
                    size="small"
                    margin="dense"
                    disabled
                    value={`${createdBy.createdByName +
                      " (" +
                      createdBy.createdDate +
                      ")"
                      }`}
                    id="HolydaycreatedByName"
                    label="Created By"
                    type="text"
                    fullWidth
                    variant="standard"
                    inputProps={{ //not show suggetions
                      autoComplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
                <Box sx={theme.customStyle.textRow}>
                  <TextField
                    size="small"
                    margin="dense"
                    disabled
                    id="HolydaylastModifiedByName"
                    value={`${modifiedBy.lastModifiedByName +
                      " (" +
                      modifiedBy.lastModifiedDate +
                      ")"
                      }`}
                    label="Modified By"
                    type="text"
                    fullWidth
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ //not show suggetions
                      autoComplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                </Box>
              </>
            )}
            <div style={{ marginTop: "20px", marginBottom: "20px" }}>
              <LoadingButton
                disabled={
                  holidayData.HolidayName &&
                    holidayData.HolidayDate
                    ? false
                    : true
                }
                loading={loadingBtn}
                variant="contained"
                onClick={() => {
                  handleAddUpdate();
                }}
              >
                {isAddHoliday ? "Add" : "Update"}
              </LoadingButton>
              <Button
                sx={{ marginLeft: 2 }}
                variant="contained"
                onClick={() => {
                  navigate("/holidays");
                }}
              >
                Cancel
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default AddUpdateHoliday;
