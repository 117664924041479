/**
 * 08-04-2023 => SUDAM B CHAVAN => reset page index to 1 page on filter data
* 08-29-2023 => ONKAR => TEXTING-1578 -REACT Upgrade  
* 08-30-2023 => ONKAR => TEXTING-1578 -REACT Upgrade  
*/
import React, { useState, useEffect, useContext } from "react";
import { Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import apiConfig from "../../api/apiConfig";

import { AuthContext } from "../../AuthContext";
import { useTheme } from "@mui/material/styles";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import moment from "moment";
import SnackBar from "../../components/SnackBar"
import {Checkbox} from "@mui/material";
import { TextField,InputAdornment } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import { StyledDataGrid } from "../../components/styledComponents/StyledDataGrid";


const columns = [
    {
        field: "tokenName",
        headerName: "Token Name",
        flex: 1,
        minWidth: 100,
        headerClassName: "super-app-theme--header",
    },
    {
        field: "tableName",
        headerName: "Table Name",
        flex: 1,
        minWidth: 100,
        headerClassName: "super-app-theme--header",
    },
    {
        field: "fieldName",
        headerName: "Field Name",
        flex: 1,
        minWidth: 100,
        headerClassName: "super-app-theme--header",
    },
    {
        field: "createdDate",
        headerName: "Created Date",
        flex: 1,
        minWidth: 100,
        headerClassName: "super-app-theme--header",
    },
    {
        field: "lastModifiedDate",
        headerName: "Modified Date",
        flex: 1,
        minWidth: 100,
        headerClassName: "super-app-theme--header",
    },
    {
        field: "isActive",
        headerName: "Is Active",
        flex: 1,
        minWidth: 50,
        filterable: false,
  
        headerClassName: "super-app-theme--header",
        renderCell: (params) => {
          return (
            <div style={{ marginLeft: "5px" }}>
              <Checkbox disabled checked={params.row.isActive} />
            </div>
          );
        },
    },
];

const TokensPage = () => {
    const theme = useTheme();
    const { messageApiToken } = useContext(AuthContext);
    const [tokenList, setTokenList] = useState([]);
    const [tokensArr, setTokenArr] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [page, setPage] = useState(0)
    useEffect(() => {
        if(messageApiToken){
        setLoading(true);
        InvokePostServiceCall(apiConfig.GET_TOKENS, {})
            .then((data) => {
                let tokensArr = [];
                console.log(data, "TokensData");
                if (data.data.tokens.length > 0) {
                    data.data.tokens.forEach((item, index) => {
                        tokensArr.push({
                            createdBy: item.createdBy,
                            createdByName: item.createdByName,
                            createdByNavigation: item.createdByNavigation,
                            createdDate: moment(item.createdDate).local().format("MM-DD-YYYY"),
                            createdDateTime:item.createdDate,
                            fieldName: item.fieldName,
                            filterType: item.filterType,
                            isActive: item.isActive,
                            lastModifiedBy: item.lastModifiedBy,
                            lastModifiedByName: item.lastModifiedByName,
                            lastModifiedByNavigation: item.lastModifiedByNavigation,
                            lastModifiedDate: moment(item.lastModifiedDate).local().format("MM-DD-YYYY"),
                            lastModifiedDateTime:item.lastModifiedDate,
                            relatedFieldName: item.relatedFieldName,
                            relatedTableName: item.relatedTableName,
                            tableName: item.tableName,
                            tokenId: item.tokenId,
                            tokenName: item.tokenName,
                            id: index,
                        });
                    });
                    setTokenList(tokensArr);
                    setTokenArr(tokensArr);
                }
                setLoading(false);
            })
            .catch((error) => {
                setSnackbarMsg(error.message)
                console.log(error);
                setOpenSnackbar(true);
                setLoading(false);
            });
        }
    }, [messageApiToken]);
    const navigate = useNavigate();

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setOpenSnackbar(false);
    };
    const filterTable = (value) => {
        let usArr = [];
        setPage(0);
        let val = value?.toLowerCase();
        tokensArr.forEach((item) => {

            if (
                item.tokenName?.toLowerCase().includes(val) ||
                item.tableName?.toLowerCase().includes(val) ||
                item.fieldName?.toLowerCase().includes(val) ||
                item.createdDate?.toLowerCase().includes(val) ||
                item.lastModifiedDate?.toLowerCase().includes(val) 
            ) {
                usArr.push(item);
            }
        });
        setTokenList(usArr);
    }
    return (
        <>
          <SnackBar
            openSnackbar={openSnackbar}
            handleCloseSnackbar={handleCloseSnackbar}
            severity={"error"}
            userMessage={snackbarMsg}
          />
            <div>
                <Box sx={theme.customStyle.tableRow}>
                    <h2 style={{ marginLeft: 10 }}>Tokens</h2>
                </Box>
             </div>
            <div style={{ display: "flex", alignItems: "flex-start", paddingLeft: 10 }}>
                <Box sx={theme.customStyle.tableRow}>
                        <Button
                            sx={theme.customStyle.modalbtn}
                            onClick={() => {
                                navigate("/addUpdateToken", {
                                    state: { isAddToken: true },
                                });
                            }}
                        >
                            <AddIcon />
                        </Button>
                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <TextField
                                sx={{ marginRight: "10px", marginBottom: "10px" }}
                                size="small"
                                type="search"
                                variant="outlined"
                                margin="normal"
                                id="outlined-search"
                                label=""
                                placeholder="Tokens Search"
                                autoComplete="off"
                                onChange={(e) => {
                                    if (e.target.value) {
                                        filterTable(e.target.value);
                                    }
                                    else {
                                        setTokenList(tokensArr);
                                    }

                                }}
                                InputLabelProps={{
                                    shrink: false,
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />
                    </Box>
                </Box>
                </div>
                    
            <Box
                sx={theme.customStyle.tableHead}
            >
                <StyledDataGrid
                    loading={loading}
                    rows={tokenList}
                    columns={columns}
                    pageSize={10}
                    page={page}
                    onPageChange={(page) => setPage(page)}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                    components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                    }}
                    onRowDoubleClick={(e)=>{
                        navigate("/addUpdateToken/"+e.row.tokenId,{
                            state:{isAddToken:false,tokenDetails:e.row}
                        })
                    }}
                    // initialState={{
                    //     sorting: {
                    //       sortModel: [{ field: 'lastModifiedDate', sort: 'desc' }],
                    //     },
                    //   }}
                />
            </Box>
        </>
    );
};


export default TokensPage;