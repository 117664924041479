/*
* 11-27-2023 => SUDAM B CHAVAN => TEXTING-1872 Chat Survey Questions Configuration - Admin UI
*12-04-2023 => SUDAM B CHAVAN =>TEXTING-1896 Survey: Member UI, Admin UI pending observations.
*12-11-2023 => SUDAM B CHAVAN =>TEXTING-1891 0: Survey : Update Questions Admin UI to add options and save , separated in backend
*/
import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import apiConfig from "../../api/apiConfig";
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../AuthContext";
import { useTheme } from "@mui/material/styles";
import { InvokeGetServiceCall } from "../../api/serviceUtil";
import SnackBar from "../../components/SnackBar";
import { IconButton, TextField, InputAdornment } from "@mui/material";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import { StyledDataGrid } from "../../components/styledComponents/StyledDataGrid";
import moment from "moment";

export default function SurveyQuestionsPage() {
    const navigate = useNavigate();
    const theme = useTheme();
    const { messageApiToken } = useContext(AuthContext);
    const [surveyQuestions, setSurveyQuestions] = useState([]);
    const [surveyQuestionsArr, setSurveyQuestionsArr] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState(false);
    const [refresh, setRefresh] = useState(true);
    const [page, setPage] = useState(0)
    const [searchText, setSearchText] = useState([]);

    useEffect(() => {
        if (messageApiToken && refresh) {
            setSurveyQuestions([]);
            setLoading(true);
            InvokeGetServiceCall(apiConfig.GET_SURVEY_QUESTIONS)
                .then((data) => {
                    let surveyQuestionsArr = [];
                    console.log(data.data, "survey question data");
                    if (data.data.surveyQuestions.length > 0) {
                        data.data.surveyQuestions.forEach((item, index) => {
                            surveyQuestionsArr.push({
                               ...item,
                                createdDateFormat: moment(item.createdDate).local().format("MM-DD-YYYY"),
                                lastModifiedDateFormat: moment(item.lastModifiedDate).local().format("MM-DD-YYYY"),
                                id: index,
                            });
                        });
                    }
                    setSurveyQuestions(surveyQuestionsArr);
                    setSurveyQuestionsArr(surveyQuestionsArr)
                    setRefresh(false);
                    setLoading(false);
                })
                .catch((err) => {
                    console.log(err, "errorData");
                    setSnackbarMsg(err.message);
                    setOpenSnackbar(true);
                    setLoading(false);
                });
        }
    }, [messageApiToken, refresh]);
    const columns = [
        {
            field: "questionName",
            headerName: "Question Name",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "questionBody",
            headerName: "Question Body",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "answerRequired",
            headerName: "Answer Required",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
         {
             field: "questionType",
             headerName: "Question Type",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
         {
             field: "options",
             headerName: "Options",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
            valueGetter: (params) => params.row.options?.replaceAll('##SEP##',','),
        },
        {
            field: "createdDateFormat",
            headerName: "Created Date",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "lastModifiedDateFormat",
            headerName: "Modified Date",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
    ];
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    function filterTable(value) {
        let tempArr = [];
        setPage(0);
        let val = value.toLowerCase();
        debugger
        surveyQuestionsArr.forEach((item) => {
            if (
                item.questionName?.toLowerCase().includes(val) ||
                item.questionBody?.toLowerCase().includes(val) ||
                item.questionType?.toLowerCase().includes(val) ||
                item.createdDateFormat?.toLowerCase().includes(val) ||
                item.lastModifiedDateFormat?.toLowerCase().includes(val)||
                item.options?.toLowerCase().includes(val)
            ) {
                tempArr.push(item);
            }
        });
        setSurveyQuestions(tempArr);
    }
    return (
        <>
            <SnackBar
                openSnackbar={openSnackbar}
                handleCloseSnackbar={handleCloseSnackbar}
                severity={"error"}
                userMessage={snackbarMsg}
            />
            <Box sx={theme.customStyle.tableRow}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <h2 style={{ marginLeft: 10 }}>Survey Questions</h2>
                </div>
            </Box>
            <div style={{ display: "flex", alignItems: "flex-start", paddingLeft: 10 }}>
                <Box sx={theme.customStyle.tableRow}>
                    <IconButton
                        sx={theme.customStyle.teamsAddIconBtn}
                        onClick={() => {
                            navigate("/addUpdateSurveyQuestions", {
                                state: { isAddSurveyQuestion: true },
                            });
                        }}
                    >
                        <AddIcon />
                    </IconButton>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>


                        <TextField
                            sx={{ marginRight: "10px", marginBottom: "10px", paddingdown: 5 }}
                            size="small"
                            type="search"
                            variant="outlined"
                            margin="normal"
                            id="outlined-search"
                            label=""
                            placeholder="Survey questions search"
                            autoComplete="off"
                            value={searchText}
                            onChange={(e) => {
                                setSearchText(e.target.value)

                                if (e.target.value) {
                                    filterTable(e.target.value);
                                } else {
                                    setSurveyQuestions(surveyQuestionsArr);
                                }
                            }}
                            InputLabelProps={{
                                shrink: false,
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                </Box>
            </div>
            <Box sx={theme.customStyle.tableHead}>
                <StyledDataGrid
                    loading={loading}
                    rows={surveyQuestions}
                    columns={columns}
                    pageSize={10}
                    page={page}
                    disableSelectionOnClick
                    components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                    }}
                    onPageChange={(page) => setPage(page)}
                    rowsPerPageOptions={[10]}
                    onRowDoubleClick={(e) => {
                        navigate("/addUpdateSurveyQuestions/" + e.row.surveyQuestionId, {
                            state: { isAddSurveyQuestion: false, surveyQuestion: e.row },
                        });
                    }}
                    columnVisibilityModel={{ history: false }} //hide the columns from the grid
                // initialState={{
                //   sorting: {
                //     sortModel: [{ field: "lastModifiedDateFormat", sort: "desc" }],
                //   },
                // }}
                />
            </Box>
        </>
    );
}
