/***************
*11-11/2022 TextField:766=>disabled auto suggetion
***********/
import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import SnackBar from "../../components/SnackBar";
import { useNavigate } from "react-router-dom";
import { Box, TextField, Button, CircularProgress, FormControl, Select, InputLabel, MenuItem } from "@mui/material";
import { AuthContext } from "../../AuthContext";
import { DataGrid } from "@mui/x-data-grid";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import apiConfig from "../../api/apiConfig";
import LoadingButton from "@mui/lab/LoadingButton";
import moment from "moment";

function AddUpdateBusinessHoursConfig() {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { messageApiToken } = useContext(AuthContext);
  let isAddBusinessHoursConfig = location.state
    ? location.state.isAddBusinessHoursConfig
    : true;
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [cellEditing, setCellEditing] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openTimeSnackbar, setOpenTimeSnackbar] = React.useState(false);
  const [timeErrMsg, setTimeErrMsg] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [businessHoursConfigDetails, setBusinessHoursConfigDetails] = useState({
    businessHoursConfigId: "",
    configName: "",
    timeZone: "",
    createdBy: "",
    lastModifiedBy: "",
  });
  const [modifiedBy, setModifiedBy] = useState({
    lastModifiedByName: "",
    lastModifiedDate: "",
  });
  const [createdBy, setCreatedBy] = useState({
    createdByName: "",
    createdDate: "",
  })
  const [businessHours, setBusinessHours] = useState([]);
  const days = {
    'Monday': 1, 'Tuesday': 2, 'Wednesday': 3, 'Thursday': 4, 'Friday': 5, 'Saturday': 6,
    'Sunday': 7
  };

  const columns = [
    {
      field: "day",
      headerName: "Day",
      flex: 1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "startTime",
      headerName: "Start Time",
      flex: 1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
      editable: true,
    },
    {
      field: "endTime",
      headerName: "End Time",
      flex: 1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
      editable: true,
    },
  ];

  useEffect(() => {
    if (messageApiToken) {
      if (!isAddBusinessHoursConfig) {
        let requestBody = {
          businessHoursConfigId:
            location.state.businessHourConfigDetails.businessHoursConfigId,
        };
        InvokePostServiceCall(apiConfig.GET_BUSINESS_HOURS_CONFIG, requestBody)
          .then((data) => {
            let businessHoursConfig =
              data.data.businessHoursConfigDetails[0].businessHoursConfig;
            let businessHours =
              data.data.businessHoursConfigDetails[0].businessHours;
            let tempArr = [];
            let tempArrOne = [];
            setBusinessHoursConfigDetails({
              configName: businessHoursConfig.configName,
              timeZone: businessHoursConfig.timeZone,
              createdBy: businessHoursConfig.createdByName,
              lastModifiedBy: businessHoursConfig.lastModifiedByName,
              businessHoursConfigId: businessHoursConfig.businessHoursConfigId,
            });
            setModifiedBy({
              lastModifiedByName: businessHoursConfig.lastModifiedByName,
              lastModifiedDate: moment(businessHoursConfig.lastModifiedDate).local().format("LLL"),
            })
            setCreatedBy({
              createdByName: businessHoursConfig.createdByName,
              createdDate: moment(businessHoursConfig.createdDate).local().format("LLL")
            })
            businessHours.forEach((item, index) => {
              tempArr.push({
                businessHoursConfigId: item.businessHoursConfigId,
                businessHoursId: item.businessHoursId,
                day: item.day,
                endTime: item.endTime,
                startTime: item.startTime,
              });
            });
            tempArr.sort((a, b) => {
              return days[a.day] - days[b.day];
            });
            tempArr.forEach((item, index) => {
              item.id = index;
              tempArrOne.push(item);
            })
            setBusinessHours(tempArrOne);
            setLoading(false);
          })
          .catch((error) => {
            setSeverity("error");
            setSnackbarMsg(error.message);
            setOpenSnackbar(true);
            console.log(error, "Error Message");
            setLoading(false);
          });
      }
    }
  }, [messageApiToken]);

  function saveBusinessHoursConfig() {
    let timeErr = false;
    for (let i = 0; i < businessHours.length; i++) {
      let startTime = businessHours[i].startTime.replace(":", "");
      let endTime = businessHours[i].endTime.replace(":", "");
      let timeDiff =
        parseInt(endTime) -
        parseInt(startTime);
      if (timeDiff < 1 && businessHours[i].startTime !== "00:00") {
        timeErr = true;
        setTimeErrMsg("Start time must be less than end time.");
        setOpenTimeSnackbar(true);
        break;
      }
    }
    if (!timeErr) {
      setBtnLoading(true);
      let businessHoursArr = [];
      businessHours.forEach((item) => {
        businessHoursArr.push({
          businessHoursConfigId: item.businessHoursConfigId,
          businessHoursId: item.businessHoursId,
          day: item.day,
          endTime: item.endTime,
          startTime: item.startTime,
        });
      });
      let requestBody = {
        businessHoursConfigInfo: {
          businessHoursConfig: {
            configName: businessHoursConfigDetails.configName,
            timeZone: businessHoursConfigDetails.timeZone,
          },
          actionType: isAddBusinessHoursConfig ? "Add" : "Update",
          businessHours: businessHoursArr,
        },
      };
      if (!isAddBusinessHoursConfig) {
        requestBody.businessHoursConfigInfo.businessHoursConfig.businessHoursConfigId =
          businessHoursConfigDetails.businessHoursConfigId;
      }

      InvokePostServiceCall(apiConfig.SAVE_BUSINESS_HOURS_CONFIG, requestBody)
        .then((data) => {
          console.log(data, "dasdads");
          setSeverity("success")
          setSnackbarMsg(isAddBusinessHoursConfig ? "Business Hours Config Added Successfully." : "Business Hours Config Updated Successfully.")
          setBtnLoading(false);
          setOpenSnackbar(true);
        })
        .catch((error) => {
          console.log(error, "Error Message");
          setSeverity("error")
          setSnackbarMsg(error.message);
          setOpenSnackbar(true);
          setBtnLoading(false);
        });
    }
  }
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    if (severity === "success") {
      navigate("/businessHoursConfig", { replace: true });
    }
    setOpenSnackbar(false);
  };
  const handleCloseTimeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenTimeSnackbar(false);
  };
  const handleTimezone = (event) => {
    setBusinessHoursConfigDetails({
      ...businessHoursConfigDetails,
      timeZone: event.target.value,
    });
  };
  return (
    <>
      <SnackBar
        openSnackbar={openSnackbar}
        handleCloseSnackbar={handleCloseSnackbar}
        severity={severity}
        userMessage={snackbarMsg}
      />
      <SnackBar
        openSnackbar={openTimeSnackbar}
        handleCloseSnackbar={handleCloseTimeSnackbar}
        severity={"error"}
        userMessage={timeErrMsg}
      />

      <div style={{ marginLeft: 20, marginRight: 20 }}>
        {loading && !isAddBusinessHoursConfig ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15%",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <h3>
              {isAddBusinessHoursConfig
                ? "Add Business Hours Config"
                : "Update Business Hours Config"}
            </h3>
            <Box sx={theme.customStyle.textRow}>
              <TextField
                size="small"
                margin="dense"
                label="Config Name *"
                type="text"
                fullWidth
                id="businessHoursConfigDetailsconfigName"
                value={businessHoursConfigDetails.configName}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ //not show suggetions
                  autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                onChange={(e) => {
                  setBusinessHoursConfigDetails({
                    ...businessHoursConfigDetails,
                    configName: e.target.value,
                  });
                }}
              />
            </Box>
            <Box sx={theme.customStyle.textRow}>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <InputLabel shrink={true}>Time Zone *</InputLabel>
                <Select
                  value={businessHoursConfigDetails.timeZone}
                  onChange={handleTimezone}
                  label="Time Zone"
                >
                  <MenuItem value={"Pacific Standard Time"}>
                    Pacific Standard Time
                  </MenuItem>
                  <MenuItem value={"Mountain Standard Time"}>
                    Mountain Standard Time
                  </MenuItem>

                  <MenuItem value={"Central Standard Time"}>
                    Central Standard Time
                  </MenuItem>
                  <MenuItem value={"Eastern Standard Time"}>
                    Eastern Standard Time
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            {!isAddBusinessHoursConfig && (
              <>
                <Box sx={theme.customStyle.textRow}>
                  <TextField
                    size="small"
                    margin="dense"
                    label="Created By"
                    disabled
                    type="text"
                    value={`${createdBy.createdByName + " (" + createdBy.createdDate + ")"}`}
                    fullWidth
                    id="businessHoursConfigDetailscreatedDate"
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,

                    }}
                    InputProps={{
                      autoComplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                </Box>
                <Box sx={theme.customStyle.textRow}>
                  <TextField
                    size="small"
                    margin="dense"
                    label="Modified By"
                    disabled
                    type="text"
                    id="businessHoursConfigDetailslastModifiedByName"
                    value={`${modifiedBy.lastModifiedByName + " (" + modifiedBy.lastModifiedDate + ")"}`}
                    InputProps={{
                      autoComplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                    fullWidth
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>

                <div>
                  <div
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    <h5>Business Hours List</h5>
                  </div>
                  <Box
                    sx={{ ...theme.customStyle.teamsTableHead, height: 422 }}
                  >
                    <DataGrid
                      rows={businessHours}
                      columns={columns}
                      pageSize={7}
                      rowsPerPageOptions={[7]}
                      hideFooter={true}
                      disableSelectionOnClick
                      onCellEditStart={() => {
                        setCellEditing(true);
                      }}
                      onCellEditCommit={(params) => {
                        let isValid = moment(params.value, "HH:mm", true).isValid();
                        if (isValid && params.value !== "24:00") {
                          if (params.field === "startTime") {
                            businessHours[params.id].startTime = params.value;
                          } else {
                            businessHours[params.id].endTime = params.value;
                          }
                          setCellEditing(false);
                        } else {
                          setTimeErrMsg("Please enter time in 24 hrs format only.");
                          setOpenTimeSnackbar(true);
                        }
                      }}
                    />
                  </Box>
                </div>
              </>
            )}

            <div style={{ marginTop: "20px", marginBottom: "20px" }}>
              <LoadingButton
                loading={btnLoading}
                disabled={
                  businessHoursConfigDetails.configName &&
                    businessHoursConfigDetails.timeZone &&
                    !cellEditing
                    ? false
                    : true
                }
                variant="contained"
                onClick={() => {
                  saveBusinessHoursConfig();
                }}
              >
                {isAddBusinessHoursConfig ? "Add" : "Update"}
              </LoadingButton>
              <Button
                sx={{ marginLeft: 2 }}
                variant="contained"
                onClick={() => {
                  navigate("/businessHoursConfig");
                }}
              >
                Cancel
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default AddUpdateBusinessHoursConfig;
