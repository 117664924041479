// 06-07-2023 => ONKAR => TEXTING-1314 => Icons in left panel is not highlighting automatically, after reject/auto reject the new message request.
// 12-08-2023 => ONKAR => TEXTING-1879 => Accepting the message request from setup dropdown page is not highlighting the active icon in left panel.
// 12-11-2023 => ONKAR => TEXTING-1915 => 0: Admin UI - Highlight color for icons in left panel is removing after reject or auto rejecting the new conversation request.
// 12-12-2023 => ONKAR => TEXTING-1879 => Accepting the message request from setup dropdown page is not highlighting the active icon in left panel.

import React, {useContext, useEffect} from "react";
import {
  ListItemButton,
  ListItemIcon,
  Tooltip,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FiberNewTwoToneIcon from '@mui/icons-material/FiberNewTwoTone';
import { AuthContext } from "../../AuthContext";
const NewConversationsNew=(props)=> {
  const theme = useTheme();
  const {
    prevSelectedMenu,setPrevSelectedmenu
  } = useContext(AuthContext);


  return (
    <>
      <ListItemButton
        selected={props.selectedMenu === "new"}
        sx={
          theme.customStyle.listItemIconDrawerClosedNew 
        }
        onClick={(e) => {
          props.openDrawer();
          props.setSelectedMenu((prev)=>{
             if(prev!=="new"){
            setPrevSelectedmenu(prev)
          }
          return 'new'
          });
          props.toggleConvDrawer(true, true)(e);
        }}
      >
        <Tooltip title="New Conversations">
          <ListItemIcon sx={theme.customStyle.listItemIcon} sel >
            <FiberNewTwoToneIcon
            iconSize={theme.customStyle.iconSize}
              sx={
                props.selectedMenu === "new"
                  ?  theme.customStyle.activeDrawerMenuDrawerClosed
                  : theme.customStyle.inActiveDrawerMenuDrawerClosed
              } />
            <Box sx={{...theme.customStyle.listItemLabelDiv,left:"2px"}}>
              <span style={{ fontSize: "10px" }}>New</span>
            </Box>
          </ListItemIcon>
        </Tooltip>
      </ListItemButton>
    </>
  );
}

export default NewConversationsNew;
