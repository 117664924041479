/***************
*11-11/2022 TextField:766=>disabled auto suggetion
12-13-2022 => ONKAR => Texting-898 CP: Template PreviewShow all the templates with tokens populated 
12-15-2022 => ONKAR => Texting-898 CP: Template PreviewShow all the templates with tokens populated reopen issue fixed. 
12-22-2022 =>ONKAR => Filter templates in insert templates component from 1st char entered.
12-26-2022 =>ONKAR => Removed the api call for templates.
01-20-2022 =>RAJESH =>Fixed issue with department rendering.
07-13-2022 =>SUDAM => TEXTING-1412 - Ability to add Team Name in Tokens
07-18-2022 =>SUDAM => TEXTING-1436 - Team Level Attributes to use in tokens
09-01-2023 => SUDAM B CHAVAN =>TEXTING-1645 - Reporting - Track templates used
03-05-2024 => ONKAR => Inserting the Template on active conversation is clearing out the existing messages on conversation page.
03-13-2024 =>  ONKAR => On accepting inbound conversation tokens are not available until refresh
07-18-2023 => SUDAM B CHAVAN =>When contact details are modified, token values data in insert and auto suggestion templates is not updating
***********/
import React, { forwardRef, useImperativeHandle, useContext, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
  Typography,
  TextField,
  DialogTitle,
  Divider,
  ListItem,
} from "@mui/material";
import { AuthContext } from "../AuthContext";


import { useState } from "react";
import { getLinkedEntityNames, openContextRecordInCRMWrapper } from "../extensions/crmWrapper";
import { GetTemplatesWithTokens } from "../extensions/TemplateConfigUtil";
import { dataService } from "../dataService";
import { sendEventToWebResource } from "../extensions/Util";

export const TemplateSelector = forwardRef((props, ref) => {
  const {
    tokens,
    userInfo,
    templatesData,crmProvider , crmMode
  } = useContext(AuthContext);
  const [templatesList, setTemplatesList] = useState([]);
  const [templatesListCopy, setTemplatesListCopy] = useState([]);
  const [emptyMessage, setEmptyMessage] = useState("");


  useImperativeHandle(ref, () => ({
    templateSelectorClick,
  }));
  const templateSelectorClick = () => {
    props.childState({ open: !props.state.open });
    props.childState({ templatePreviewText: null });
  };

  async function getTemplatesWithTokensReplaced(tempArr,tempData){
    let finalArr=[];
    tempArr.forEach(async (item) => {
      let templateBody = await GetTemplatesWithTokens(
        item,
        tempData,
        tokens,
        userInfo,
        props.state.conversation.teamId
      );
      finalArr.push({
          category: item.category,
          relatedObjects: item.relatedObjects,
          teamId: item.teamId,
          templateBody: templateBody,
          templateContext: item.templateContext,
          templateId:item.templateId ,
          templateName: item.templateName,
        });
    });
    return finalArr;
  }

  function loadTemplates(linkedEntities, conversationAttributes, templates, prmPropsMessageList, prmConversation){
    debugger
    var conv = Object.assign({}, prmConversation ? prmConversation : props.state.conversation);
    conv.linkedEntities = linkedEntities;
    conv.messageList = prmPropsMessageList ? prmPropsMessageList :props.messageList;
    conv.showNotification = false; //added to avoid unread conv notification on template selection
    dataService.setData({ updateConversation: conv }); //updated conversation object in store
   let tempArr = [];
    let templatesList = templates ? templates : templatesData;
    let filteredTemplatesListByTeam = templatesList.filter(
    (templateItem) =>
      templateItem.teamId.toLowerCase() ===
        conv.teamId.toLowerCase()
  );
  if (linkedEntities) {
    let tempData = JSON.parse(
      conversationAttributes
    );
    filteredTemplatesListByTeam.forEach((item) => {
      var includeTemplate = false;
      if (item.relatedObjects != undefined && item.relatedObjects) {
        let relatedObjectsArr = item.relatedObjects.split(",");
        if (relatedObjectsArr.every((r) => linkedEntities?.includes(r)) || (item.relatedObjects == null || item.relatedObjects == "")) {
          if ((userInfo.department == "" || (item.department == undefined || item.department == "" || userInfo.department == item.department))) {
            includeTemplate = true;
          }
        }
      }
      else if ((item.relatedObjects == null || item.relatedObjects == "") && (userInfo.department == "" || (item.department == undefined || item.department == "" || userInfo.department == item.department))) {
        includeTemplate = true;
      }
      if (includeTemplate == true) {
        tempArr.push(item);
      }

    });
    if (tempArr.length === 0) {
      setEmptyMessage("No Data Found");
    } else {
      setEmptyMessage("");
      getTemplatesWithTokensReplaced(tempArr, tempData)
        .then((finalArr) => {
          setTemplatesList(finalArr);
          setTemplatesListCopy(finalArr);
          if (finalArr.length === 0) {
            setEmptyMessage("No Data Found");
          } else {
            setEmptyMessage("");
          }
        })
        .catch((error) => {
          console.log(error, "error message");
        });
    }
  } else {
    setTemplatesList(filteredTemplatesListByTeam);
    setTemplatesListCopy(filteredTemplatesListByTeam);
    if (filteredTemplatesListByTeam.length === 0) {
      setEmptyMessage("No Data Found");
    } else {
      setEmptyMessage("");
    }
  }
    props.childState({ showLoader: false });
}

  function receivedMessageFromWebResource(event){
    console.log("webresource:receivedMessageFromWebResource - TemplateSelector :", event.data);
    switch (event.data.eventName) {
      case "openRecordinCRMResult":
        console.log("openRecordinCRMResult data-", event.data.data);
        debugger
        let data = event.data.data;
        if (data?.eventFor === "TemplateSelector") {
          loadTemplates(data?.linkedEntityNames, data?.result?.conversationAttributes, data?.templates, data?.compPropsMessageList, data?.result);
        }
        break;
      case "LinkedEntityNamesLoaded":
        debugger
        console.log("LinkedEntityNamesLoaded data-", event.data.data);
        const { linkedEntityNames, conversationAttributes, templates, eventFrom, compPropsMessageList, conversation } = event.data.data;
        if (eventFrom === "TemplateSelector") {
          loadTemplates(linkedEntityNames, conversationAttributes, templates, compPropsMessageList, conversation);
        }
        break;
      default:
        break;
    }
  }
  useEffect(() => {
    window.addEventListener("message", receivedMessageFromWebResource);
    return () => {
      window.removeEventListener('message', receivedMessageFromWebResource);
    };
  }, []);
  async function getLinkedEntities(conversationAttributes) {
    var linkedEntitiesNames = [];
    let convAttributesObj = conversationAttributes ? JSON.parse(conversationAttributes) : "";
    if (crmMode == "true" && convAttributesObj.hasOwnProperty("ActivityId")) {
      // Dynamically get the linked entities names from CRM ny passing activityid from conversation attributes.
      linkedEntitiesNames = await getLinkedEntityNames(convAttributesObj["ActivityId"], crmProvider);
      linkedEntitiesNames.push("users");
      linkedEntitiesNames.push("userattributes");
      linkedEntitiesNames.push("teamattributes");
      return linkedEntitiesNames;
    }
    return null;
  }
  useEffect(() => {
    async function updateTokenAndTemplatesData() {
      if (props.conversation?.isReadOnly !== true && props.state.open) {
        setTemplatesList([]);
        setTemplatesListCopy([]);
        setEmptyMessage("");
        props.childState({ showLoader: true });
        let conversationAttributes = props.state.conversation.conversationAttributes
        var linkedEntitiesNames = null;
        let loadCRMEntities = false;
        let convAttributesObj = conversationAttributes ? JSON.parse(conversationAttributes) : "";
        if (props.state.conversation.linkedEntities != undefined && props.state.conversation.linkedEntities != null && props.state.conversation.linkedEntities != "") {
          linkedEntitiesNames = props.state.conversation.linkedEntities;
        }
        else if (crmMode == "true" && window != null && convAttributesObj.hasOwnProperty("ActivityId")) {
          loadCRMEntities = true;
        }
        if (props.state.conversation.channel === "Chat" && props.state.conversation.authId) {
          if (window.sforce != null && crmProvider == 'S') {
            let result = await openContextRecordInCRMWrapper(
              props.selectedConversation,
              userInfo.crmMemberFields,
              false,
              crmProvider
            )();
            conversationAttributes = result.conversationAttributes
          }
          else{
              sendEventToWebResource("openRecordinCRM", { conv: props.state.conversation, openRecord: false, templates: templatesData, compPropsMessageList: props.messageList, loadCRMEntities: true, eventFrom: "TemplateSelector" })
          }
          
        }
          //for When contact details are modified, token values data in insert and auto suggestion templates is not updating
        else if (window.sforce != null && crmProvider == 'S') {
          linkedEntitiesNames = await getLinkedEntities(conversationAttributes);
          loadTemplates(linkedEntitiesNames, conversationAttributes);
        }
        else if (loadCRMEntities){
            sendEventToWebResource("getLinkedEntityNames", { activityId: convAttributesObj["ActivityId"], conversationAttributes, templates: templatesData, compPropsMessageList: props.messageList, conversation: props.state.conversation, eventFrom: "TemplateSelector" });
        }
        else {
          if (linkedEntitiesNames || convAttributesObj === "") {
            loadTemplates(linkedEntitiesNames, conversationAttributes);
          }
        }
      }
    }
    updateTokenAndTemplatesData()
  }, [props.state.open]);

  function filterTemplates(value){
    let filteredData= templatesListCopy.filter((item)=>item.templateName
    .toLowerCase().includes(value))
    setTemplatesList(filteredData);
    if (filteredData.length === 0) {
      setEmptyMessage("No Data Found");
    } else {
      setEmptyMessage("");
    }
  }

  return (
    <Dialog
      open={props.state.open}
      onClose={props.handleModalClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <>
        <DialogContent>
          <DialogTitle></DialogTitle>

          <TextField
                      variant="standard"
                      label="Search Templates"
                      placeholder="Template Name"
                      autoComplete="off"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => {
                        if(e.target.value){
                          filterTemplates(e.target.value.toLowerCase())
                        }else{
                        setTemplatesList(templatesListCopy)
                        setEmptyMessage("");
                        }
                       
                      }}
                    />
          <Box sx={{ marginTop: "10px" }}>
            <Box
              sx={{ minHeight:"250px",maxHeight: "250px", marginTop: "10px", overflowY: "auto" }}
            >
              {emptyMessage !== "" && (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <span>No Records Found</span>
                </Box>
              )}
              {props.state.showLoader && (
                <Box
                  sx={{ display: "flex", marginLeft: "44%", marginTop: "2%" }}
                >
                  <CircularProgress />
                </Box>
              )}
              {templatesList?.map((item, index) => {
                return (
                  <>
      
                    {index > 0 && <Divider />}
                    <ListItem
                      button
                      sx={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "center"
                      }}
                      key={index}
                      onClick={() => {
                        props.handleTemplateSelection(item);
                      }}
                    >
                      <span style={{ fontSize: "13px" }}><strong>{item.templateName}</strong></span>
                      <Typography style={{ fontSize: "12px" }}>{item.templateBody}</Typography>
                    </ListItem>
                  
                  </>
                );
              })}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleModalClose} autoFocus>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <span>
                <strong> Cancel</strong>
              </span>
            </div>
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
});
