import React, { Component } from 'react';
import queryString from 'query-string'
import apiConfig from "../../api/apiConfig";
import axios from "axios";
import './DocuSign.scss';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box'




class DocuSignCustomerApp extends Component {
    constructor(props) {
        super(props)
        this.inputRef = React.createRef();
        this.state = {
            documentId: this.getQueryVariable('documentid'),
            customerPhoneNumber: '',
            showResponseScreen: false,
            serviceResponseMsg: "",
            showInitLoader: false
        }

        this.redirectToDocuSign = this.redirectToDocuSign.bind(this);
        this.updateCustomerPhoneNumber = this.updateCustomerPhoneNumber.bind(this);
    }


    async redirectToDocuSign() {
        try {
            
            if (this.state.customerPhoneNumber != "") {
                this.setState({ showResponseScreen: true });
                this.setState({ showInitLoader: true });
                let requestBody = {
                    createRecipientViewRequest: {

                    },
                    userDetails: {
                        documentId: this.state.documentId,
                        customerPhoneNumber: this.state.customerPhoneNumber
                    },
                };
                const axiosInstance = axios.create({
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                await axiosInstance.post(apiConfig.CREATE_RECIPIENT_VIEW, requestBody).then((response) => {

                    if (response != null && response.data != null && response.data.url != null) {
                        window.open(response.data.url, "_self");                    
                    }
                    else {
                        this.setState({ serviceResponseMsg : response.data.message });
                        this.setState({ showResponseScreen: true })
                    }
                    this.setState({ showInitLoader: false })
                }).catch((err) => {
                    console.log("Failed" + err);
                    this.setState({ serviceResponseMsg: "Failed to redirect. Please try again." });
                    this.setState({ showResponseScreen: true });
                    this.setState({ showInitLoader: false })
                });;



            } 

        }
        finally {

        }
    }

    getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        console.log(query)//"app=article&act=news_content&aid=160990"
        var vars = query.split("&");
        console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
            if (pair[0] == variable) { return pair[1]; }
        }
        return (false);
    }


    updateCustomerPhoneNumber(event) {
        this.setState({
            customerPhoneNumber: event.target.value,

        });
    }

    render() {
        const disabled = this.state.customerPhoneNumber === '' ? true : false;
        return (

            <div className="appDocuSign">
                {

                    this.state.showResponseScreen === false ?
                        <div className="lobby">
                            <input className="userInput"
                                value={this.state.customerPhoneNumber}
                                onChange={this.updateCustomerPhoneNumber}
                                placeholder="Please enter mobile number" />
                            <button className="signButton" disabled={disabled} onClick={this.redirectToDocuSign}>Sign</button>
                        </div> : <div style={{ textAlign: "center" }}>
                            <div style={{ marginTop: "5%" }}>
                                <h3>
                                    {this.state.serviceResponseMsg}
                                </h3>

                            </div>
                        </div>
                }
                {
                    this.state.showInitLoader == true ?
                        <Box sx={{ display: 'flex', marginLeft: "50%", marginTop: "5%"}}>
                            <CircularProgress />
                        </Box> : <></>
                }

            </div>
        );
    }

}

export default DocuSignCustomerApp;
