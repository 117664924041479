/***************
 *  Yogesh              11/28/2022      Texting-850 loading added 
 * 06-16-2023 => ONKAR => Texting-1339 Inbound Conversation from Unassigned - Queue
 * 06-23-2023 => ONKAR => Texting-1364 Team is changing to users default team when member assigns unassigned conversation to himself.
 * 07-22-2023 => RAJESH => Implemented the Assign to option if the conversation is in preassign queue with new boolean flag 
 * 09-01-2023 => SUDAM B CHAVAN =>TEXTING-1645 - Reporting - Track templates used
 * 09-23-2023 => SUDAM B CHAVAN => TEXTING-1729 - Option to Scheudule the message during offhours
 * 09-28-2023 => SUDAM B CHAVAN => TEXTING-1749 - Schedule outbound messages during off hours observations.
 * 10-12-2023 => ONKAR => TEXTING-1796 - Sentiment Re-Calculate button to calculate sentiment with latest messages.
 * 10-17-2023 => ONKAR => TEXTING-1805 - Sentiment is showing loader instead of emoji response.
 * 11-08-2023 => ONKAR => TEXTING-1839 - Adding the conversation I'd in URL should open the conversation page.
 * 12/11/2023 => SUDAM B CHAVAN => TEXTING-1906 0:Hardcoded TCPA message is showing instead of Team TCPA message.
 * 01-14-2023 => ONKAR => TEXTING - 1936 - Request Assistance : UI
 * 01-23-2023 => ONKAR => TEXTING - 1936 - Request Assistance : UI
 *                        2.On supervisor request assistance page, Blank space is showing in input message box place on CRM, Admin Texting view.
 */
import { InvokePostServiceCall } from "./serviceUtil";
import {
    loadActiveConversations,
    loadConversationMessages,
    removeConversation,
    loadUnassignedConversations,
    loadCompStart,
    updateMsgCountForSentiment,
    updateOverallSentiment,
    updateSentimentLoading,
    loadSupervisorConversations,
    loadSupervisorConversationMessages
} from "../storage/slices/messageSlice";
import { SendToAllUserConnections } from "../azure/pubSub";
import apiConfig from "./apiConfig";
import { mapConversation } from "../extensions/Util";
import { dataService } from "../dataService";


export const getActiveConversationsAPI = (userId, msgSupportNumber,setOpenConvWithId="") => {
    return (dispatch) => {
        let url = apiConfig.GET_ACTIVE_CONVERSATIONS_BY_USER_ID + userId;

        var msgReq = {
            userId: userId,
        };

        InvokePostServiceCall(url, JSON.stringify(msgReq))
            .then((resp) => {
                let conversations = [];

                for (var i = 0; i < resp?.data?.conversations?.length; i++) {
                    conversations.push(mapConversation(resp?.data?.conversations[i], resp?.data?.conversations[i].teamPhoneNumber));
                }
                dispatch(loadCompStart());
                dispatch(loadActiveConversations(conversations));
                if(setOpenConvWithId!==""){
                    setOpenConvWithId(true)
                }
            })
            .catch((err) => {
                console.log(err, "Error Message");
            });
    };
};

// Action creator for fetching supervisor conversations from the API
export const getSupervisorConversationsAPI=()=>{
    return(dispatch)=>{
        // Define the API endpoint URL using apiConfig
        let url = apiConfig.GET_ASSISTANCE_REQUEST_CONVERSATIONS

        // Make a POST request to the API endpoint
        InvokePostServiceCall(url,{})
        .then((resp)=>{
            // Extract conversations from the API response and map them
            let supervisorConversations = resp?.data?.conversations?.map((conversation) =>{
                conversation.isReadOnly=true;
            return mapConversation(conversation, conversation.teamPhoneNumber)}
        );

            // Dispatch an action indicating the start of loading
            dispatch(loadCompStart());
            // Dispatch an action to load supervisor conversations into the state
            dispatch(loadSupervisorConversations(supervisorConversations))



        }).catch((err)=>{
            // Log any errors that occur during the API call
            console.log(err,"Error Message")
        })
    }

}


export const getUnassignedConversationAPI = (context, isCallFromPubsubEvent) => {
    return (dispatch) => {
        let url = apiConfig.GET_MISSED_CONVERSTAIONS;
        let requestBody = {
            userId: context.userInfo.userId
        };
        if (!isCallFromPubsubEvent) {//false
            context && context.setConvLoading(true) // set loading true before calling the api
        }
        InvokePostServiceCall(url, JSON.stringify(requestBody)).then((resp) => {
            let unassignedConversations = [];
            for (var i = 0; i < resp?.data?.conversations?.length; i++) {
                var conv = resp?.data?.conversations[i];
                conv.isReadOnly = true;
                unassignedConversations.push(mapConversation(conv, resp?.data?.conversations[i].teamPhoneNumber));

            }
            dispatch(loadCompStart());
            dispatch(loadUnassignedConversations(unassignedConversations));
        }).catch((err) => {
            console.log(err, "Error Message");
        }).finally(() => {
            context && context.setConvLoading(false) //  new state set to false after getting the res from the server
        })
    };
};

// Action creator for fetching messages for a given conversation ID
export const getMessagesForConversationId = (conversationId, setConvLoading, isCheckMsgShowNotification) => {
    return (dispatch) => {
        // Set conversation loading to true
        setConvLoading(true);

        // Define the API endpoint URL using apiConfig
        let url = apiConfig.GET_MESSAGES;

        // Prepare the request object with the conversationId
        var getMsgsReq = { conversationId: conversationId };

        // Make a POST request to the API endpoint
        InvokePostServiceCall(url, JSON.stringify(getMsgsReq))
            .then((resp) => {
                debugger
                    // Extract and process messagesInfo from the API response
                    let messageInfoStr=JSON.stringify(resp?.data?.messagesInfo)
                    let messagesInfo = [...JSON.parse(messageInfoStr)];
                    let supervisorMessages=[...JSON.parse(messageInfoStr)]

                    // Filter supervisor messages based on messageSubType
                    for(let i=0;i<supervisorMessages.length;i++){
                        let filteredList=supervisorMessages[i].messages.filter((message)=>message.messageSubType==="INTERNAL")
                        supervisorMessages[i].messages=[...filteredList]
                    }

                    // Filter out empty supervisor message lists
                    let filteredSupervisorMessageList=supervisorMessages.filter((item)=>item.messages.length>0);
                    supervisorMessages=[...filteredSupervisorMessageList]

                    // Filter customer messages based on messageSubType
                    for(let i=0;i<messagesInfo.length;i++){
                        let filteredList=messagesInfo[i].messages.filter((message)=>message.messageSubType!=="INTERNAL")
                        messagesInfo[i].messages=[...filteredList]
                    }

                    // Filter out empty message lists
                    let filteredMessageList=messagesInfo.filter((item)=>item.messages.length>0);
                    messagesInfo=[...filteredMessageList]

                    // Dispatch actions to load messages into the state
                    dispatch(
                        loadSupervisorConversationMessages({
                            conversationId: conversationId,
                            messageList: messagesInfo,
                            supervisorMessageList:supervisorMessages
                        })
                    );
                if (isCheckMsgShowNotification) {
                    // msgsList.reverse();// TO find the 
                    let newMessageInfoIndex = messagesInfo.findIndex((x) => x.fromObjectType === 'Customer' && x.messages.find(m => m.showNotification == true));
                    debugger;
                    // let unreadnewMessageInfo?.messages.find((m)=>m.showNotification === true)
                    if (newMessageInfoIndex != -1) {
                        debugger;
                        for (let i = 0; i < messagesInfo.length; i++) {

                            for (let j = 0; j < messagesInfo[i].messages.length; j++) {
                                try {
                                    messagesInfo[i].messages[j].showNotification = false;
                                } catch (error) {
                                    console.error('Property is read-only'); //log for test after refresh texting app & send message are not visible 
                                }
                            }

                        }
                        //UpdateConversationAsRead(conversationId);
                    }
               }
                dispatch(
                    loadConversationMessages({
                        conversationId: conversationId,
                        messageList: messagesInfo,
                        supervisorMessageList:supervisorMessages
                    })
                );         
                if (setConvLoading !== undefined) {
                    setConvLoading(false);
                }
            })
            .catch((err) => {
                setConvLoading(false);
                console.log(err, "Error Message");
            });
    };
};
export const sendMessage = (userId, conversation, inputMessage, appContext, templateId, isAgentScheduledMsg, targetedScheduleDate) => {
    return (dispatch) => {
        let url = apiConfig.SEND_MESSAGES;
        var msgReq = {
            message: {
                conversationId: conversation.conversationId,
                fromObjectType: "User",
                fromId: userId,
                fromPhoneNumber: conversation.userPhoneNumber,
                toPhoneNumber: conversation.customerPhoneNumber,
                //messageDate: new Date().toISOString(), //toLocaleString("en-US"),
                messageBody: inputMessage,
                status: "sending",
                TeamId: conversation.teamId,
                templateId: templateId,
                ScheduleMessageDate: targetedScheduleDate
            },
            sourceSystem: "Texting-UI",
            isAgentScheduledMsg: isAgentScheduledMsg
        };
        InvokePostServiceCall(url, JSON.stringify(msgReq))
            .then((resp) => {
                var response = resp.data;
                appContext && appContext.setMsgLoadingState(false);
                if (response != null && response.status === "SUCCESS") {
                    //let outBoundMessage = {
                    //  messageType: "OutBoundMessage",
                    //  conversation: conversation,
                    //  conversationId: conversation.conversationId,
                    //  AgentId: userId,
                    //  message: resp.data.message,
                    //  fromObjectType: "User"
                    //};
                    //SendToAllUserConnections(userId, JSON.stringify(outBoundMessage));
                }
                else if (response != null && response.status === 'FAILED' && response.errorDescription != null) {
                    if (response.isNonTcpa) {
                        dataService.setData({ showConfirmationMessage: response.errorDescription,
                            messageRequest: response.message,
                            messageScheduleDate: response.targetedScheduleDate
                        });
                    }
                    else {
                        dataService.setData({ showAlertMessage: response.errorDescription });
                    }
                    //sendMessageCallBack();
                }
            })
            .catch((err) => {
                console.log(err, "Error Message");
                //msgReq.message.status = 'failed';
                //let outBoundMessage = {
                //  messageType: "OutBoundMessage",
                //  conversation: conversation,
                //  conversationId: conversation.conversationId,
                //  AgentId: userId,
                //  message: msgReq.message,
                //  fromObjectType: "User"
                //};
                //SendToAllUserConnections(userId, JSON.stringify(outBoundMessage));
            });
    };
};

// Action creator for sending internal messages from a supervisor/Agent
export const sendMessageSupervisor = (userId, conversation, inputMessage,messageType) => {
    return (dispatch) => {
        // Define the API endpoint URL using apiConfig
        let url = apiConfig.SEND_INTERNAL_MESSAGE;

        // Prepare the request object with necessary message details
        var msgReq = {
              conversationId:conversation.conversationId,
              Message:inputMessage,
              MessageType:messageType,
              MessageSubType:"INTERNAL",
              ToUserId:userId//supervisor or agent Id based on inbound/outbound message type
        };

        // Make a POST request to send the internal message
        InvokePostServiceCall(url, msgReq)
            .then((resp) => {
                 // Check the response status
                var response = resp.data;
                if (response != null && response.status === "SUCCESS") {
                   
                }
                else if (response != null && response.status === 'FAILED' && response.errorDescription != null) {
                    // Handle failure by setting an alert message
                        dataService.setData({ showAlertMessage: response.errorDescription });
                }
            })
            .catch((err) => {
                console.log(err, "Error Message");
            });
    };
};

export const endConversation = (userId, conversationId, teamId) => {
    return (dispatch) => {
        let url = apiConfig.END_CONVERSATION;
        let msgReq = {
            conversation: {
                conversationId: conversationId,
                conversationEndedDate: new Date().toISOString(),
                conversationEndedById: userId,
            },
            teamId: teamId,
            userId: userId,
        };

        InvokePostServiceCall(url, JSON.stringify(msgReq))
            .then((resp) => {
                let endConversationBody = {
                    messageType: "MultiTabEndConversation",
                    conversationId: "" + conversationId + "",
                    AgentId: "" + userId + "",
                };
                SendToAllUserConnections(userId, JSON.stringify(endConversationBody));

                dispatch(removeConversation(conversationId));
            })
            .catch((err) => {
                console.log(err, "Error Message");
            });
    };
};

export const UpdateConversationAsRead = (conversationId) => {
    // debugger;
    let requestBody = {
        // userId: userInfo.userId,
        conversationId: conversationId,
    };
    InvokePostServiceCall(apiConfig.UPDATE_CONVERSATION_AS_READ, requestBody)
        .then((res) => {
            // debugger;
            console.log(res, "UpdateConversationAsRead");

        })
        .catch((err) => {
            debugger;

            console.log(err, "Error Message", "UpdateConversationAsRead");

        });
    return;
}

export const checkStartConversationEligibility = (requestBody, startConversationObj) => {
    return new Promise((resolve) => {
        debugger;
        
        InvokePostServiceCall(apiConfig.CHECK_START_CONV_ELIGIBILITY, requestBody)
            .then((res) => {
                var response = res.data;
                resolve(response);
                if (response != null && response.status === "SUCCESS") {
                    //dataService.setData({ showAlertMessage: response.status });
                }
                else if (response != null && response.status === 'FAILED' && response.errorDescription != null) {
                    if (response.isSelfAssignable == true) {  // if conversation is self assignable then show alert. This flag is set in backend.
                        var selfAssignConvObj = new Object();
                        selfAssignConvObj.conversationId = response.conversationId;
                        selfAssignConvObj.alertText = response.errorDescription;
                        selfAssignConvObj.teamId = response.teamId;
                        dataService.setData({ showSelfAssignAlert: selfAssignConvObj });
                    }
                    else if (response.isNonTcpa) { //if it's non TCPA hrs..to show confirmation box to schedule the msg
                        let startConvObj = startConversationObj;
                        startConvObj.messageScheduleDate = response.targetedScheduleDate
                        let showConfirmationOnStartConv = {
                            confrmationText: response.errorDescription,
                            messageScheduleDate: response.targetedScheduleDate,
                            requestBody: requestBody,
                            startConversationObj: startConvObj,
                        };
                        dataService.setData({
                            showConfirmationOnStartConv: showConfirmationOnStartConv
                        });
                    }
                    else {
                        dataService.setData({ showAlertMessage: response.errorDescription });
                    }
                }
                else {
                    dataService.setData({ showAlertMessage: "" });
                }


            })
            .catch((err) => {
                debugger;
                var r = {};
                r.status = 'FAILED';
                resolve(r);               
                dataService.setData({ showAlertMessage:"Something went wrong!" });
                console.log(err, "Error Message", "CHECK_START_CONV_ELIGIBILITY");

            });
    })

}

export const getSentiment = async (conversationId, messageList) => {
//call api when conversation have atleast one message from customer.
  try {
    let messages = [];
    let customerMessages = messageList.filter(
      (x) => x.fromObjectType === "Customer"
    );
    //Calculate sentiment when conversation have atleast one message from customer
    if (customerMessages.length > 0) {
      messageList.forEach((msgs) => {
        msgs.messages.forEach((msg) => {
          if (msg.fromObjectType === "User") {
            messages.push(`Agent : ${msg.messageBody}`);
          } else {
            messages.push(`Customer : ${msg.messageBody}`);
          }
        });
      });
      //Invoke api to get sentiment of the conversation
      let result = await InvokePostServiceCall(apiConfig.GET_SENTIMENT, {
        conversationId: conversationId,
        messages,
      });
      return result;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};




export const updateSentimentInStore = (payload) => {
//update the overallSentiment value of selected conversation in the store (conversation page).
  return async (dispatch) => {
    if(payload.messageList.length>0){
        dispatch(updateSentimentLoading(true))
    let sentimentResult = await getSentiment( //get sentiment api call
      payload.conversationId,
      payload.messageList
    );
    if(sentimentResult){
    let sentimentPayload = {
      conversationId: payload?.conversationId,
      overallSentiment: sentimentResult.data?.overallSentiment
        ? sentimentResult.data.overallSentiment
        : 3,
    };
    //update the overall sentiment value in store
    dispatch(updateMsgCountForSentiment(sentimentPayload));
    dispatch(updateOverallSentiment(sentimentPayload));
   }
   dispatch(updateSentimentLoading(false))
  };
}
};

export const updateSentimentInSearchAndChannel = async (
  convDetails,
  messageList,
  setConvDetails,
  isRefresh = "",
  setLoading = ""
) => {
    //update the overallSentiment value of selected conversation in the search copnversation and omnichannel page
  if (setLoading !== "" && isRefresh !== "") {
    setLoading(true);
  }
  let conversationId = convDetails.ConversationId //check if field names inside payload convDetails starts with capital letter or not.
    ? convDetails.ConversationId
    : convDetails.conversationId;
  if (messageList.length > 0) {
    let sentimentResult = await getSentiment(conversationId, messageList); //get Sentiment api call
    if (sentimentResult) {
      let tempConvData = { ...convDetails };
      let overallSentiment = sentimentResult.data?.overallSentiment
        ? sentimentResult.data.overallSentiment
        : 3;
      if (convDetails.ConversationId) {   //check if field names inside payload convDetails starts with capital letter or not.
        tempConvData.OverallSentiment = overallSentiment;
      } else {
        tempConvData.overallSentiment = overallSentiment;
      }
      setConvDetails(tempConvData);
    }
  }
  if (setLoading !== "" && isRefresh !== "") {
    setLoading(false);
  }
};