/***************
*11-11/2022 TextField:766=>disabled auto suggetion
08-08-2023 PRABHAT TEXTING-1549 Add Profile Photo upload to User Profile page
08-10-2023 PRABHAT TEXTING-1561 Agent profile picture should be shown in Texting UI view.
08-22-2023 SUDAM B CHAVAN TEXTING-1608 - User Log in History observations.
29-08-2023 => PRABHAT => TEXTING - 1633 - Modification of Profile picture button layout in Agent profile CRM View.
08-29-2023 => ONKAR => TEXTING-1578 -REACT Upgrade
01-25-2024 => SUDAM B CHAVAN => TEXTING-1961 User preferred team is changing to first team in the dro
***********/
import React, { useContext, useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    TextField,
    InputLabel,
    FormControl,
    Select,
    MenuItem,
    Checkbox,
    Avatar,
    CircularProgress,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTheme } from "@mui/material/styles";
import { AuthContext } from "../AuthContext";
import moment from "moment";
import apiConfig from "../api/apiConfig";
import { InvokePostServiceCall } from "../api/serviceUtil";
import SnackBar from "../components/SnackBar";
import AvatarFile from "../components/AvatarFileComp";
import LoginHistory from "../pages/users/LoginHistory";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function UserProfileDialog(props) {

    const [createdBy, setCreatedBy] = useState({
        createdByName: "",
        createdDate: "",
    })
    const [modifiedBy, setModifiedBy] = useState({
        lastModifiedByName: "",
        lastModifiedDate: "",
    });
    const {
        userInfo, setUserInfo,
        contactsFilterData,
        contactsListFilterData,
        usersFilterData, departments, ProfileImgBase64,setProfileImgBase64,
    } = useContext(AuthContext);

    const [teamList1, setteamList1] = useState([]);
    const [teamsList, setTeamsList] = useState([]);
    const theme = useTheme();
    const [open, setopen] = useState(true);
    const { messageApiToken } = useContext(AuthContext);
    const [userAttributesJson, setUserAttributesJson] = useState("");
    const [collapse, setCollapse] = useState(false)
    const [jsonError, setJsonError] = useState(false);
    const [validJson, setValidJson] = useState(false);
    const [emailErr, setEmailErr] = useState(false);
    const [phoneErr, setPhoneErr] = useState(false);
    const [maxConvErr, setMaxConvErr] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [forceLookup, setForceLookup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [lookup, setLookup] = useState(false);
    const [addTeam, setAddTeam] = useState([]);
    //const [ProfileImgBase64, setProfileImgBase64] = useState("");
    const [severity, setSeverity] = useState("success");
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [teams, setTeams] = useState([]);
    const [teamData, setTeamData] = useState([]);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const isProfile = true;
    let regEmail = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i;
    let numberRegex = /^(0|[1-9]\d*)$/;
    const handleClose = () => {
        console.log("userprofile", props);
        props.setShowprofile(false);

    }

    const [userData, setUserData] = useState({
        applicationUserId: null,
        phoneNumber: null,
        userName: null,
        azureAdid: null,
        isActive: false,
        firstName: null,
        lastName: null,
        emailId: null,
        team: [],
        role: null,
        userAttributes: "",
        maxConversations: "",
        userPreferences: "",
        department: null,
        lastLoginDate: ""
    });
    const [preferredTeamId, setPreferredTeamId] = useState(userInfo?.teamId);
    useEffect(() => {
        if (messageApiToken) {
            setLoading(true);
            let requestBody = {
                userId: userInfo.userId
            };
            InvokePostServiceCall(apiConfig.GET_USERS, requestBody)
                .then((data) => {
                    let userDetails = data.data.userDetails[0].user;
                    console.log(userDetails, "()(()");
                    setUserData({
                        userId: userDetails.userId,
                        applicationUserId: userDetails.applicationUserId,
                        phoneNumber: userDetails.phoneNumber,
                        userName: userDetails.userName,
                        azureAdid: userDetails.azureAdid,
                        firstName: userDetails.firstName,
                        lastName: userDetails.lastName,
                        emailId: userDetails.emailId,
                        role: userDetails.role,
                        isActive: userDetails.isActive,
                        userAttributes: userDetails.userAttributes,
                        maxConversations: userDetails.maxConversations,
                        userPreferences: userDetails.userPreferences,
                        department: userDetails.department === "" ? "None" : userDetails.department,
                        lastLoginDate: userDetails?.lastLoginDate ? moment(userDetails?.lastLoginDate).local().format("LLL") : "",
                    });
                    setProfileImgBase64(userDetails.profileImg)
                    setModifiedBy({
                        lastModifiedByName: userDetails.lastModifiedByName,
                        lastModifiedDate: moment(userDetails.lastModifiedDate).local().format("LLL"),
                    })
                    setCreatedBy({
                        createdByName: userDetails.createdByName,
                        createdDate: moment(userDetails.createdDate).local().format("LLL")
                    })
                    setUserAttributesJson(userDetails.userAttributes);
                    try {
                        JSON.parse(userDetails.userAttributes);
                        setValidJson(true);
                    } catch (e) {
                        setValidJson(false);
                    }
                    if (data.data.userDetails[0].teams.length > 0) {
                        let teamArr = [];
                        data.data.userDetails[0].teams.forEach((item) => {
                            teamArr.push(item.team.teamId);
                        });
                        setTeams(teamArr);
                        setTeamData(teamArr);
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    setSeverity("error");
                    setSnackbarMsg(error.message);
                    handleOpenSnackbar();
                    console.log(error.message);
                    setLoading(false);
                });

        }
    }, [messageApiToken]);


    useEffect(() => {
        if (messageApiToken) {
            const teamsArr = [];
            InvokePostServiceCall(apiConfig.GET_TEAMS, { isActive: true, userId: userInfo.userId })
                .then((data) => {
                    let teamData = data.data.teamDetails;
                    if (teamData.length > 0) {
                        teamData.forEach((item, index) => {
                            teamsArr.push({
                                team: {
                                    teamId: item.team.teamId,
                                    teamName: item.team.teamName,
                                    type: item.team.type,
                                    msgSupportNumber: item.team.msgSupportNumber,
                                    voiceSupportNumber: item.team.voiceSupportNumber,
                                    shortCode: item.team.shortCode,
                                },
                                actionType: "Add",
                                users: item.users,
                            });
                        });
                    }
                    setTeamsList(teamsArr);
                    setteamList1(userInfo.teams);
                })
                .catch((error) => {
                    setSeverity("error");
                    setSnackbarMsg(error.message);
                    handleOpenSnackbar();
                    console.log("teamsApi", error);
                });
        }
    }, [messageApiToken]);


    const handleAddUpdateUser = () => {
        let chkmobile = false;

        const PHONE_REGEX = /^[+][0-9]{11}$/i
        let PHONE_US = /^\([0-9]{3}\) |[0-9]{3}-[0-9]{3}-[0-9]{4}$/i;
        const PHONE_INDIAN_REGEX = /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/i;

        if (PHONE_REGEX.test(userData.phoneNumber)) {
            chkmobile = true;
        } else if (PHONE_US.test(userData.phoneNumber)) {
            chkmobile = true;
        }
        else if (PHONE_INDIAN_REGEX.test(userData.phoneNumber)) {
            chkmobile = true;
        }
        if (forceLookup) {
            setSnackbarMsg("There is a change in Email ID, please do Lookup user before saving the record.");
            setSeverity("warning");
            handleOpenSnackbar();
        } else {
            if ((userData.phoneNumber.length ? chkmobile : true) && regEmail.test(userData.emailId) && numberRegex.test(userData.maxConversations)) {
                setLoadingBtn(true);
                userInfo.teamId = preferredTeamId;
                setUserInfo(userInfo);
                let userpreference = JSON.stringify({
                    ContactsFilter: {
                        firstName: contactsFilterData.firstName,
                        lastName: contactsFilterData.lastName,
                        phone: contactsFilterData.phone,
                        email: contactsFilterData.email,
                        teamId: contactsFilterData.teamId,
                    },
                    UsersFilter: {
                        firstName: usersFilterData.firstName,
                        lastName: usersFilterData.lastName,
                        phone: usersFilterData.phone,
                        email: usersFilterData.email,
                        teamId: usersFilterData.teamId,
                        isActive: usersFilterData.isActive
                    },
                    ContactsListFilter: contactsListFilterData,
                    DefaultTeamId: preferredTeamId,
                })
                let userDataReqObj = { ...userData, userPreferences: userpreference, profileImg: ProfileImgBase64, }
                let requestBody = {
                    userDetail: {
                        user: userDataReqObj, 
                        actionType: "Update",
                        teams: addTeam,
                    },
                };

                InvokePostServiceCall(apiConfig.ADD_UPDATE_USER, requestBody)
                    .then((data) => {
                        props.setShowprofile(false);
                        setSnackbarMsg(true ? "User Updated Successfully." : "User Updated Successfully");
                        setSeverity("success");
                        handleOpenSnackbar();
                        setLoadingBtn(false);
                    })
                    .catch((err) => {
                        setSeverity("error")
                        setSnackbarMsg(err.message);
                        console.log(err, "adduserError");
                        handleOpenSnackbar();
                        setLoadingBtn(false);
                    });
            }
            else {
                setLookup(false);
                setSeverity("error")
                if (!chkmobile) {
                    setPhoneErr(true)
                }
                if (!regEmail.test(userData.emailId)) {
                    setEmailErr(true)
                }
                if (!numberRegex.test(userData.maxConversations)) {
                    setMaxConvErr(true);
                }
                setSnackbarMsg("Invalid Data")
                handleOpenSnackbar();
            }
        }
    };
    const handleCloseSnackbar = (event, reason) => {
        setOpenSnackbar(false);
    }
    const handleOpenSnackbar = () => {
        setOpenSnackbar(true);
    };
    const handleChangeDefaultTeam = (event) => {
        setPreferredTeamId(event.target.value);
    };
    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth={"md"}
            >
                <DialogTitle>Profile</DialogTitle>
                <DialogContent>
                    <SnackBar
                        openSnackbar={openSnackbar}
                        handleCloseSnackbar={handleCloseSnackbar}
                        severity={severity}
                        userMessage={
                            snackbarMsg
                        }
                    />
                    {loading ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "15%",
                            }}
                        >
                            <CircularProgress />
                        </div>
                    ) : (<>
                            <Box sx={{ ...theme.customStyle.textRow, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div>
                                    <InputLabel htmlFor="upload-avatar-pic">
                                        <Avatar
                                            alt="Remy Sharp"
                                            src={ProfileImgBase64} // Use the state variable to display the uploaded image
                                            sx={{ width: 140, height: 140 }}
                                        />
                                    </InputLabel>
                                    <AvatarFile
                                        name="avatar" // Provide a unique name for the field (optional)
                                        label="Profile Picture" // Label for the field (optional)
                                        value={ProfileImgBase64} // Pass the profile image value from the state
                                        onChange={(base64String) => setProfileImgBase64(base64String)} // Update the state with the uploaded image data
                                    />
                                </div>
                            </Box>


                        <Box sx={theme.customStyle.textRow}>
                            <TextField
                                size="small"
                                margin="dense"
                                required
                                id="ProfileemailId"
                                disabled={isProfile}
                                value={userData.emailId}
                                error={emailErr}
                                label="Email Id"
                                type="text"
                                fullWidth
                                variant="standard"
                                inputProps={{ //not show suggetions
                                    shrink: true,
                                    autoComplete: 'new-password',
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                                onChange={(e) => {
                                    setEmailErr(false)
                                    setUserData({ ...userData, emailId: e.target.value });
                                }}
                                helperText={emailErr ? "Invalid Email Id" : ""}
                            />

                        </Box>
                        <Box sx={theme.customStyle.textRow}>
                            <TextField
                                size="small"
                                margin="dense"
                                disabled={isProfile}
                                required
                                value={userData.firstName}
                                label="First Name"
                                type="text"
                                fullWidth
                                id="profilefirstName"
                                variant="standard"
                                InputLabelProps={{
                                    shrink: true,
                                    autoComplete: 'new-password',
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                                onChange={(e) => {
                                    setUserData({ ...userData, firstName: e.target.value });
                                }}
                            />
                        </Box>

                        <Box sx={theme.customStyle.textRow}>
                            <TextField
                                size="small"
                                margin="dense"
                                required
                                disabled={isProfile}
                                value={userData.lastName}
                                label="Last Name"
                                type="text"
                                fullWidth
                                variant="standard"
                                id="ProfilelastName"
                                InputLabelProps={{
                                    shrink: true,
                                    autoComplete: 'new-password',
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                                onChange={(e) => {
                                    setUserData({ ...userData, lastName: e.target.value });
                                }}
                            />
                        </Box>

                        <Box sx={theme.customStyle.textRow}>
                            <TextField
                                size="small"
                                margin="dense"
                                disabled={isProfile}
                                error={phoneErr}
                                value={userData.phoneNumber}
                                label="Phone Number"
                                type="text"
                                fullWidth
                                variant="standard"
                                id="ProfilephoneNumber"
                                InputLabelProps={{
                                    shrink: true,
                                    autoComplete: 'new-password',
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                                onChange={(e) => {
                                    setPhoneErr(false);
                                    setUserData({ ...userData, phoneNumber: e.target.value });
                                }}
                                helperText={phoneErr ? "Invalid Mobile Number" : ""}
                            />
                        </Box>
                        <Box sx={theme.customStyle.textRow}>
                            <TextField
                                size="small"
                                margin="dense"
                                value={userData.userAttributes}
                                disabled={isProfile}
                                label="User Attributes"
                                type="text"
                                fullWidth
                                variant="standard"
                                id="ProfileuserAttributes"
                                InputLabelProps={{
                                    shrink: true,
                                    autoComplete: 'new-password',
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                                InputProps={{
                                    readOnly: true
                                }}

                            />

                        </Box>

                        <div>

                            <Box sx={theme.customStyle.textRow}>
                                <TextField
                                    size="small"
                                    required
                                    margin="dense"
                                    error={maxConvErr}
                                    value={userData.maxConversations}
                                    disabled={isProfile}
                                    label="MAX Conversations"
                                    type="number"
                                    fullWidth
                                    variant="standard"
                                    id="ProfilemaxConversations"
                                    InputLabelProps={{
                                        shrink: true, autoComplete: 'new-password',
                                        form: {
                                            autocomplete: 'off',
                                        },
                                    }}
                                    onChange={(e) => {
                                        setMaxConvErr(false);
                                        setUserData({ ...userData, maxConversations: e.target.value });
                                    }}
                                    helperText={maxConvErr ? "Invalid Number" : ""}
                                />
                            </Box>

                            <span>Is Active</span>
                            <Checkbox disabled={isProfile} checked={userData.isActive} />
                        </div>
                        {<Box sx={{ ...theme.customStyle.textRow, marginTop: 2.5 }}>
                            <FormControl variant="standard" sx={{ width: "100%" }}>
                                <InputLabel shrink={true} disabled={true}>Department</InputLabel>
                                <Select
                                    onChange={(e) => {
                                        setUserData({ ...userData, department: e.target.value });
                                    }}
                                    value={userData.department}
                                    MenuProps={MenuProps}
                                    required
                                    disabled={true}
                                    label="Department"
                                    helperText="Please select Department."
                                >
                                    <MenuItem
                                        key="None"
                                        value="None"
                                    >
                                        None
                                    </MenuItem>
                                    {departments && departments.map((option) => (
                                        <MenuItem
                                            key={option}
                                            value={option}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>

                            </FormControl>
                        </Box>}
                        {<Box sx={{ ...theme.customStyle.textRow, marginTop: 2.5 }}>
                            <FormControl variant="standard" sx={{ width: "100%" }}>
                                <InputLabel shrink={true} disabled={true}>Teams</InputLabel>
                                <Select
                                    multiple
                                    required
                                    disabled={true}
                                    value={teams}
                                    MenuProps={MenuProps}
                                    label="Teams"
                                    helperText="Please select team."
                                >
                                    {teamsList.map((option) => (
                                        <MenuItem
                                            key={option.team.teamId}
                                            value={option.team.teamId}
                                        >
                                            {option.team.teamName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>}
                        <Box sx={{ ...theme.customStyle.textRow, marginTop: 2.5 }}>
                            <TextField
                                size="small"
                                select
                                required
                                label="Role"
                                defaultValue={userData.role}
                                disabled={isProfile}
                                value={userData.role}
                                fullWidth
                                id="Profilelastrole"
                                InputLabelProps={{
                                    shrink: true, autoComplete: 'new-password',
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                                variant="standard"
                            >
                                <MenuItem value={"Admin"}>Admin</MenuItem>
                                <MenuItem value={"Supervisor"}>Supervisor</MenuItem>
                                <MenuItem value={"Agent"}>Agent</MenuItem>
                            </TextField>
                        </Box>
                        <Box sx={{ ...theme.customStyle.textRow, marginTop: 2.5 }}>
                            <FormControl variant="standard" sx={{ width: "100%" }}>
                                <InputLabel shrink={true} >Preferred Team</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    label="Team"
                                    value={preferredTeamId}
                                    onChange={handleChangeDefaultTeam}
                                >
                                    {teamList1 && teamList1.map((option, index) => (
                                        <MenuItem key={option.teamId} value={option.teamId}
                                            onClick={() => { console.log("teamanem") }}
                                        >
                                            {option.teamName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                            <Box sx={theme.customStyle.textRow}>
                                <TextField
                                    size="small"
                                    margin="dense"
                                    disabled
                                    value={userData.lastLoginDate}
                                    label="User Last Login"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    id="lastLoginDate"
                                    inputProps={{ //not show suggetions
                                        autoComplete: 'new-password',
                                        form: {
                                            autocomplete: 'off',
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>
                        {/* <Box sx={{ ...styles.textRow, marginTop: 2.5 }}>
              <TextField
                size="small"
                id="standard-select-currency"
                select
                label="Select"
                defaultValue={team}
                value={team}
                fullWidth
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText="Please select team."
                variant="standard"
              >
                {teamsList.map((option, index) => (
                  <MenuItem key={index} value={option.team.teamId}>
                    {option.team.teamName}
                  </MenuItem>
                ))}
              </TextField>
            </Box> */}
                    </>)}

                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        size="small"
                        loading={props.btnLoading}
                        onClick={() => {
                            handleAddUpdateUser();
                        }}
                    >
                        save
                    </LoadingButton>
                    <Button size="small" onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default UserProfileDialog;
