/***************
*11-11/2022 TextField:766=>disabled auto suggetion
***********/
import React, { useState, useEffect, useContext, useRef } from "react";
import Box from "@mui/material/Box";
import SnackBar from "./SnackBar";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Checkbox,
  TextField,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

function UpdateTeamUser(props) {
  const title = "Update Users";
  const theme = useTheme();
  const isAddTemplate = props.isAddTemplate;
  const [checked, setChecked] = React.useState(props.templateDetails.inboundConversation);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [severity, setSeverity] = useState("success");
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [userData, setUserData] = useState(
    {
      id: null,
      phoneNumber: null,
      userName: null,
      azureAdid: null,
      firstName: null,
      lastName: null,
      emailId: null,
      indexId: null,
      inboundConversation: null,
      teamToUserId: null,
    });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    // setSelectedMention(0)
  };
  const handleClickAway = () => {
    setAnchorEl(null);
  }
  const openPoper = Boolean(anchorEl);
  const id = openPoper ? 'simple-popper' : undefined;
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    if (severity === "success") {
      props.setRefresh(!props.refresh)
    }
    props.handleClose();
    setOpenSnackbar(false);
  };
  useEffect(() => {
    let templateDetails = props.templateDetails;
    setUserData(templateDetails);
  }
    , []);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      <SnackBar
        openSnackbar={openSnackbar}
        handleCloseSnackbar={handleCloseSnackbar}
        severity={severity}
        userMessage={snackbarMsg}
      />
      <div style={{ marginLeft: 20, marginRight: 20 }}>
        <h2>{title}</h2>
        <Box sx={theme.customStyle.textRow}>
          <TextField
            size="small"
            margin="dense"
            fullWidth
            variant="standard"
            disabled
            value={userData.userName}
            label="Full Name"
            id="updateUseruserName"
            inputProps={{ //not show suggetions

              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Box>
        <Box sx={theme.customStyle.textRow}>
          <TextField
            size="small"
            margin="dense"
            variant="standard"
            fullWidth
            disabled
            value={userData.emailId}
            label="Email Id"
            id="updateUseruseremailId"
            inputProps={{ //not show suggetions

              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Box>
        <Box sx={{ ...theme.customStyle.textRow, marginTop: 2 }}>
          <TextField
            size="small"
            label="Phone"
            fullWidth
            disabled
            value={userData.phoneNumber}
            variant="standard"
            id="updateUseruserphoneNumber"
            inputProps={{ //not show suggetions
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          >
          </TextField>
        </Box>
        <Box >
          <span>Inbound Message</span>
          <Checkbox checked={checked} onChange={handleChange} />
        </Box>

        <LoadingButton
          loading={props.updateBtnLoading}
          variant="contained"
          onClick={() => {
            props.UpdateTeamMember(userData.id, checked);
          }}
        >
          {props.btnText}
        </LoadingButton>
        <Button
          sx={{ marginLeft: 2 }}
          variant="contained"
          onClick={() => {
            props.handleClose()
          }}
        >
          Cancel
        </Button>
      </div>
    </>
  )
}
export default UpdateTeamUser