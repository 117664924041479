/*
*11-27-2023 => SUDAM B CHAVAN => TEXTING-1872 Chat Survey Questions Configuration - Admin UI
*12-04-2023 => SUDAM B CHAVAN =>TEXTING-1896 Survey: Member UI, Admin UI pending observations.
*/
import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import apiConfig from "../../api/apiConfig";
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../AuthContext";
import { useTheme } from "@mui/material/styles";
import { InvokeGetServiceCall } from "../../api/serviceUtil";
import SnackBar from "../../components/SnackBar";
import { IconButton, TextField, InputAdornment } from "@mui/material";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import { StyledDataGrid } from "../../components/styledComponents/StyledDataGrid";
import moment from "moment";

export default function SurveyTemplatesPage() {
    const navigate = useNavigate();
    const theme = useTheme();
    const { messageApiToken } = useContext(AuthContext);
    const [surveyTemplates, setSurveyTemplates] = useState([]);
    const [surveyTemplatesArr, setSurveyTemplatesArr] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState(false);
    const [refresh, setRefresh] = useState(true);
    const [page, setPage] = useState(0)
    const [searchText, setSearchText] = useState([]);

    useEffect(() => {
        if (messageApiToken && refresh) {
            setSurveyTemplates([]);
            setLoading(true);
            InvokeGetServiceCall(apiConfig.GET_SURVEY_TEMPLATES)
                .then((data) => {
                    let surveyTemplatesArr = [];
                    console.log(data.data, "survey template data");
                    if (data.data.surveyTemplates.length > 0) {
                        data.data.surveyTemplates.forEach((item, index) => {
                            surveyTemplatesArr.push({
                                ...item,
                                createdDateFormat: moment(item.createdDate).local().format("MM-DD-YYYY"),
                                lastModifiedDateFormat: moment(item.lastModifiedDate).local().format("MM-DD-YYYY"),
                                id: index,
                            });
                        });
                    }
                    setSurveyTemplates(surveyTemplatesArr);
                    setSurveyTemplatesArr(surveyTemplatesArr)
                    setRefresh(false);
                    setLoading(false);
                })
                .catch((err) => {
                    console.log(err, "errorData");
                    setSnackbarMsg(err.message);
                    setOpenSnackbar(true);
                    setLoading(false);
                });
        }
    }, [messageApiToken, refresh]);
    const columns = [
        {
            field: "surveyName",
            headerName: "Survey Name",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "surveyDescription",
            headerName: "Survey Description",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "surveyCategory",
            headerName: "Survey Category",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "createdDateFormat",
            headerName: "Created Date",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "lastModifiedDateFormat",
            headerName: "Modified Date",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        ];
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    function filterTable(value) {
        let tempArr = [];
        setPage(0);
        let val = value.toLowerCase();
        surveyTemplatesArr.forEach((item) => {
            if (
                item.surveyName?.toLowerCase().includes(val) ||
                item.surveyDescription?.toLowerCase().includes(val) ||
                item.createdDateFormat?.toLowerCase().includes(val) ||
                item.lastModifiedDateFormat?.toLowerCase().includes(val) ||
                item.surveyCategory?.toLowerCase().includes(val)
            ) {
                tempArr.push(item);
            }
        });
        setSurveyTemplates(tempArr);
    }
    return (
        <>
            <SnackBar
                openSnackbar={openSnackbar}
                handleCloseSnackbar={handleCloseSnackbar}
                severity={"error"}
                userMessage={snackbarMsg}
            />
            <Box sx={theme.customStyle.tableRow}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <h2 style={{ marginLeft: 10 }}>Survey Template</h2>
                </div>
            </Box>
            <div style={{ display: "flex", alignItems: "flex-start", paddingLeft: 10 }}>
                <Box sx={theme.customStyle.tableRow}>
                    <IconButton
                        sx={theme.customStyle.teamsAddIconBtn}
                        onClick={() => {
                            navigate("/addUpdateSurveyTemplate", {
                                state: { isAddSurveyTemplate: true },
                            });

                        }}
                    >
                        <AddIcon />
                    </IconButton>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>


                        <TextField
                            sx={{ marginRight: "10px", marginBottom: "10px", paddingdown: 5 }}
                            size="small"
                            type="search"
                            variant="outlined"
                            margin="normal"
                            id="outlined-search"
                            label=""
                            placeholder="Survey Templates search"
                            autoComplete="off"
                            value={searchText}
                            onChange={(e) => {
                                setSearchText(e.target.value)

                                if (e.target.value) {
                                    filterTable(e.target.value);
                                } else {
                                    setSurveyTemplates(surveyTemplatesArr);
                                }
                            }}
                            InputLabelProps={{
                                shrink: false,
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                </Box>
            </div>
            <Box sx={theme.customStyle.tableHead}>
                <StyledDataGrid
                    loading={loading}
                    rows={surveyTemplates}
                    columns={columns}
                    pageSize={10}
                    page={page}
                    disableSelectionOnClick
                    components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                    }}
                    onPageChange={(page) => setPage(page)}
                    rowsPerPageOptions={[10]}
                    onRowDoubleClick={(e) => {
                        navigate("/addUpdateSurveyTemplate/" + e.row.surveyTemplateId, {
                            state: { isAddSurveyTemplate: false, surveyTemplates: e.row },
                        });
                    }}
                    columnVisibilityModel={{ history: false }} //hide the columns from the grid
                // initialState={{
                //   sorting: {
                //     sortModel: [{ field: "lastModifiedDateFormat", sort: "desc" }],
                //   },
                // }}
                />
            </Box>
        </>
    );
}
