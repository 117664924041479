
/***************
* 11-27-2023 => SUDAM B CHAVAN => TEXTING-1872 Chat Survey Questions Configuration - Admin UI
***********/
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import apiConfig from "../../api/apiConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import SnackBar from "../../components/SnackBar";
import LoadingButton from "@mui/lab/LoadingButton";
import {
    TextField,
    Button,
    MenuItem,
} from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { useTheme } from "@mui/material/styles";
import CustomTabList from "../../components/CustomTabList";
import QuestionsToSurveyTemplate from "./questionsToSurveyTemplate";
import moment from "moment";


export default function AddUpdateSurveyTemplate() {
    const AddSurveyTemplate = "Add Survey Template";
    const UpdateSurveyTemplate = "Update Survey Template";
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const isAddSurveyTemplate = location.state ? location.state.isAddSurveyTemplate : true;
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [severity, setSeverity] = useState("success");
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [surveyTemplateData, setSurveyTemplateData] = useState({
        surveyCategory: null,
        surveyDescription: null,
        surveyName: null,
        surveyTemplateId: null,
    });
    let lastModifiedByName = isAddSurveyTemplate ? "" : location.state.surveyTemplates.lastModifiedByName
    let lastModifiedDate = moment(isAddSurveyTemplate ? "" : location.state.surveyTemplates.lastModifiedDate).local().format("LLL")
    let createdByName = isAddSurveyTemplate ? "" : location.state.surveyTemplates.createdByName
    let createdDate = moment(isAddSurveyTemplate ? "" : location.state.surveyTemplates.createdDate).local().format("LLL")

    const [value, setValue] = React.useState("1");
    const tabList = [
        { label: "Details", value: "1", displayOrder: 1 },
        { label: "Associated Questions", value: "2", displayOrder: 2 },
    ];
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        if (severity === "success") {
            navigate("/surveyTemplate", { replace: true });
        }
        setOpenSnackbar(false);
    };

    useEffect(() => {
        if (!isAddSurveyTemplate) {
            let surveyTemplate = location.state.surveyTemplates;
            setSurveyTemplateData({
                surveyTemplateId: surveyTemplate.surveyTemplateId,
                surveyName: surveyTemplate.surveyName,
                surveyDescription: surveyTemplate.surveyDescription,
                surveyCategory: surveyTemplate.surveyCategory,
            });
        }
    }, []);


    const handleChangeContext = (value, selectLabel) => {
        if (selectLabel === "Category") {
            setSurveyTemplateData({ ...surveyTemplateData, surveyCategory: value });
        }
    };

    const handleAddUpdateSurveyTemplate = () => {
        setLoadingBtn(true);
        let addRequestBody = {
            actionType: "Add",
            SurveyTemplate: {
                surveyName: surveyTemplateData.surveyName,
                surveyDescription: surveyTemplateData.surveyDescription,
                surveyCategory: surveyTemplateData.surveyCategory,
            },
        };
        let updateRequestBody = {
            actionType: "Update",
            SurveyTemplate: {
                surveyTemplateId: surveyTemplateData.surveyTemplateId,
                surveyName: surveyTemplateData.surveyName,
                surveyDescription: surveyTemplateData.surveyDescription,
                surveyCategory: surveyTemplateData.surveyCategory,
            },
        };
        console.log("requestBody",
            isAddSurveyTemplate ? addRequestBody : updateRequestBody);

        InvokePostServiceCall(
            apiConfig.SAVE_SURVEY_TEMPLATE,
            isAddSurveyTemplate ? addRequestBody : updateRequestBody
        )
            .then((data) => {
                setSeverity("success");
                if (isAddSurveyTemplate) {
                    setSurveyTemplateData({
                        ...surveyTemplateData,
                        surveyTemplateId: data.data.surveyTemplateId,
                    });
                }
                setSnackbarMsg(`Survey template ${isAddSurveyTemplate ? "added " : "updated"} successfully`);
                setOpenSnackbar(true);
                setLoadingBtn(false);
            })
            .catch((err) => {
                setSeverity("error")
                setSnackbarMsg(err.message)
                console.log(err.message, "ERROR DATA");
                setOpenSnackbar(true);
                setLoadingBtn(false);
            });
    };

 
    return (
        <>
            <SnackBar
                openSnackbar={openSnackbar}
                handleCloseSnackbar={handleCloseSnackbar}
                severity={severity}
                userMessage={snackbarMsg}
            />
            <div style={{ marginLeft: 20, marginRight: 20 }}>
                <h2>{isAddSurveyTemplate ? AddSurveyTemplate : UpdateSurveyTemplate}</h2>
                <TabContext value={value} >
                    <CustomTabList tabList={tabList} showTabsCount={3} handleChangeTab={(event, selectedTabValue) => {
                        setValue(selectedTabValue)
                    }}></CustomTabList>
                    <TabPanel value="1">
                        <div>
                            <Box sx={theme.customStyle.textRow}>
                                <TextField
                                    size="small"
                                    margin="dense"
                                    variant="standard"
                                    value={surveyTemplateData.surveyName}
                                    fullWidth
                                    required
                                    label="Survey Name"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    id="UpdateSurveyName"
                                    inputProps={{ //not show suggetions
                                        autoComplete: 'new-password',
                                        form: {
                                            autocomplete: 'off',
                                        },
                                    }}
                                    onChange={(e) => {
                                        setSurveyTemplateData({
                                            ...surveyTemplateData,
                                            surveyName: e.target.value,
                                        });
                                    }}
                                />
                            </Box>
                           
                            <Box sx={theme.customStyle.textRow}>
                                <TextField
                                    margin="dense"
                                    label="Description"
                                    multiline
                                    required
                                    rows={2}
                                    inputProps={{
                                        maxLength: 500, autoComplete: 'new-password',
                                        form: {
                                            autocomplete: 'off',
                                        },
                                    }}
                                    id="updateSurveyDescription"
                                    value={surveyTemplateData.surveyDescription}
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => {
                                        setSurveyTemplateData({
                                            ...surveyTemplateData,
                                            surveyDescription: e.target.value,
                                        });
                                    }}
                                />
                            </Box>
                            {!isAddSurveyTemplate && (
                                <>
                                    <Box sx={theme.customStyle.textRow}>
                                        <TextField
                                            margin="dense"
                                            disabled
                                            label="Created By"
                                            type="text"
                                            value={`${createdByName + " (" + createdDate + ")"}`}
                                            fullWidth
                                            variant="standard"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            id="updateCreatedByName"
                                            inputProps={{ //not show suggetions
                                                autoComplete: 'new-password',
                                                form: {
                                                    autocomplete: 'off',
                                                },
                                            }}
                                        />
                                    </Box>
                                    <Box sx={theme.customStyle.textRow}>
                                        <TextField
                                            margin="dense"
                                            label="Modified By"
                                            disabled
                                            type="text"
                                            value={`${lastModifiedByName + " (" + lastModifiedDate + ")"}`}
                                            fullWidth
                                            variant="standard"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            id="updateLastModifiedByName"
                                            inputProps={{ //not show suggetions
                                                autoComplete: 'new-password',
                                                form: {
                                                    autocomplete: 'off',
                                                },
                                            }}
                                        />
                                    </Box>
                                </>
                            )}
                            <LoadingButton
                                loading={loadingBtn}
                                variant="contained"
                                disabled={
                                    !surveyTemplateData.surveyName ? true :
                                        !surveyTemplateData.surveyDescription ? true : false
                                }
                                onClick={() => {
                                    handleAddUpdateSurveyTemplate();
                                }}
                            >
                                {!isAddSurveyTemplate ? UpdateSurveyTemplate : AddSurveyTemplate}
                            </LoadingButton>
                            <Button
                                sx={{ marginLeft: 2 }}
                                variant="contained"
                                onClick={() => {
                                    navigate("/surveyTemplate");
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </TabPanel>
                    <TabPanel value="2">
                        <QuestionsToSurveyTemplate isAdd={isAddSurveyTemplate} surveyTemplateId={surveyTemplateData.surveyTemplateId} />
                        

                    </TabPanel>
                </TabContext>
            </div>
        </>
    );
}