////11-09-2022 Texting-783 Filter chip should show the team name that was filtered-done. 
////11-09-2022 Texting-783 Removed None option from teams dropdown. 
////15/11/2022 => Texting-802 setting setSeacrhClicked true if search button clicked
////29-11-2022 => YOGESH=>Texting-866=> Disabled auto suggection from all textfields
import React, { useContext, useState } from 'react'

import { useTheme } from "@mui/material/styles";
import { Button, FormControl, MenuItem, Select, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { AuthContext } from '../AuthContext';

export default function AgentsFilter({ setSeacrhClicked, value, setTeamChanged, clearFilter, setShowFilter, showFilter, setFilterData, filterData, teams, handleSearch, getUsers, setFilterTeamName }) {
    const { userInfo } = useContext(AuthContext);
    const theme = useTheme();
    return (
        <div>

            {
                showFilter &&
                <div style={theme.customStyle.filterMainDiv}>
                    <div style={theme.customStyle.textFieldMainDiv}>
                        <div style={theme.customStyle.textFieldLabel}>Team</div>
                        <FormControl fullWidth size="small" sx={{ minWidth: 140 }}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label=""
                                sx={theme.customStyle.filterTextField}
                                value={filterData && filterData.teamId}
                                onChange={(e) => {
                                    setTeamChanged(true);
                                    setFilterData({ ...filterData, teamId: e.target.value })
                                    if (e.target.value === "None") { //logic to show teamName in filter chip.
                                        setFilterTeamName(userInfo.teamName)
                                    } else {
                                        teams.forEach((item) => {
                                            if (item.teamId === e.target.value) {
                                                setFilterTeamName(item.teamName)
                                            }
                                        })
                                    }
                                }}
                            >

                                {teams &&
                                    teams.map((option, index) => (
                                        <MenuItem key={option.teamId} value={option.teamId}>
                                            {option.teamName}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div style={theme.customStyle.textFieldMainDiv}>
                        <div style={theme.customStyle.textFieldLabel}>Agent Name</div>
                        <TextField
                            label=""
                            size="small"
                            variant="outlined"
                            sx={theme.customStyle.filterTextField}
                            value={filterData && filterData.name ? filterData.name : ""}
                            onFocus={event => {
                                event.target.setAttribute('autocomplete', 'new-password');
                            }}
                            onChange={(e) => {
                                setFilterData({
                                    ...filterData,
                                    name: e.target.value,
                                });
                            }}
                        />
                    </div>
                    {/* {                  // removed filter as per story Texting-841
                        value !== "3" &&
                        <div style={theme.customStyle.textFieldMainDiv}>
                            <div style={theme.customStyle.textFieldLabel}>Status</div>
                            <FormControl fullWidth size="small" sx={{ minWidth: 88 }}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={filterData && filterData.status ? filterData.status : ""}
                                    label=""
                                    sx={theme.customStyle.filterTextField}
                                    onChange={(e) => setFilterData({
                                        ...filterData,
                                        status: e.target.value,
                                    })}
                                >
                                    <MenuItem value={"Available"}>Available</MenuItem>
                                    <MenuItem value={"Busy"}>Busy</MenuItem>
                                    <MenuItem value={"Offline"}>Offline</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    } */}

                    {/* shown the same filter in all tabs */}
                    <div style={theme.customStyle.textFieldMainDiv}>
                        <div style={theme.customStyle.textFieldLabel}>Contact Name</div>
                        <TextField
                            label=""
                            size="small"
                            variant="outlined"
                            sx={theme.customStyle.filterTextField}
                            value={filterData && filterData.contactName ? filterData.contactName : ""}
                            onFocus={event => {
                                event.target.setAttribute('autocomplete', 'new-password');
                            }}
                            onChange={(e) => {
                                setFilterData({
                                    ...filterData,
                                    contactName: e.target.value,
                                });
                            }}
                        />
                    </div>
                    <div style={theme.customStyle.textFieldMainDiv}>
                        <div style={theme.customStyle.textFieldLabel}>Contact Phone</div>
                        <TextField
                            label=""
                            size="small"
                            variant="outlined"
                            sx={theme.customStyle.filterTextField}
                            value={filterData && filterData.contactPhone ? filterData.contactPhone : ""}
                            onChange={(e) => {
                                setFilterData({
                                    ...filterData,
                                    contactPhone: e.target.value,
                                });
                            }}
                            onFocus={event => {
                                event.target.setAttribute('autocomplete', 'new-password');
                            }}
                        />
                    </div>



                    <LoadingButton
                        variant="contained"
                        size="small"
                        sx={theme.customStyle.filterButtons}
                        onClick={() => { setShowFilter(false); setSeacrhClicked(true); value == "1" && handleSearch(); }}
                    >
                        Search
                    </LoadingButton>
                    <Button
                        variant="contained"
                        size="small"
                        sx={{ ...theme.customStyle.filterButtons, marginLeft: "10px" }}
                        onClick={() => {
                            setFilterData({ teamId: userInfo.teamId });
                            setSeacrhClicked(false)
                            clearFilter(true)
                        }}
                    >
                        Clear
                    </Button>
                </div>
            }

        </div>
    )
}
