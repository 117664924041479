/**
 * 12-06-2023 => SUDAM B CHAVAN => TEXTING-1892 - 0: Survey : View Completed Survey in agent view
* 12-08-2023 => SUDAM B CHAVAN => TEXTING-1892 0: Survey : View Completed Survey in agent view 
* 12-11-2023 => SUDAM B CHAVAN => TEXTING-1892 0: Survey : View Completed Survey in agent view-reopen item fixed
* 12-12-2023 => SUDAM B CHAVAN => TEXTING-1892 - 0: Survey : View Completed Survey in agent view
*/

import React, { useEffect, useState } from "react";
import { Grid, CircularProgress, Box } from "@mui/material";
import apiConfig from "../../api/apiConfig";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import SnackBar from "../../components/SnackBar";
import moment from "moment";


function ViewSurvey(props) {
    const [loading, setLoading] = useState(false);
    const [surveyDetails, setSurveyDetails] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    useEffect(() => {
        setLoading(true);
        let requestBody =
        {
            conversationId: props.conversationId,
        }
        //loading survey member response details
        InvokePostServiceCall(apiConfig.GET_SURVEY_RESPONSES_FOR_CONVERSATION, requestBody)
            .then((data) => {
                if (data.data.status === "SUCCESS") {
                    setSurveyDetails(data.data.surveyResponses);
                }
                setLoading(false);
            })
            .catch((error) => {
                setSnackbarMsg(error.message);
                setOpenSnackbar(true);
                setLoading(false);
            });
    }, []);



    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };
    return (
        <>
            <SnackBar
                openSnackbar={openSnackbar}
                handleCloseSnackbar={handleCloseSnackbar}
                severity={"error"}
                userMessage={snackbarMsg}
            />
            <div
                className="customScroll"
                style={{
                    height: "calc(85vh - 194px)",
                    overflow: "auto",
                }}
            >
                <div style={{ padding: "10px" }}>
                    {loading ? (
                        <div style={styles.progressStyle}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <>
                            {surveyDetails.length > 1 && !loading ? (
                                    <>
                                        <Grid
                                            container
                                            spacing={{ xs: 1, md: 1 }}
                                            columns={{ xs: 2, sm: 8, md: 12 }}
                                            sx={{ margin: "10px" }}
                                        >
                                            <Box sx={{ padding: "15px" ,paddingBottom:"1px"}}>
                                                <Box sx={{fontSize:"16px"}}><strong> Survey Name: {surveyDetails[0].surveyName}</strong></Box>
                                            </Box>
                                        </Grid>
                                            <Box sx={{ padding: "0px 15px 15px 8px",display:"flex",justifyContent:"space-between" }}>
                                              
                                                    <Box>Created Date: {
                                                    surveyDetails[0].createdDate ? moment(surveyDetails[0].createdDate).local().format("MM-DD-YYYY") + ", " + moment(surveyDetails[0].createdDate).local().format("LT")
                                                        :null
                                                    
                                                    }</Box>
                                            <Box>Submitted Date: {                                                    
                                                      surveyDetails[0].completedDate ? moment(surveyDetails[0].completedDate).local().format("MM-DD-YYYY") + ", " + moment(surveyDetails[0].completedDate).local().format("LT")
                                                        :null
                                                    }</Box>
                                            </Box>


                                        <Grid
                                            container
                                            spacing={{ xs: 1, md: 1 }}
                                            columns={{ xs: 2, sm: 8, md: 12 }}
                                            sx={{ margin: "10px" }}
                                        >
                                            <Box sx={{ padding: "15px" }}>
                                                {surveyDetails.map((item, index) => (
                                                    <>
                                                        <Box sx={styles.textRow}><strong> Q. {item.questionBody}</strong></Box>
                                                        <Box sx={styles.textRow}>A. {item.responseBody}</Box>
                                                    </>
                                                ))}
                                            </Box>
                                        </Grid></>
                            ) : (
                             <div style={{ textAlign: "center" }}>Survey not available for this conversation</div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
}
const styles = {
    textRow: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 1.5,
        "& h2": {
            paddingLeft: "0",
        },
    },
    progressStyle: {
        display: "flex",
        justifyContent: "center",
        marginTop: "30px",
    },

};
export default ViewSurvey;