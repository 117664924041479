////12/09/2022 => SUDAM => Texting-912 Replicate the Token replcaement logic on the Javascript to do client side rendering
////06/15/2022 => SUDAM => TEXTING-1346 - Exists in Production - User and User Attribute Tokens are not replaceable in templates if agent’s user Attributes field is blank.
////07/11/2023 => ONKAR => TEXTING - 1421 Token is not getting replaced if value of field used in token is 0.
////07/13/2022 => SUDAM => TEXTING-1412, 1418.
export async function GetTemplatesWithTokens(template, attributes, tokens, userInfo, teamId) {
    const makeCamelCase = str => str.charAt(0).toLowerCase() + str.slice(1);//TeamName to teamName convert
    var templateBodyTemp = template?.templateBody;
    debugger
    try {
        if (templateBodyTemp) {
            var tokenArr = templateBodyTemp.split('#')
            if (tokenArr && tokenArr.length > 0) {
                tokenArr.forEach(temToken => {
                    if (temToken) {
                        var token = tokens.filter(t => t.tokenName === temToken)?.[0];
                        if (token) {
                            switch (token.tableName?.toLowerCase()) {
                                case "context":
                                    if (temToken in attributes && (attributes[temToken] || attributes[temToken]==0 )) {
                                        templateBodyTemp = templateBodyTemp.replace("##" + temToken + "##", attributes[temToken])
                                    }
                                    break;
                                case "users":
                                    try {
                                        var userAttributes = userInfo.userAttributes;
                                        var userAttributesObj = null;
                                        if (userAttributes) {
                                            userAttributesObj = new Object();
                                            userAttributesObj = JSON.parse(userAttributes);
                                        }
                                        var fieldNames = token.fieldName.split('.')
                                        //when we have userAttributes then only below if block will execute.
                                        if (fieldNames != null && fieldNames.length > 1 && userAttributesObj != null && fieldNames[1] in userAttributesObj && (userAttributesObj[fieldNames[1]] || userAttributesObj[fieldNames[1]]== 0 )) {
                                            templateBodyTemp = templateBodyTemp.replace("##" + temToken + "##", userAttributesObj[fieldNames[1]])
                                        }
                                        else if (fieldNames.length === 1)   // Assuming the field is from user Table
                                            templateBodyTemp = templateBodyTemp.replace("##" + temToken + "##", userInfo[fieldNames[0]])
                                    }
                                    catch (ex){
                                        console.log("Error While parsing the userAttributes Token for token" + token);
                                    }
                                    break;       
                                case "teams":
                                    try {
                                        debugger
                                        var team = userInfo.teams.filter(t => t.teamId === teamId)?.[0];
                                        if (team) {
                                            var teamAttributes = team.teamAttributes;
                                            var teamAttributesObj = null;
                                            if (teamAttributes) {
                                                teamAttributesObj = new Object();
                                                teamAttributesObj = JSON.parse(teamAttributes);
                                            }
                                            console.log("Team to replace token :: ", team);
                                            var fieldNames = token.fieldName.split('.')//if token field name like "teamAttributes.fieldName" then below if block will execute
                                            if (fieldNames != null && fieldNames.length > 1 && teamAttributesObj != null) {
                                                let fieldName = fieldNames[1];
                                                if (fieldName in teamAttributesObj && (teamAttributesObj[fieldName] || teamAttributesObj[fieldName] == 0)) {
                                                    templateBodyTemp = templateBodyTemp.replace("##" + temToken + "##", teamAttributesObj[fieldName])
                                                }
                                            }
                                            else if (fieldNames.length === 1)// Assuming the field is from Teams Table
                                            {
                                                templateBodyTemp = templateBodyTemp.replace("##" + temToken + "##", team[makeCamelCase(fieldNames[0])])
                                            }
                                        }
                                        else{
                                            console.log("Team not found by given teamId :: " + teamId);
                                        }
                                    }
                                    catch (ex) {
                                        console.log("Error While parsing the Teams Token for token" + token);
                                    }
                                    break;
                                default:
                                    break;
                            }
                        }
                        else {
                            console.log("token is not found for the token name - ", temToken)
                        }
                    }
                });
            }
        }
        return templateBodyTemp;
    } catch (err) {
        console.log(err, "Error Message - GetTemplatesWithTokens");
    }
    return templateBodyTemp;
}