// 08-30-2023 => ONKAR => TEXTING-1578 -REACT Upgrade
//09-11-2023 => ONKAR => TEXTING-1684 - Assignment history table should show New conversation rejected user records as well.
//09-13-2023 => SUDAM B CHAVAN => TEXTING-1704 - Assignment history of rejected user records pending observations.
import React, { useContext, useEffect, useState } from "react";
import apiConfig from "../api/apiConfig"
import { useTheme } from "@mui/material/styles";
import { AuthContext } from "../AuthContext";
import { InvokePostServiceCall } from "../api/serviceUtil";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ReactComponent as Accepted} from "../assets/Agent-Accept.svg"
import { ReactComponent as Rejected} from "../assets/Agent-Reject.svg"
import moment from "moment";
import {
    Box,
    Button,
    CircularProgress,
} from "@mui/material";
export default function ConversationAssignmentHistory(props) {
    const theme = useTheme();

    const [convAssignmentHistory, setConvAssignmentHistory] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        getConvAssignmentHistory();
    }, []);

    const getConvAssignmentHistory = () => {
        setLoading(true); // set loading true before calling the api
        InvokePostServiceCall(apiConfig.GET_CONVERSATION_ASSIGNMENT_HISTORY, {
            ConversationId: props.conversationId
        })
            .then((data) => {
                const tempArr = [];
                data.data.convAssignmentHistory &&
                    data.data.convAssignmentHistory.forEach((item, index) => {
                        var obj = {
                            assignedBy: item.assignedBy,
                            assignedDate: moment(item.assignedDate).local().format("MM-DD-YYYY") + ", " + moment(item.assignedDate).local().format("LT"),
                            fromName: item.fromName,
                            toName: item.toName,
                            id: index,
                            teamName: item.teamName,
                            userResponse: item.userResponse
                        }
                        tempArr.push(obj);
                    });
                setConvAssignmentHistory(tempArr)
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error, "error message");
            });
    }
    return (
        <>
            {
                loading ? (
                    <Box sx={{ display: "flex", marginLeft: "40%" }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>

                        {convAssignmentHistory && convAssignmentHistory.length > 0 ? <>

                            <div style={{ ...theme.customStyle.infoListStyle, paddingTop: "0px", marginBottom: props.isViewMore ? "10px" : "0px" }}>
                                <div style={theme.customStyle.infoListLabel}>Assignment History:
                                    {/* {!props.isViewMore &&
                        <Button size="small" style={{ textTransform: "capitalize", float: "right" }} onClick={() => props.setIsViewMore(!props.isViewMore)} autoFocus>
                            <span>
                                <strong> view {!props.isViewMore ? "more" : "less"}</strong>
                            </span>
                        </Button> */}
                                </div>

                                {!props.isViewMore && <div>
                                    {"Assigned " + (convAssignmentHistory[0].fromName ? "from " + convAssignmentHistory[0].fromName + " " : " ") + "to " + convAssignmentHistory[0].toName + " by  " + convAssignmentHistory[0].assignedBy}
                                    <Button size="small" style={{ textTransform: "capitalize", float: "right" }} onClick={() => props.setIsViewMore(!props.isViewMore)} autoFocus>
                                        <span>
                                            <strong> view more</strong>
                                        </span>
                                    </Button>
                                </div>}
                            </div>
                            {props.isViewMore &&
                                <Box sx={{ ...theme.customStyle.tableHead, height: "450px", padding: "0px" }}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="simple table" sx={{ minWidth: "382px", border: "3px solid black" }} >
                                            <TableHead sx={theme.customStyle.customTableTHead}>
                                                <TableRow>
                                                    <TableCell>Date</TableCell>
                                                    <TableCell>Assign BY</TableCell>
                                                    <TableCell>Team Name</TableCell>
                                                    <TableCell>Assign To</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody sx={theme.customStyle.customTableTBody}>
                                                {convAssignmentHistory && convAssignmentHistory.length > 0 ? convAssignmentHistory.map((item, index) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {item.assignedDate}
                                                        </TableCell>
                                                        <TableCell>{item.assignedBy}</TableCell>
                                                        <TableCell >{item.teamName}</TableCell>
                                                        <TableCell>{item.toName}</TableCell>
                                                        <TableCell>
                                                            {
                                                            item.userResponse
                                                                && (item.userResponse === "Accepted" ? <Accepted height={"25px"} width={"30px"} fill="green" /> 
                                                                    : item.userResponse === "Rejected" ? <Rejected height={"25px"} width={"30px"} fill="red" /> :<></>)
                                                        
                                                        }</TableCell>
                                                    </TableRow>
                                                )) : null}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <div>
                                        <Button size="small" style={{ textTransform: "capitalize", float: "right", marginTop: "10px" }} onClick={() => props.setIsViewMore(!props.isViewMore)} autoFocus>
                                            <span>
                                                <strong> view less</strong>
                                            </span>
                                        </Button>
                                    </div>
                                </Box>

                            }
                            {/* <DataGrid
                        loading={loading}
                        className={classes.root}
                        rows={convAssignmentHistory}
                        columns={columns}
                        // components={{
                        //     NoRowsOverlay: CustomNoRowsOverlay,
                        // }}
                        rowsPerPageOptions={[1]}
                    /> */}

                        </> : null}
                    </>)}
        </>
    );
}
