// 08-29-2023 => ONKAR => TEXTING-1578 -REACT Upgrade
// 08-31-2023 => PRABHAT => TEXTING-1632 - Implementation changes in object history for templates
// 09-04-2023 => PRABHAT => TEXTING-1662 - Opening the history tab in Templates is making the Admin UI page Blank.
// 03-27-2023 => RAMAKANT => More space in Admin -> Templates -> History
import React, { useState, useEffect, useContext, useRef } from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import apiConfig from "../../api/apiConfig";
import { DataGrid } from "@mui/x-data-grid";
// import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../AuthContext";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import SnackBar from "../../components/SnackBar";
import LoadingButton from "@mui/lab/LoadingButton";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import {
  IconButton,
  Button,
} from "@mui/material";
import moment from "moment";
import { StyledDataGrid } from "../../components/styledComponents/StyledDataGrid";

function ObjectHistoryComp(props) {
  const appContext = useContext(AuthContext);
  const theme = useTheme();
  const inputRef = useRef();
  const [open, setOpen] = React.useState(true);
  const [isLoadHistory, setIsLoadHistory] = React.useState(true);
  const [historyData, setHistoryData] = React.useState([]);
  const { objectName,objectId,numOfRows } = props;
    const dayInMonthComparator = (v1, v2) => {
        if (!v1) v1 = new Date(null);
        if (!v2) v2 = new Date(null);
        if (v1 && v2) {
            var date1 = moment(v1);
            var date2 = moment(v2);
            var diff = date1.diff(date2, 'seconds');
            return diff;
        }
    };
  const columns = [
      {
          field: "UpdateDateTime",
          headerName: "Updated DateTime",
          flex: 1,
          minWidth: 100,
          headerClassName: "super-app-theme--header",
          type: "date",
          valueGetter: (params) => {
              if (params.value) {
                  return new Date(params.value);
              }
              return null;
          },
          valueFormatter: (params) => {
              if (params.value instanceof Date) {
                  return params.value.toLocaleTimeString('en-US', {
                      month: '2-digit',
                      day: "2-digit",
                      year: "numeric",
                      hour: 'numeric',
                      minute: 'numeric'
                  });
              }
              return "";
          },
          sortComparator: dayInMonthComparator,
      },
    {
      field: "FieldNames",
      headerName: "Field Names",
      flex: 1,
      minWidth: 100,
       headerClassName: "super-app-theme--header",
    },
    {
      field: "OriginalValues",
      headerName: "Original Values",
      flex: 1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <span title={params.value} className="table-cell-trucate" 
        style={{
          textOverflow: "ellipsis",
          display: "-webkit-box",
          maxWidth: "200px",
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
        }}
        >{params.value}</span>
      )
    },
    {
      field: "UpdatedValues",
      headerName: "Updated Values",
      flex: 1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
          <span title={params.value} 
          style={{
            textOverflow: "ellipsis",
            display: "-webkit-box",
            maxWidth: "200px",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
          >{params.value}</span>
      )
    },
    {
        field: "UpdatedBy",
        headerName: "UpdatedBy",
        flex: 1,
        minWidth: 100,
        headerClassName: "super-app-theme--header",
    }
  ];
  const handleClose = () => {
    setOpen(false);
  };
  const isValidJsonString = (jsonString) => {
    try {
      JSON.parse(jsonString);
      return true;
    } catch (error) {
      return false;
    }
  }
  const findUpdatedValues = (oldJsonString, newJsonString) => {
    // Check for null or empty JSON strings
    debugger
    if (!oldJsonString || !newJsonString) {
      console.error("JSON strings cannot be null or empty.");
      return null;
    }

    // Check for valid JSON strings
    if (!isValidJsonString(oldJsonString) || !isValidJsonString(newJsonString)) {
      console.error("Invalid JSON strings.");
      return null;
    }

    const oldObj = JSON.parse(oldJsonString);
    const newObj = JSON.parse(newJsonString);
    const updatedValues = {};
    const oldValues = {};

    Object.keys(newObj).forEach(key => {
      if (oldObj.hasOwnProperty(key) && oldObj[key] !== newObj[key]) {
        updatedValues[key] = newObj[key];
        oldValues[key] = oldObj[key];
      }
    });
    // Convert objects to JSON strings
    const updatedValuesJson = JSON.stringify(updatedValues);
    const oldValuesJson = JSON.stringify(oldValues);

    return { updatedValuesJson, oldValuesJson };
  }

  useEffect(() => {
    var requestBody = {
      "id":
        (props.objectName === 'OptOut'
          ? props.objectKeyname.phoneNumberOptOutId
          : props.objectName === 'Users'
            ? props.objectKeyname.userId
            : props.objectName === 'Teams'
              ? props.objectKeyname.teamId
              : props.objectName === 'Settings'
                ? props.objectKeyname.settingsId
                : props.objectName === 'Template'
                  ? props.objectKeyname.templateId
                  : props.objectName === 'ChatConfig'
                    ? props.objectKeyname.chatConfigId
                    : ""),
      "objectname": props.objectName,
      "numOffilterrows": props.numOfRows
    }
    InvokePostServiceCall(apiConfig.GET_OBJECT_HISTORY, requestBody)
      .then((data) => {
        let tempArr = [];
        if (data.data.historyRows.length > 0) {
          let rowCount = 1;
          data.data.historyRows.forEach((item, index) => {
            let updatetime = item.validFrom;
            let updatedbyName = item.updatedBy;
            let fieldname = '';
            let updateVal = '';
            let originalVal = '';
            item.fieldList.forEach((fItem) => {
              fieldname = fItem.fieldName;
              let result = null;
              result = fieldname == "ChatConfiguration" && findUpdatedValues(fItem.originalvalue, fItem.updateValue);
              if (result) {
                const { updatedValuesJson, oldValuesJson } = result;
                updateVal = (updatedValuesJson != null ? updatedValuesJson : '');
                originalVal = (oldValuesJson != null ? oldValuesJson : '');
              }
              else {
                updateVal = (fItem.updateValue != null ? fItem.updateValue : '');
                originalVal = (fItem.originalvalue != null ? fItem.originalvalue : '');
              }
              updatetime = item.validFrom ? item.validFrom + '+00:00' : item.validFrom;
              tempArr.push({
                UpdateDateTime: updatetime,
                UpdatedBy : updatedbyName,
                FieldNames: fieldname,
                UpdatedValues: updateVal,
                OriginalValues: originalVal,
                id: rowCount
              })
              rowCount++;
            })
          })
        }
        setHistoryData(tempArr);
        setIsLoadHistory(false);
      })
      .catch((error) => {
        console.log("Error Message", error);
        setIsLoadHistory(false);
      });
  }, [isLoadHistory]);

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column", 
                    height: "100%", 
                }}
            >
                <div
                    style={{
                        flex: 1, 
                        overflowY: "auto", 
                    }}
                >
                    <Box sx={{ ...theme.customStyle.historytableHead }}>
                        <StyledDataGrid
                            loading={isLoadHistory}
                            rows={historyData}
                            columns={columns}
                            pageSize={5}
                            getRowHeight={() => {
                              return "77px"
                            }}
                            components={{
                                NoRowsOverlay: CustomNoRowsOverlay,
                            }}
                        />
                    </Box>
                </div>
                {!props.istab && (
                    <div style={{ display: "flex", marginTop: "10px" }}>
                        <Button
                            sx={{ marginLeft: "auto" }}
                            variant="contained"
                            onClick={() => {
                                props.handleClose();
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                )}
            </div>
        </>
    );
}

export default ObjectHistoryComp