/***********************************
 * 01-09-2024 => SUDAM B CHAVAN => TEXTING-1943 Contact List UI-Upload file changes
 * 01-11-2024 => SUDAM B CHAVAN => TEXTING-1943 Contact List UI-Upload file changes & delete contact
 * 01-12-2024 => SUDAM B CHAVAN => TEXTING-1943 Contact List UI-show/hide Icon name on width changes 
 *                              =>  TEXTING-1943 Contact List UI-New Observations-new status changes for upload file
 * 01-16-2024 => SUDAM B CHAVAN => TEXTING-1943 Contact List UI - Contacts list filter 
 * 01-20-2024 => SUDAM B CHAVAN => TEXTING-1955 Multiple file uploads to Contact List one after other
 */

import React, { useState, useEffect, useContext } from "react";
import {
    IconButton,
    Button,
    Box,
    Checkbox,
    Dialog,
    DialogContent,
    DialogActions,
} from "@mui/material";
import apiConfig from "../api/apiConfig";
import { InvokeMultipartPostServiceCall, InvokePostServiceCall } from "../api/serviceUtil";
import { useTheme } from "@mui/material/styles";
import SnackBar from "./SnackBar";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
import { StyledDataGrid } from "./styledComponents/StyledDataGrid";
import AvatarFile from "./AvatarFileComp";
import moment from "moment";
import RemoveIcon from "@mui/icons-material/Remove";
import LoadingButton from "@mui/lab/LoadingButton";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';
//filter ref
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Chip from "@mui/material/Chip";
import FilterComponent from "./FilterComponent";
import { AuthContext } from "../AuthContext";


const ContactList = (props) => {
    const theme = useTheme();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackBarMsg] = useState("");
    const [isLoading, setLoading] = useState(false);//true
    const [contactList, setContactList] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 5,
    });
    const [btnLoading, setBtnLoading] = useState(false);
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [removeSelectionModel, setRemoveSelectionModel] = useState([]);
    const [rowCountState, setRowCountState] = React.useState(0);
    const [page, setPage] = useState(0);
    const [severity, setSeverity] = useState("success");
    //filter states
    const [showFilter, setShowFilter] = useState(false);
    const [filterChip, setFilterChip] = useState([]);
    const [filterTeamName, setFilterTeamName] = useState("");
    const [deletedChip, setDeletedChip] = useState("");
    const { contactsListFilterData, setContactsListFilterData, loadContactsListFilter } = useContext(AuthContext);
    const [filterApplied, setFilterApplied] = useState(false);
    const [filterLoading, setFilterLoading] = useState(false);
    const [currentpage, setCurrentPage] = useState(0);
    const columns = [
        {
            // Spread the properties from GRID_CHECKBOX_SELECTION_COL_DEF
            ...GRID_CHECKBOX_SELECTION_COL_DEF,
            // Specify the field for the checkbox column
            field: "checkBox",

            // Customize the header rendering with a Checkbox
            renderHeader: (params) => (
                <Checkbox
                    sx={{ marginLeft: "-5px" }}
                    // Handle header checkbox click to select/deselect all contact list for removal
                    onClick={() => {
                        let removeSelectionModelArr = [...removeSelectionModel];

                        // If all contact list are already selected for removal, clear the selection; otherwise, select all
                        if (contactList.length === removeSelectionModelArr.length) {
                            removeSelectionModelArr = [];
                        } else {
                            removeSelectionModelArr = [];
                            contactList.forEach((item) => {
                                removeSelectionModelArr.push(item.id);
                            });
                        }

                        // Update the selection model for removal
                        setRemoveSelectionModel([...removeSelectionModelArr]);
                    }}
                    // Set the checked state based on whether all users are selected for removal
                    checked={removeSelectionModel.length === contactList.length}
                />
            ),

            // Customize the cell rendering with a Checkbox
            renderCell: (params) => {
                return (
                    <Checkbox
                        // Handle individual checkbox click to toggle contact list selection for removal
                        onClick={() => {
                            let removeSelectionModelArr = [...removeSelectionModel];
                            let isPresent = removeSelectionModel.some(
                                (item) => item === params.row.id
                            );

                            // If the contact list is not already selected for removal, add to selection; otherwise, remove
                            if (!isPresent) {
                                removeSelectionModelArr.push(params.row.id);
                                setRemoveSelectionModel([...removeSelectionModelArr]);
                            } else {
                                let index = removeSelectionModel.indexOf(params.row.id);
                                if (index !== -1) {
                                    removeSelectionModelArr.splice(index, 1);
                                    setRemoveSelectionModel([...removeSelectionModelArr]);
                                }
                            }
                        }}
                        // Set the checked state based on whether the contact list is selected for removal
                        checked={removeSelectionModel.includes(params.row.id)}
                    />
                );
            },
            headerClassName: "super-app-theme--header",
        },
        {
            field: "firstName",
            headerName: "First Name",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        }, {
            field: "lastName",
            headerName: "Last Name",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        }, {
            field: "phoneNumber",
            headerName: "Phone Number",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "statusDescription",
            headerName: "Status Description",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },

        {
            field: "createdDate",
            headerName: "Created Date",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => moment(params.row.createdDate).local().format("MM-DD-YYYY"),
        },
    ];
    useEffect(() => {
        getContactList();
    }, [page, loadContactsListFilter]);

    const getContactList =()=>{
        setLoading(true);
        let requestBody = {
            pageSize,
            pageIndex: page,
            communicationListId: props.communicationListId,
            ...contactsListFilterData && contactsListFilterData
        }
        InvokePostServiceCall(apiConfig.GET_CONTACT_LIST, requestBody)
            .then((data) => {
                setContactList(data.data.contactLists);
                setRowCountState(data.data.totalCount)
                setLoading(false);
            })
            .catch((error) => {
                setSnackBarMsg(error.message);
                setSeverity("error");
                setOpenSnackbar(true);           
                setLoading(false);
            });
    }
    const uploadFile = (file) => {
        // Check if a file is provided
        if (file) {
            // Create a new FormData object to send the file and additional data
            const formData = new FormData();
            formData.append('fileUpload', file); // Append the file to the FormData
            formData.append('communicationListId', props.communicationListId); // Append additional data (communicationListId)

            // Set loading state to true, indicating that the file is being uploaded
            setLoading(true);

            // Make a multipart POST service call to upload the file
            InvokeMultipartPostServiceCall(apiConfig.UPLOAD_CONTACT_LIST_CSV, formData)
                .then((data) => {
                    // Successful response - set loading state to false
                    props.onContactListUpload();
                    setLoading(false);
                })
                .catch((error) => {
                    // Error occurred during the service call - handle the error
                    setSnackBarMsg(error.message);
                    setSeverity("error");
                    setOpenSnackbar(true);
                    setLoading(false);
                });
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };
    const deleteTeamMembers = () => {
        // Set loading state to true, indicating that the deletion operation is in progress.
        setBtnLoading(true);
        // Prepare the request body containing the contactListIds to be deleted.
        let requestBody = {
            contactListIds: removeSelectionModel
        };
        // Make a POST service call to delete the contact lists.
        InvokePostServiceCall(apiConfig.DELETE_CONTACT_LIST, requestBody)
            .then((data) => {
                // Refresh the contact list.
                getContactList();
                // Close the modal.
                handleClose();
                // Clear the selection model.
                setRemoveSelectionModel([]);
                // Show success message in a snackbar.
                setSnackBarMsg("Contact list Removed Successfully");
                setSeverity("success");
                setOpenSnackbar(true);
                // Set loading state to false.
                setBtnLoading(false);
            })
            .catch((error) => {
                // Log the error and handle it.
                console.log(error, "Error Message");
                // Show error message in a snackbar.
                setSnackBarMsg(error.message);
                setSeverity("error");
                setOpenSnackbar(true);
                // Set loading state to false.
                setBtnLoading(false);
            });
    };

    const handleClose = () => {
        setOpenWarningDialog(false);
    };
    //to delete the selected filter chips
    const handleDelete = (chipToDelete) => () => {
        setFilterChip((chips) => chips.filter((chip) => chip !== chipToDelete));
        setDeletedChip(chipToDelete);
    };
    return (
        <>
            <SnackBar
                openSnackbar={openSnackbar}
                handleCloseSnackbar={handleCloseSnackbar}
                severity={severity}
                userMessage={snackbarMsg}
            /> 
            <Box sx={{ px: "10px", ...theme.customStyle.tableRow }}>
                <div>
                    <IconButton
                        disabled={props.isReadOnly || removeSelectionModel.length < 1}
                        sx={{
                            backgroundColor: "red",
                            color: "white",
                            "&:hover": { backgroundColor: "#db0000" },
                            height: "40px",
                            width: "40px",
                        }}
                        onClick={() => {
                            setOpenWarningDialog(true);
                        }}
                    >
                        <RemoveIcon />
                    </IconButton>
                    <IconButton
                        sx={{ marginLeft: "5px" }}
                        onClick={() => {
                            setShowFilter(!showFilter);
                        }}
                    >
                        <FilterAltIcon />
                    </IconButton>
                    {filterChip.map((item) => <Chip label={item} onDelete={handleDelete(item)} />
                    )}
                </div>
                <AvatarFile
                    title="Upload file"
                    label="Contact List"
                    returnFormat="file"
                    isMobileView={props.isMobileView}
                    onChange={(file) => uploadFile(file)}
                    acceptedFiles={["text/csv"]}
                    maxFileSize={10}
                    iconName={"Upload File"}
                    disabled={isLoading || props.isReadOnly || ["Upload Contacts"].includes(props.status)}
                />

            </Box>
            {
                props.status === "Upload Contacts" &&
                <Box sx={{ display: "flex", justifyContent: "center", px: "10px", ...theme.customStyle.tableRow }}>
                    <h5 style={{ color: "green" }}> Contacts list upload is in progress.</h5>
                </Box>
            }
            <FilterComponent
                showFilter={showFilter}
                filterChip={filterChip}
                setFilterChip={setFilterChip}
                deletedChip={deletedChip}
                setDeletedChip={setDeletedChip}
                url={apiConfig.GET_CONTACT_LIST}
                setShowFilter={setShowFilter}
                currentPage={"ContactsList"}
                setFilteredUsers={setContactList}
                setFilterApplied={setFilterApplied}
                setIsLoading={setFilterLoading}
                isLoading={filterLoading}
                filterData={contactsListFilterData}
                setFilterData={setContactsListFilterData}
                setCurrentPage={setCurrentPage}
                filterTeamName={filterTeamName}
                setFilterTeamName={setFilterTeamName}
                setPage={setPage}
                setRowCountState={setRowCountState}
                reqPayload={{
                    pageSize,
                    pageIndex: 0,
                    communicationListId: props.communicationListId
                    }}
            />
            <Box sx={theme.customStyle.tableHead}>
                <StyledDataGrid
                    loading={isLoading || filterLoading}
                    page={page}
                    onPageChange={(newPage) => setPage(newPage)}
                    rows={contactList}
                    columns={columns}
                    pageSize={pageSize}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                    components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                    }}
                    paginationMode="server"
                    rowCount={rowCountState}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}                
                />
            </Box>

            <Dialog
                open={openWarningDialog}
                onClose={handleClose}
                fullWidth
                maxWidth={"sm"}
            >
                <DialogContent>
                    <h6>
                        Are you sure you want to remove {removeSelectionModel.length} contact List
                        from communication List ?
                    </h6>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loading={btnLoading}
                        size="small"
                        onClick={() => {
                            deleteTeamMembers();
                        }}
                    >
                        Remove
                    </LoadingButton>
                    <Button
                        size="small"
                        onClick={() => {
                            setOpenWarningDialog(false);
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ContactList;  