////10/31/2022-> TEXTING-714 - passed team id from conversation object to load the Agents..
////06/21/2022->SUDAM B CHAVAN - TEXTING-1348 - LOW PRIORITY: Able to transfer conversation from X team to Y Team, even if the conversation is in active with Y team
////09/07/2023->ONKAR - TEXTING-1682 - Exists in Prod - From Omni channel Team conversations Instead of selected Team, Conversation is assigning to Agent preferred Team.
import {
    Badge,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import React, { useEffect } from "react";
import apiConfig from "../api/apiConfig";
import { InvokePostServiceCall } from "../api/serviceUtil";
import { styled } from "@mui/material/styles";
import { dataService } from "../dataService";

export const AssignConvFromTeamConversation = (props) => {
    useEffect(() => {
        setSelectedUser("");
        loadTeamAgents();
    },[])
    
    const [agents, setAgents] = React.useState([]);
    const [selectedUser, setSelectedUser] = React.useState();
    const [confirmAssignment, setConfirmAssignment] = React.useState(false);
    const [confirmAssignmentMsg, setConfirmAssignmentMsg] = React.useState("");
    const [showLoader, setShowLoader] = React.useState(false);

    const loadTeamAgents = () => {
        debugger
        setShowLoader(true);
        let msgReq = {
            teamId: props.state.conversation.teamId,
            isActive: true,
            SortByName: true,
            SortByStatus: true
        };
        InvokePostServiceCall(apiConfig.GET_USERS, msgReq)
            .then((data) => {
                setAgents(data.data.userDetails);
               setShowLoader(false);
            })
            .catch((error) => {
                props.openMainSnackbar(error.message);
                setShowLoader(false);
                console.log(error.message);
            });
    };

    const handleChange = (event) => {
        setSelectedUser(event.target.value); //event.target.value.user.userId
    };

    const handleAssignConversation = (forceAssign) => (event) => {
        if (selectedUser != null) {
            setShowLoader(true);
            let msgReq = {
                conversation: {
                    conversationId: props.conversation.conversationId,
                    CustomerName: props.conversation.customerName,
                    CustomerPhoneNumber: props.conversation.customerPhoneNumber,
                    AssignedDate: new Date().toISOString(),
                    status: props.conversation.status,
                    PreAssigneeId: props.conversation.preAssigneeId,
                },
                teamId: props.conversation.teamId,
                userId: selectedUser.user.userId,
                requestedUserId: props.state.appContext.userInfo.userId,
                forceAssign: forceAssign
            };

            InvokePostServiceCall(apiConfig.ASSIGN_CONVERSATION, msgReq)
                .then((data) => {

                    if (data.data.status.toLowerCase() === "success") {
                        props.onAssignConvSuccess(selectedUser.user.userId);
                    }
                    else if (data.data.status.toLowerCase() === "missedconvassigned") {
                        setConfirmAssignmentMsg(data.data.statusDescription);
                        setConfirmAssignment(true);
                    }
                    else if (props.conversation.status == 'InActive') {
                        props.onAssignConvSuccess(selectedUser.user.userId);
                    }
                    else if (props.conversation.status === 'FailedToAssignCrossTeam') {
                        dataService.setData({ showSelfAssignAlert: data.data.statusDescription });
                    }
                    if (forceAssign) {
                        setConfirmAssignment(false);
                    }
                    setShowLoader(false);
                    props.handleModalClose();

                })
                .catch((error) => {

                    props.openMainSnackbar(error.message);
                    setShowLoader(false);
                    props.handleModalClose();
                    console.log(error.message);
                });
        }
    };

    const handleConfirmAssignClose = () => {
        setConfirmAssignment(false);
        setConfirmAssignmentMsg("");
    };

    return (
        <>
            <Dialog
                open={true}
                onClose={props.handleModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={props.dialogFullWidth}
            >
                <DialogContent>
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="demo-simple-select-label">Agents</InputLabel>
                        <Select
                            labelId="select-agent"
                            id="select-agent"
                            label="Agents"
                            onChange={handleChange}
                            renderValue={(selected) => selected.user.userName}
                        >
                            {agents?.map((a) => {
                                let badgeColor = "#b8bfb3";
                                if (a.user.status === "Available" && a.user.connectionStatus === true) {
                                    badgeColor = "#44b700"
                                } else if (a.user.status === "Busy" && a.user.connectionStatus === true) {
                                    badgeColor = "red"
                                } else {
                                    badgeColor = "#b8bfb3"
                                }
                                return (
                                    <MenuItem key={a.user.userId} value={a}>
                                        <div>
                                            <div>
                                                <div
                                                    style={{
                                                        position: "relative",
                                                        width: "10px",
                                                        height: "10px",
                                                        borderRadius: "6px",
                                                        backgroundColor: badgeColor,
                                                        display: "inline-block",
                                                    }}
                                                ></div>
                                                <span style={{ marginLeft: "10px" }}>
                                                    {a.user.userName}
                                                </span>
                                            </div>
                                            <div>
                                                <span
                                                    style={{
                                                        marginLeft: "20px",
                                                        fontSize: "13px",
                                                        color: "#8080809e",
                                                        position: "relative",
                                                        bottom: "5px",
                                                    }}
                                                >{`${a.activeConversationCount} Active Conversations`}</span>
                                            </div>
                                        </div>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <DialogContentText>
                        {showLoader && (
                            <Box sx={{ display: "flex", marginLeft: "40%" }}>
                                <CircularProgress />
                            </Box>
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={!selectedUser}
                        onClick={handleAssignConversation(false)}>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <span>
                                <strong> Assign</strong>
                            </span>
                        </div>
                    </Button>
                    <Button onClick={props.handleModalClose}>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <span>
                                <strong> Cancel</strong>
                            </span>
                        </div>
                    </Button>
                </DialogActions>
            </Dialog>
            {confirmAssignment && (
                <Dialog
                    open={confirmAssignment}
                    onClose={handleConfirmAssignClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={props.dialogFullWidth}
                >
                    <DialogTitle>Alert</DialogTitle>
                    <Divider />
                    <DialogContent>
                        <div style={{ display: "flex", padding: "5px" }}>
                            <Typography gutterBottom>{confirmAssignmentMsg}</Typography>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleAssignConversation(true)}>
                            <div style={{ display: "flex" }}>
                                <span>
                                    <strong> Assign </strong>
                                </span>
                            </div>
                        </Button>
                        <Button onClick={handleConfirmAssignClose}>
                            <div style={{ display: "flex" }}>
                                <span>
                                    <strong> Cancel</strong>
                                </span>
                            </div>
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};