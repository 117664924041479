/***************
* 26-07-2023 => PRABHAT => Texting-1368 Added Created Date, Last Modified Date, Is Admin to Settings Grid.
* 04-08-2023 => SUDAM B CHAVAN => reset page index to 1 page on filter data
* 08-30-2023 => ONKAR => TEXTING-1578 -REACT Upgrade
* 10-27-2023 => SUDAM B CHAVAN => TEXTING-1675 - Created and Modified date fields is showing as undefined in Update Settings page.
***********/
import React, { useState, useEffect, useContext } from "react";
import { Box, Button, Checkbox } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AuthContext } from "../../AuthContext";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import apiConfig from "../../api/apiConfig";
import { useNavigate } from "react-router-dom";
import SnackBar from "../../components/SnackBar";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid } from "@mui/x-data-grid";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import HistoryIcon from '@mui/icons-material/History';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ObjectHistoryComp from "../teams/ObjectHistoryComp";
import moment from "moment";

function ApplicationSettingsPage() {
    const theme = useTheme();
    const [appSettingsData, setAppSettingsData] = useState([]);
    const [tempArr, setTempArr] = useState([]);
    const [loading, setLoading] = useState(true);
    const { messageApiToken } = useContext(AuthContext);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [openTemplateHistory, setOpenTemplateHistory] = React.useState(false);
    const [settingDetails, setSettingDetails] = React.useState([]);
    const [page, setPage] = useState(0);
    const navigate = useNavigate();

    const columns = [
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "settingsKey",
            headerName: "Setting Key",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "type",
            headerName: "Type",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "settingsValue",
            headerName: "Setting Value",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "createdDate",
            headerName: "Created Date",
            flex: 1,
            minWidth: 120,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => moment(params.row.createdDate).local().format("MM-DD-YYYY"),
        },
        {
            field: "lastModifiedDate",
            headerName: "Last Modified Date",
            flex: 1,
            minWidth: 120,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => moment(params.row.lastModifiedDate).local().format("MM-DD-YYYY"),
        },
        {
            field: "isActive",
            headerName: "Is Active",
            flex: 1,
            minWidth: 50,
            filterable: false,

            headerClassName: "super-app-theme--header",
            renderCell: (params) => {
                return (
                    <div style={{ marginLeft: "5px" }}>
                        <Checkbox disabled checked={params.row.isActive} />
                    </div>
                );
            },
        },
        {
            field: "isadminSetting",
            headerName: "Is Admin",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => {
                return (
                    <div style={{ marginLeft: "5px" }}>
                        <Checkbox disabled checked={params.row.isadminSetting} />
                    </div>
                );
            },
        },
        {
            field: "history",
            headerName: "-",
            flex: 1,
            minWidth: 50,
            filterable: false,

            headerClassName: "super-app-theme--header",
            renderCell: (params) => {
                return (
                    <div style={{ marginLeft: "5px" }}>
                        <IconButton
                            //sx={theme.customStyle.HistoryIconBtn}
                            onClick={(e) => {
                                setSettingDetails(params.row);
                                setOpenTemplateHistory(true);
                            }}
                        >
                            <HistoryIcon />
                        </IconButton>
                    </div>
                );
            }
        }

    ];

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };
    const handleClose = () => {
        setOpenTemplateHistory(false);
    };

    function getSettings() {
        setLoading(true);
        InvokePostServiceCall(apiConfig.GET_SETTINGS, {})
            .then((data) => {
                if (data.data.settings.length > 0) {
                    let tempArr = [];
                    data.data.settings.forEach((item, index) => {
                        item.id = index;
                        item.createdDate = moment(item.createdDate).local().format("MM-DD-YYYY") + ", " + moment(item.createdDate).local().format("LT")
                        item.lastModifiedDate = moment(item.lastModifiedDate).local().format("MM-DD-YYYY") + ", " + moment(item.lastModifiedDate).local().format("LT")
                        tempArr.push(item);
                    });
                    setAppSettingsData(tempArr);
                    setTempArr(tempArr);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error, "error message");
                setSnackbarMsg(error.message);
                setOpenSnackbar(true);
                setLoading(false);
            });
    }
    useEffect(() => {
        if (messageApiToken) {
            getSettings();
        }
    }, [messageApiToken]);

    function filterTable(value) {
        let usArr = [];
        let val = value?.toLowerCase();
        setPage(0);
        tempArr.forEach((item) => {

            if (
                item.name?.toLowerCase().includes(val) ||
                item.settingsKey?.toLowerCase().includes(val) ||
                item.type?.toLowerCase().includes(val) ||
                item.settingsValue?.toLowerCase().includes(val)
            ) {
                usArr.push(item);
            }
        });
        setAppSettingsData(usArr);
    }

    return (
        <>
            <SnackBar
                openSnackbar={openSnackbar}
                handleCloseSnackbar={handleCloseSnackbar}
                severity={"error"}
                userMessage={snackbarMsg}
            />
            <div>
                <Box sx={theme.customStyle.tableRow}>
                    <h2 style={{ marginLeft: 10 }}>Application Settings</h2>
                </Box>
            </div>
            <div style={{ display: "flex", alignItems: "flex-start", paddingLeft: 10 }}>
                <Box sx={theme.customStyle.tableRow}>
                    <Button
                        sx={theme.customStyle.modalbtn}
                        onClick={() => {
                            navigate("/addUpdateSettings", {
                                state: { isAddSettings: true },
                            });
                        }}
                    >
                        <AddIcon />
                    </Button>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <TextField
                            sx={{ marginRight: "10px", marginBottom: "10px" }}
                            size="small"
                            type="search"
                            variant="outlined"
                            margin="normal"
                            id="outlined-search"
                            label=""
                            placeholder="Settings Search"
                            autoComplete="off"
                            onChange={(e) => {
                                if (e.target.value) {
                                    filterTable(e.target.value);
                                }
                                else {
                                    setAppSettingsData(tempArr);
                                }

                            }}
                            InputLabelProps={{
                                shrink: false,
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                </Box>
            </div>

            <Box sx={theme.customStyle.tableHead}>
                <DataGrid
                    loading={loading}
                    rows={appSettingsData}
                    disableSelectionOnClick
                    components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                    }}
                    onRowDoubleClick={(e) => {
                        navigate("/addUpdateSettings", {
                            state: { isAddSettings: false, settingDetails: e.row },
                        });
                    }}
                    getRowClassName={(params) =>
                        params.row.id % 2 === 0
                            ? "super-app-theme--even"
                            : "super-app-theme--odd"
                    }
                    columns={columns}
                    pageSize={10}
                    page={page}
                    onPageChange={(page) => setPage(page)}
                    rowsPerPageOptions={[10]}
                    columnVisibilityModel={{ history: false }} //hide the columns from the grid
                />
            </Box>
            <Dialog open={openTemplateHistory} onClose={handleClose} fullWidth maxWidth={"lg"}>
                <DialogContent>
                    <ObjectHistoryComp
                        open={true}
                        handleClose={handleClose}
                        objectName="Settings"
                        objectKeyname={settingDetails}
                        numOfRows={10}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
}
export default ApplicationSettingsPage;
