/**********
 * 9/11/2022 Texting-766 - removed auto suggetion
 * 11/11/2022 Texting-759=> Resetting users array before search hense loader will be visible
 * *11-11/2022 TextField:766=>disabled auto suggetion
 * 11-16-2022 => ONKAR => Texting-788 => Team name added in chip for selected team in filter.
 * 11-18-2022 => ONKAR => Texting-788 => resetting the table page number to 0 after search or clear filter.
 * 11-28-2022 => yogesh => Texting-866 => removed auto suggestion
 * 01-16-2024 => SUDAM B CHAVAN => TEXTING-1943 Contact List UI - Contacts list filter 
 * 01-16-2024 => SUDAM B CHAVAN => TEXTING-1943 - changes for filter chips are not not deleting 
 * 01-24-2024 => SUDAM B CHAVAN => TEXTING-1954 Contact list pending observations
 * 01-24-2024 => SUDAM B CHAVAN => TEXTING-1956 Contact List Filter
 * 01-29-2024 => SUDAM B CHAVAN => TEXTING-1956 Contact List Filter- observations
 * 01-30-2024 => SUDAM B CHAVAN => TEXTING-1956 Contact List Filter- observations
 *************/

import React, { useState, useEffect, useContext } from "react";
import {
    TextField,
    Button,
    FormControl,
    Select,
    MenuItem,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { useTheme } from "@mui/material/styles";
import { InvokePostServiceCall } from "../api/serviceUtil";
import moment from "moment";
import apiConfig from "../api/apiConfig";
import { AuthContext } from "../AuthContext";
import SnackBar from "./SnackBar";
import LoadingButton from '@mui/lab/LoadingButton';
import { dataService } from "../dataService";

function FilterComponent(props) {
    const {
        showFilter,
        filterChip,
        setFilterChip,
        deletedChip,
        setDeletedChip,
        url,
        setFilteredUsers,
        setIsLoading,
        setFilterApplied,
        currentPage,
        setShowFilter,
        setCurrentPage,
        filterData, setFilterData, isLoading,
        usersPage, setFilterTeamName,
        reqPayload,
        setRowCountState        
    } = props;
    const theme = useTheme();
    const { userInfo, contactsFilterData, setContactsFilterData, messageApiToken,
        loadUserFilter, setLoadUserFilter,
        loadContactFilter, setLoadContactFilter,
        usersFilterData, setUsersFilterData,
        contactsListFilterData, setContactsListFilterData,
        loadContactsListFilter, setLoadContactsListFilter,
    } = useContext(AuthContext);
    const [teamsList, setTeamsList] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");

    useEffect(() => {
        if (deletedChip) {
            const updatedFilterData = { ...filterData };

            switch (deletedChip) {
                case "First Name":
                    updatedFilterData.firstName = "";
                    break;
                case "Last Name":
                    updatedFilterData.lastName = "";
                    break;
                case "Phone":
                    updatedFilterData.phone = "";
                    break;
                case "Email":
                    updatedFilterData.email = "";
                    break;
                case "Is Active":
                    updatedFilterData.isActive = "";
                    break;
                case "Team":
                    updatedFilterData.teamId = "";
                    break;
                case "Status":
                    updatedFilterData.status = "";
                    break;
                case "From Date":
                    updatedFilterData.fromDate = "";
                    break;
                case "To Date":
                    updatedFilterData.toDate = "";
                    break;
                default:
                    break;
            }
            setFilterData(updatedFilterData);
            setDeletedChip("");
            updateFilter();
            if (filterChip.length > 0) {
                applyFilter();
            }
            else {
                clearFilter();
            }

        }
    }, [deletedChip]);

    useEffect(() => {
        if ((currentPage === "Users" && loadUserFilter) ||
            (currentPage === "Contacts" && loadContactFilter) ||
            (currentPage === "ContactsList" && loadContactsListFilter)) {
            applyFilter();
        }
    }, [loadUserFilter, loadContactFilter, loadContactsListFilter])

    useEffect(() => {
        if (messageApiToken) {
            setTeamsList(userInfo.role == "Admin" ? userInfo.allTeams : userInfo.teams);
        }
    }, [messageApiToken]);

    useEffect(() => {
        if (filterData.teamId) {
            if (filterData.teamId !== "None" && teamsList.length > 0) {
                teamsList.forEach((item) => {
                    if (item.teamId === filterData.teamId) {
                        setFilterTeamName(item.teamName);
                    }
                });
            } else {
                setFilterTeamName("None");
            }
        }
    }, [teamsList]);

    function applyFilter() {
        let tempArr = [];
        let tempArrOne = [];
        let filterChipArr = filterChip;
        setFilterApplied(true);

        setIsLoading(true);
        let requestBody = {
            firstName: deletedChip === "First Name" ? "" : filterData.firstName,
            lastName: deletedChip === "Last Name" ? "" : filterData.lastName,
            phoneNumber: deletedChip === "Phone" ? "" : filterData.phone,
            ...currentPage === "ContactsList" ? {
                status: deletedChip === "Status" ? "" : filterData.status,
                fromDate: deletedChip === "From Date" ? "" : filterData.fromDate ,
                toDate: deletedChip === "To Date" ? "" : filterData.toDate,
                ...reqPayload
            }
                :
                {
                    email: deletedChip === "Email" ? "" : filterData.email,
                    isActive: deletedChip === "Is Active" ? "" : filterData.isActive === "None" ? "" : filterData.isActive,
                    teamId: deletedChip === "Team" ? "" : filterData.teamId === "None" ? "" : filterData.teamId,
                }
        };
        InvokePostServiceCall(url, requestBody)
            .then((data) => {
                //first & last name are common to all pages to adding outside the block
                if (filterData.firstName !== "" && deletedChip !== "First Name") {
                    tempArr.push("First Name");
                }
                if (filterData.lastName !== "" && deletedChip !== "Last Name") {
                    tempArr.push("Last Name");
                }
                if (filterData.phone !== "" && deletedChip !== "Phone") {
                    tempArr.push("Phone");
                }
                if (filterData.firstName === "") {
                    filterChipArr.splice(filterChipArr.indexOf("First Name"), 1);
                }
                if (filterData.lastName === "") {
                    filterChipArr.splice(filterChipArr.indexOf("Last Name"), 1);
                }
                if (filterData.phone === "") {
                    filterChipArr.splice(filterChipArr.indexOf("Phone"), 1);
                }
                 if(currentPage === "ContactsList") {
                     if (filterData.status !== "" && deletedChip !== "Status") {
                         tempArr.push("Status");
                     }
                     if (filterData.status === "") {
                         filterChipArr.splice(filterChipArr.indexOf("Status"), 1);
                     }
                     //From Date
                     if (filterData.fromDate !== "" && deletedChip !== "From Date") {
                         tempArr.push("From Date");
                     }
                     if (filterData.fromDate === "") {
                         filterChipArr.splice(filterChipArr.indexOf("From Date"), 1);
                     }
                     //To Date
                     if (filterData.toDate !== "" && deletedChip !== "To Date") {
                         tempArr.push("To Date");
                     }
                     if (filterData.toDate === "") {
                         filterChipArr.splice(filterChipArr.indexOf("To Date"), 1);
                     }
                 }
                 else{
                     if (filterData.email !== "" && deletedChip !== "Email") {
                         tempArr.push("Email");
                     }
                     if (filterData.isActive !== "" && deletedChip !== "Is Active") {
                         if (currentPage === "Users") {
                             tempArr.push("Is Active");
                         }
                     }
                     if (filterData.teamId !== "" && deletedChip !== "Team") {
                         tempArr.push("Team");
                     }
                     if (filterData.email === "") {
                         filterChipArr.splice(filterChipArr.indexOf("Email"), 1);
                     }
                     if (filterData.isActive === "") {
                         filterChipArr.splice(filterChipArr.indexOf("Is Active"), 1);
                     }
                     if (filterData.teamId === "") {
                         filterChipArr.splice(filterChipArr.indexOf("Team"), 1);
                     }
                 }
                if (filterChipArr.length > 0) {
                    tempArr.forEach((item) => {
                        const present = filterChipArr.find((tempItem) => tempItem === item);
                        if (present !== item) {
                            tempArrOne.push(item);
                        }
                    });
                    setFilterChip(filterChipArr.concat(tempArrOne));
                } else {
                    setFilterChip(filterChipArr.concat(tempArr));
                }

                let userArr = [];
                let filteredArr;

                if (currentPage === "Users") {
                    filteredArr = data.data.filteredUsers;
                    filteredArr.forEach((item, index) => {
                        userArr.push({
                            azureAdid: item.azureAdid,
                            connectionStatus: item.connectionStatus,
                            createdBy: item.createdBy,
                            createdDate: moment(item.createdDate).local().format("MM-DD-YYYY"),
                            emailId: item.emailId,
                            firstName: item.firstName,
                            id: index,
                            isActive: item.isActive,
                            lastModifiedBy: item.lastModifiedBy,
                            lastModifiedDate: moment(item.lastModifiedDate)
                                .local()
                                .format("MM-DD-YYYY"),
                            lastName: item.lastName,
                            lastPreAssignmentDate: item.lastPreAssignmentDate,
                            phoneNumber: item.phoneNumber,
                            role: item.role,
                            status: item.status,
                            department: item.department,
                            userId: item.userId,
                            userName: item.userName,
                            clientFacingPhoneNum: item.clientFacingPhoneNum,
                        });
                    });
                    setFilteredUsers(userArr);
                    props.setFilteredUsersCopy(userArr)
                } 
                else if (currentPage === "ContactsList")
                {
                    setRowCountState(data.data.totalCount)
                    setFilteredUsers(data.data.contactLists);
                } else {
                    filteredArr = data.data.filteredContacts;
          
                    filteredArr.forEach((item, index) => {
                        userArr.push({
                            contactAttributes: item.contactAttributes,
                            id: item.contactId,
                            createdBy: item.createdBy,
                            createdDate: moment(item.createdDate).local().format("MM-DD-YYYY"),
                            email: item.email,
                            firstName: item.firstName,
                            isActive: item.isActive,
                            lastModifiedBy: item.lastModifiedBy,
                            lastModifiedDate: moment(item.lastModifiedDate)
                                .local()
                                .format("MM-DD-YYYY"),
                            lastName: item.lastName,
                            mobileNumber: item.mobileNumber,
                            name: item.name,
                            contactId: item.contactId,
                        });
                    });
                    setFilteredUsers(userArr);
                    props.setFilteredUsersCopy(userArr)
                }

                if (filterData.teamId === "None") {
                    setFilterTeamName("None");

                } else {
                    teamsList.forEach((item) => {
                        if (item.teamId === filterData.teamId) {
                            setFilterTeamName(item.teamName)
                        }
                    })
                }

                setIsLoading(false);
                setShowFilter(false);
                setCurrentPage(0);
            })
            .catch((error) => {
                debugger;
                setSnackbarMsg(error.message);
                setOpenSnackbar(true);
                setIsLoading(false);
                setShowFilter(false);
                console.log(error);
            });
    }

    function updateFilter() {
        let requestBody = {
            userDetail: {
                user: {
                    userId: userInfo.userId,
                },
            },
        };
        if (currentPage === "Users") {
            requestBody.userDetail.user.userPreferences = JSON.stringify({
                ContactsFilter: {
                    firstName: contactsFilterData.firstName,
                    lastName: contactsFilterData.lastName,
                    phone: contactsFilterData.phone,
                    email: contactsFilterData.email,
                    teamId: contactsFilterData.teamId,
                },
                UsersFilter: {
                    firstName: deletedChip === "First Name" ? "" : usersFilterData.firstName,
                    lastName: deletedChip === "Last Name" ? "" : usersFilterData.lastName,
                    phone: deletedChip === "Phone" ? "" : usersFilterData.phone,
                    email: deletedChip === "Email" ? "" : usersFilterData.email,
                    teamId: deletedChip === "Team" ? "" : usersFilterData.teamId,
                    isActive: deletedChip === "Is Active" ? "" : usersFilterData.isActive
                },
                ContactsListFilter: contactsListFilterData,
                DefaultTeamId: userInfo.teamId,
            })
        } 
        //updating the contacts list filter in user Preferences
        else if (currentPage === "ContactsList") {
            requestBody.userDetail.user.userPreferences = JSON.stringify({
                ContactsListFilter: {
                    status: deletedChip === "Status" ? "" : contactsListFilterData.status,
                    firstName: deletedChip === "First Name" ? "" : contactsListFilterData.firstName,
                    lastName: deletedChip === "Last Name" ? "" : contactsListFilterData.lastName,
                    phone: deletedChip === "Phone" ? "" : contactsListFilterData.phone,
                    fromDate: deletedChip === "From Date" ? "" : contactsListFilterData.fromDate,
                    toDate: deletedChip === "To Date" ? "" : contactsListFilterData.toDate,
                },
                ContactsFilter: contactsFilterData,
                UsersFilter: usersFilterData,
                DefaultTeamId: userInfo.teamId,
            })
        }else {
            requestBody.userDetail.user.userPreferences = JSON.stringify({
                ContactsFilter: {
                    firstName: deletedChip === "First Name" ? "" : contactsFilterData.firstName,
                    lastName: deletedChip === "Last Name" ? "" : contactsFilterData.lastName,
                    phone: deletedChip === "Phone" ? "" : contactsFilterData.phone,
                    email: deletedChip === "Email" ? "" : contactsFilterData.email,
                    teamId: deletedChip === "Team" ? "" : contactsFilterData.teamId,
                },
                UsersFilter: {
                    firstName: usersFilterData.firstName,
                    lastName: usersFilterData.lastName,
                    phone: usersFilterData.phone,
                    email: usersFilterData.email,
                    teamId: usersFilterData.teamId,
                    isActive: usersFilterData.isActive
                },
                ContactsListFilter: contactsListFilterData,
                DefaultTeamId: userInfo.teamId,
               
            })

        }
        InvokePostServiceCall(apiConfig.SAVE_USER_PREFERENCES, requestBody)
            .then((data) => {
                console.log(data, "saveUserPreferences");
            })
            .catch((error) => {
                console.log(error);
            });
    }


    function clearFilter() {
        setFilterData({
            fullName: "",
            firstName: "",
            lastName: "",
            phone: "",
            email: "",
            isActive: "",
            teamId: "",
            status: "",
            fromDate: "",
            toDate: ""
        });
        setFilterChip([]);
        setFilterApplied(false);
        props.setPage(0);

        let requestBody = {
            userDetail: {
                user: {
                    userId: userInfo.userId,
                },
            },
        };
        if (currentPage === "Users") {
            setLoadUserFilter(false);
            setUsersFilterData({
                firstName: "",
                lastName: "",
                phone: "",
                email: "",
                isActive: "",
                teamId: "",
            });
            requestBody.userDetail.user.userPreferences = JSON.stringify({
                ContactsFilter: {
                    firstName: contactsFilterData.firstName,
                    lastName: contactsFilterData.lastName,
                    phone: contactsFilterData.phone,
                    email: contactsFilterData.email,
                    teamId: contactsFilterData.teamId,
                },
                UsersFilter: {
                    firstName: "",
                    lastName: "",
                    phone: "",
                    email: "",
                    teamId: "",
                    isActive: ""
                },
                ContactsListFilter: contactsListFilterData,
                DefaultTeamId: userInfo.teamId,
            })
        } 
       else if (currentPage === "ContactsList") {
            setLoadContactsListFilter(false);
            setContactsListFilterData({
                status: "",
                firstName:"",
                lastName: "",
                phone: "",
                fromDate: "",
                toDate: ""
            });
            requestBody.userDetail.user.userPreferences = JSON.stringify({
                ContactsFilter: contactsFilterData,
                UsersFilter: usersFilterData,
                ContactsListFilter: {
                    status: "",
                    firstName: "",
                    lastName: "",
                    phone: "",
                    fromDate: "",
                    toDate: ""
                },
                DefaultTeamId: userInfo.teamId,
            })
        } else {
            setLoadContactFilter(false);
            setContactsFilterData({
                firstName: "",
                lastName: "",
                phone: "",
                email: "",
                teamId: "",
            });
            requestBody.userDetail.user.userPreferences = JSON.stringify({
                ContactsFilter: {
                    firstName: "",
                    lastName: "",
                    phone: "",
                    email: "",
                    teamId: "",
                },
                UsersFilter: {
                    firstName: usersFilterData.firstName,
                    lastName: usersFilterData.lastName,
                    phone: usersFilterData.phone,
                    email: usersFilterData.email,
                    teamId: usersFilterData.teamId,
                    isActive: usersFilterData.isActive
                },
                ContactsListFilter: contactsListFilterData,
                DefaultTeamId: userInfo.teamId,
            })

        }
        InvokePostServiceCall(apiConfig.SAVE_USER_PREFERENCES, requestBody)
            .then((data) => {
                console.log(data, "saveUserPreferences");
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const handleChangeIsActive = (event) => {
        setFilterData({ ...filterData, isActive: event.target.value });
    };
    const handleChangeTeam = (event) => {
        setFilterData({ ...filterData, teamId: event.target.value });
    };
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };
    const handleSearch = (event) => {
        setFilteredUsers([]) //reseting the filtered user before search
        props.setPage(0);
        if (currentPage === "Contacts") {
            props.setFilteredUsersCopy([])
            if (teamsList.length == 0) {
                dataService.setData({ showAlertMessage: "You are not assigned to any team, Please contact your supervisor or administrator" });
            }
            else {
                if (!loadContactFilter) {
                    setLoadContactFilter(true);
                }
                else {
                    applyFilter();
                }
            }
        }
        else if (currentPage === "ContactsList") {
            if (!loadContactsListFilter) {
                setLoadContactsListFilter(true);
            }
            else {
                applyFilter();
            }
        }
        else {
            if (!loadUserFilter) {
                setLoadUserFilter(true);
            }
            else {
                applyFilter();
            }
        }
        updateFilter();
    };
    const fakeInputStyle = { opacity: 0, float: 'left', border: 'none', height: '0', width: '0' }
    return (
        <>
            <SnackBar
                openSnackbar={openSnackbar}
                handleCloseSnackbar={handleCloseSnackbar}
                severity={"error"}
                userMessage={snackbarMsg}
            />
            <Collapse in={showFilter}>
                <div>
                    <div style={theme.customStyle.filterMainDiv}>
                        <div style={theme.customStyle.textFieldMainDiv}>
                            <div style={theme.customStyle.textFieldLabel}>First Name</div>
                            <TextField
                                label=""
                                size="small"
                                variant="outlined"
                                sx={theme.customStyle.filterTextField}
                                value={filterData.firstName}
                                onChange={(e) => {
                                    setFilterData({
                                        ...filterData,
                                        firstName: e.target.value,
                                    });
                                }}
                                autoComplete='off'
                            />
                        </div>
                        <div style={theme.customStyle.textFieldMainDiv}>
                            <div style={theme.customStyle.textFieldLabel}>Last Name</div>
                            <TextField
                                label=""
                                size="small"
                                variant="outlined"
                                sx={theme.customStyle.filterTextField}
                                value={filterData.lastName}
                                onChange={(e) => {
                                    setFilterData({
                                        ...filterData,
                                        lastName: e.target.value,
                                    });
                                }}
                                autoComplete='off'
                            />
                        </div>
                        <div style={theme.customStyle.textFieldMainDiv}>
                            <div style={theme.customStyle.textFieldLabel}>Phone</div>

                            <TextField
                                label=""
                                size="small"
                                variant="outlined"
                                sx={theme.customStyle.filterTextField}
                                value={filterData.phone}
                                onChange={(e) => {
                                    setFilterData({
                                        ...filterData,
                                        phone: e.target.value,
                                    });
                                }}
                                autoComplete='off'
                            />
                        </div>
                        {currentPage === "ContactsList" ? <>
                            <div style={theme.customStyle.textFieldMainDiv}>
                                <div style={theme.customStyle.textFieldLabel}>Status</div>
                                <TextField
                                    label=""
                                    size="small"
                                    variant="outlined"
                                    sx={theme.customStyle.filterTextField}
                                    value={filterData.status}
                                    onChange={(e) => {
                                        setFilterData({
                                            ...filterData,
                                            status: e.target.value,
                                        });
                                    }}
                                    autoComplete='off'
                                />
                            </div>
                            <div style={theme.customStyle.textFieldMainDiv}>
                                <div style={theme.customStyle.textFieldLabel}>From Date</div>
                                <TextField
                                    sx={theme.customStyle.filterTextField}
                                    variant="outlined"
                                    size="small"
                                    type={"date"}
                                    id="fromDate"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onFocus={event => {
                                        event.target.setAttribute('autocomplete', 'new-password');
                                    }}
                                    value={moment(filterData.fromDate).local().format("YYYY-MM-DD")}
                                    onChange={(e) => {
                                        setFilterData({
                                            ...filterData,
                                            fromDate: moment(e.target.value).format("MM-DD-YYYY"),
                                        });
                                    }}
                                />
                            </div>
                            <div style={theme.customStyle.textFieldMainDiv}>
                                <div style={theme.customStyle.textFieldLabel}>To Date</div>
                                <TextField
                                    sx={theme.customStyle.filterTextField}
                                    variant="outlined"
                                    size="small"
                                    type={"date"}
                                    id="toDate"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onFocus={event => {
                                        event.target.setAttribute('autocomplete', 'new-password');
                                    }}
                                    value={moment(filterData.toDate).local().format("YYYY-MM-DD")}
                                    onChange={(e) => {
                                        setFilterData({
                                            ...filterData,
                                            toDate: moment(e.target.value).format("MM-DD-YYYY"),
                                        });
                                    }}
                                />
                            </div>
                            <LoadingButton
                                loading={isLoading}
                                disabled={!filterData.status
                                    && !filterData.firstName
                                        && !filterData.lastName
                                         && !filterData.phone
                                    && (!filterData.fromDate || !filterData.toDate)
                                }
                                variant="contained"
                                size="small"
                                sx={theme.customStyle.filterButtons}
                                onClick={handleSearch}
                            >
                                Search
                            </LoadingButton>
                        </>
                            : <>
                                
                                <div style={theme.customStyle.textFieldMainDiv}>
                                    <div style={theme.customStyle.textFieldLabel}>Email</div>
                                    <TextField
                                        label=""
                                        size="small"
                                        variant="outlined"
                                        sx={theme.customStyle.filterTextField}
                                        value={filterData.email}
                                        onChange={(e) => {
                                            setFilterData({
                                                ...filterData,
                                                email: e.target.value,
                                            });
                                        }}
                                        autoComplete='off'
                                    />
                                </div>
                                {currentPage === "Users" && (
                                    <div style={theme.customStyle.textFieldMainDiv}>
                                        <div style={theme.customStyle.textFieldLabel}>Is Active</div>
                                        <FormControl fullWidth size="small" sx={{ minWidth: 88 }}>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={filterData.isActive}
                                                label=""
                                                onChange={handleChangeIsActive}
                                                sx={theme.customStyle.filterTextField}
                                            >
                                                <MenuItem value={"None"}>None</MenuItem>
                                                <MenuItem value={true}>True</MenuItem>
                                                <MenuItem value={false}>False</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                )}
                                <div style={theme.customStyle.textFieldMainDiv}>
                                    <div style={theme.customStyle.textFieldLabel}>Team</div>
                                    <FormControl fullWidth size="small" sx={{ minWidth: 140 }}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={filterData.teamId}
                                            label=""
                                            onChange={handleChangeTeam}
                                            sx={theme.customStyle.filterTextField}
                                        >
                                            <MenuItem value={"None"}>None</MenuItem>
                                            {teamsList && teamsList.map((option, index) => (
                                                <MenuItem key={option.teamId} value={option.teamId}>
                                                    {option.teamName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <LoadingButton
                                    loading={isLoading}
                                    disabled={
                                        !filterData.firstName &&
                                            !filterData.lastName &&
                                            !filterData.email &&
                                            !filterData.phone &&
                                            filterData.isActive !== "None" && filterData.isActive !== true && filterData.isActive !== false &&
                                            !filterData.teamId
                                            ? true
                                            : false
                                    }
                                    variant="contained"
                                    size="small"
                                    sx={theme.customStyle.filterButtons}
                                    onClick={handleSearch}
                                >
                                    Search
                                </LoadingButton>
                            </>}
                        <Button
                            variant="contained"
                            size="small"
                            sx={{ ...theme.customStyle.filterButtons, marginLeft: "10px" }}
                            onClick={() => {
                                clearFilter();
                                setShowFilter(false);
                            }}
                        >
                            Clear
                        </Button>
                    </div>
                </div>
            </Collapse>
        </>
    );
}

export default FilterComponent;
