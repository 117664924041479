////10/31/2022:TEXTING-700 - Added User update modal on row click
//// Mohit : 1/3/2023:TEXTING-963 - Clear Userlist and add loader in getallusers method for TeamsTabledialog
//// Mohit : 1/9/2023:TEXTING-963 - closed reopen issue
// 08-29-2023 => ONKAR => TEXTING-1578 -REACT Upgrade
// 08-30-2023 => ONKAR => TEXTING-1578 -REACT Upgrade
// 12-06-2023 => ONKAR => TEXTING-1866 0: Add users table is showing existing users of the team also.
// 12-07-2023 => ONKAR => TEXTING-1867 -0: Selected Team or User in add Team/users tab is removing if filter the table data.
// 04-05-2024 => SUDAM B CHAVAN => on click of select all check box after filter records all the record are getting selected and mapping for Teams and Templates

import React, { useEffect, useState, useContext } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
    IconButton,
    Button,
    Box,
    Checkbox,
    Dialog,
    DialogContent,
    Link,
    DialogActions,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useTheme } from "@mui/material/styles";
import { AuthContext } from "../AuthContext";
import { InvokePostServiceCall } from "../api/serviceUtil";
import apiConfig from "../api/apiConfig";
import TeamsTableDialog from "./TeamsTableDialog";
import EditIcon from "@mui/icons-material/Edit";
import SnackBar from "./SnackBar";
import LoadingButton from "@mui/lab/LoadingButton";
import UpdateTeamUser from "../components/UpdateTeamUser";
import { TextField, InputAdornment } from "@mui/material";
import CustomNoRowsOverlay from "../components/CustomNoRowsOverlay";
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from "react-router-dom";
import { StyledDataGrid } from "./styledComponents/StyledDataGrid";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';

function TeamsListOfUsers(props) {
    const navigate = useNavigate();
    const theme = useTheme();
    const { messageApiToken } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [dialogLoading, setDialogLoading] = useState(true);
    const [userList, setUserList] = useState([]);
    const [userArr, setUserArr] = useState([]);
    const [totalUserList, setTotalUserList] = useState([]);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [openRejectedUsersDialog, setOpenRejectedUsersDialog] = useState({
        open:false,
        isAllRejected:true
    });
    const [addSelectionModel, setAddSelectionModel] = useState([]);
    const [removeSelectionModel, setRemoveSelectionModel] = useState([]);
    const [selectedDialog, setSelectedDialog] = useState("add");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackBarMsg, setSnackBarMsg] = useState("");
    const [severity, setSeverity] = useState("success");
    const [btnLoading, setBtnLoading] = useState(false);
    const [openUserModal, setOpenUserModal] = React.useState(false);
    const [selectedUserDetails, setSelectedUserDetails] = useState();
    const [refresh, setRefresh] = useState(false);
    const [updateBtnLoading, setUpdateBtnLoading] = useState(false)
    const [searchText, setSearchText] = useState([]);
    const [rejectedUserList, setRejectedUserList] = useState([]);
    const [rejectedUsersListNotHaveCFN, setRejectedUsersListNotHaveCFN] = useState([]);
    const [selectdUsers, setSelectdUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
   

    let updateArr = [];

    const columns = [
        {
            // Spread the properties from GRID_CHECKBOX_SELECTION_COL_DEF
            ...GRID_CHECKBOX_SELECTION_COL_DEF,
            // Specify the field for the checkbox column
            field: "checkBox",

            // Customize the header rendering with a Checkbox
            renderHeader: (params) => (
                <Checkbox
                  sx={{ marginLeft: "-5px" }}
                  // Handle header checkbox click to select/deselect all users
                  onClick={() => {
                    let selectdUsersArr = [...selectdUsers];

                    // If all users are already selected, clear the selection; otherwise, select all
                    if (filteredUsers?.length === selectdUsersArr.length) {
                      selectdUsersArr = [];
                    } else {
                      selectdUsersArr = [];
                        filteredUsers.forEach((item) => {
                        selectdUsersArr.push(item.id);
                      });
                    }

                      // Update selected users and selection model
                    setSelectdUsers([...selectdUsersArr]);
                    setAddSelectionModel([...selectdUsersArr]);
                  }}
                  // Set the checked state based on whether all users are selected
                    checked={filteredUsers?.length>0 && selectdUsers.length === filteredUsers.length}
                />
              ),

              // Customize the cell rendering with a Checkbox
              renderCell: (params) => {
                return (
                  <Checkbox
                   // Handle individual checkbox click to toggle user selection
                    onClick={() => {
                      let isPresent = selectdUsers.some(
                        (item) => item === params.row.id
                      );
                      let selectdUsersArr = [...selectdUsers];

                      // If the user is not already selected, add to selection; otherwise, remove
                      if (!isPresent) {
                        selectdUsersArr.push(params.row.id);
                        setSelectdUsers([...selectdUsersArr]);
                        setAddSelectionModel([...selectdUsersArr]);
                      } else {
                        let index = selectdUsers.indexOf(params.row.id);
                        if (index !== -1) {
                          selectdUsersArr.splice(index, 1);
                          setSelectdUsers([...selectdUsersArr]);
                          setAddSelectionModel([...selectdUsersArr]);
                        }
                      }
                    }}
                    // Set the checked state based on whether the user is selected
                    checked={selectdUsers.includes(params.row.id)}
                  />
                );
              },
              headerClassName: "super-app-theme--header",
              sortable: false,
              filterable: false,
        },
        {
            field: "userName",
            headerName: " Full Name",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",

        },
        {
            field: "emailId",
            headerName: "Email",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "clientFacingPhoneNum",
            headerName: "Client Facing Phone No.",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "inboundConversation",
            headerName: "Inbound Message",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => {
                return (
                    <div style={{ marginLeft: "10px" }}>
                        <Checkbox
                            disabled={
                                openAddDialog === true
                                    ? false
                                    : true
                            }
                            onClick={() => {
                                if (selectedDialog === "add") {
                                    if (
                                        totalUserList[params.row.indexId].inboundConversation !==
                                        true
                                    ) {
                                        totalUserList[
                                            params.row.indexId
                                        ].inboundConversation = true;
                                    } else {
                                        totalUserList[
                                            params.row.indexId
                                        ].inboundConversation = false;
                                    }
                                } else {
                                    if (
                                        userList[params.row.indexId].inboundConversation !== true
                                    ) {
                                        userList[params.row.indexId].inboundConversation = true;
                                    } else {
                                        userList[params.row.indexId].inboundConversation = false;
                                    }
                                    if (updateArr.includes(params.row.id) === false) {
                                        updateArr.push(params.row.id);
                                    }
                                }
                            }}
                            defaultChecked={params.row.inboundConversation}
                        />
                    </div>
                );
            },
        },
    ];
    const teamUserscolumns = [
        {
            // Spread the properties from GRID_CHECKBOX_SELECTION_COL_DEF
            ...GRID_CHECKBOX_SELECTION_COL_DEF,
            // Specify the field for the checkbox column
            field: "checkBox",

            // Customize the header rendering with a Checkbox
            renderHeader: (params) => (
              <Checkbox
                sx={{ marginLeft: "-5px" }}
                // Handle header checkbox click to select/deselect all users for removal
                onClick={() => {
                  let removeSelectionModelArr = [...removeSelectionModel];

                  // If all users are already selected for removal, clear the selection; otherwise, select all
                  if (userList.length === removeSelectionModelArr.length) {
                    removeSelectionModelArr = [];
                  } else {
                    removeSelectionModelArr = [];
                    userList.forEach((item) => {
                      removeSelectionModelArr.push(item.userId);
                    });
                  }

                  // Update the selection model for removal
                  setRemoveSelectionModel([...removeSelectionModelArr]);
                }}
                 // Set the checked state based on whether all users are selected for removal
                    checked={userList.length > 0 && removeSelectionModel.length === userList.length}
              />
            ),

            // Customize the cell rendering with a Checkbox
            renderCell: (params) => {
              return (
                <Checkbox
                 // Handle individual checkbox click to toggle user selection for removal
                  onClick={() => {
                    let removeSelectionModelArr = [...removeSelectionModel];
                    let isPresent = removeSelectionModel.some(
                      (item) => item === params.row.userId
                    );

                    // If the user is not already selected for removal, add to selection; otherwise, remove
                    if (!isPresent) {
                      removeSelectionModelArr.push(params.row.userId);
                      setRemoveSelectionModel([...removeSelectionModelArr]);
                    } else {
                      let index = removeSelectionModel.indexOf(params.row.userId);
                      if (index !== -1) {
                        removeSelectionModelArr.splice(index, 1);
                        setRemoveSelectionModel([...removeSelectionModelArr]);
                      }
                    }
                  }}
                  // Set the checked state based on whether the user is selected for removal
                  checked={removeSelectionModel.includes(params.row.userId)}
                />
              );
            },
            headerClassName: "super-app-theme--header",
        },
        {
            field: "userName",
            headerName: " Full Name",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => {
                return (
                    <Link
                        component="button"
                        variant="body2"
                        onClick={() => {
                            navigate("/addUpdateUser/" + params.row.id, {
                                state: { isAddUser: false, userDetails: params.row, isProfile: false },
                            });
                        }}
                        underline="hover">
                        {params.row.userName}
                    </Link>
                );
            },

        },
        {
            field: "emailId",
            headerName: "Email",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "clientFacingPhoneNum",
            headerName: "Client Facing Phone No.",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "inboundConversation",
            headerName: "Inbound Message",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => {
                return (
                    <div style={{ marginLeft: "10px" }}>
                        <Checkbox
                            disabled={true}
                            defaultChecked={params.row.inboundConversation}
                        />
                    </div>
                );
            },
        },
    ];
    const rejectedUserListcolumns = [
      {
        field: "userName",
        headerName: "User Name",
        flex: 1,
        minWidth: 100,
        headerClassName: "super-app-theme--header",
    }];
    useEffect(() => {
        if (messageApiToken) {
            getUsersByTeamId();
        }
    }, [messageApiToken]);

    const getUsersByTeamId = () => {
        setSearchText('');
        if (!props.isAddTeams) {
            setLoading(true);
            InvokePostServiceCall(apiConfig.GET_USER_BY_TEAM_ID, {
                teamId: props.teamId,
                isActive: true,
                SortByName: true,
            })
                .then((data) => {
                    const userArr = [];
                    data.data.userDetails.forEach((item, index) => {
                        userArr.push({
                            id: item.user.userId,
                            userId: item.user.userId,
                            applicationUserId: item.user.applicationUserId,
                            phoneNumber: item.user.phoneNumber,
                            connectionId: item.user.connectionId,
                            userName: item.user.userName,
                            azureAdid: item.user.azureAdid,
                            firstName: item.user.firstName,
                            lastName: item.user.lastName,
                            emailId: item.user.emailId,
                            indexId: index,
                            inboundConversation: item.inboundConversation,
                            teamToUserId: item.teamToUserId,
                            clientFacingPhoneNum: item.user.clientFacingPhoneNum,
                        });
                    });
                    setUserList(userArr);
                    setUserArr(userArr);
                    setLoading(false);
                })
                .catch((err) => {
                    console.log(err, "teamsApiError");
                    setLoading(false);
                    setSnackBarMsg(err.message);
                    setSeverity("error");
                    setOpenSnackbar(true);
                });
        }
    };

    const getAllUsers = () => {
        setTotalUserList([]);
        setFilteredUsers([]);
        setDialogLoading(true);
        if (messageApiToken && !props.isAddTeams) {
            InvokePostServiceCall(apiConfig.GET_USERS, { isActive: true, SortByName: true })
                .then((data) => {
                    const userArr = [];
                    const tempArr = [];
                    data.data.userDetails.forEach((item, index) => {
                        let present = userList.some((data) => item.user.userId === data.id);
                        if (present === false) {
                            tempArr.push(item.user);
                        }
                    });
                    tempArr.forEach((item, index) => {
                        userArr.push({
                            id: item.userId,
                            applicationUserId: item.applicationUserId,
                            phoneNumber: item.phoneNumber,
                            connectionId: item.connectionId,
                            userName: item.userName,
                            azureAdid: item.azureAdid,
                            firstName: item.firstName,
                            lastName: item.lastName,
                            emailId: item.emailId,
                            indexId: index,
                            inboundConversation: item.inboundConversation,
                            teamToUserId: item.teamToUserId,
                            clientFacingPhoneNum: item.clientFacingPhoneNum,
                        });
                    });
                    setTotalUserList(userArr);   
                    setFilteredUsers(userArr);  
                    //Set Timeout added To fix No record found message issue
                    setTimeout(() => {          
                        setDialogLoading(false);
                    }, 1);

                })
                .catch((error) => {
                    console.log(error, "Error Message");
                    setDialogLoading(false);
                    setSnackBarMsg(error.message);
                    setSeverity("error");
                    setOpenSnackbar(true);
                });
        }
    };
    const handleClose = () => {
        setOpenAddDialog(false);
        setOpenWarningDialog(false);
    };

    const saveTeamMembers = (actionType) => {
        setBtnLoading(true);
        let teamToUsersArr = [];
        if (actionType === "Add") {
            totalUserList.forEach((item) => {
                let present = addSelectionModel.some((data) => data === item.id);
                if (present === true) {
                    teamToUsersArr.push({
                        teamId: props.teamId,
                        userId: item.id,
                        inboundConversation: item.inboundConversation,
                    });
                }
            });
        } else {
            userList.forEach((item) => {
                let present = updateArr.some((data) => data === item.id);
                if (present === true) {
                    teamToUsersArr.push({
                        teamToUserId: item.teamToUserId,
                        teamId: props.teamId,
                        userId: item.id,
                        inboundConversation: item.inboundConversation,
                    });
                }
            });
        }
        let requestBody = {
            actionType: actionType,
            teamToUsers: teamToUsersArr,
        };
        InvokePostServiceCall(apiConfig.SAVE_TEAM_MEMBER, requestBody)
            .then((data) => {
                console.log(data);
                getUsersByTeamId();
                handleClose();
                setUserList([])
                var tempRejectedUserList = [];
                let totalRejectedUsersCount = 0;
                if (data.data.rejectedUsersList?.length > 0 || data.data.rejectedUsersListNotHaveCFN?.length > 0) {
                    data.data.rejectedUsersList.forEach(function (element, index) {
                        var user = {
                            userName: element,
                            id: index
                        }
                        tempRejectedUserList.push(user)
                    })
                    setRejectedUserList(tempRejectedUserList)
                    setRejectedUsersListNotHaveCFN(data.data.rejectedUsersListNotHaveCFN);
                    let cfnUsers = data.data.rejectedUsersListNotHaveCFN.length;
                    totalRejectedUsersCount = ((cfnUsers ? cfnUsers:0) + tempRejectedUserList.length);
                    setOpenRejectedUsersDialog({ open: true, 
                                                isAllRejected: (teamToUsersArr.length === totalRejectedUsersCount)});
                }
                if (tempRejectedUserList.length === 0 && totalRejectedUsersCount === 0) {
                    setSnackBarMsg(
                        `Users ${actionType === "Add" ? "Added" : "Updated"} Successfully`
                    );
                    setSeverity("success");
                    setOpenSnackbar(true);
                }
                setBtnLoading(false);
                updateArr = [];
            })
            .catch((error) => {
                console.log(error, "Error Message");
                setSnackBarMsg(error.message);
                setSeverity("error");
                setOpenSnackbar(true);
                setBtnLoading(false);
            });
    };
    //// function added for update user from modal..
    const UpdateTeamMember = (userId, inboundConversation) => {
        setUpdateBtnLoading(true);
        let teamToUsersArr = [];
        let item = userList.find((data) => data.id === userId);
        if (item) {
            teamToUsersArr.push({
                teamToUserId: item.teamToUserId,
                teamId: props.teamId,
                userId: item.id,
                inboundConversation: inboundConversation,
            })


            let requestBody = {
                actionType: 'Update',
                teamToUsers: teamToUsersArr,
            };
            setUpdateBtnLoading(true)
            InvokePostServiceCall(apiConfig.SAVE_TEAM_MEMBER, requestBody)
                .then((data) => {
                    setUpdateBtnLoading(false)
                    getUsersByTeamId();
                    handleModalClose();
                    setUserList([])
                    setSnackBarMsg(
                        `User Updated Successfully`
                    );
                    setSeverity("success");
                    setOpenSnackbar(true);
                    setBtnLoading(false);
                    setUpdateBtnLoading(false)
                    updateArr = [];
                })
                .catch((error) => {
                    console.log(error, "Error Message");
                    setSnackBarMsg(error.message);
                    setSeverity("error");
                    setOpenSnackbar(true);
                    setBtnLoading(false);
                });
        }
    };

    const deleteTeamMembers = () => {
        setBtnLoading(true);
        let teamToUsersArr = [];
        userList.forEach((item) => {
            let present = removeSelectionModel.some((data) => data === item.id);
            if (present === true) {
                teamToUsersArr.push({
                    teamToUserId: item.teamToUserId,
                    teamId: props.teamId,
                    userId: item.id,
                    InboundConversation: item.inboundConversation,
                });
            }
        });
        let requestBody = {
            teamToUsers: teamToUsersArr,
        };
        InvokePostServiceCall(apiConfig.DELETE_TEAM_MEMBER, requestBody)
            .then((data) => {
                console.log(data);
                getUsersByTeamId();
                handleClose();
                setUserList([])
                setRemoveSelectionModel([])
                setSnackBarMsg("Users Removed Successfully");
                setSeverity("success");
                setOpenSnackbar(true);
                setBtnLoading(false);
            })
            .catch((error) => {
                console.log(error, "Error Message");
                setSnackBarMsg(error.message);
                setSeverity("error");
                setOpenSnackbar(true);
                setBtnLoading(false);
            });
    };
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        handleClose();
        setOpenSnackbar(false);
    };
    const handleModalClose = () => {
        setOpenUserModal(false);
    };
    const filterTable = (value) => {
        let usArr = [];
        let val = value?.toLowerCase();
        userArr.forEach((item) => {

            if (
                item.userName?.toLowerCase().includes(val) ||
                item.emailId?.toLowerCase().includes(val) ||
                item.clientFacingPhoneNum?.toLowerCase().includes(val)
            ) {
                usArr.push(item);
            }
        });
        setUserList(usArr);
    }
    return (
        <>
            <SnackBar
                openSnackbar={openSnackbar}
                handleCloseSnackbar={handleCloseSnackbar}
                severity={severity}
                userMessage={snackBarMsg}
            />
            <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: 10}}>
                <div>
                    <Box sx={theme.customStyle.tableRow}>
                    <IconButton
                        disabled={removeSelectionModel.length > 0 || loading ? true : false}
                        sx={theme.customStyle.teamsAddIconBtn}
                        onClick={() => {
                            setSelectedDialog("add");
                            getAllUsers();
                            setOpenAddDialog(true);
                        }}
                    >
                        <AddIcon />
                    </IconButton>
                    <IconButton
                        disabled={removeSelectionModel.length < 1 ? true : false}
                        sx={{
                            backgroundColor: "red",
                            color: "white",
                            "&:hover": { backgroundColor: "#db0000" },
                        }}
                        onClick={() => {
                            setOpenWarningDialog(true);
                        }}
                    >
                        <RemoveIcon />
                    </IconButton>
                    </Box>
                </div>
                <div>
                    <TextField
                        sx={{ marginRight: "10px", marginBottom: "10px" }}
                        size="small"
                        type="search"
                        variant="outlined"
                        margin="normal"
                        id="outlined-search"
                        label=""
                        placeholder="Users Search"
                        autoComplete="off"
                        value={searchText}
                        onChange={(e) => {
                            setSearchText(e.target.value)
                            if (e.target.value) {
                                filterTable(e.target.value);
                            }
                            else {
                                setUserList(userArr);
                            }

                        }}
                        InputLabelProps={{
                            shrink: false,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                </div>
            </div>
                <Box sx={theme.customStyle.tableHead}>
                    <StyledDataGrid
                        loading={loading}
                        rows={userList}
                        columns={teamUserscolumns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                        components={{
                            NoRowsOverlay: CustomNoRowsOverlay,
                        }}
                        onRowDoubleClick={(e) => {
                            setOpenUserModal(true);
                            setSelectedUserDetails(e.row)
                        }}
                    />
                </Box>
           
           { openAddDialog && <TeamsTableDialog
                rows={totalUserList}
                loading={dialogLoading}
                columns={columns}
                open={true}
                handleClose={handleClose}
                saveTeamMembers={saveTeamMembers}
                checkboxSelection={true}
                selectionModel={addSelectionModel}
                setSelectionModel={setAddSelectionModel}
                dialogTitle={"Add Users"}
                btnText={"Add"}
                btnLoading={btnLoading}
                selectdUsers={selectdUsers}
                setSelectdUsers={setSelectdUsers}
                setFilteredUsers={setFilteredUsers}
            />}
            <Dialog
                open={openUserModal}
                onClose={handleModalClose}
                fullWidth
                maxWidth={"lg"}
            >
                <DialogContent>
                    <UpdateTeamUser
                        open={openUserModal}
                        handleClose={handleModalClose}
                        templateDetails={selectedUserDetails}
                        // saveTeamMembers={saveTeamMembers}
                        setUpdateBtnLoading={setUpdateBtnLoading}
                        updateBtnLoading={updateBtnLoading}
                        UpdateTeamMember={UpdateTeamMember}
                        refresh={refresh}
                        setRefresh={setRefresh}
                        teamName={props.teamName}
                        teamId={props.teamId}
                        dialogTitle={"Update Users"}
                        btnText={"Save"}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                open={openWarningDialog}
                onClose={handleClose}
                fullWidth
                maxWidth={"sm"}
            >
                <DialogContent>
                    <h6>
                        Are you sure you want to remove {removeSelectionModel.length} users
                        from team ?
                    </h6>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loading={btnLoading}
                        size="small"
                        onClick={() => {
                            deleteTeamMembers();
                        }}
                    >
                        Remove
                    </LoadingButton>
                    <Button
                        size="small"
                        onClick={() => {
                            setOpenWarningDialog(false);
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openRejectedUsersDialog.open}
                onClose={handleClose}
                fullWidth
                maxWidth={"sm"}
            >
                <DialogContent>
                    <h5>
                        Following users failed to add  
                    </h5>
                    <hr />
                    {rejectedUserList && rejectedUserList.length > 0 && <>
                        <strong> Users are already part of an individual type of team</strong>
                    <Box sx={{ ...theme.customStyle.tableHead, marginTop: "10px", height:"auto" }}>
                        <ul style={{color:"red"}}>
                            {rejectedUserList.map((item, index) => {
                                return <li key={index}>{item.userName}</li>
                            })}
                        </ul>
                    </Box></>}
                    {rejectedUsersListNotHaveCFN && rejectedUsersListNotHaveCFN.length > 0 && <>
                    <hr />
                        <strong> Users do not have client facing number</strong>
                        <Box sx={{ ...theme.customStyle.tableHead, marginTop: "10px", height: "auto" }}>
                            <ul style={{ color: "red" }}>
                                {rejectedUsersListNotHaveCFN.map((item, index) => {
                                    return <li key={index}>{item}</li>
                                })}
                            </ul>
                        </Box></>}
                    {!openRejectedUsersDialog.isAllRejected && <h5 style={{ color: "green" }}>Rest of the users are added successfully.</h5> }
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loading={btnLoading}
                        size="small"
                        onClick={() => {
                            setOpenRejectedUsersDialog({open:false, isAllRejected:true});
                            setRejectedUserList([]);
                            setRejectedUsersListNotHaveCFN([]);
                        }}
                    >
                        OK
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default TeamsListOfUsers;
