export const states = [
    { code: "AL", name: "Alabama" },
    { code: "AK", name: "Alaska" },
    { code: "AZ", name: "Arizona" },
    { code: "AR", name: "Arkansas" },
    { code: "CA", name: "California" },
    { code: "CO", name: "Colorado" },
    { code: "CT", name: "Connecticut" },
    { code: "DE", name: "Delaware" },
    { code: "FL", name: "Florida" },
    { code: "GA", name: "Georgia" },
    { code: "HI", name: "Hawaii" },
    { code: "ID", name: "Idaho" },
    { code: "IL", name: "Illinois" },
    { code: "IN", name: "Indiana" },
    { code: "IA", name: "Iowa" },
    { code: "KS", name: "Kansas" },
    { code: "KY", name: "Kentucky" },
    { code: "LA", name: "Louisiana" },
    { code: "ME", name: "Maine" },
    { code: "MD", name: "Maryland" },
    { code: "MA", name: "Massachusetts" },
    { code: "MI", name: "Michigan" },
    { code: "MN", name: "Minnesota" },
    { code: "MS", name: "Mississippi" },
    { code: "MO", name: "Missouri" },
    { code: "MT", name: "Montana" },
    { code: "NE", name: "Nebraska" },
    { code: "NV", name: "Nevada" },
    { code: "NH", name: "New Hampshire" },
    { code: "NJ", name: "New Jersey" },
    { code: "NM", name: "New Mexico" },
    { code: "NY", name: "New York" },
    { code: "NC", name: "North Carolina" },
    { code: "ND", name: "North Dakota" },
    { code: "OH", name: "Ohio" },
    { code: "OK", name: "Oklahoma" },
    { code: "OR", name: "Oregon" },
    { code: "PA", name: "Pennsylvania" },
    { code: "RI", name: "Rhode Island" },
    { code: "SC", name: "South Carolina" },
    { code: "SD", name: "South Dakota" },
    { code: "TN", name: "Tennessee" },
    { code: "TX", name: "Texas" },
    { code: "UT", name: "Utah" },
    { code: "VT", name: "Vermont" },
    { code: "VA", name: "Virginia" },
    { code: "WA", name: "Washington" },
    { code: "WV", name: "West Virginia" },
    { code: "WI", name: "Wisconsin" },
    { code: "MY", name: "Wyoming" },
]
export const timezones = [
    { name: "Pacific Daylight Time" },
    { name: "Pacific Standard Time" },
    { name: "Eastern Daylight Time" },
    { name: "Eastern Standard Time" },
    { name: "Central Daylight Time" },
    { name: "Central Standard Time" },
    { name: "Mountain Standard Time" },
    { name: "Mountain Daylight Time" }

// { name: "Anywhere on Earth", text: "UTC-12: Anywhere on Earth(AoE)" },
// { name: "Samoa Standard Time", text: "UTC-11: Samoa Standard Time(ST)" },
// { name: "Hawaii-Aleutian Standard Time", text: "UTC-10: Hawaii-Aleutian Standard Time(HAT)" },
// { name: "Alaska Standard Time", text: "UTC-9: Alaska Standard Time(AKT)" },
// { name: "Pacific Standard Time", text: "UTC-8: Pacific Standard Time(PT)" },
// { name: "Mountain Standard Time", text: "UTC-7: Mountain Standard Time(MT)" },
// { name: "Central Standard Time", text: "UTC-6: Central Standard Time(CT)" },
// { name: "Eastern Standard Time", text: "UTC-5: Eastern Standard Time(ET)" },
// { name: "Atlantic Standard Time", text: "UTC-4: Atlantic Standard Time(AST)" },
// { name: "Chamorro Standard Time", text: "UTC+10: Chamorro Standard Time(ChT)" },
// { name: "Wake Island Time Zone", text: "UTC+12: Wake Island Time Zone(WIT)" },
]