/**
 * 08-22-2023 => SUDAM B CHAVAN => TEXTING-1600 - Conversation assignment/reassignment
 */
import React from "react";
import moment from "moment";
import { useTheme } from "@mui/material/styles";

function ChatInfoMsg(props) {
    const theme = useTheme();
    const { message, componentType } = props;
    return (
        <div style={theme.customStyle.transferHistoryDiv} >
            <div style={theme.customStyle.transferHistory}>
                {componentType === "History" ? //ConversationHistory component having diff field names for message objects..
                    message.subType === "ASSIGNED"
                        ? `Conversation assigned to ${message.body}`
                        : message.body
                    :
                    message.messageSubType === "ASSIGNED"
                        ? `Conversation assigned to ${message.messageBody}`
                        : message.messageBody}
            </div>
            <div style={{ marginBottom: "15px" }}>
                <span style={{ fontSize: '12px', color: "grey" }}>
                    {moment(message.messageDate).format("lll")}
                </span>
            </div>
        </div>
    );
};
export default ChatInfoMsg;