import React, { useContext, useEffect } from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Checkbox,
    FormGroup,
    FormControlLabel,
} from "@mui/material";
import apiConfig from "../api/apiConfig";
import { InvokePostServiceCall } from "../api/serviceUtil";
import { AuthContext } from "../AuthContext";
import SnackBar from "./SnackBar";

import { useState } from "react";
import { dataService } from "../dataService";

const SelectDocumentTypes = (props) => {
    const {
        userInfo,
    } = useContext(AuthContext);
    const [documentList, setDocumentList] = useState([]);
    const [documentTypeList, setDocumentTypeList] = useState([]);
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");


    const handleSelectedDocuments = (e) => {
        const { checked, value } = e.target;
        if (documentList?.filter(d => d === value)?.length > 0) {
            setDocumentList(documentList.filter(d => d !== value))
        }
        else {
            var temp = documentList;
            temp.push(value)
            setDocumentList(temp);
        }
    }
    const handleSubmit = (e) => {

        if (documentList?.length > 5) {
            setErrorMsg("You can only select up to 5 document types per request");
            setShowSnackBar(true);
        }
        else if (documentList?.length > 0) {
            var tempDocumentTypes = [];
            documentList.forEach(element => {
                var obj = {
                    docName: element
                }
                tempDocumentTypes.push(obj)
            });
            var request = {
                conversationId: props.state?.conversation?.conversationId,
                documentTypeCol: {
                    documentTypes: tempDocumentTypes
                }
            };
            InvokePostServiceCall(apiConfig.GET_DOCUMENT_REQ_URL, request)
                .then((data) => {
                    console.log("sudam log", data)
                    var response = data.data;

                    var uploadDocUrl = response.memberProfileDocURL + "?authKey=" + response.authKey;

                    var strResp = "\nPlease upload the below documents by navigating to the URL-\n" + documentList.join('\n');
                    props.handleModalResponse(uploadDocUrl + strResp)

                })
                .catch((err) => {
                    console.log(err, "Error Message");
                });
        }
        else {
            setErrorMsg("Select at least 1 document");
            setShowSnackBar(true);
        }
    }
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setShowSnackBar(false);
    }
    useEffect(() => {
        var teamId = props.state?.conversation?.teamId;
        if (teamId) {
            var team = userInfo.teams?.filter(t => t.teamId === teamId)
            var docType = team ? team[0].documentTypes?.split(',') : []
            docType = docType?.filter(x => x)
            setDocumentTypeList(docType);
        }
    }, [])


    return (
        <>
            <SnackBar
                openSnackbar={showSnackBar}
                handleCloseSnackbar={handleCloseSnackbar}
                severity={"error"}
                userMessage={errorMsg}
            />
            <Dialog
                open={props.state.showDocumentTypeDailog}
                onClose={props.handleModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle >
                    <Box>
                        <strong>Select Document Types</strong>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Divider />
                    <Box sx={{ marginTop: "10px" }}>
                        {documentTypeList?.map((type, index) => {

                            return (
                                <Box>
                                    <FormGroup>
                                        <FormControlLabel control={
                                            <Checkbox sx={{ padding: "1px" }}
                                                value={type}
                                                onClick={handleSelectedDocuments} />}
                                            label={type} />
                                    </FormGroup>
                                </Box>

                            );
                        })
                        }
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleModalClose} autoFocus>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <span>
                                <strong> Cancel</strong>
                            </span>
                        </div>
                    </Button>
                    <Button onClick={handleSubmit} autoFocus>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <span>
                                <strong> Submit</strong>
                            </span>
                        </div>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default SelectDocumentTypes;