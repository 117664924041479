/**************************
 * 11/11/2022   Texting-759=> Resetting the conversion array before api call
 * 11-11/2022 TextField:766=>disabled auto suggetion
 * 29-11-2022 => YOGESH=>Texting-866=> Disabled auto suggection from all textfields
 * 12-01-2022 => ONKAR => Texting-875 Previously opened conversation chat is showing for few seconds when opening the conversation from Search conversation and omni channel fixed.
 * 09-05-2023 => SUDAM B CHAVAN => TEXTING-1161 - Search conversations by email
 * 10-05-2023 => SUDAM B CHAVAN => TEXTING-1161 - Search conversations added Email column and Active-Bot to filter ddl
 * 22-07-2023 => Prabhat karri => TEXTING-1446 - In search conversations added Search by Sentiment field.
 * 07-25-2023 => ONKAR => TEXTING-1475 - Critical: Sentiment Optimization
 * 08-29-2023 => ONKAR => TEXTING-1578 -REACT Upgrade
 * 09-04-2023 => PRABHAT => TEXTING-1665 ReactUpgrade: Using search conversations is making the Admin UI blank.
 * 09-29-2023 => SUDAM B CHAVAN => TEXTING-1761  - Inbound message assignment pending changes.
 * 10-06-2023 => ONKAR => TEXTING-1780  - Sentiment Emoji showing up for first outbound message in search conversations.
 * 10-12-2023 => ONKAR =>  TEXTING-1796 - Sentiment Re-Calculate button to calculate sentiment with latest messages.
 * 10-19-2023 => ONKAR =>  TEXTING-1816 - Overall sentiment row is not showing any value for Search conversations results.
 * 10-30-2023 => SUDAM B CHAVAN =>TEXTING-1827 - Search Messages
 * 12-08-2023 => SUDAM B CHAVAN => TEXTING-1892 0: Survey : View Completed Survey in agent view \
 * 01-18-2023 => ONKAR => TEXTING - 1936 - Request Assistance : UI
                          4. Messages exchanged in request assistance popup is showing on conversation page opened from search conversations and omnichannel.
*/
import React, { useState, useContext, useEffect } from "react";
import {
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Divider,
    CircularProgress,
    InputAdornment,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Checkbox
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { useTheme, experimentalStyled as styled } from "@mui/material/styles";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import apiConfig from "../../api/apiConfig";
import { mapConversation } from "../../extensions/Util";
import { AuthContext } from "../../AuthContext";
import moment from "moment";
import SearchConvChat from "../../components/SearchConvChat";
import SnackBar from "../../components/SnackBar";
import SearchIcon from '@mui/icons-material/Search';
import LoadingButton from "@mui/lab/LoadingButton";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { createTheme } from "@mui/material/styles";
import FileSaver from 'file-saver';
import { StyledDataGrid } from "../../components/styledComponents/StyledDataGrid";
import { updateSentimentInSearchAndChannel } from "../../api/messageServices";
import CustomTabList from "../../components/CustomTabList";
import SearchMessages from "./SearchMessages";



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
}));
const theme2 = createTheme({
    boxShadow: "none"
});
function SearchConversation() {
    const theme = useTheme();
    const [convData, setConvData] = useState([]);
    const [convDetails, setConvDetails] = useState();
    const [chatLoading, setChatLoading] = useState(false);
    const [msgList, setMsgList] = useState([]);
    const appContext = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [circularLoading, setCircularLoading] = useState(false);
    const [status, setStatus] = useState("none");
    const [agent, setAgent] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [overAllSentiment, setoverAllSentiment] = useState("");
    var todayDate = new Date();
    const [date, setDate] = useState({
        toDate: moment(todayDate).format('YYYY-MM-DD'),
        fromDate: moment(new Date(todayDate.setDate(todayDate.getDate() - 90))).format('YYYY-MM-DD')
    });
    const [showConvDialog, setShowConvDialog] = useState(false);
    const [selectedConv, setSelectedConv] = useState();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const { messageApiToken, enableSentimentCalculation, setSelectedMsgId } = useContext(AuthContext);
    const [userList, setUserList] = useState([]);
    const [teamList, setTeamList] = useState([]);
    const [teamId, setTeamId] = useState("");
    const [communicationId, setCommunicationId] = useState("");
    const [communicationSearchVal, setCommunicationSearchVal] = useState("");
    const [communicationList, setCommunicationList] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [commLoading, setCommLoading] = useState(true);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [snackbarMsg, setSnackBarMsg] = useState("")
    const [teamName, setTeamName] = useState("");
    const [showTeamName, setShowTeamName] = useState(false);
    const [showCommunicationName, setShowCommunicationName] = useState(false);
    const [selectedCommunication, setSelectedCommunication] = useState();
    const [usersLoading, setUsersLoading] = useState(false);
    const [phoneerr, setPhoneerr] = useState(false);
    const [emailerr, setEmailerr] = useState(false);
    const [dateErr, setDateErr] = useState({
        fromDateErr: false,
        toDateErr: false,
    });
    const [tabValue, setTabValue] = React.useState("1");
    const tabList = [
        { label: "Conversations", value: "1", displayOrder: 1 },
        { label: "Messages", value: "2", displayOrder: 2 },
    ]
    const [communicationName, setCommunicationName] = useState("");
    const emptyGUID = "00000000-0000-0000-0000-000000000000";

    const handleStatus = (event) => {
        setStatus(event.target.value);
    };
    const handleSentiment = (event) => {
        setoverAllSentiment(event.target.value);
    };
    const handleAgent = (event) => {
        setAgent(event.target.value);
    };
    const handleTeam = (event) => {
        if (event.target.value != emptyGUID) {
            setTeamId(event.target.value);
            setUserList([]);
            getUsers({ isActive: true, teamId: event.target.value, SortByName: true });
        } else {
            setTeamId(emptyGUID);
            setAgent(emptyGUID);
            setUserList([]);
        }

    };
    const dayInMonthComparator = (v1, v2) => {
        if (!v1) v1 = new Date(null);
        if (!v2) v2 = new Date(null);
        if (v1 && v2) {
            var date1 = moment(v1);
            var date2 = moment(v2);
            var diff = date1.diff(date2, 'seconds')
            return diff
        }
    }
    const columns = [
        {
            field: "customerName",
            headerName: "Contact Name",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "customerPhoneNumber",
            headerName: "Contact Phone",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "createdDate",
            headerName: "Conversation Date",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
            type: "date",
            valueGetter: (params) => {
                if (params.value) {
                    return new Date(params.value);
                }
                return null;
            },
            valueFormatter: (params) => {
                if (params.value instanceof Date) {
                    return params.value.toLocaleTimeString('en-US', {
                        month: '2-digit',
                        day: "2-digit",
                        year: "numeric",
                        hour: 'numeric',
                        minute: 'numeric'
                    });
                }
                return "";
            },
            sortComparator: dayInMonthComparator,
        },
        {
            field: "recentCustomerRespondedDate",
            headerName: "Customer Response Date",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
            type: "date",
            valueGetter: (params) => {
                if (params.value) {
                    return new Date(params.value);
                }
                return null;
            },
            valueFormatter: (params) => {
                if (params.value instanceof Date) {
                    return params.value.toLocaleTimeString('en-US', {
                        month: '2-digit',
                        day: "2-digit",
                        year: "numeric",
                        hour: 'numeric',
                        minute: 'numeric'
                    });
                }
                return "";
            },
            sortComparator: dayInMonthComparator,
        },
        {
            field: "recentAgentRespondedDate",
            headerName: "Agent Response Date",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
            type: "date",
            valueGetter: (params) => {
                if (params.value) {
                    return new Date(params.value);
                }
                return null;
            },
            valueFormatter: (params) => {
                if (params.value instanceof Date) {
                    return params.value.toLocaleTimeString('en-US', {
                        month: '2-digit',
                        day: "2-digit",
                        year: "numeric",
                        hour: 'numeric',
                        minute: 'numeric'
                    });
                }
                return "";
            },
            sortComparator: dayInMonthComparator,
        },
        {
            field: "agentName",
            headerName: "Agent",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "overallSentiment",
            headerName: "OverAll Sentiment",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
            valueFormatter: (params) => mapSentimentLabel(params.value),// Custom value formatter for DataGrid column
        },

        {
            field: "teamName",
            headerName: "Team",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "communicationName",
            headerName: "Communication Lists",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
    ];

    const communicationColumns = [
        {
            field: "checkBox",
            headerName: "-",
            width: 50,
            filterable: false,
            align: "center",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => {
                return (
                    <Checkbox onClick={() => {
                        if (selectedCommunication !== params.id) {
                            setSelectedCommunication(params.id)

                        } else {
                            setSelectedCommunication({})
                        }

                    }} checked={selectedCommunication === params.id} value={params.id} />
                )
            }
        },

        {
            field: "communicationListName",
            headerName: "Communication Name",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "communicationListCode",
            headerName: "Communication Code",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "createdDate",
            headerName: "Created Date",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "lastModifiedDate",
            headerName: "Modified Date",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
    ]

    useEffect(() => {
        if (messageApiToken) {
            // getUsers({ isActive: true });
            getTeams();
        }
    }, [messageApiToken]);

    const onAssignConvSuccess = () => {
        setShowConvDialog(false);
    };

    const getConversationsByFilter = (isExportToExcel) => {
        
        if (
            (date.fromDate !== "" && date.toDate === "") ||
            (date.fromDate === "" && date.toDate !== "")
        ) {
            setDateErr({
                ...date,
                fromDateErr: date.fromDate === "" ? true : false,
                toDateErr: date.toDate === "" ? true : false,
            });
            return;
        }
        else{
            let { dateS, dateE, diffDays } = "";
            dateS = moment(date.fromDate);
            dateE = moment(date.toDate);
            diffDays = dateE.diff(dateS, 'days')
            if (diffDays < 0){
                setSnackBarMsg("From Date should not greater than To Date");
                setOpenSnackbar(true)
                return;
            }
            else if (diffDays > 90) {
                setSnackBarMsg("Date range should not be exceed more than 90 days");
                setOpenSnackbar(true)
                return;
            }

        }
        let chkmobile = false;
        const PHONE_REGEX = /^[+][0-9]{11}$/i
        let PHONE_US = /^\([0-9]{3}\) |[0-9]{3}-[0-9]{3}-[0-9]{4}$/i;
        const PHONE_INDIAN_REGEX = /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/i;
        if (phone?.startsWith("+91")) {
            if (PHONE_INDIAN_REGEX.test(phone)) {
                chkmobile = true;

            }
        }
        else {
            if (PHONE_REGEX.test(phone)) {
                chkmobile = true;

            } else if (PHONE_US.test(phone)) {
                chkmobile = true;

            }
        }
        if (!chkmobile && phone.length > 0) {
            //setSnackBarMsg("Invalid Phone Number");
            //setOpenSnackbar(true)
            setPhoneerr(true);
            return;
        }
        let regEmail = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i;
        if (email && !regEmail.test(email)) {
            setEmailerr(true);
            return;
        }
        setCircularLoading(true);
        let requestBody = {
            userId: agent === "" ? emptyGUID : agent,
            status: status === "none" ? "" : status,
            phoneNum: phone,
            teamId: teamId === "none" || !teamId ? emptyGUID : teamId,
            communicationListId: communicationId === "" ? emptyGUID : communicationId,
            fromDate: date.fromDate ? moment(date.fromDate).format("MM-DD-YYYY") : "",
            toDate: date.toDate ? moment(date.toDate).format("MM-DD-YYYY") : "",
            email: email,
            overAllSentiment: overAllSentiment === "none" ? "" : overAllSentiment,
            //overallSentiment: "4"
        };
        console.log(requestBody, "requestBody");
        if (isExportToExcel) {

            InvokePostServiceCall(apiConfig.GET_CONVERSATIONS_EXCEL_REPORT, requestBody)
                .then((res) => {
                    setCircularLoading(false);
                    if (res.data.status == "success") {
                        FileSaver.saveAs(res.data.excelInBase64, 'Convertion Report.xlsx');
                    } else if (res.data.status == "failed") {
                        setSnackBarMsg(res.data.errorDescription);
                        setOpenSnackbar(true);
                    }
                })
                .catch((err) => {
                    console.log(err, "Error message");
                    setSnackBarMsg(err.message);
                    setOpenSnackbar(true);
                    setLoading(false);
                    setCircularLoading(false);
                });
        }
        else {
            setCircularLoading(true);
            setLoading(true);
            setConvData([]);
            let isReadOnly = true;
            setConvData([]) //resetting the con data before calling api hence able to see the loader
            InvokePostServiceCall(apiConfig.GET_CONVERSATION_BY_FILTER, requestBody)
                .then((data) => {
                    console.log(data);
                    debugger
                    let convArr = [];
                    let tempArr = [];
                    let conversation = data.data.conversations;

                    if (conversation != null && conversation.length > 0) {
                        for (var i = 0; i < conversation.length; i++) {
                            var conv = mapConversation(
                                conversation[i],
                                conversation[i].teamPhoneNumber
                            );
                            conv.isReadOnly = isReadOnly;
                            conv.recentCustomerRespondedDate = conversation[i].recentCustomerRespondedDate;
                            conv.recentAgentRespondedDate = conversation[i].recentAgentRespondedDate;
                            conv.status = conv.status;
                            convArr.push(conv);
                        }

                        convArr.forEach((item, index) => {
                            item.id = index;
                            item.communicationName = communicationSearchVal;
                            item.createdDate = moment(item.createdDate).local().format("MM-DD-YYYY") + ", " + moment(item.createdDate).local().format("LT")
                            item.recentCustomerRespondedDate = item.recentCustomerRespondedDate ? moment(item.recentCustomerRespondedDate).local().format("MM-DD-YYYY") + ", " + moment(item.recentCustomerRespondedDate).local().format("LT") : "";
                            item.recentAgentRespondedDate = item.recentAgentRespondedDate ? moment(item.recentAgentRespondedDate).local().format("MM-DD-YYYY") + ", " + moment(item.recentAgentRespondedDate).local().format("LT") : "";
                            item.status = item.status === "UnAssigned" ? "Missed" : item.status;
                            tempArr.push(item);
                        });
                        if (teamName) {
                            setShowTeamName(true);
                        } else {
                            setShowTeamName(false);
                        }
                        if (communicationSearchVal) {
                            setShowCommunicationName(true)
                        } else {
                            setShowCommunicationName(false)
                        }
                        setConvData(tempArr);
                    } else {
                        setConvData(convArr);
                    }
                    
                    setLoading(false);
                    setCircularLoading(false);
                })
                .catch((err) => {
                    console.log(err, "Error message");
                    setSnackBarMsg(err.message);
                    setOpenSnackbar(true);
                    setLoading(false);
                    setCircularLoading(false);
                });
        }
    };

    const clearSearch = () => {
        setStatus("none");
        setAgent("");
        setPhone("");
        setTeamId("");
        setTeamName("");
        setPhoneerr("");
        setEmail("");
        setEmailerr("");
        setCommunicationId("");
        setoverAllSentiment("");
        var todayDate = new Date();
        setDate({
            toDate: moment(todayDate).format('YYYY-MM-DD'),
            fromDate: moment(new Date(todayDate.setDate(todayDate.getDate() - 90))).format('YYYY-MM-DD')});
        setDateErr({ ...dateErr, fromDateErr: false, toDateErr: false });
        setConvData([]);
        setCommunicationName("")
        setCommunicationSearchVal("");
        setCommunicationList([]);
        setSelectedCommunication();
        setUserList([]);
    };

    const getMessages = (convDetails) => {
        setChatLoading(true);
        var requestBody = { conversationId: convDetails.conversationId };

        InvokePostServiceCall(apiConfig.GET_MESSAGES, requestBody)
            .then((data) => {
                let conversation = {};
                let messagesArr=[...data.data.messagesInfo]
                // Filter customer messages based on messageSubType
                for(let i=0;i<messagesArr.length;i++){
                    let filteredList=messagesArr[i].messages.filter((message)=>message.messageSubType!=="INTERNAL")
                    messagesArr[i].messages=[...filteredList]
                }
                // Filter out empty message lists
                let filteredMessageList=messagesArr.filter((item)=>item.messages.length>0);
                messagesArr=[...filteredMessageList]

                conversation.messageList = messagesArr;
                conversation.refreshMessages = false;
                if(
                    !convDetails.overallSentiment &&
                     enableSentimentCalculation==="Y"){
                    updateSentimentInSearchAndChannel(convDetails,data.data.messagesInfo,setConvDetails)
                }
                setMsgList(conversation);
                setChatLoading(false);
            })
            .catch((error) => {
                setSnackBarMsg(error.message);
                setOpenSnackbar(true);
                setChatLoading(false);
            });
    };
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };
    const onRowSelect = (e) =>{
        setSelectedConv(e.row.conversationId);
        setSelectedMsgId(e.row.messageId)
        setConvDetails(e.row);
        setShowConvDialog(true);
        getMessages(e.row);
    }

    const getTeams = () => {

        setTeamList(appContext.userInfo.role == "Admin" ? appContext.userInfo.allTeams : appContext.userInfo.teams);
        // InvokePostServiceCall(apiConfig.GET_TEAMS, { isActive: true })
        // .then((data) => {
        // setTeamList(data.data.teamDetails);
        //})
        //.catch((error) => {
        //console.log(error, "Error Message");
        //});
    };
    const getUsers = (payload) => {
        setUsersLoading(true);
        InvokePostServiceCall(apiConfig.GET_USERS, payload)
            .then((data) => {
                setUserList(data.data.userDetails);
                setUsersLoading(false);
            })
            .catch((error) => {
                setSnackBarMsg(error.message);
                setOpenSnackbar(true);
                setUsersLoading(false);
                console.log("usersApi", error);
            });
    };
    const getCommunications = (requestBody) => {
        setCommLoading(true)
        setLoadingBtn(true)
        InvokePostServiceCall(apiConfig.GET_SEARCH_COMMUNICATION_LIST, requestBody)
            .then((data) => {
                let tempArr = []
                data.data.communicationList.forEach((item, index) => {
                    item.id = index;
                    item.createdDate = moment(item.createdDate).local().format("MM-DD-YYYY")
                    item.lastModifiedDate = moment(item.lastModifiedDate).local().format("MM-DD-YYYY")
                    tempArr.push(item);
                })
                setCommunicationList(tempArr);
                setCommLoading(false);
                setLoadingBtn(false);
                console.log(data, "communications Data");
            })
            .catch((error) => {
                console.log(error, "Error Message");
                setSnackBarMsg(error.message);
                setOpenSnackbar(true);
                setCommLoading(false);
                setLoadingBtn(false);
            });
    };
    const handleClose = () => {
        setOpenDialog(false);
    };
    const handleCloseConvDialog = () => {
        setShowConvDialog(false);
        setConvDetails("")
        setSelectedConv()
        setMsgList([])
        appContext.setSelectedMsgId("");//clear selected message from search messages
    };
    // Function to map sentimentValue to corresponding labels
    const mapSentimentLabel = (sentimentValue) => {
        switch (sentimentValue) {
            case 1:
                return 'Very Unsatisfied';
            case 2:
                return 'Unsatisfied';
            case 3:
                return 'Neutral';
            case 4:
                return 'Satisfied';
            case 5:
                return 'Very Satisfied';
            default:
                return '';
        }
    };

    return (
        <>
            <SnackBar
                openSnackbar={openSnackbar}
                handleCloseSnackbar={handleCloseSnackbar}
                severity={"error"}
                userMessage={snackbarMsg}
            />
            <TabContext value={tabValue}>
                <CustomTabList tabList={tabList} showTabsCount={3} handleChangeTab={(event, selectedTabValue) => {
                    setTabValue(selectedTabValue)
                }}></CustomTabList>
                <TabPanel value="1" sx={{ padding: "0px" }}>
                    <h2 style={{ marginLeft: "10px", marginTop: "4px" }}>Search Conversations</h2>
                    <Box
                        sx={theme.customStyle.searchConvMainDiv}
                    >
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid
                                container
                                spacing={{ xs: 3, md: 3 }}
                                columns={{ xs: 12, sm: 12, md: 12 }}
                            >
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Item sx={theme.customStyle.searchConvRemoveBox}>
                                        <TextField
                                            sx={theme.customStyle.searchConvTextfield}
                                            error={phoneerr}
                                            helperText={phoneerr ? "Invalid Phone number." : ""}
                                            variant="standard"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onFocus={event => {
                                                event.target.setAttribute('autocomplete', 'new-password');
                                            }}
                                            label="Contact Phone"
                                            value={phone}
                                            onChange={(e) => {
                                                setPhoneerr(false);
                                                setPhone(e.target.value);
                                            }}
                                        />


                                    </Item>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Item sx={theme.customStyle.searchConvRemoveBox}>
                                        <TextField
                                            sx={theme.customStyle.searchConvTextfield}
                                            error={emailerr}
                                            helperText={emailerr ? "Invalid email address." : ""}
                                            variant="standard"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onFocus={event => {
                                                event.target.setAttribute('autocomplete', 'new-password');
                                            }}
                                            label="Email"
                                            value={email}
                                            onChange={(e) => {
                                                setEmailerr(false);

                                                setEmail(e.target.value);
                                            }}
                                        />
                                    </Item>
                                </Grid>

                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Item sx={theme.customStyle.searchConvRemoveBox}>
                                        <FormControl
                                            variant="standard"
                                            sx={theme.customStyle.searchConvSelect}

                                        >
                                            <InputLabel shrink={true}>Status</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                value={status}
                                                // defaultValue={"none"}
                                                onChange={handleStatus}
                                                label="Status"
                                            >
                                                <MenuItem value="none">None</MenuItem>
                                                <MenuItem value={"New"}>New</MenuItem>
                                                <MenuItem value={"Active"}>Active</MenuItem>
                                                <MenuItem value={"Active-Bot"}>Active-Bot</MenuItem>
                                                <MenuItem value={"UnAssigned"}>Missed</MenuItem>
                                                <MenuItem value={"Completed"}>Completed</MenuItem>
                                                <MenuItem value={"SentFromList"}>SentFromList</MenuItem>
                                                <MenuItem value={"PreAssigned"}>PreAssigned</MenuItem>
                                            </Select>
                                        </FormControl>

                                    </Item>
                                </Grid>
                                {/* {Array.from(Array(6)).map((_, index) => (
          <Grid item xs={2} sm={8} md={4} key={index}>
            <Item>xs=2</Item>
          </Grid>
        ))} */}
                            </Grid>
                        </Box>
                        <Box>

                        </Box>


                    </Box>

                    <Box>
                    </Box>
                    <Box
                        sx={theme.customStyle.searchConvMainDiv}
                    >
                        <Box sx={{ flexGrow: 1 }}>

                            <Grid
                                container
                                spacing={{ xs: 3, md: 3 }}
                                columns={{ xs: 12, sm: 12, md: 12 }}
                            >
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Item sx={theme.customStyle.searchConvRemoveBox}>
                                        <FormControl
                                            variant="standard"
                                            sx={theme.customStyle.searchConvSelect}
                                        >
                                            <InputLabel shrink={true}>Team</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                value={teamId === "" ? "00000000-0000-0000-0000-000000000000" : teamId}
                                                onChange={handleTeam}
                                                label="Team"

                                            >
                                                <MenuItem value="00000000-0000-0000-0000-000000000000" selected="true" onClick={() => { setTeamName("") }}>None</MenuItem>
                                                {teamList && teamList.map((option, index) => (
                                                    <MenuItem key={option.teamId} value={option.teamId}
                                                        onClick={() => { setTeamName(option.teamName); console.log("teamanem") }}
                                                    >
                                                        {option.teamName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Item sx={theme.customStyle.searchConvRemoveBox}>

                                        <FormControl
                                            variant="standard"
                                            sx={theme.customStyle.searchConvSelect}
                                        >
                                            <InputLabel shrink={true}>Agent</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                value={agent === "" || status == "UnAssigned" ? "00000000-0000-0000-0000-000000000000" : agent}
                                                onChange={handleAgent}
                                                label="Agent"
                                                disabled={status == "UnAssigned" ? true : false}

                                            >
                                                <MenuItem value={"00000000-0000-0000-0000-000000000000"}>None</MenuItem>
                                                {usersLoading && <div style={{ display: "flex", justifyContent: "center" }}><CircularProgress /></div>}
                                                {userList && userList.map((option, index) => (
                                                    <MenuItem key={option.user.userId} value={option.user.userId}>
                                                        {option.user.userName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>


                                    </Item>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Item sx={theme.customStyle.searchConvRemoveBox}>
                                        <TextField
                                            sx={theme.customStyle.searchConvTextfield}
                                            variant="standard"
                                            size="small"
                                            value={communicationSearchVal}
                                            InputLabelProps={{
                                                shrink: true,
                                                readOnly: true,
                                            }}
                                            onFocus={event => {
                                                event.target.setAttribute('autocomplete', 'new-password');
                                            }}
                                            autoComplete="off"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => { setOpenDialog(true) }}
                                                            edge="end"
                                                        >
                                                            <SearchIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            label="Communication"
                                        />
                                    </Item>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>

                    <Box
                        sx={theme.customStyle.searchConvMainDiv}
                    >
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid
                                container
                                spacing={{ xs: 3, md: 3 }}
                                columns={{ xs: 12, sm: 12, md: 12 }}
                            >
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Item sx={theme.customStyle.searchConvRemoveBox}>
                                        <TextField
                                            error={dateErr.fromDateErr}
                                            sx={theme.customStyle.searchConvTextfield}
                                            variant="standard"
                                            size="small"
                                            type={"date"}
                                            id="searchConversionfromDate"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onFocus={event => {
                                                event.target.setAttribute('autocomplete', 'new-password');
                                            }}
                                            value={date.fromDate}
                                            label="From Date"
                                            onChange={(e) => {
                                                setDate({
                                                    ...date,
                                                    fromDate: e.target.value,
                                                });
                                                setDateErr({ ...dateErr, fromDateErr: false, fromDate: false });
                                            }}
                                            helperText={dateErr.fromDateErr ? "Enter From Date" : ""}
                                        />
                                    </Item>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Item sx={theme.customStyle.searchConvRemoveBox}>
                                        <TextField
                                            error={dateErr.toDateErr}
                                            sx={theme.customStyle.searchConvTextfield}
                                            variant="standard"
                                            size="small"
                                            type={"date"}
                                            value={date.toDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onFocus={event => {
                                                event.target.setAttribute('autocomplete', 'new-password');
                                            }}
                                            label="To Date"
                                            onChange={(e) => {
                                                setDate({
                                                    ...date,
                                                    toDate: e.target.value,
                                                });
                                                if (new Date(e.target.value) < new Date(date.fromDate)) {
                                                    setDateErr({ ...dateErr, toDateErr: true });
                                                } else {

                                                    setDateErr({ ...dateErr, toDateErr: false });
                                                }
                                            }}
                                            helperText={dateErr.toDateErr ? "Enter to Date" : ""}
                                        />
                                    </Item>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Item sx={theme.customStyle.searchConvRemoveBox}>
                                        <FormControl
                                            variant="standard"
                                            sx={theme.customStyle.searchConvSelect}

                                        >
                                            <InputLabel shrink={true}>OverAll Sentiment</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                value={overAllSentiment}
                                                //defaultValue={"none"}
                                                onChange={handleSentiment}
                                                label="overAll Sentiment"
                                            >
                                                <MenuItem value="none">None</MenuItem>
                                                <MenuItem value={'1'}>Very Unsatisfied</MenuItem>
                                                <MenuItem value={'2'}>Unsatisfied</MenuItem>
                                                <MenuItem value={'3'}>Neutral</MenuItem>
                                                <MenuItem value={'4'}>Satisfied</MenuItem>
                                                <MenuItem value={'5'}>Very Satisfied</MenuItem>
                                            </Select>
                                        </FormControl>

                                    </Item>
                                </Grid>

                            </Grid>

                        </Box>
                    </Box>
                    <Box>
                        <Button
                            sx={theme.customStyle.searchConvBtn}
                            variant="contained"
                            onClick={() => {
                                getConversationsByFilter();
                            }}
                        >
                            Search
                        </Button>
                        <Button
                            sx={theme.customStyle.searchConvBtn}
                            variant="contained"
                            onClick={() => {
                                getConversationsByFilter(true);
                            }}
                        >
                            Export Message Log
                        </Button>

                        <Button
                            sx={theme.customStyle.searchConvBtn}
                            variant="contained"
                            onClick={() => {
                                clearSearch();
                            }}
                        >
                            Clear
                        </Button>
                    </Box>
                    {circularLoading && (
                        <div style={{ marginLeft: "46%", marginTop: "10%" }}>
                            <CircularProgress />
                        </div>
                    )}
                    {!circularLoading && !loading && convData.length < 1 && (
                        <div style={{ marginLeft: "44%", marginTop: "10%" }}>
                            No Conversations
                        </div>
                    )}

                    {convData.length > 0 && !circularLoading ? (
                        <Box sx={{ ...theme.customStyle.tableHead, marginTop: "10px" }}>
                            <StyledDataGrid
                                loading={loading}
                                rows={convData}
                                columns={columns}
                                pageSize={10}
                                rowsPerPageOptions={[10]}
                                onRowDoubleClick={(e) => {
                                    setSelectedConv(e.row.conversationId);
                                    setConvDetails(e.row);
                                    setShowConvDialog(true);
                                    getMessages(e.row);
                                }}
                                columnVisibilityModel={{
                                    // Hide Columns  
                                    teamName: showTeamName ? true : false,
                                    communicationName: showCommunicationName ? true : false,
                                }}
                            // initialState={{
                            //     sorting: {
                            //         sortModel: [{ field: "createdDate", sort: "desc" }],
                            //     },
                            // }}
                            />
                            <div>* All timestamps are in Pacific Time.</div>
                        </Box>
                    ) : null}

                   
                </TabPanel>
                <TabPanel sx={{ padding: "0px" }} value="2">
                    <SearchMessages
                    onRowSelect = {onRowSelect}
                    ></SearchMessages>
                </TabPanel>

            </TabContext>
            {/* {convData.length >= 100 && (
                <p
                    style={{
                        fontWeight: 500,
                        marginLeft: "10px",
                        marginTop: "5px",
                    }}
                >
                    *Only 100 Conversations are displayed, please use filter option to
                    refine the results further
                </p>
            )} */}
            <SearchConvChat
                tabName={tabValue === "2" ? "Messages" :"Conversations"}
                chatLoading={chatLoading}
                msgList={msgList}
                selectedConv={selectedConv}
                showConvDialog={showConvDialog}
                setShowConvDialog={setShowConvDialog}
                convDetails={convDetails}
                appContext={appContext}
                onAssignConvSuccess={onAssignConvSuccess}
                setSelectedConverstion={setSelectedConv}
                handleClose={handleCloseConvDialog}
                setConvDetails={setConvDetails}
            />

            <Dialog
                open={openDialog}
                onClose={handleClose}
                fullWidth
                maxWidth={"md"}
            >
                <DialogTitle>Communication Search</DialogTitle>
                <DialogContent>
                    <Box style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                        <TextField
                            variant="standard"
                            size="small"
                            value={communicationName}
                            onFocus={event => {
                                event.target.setAttribute('autocomplete', 'new-password');
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            label="Communication Name"
                            onChange={(e) => {
                                setCommunicationName(e.target.value)
                            }}
                        />

                        <LoadingButton sx={{ marginLeft: "16px" }} variant="contained"
                            loading={loadingBtn}
                            onClick={() => {
                                let requestBody = {
                                    communicationListName: communicationName,
                                    isActive: true
                                }
                                getCommunications(requestBody)
                            }}
                        >Search</LoadingButton>
                        <Button sx={{ marginLeft: "10px" }} variant="contained"
                            onClick={() => {
                                setCommunicationName("")
                                setCommunicationId("");
                                setCommunicationSearchVal("");
                                setCommunicationList([]);
                                setSelectedCommunication()
                            }}
                        >Clear</Button>
                    </Box>
                    {communicationList.length > 0 && <Box sx={{ ...theme.customStyle.tableHead, height: 500 }}>
                        <StyledDataGrid
                            rows={communicationList}
                            columns={communicationColumns}
                            pageSize={8}
                            rowsPerPageOptions={[8]}
                            onRowClick={(e) => {
                                setSelectedCommunication(e.row.id)
                                setCommunicationId(e.row.communicationListId)
                                setCommunicationSearchVal(e.row.communicationListName)
                            }}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: "createdDate", sort: "desc" }],
                                },
                            }}
                        />
                    </Box>}
                    {communicationList.length < 1 && commLoading === false && <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>No Communications</div>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        handleClose();
                    }}>Select</Button>
                    <Button onClick={() => {
                        setCommunicationId("");
                        setCommunicationSearchVal("");
                        setSelectedCommunication()
                        handleClose();
                    }}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default SearchConversation;
