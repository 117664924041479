import React, { Component } from 'react';
import queryString from 'query-string'
import apiConfig from "../../api/apiConfig";
import DocuSignMessage from "./DocuSignMessage";
import { Routes, Route, MemoryRouter } from "react-router-dom";

import './DocuSign.scss';
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from "@azure/msal-react";
import AuthContextProvider from "../../AuthContext";






class DocuSignAgentApp extends Component {
    constructor(props) {
        super(props)
        this.inputRef = React.createRef();
        this.state = {
          
        }

        
    }



    render() {
        return (
            <MemoryRouter>
                <AuthenticatedTemplate>
                <AuthContextProvider context="DocuSign">
                        <DocuSignMessage />
                    </AuthContextProvider>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <div className="App">
                        <h5 className="card-title">
                            You are not signed in! Please sign in.
                        </h5>
                    </div>
                </UnauthenticatedTemplate>
            </MemoryRouter>
        );
    }

}

export default DocuSignAgentApp;
