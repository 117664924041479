////12-02-2022 => ONKAR => Texting-875 added loader when opening the conversation from Search conversation and omni channel .
////05-08-2023 => SUDAM B CHAVAN => TEXTING - 1250 - Authentication indicator should be added to conversation page in Omni channel and Search conversations
////05-10-2023 => SUDAM B CHAVAN => TEXTING - 1161 - Email id to be shown below customer name when conversation is opened from search conversations page
////07-04-2023 => ONKAR => TEXTING-1403 - Sentiment UI enhancements.
////07-06-2023 => SUDAM B CHAVAN => TEXTING-1388- GPT Component
////07-10-2023 => ONKAR =>Texting-1404 Sentiment emoji added for mixed sentiment.
////07-10-2023 => SUDAM B CHAVAN =>  TEXTING-1417 - GPT Component Pending observations.
////07-11-2023 => ONKAR => TEXTING-1404 - Sentiment emoji is not showing correct emotion - Not consistent.(updated emojis as per new api changes).
////07-25-2023 => ONKAR => TEXTING-1475 - Critical: Sentiment Optimization
// 08-30-2023 => ONKAR => TEXTING-1578 -REACT Upgrade
////08-30-2023 => SUDAM B CHAVAN => TEXTING-1644 - Authenticated Conversations history issue
//10-09-2023 => ONKAR => TEXTING-1780 - Sentiment Emoji showing up for first outbound message in search conversations.
//10-12-2023 => ONKAR =>  TEXTING-1796 - Sentiment Re-Calculate button to calculate sentiment with latest messages.
//10-19-2023 => ONKAR =>TEXTING-1815 -Number 0 is showing on omni channel, History tab instead of Neutral emoji If overall sentiment of conversation is '0'.
//11-01-2023 => ONKAR =>TEXTING-1828 -Prod: Two Scrolls bars are displayed in search conversations.
////12-06-2023 => SUDAM B CHAVAN => TEXTING-1892 - 0: Survey : View Completed Survey in agent view
////12-08-2023 => SUDAM B CHAVAN => TEXTING-1892 0: Survey : View Completed Survey in agent view
////12-11-2023 => SUDAM B CHAVAN => TEXTING-1892 0: Survey : View Completed Survey in agent view-reopen item fixed
////12-11-2023 => SUDAM B CHAVAN => TEXTING-1904 0: Admin UI - Search messages observations.
////12-12-2023 => SUDAM B CHAVAN => TEXTING-1916 0: Configuration to Enable Survey in appsettings
import React, { useContext }from "react";
import {
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Tooltip
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Messenger from "../components/Messenger";
import { useTheme } from "@mui/material/styles";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import ChatHistory from "../components/ChatHistory";
import TaskAltTwoToneIcon from '@mui/icons-material/TaskAltTwoTone';
import { AuthContext } from "../AuthContext";
import CustomTabList from "../components/CustomTabList";
import SentimentEmoji from "./SentimentEmoji";
import ViewSurvey from "../pages/survey/viewSurvey";

function SearchConvChat(props) {
  const {
    convDetails,
    showConvDialog,
    setConvDetails,
    selectedConvForSentiment,
    msgList,
    chatLoading,
    handleClose,
    tabName
  } = props;
  const theme=useTheme();
  const [tabValue, setTabValue] = React.useState("1");
  const {
    enableSentimentCalculation,
    userInfo
  } = useContext(AuthContext);
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleCloseModal = (event, newValue) => {
    setTabValue("1");
    handleClose();
  };

  let sentimentComp =
    enableSentimentCalculation === "Y" && msgList?.messageList?.length > 0 && (convDetails?.overallSentiment || convDetails?.overallSentiment===0)? (
      <SentimentEmoji overallSentiment={convDetails?.overallSentiment} styles={{bottom: "1px"}} setConvDetails={setConvDetails} convDetails={selectedConvForSentiment?selectedConvForSentiment:convDetails} messageList={msgList.messageList} isRefresh={true}/>
    ) : (
      <></>
    );
  const tabList = [
    { label: "MESSAGES", value: "1", displayOrder: 1 }
  ]
  if (tabName !== "Messages") {//for search for messages then don't show other tabs except messages
    tabList.push({ label: "History", value: "2", displayOrder: 2 })

    if (userInfo.isGPTSummaryEnabled) {
      tabList.push({ label: "Summary", value: "3", displayOrder: 3 })
    }
    //userInfo.isSurveyEnabledAppSettings is the app level setting to enable the survey functionality if Y the only will show this tab
    if (convDetails?.status === 'Completed' && userInfo.isSurveyEnabledAppSettings) {
      // If conversation status is 'Completed' or the page is messages and isSurveyEnabled is true
      let isSurveyEnabled = false;
      if (userInfo.role === "Admin") {
        // If user role is 'Admin'
        isSurveyEnabled = userInfo.allTeams?.some((team) => team.teamId === convDetails.teamId && team.isSurveyEnabled === true);
      } else {
        // If user role is not 'Admin'
        isSurveyEnabled = userInfo.teams?.some((team) => team.teamId === convDetails.teamId && team.isSurveyEnabled === true);
      }
      if (isSurveyEnabled) {
        // If isSurveyEnabled is true, add an object to tabList
        tabList.push({ label: "Survey", value: "4", displayOrder: 4 });
      }
    }
  }
  return (
    <>
      <Dialog
        open={showConvDialog}
        fullWidth={true}
        maxWidth={"lg"}
        scroll={"paper"}
        // fullScreen={true}
        onClose={handleCloseModal}
      >
        <DialogTitle sx={{ padding: "16px 12px" }}>
          <div style={styles.titleDiv}>
            <div>
              <div
                style={{
                  // color: "#277a7c",//remove blue color
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                {(convDetails?.channel === 'Chat' && convDetails?.authenticationStatus === 'Authenticated')
                  && <Tooltip title="Authenticated">
                    <TaskAltTwoToneIcon color="success" sx={{ marginRight: "3px" }} />
                  </Tooltip>}
                {convDetails?.customerName?.trim() === ''
                  ? 'Unknown' 
                  : convDetails?.customerName}
              </div>
            
              {convDetails?.channel === 'Chat' ?
                (<div style={{ fontWeight: "bold", fontSize: "16px" }}>
                  {convDetails?.email} {sentimentComp}
                </div>) :
                (<div style={{ fontWeight: "bold", fontSize: "16px" }}>
                  {convDetails?.customerPhoneNumber || convDetails?.CustomerPhoneNumber}{sentimentComp}
                </div>)}
            </div>
            <div>
              <IconButton onClick={handleCloseModal}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>

        </DialogTitle>
        <DialogContent
          dividers={'paper'}
          className="customScroll"
          sx={{ paddingLeft: "4px", paddingRight: "4px" }}
        >
          {chatLoading ? <Box sx={{...theme.customStyle.convCircularProgress,marginTop:"15%"}}><CircularProgress/></Box>:
          <div>
              <TabContext value={tabValue}>
                  <CustomTabList tabList={tabList} showTabsCount={3} handleChangeTab={(event, selectedTabValue) => {
                  setTabValue(selectedTabValue)
                  }}></CustomTabList>
                <TabPanel value="1" sx={{ padding: "0px" }}>
                  <Messenger
                  tabName={tabName}
                    conversation={convDetails}
                    onAssignConvSuccess={props.onAssignConvSuccess}
                    appContext={props.appContext}
                    context={"dialog"}
                    msgList={msgList.messageList}
                    chatLoading={chatLoading}
                    theme={theme}
                  />
                 
                </TabPanel>
                <TabPanel sx={{ padding: "0px" }} value="2">
                  <ChatHistory
                    isFromModal={true}
                    conversation={convDetails?.customerPhoneNumber}
                    conversationId={convDetails?.conversationId}
                    email={convDetails?.email}
                    externalId={convDetails?.authId}
                  />
                </TabPanel>
                <TabPanel sx={{ padding: "0px" }} value="4">
                  <ViewSurvey
                    isFromModal={true}
                    conversationId={convDetails?.conversationId}
                  />
                </TabPanel>
                              
              </TabContext>
          </div>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
const styles = {
  dateStyle: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    fontSize: 10,
  },
  mainDiv: {
    padding: "10px",
  },
  progressStyle: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
  },
  titleDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  hrStyle: {
    width: "40%",
  },
};

export default SearchConvChat;
