/**
 * 08-04-2023 => SUDAM B CHAVAN => reset page index to 1 page on filter data
// 08-29-2023 => ONKAR => TEXTING-1578 -REACT Upgrade
// 08-30-2023 => ONKAR => TEXTING-1578 -REACT Upgrade
*/
import React, { useState, useEffect, useContext } from "react";
import { Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid } from "@mui/x-data-grid";
import { AuthContext } from "../../AuthContext";
import { useTheme } from "@mui/material/styles";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import moment from "moment";
import SnackBar from "../../components/SnackBar";
import { useNavigate } from "react-router-dom";
import apiConfig from "../../api/apiConfig";
import { TextField,InputAdornment } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import { StyledDataGrid } from "../../components/styledComponents/StyledDataGrid";


function BusinessHoursConfig() {
    const theme = useTheme();
    const navigate = useNavigate();
    const { messageApiToken } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [businessHourConfigList, setBusinessHoursConfigList] = useState([]);
    const [tempArr, setTempArr] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [page, setPage] = useState(0);
    useEffect(() => {
        if (messageApiToken) {
            InvokePostServiceCall(apiConfig.GET_BUSINESS_HOURS_CONFIG, {})
                .then((data) => {
                    setLoading(false);
                    let tempArr = [];
                    data.data.businessHoursConfigDetails.forEach((item, index) => {
                        item.businessHoursConfig.id = index;
                        item.businessHoursConfig.createdDate = moment(
                            item.businessHoursConfig.createdDate
                        )
                            .local()
                            .format("MM-DD-YYYY");
                        item.businessHoursConfig.lastModifiedDate = moment(
                            item.businessHoursConfig.lastModifiedDate
                        )
                            .local()
                            .format("MM-DD-YYYY");
                        tempArr.push(item.businessHoursConfig);
                    });
                    setBusinessHoursConfigList(tempArr);
                    setTempArr(tempArr);
                })
                .catch((error) => {
                    setSnackbarMsg(error.message)
                    console.log(error, "Error Message");
                    setOpenSnackbar(true);
                    setLoading(false);
                });
        }
    }, [messageApiToken]);

    const columns = [
        {
            field: "configName",
            headerName: "Config Name",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "timeZone",
            headerName: "Time Zone",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "createdDate",
            headerName: "Created Date",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "lastModifiedDate",
            headerName: "Modified Date",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
    ];
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };
    const filterTable = (value) => {
        let usArr = [];
        let val = value?.toLowerCase();
        setPage(0);
        tempArr.forEach((item) => {

            if (
                item.configName?.toLowerCase().includes(val) ||
                item.timeZone?.toLowerCase().includes(val) ||
                item.createdDate?.toLowerCase().includes(val) ||
                item.lastModifiedDate?.toLowerCase().includes(val)
            ) {
                usArr.push(item);
            }
        });
        setBusinessHoursConfigList(usArr);
    }

    return (
        <>
            <SnackBar
                openSnackbar={openSnackbar}
                handleCloseSnackbar={handleCloseSnackbar}
                severity={"error"}
                userMessage={snackbarMsg}
            />
            <div>
                <Box sx={theme.customStyle.tableRow}>
                    <h2 style={{ marginLeft: 10 }}>Business Hours Config</h2>
                </Box>
            </div>

            <div style={{ display: "flex", alignItems: "flex-start", paddingLeft: 10, m: 1 }}>
                <Box sx={theme.customStyle.tableRow}>
                    <Button
                        sx={theme.customStyle.modalbtn}
                        onClick={() => {
                            navigate("/addUpdateBusinessHoursConfig", {
                                state: { isAddBusinessHoursConfig: true },
                            });
                        }}
                    >
                        <AddIcon />
                    </Button>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <TextField
                            sx={{ marginRight: "10px", marginBottom: "10px" }}
                            size="small"
                            type="search"
                            variant="outlined"
                            margin="normal"
                            id="outlined-search"
                            label=""
                            placeholder="Bus.Hours Search"
                            autoComplete="off"
                            onChange={(e) => {
                                if (e.target.value) {
                                    filterTable(e.target.value);
                                }
                                else {
                                    setBusinessHoursConfigList(tempArr);
                                }

                            }}
                            InputLabelProps={{
                                shrink: false,
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                </Box>
            </div>
            <Box sx={theme.customStyle.tableHead}>
                <StyledDataGrid
                    loading={loading}
                    rows={businessHourConfigList}
                    columns={columns}
                    pageSize={10}
                    page={page}
                    onPageChange={(page) => setPage(page)}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                    components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                    }}
                    onRowDoubleClick={(e) => {
                        navigate("/addUpdateBusinessHoursConfig/" + e.row.businessHoursConfigId, {
                            state: { isAddBusinessHoursConfig: false, businessHourConfigDetails: e.row },
                        });
                    }}
                    // initialState={{
                    //     sorting: {
                    //         sortModel: [{ field: 'lastModifiedDate', sort: 'desc' }],
                    //     },
                    // }}
                />
            </Box>
        </>
    );
}

export default BusinessHoursConfig;
