class authData {
    token = "";

    getToken = () => this.token;

    setToken = (token) => {
        this.token = token;
    };
}
class webChatAuthData {
  authData ={
    token : "",
    deploymentId:""};

    getAuthData = () => this.authData;

    setAuthData = (data) => {
        this.authData = data;
    };
}


const tokenData = new authData();
export const webchatTokenData = new webChatAuthData();

export default tokenData;