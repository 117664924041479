//// 05/05/2023 => ONKAR => Webchat to send page level information when conversation started
//// 06/09/2023 => SUDAM B CHAVAN => TEXTING-1323 - When click on info button, scroll bar in info page is moving to bottom automatically.
import React,{useState, useEffect} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
  DialogTitle,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import ConversationAssignmentHistory from "./ConversationAssignmentHistory";
import ConversationAttributesInfo from './ConversationAttributesInfo';
import VisitorInfo from './VisitorInfo';

export function Information(props) {
  const theme = useTheme();
  const [isViewMore, setIsViewMore] = useState(false)
  const [isViewVisitorInfo, setIsViewVisitorInfo] = useState(false)
  const [isViewMoreConvAttributes, setIsViewMoreConvAttributes] = useState(false)
  const [title, setTitle] = useState("")
  useEffect(() => { 
    setIsViewMore(false);
    setIsViewMoreConvAttributes(false);
    setIsViewVisitorInfo(false);
  }, [props])
    console.log(props,"propssss")
  return (
    <Dialog
      open={props.state.showInfo}
      onClose={props.handleInfoModalClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={props.dialogFullWidth}
    >
      <DialogTitle>
        <Box>
          <strong>{title ? title : "Information"}</strong>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent sx={isViewMore || isViewMoreConvAttributes || isViewVisitorInfo ?{padding:"0px 3px"  }:{}}>
        {props.state.showLoader ? (
          <Box sx={{ display: "flex", marginLeft: "40%" }}>
            <CircularProgress />
          </Box>
        ) : (
          /*  <div>
                         <Typography gutterBottom>Source: {props.state.conversation.source}</Typography>
                         <Typography gutterBottom>Team: {props.state.appContext.userInfo.teamName}</Typography>
                         <Typography gutterBottom>
                             Logged in User Id: 
                         </Typography>
                         <Typography gutterBottom>
                             Conversation ID: {props.state.conversation.conversationId}
                         </Typography>
                         <Typography gutterBottom>
                             Agent : {props.state.conversation.agentName} - {props.state.conversation.agentId}
                         </Typography>
                         <Typography gutterBottom>
                             Conversation Created By  {props.state.conversation.createdByName}  & Date:  {props.state.conversation.createdDate}
                         </Typography>
                         <Typography gutterBottom>
                             Conversation Ended By {props.state.conversation.conversationEndedByName}  & Date: {props.state.conversation.conversationEndedDate}
                         </Typography>
                         <Typography gutterBottom>
                             Assignment History: {props.state.conversation.notes}
                         </Typography>
                     </div> */
       <>
        {!isViewMore && !isViewMoreConvAttributes&& !isViewVisitorInfo &&
          <div> 
            <div style={{ ...theme.customStyle.infoListStyle, paddingTop: "0px" }}>
              <div style={theme.customStyle.infoListLabel}>Source:</div>
              <div>{props.state.conversation.source}</div>
            </div>
            <Divider />
            <div style={theme.customStyle.infoListStyle}>
              <div style={theme.customStyle.infoListLabel}>Team:</div>
              <div>{props.state.conversation.teamName}</div>
            </div>
            <Divider />
            <div style={theme.customStyle.infoListStyle}>
              <div style={theme.customStyle.infoListLabel}>Phone Number:</div>
              <div>{props.state.conversation.userPhoneNumber}</div>
            </div>
            <Divider />
            <div style={theme.customStyle.infoListStyle}>
              <div style={theme.customStyle.infoListLabel}>Logged in User:</div>
              <div>
                {props.state.appContext.userInfo.userName} (
                {props.state.appContext.userInfo.userId})
              </div>
            </div>
            <Divider />
            <div style={theme.customStyle.infoListStyle}>
              <div style={theme.customStyle.infoListLabel}>Conversation ID:</div>
              <div>{props.state.conversation.conversationId}</div>
            </div>
            <Divider />
            {props.state.conversation.status !== "UnAssigned" ? (
              <>
                <div style={theme.customStyle.infoListStyle}>
                  <div style={theme.customStyle.infoListLabel}>Agent:</div>
                  <div>
                    {props.state.conversation.agentName}
                    {props.state.conversation.agentId != null ? "( " : ""}
                    {props.state.conversation.agentId}
                    {props.state.conversation.agentId != null ? " )" : ""}
                  </div>
                </div>
                <Divider />
              </>
            ) : null}
            {props.state.conversation.createdByName !== "" &&
              props.state.conversation.createdByName != null ? (
              <>
                <div style={theme.customStyle.infoListStyle}>
                  <div style={theme.customStyle.infoListLabel}>Created By:</div>
                  <div>{props.state.conversation.createdByName}</div>
                </div>
                <Divider />
              </>
            ) : null}
            <div style={theme.customStyle.infoListStyle}>
              <div style={theme.customStyle.infoListLabel}>Created On:</div>
              <div>
                {new Date(
                  props.state.conversation.createdDate
                ).toDateString()}
              </div>
            </div>
            <Divider />
            {props.state.conversation.conversationEndedByName !== "" &&
              props.state.conversation.conversationEndedByName != null ? (
              <>
                <div style={theme.customStyle.infoListStyle}>
                  <div style={theme.customStyle.infoListLabel}>Ended By:</div>
                  <div>
                    {props.state.conversation.conversationEndedByName}
                    {props.state.conversation.conversationEndedByName !== ""
                      ? " on "
                      : ""}
                    {props.state.conversation.conversationEndedDate != null &&
                      props.state.conversation.conversationEndedDate !==
                      undefined
                      ? new Date(
                        props.state.conversation.conversationEndedDate
                      ).toDateString()
                      : props.state.conversation.conversationEndedDate}
                  </div>
                </div>
                <Divider />
              </>
            ) : null}
            {props.msglist !== "" &&
              props.msglist != null ? (
              <>
                <div style={theme.customStyle.infoListStyle}>
                  <div style={theme.customStyle.infoListLabel}>
                    Assignment History:
                  </div>
                  <div>
                    {

                      props.msglist &&
                      props.msglist.map((msg, index) => {
                        return (

                          msg.messages.map((msg1, index1) => {

                            if (msg1.messageType === "INFO") {
                              return (
                                <div>
                                  {moment(msg1.createdDate).local().format("MM-DD-YYYY") + " " + moment(msg1.createdDate).local().format("LT") + " Conversation " + msg1.messageBody + "(" + props.state.conversation.agentId + ")"}
                                </div>

                              )
                            }

                          })
                        )


                      })


                    }




                  </div>
                </div>
                <Divider />        
              </>
            ) : null}
             
          </div>}
              
          </>
        )}
      </DialogContent>
      <div style= {{padding: "10px 24px"}}>
        {props.state.conversation.status !== "UnAssigned" && !isViewMore && !isViewVisitorInfo &&
          <ConversationAttributesInfo
            conversation={props.state.conversation}
            isViewMore={isViewMoreConvAttributes}
            setIsViewMore={setIsViewMoreConvAttributes}
          />}
        {props.state.conversation.status !== "UnAssigned" && !isViewMoreConvAttributes && !isViewVisitorInfo &&
          <ConversationAssignmentHistory
            conversationId={props.state.conversation.conversationId}
            isViewMore={isViewMore}
            setIsViewMore={setIsViewMore}
          />}
        
        {!isViewMore && !isViewMoreConvAttributes &&
        <VisitorInfo
          setTitle={setTitle}
            conversationId={props.state.conversation.conversationId}
          isOpen={isViewVisitorInfo}
          setIsOpen={setIsViewVisitorInfo}
          />}
        </div>
        <DialogActions>
        <Button onClick={props.handleInfoModalClose} autoFocus>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CloseIcon size={20} color="primary" />
            <span>
              <strong> Close</strong>
            </span>
          </div>
        </Button>
      </DialogActions>
    </Dialog>
  );
}