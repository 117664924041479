import axios from "axios";
import { useContext } from "react";
import { AuthContext } from "../AuthContext";
import jwt_Decode from "jwt-decode";
import { useMsal } from "@azure/msal-react";
import dayjs from "dayjs";
import { InvokePostServiceCall } from "./serviceUtil";
import apiConfig from "./apiConfig";

const useAxios = (url, body) => {
  const { messageApiToken, setMessageApiToken } = useContext(AuthContext);
  const { instance, accounts } = useMsal();
  const token = messageApiToken;
  const axiosInstance = axios.create({
    url,
    data: body,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  axiosInstance.interceptors.request.use(async (req) => {
    const user = jwt_Decode(token);

    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
    console.log(isExpired, "isExpired!!!!!!!!!!!!!");

    if (!isExpired) {
      return req;
    } else {
      const loginRequest = {
        scopes: [apiConfig.API_AD_SCOPE],
      };
      const tokenRequest = { loginRequest, account: accounts[0] };
      let logInReq = {
        userDetail: {
          user: {
            azureAdid: accounts[0].localAccountId.toLowerCase(),
          },
        },
      };
      instance.acquireTokenSilent(tokenRequest).then((response) => {
        InvokePostServiceCall(apiConfig.LOGIN, logInReq, response.accessToken)
          .then((apiresponse) => {
            setMessageApiToken(apiresponse.data.token);
          })
          .catch((error) => {
            console.log("Error in AuthContxt" + error.message);
          });
      });

      return req;
    }
  });

  return axiosInstance;
};
export default useAxios;
