//// 11-14-2022 => version info added.
//// 08 - 10 - 2023 PRABHAT TEXTING - 1561 Agent profile picture should be shown in Texting UI view.
//09-05-2023 => ONKAR =>TEXTING-1668- React Upgrade : End conversation error.
//11-03-2023 => ONKAR =>TEXTING-1829- When sign out from click to message and re login is not updating user status to available.
import React, { useState, Fragment, useEffect, useContext } from "react";
import "./SignIn.css";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { styled, useTheme } from '@mui/material/styles';
import { Avatar, Menu, MenuItem, ListItemIcon, IconButton, Divider, Box, Badge } from '@mui/material';
import { Login, Logout, Circle } from '@mui/icons-material';
import apiConfig from "../api/apiConfig";
import useAxios from "../api/useAxios";
import { AuthContext } from "../AuthContext";
import packageJson from '../../package.json';


/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const SignIn = (props) => {
    const axios = useAxios();
    const availableColor = '#44b700';
    const busyColor = 'red';
    const offlineColor = '#b8bfb3';
    const availableStatus = 'Available';
    const busyStatus = 'Busy';
    const offlineStatus = 'Offline';
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const { isConnected } = props;
    const {
        statusChanged,
        setDecision,
        decision,
        userInfo, ProfileImgBase64
    } = useContext(AuthContext);

    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            backgroundColor: props.userStatusBgColor,
            color: props.userStatusBgColor,
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: 'ripple 1.2s infinite ease-in-out',
                border: props.userStatusBgColor === availableColor ? '1px solid currentColor' : '0px solid currentColor',
                content: '""',
            },
        },
        '@keyframes ripple': {
            '0%': {
                transform: 'scale(.8)',
                opacity: 1,
            },
            '100%': {
                transform: 'scale(2.4)',
                opacity: 0,
            },
        },
    }));

    const StyledBadgeOffline = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            backgroundColor: '#b8bfb3',
            color: '#b8bfb3',
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: 'ripple 1.2s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""',
            },
        },
    }));
   
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogin = () => {
        if (!isAuthenticated) {
            instance.loginPopup(loginRequest).catch(e => {
                console.log(e);
            });
        } else if (isAuthenticated) {
            localStorage.removeItem("jwtToken");//removing token from local storage
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: window.location.href
            });
        }
    }
    const onUserStatusSelectionChange = (status) => {
        switch (status) {
            case availableStatus:
                props.setUserStatusBgColor(availableColor);
                break;
            case busyStatus:
                props.setUserStatusBgColor(busyColor);
                break;
            default:
                props.setUserStatusBgColor(offlineColor);
                break;
        }

        //console.log("Properties: ", props.userInfo);
        var updateUserStatusReq = {
            userDetail: {
                user:
                {
                    userId: props.userInfo.userId,
                    status: status
                }
            }
        };

        axios
            .post(apiConfig.UPDATE_USER_STATUS, updateUserStatusReq)
            .then((res) => {
                console.log(res);
            })
            .catch((error) => {
                console.log("User status update failed: ", error.message);
            });
    };
    // useEffect(() => {
    //     if (decision === "Rejected") {
    //         onUserStatusSelectionChange(busyStatus);
    //     }
    // }, [statusChanged]);

    let loginMenuItem;

    if (isAuthenticated) {
        loginMenuItem = (
            <div>
                <MenuItem
                    onClick={() => {
                        setDecision("");
                        onUserStatusSelectionChange(availableStatus);
                    }}
                >
                    <ListItemIcon>
                        <Circle fontSize="small" sx={{ color: availableColor }} />
                    </ListItemIcon>
                    Available
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setDecision("");
                        onUserStatusSelectionChange(busyStatus);
                    }}
                >
                    <ListItemIcon>
                        <Circle fontSize="small" sx={{ color: busyColor }} />
                    </ListItemIcon>
                    Busy
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setDecision("");
                        onUserStatusSelectionChange(offlineStatus);
                    }}
                >
                    <ListItemIcon>
                        <Circle fontSize="small" sx={{ color: offlineColor }} />
                    </ListItemIcon>
                    Offline
                </MenuItem>

                <MenuItem
                    onClick={() => {

                        props.setShowprofile(true);

                        //navigate("/userProfile", {  state: { isAddUser: false, userId: userInfo.userId, isProfile:true }});
                    }}
                >
                    <ListItemIcon>
                        <Avatar src={ProfileImgBase64 ? ProfileImgBase64 : userInfo?.profileImg} fontSize="small" sx={{ ...theme.customStyle.convAvatar, }} />                    </ListItemIcon>
                    {userInfo?.userName}
                </MenuItem>

                <Divider />
                <MenuItem onClick={handleLogin}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Sign Out
                </MenuItem>
                <Divider/>
                <Box sx={theme.customStyle.versionDetails}>
                <span>Version: {packageJson.version}</span>
                </Box>
            </div>
        );
    }
    else {
        loginMenuItem = <MenuItem onClick={handleLogin}><ListItemIcon><Login fontSize="small" /></ListItemIcon>Sign In</MenuItem>;
    }

    let badge;
    if (isConnected) {
        //Muzaffar
        //if (props.userInfo && props.userInfo.status) {
        //    setCurrUserStatus(props.userInfo.status);
        //}

        let userNameInitial = '';
        if (accounts[0]?.name) {
            let nameArray = accounts[0]?.name.split(" ");
            if (nameArray.length > 0)
                userNameInitial = nameArray[0].charAt(0);
            if (nameArray.length > 1)
                userNameInitial += nameArray[1].charAt(0);
        }
        const avatarProps = {
            sx: theme.customStyle.signInAvatar,
            alt: accounts[0]?.name,
            src: ProfileImgBase64 ? ProfileImgBase64 : userInfo.profileImg
        };
        badge = <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
            <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant="dot">
                <Avatar {...avatarProps}>
                    {userNameInitial}
                </Avatar>
            </StyledBadge>
        </IconButton>
    }
    else {
        badge = <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
            <StyledBadgeOffline
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant="dot">
                {/*<Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />*/}
                <Avatar sx={{ width: 32, height: 32 }}></Avatar>
            </StyledBadgeOffline>
        </IconButton>
    }

    return (
        <Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                {/*<Typography sx={{ minWidth: 100 }}>Contact</Typography>*/}
                {/*<Typography sx={{ minWidth: 100 }}>Profile</Typography>*/}
                {/*<Tooltip title="Account settings">*/}
                {badge}
                {/*</Tooltip>*/}
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: '#f0f8ff',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {/*<MenuItem>*/}
                {/*    <Avatar /> Profile*/}
                {/*</MenuItem>*/}
                {/*<MenuItem>*/}
                {/*    <Avatar /> My account*/}
                {/*</MenuItem>*/}
                {/*<Divider />*/}
                {/*<MenuItem>*/}
                {/*    <ListItemIcon>*/}
                {/*        <PersonAdd fontSize="small" />*/}
                {/*    </ListItemIcon>*/}
                {/*    Add another account*/}
                {/*</MenuItem>*/}
                {/*<MenuItem>*/}
                {/*    <ListItemIcon>*/}
                {/*        <Settings fontSize="small" />*/}
                {/*    </ListItemIcon>*/}
                {/*    Settings*/}
                {/*</MenuItem>*/}
                {loginMenuItem}
                {/*<MenuItem>*/}
                {/*    <ListItemIcon>*/}
                {/*        <Logout fontSize="small" />*/}
                {/*    </ListItemIcon>*/}
                {/*    Logout*/}
                {/*</MenuItem>*/}
            </Menu>
        </Fragment>
        /*<button className="signInButton " onClick={handleLogin}>{isAuthenticated?'Sign Out' : 'Sign In'}</button>*/

    )
}