/**
 *10-17-2023 => SUDAM B CHAVAN => TEXTING-1646 Reporting - Realtime Reports Design
 *10-20-2023  => SUDAM B CHAVAN => TEXTING-1646 Reporting - Realtime Reports Design-reopen items
 */
import React, { useState, useEffect, useContext } from "react";
import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client'
import apiConfig from "../api/apiConfig";
import { InvokeGetServiceCall } from "../api/serviceUtil";
import { AuthContext } from "../AuthContext"; 
import { CircularProgress } from "@mui/material";
import SnackBar from "./SnackBar";
function PowerBIReport() {
    const { messageApiToken } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [reportDetails, setReportDetails] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    useEffect(() => {
        if (messageApiToken){
        setLoading(true);
        InvokeGetServiceCall(apiConfig.GENERATE_POWERBI_ACCESS_TOKEN)
            .then((data) => {
                setLoading(false);
                setReportDetails(data.data)
            })
            .catch((error) => {
                setSnackbarMsg(error.message);
                setOpenSnackbar(true);
                setLoading(false);
            });
        }
    }, [])
    useEffect(() => {
        //re-render the component when report Details state is changed
    }, [reportDetails])
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };



    return (

        <>
            {
                loading ? (
                    <div style={styles.progressStyle} >
                        <CircularProgress />
                    </div>
                ) : (<>
                    <SnackBar
                        openSnackbar={openSnackbar}
                        handleCloseSnackbar={handleCloseSnackbar}
                        severity={"error"}
                        userMessage={snackbarMsg}
                    />
                    {reportDetails &&
                        <PowerBIEmbed
                            embedConfig={{
                                type: 'report',   // Supported types: report, dashboard, tile, visual, qna, paginated report and create
                                id: reportDetails.reportId,
                                embedUrl: reportDetails.embedUrlembedUrl,
                                accessToken: reportDetails.embedToken,
                                tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
                                settings: {
                                    panes: {
                                        filters: {
                                            expanded: true,
                                            visible: true
                                        }
                                    },
                                    navContentPaneEnabled: false,
                                    background: models.BackgroundType.Transparent,
                                }
                            }}

                            eventHandlers={
                                new Map([
                                    ['loaded', function () { console.log('Report loaded'); }],
                                    ['rendered', function () { console.log('Report rendered'); }],
                                    ['error', function (event) { console.log(event.detail); }],
                                    ['visualClicked', () => console.log('visual clicked')],
                                    ['pageChanged', (event) => console.log(event)],
                                ])
                            }

                            cssClassName={"bodyContainer dashboardContainer"}


                            getEmbeddedComponent={(embeddedReport) => {
                                window.Report = embeddedReport;
                            }}
                        />}
                </>)}
        </>
    )
}
const styles = {
    progressStyle: {
        display: "flex",
        justifyContent: "center",
        marginTop: "30px",
    },
};
export default PowerBIReport