import React, { useContext,useEffect } from "react";
import { Badge, IconButton, Avatar } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { AuthContext } from "../AuthContext";
import { useNavigate } from "react-router-dom";
import { InvokePostServiceCall } from "../api/serviceUtil";
import apiConfig from "../api/apiConfig";
import { mapConversation } from "../extensions/Util";

function HeaderNotifications(props) {
  const {
    setHeaderNotification,
    notiFicationFilter,
    setNotificationFilter,
    inactiveConvCount,
    setSetupCollapse,
    setOpenConversationDrawer
  } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleClickUnAssigned = () => {
    setSetupCollapse(false);
    setOpenConversationDrawer(true);
    setHeaderNotification(true);
    props.setSelectedMenu("unassigned")
    setNotificationFilter(!notiFicationFilter);
  };
  const handleClickInActive = () => {
    setSetupCollapse(false);
    setOpenConversationDrawer(true);
    props.getInActiveConversations();
  };
  let badgeBackgroundOrange = {
    "& .MuiBadge-badge": {
      color: "#fff",
      backgroundColor: "orange"
    }
  };

  return (
    <>
      <IconButton
        color="inherit"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        // onClick={() => {
        //   if (props.unassignedConvCounts > 0 || inactiveConvCount > 0) {
        //     setSetupCollapse(false);
        //     setOpenConversationDrawer(true);
        //   }
        // }}
      >
        <Badge
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          badgeContent={props.unassignedConvCounts} color="error" onClick={() => {if (props.unassignedConvCounts > 0) { handleClickUnAssigned(); } }}>
          <NotificationsIcon />
        </Badge>
        <Badge
          style={{ marginTop: 10, marginLeft: 5 }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          badgeContent={inactiveConvCount}
          sx={badgeBackgroundOrange}
          onClick={handleClickInActive}
        ></Badge>
      </IconButton>
    </>
  );
}

export default HeaderNotifications;
