// 08-29-2023 => ONKAR => TEXTING-1578 -REACT Upgrade 

import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .MuiDataGrid-row:nth-child(odd)": {
        background: "#eeeeee",
    },
    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
        {
            backgroundColor: "#505f79",
            height: "55px",
        },
    }));

