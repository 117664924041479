
/********************************************************************************************************************************************
Name: CIFUTIL
=============================================================================================================================================
Purpose:
Contains all the CIF methods to connect with D:
History:
VERSION     AUTHOR              DATE            DETAILS
1.0         Rajesh              12/01/2021      Initial creation of CIF component
1.1         Rajesh              11/08/2022      Updated CIF to start conversation from the member profile
////11-01-2022 => Texting-734 IB/OB, Team Name changes  
////11-01-2022 => Texting-734 IB/OB, Team Name changes
////11-03-2022 => Texting-734 IB/OB, Team Name changes reopen bugs fixed.
////11-04-2022 => Texting-734 IB/OB, Team Name changes reopen bugs fixed (changed values to lowercase for comparing).*/
////11-28-2022 => Texting-847 Ability to take over existing conversation assigned to someone else by agent.
////12-01-2022 => ONKAR  => Texting-874 Assign to me option is showing for missed conversations, nonwhite list alert, Consent unavailable alert fixed.
////12/09/2022 => ONKAR => Texting-913 Prompt the Templates to the User before start the conversation
////25/01/2022 => RAJESH => FIxed the SF linked entities names issue
////06/14/2023 => ONKAR => Texting-1339 Inbound Conversation from Unassigned - Queue.
////06/14/2023 => ONKAR => Texting-1347 Red is not disappearing after viewing the customer’s message on the conversation initiated from team that is not opted for start conv with templates and on conv from individual Team.
////06/15/2023 => ONKAR => undo Texting-1347 changes.
////06-23-2023 => ONKAR => Texting-1364 Team is changing to users default team when member assigns unassigned conversation to himself.
////07-18-2023 => SUDAM B CHAVAN => TEXTING-1436 - Team Level Attributes to use in tokens
////07-18-2024 => SUDAM B CHAVAN =>When contact details are modified and updated to member from application, Still contact details page is showing as application record is linked
////07-22-2024 => SUDAM B CHAVAN =>When Assigning the conversation manually from omni channel or search conversations and click on assign to me button from alert popup, Texting UI is showing blank page


import { InvokePostServiceCall } from "../api/serviceUtil";
import apiConfig from "../api/apiConfig";
import { dataService } from "../dataService";
import moment from "moment";

export async function searchAndScreenPop(mobileNo) {
    if (window.sforce != null) {

        window.sforce.opencti.searchAndScreenPop({
            searchParams: mobileNo, callType: window.sforce.opencti.CALL_TYPE.INBOUND , deferred: false, callback: function (result) {
                if (result.success) {
                    console.log(result.returnValue);
                } else {
                    console.log(result.errors);
                }
            } });

    } 
}


export async function searchOppandFinAccountSF(recordId) {
    var searchResults = new Object();
    searchResults.finAccountResults = [];
    searchResults.oppResults = [];
    return searchResults;

}

var isClicked;
export async function startConversationSF(data, azureID, crmMemberFields, teamId) {

    console.log("startCustomConversation-cifutil-start - IN SF" + data);

    console.log("startCustomConversation- cifutil - executing" + data);
    var contactFields = ["createddate", "email", "name", "MailingState", "csg__TimeZone__c"];
    var acctFields = [];        //List of fields to be retrieved from Account object
    var oppFields = ["AccountId"];    //List of fields to be retrieved from Opportunity object

    let ObjData = JSON.parse(data);
    var recordId = ObjData.recordId;

    //Extract context/object specific fields mentioned in tokens
    crmMemberFields.forEach((val, key) => {
        var conPrefix = (ObjData.entityLogicalName === "opportunity" ? "account.personcontact" : "");
        if (val.includes(".")) {
            //Add tokens related to account object to list
            if (val.split(".")[0].toLowerCase() == 'saccount' && val.split(".")[1].toLowerCase() != 'name' && val.split(".")[1].toLowerCase() != 'email') {
                acctFields.push(val.substring(val.indexOf(".") + 1, val.length));
            }
            //Add tokens related to contact object to list
            else if (val.split(".")[0].toLowerCase() == 'scontact' && val.split(".")[1].toLowerCase() != 'name' && val.split(".")[1].toLowerCase() != 'email') {
                contactFields.push(val.substring(val.indexOf(".") + 1, val.length));
            }
            //Add tokens related to opportunity object to list
            else if (val.split(".")[0].toLowerCase() == 'sopportunity') {   //Get token variable for opportunity. val.substring to get token values for related object fields e.g. sopportunity.account.name
                oppFields.push(val.substring(val.indexOf(".") + 1, val.length));
            }
        }
    });

    var contactFieldsString = contactFields?.join(',');
    var acctFieldsString = acctFields?.join(',');
    var oppFieldsString = oppFields?.join(',');
    var methodName, methodParams = '';

    //Set Apex Method and Params based on entity type
    if (ObjData.entityLogicalName === "account") {
        methodName = "getAccount";
        methodParams = 'conId=' + recordId + '&fieldNames=' + acctFieldsString;
    }
    else if (ObjData.entityLogicalName === "contact") {
        methodName = "getContact";
        methodParams = 'conId=' + recordId + '&fieldNames=' + contactFieldsString;
    }
    else if (ObjData.entityLogicalName === "opportunity") {
        methodName = "getOpportunity";
        methodParams = 'conId=' + recordId + '&fieldNames=' + oppFieldsString;
    }

    //Invokes API method
    window.sforce.opencti.runApex({
        apexClass: "TextingUtil",
        methodName: methodName,
        methodParams: methodParams,
        callback: function (response) {
            if (response.success == true && response.returnValue.runApex != '') {
                try {
                    var conResponse = JSON.parse(response.returnValue.runApex);
                    var convAttributes = {};


                    var reqDataObj = {};
                    reqDataObj.conversation = {};

                    //Build conversation attributes with Tokens and respective values
                    crmMemberFields.forEach((val, key) => {
                        if (!convAttributes.hasOwnProperty(key)) {
                            if (val.includes(".")) {
                                convAttributes[key] = getConvAttrValue(conResponse, val);
                            }
                        }
                    });

                    reqDataObj.conversation.source = 'CRM';
                    reqDataObj.conversation.agentId = azureID;
                    reqDataObj.conversation.customerPhoneNumber = ObjData.number;
                    reqDataObj.conversation.externalId = recordId;
                    reqDataObj.conversation.externalType = "Contact"
                    reqDataObj.contact = {};
                    var customerName;
                    reqDataObj.contact.stateCode = conResponse.MailingState;
                    reqDataObj.contact.timezone = conResponse.csg__TimeZone__c;

                    reqDataObj.contact.email = conResponse.Email;
                    if (ObjData.entityLogicalName === "opportunity") {
                        //Get customer name from account related to opportunity
                        customerName = conResponse['Account']['Name']?.split(" ");
                        reqDataObj.contact.contactAttributes = "{ \"ExternalId\": \"" + recordId + "\",\"ContactId\": \"" + conResponse['AccountId'] + "\"}";
                        convAttributes.parentcontactid = conResponse['Account']['PersonContactId'];
                    }
                    else {
                        customerName = ObjData.recordName.split(" ");
                        reqDataObj.contact.contactAttributes = "{ \"ExternalId\": \"" + recordId + "\",\"ContactId\": \"" + recordId + "\"}";
                        convAttributes.parentcontactid = ObjData.recordId;
                    }
                    convAttributes.wcu_externalid = recordId;
                    convAttributes.ActivityId = "";
                    convAttributes.EntityLogicalName = ObjData.entityLogicalName;
                    convAttributes.EntityKeyfield = "id";
                    convAttributes.EntityId = recordId;
                    convAttributes.PhoneNumber = ObjData.number;
                    if (customerName.length > 1) {
                        reqDataObj.contact.firstName = customerName[0];
                        reqDataObj.contact.lastName = customerName[1];
                    }
                    else {
                        reqDataObj.contact.firstName = ObjData.recordName;
                    }
                    reqDataObj.teamId = teamId;

                    reqDataObj.conversation.ConversationAttributes = JSON.stringify(convAttributes);
                    isClicked = null;
                    var reqData = JSON.stringify(reqDataObj);
                    let url = apiConfig.START_CONVERSATION;
                    InvokePostServiceCall(url, reqData)
                        .then((resp) => {
                            var response = resp.data;
                            if (response != null && response.status === "SUCCESS") {
                                console.log("Conversation Created succesfully" + JSON.stringify(response));
                                dataService.setData({ showAlertMessage: response.status });
                            }
                            else if (response != null && response.status === 'FAILED' && response.errorDescription != null) {
                                if (response.isActiveinCommList == true || response.errorDescription.includes("Customer is already in active conversation") || response.errorDescription.includes("Conversation is in unassigned queue")) {
                                    var selfAssignConvObj = new Object();
                                    selfAssignConvObj.conversationId = response.conversationId;
                                    selfAssignConvObj.alertText = response.errorDescription;
                                    selfAssignConvObj.teamId = response.teamId;
                                    dataService.setData({ showSelfAssignAlert: selfAssignConvObj });
                                }
                                else {
                                    dataService.setData({ showAlertMessage: response.errorDescription });
                                }
                            }
                            else {
                                dataService.setData({ showAlertMessage: "" });
                            }

                        })
                        .catch((err) => {
                            console.log(err, "Error Message");
                            dataService.setData({ showAlertMessage: "" });
                        });
                } catch (err) {
                    console.log(err, "Error Message - Custom");
                    dataService.setData({ showAlertMessage: "Something went wrong with member data." });
                    isClicked = null;
                }

            } else {
            }
        }
    });
}

export async function getConvAttributesSF(data, azureID, crmMemberFields, teamId) {


    return new Promise((resolve) => {

        console.log("startCustomConversation-cifutil-start - IN SF" + data);

        console.log("startCustomConversation- cifutil - executing" + data);
        var contactFields = ["createddate", "name", "email", "MailingState", "csg__TimeZone__c"];
        var acctFields = [];        //List of fields to be retrieved from Account object
        var oppFields = ["AccountId"];    //List of fields to be retrieved from Opportunity object

        let ObjData = JSON.parse(data);
        var recordId = ObjData.recordId;

        //Extract context/object specific fields mentioned in tokens
        crmMemberFields.forEach((val, key) => {
            if (val.includes(".")) {
                //Add tokens related to account object to list
                if (val.split(".")[0].toLowerCase() == 'saccount' && val.split(".")[1].toLowerCase() != 'name' && val.split(".")[1].toLowerCase() != 'email') {
                    acctFields.push(val.substring(val.indexOf(".")+1, val.length));
                }
                //Add tokens related to contact object to list
                else if (val.split(".")[0].toLowerCase() == 'scontact' && val.split(".")[1].toLowerCase() != 'name' && val.split(".")[1].toLowerCase() != 'email') {
                    contactFields.push(val.substring(val.indexOf(".")+1, val.length));
                }
                //Add tokens related to opportunity object to list
                else if (val.split(".")[0].toLowerCase() == 'sopportunity') {   //Get token variable for opportunity. val.substring to get token values for related object fields e.g. sopportunity.account.name
                    oppFields.push(val.substring(val.indexOf(".")+1, val.length));
                }
            }
        });

        var contactFieldsString = contactFields.join(',');
        var acctFieldsString = acctFields?.join(',');
        var oppFieldsString = oppFields?.join(',');
        var methodName, methodParams = '';

        //Set Apex Method and Params based on entity type
        if (ObjData.entityLogicalName === "account") {
            methodName = "getAccount";
            methodParams = 'conId=' + recordId + '&fieldNames=' + acctFieldsString;
        }
        else if (ObjData.entityLogicalName === "contact") {
            methodName = "getContact";
            methodParams = 'conId=' + recordId + '&fieldNames=' + contactFieldsString;
        }
        else if (ObjData.entityLogicalName === "opportunity") {
            methodName = "getOpportunity";
            methodParams = 'conId=' + recordId + '&fieldNames=' + oppFieldsString;
        }


        //Invokes API method
        window.sforce.opencti.runApex({
            apexClass: "TextingUtil",
            methodName: methodName,
            methodParams: methodParams,
            callback: function (response) {
                if (response.success == true && response.returnValue.runApex != '') {
                    try {
                        var conResponse = JSON.parse(response.returnValue.runApex);
                        var convAttributes = {};


                        var reqDataObj = {};
                        reqDataObj.conversation = {};

                        //Build conversation attributes with Tokens and respective values
                        crmMemberFields.forEach((val, key) => {
                            if (!convAttributes.hasOwnProperty(key)) {
                                if (val.includes(".")) {
                                    convAttributes[key] = getConvAttrValue(conResponse, val);
                                }
                            }
                        });

                        reqDataObj.conversation.source = 'CRM';
                        reqDataObj.conversation.agentId = azureID;
                        reqDataObj.conversation.customerPhoneNumber = ObjData.number;
                        reqDataObj.conversation.externalId = recordId;
                        reqDataObj.conversation.externalType = "Contact"
                        reqDataObj.contact = {};
                        var customerName;
                        reqDataObj.contact.stateCode = conResponse.MailingState;
                        reqDataObj.contact.timezone = conResponse.csg__TimeZone__c;
                        reqDataObj.contact.email = conResponse.Email;

                        if (ObjData.entityLogicalName === "opportunity") {
                            //Get customer name from account related to opportunity
                            customerName = conResponse['Account']['Name']?.split(" ");
                            reqDataObj.contact.contactAttributes = "{ \"ExternalId\": \"" + recordId + "\",\"ContactId\": \"" + conResponse['AccountId'] + "\"}";
                            convAttributes.parentcontactid = conResponse['Account']['PersonContactId'];
                        }
                        else {
                            customerName = ObjData.recordName.split(" ");
                            reqDataObj.contact.contactAttributes = "{ \"ExternalId\": \"" + recordId + "\",\"ContactId\": \"" + recordId + "\"}";
                            convAttributes.parentcontactid = ObjData.recordId;
                        }

                        convAttributes.wcu_externalid = recordId;
                        convAttributes.ActivityId = "";
                        convAttributes.EntityLogicalName = ObjData.entityLogicalName;
                        convAttributes.EntityKeyfield = "id";
                        convAttributes.EntityId = recordId;
                        convAttributes.PhoneNumber = ObjData.number;
                        if (customerName.length > 1) {
                            reqDataObj.contact.firstName = customerName[0];
                            reqDataObj.contact.lastName = customerName[1];
                        }
                        else {
                            reqDataObj.contact.firstName = ObjData.recordName;
                        }
                        reqDataObj.teamId = teamId;

                        reqDataObj.conversation.ConversationAttributes = JSON.stringify(convAttributes);
                        resolve(reqDataObj);
                    } catch (err) {
                        console.log(err, "Error Message - Custom");
                        dataService.setData({ showAlertMessage: "Something went wrong with member data." });
                        isClicked = null;
                    }

                } else {
                }
            }
        });
    })

}


//Return field value from salesforce.
//conResponse: Response from Salesforce
//val: Value to be retrieved from Salesforce response. Value can contain related object fields delimited by '.'
//     e.g. Account.Name, Account.PersonContact.Name
export function getConvAttrValue(conResponse, val) {
    var convAttribute;
    if (val.split(".").length === 2) {
        convAttribute = conResponse[val.split(".")[1]];
    }
    else if (val.split(".").length === 3 && conResponse[val.split(".")[1]] != null) {
        convAttribute = conResponse[val.split(".")[1]][val.split(".")[2]];
    }
    else if (val.split(".").length === 4 && conResponse[val.split(".")[1]] != null && conResponse[val.split(".")[1]][val.split(".")[2]] != null) {
        convAttribute = conResponse[val.split(".")[1]][val.split(".")[2]][val.split(".")[3]];
    }
    return convAttribute;
}

export async function startConvWithTemplatesSF(reqDataObj) {
    var reqData = JSON.stringify(reqDataObj);
    let url = apiConfig.START_CONVERSATION;
    InvokePostServiceCall(url, reqData)
        .then((resp) => {
            var response = resp.data;
            if (response != null && response.status === "SUCCESS") {
                console.log("Conversation Created succesfully" + JSON.stringify(response));
                dataService.setData({ showAlertMessage: response.status });
            }
            else if (response != null && response.status === 'FAILED' && response.errorDescription != null) {
                if (response.isActiveinCommList == true || response.errorDescription.includes("Customer is already in active conversation")||response.errorDescription.includes("Conversation is in unassigned queue")) {
                    var selfAssignConvObj = new Object();
                    selfAssignConvObj.conversationId = response.conversationId;
                    selfAssignConvObj.alertText = response.errorDescription;
                    selfAssignConvObj.teamId = response.teamId;
                    dataService.setData({ showSelfAssignAlert: selfAssignConvObj });
                }
                else {
                    dataService.setData({ showAlertMessage: response.errorDescription });
                }
            }
            else {
                dataService.setData({ showAlertMessage: "" });
            }

        })
        .catch((err) => {
            console.log(err, "Error Message");
            dataService.setData({ showAlertMessage: "" });
        });

}

export async function searchContactandProspectsSF(searchText) {

    return new Promise((resolve) => {
        if (searchText != null && searchText != '' && searchText != ' ') {
            try {
                var searchItem = searchText;
                if (searchItem && searchItem.indexOf('1') === 0) {
                    searchItem = searchItem.substring(1);
                }
                var searchResults = new Object();
                var parms = 'searchText=' + searchItem;

                searchResults.contactResult = [];
                searchResults.prospectResult = [];
                searchResults.oppResults = [];
                window.sforce.opencti.runApex({
                    apexClass: "TextingUtil",
                    methodName: "searchContacts",
                    methodParams: parms,
                    callback: function (response) {
                        if (response.success == true && response.returnValue.runApex != '') {
                            var searchResponse = JSON.parse(response.returnValue.runApex);
                            searchResponse.forEach((entity, index) => {
                                entity.forEach((val, key) => {
                                    if (val.attributes?.type === 'Contact') {
                                        var con = new Object();
                                        con.fullname = val.Name;
                                        con.contactid = val.Id;
                                        con.mobilephone = val.Phone;
                                        con.telephone2 = val.MobilePhone;
                                        con.telephone1 = val.OtherPhone;
                                        searchResults.contactResult.push(con);
                                    }
                                    else if (val.attributes?.type === 'Opportunity') {
                                        var opp = new Object();
                                        opp.name = val.Name;
                                        opp.opportunityid = val.Id;
                                        searchResults.oppResults.push(opp);
                                    }
                                });
                            });
                           
                        }
                        resolve(searchResults);

                    }
                });


            }
            catch (err) {
                console.log(err, "Error Message - Custom");
            }
        }
    });


        /*  var formattedMobileNumber = '';
         var mobile = searchText.replaceAll(/[^a-zA-Z0-9]/g, '');
          if (mobile && mobile.indexOf('1') === 0) {
              mobile = mobile.substring(1);
          }
          formattedMobileNumber = '%25' + mobile.replace(/.{1}/g, '$&%25');
          var searchResults = new Object();
          let contactFilter = "?$select=mobilephone,wcu_emailaddress1,telephone1,
          telephone2,contactid,firstname,lastname, fullname, wcu_mobilephone &$filter=contains( mobilephone,'" + formattedMobileNumber + "') or contains( telephone1,'" + formattedMobileNumber + "') or contains( telephone2,'" + formattedMobileNumber + "') or  wcu_emailaddress1 eq '" + searchText + "' or wcu_telephone1 eq '" + searchText + "' or  firstname eq '" + searchText + "' or lastname eq '" + searchText + "' or fullname eq '" + searchText + "'";
          searchResults.contactResult = await window.Microsoft.CIFramework.searchAndOpenRecords("contact", contactFilter, true);
          let prospectFilter = "?$select=emailaddress1,leadid,firstname,lastname,fullname,telephone1,telephone2,telephone3,mobilephone &$filter=contains( mobilephone,'" + formattedMobileNumber + "') or contains( telephone1,'" + formattedMobileNumber + "') or contains( telephone2,'" + formattedMobileNumber + "') or  firstname eq '" + searchText + "' or lastname eq '" + searchText + "' or fullname eq '" + searchText + "'";
          searchResults.prospectResult = await window.Microsoft.CIFramework.searchAndOpenRecords("lead", prospectFilter, true); */


    

}







export async function updateActivitySF(payload) {
    try {
        var data = {};
        var partyList = [];
        var jsonData = '';
        var convObj = JSON.parse(payload.conv.conversationAttributes);
        var params ='';
        //for update linked entity in SF
        if (payload.entityType==="Contact") {
            params = 'contactId=' + payload.entityId + '&activityId=' + convObj.ActivityId + '&whatId=';
        }
        else if (payload.entityType === "Opportunity") {
            params = 'contactId=&whatId=' + payload.entityId + '&activityId=' + convObj.ActivityId;
        } 
        convObj.EntityId = payload.entityId;
        convObj.EntityLogicalName = payload.entityType.toLowerCase();
        var conversation = Object.assign({}, payload.conv);
        conversation.conversationAttributes = JSON.stringify(convObj);
        //dataService.setData({ updateConversation: conversation });
        return new Promise((resolve) => {
            window.sforce.opencti.runApex({
                apexClass: "TextingUtil",
                methodName: "updateTaskWithActivity",
                methodParams: params,
                callback: function (response) {
                    if (response.success == true && response.returnValue.runApex != '') {
                       
                        openRecordinCRMSF(conversation, payload.crmMemberFields, false);
                        resolve("");
                    } else {
                    }
                }
            });
        });


       
       
    }
    catch (exce) {
        console.log("exception" + exce);
    }

}
export async function closeActivityinCRMSF(conv) {
    try {
        if (conv.conversationAttributes != null && conv.conversationAttributes !== '') {
            var convObj = JSON.parse(conv.conversationAttributes);
            if (convObj.ActivityId != null && convObj.ActivityId !== '') {
                window.sforce.opencti.saveLog({
                    value: {
                        entityApiName: "task",
                        Id: convObj.ActivityId,
                        Status : 'completed'
                    },
                    callback: function (response) {
                        if (response.success == true && response.returnValue != null) {
                            console.log("Update succesful");
                        }
                    }
                });
            }
        }
    }
    catch (err) {
        console.log(err, "Error Message");
    }

}

///Method to open communication panel in Salesforce
export async function openCtiWindow() {
    //added check for opencti object to avoid error
    if (window.sforce != null && window.sforce.opencti != null) {
        window.sforce.opencti.isSoftphonePanelVisible({
            visible: true, callback: function (res) {
                if (res.success == true && res.returnValue.visible == false) {
                    window.sforce.opencti.setSoftphonePanelVisibility({ visible: true, callback: function (res) { } });
                }
            }
        });
    }
}

export async function createOrSearchTaskinSF(conv, crmMemberFields, createTask, activityDueDataHours, context, userTeams) {
    try {
        debugger;
        console.log("createOrSearchTaskinCRMD-context" + context);
        console.log("createOrSearchTaskinCRMD-createTask" + createTask);

        if (window.sforce != null) {
         
                console.log("createOrSearchTaskinCRMD-Inside Create Task");
                var data = {};
                var reqDataObj = {};
                var convObj = {};
                var searchActivityinCRM = false;
            console.log("createOrSearchTaskinCRMD-CIF");
            if (conv.conversationAttributes != null && conv.conversationAttributes !== '' && conv.conversationAttributes != undefined) {
                convObj = JSON.parse(conv.conversationAttributes);
            }
           
                var convAttributes = {};
                convAttributes.conversationId = conv.conversationId;
                convAttributes.userId = conv.agentId != null ? conv.agentId.toLowerCase() : "";
                convAttributes.agentName = conv.agentName;
                convAttributes.wcu_firstname = conv.wcu_firstname;
                convAttributes.EntityLogicalName = 'Task';
               
                
            
                var result = "";
                var activityId = "";
            try {
                let taskname = 'Conversation with ' + conv.customerName;
                var teamAttributesobj = userTeams.filter(t => t.teamId === conv.teamId)?.[0]?.teamAttributes;
                var crmTeamId = teamAttributesobj ? JSON.parse(teamAttributesobj)?.CRMTeamId : null;
                var tskPayLoad = new Object();

                var conResult = {};

                if (!!conv.authId) {

                  conResult =   await getContactsByEXTID(conv.authId);
                }

                tskPayLoad = {
                    entityApiName: "task",
                    Subject: taskname,
                    csg__wcu_externalsystem__c: "Texting",
                    csg__wcu_externalid__c: conv.conversationId,
                    csg__wcu_teamname__c: String(crmTeamId),
                    csg__wcu_to__c: conv.customerPhoneNumber,
                    csg__wcu_messagetype__c: conv.source.toLowerCase(),
                    
                    status: 'In Progress'
                }

                if (!!conResult) {
                    debugger;
                    tskPayLoad.WhoId = conResult.Id;
                    window.sforce.opencti.screenPop({
                        type: window.sforce.opencti.SCREENPOP_TYPE.SOBJECT, //Review the arguments section.
                        params: { recordId: conResult.Id } //Depends on the SCREENPOP_TYPE. Review the arguments section.
                    })
                }
                if (conv.source.toLowerCase() === "inbound" && conv.channel == 'SMS') {
                    console.log("createOrSearchTaskinCRM-source Inbound");
                    var mobile = conv.customerPhoneNumber.replaceAll(" ", "").slice(-10);
                    // Serach for the existing contact in crm
                    if (!tskPayLoad.WhoId) {
                        searchAndScreenPop(mobile);
                    }
                    convObj.EntityId = "";
                    convObj.EntityLogicalName = 'Task';
                }
                else if (conv.channel == 'Chat') {
                    convObj.EntityId = "";
                    convObj.EntityLogicalName = 'Task';
                    if (!tskPayLoad.WhoId) {
                        searchAndScreenPop(conv.email);
                    }
                    else {
                        convObj.EntityId = tskPayLoad.WhoId;
                        convObj.EntityLogicalName = 'contact';
                    }

                }
                else if (convObj != null && convObj !== {}) {
                    console.log("createOrSearchTaskinCRM-convObj " + convObj);
                    //dataService.setData({ addConversation: conv });
                    if (convObj.EntityLogicalName != null && convObj.EntityLogicalName === 'opportunity') {
                        if (convObj.parentcontactid != null && convObj.parentcontactid !== '') {
                            tskPayLoad.WhoId = convObj.parentcontactid;
                        }
                        tskPayLoad.WhatId = convObj.EntityId;
                    }
                    else if (convObj.EntityLogicalName != null && convObj.EntityLogicalName === 'contact') {
                        tskPayLoad.WhoId = convObj.EntityId;
                    }
                }
                const params = `payload=${JSON.stringify(tskPayLoad)}`;
                return new Promise((resolve, reject) => {
                    window.sforce.opencti.runApex({
                        apexClass: "TextingUtil",
                        methodName: "upsertTask",
                        methodParams: params,
                        callback: function (response) {
                            if (response.success == true && response.returnValue != null) {
                                activityId = response.returnValue.runApex;
                                convObj.ActivityId = activityId;
                                reqDataObj.conversation = {};
                                reqDataObj.conversation.conversationId = conv.conversationId;
                                reqDataObj.conversation.ConversationAttributes = JSON.stringify(convObj);
                                var conversation = Object.assign({}, conv);
                                conversation.conversationAttributes = reqDataObj.conversation.ConversationAttributes;
                                dataService.setData({ updateConversation: conversation });
                                resolve(conversation);
                                console.log("createOrSearchTaskinCRMD-updateConversation" + result);
                                updateConversation(reqDataObj); //DB
                            }
                            else {
                                resolve("");
                            }
                        }
                    });
                });
            }
                catch (err) {
                console.error("Error Message :: ", err);
                }
                finally {
                    console.log("createOrSearchTaskinCRMD-Create SMS Task " + result);

                   
                }
            }
        }
    
    catch (err) {
        console.log(err, "Error Message");
    }


}

export function updateConversation(reqDataObj) {
    let url = apiConfig.UPDATE_CONVERSATION;
    InvokePostServiceCall(url, JSON.stringify(reqDataObj))
        .then((resp) => {
            console.log("Conversation updated");
        })
        .catch((err) => {
            console.log(err, "Error Message");
        });

}


export async function getContactsByEXTID(authId) {
    debugger;
    var parms = 'authId=' + authId;
    return new Promise((resolve) => {
        window.sforce.opencti.runApex({
            apexClass: "TextingUtil",
            methodName: "getContactByExternalId",
            methodParams: parms,
            callback: function (response) {
                if (response.success == true && response.returnValue.runApex != '') {
                    var conRes = JSON.parse(response.returnValue.runApex);                    
                    resolve(conRes);
                } else {
                    resolve("");
                }
            }
        });
    });


}

export async function getLinkedEntitiesSF(activityId) {
    var linkedRecords = {};
    linkedRecords.isRecordAvailable = false;
    var parms = 'activityId=' + activityId;


    return new Promise((resolve) => {
        window.sforce.opencti.runApex({
            apexClass: "TextingUtil",
            methodName: "getTask",
            methodParams: parms,
            callback: function (response) {
                if (response.success == true && response.returnValue.runApex != '') {
                    var tskRespone = JSON.parse(response.returnValue.runApex);
                    //If task is related to opportunity return opportunity as linked entity
                    if (tskRespone.WhatId != undefined && tskRespone.WhatId.startsWith('006')) {
                        var opp = new Object();
                        var opps = [];
                        opp.name = tskRespone.What.Name;
                        opp.opportunityid = tskRespone.WhatId;
                        opps.push(opp);
                        linkedRecords.opportunity = JSON.stringify(opps);
                        linkedRecords.isRecordAvailable = true;
                    }
                    else if (tskRespone.WhoId != undefined) {
                        var con = new Object();
                        var contacts = [];
                        con.fullname = tskRespone.Who.Name;
                        con.contactid = tskRespone.WhoId
                        contacts.push(con);
                        linkedRecords.contact = JSON.stringify(contacts);
                        linkedRecords.isRecordAvailable = true;
                    }
                    resolve(linkedRecords);
                } else {
                    resolve("");
                }
            }
        });
    });

   

    /*var filter = '?$select= participationtypemask,_partyid_value,_activityid_value &$orderby= participationtypemask asc &$filter= (participationtypemask eq 2 or participationtypemask eq 8) and  _activityid_value  eq ' + activityId + ' ';
    var result = await window.Microsoft.CIFramework.searchAndOpenRecords("activityparty", filter, true);  // Retrieve activity party records which are "To" and "Regarding"
    var activities = JSON.parse(result);
    for (var i = 0; i < Object.keys(activities).length; i++) {
        if (activities[i]["_partyid_value@Microsoft.D.CRM.lookuplogicalname"] == "contact") {
            let contactFilter = "?$select=wcu_emailaddress1,wcu_telephone1,wcu_telephone2,contactid,firstname,lastname, fullname, wcu_mobilephone &$filter= contactid eq " + activities[i]["_partyid_value"] + "";
            linkedRecords.contact = await window.Microsoft.CIFramework.searchAndOpenRecords("contact", contactFilter, true);
            linkedRecords.isRecordAvailable = true;
        }
        if (activities[i]["_partyid_value@Microsoft.D.CRM.lookuplogicalname"] == "lead") {
            let prospectFilter = "?$select=emailaddress1,leadid,firstname,lastname,fullname,telephone1,telephone2,telephone3 &$filter=leadid  eq " + activities[i]["_partyid_value"] + "";
            linkedRecords.lead = await window.Microsoft.CIFramework.searchAndOpenRecords("lead", prospectFilter, true);
            linkedRecords.isRecordAvailable = true;
        }
        if (activities[i]["_partyid_value@Microsoft.D.CRM.lookuplogicalname"] == "opportunity") {
            let oppFilter = "?$select=name,wcu_externalid,wcu_productname,opportunityid &$filter=opportunityid eq " + activities[i]["_partyid_value"] + "";
            linkedRecords.opportunity = await window.Microsoft.CIFramework.searchAndOpenRecords("opportunity", oppFilter, true);
            linkedRecords.isRecordAvailable = true;
        }
        if (activities[i]["_partyid_value@Microsoft.D.CRM.lookuplogicalname"] == "wcu_finaccount") {
            var properties = ["wcu_finproducttype", "wcu_finproductid", "wcu_accountid", "wcu_name"].join();
            let finAccountFilter = "?$select=" + properties + " &$filter=wcu_finaccountid eq " + activities[i]["_partyid_value"] + "";
            linkedRecords.finaccount = await window.Microsoft.CIFramework.searchAndOpenRecords("wcu_finaccount", finAccountFilter, true);
            linkedRecords.isRecordAvailable = true;
        }

    } */

  


}


export async function getLinkedEntityNamesSF(activityId) {
 
    var linkedEntitiesNames = [];
    var parms = 'activityId=' + activityId;
    return new Promise((resolve) => {
        window.sforce.opencti.runApex({
            apexClass: "TextingUtil",
            methodName: "getTask",
            methodParams: parms,
            callback: function (response) {
                if (response.success == true && response.returnValue.runApex != '') {
                    var tskRespone = JSON.parse(response.returnValue.runApex);
                    //If task is related to opportunity return opportunity as linked entity
                    if (tskRespone.WhatId != undefined && tskRespone.WhatId.startsWith('006')) {
                        linkedEntitiesNames.push("sopportunity");
                    }
                    else if (tskRespone.WhoId != undefined) {
                        linkedEntitiesNames.push("scontact");
                    }
                    linkedEntitiesNames.push("users");
                    linkedEntitiesNames.push("userattributes");
                    linkedEntitiesNames.push("teamattributes");
                    resolve(linkedEntitiesNames);
                } else {
                    resolve("");
                }
            }
        });
    });
}



export async function openRecordinCRMSF(conv, crmMemberFields, openRecord) {
    try {

        

        console.log("openRecordinCRM-Started");
        let filter = '';

        if (conv.conversationAttributes != null && conv.conversationAttributes !== '') {
            var convObj = JSON.parse(conv.conversationAttributes);
            var entityKeyField = convObj.EntityKeyfield;
            var entityId = convObj.EntityId;
            if (entityId === null || entityId == undefined || entityId == "") {
                entityId = convObj.ActivityId;
            }
            var entityLogicalName = "contact";
            if (openRecord == true) {
                window.sforce.opencti.screenPop({
                    type: window.sforce.opencti.SCREENPOP_TYPE.SOBJECT, //Review the arguments section.
                    params: { recordId: entityId } //Depends on the SCREENPOP_TYPE. Review the arguments section.
                });
            }
            updateTokenswithCRMdata(conv, crmMemberFields);
            
        }
        console.log("openRecordinCRM-Ended");
    }
    catch (err) {
        console.log(err, "Error Message");
    }
}


export async function openEnityinCRMSF(entityId, entityLogicalName, entityKeyField){
    window.sforce.opencti.screenPop({
        type: window.sforce.opencti.SCREENPOP_TYPE.SOBJECT, //Review the arguments section.
        params: { recordId: entityId } //Depends on the SCREENPOP_TYPE. Review the arguments section.
    });
}





export const openContextRecordInCRMSF = (conv, crmMemberFields, entity) => (event) => {
    openRecordinCRMSF(conv, crmMemberFields, entity);
}

export async function updateTokenswithCRMdata(conv, crmMemberFields) {
    try {
        let conversation = {};
        let convAttributes = {};
        let contact = new Object();
        var contactFields = ["createddate", "name", "email", "MailingState", "csg__TimeZone__c"];
        var acctFields = [];        //List of fields to be retrieved from Account object
        var oppFields = ["AccountId"];    //List of fields to be retrieved from Opportunity object
        conversation = Object.assign({}, conv);
        convAttributes = JSON.parse(conversation.conversationAttributes);
        //Extract context/object specific fields mentioned in tokens
        crmMemberFields.forEach((val, key) => {
            if (val.includes(".")) {
                //Add tokens related to account object to list
                if (val.split(".")[0].toLowerCase() == 'saccount' && val.split(".")[1].toLowerCase() != 'name' && val.split(".")[1].toLowerCase() != 'email') {
                    acctFields.push(val.substring(val.indexOf(".") + 1, val.length));
                }
                //Add tokens related to contact object to list
                else if (val.split(".")[0].toLowerCase() == 'scontact' && val.split(".")[1].toLowerCase() != 'name' && val.split(".")[1].toLowerCase() != 'email') {
                    contactFields.push(val.substring(val.indexOf(".") + 1, val.length));
                }
                //Add tokens related to opportunity object to list
                else if (val.split(".")[0].toLowerCase() == 'sopportunity') {   //Get token variable for opportunity. val.substring to get token values for related object fields e.g. sopportunity.account.name
                    oppFields.push(val.substring(val.indexOf(".") + 1, val.length));
                }
            }
        });

        //Invokes API method
        var contactFieldsString = contactFields.join(',');
        var acctFieldsString = acctFields?.join(',');
        var oppFieldsString = oppFields?.join(',');
        var methodName, methodParams = '';

        //Set Apex Method and Params based on entity type
        if (convAttributes.EntityLogicalName === "account") {
            methodName = "getAccount";
            methodParams = 'conId=' + convAttributes.EntityId + '&fieldNames=' + acctFieldsString;
        }
        else if (convAttributes.EntityLogicalName === "contact") {
            methodName = "getContact";
            methodParams = 'conId=' + convAttributes.EntityId + '&fieldNames=' + contactFieldsString;
        }
        else if (convAttributes.EntityLogicalName === "opportunity") {
            methodName = "getOpportunity";
            methodParams = 'conId=' + convAttributes.EntityId + '&fieldNames=' + oppFieldsString;
        }
        window.sforce.opencti.runApex({
            apexClass: "TextingUtil",
            methodName: methodName,
            methodParams: methodParams,
            callback: function (response) {
                if (response.success == true && response.returnValue.runApex != '') {
                    try {
                        var conResponse = JSON.parse(response.returnValue.runApex);


                        var reqDataObj = {};
                        reqDataObj.conversation = {};

                        crmMemberFields.forEach((val, key) => {
                            if (!convAttributes.hasOwnProperty(key)) {
                                if (val.includes(".")) {
                                    convAttributes[key] = getConvAttrValue(conResponse, val);
                                }
                            }
                        });
                        conversation.requiredConvAttrUpdate = false;
                        conversation.conversationAttributes = JSON.stringify(convAttributes);
                        conversation.linkedEntities = "";
                        conversation.customerName = conResponse.Name ? conResponse.Name : conversation.customerName;
                        convAttributes.EntityKeyfield = convAttributes.EntityLogicalName + "id";
                        if (convAttributes.EntityLogicalName === "opportunity") {
                            //Get customer name from account related to opportunity
                            conversation.customerName = conResponse['Account']['Name'];
                            conversation.externalId = convAttributes.EntityId;
                            conversation.externalType = "Opportunity"
                            contact.contactAttributes = "{ \"ExternalId\": \"" + convAttributes.EntityId + "\",\"ContactId\": \"" + conResponse['AccountId'] + "\"}";
                            convAttributes.parentcontactid = conResponse['Account']['PersonContactId'];
                        }
                        else {
                            contact.contactAttributes = "{ \"ExternalId\": \"" + convAttributes.EntityId + "\",\"ContactId\": \"" + convAttributes.EntityId + "\"}";
                            conversation.externalId = convAttributes.EntityId;
                            conversation.externalType = "Contact"
                            convAttributes.parentcontactid = convAttributes.EntityId;
                        }
                        dataService.setData({ updateContextConv: conversation });           // Updates the conversation customerName on the client side.
                        var reqDataObj = {};
                        reqDataObj.conversation = conversation;
                        reqDataObj.contact = contact;
                        reqDataObj.contact.email = conResponse.Email;
                        reqDataObj.contact.stateCode = conResponse.MailingState;
                        reqDataObj.contact.timezone = conResponse.csg__TimeZone__c;
                        var customerName = conversation.customerName.split(" ");
                        if (customerName.length > 1) {
                            reqDataObj.contact.firstName = customerName[0];
                            reqDataObj.contact.lastName = customerName[1];
                        }
                        else {
                            reqDataObj.contact.firstName = conversation.customerName;
                        }
                        updateConversation(reqDataObj);
                    }
                    catch (Ex) {

                    }
                }
            }
        });
       
    }
    catch (exception) {
        console.log("exception" + exception)
    }
    // }
}
