//// 11-16-2022 => ONKAR => Texting-788 => Team name added in chip for selected team in filter.
//// 11-18-2022 => ONKAR => Texting-788 => resetting the table page number to 0 after search or clear filter.
///* 22/11/2022   Texting:827- no rectords found after no data
////04-08-2023 => SUDAM B CHAVAN => reset page index to 1 page on filter data
// 08-29-2023 => ONKAR => TEXTING-1578 -REACT Upgrade 
// 08-30-2023 => ONKAR => TEXTING-1578 -REACT Upgrade 
import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import { Button, IconButton,InputAdornment } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import apiConfig from "../../api/apiConfig";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../AuthContext";
import { useTheme } from "@mui/material/styles";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import moment from "moment";
import FilterComponent from "../../components/FilterComponent";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Chip from "@mui/material/Chip";
import { TextField } from "@mui/material";
import SnackBar from "../../components/SnackBar"
import { Checkbox } from "@mui/material";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import HistoryIcon from '@mui/icons-material/History';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ObjectHistoryComp from "../teams/ObjectHistoryComp";
import { StyledDataGrid } from "../../components/styledComponents/StyledDataGrid";

const UsersPage = () => {
    const { messageApiToken, usersFilterData, setUsersFilterData, loadUserFilter, userInfo } = useContext(AuthContext);
    const navigate = useNavigate();
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const [userData1, setUserData1] = useState([]);
    const [userArr, setUserArr] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const [filterChip, setFilterChip] = useState([]);
    const [deletedChip, setDeletedChip] = useState("");
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [filteredUsersCopy, setFilteredUsersCopy] = useState([]);
    const [filterApplied, setFilterApplied] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [filterLoading, setFilterLoading] = useState(false);
    //const userArr = [];
    const [currentpage, setCurrentPage] = useState(0);
    const [filterTeamName, setFilterTeamName] = useState("");
    const [page, setPage] = useState(0);
    const [openTemplateHistory, setOpenTemplateHistory] = React.useState(false);
    const [userDetails, setUserDetails] = React.useState([]);
    const employeeColumns = [
        {
            field: "firstName",
            headerName: "First Name",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "lastName",
            headerName: "Last Name",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "emailId",
            headerName: "Email",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "clientFacingPhoneNum",
            headerName: "Client Facing Phone No.",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "department",
            headerName: "Department",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "createdDate",
            headerName: "Created Date",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "lastModifiedDate",
            headerName: "Modified Date",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "isActive",
            headerName: "Is Active",
            flex: 1,
            minWidth: 50,
            filterable: false,

            headerClassName: "super-app-theme--header",
            renderCell: (params) => {
                return (
                    <div style={{ marginLeft: "5px" }}>
                        <Checkbox disabled checked={params.row.isActive} />
                    </div>
                );
            },
        }, {
            field: "history",
            headerName: "-",
            flex: 1,
            minWidth: 50,
            filterable: false,

            headerClassName: "super-app-theme--header",
            renderCell: (params) => {
                return (
                    <div style={{ marginLeft: "5px" }}>
                        <IconButton
                            //sx={theme.customStyle.HistoryIconBtn}
                            onClick={(e) => {
                                setUserDetails(params.row);
                                setOpenTemplateHistory(true);
                            }}
                        >
                            <HistoryIcon />
                        </IconButton>
                    </div>
                );
            }
        }
    ];
    const handleClose = () => {
        setOpenTemplateHistory(false);
    };


    function fetchUsers() {
        let requestBody = {
            isActive: true,
        }
        setUserData1([]);
        setFilteredUsers([])
        InvokePostServiceCall(apiConfig.GET_USERS, requestBody)
            .then((data) => {
                let userArr = [];
                /*setUserData1(data.data.userDetails.map((item, index) => {
                    return {
                        actionType: item.actionType,
                        applicationUserId: item.user.applicationUserId,
                        azureAdid: item.user.azureAdid,
                        connectionId: item.user.connectionId,
                        createdBy: item.user.createdBy,
                        createdByName: item.user.createdByName,
                        isActive: item.user.isActive,
                        createdDate: moment(item.user.createdDate).local().format("MM-DD-YYYY"),
                        emailId: item.user.emailId !== null ? item.user.emailId : "",
                        firstName: item.user.firstName,
                        lastModifiedBy: item.user.lastModifiedBy,
                        lastModifiedByName: item.user.lastModifiedByName,
                        lastModifiedDate: moment(item.user.lastModifiedDate)
                            .local()
                            .format("MM-DD-YYYY"),
                        lastName: item.user.lastName,
                        phoneNumber: item.user.phoneNumber,
                        teams: item.teams,
                        userId: item.user.userId,
                        userName: item.user.userName,
                        id: index,
                    }
                }));  */
                data.data.userDetails.map((item, index) => {
                    userArr.push({
                        actionType: item.actionType,
                        applicationUserId: item.user.applicationUserId,
                        azureAdid: item.user.azureAdid,
                        connectionId: item.user.connectionId,
                        createdBy: item.user.createdBy,
                        createdByName: item.user.createdByName,
                        isActive: item.user.isActive,
                        createdDate: moment(item.user.createdDate).local().format("MM-DD-YYYY"),
                        emailId: item.user.emailId !== null ? item.user.emailId : "",
                        firstName: item.user.firstName,
                        lastModifiedBy: item.user.lastModifiedBy,
                        lastModifiedByName: item.user.lastModifiedByName,
                        lastModifiedDate: moment(item.user.lastModifiedDate)
                            .local()
                            .format("MM-DD-YYYY"),
                        lastName: item.user.lastName,
                        phoneNumber: item.user.phoneNumber,
                        department: item.user.department ==="" ? "None": item.user.department,
                        teams: item.teams,
                        userId: item.user.userId,
                        userName: item.user.userName,
                        id: index,
                        clientFacingPhoneNum: item.user.clientFacingPhoneNum,
                    });
                })
                setUserData1(userArr);
                setUserArr(userArr);
                setIsLoading(false);
                console.log(data, "@@@@@@@@users");
            })
            .catch((err) => {
                console.log(err, "err");
                setSnackbarMsg(err.message)
                setOpenSnackbar(true);
                setIsLoading(false);
            });
    }
    useEffect(() => {
        if (messageApiToken && !loadUserFilter) {
            setIsLoading(true);
            fetchUsers();
        }
    }, [messageApiToken, loadUserFilter]);

    // if (userData1.length > 0) {
    //     userData1.forEach((item, index) => {
    //         userArr.push({
    //             actionType: item.actionType,
    //             applicationUserId: item.user.applicationUserId,
    //             azureAdid: item.user.azureAdid,
    //             connectionId: item.user.connectionId,
    //             createdBy: item.user.createdBy,
    //             createdByName: item.user.createdByName,
    //             isActive: item.user.isActive,
    //             createdDate: moment(item.user.createdDate).local().format("MM-DD-YYYY"),
    //             emailId: item.user.emailId !== null ? item.user.emailId : "",
    //             firstName: item.user.firstName,
    //             lastModifiedBy: item.user.lastModifiedBy,
    //             lastModifiedByName: item.user.lastModifiedByName,
    //             lastModifiedDate: moment(item.user.lastModifiedDate)
    //                 .local()
    //                 .format("MM-DD-YYYY"),
    //             lastName: item.user.lastName,
    //             phoneNumber: item.user.phoneNumber,
    //             teams: item.teams,
    //             userId: item.user.userId,
    //             userName: item.user.userName,
    //             id: index,
    //         });
    //     });
    // }

    function handleFilter() {
        setShowFilter(!showFilter);
    }
    const handleDelete = (chipToDelete) => () => {
        setFilterChip((chips) => chips.filter((chip) => chip !== chipToDelete));
        setDeletedChip(chipToDelete);
    };
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };
    function filterTable(value) {
        let usArr = [];
        setPage(0)
        let val = value?.toLowerCase();
        if (filterApplied && filterChip.length > 0) {
            filteredUsersCopy.forEach((item) => {

                if (
                    item.firstName?.toLowerCase().includes(val) ||
                    item.lastName?.toLowerCase().includes(val) ||
                    item.emailId?.toLowerCase().includes(val) ||
                    item.clientFacingPhoneNum?.toLowerCase().includes(val) ||
                    //item.department?.toLowerCase().includes(val) ||
                    item.createdDate?.toLowerCase().includes(val) ||
                    item.lastModifiedDate?.toLowerCase().includes(val)
                ) {
                    usArr.push(item);
                }
            });
            setFilteredUsers(usArr);

        } else {
            userArr.forEach((item) => {
                if (
                    item.firstName?.toLowerCase().includes(val) ||
                    item.lastName?.toLowerCase().includes(val) ||
                    item.emailId?.toLowerCase().includes(val) ||
                    item.clientFacingPhoneNum?.toLowerCase().includes(val) ||
                    item.department?.toLowerCase().includes(val) ||
                    item.createdDate?.toLowerCase().includes(val) ||
                    item.lastModifiedDate?.toLowerCase().includes(val)
                ) {
                    usArr.push(item);
                }

            });
            setUserData1(usArr);
        }
    }
    return (
        <>
            <SnackBar
                openSnackbar={openSnackbar}
                handleCloseSnackbar={handleCloseSnackbar}
                severity={"error"}
                userMessage={snackbarMsg}
            />
            <div>
                <Box sx={theme.customStyle.tableRow}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <h2 style={{ marginLeft: 10 }}>Users</h2>
                        <IconButton
                            sx={{ marginLeft: "5px" }}
                            onClick={() => {
                                handleFilter();
                            }}
                        >
                            <FilterAltIcon />
                        </IconButton>
                        {filterChip.map((item) => {
                            return (
                                <div style={{ marginLeft: "5px" }}>
                                    <Chip label={item === "Team" ? "Team: " + filterTeamName : item} onDelete={handleDelete(item)} />
                                </div>
                            );
                        })}
                    </div>
                </Box>
            </div>
            <FilterComponent
                usersPage={true}
                showFilter={showFilter}
                setShowFilter={setShowFilter}
                filterChip={filterChip}
                setFilterChip={setFilterChip}
                deletedChip={deletedChip}
                setDeletedChip={setDeletedChip}
                url={apiConfig.GET_USERS_BY_FILTER}
                currentPage={"Users"}
                setFilteredUsers={setFilteredUsers}
                setFilteredUsersCopy={setFilteredUsersCopy}
                setFilterApplied={setFilterApplied}
                setIsLoading={setFilterLoading}
                isLoading={filterLoading}
                filterData={usersFilterData}
                setFilterData={setUsersFilterData}
                setCurrentPage={setCurrentPage}
                filterTeamName={filterTeamName}
                setFilterTeamName={setFilterTeamName}
                setPage={setPage}
            />
            <div style={{ display: "flex", alignItems: "flex-start",paddingLeft:10 }}>
                <Box sx={theme.customStyle.tableRow}>
                    <Button
                        sx={theme.customStyle.modalbtn}
                        onClick={() => {
                            navigate("/addUpdateUser", { state: { isAddUser: true } });
                        }}
                    >
                        <AddIcon />
                </Button>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <TextField
                        sx={{ marginRight: "10px", marginBottom: "10px" }}
                        size="small"
                        type="search"
                        variant="outlined"
                        margin="normal"
                        id="outlined-search"
                        label=""
                        placeholder="Users Search"
                        autoComplete="off"
                        onChange={(e) => {
                            if (e.target.value) {
                                filterTable(e.target.value);
                            } else {
                                if (filterApplied && filterChip.length > 0) {
                                    setFilteredUsers(filteredUsersCopy)
                                } else {
                                    setUserData1(userArr);
                                }
                            }
                        }}
                        InputLabelProps={{
                            shrink: false,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                    </Box>
                </Box>
               </div>
            <Box sx={theme.customStyle.tableHead}>
                <StyledDataGrid
                    disableSelectionOnClick
                    loading={isLoading || filterLoading}
                    page={page}
                    onPageChange={(Page) => setPage(Page)}
                    components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                    }}
                    onRowDoubleClick={(e) => {
                        navigate("/addUpdateUser/" + e.row.userId, {
                            state: { isAddUser: false, userDetails: e.row, isProfile: false },
                        });
                    }}
                    rows={filterLoading ? [] : filterApplied && filterChip.length > 0 ? filteredUsers : userData1}
                    columns={employeeColumns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    columnVisibilityModel={{ history: false }} //hide the columns from the grid
                    // initialState={{
                    //     sorting: {
                    //         sortModel: [{ field: 'lastModifiedDate', sort: 'desc' }],
                    //     },
                    // }}
                />
            </Box>
            <Dialog open={openTemplateHistory} onClose={handleClose} fullWidth maxWidth={"lg"}>
                <DialogContent>
                    <ObjectHistoryComp
                        open={true}
                        handleClose={handleClose}
                        objectName="Users"
                        objectKeyname={userDetails}
                        numOfRows={10}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default UsersPage;