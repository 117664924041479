// 06-27-2023 => ONKAR => Texting-1378 TCPA UI Changes
// 06-30-2023 => ONKAR => Texting-1391 TCPA UI observations
// 07-03-2023 =>ONKAR=> Texting-1398 TCPA UI Pending observations.
// 07-04-2023 =>ONKAR=> Texting-1398 TCPA UI Pending observations.
// 07-19-2023 =>ONKAR=> Texting-1440 Also keep new column StateCode .. 2 letter abbreviation..
// 08-04-2023 => SUDAM B CHAVAN => reset page index to 1 page on filter data
// 08-24-2023 => PRABHAT => Texting-1616 TCPA UI table search is not showing results with State code.
// 08-29-2023 => ONKAR => TEXTING-1578 -REACT Upgrade 
// 08-30-2023 => ONKAR => TEXTING-1578 -REACT Upgrade 
import React, { useState, useEffect, useContext } from "react";
import { Box, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { AuthContext } from "../../AuthContext";
import { useTheme } from "@mui/material/styles";
import {
  InvokePostServiceCall,
  InvokeGetServiceCall,
} from "../../api/serviceUtil";
import moment from "moment";
import SnackBar from "../../components/SnackBar";
import apiConfig from "../../api/apiConfig";
import { TextField,InputAdornment } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import LoadingButton from "@mui/lab/LoadingButton";
import { StyledDataGrid } from "../../components/styledComponents/StyledDataGrid";




function TCPAPage() {
  const theme = useTheme();
  const { messageApiToken } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [TCPAList, setTCPAList] = useState([]);
  const [TCPAListCopy, setTCPAListCopy] = useState([]);
  const [disableSaveBtn, setDisableSaveBtn] = useState(true);
  const [severity, setSeverity] = useState("success");
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [tcpaErrors, setTcpaErrors] = useState([]);
  const [page, setPage] = useState(0);
  

  const columns = [
    {
      field: "state",
      headerName: "State",
      flex: 1,
      minWidth: 140,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "stateCode",
      headerName: "State Code",
      flex: 1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "monStartTime",
      headerName: "Mon Start",
      flex: 1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
      editable: true,
    },
    {
      field: "monEndTime",
      headerName: "Mon End",
      flex: 1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
      editable: true,
    },
    {
      field: "tueStartTime",
      headerName: "Tue Start",
      flex: 1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
      editable: true,
    },
    {
      field: "tueEndTime",
      headerName: "Tue End",
      flex: 1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
      editable: true,
    },
    {
      field: "wedStartTime",
      headerName: "Wed Start",
      flex: 1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
      editable: true,
    },
    {
      field: "wedEndTime",
      headerName: "Wed End",
      flex: 1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
      editable: true,
    },
    {
      field: "thuStartTime",
      headerName: "Thu Start",
      flex: 1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
      editable: true,
    },
    {
      field: "thuEndTime",
      headerName: "Thu End",
      flex: 1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
      editable: true,
    },
    {
      field: "friStartTime",
      headerName: "Fri Start",
      flex: 1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
      editable: true,
    },
    {
      field: "friEndTime",
      headerName: "Fri End",
      flex: 1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
      editable: true,
    },
    {
      field: "satStartTime",
      headerName: "Sat Start",
      flex: 1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
      editable: true,
    },
    {
      field: "satEndTime",
      headerName: "Sat End",
      flex: 1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
      editable: true,
    },
    {
      field: "sunStartTime",
      headerName: "Sun Start",
      flex: 1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
      editable: true,
    },

    {
      field: "sunEndTime",
      headerName: "Sun End",
      flex: 1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
      editable: true,
    },
  ];

  function getTCPA() {
    //get all tcpa records
    InvokeGetServiceCall(apiConfig.GET_TCPA)
      .then((data) => {
        console.log(data, "TCPADAT");
        let tcpaArr = [];
        data.data.tcpaList.forEach((item, index) => {
          item.id = index;
          tcpaArr.push(item);
        });
        setTCPAList(tcpaArr);
        setTCPAListCopy(tcpaArr);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error, "Error Message");
        setSeverity("error");
        setSnackbarMsg(error.message);
        setOpenSnackbar(true);
        setLoading(false);
      });
  }

  function saveTCPA() {
    // save updated tcpa records
    setBtnLoading(true);
    let tcpaArr = [];
    TCPAList.forEach((item) => {
      let tcpaItem = { ...item };
      delete tcpaItem.id;
      tcpaArr.push(tcpaItem);
    });
    InvokePostServiceCall(apiConfig.UPDATE_TCPA, { tcpaList: tcpaArr })
      .then((data) => {
        setSeverity("success");
        setSnackbarMsg("TCPA Changes Updated Successfully.");
        setOpenSnackbar(true);
        setBtnLoading(false);
        setDisableSaveBtn(true);
      })
      .catch((error) => {
        console.log(error, "Error Message");
        setSeverity("error");
        setSnackbarMsg(error.message);
        setOpenSnackbar(true);
        setBtnLoading(false);
      });
  }

  function filterTable(value) {
    let filteredArr = [];
    let val = value?.toLowerCase();
    setPage(0);
    TCPAList.forEach((item) => {
      if (item.state?.toLowerCase().includes(val) || item.stateCode?.toLowerCase().includes(val)) {
        filteredArr.push(item);
      }
    });
    setTCPAListCopy(filteredArr);
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  useEffect(() => {
    if (messageApiToken) {
      getTCPA();
    }
  }, [messageApiToken]);
  return (
    <>
      <SnackBar
        openSnackbar={openSnackbar}
        handleCloseSnackbar={handleCloseSnackbar}
        severity={severity}
        userMessage={snackbarMsg}
      />
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <h2>TCPA</h2>

        <Box
          sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          <TextField
            sx={{ marginRight: "10px", marginBottom: "10px" }}
            size="small"
            type="search"
            variant="outlined"
            margin="normal"
            id="outlined-search"
            label=""
            placeholder="State Search"
            autoComplete="off"
            onChange={(e) => {
              if (e.target.value) {
                filterTable(e.target.value);
              } else {
                setTCPAListCopy(TCPAList);
              }
            }}
            InputLabelProps={{
              shrink: false,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box
          sx={{
            ...theme.customStyle.tableHead,
            "& .error": {
                color: "red",
                fontWeight: 600,
            },
          }}
        >
          <StyledDataGrid
            loading={loading}
            rows={TCPAListCopy}
            columns={columns}
            pageSize={10}
            page={page}
            onPageChange={(page) => setPage(page)}
            rowsPerPageOptions={[10]}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            onCellEditStart={() => {
              setDisableSaveBtn(true);
            }}
            getCellClassName={(params) => {
              if (params.field !== "state" && params.field !== "stateCode") {
                let isValid = moment(params.value, "HH:mm", true).isValid();
                if (isValid && params.value !== "24:00") {
                  let selectedDayName = params.field.replace(
                    params.field.includes("StartTime")
                      ? "StartTime"
                      : "EndTime",
                    ""
                  );
                  let startTime = TCPAList[params.id][
                    selectedDayName + "StartTime"
                  ].replace(":", "");
                  let endTime = TCPAList[params.id][
                    selectedDayName + "EndTime"
                  ].replace(":", "");
                  let timeDiff = parseInt(endTime) - parseInt(startTime);
                  if (
                    timeDiff < 1 &&
                    TCPAList[params.id][selectedDayName + "StartTime"] !==
                      "00:00"
                  ) {
                    return "error";
                  }
                } else {
                  return "error";
                }
              }
            }}
            onCellEditCommit={(params) => {
              let isValid = moment(params.value, "HH:mm", true).isValid();
              if (isValid && params.value !== "24:00") {
                if (params.field.includes("StartTime")) {
                  TCPAList[params.id][params.field] = params.value;
                } else {
                  TCPAList[params.id][params.field] = params.value;
                }
                TCPAList[params.id].isUpdated = true;
                setDisableSaveBtn(false);
                let selectedDayName = params.field.replace(
                  params.field.includes("StartTime") ? "StartTime" : "EndTime",
                  ""
                );
                let startTime = TCPAList[params.id][
                  selectedDayName + "StartTime"
                ].replace(":", "");
                let endTime = TCPAList[params.id][
                  selectedDayName + "EndTime"
                ].replace(":", "");
                let timeDiff = parseInt(endTime) - parseInt(startTime);
                let tcpaErrorArr = [...tcpaErrors];
                const index = tcpaErrorArr.indexOf(params.id);
                if (
                  timeDiff < 1 &&
                  TCPAList[params.id][selectedDayName + "StartTime"] !== "00:00"
                ) {
                  setSeverity("error");
                  setSnackbarMsg("Start time must be less than end time.");
                  setOpenSnackbar(true);
                  setDisableSaveBtn(true);
                  if (index < 0) {
                    setTcpaErrors([...tcpaErrors, params.id]);
                  }
                  return;
                }
                if (index > -1) {
                  tcpaErrorArr.splice(index, 1);
                }
                setTcpaErrors([...tcpaErrorArr]);
              } else {
                let tcpaErrorArr = [...tcpaErrors];
                const index = tcpaErrorArr.indexOf(params.id);
                if (index < 0) {
                  setTcpaErrors([...tcpaErrors, params.id]);
                }
                setSeverity("error");
                setSnackbarMsg("Please enter time in 24 hrs format only.");
                setOpenSnackbar(true);
                setDisableSaveBtn(true);
              }
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "state", sort: "asc" }],
              },
            }}
          />
        </Box>
        <div style={{ marginTop: "20px" }}>
          {/* <Button variant="contained">Cancel</Button> */}
          <LoadingButton
            variant="contained"
            sx={{ marginLeft: "10px" }}
            disabled={disableSaveBtn || tcpaErrors.length > 0}
            loading={btnLoading}
            onClick={saveTCPA}
          >
            Save
          </LoadingButton>
        </div>
      </div>
    </>
  );
}

export default TCPAPage;
