/********************************************************************************************************************************************
Name: CIFUTIL
=============================================================================================================================================
Purpose:
Contains all the CIF methods to connect with D:
History:
VERSION     AUTHOR              DATE            DETAILS
1.0         Rajesh              12/01/2021      Initial creation of CIF component
1.1         Rajesh              11/08/2022      Updated CIF to start conversation from the member profile
////11-01-2022 => Texting-734 IB/OB, Team Name changes  
////11-01-2022 => Texting-734 IB/OB, Team Name changes
////11-03-2022 => Texting-734 IB/OB, Team Name changes reopen bugs fixed.
////11-04-2022 => Texting-734 IB/OB, Team Name changes reopen bugs fixed (changed values to lowercase for comparing).*/
////11-28-2022 => Texting-847 Ability to take over existing conversation assigned to someone else by agent.
////12-01-2022 => ONKAR  => Texting-874 Assign to me option is showing for missed conversations, nonwhite list alert, Consent unavailable alert fixed.
////12/09/2022 => ONKAR => Texting-913 Prompt the Templates to the User before start the conversation
////06/15/2023 => ONKAR => Texting-1347 Red is not disappearing after viewing the customer’s message on the conversation initiated from team that is not opted for start conv with templates and on conv from individual Team.
////02/13/2023 => ONKAR => "New object chat for Chat".
////03-13-2024 =>  ONKAR => On accepting inbound conversation tokens are not available until refresh
////07-19-2024 =>  SUDAM B CHAVAN => open crm texting panel for dynamic crm provider


import { InvokePostServiceCall } from "../api/serviceUtil";
import apiConfig from "../api/apiConfig";
import { dataService } from "../dataService";
import moment from "moment";
import { openCifWindow } from "../extensions/Util";

import {
    startConversationD, getConvAttributesD, openRecordinCRMD, createOrSearchTaskinCRMD,
    startConvWithTemplatesD, getCustomConvAttributesD, startCustomConversationD,
    getLinkedEntitiesD, openEnityinCRMD, searchContactandProspectsD, updateActivityD, searchOppandFinAccountD, getLinkedEntityNamesD, closeActivityinCRMD, createNewSessionForConversation
} from "./cifUtil";
import {
    startConversationSF, createOrSearchTaskinSF, openRecordinCRMSF, startConvWithTemplatesSF, getConvAttributesSF,
    getLinkedEntitiesSF, openEnityinCRMSF, searchContactandProspectsSF, updateActivitySF, searchOppandFinAccountSF, getLinkedEntityNamesSF, closeActivityinCRMSF, openCtiWindow
} from "./ctiUtil";



var isClicked;
export async function startConversation(data, azureID, crmMemberFields, teamId, convAttribute, memberProfile, crmProvider) {
    if (crmProvider != null && crmProvider == 'S') {
        startConversationSF(data, azureID, crmMemberFields, teamId);
    }
    else {
        startConversationD(data, azureID, crmMemberFields, teamId, convAttribute, memberProfile);
    }

}

export async function getConvAttributes(data, azureID, crmMemberFields, teamId, convAttribute, memberProfile, crmProvider) {
    var convObj = new Object();
    if (crmProvider != null && crmProvider == 'S') {
        convObj = await getConvAttributesSF(data, azureID, crmMemberFields, teamId);
    }
    else {
        convObj = await getConvAttributesD(data, azureID, crmMemberFields, teamId, convAttribute, memberProfile);
    }
    return convObj;

}

export async function startConvWithTemplates(reqDataObj, crmProvider) {
    if (crmProvider != null && crmProvider == 'S') {
        startConvWithTemplatesSF(reqDataObj);
    }
    else {
        startConvWithTemplatesD(reqDataObj);
    }
}

export async function getCustomConvAttributes(data, azureID, crmMemberFields, teamId, convAttribute, crmProvider) {
    var convObj = new Object();
    if (crmProvider != null && crmProvider == 'S') {
        convObj = await getConvAttributesSF(data, azureID, crmMemberFields, teamId);
    }
    else {
        convObj = await getCustomConvAttributesD(data, azureID, crmMemberFields, teamId, convAttribute);
    }
    return convObj;
}

export async function startCustomConversation(data, azureID, crmMemberFields, teamId, convAttribute, crmProvider) {
    if (crmProvider != null && crmProvider == 'S') {
        startConversationSF(data, azureID, crmMemberFields, teamId);
    }
    else {
        startCustomConversationD(data, azureID, crmMemberFields, teamId, convAttribute);
    }
}

export async function createOrSearchTaskinCRM(conv, crmMemberFields, createTask, activityDueDataHours, context, userTeams, crmProvider,crmModeV2="",userInfo,updateConversationAttributes,dispatch,upsertConversationtoList) {
    if (crmProvider != null && crmProvider == 'S') {
        await createOrSearchTaskinSF(conv, crmMemberFields, createTask, activityDueDataHours, context, userTeams);
    }
    else {
        let convAttributes="";
        convAttributes=  await createOrSearchTaskinCRMD(conv, crmMemberFields, createTask, activityDueDataHours, context, userTeams,crmModeV2); //D
        if(crmModeV2 && (context === "TransferConversation" || context === "SelfAssignConversation")){
            conv.conversationAttributes=convAttributes?.conversationAttributes;
            createNewSessionForConversation(conv,userInfo,updateConversationAttributes,dispatch,upsertConversationtoList)
        }
        return convAttributes;
    }

}


export const openContextRecordInCRMWrapper = async (conv, crmMemberFields, entity, crmProvider) => {
    if (crmProvider != null && crmProvider == 'S') {
        openRecordinCRMSF(conv, crmMemberFields, entity);
    }
    else {
        let result=await openRecordinCRMD(conv, crmMemberFields, entity); //D
        return result
    }
   
}

export async function getLinkedEntities(activityId, crmProvider,channel) {
    var linkedRecords = [];
    if (crmProvider != null && crmProvider == 'S') {
        linkedRecords =  await getLinkedEntitiesSF(activityId);
    }
    else {
       let objectChat=channel === "Chat"?"wcu_chat":"wcu_sms"
       linkedRecords =  await getLinkedEntitiesD(activityId,objectChat); //D
    }

    return linkedRecords;
}


export const openEnityinCRM = (entityId, entityLogicalName, entityKeyField, crmProvider) => {
    if (crmProvider != null && crmProvider == 'S') {
        openEnityinCRMSF(entityId, entityLogicalName, entityKeyField);
    }
    else {
        openEnityinCRMD(entityId, entityLogicalName, entityKeyField); //D
    }
    
}


export async function searchContactandProspects(searchText, crmProvider) {
    var searchResults = '';
    if (crmProvider != null && crmProvider == 'S') {
         searchResults = await searchContactandProspectsSF(searchText);
    }
    else {
        searchResults = await searchContactandProspectsD(searchText);
    }
    return searchResults;


}


export async function updateActivity(payload, crmProvider) {
    if (crmProvider != null && crmProvider == 'S') {
        await updateActivitySF(payload);
    }
    else {
        await updateActivityD(payload);
    }
}


export async function searchOppandFinAccount(recordId, crmProvider) {
    var searchResults = '';
    if (crmProvider != null && crmProvider == 'S') {
        searchResults = await searchOppandFinAccountSF(recordId);
    }
    else {
        searchResults = await searchOppandFinAccountD(recordId);
    }
    return searchResults;
}


export async function getLinkedEntityNames(activityId, crmProvider) {
    var linkedEntitiesNames = [];
    if (crmProvider != null && crmProvider == 'S') {
        linkedEntitiesNames = await getLinkedEntityNamesSF(activityId);
    }
    else {
        linkedEntitiesNames = await getLinkedEntityNamesD(activityId);
    }
    return linkedEntitiesNames;
}

export async function closeActivityinCRM(conv,crmProvider) {
    if (crmProvider != null && crmProvider == 'S') {
         closeActivityinCRMSF(conv);
    }
    else {
        closeActivityinCRMD(conv);
    }

}


export async function refreshTokensData(conv, crmMemberFields, crmProvider) {
    let conversation = Object.assign({}, conv);
    conversation.showNotification=false;
    if (crmProvider != null && crmProvider == 'S') {
        openRecordinCRMSF(conversation, crmMemberFields, false);
    }
    else {
        openRecordinCRMD(conversation, crmMemberFields, false);
    }

   
}

export function openCommunicationPanel(crmProvider) {
    if (crmProvider != null && crmProvider === 'S') {
        openCtiWindow();
    }
    else {
        openCifWindow();
    }
}
