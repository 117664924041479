import { pubsubConfig } from "./authConfig";
import { WebPubSubServiceClient, AzureKeyCredential } from "@azure/web-pubsub";

/**
 * Send data all the connections of the user 
 * @param userID,messageBody
 */
export function SendToAllUserConnections(userID,messageBody) {

    let key = new AzureKeyCredential(pubsubConfig.pubSubAuthKey);
    let serviceClient = new WebPubSubServiceClient(pubsubConfig.pubSubEndpoint, key, pubsubConfig.pubSubHubName);
    serviceClient.sendToUser(userID, messageBody);
}
