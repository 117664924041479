//29-09-23 => ONKAR => Created resusable component for sentiment emojis.
//10-12-23 => ONKAR =>  TEXTING-1796 - Sentiment Re-Calculate button to calculate sentiment with latest messages.

import React, { useContext, useState } from 'react'
import {
    Tooltip,
    CircularProgress
  } from "@mui/material";
  import AngryFace from "../assets/AngryFace.svg"
  import Neutralface from "../assets/Neutralface.svg"
  import PositiveFace from "../assets/PositiveFace.svg"
  import VeryPositiveFace from "../assets/VeryPositiveFace.svg"
  import SlightlyNagitive from "../assets/SlightlyNagitive.svg"
import { AuthContext } from '../AuthContext';
import { updateSentimentInSearchAndChannel, updateSentimentInStore } from '../api/messageServices';
import { useDispatch,useSelector } from 'react-redux';
import { conversationsSelector } from '../storage/slices/messageSlice';

function SentimentEmoji(props) {
    const {userInfo}=useContext(AuthContext)
    const dispatch=useDispatch()
    const { sentimentLoading } = useSelector(
      conversationsSelector
    );
    const {overallSentiment,styles,convDetails,setConvDetails,messageList,isRefresh,isConvPage}=props;
    const [loading,setLoading]=useState(false);
  return (
    <>
      {loading || sentimentLoading? (
       <div style={{display:"inline-block", width: "30px",
       height: "30px",
       position: "relative"}}> <CircularProgress size={18} sx={{position:"relative",top:"7px",left:"4px"}} /></div>
      ) : (
        <Tooltip
          title={`Sentiment: ${
            overallSentiment === 1
              ? "Very Unsatisfied"
              : overallSentiment === 2
              ? "Unsatisfied"
              : overallSentiment === 4
              ? "Satisfied"
              : overallSentiment ===5
              ? "Very Satisfied"
              : "Neutral"
          }`}
        >
          <img
            style={{
              width: "25px",
              height: "25px",
              position: "relative",
              marginLeft:"5px",
              cursor: isRefresh && userInfo?.role==="Admin"?"pointer":"default",
              ...styles,
            }}
            onClick={() => {
              if (isRefresh && userInfo?.role==="Admin") {//check isRefresh is true and role is admin to call sentiment api.
                if (isConvPage && convDetails.isReadOnly===false) {//call sentiment api for conversation page
                  dispatch(
                    updateSentimentInStore({
                      conversationId: convDetails.conversationId,
                      messageList: convDetails.messageList,
                    })
                  );
                } else if(!isConvPage){//call sentiment api for omnichannel and search conversation page
                  updateSentimentInSearchAndChannel(
                    convDetails,
                    messageList,
                    setConvDetails,
                    isRefresh,
                    setLoading
                  );
                }
              }
            }}
            src={
              overallSentiment === 1
                ? AngryFace
                : overallSentiment === 2
                ? SlightlyNagitive
                : overallSentiment === 4
                ? PositiveFace
                : overallSentiment === 5
                ? VeryPositiveFace
                : Neutralface
            }
            alt="HappyFace"
          />
        </Tooltip>
      )}
    </>
  );
}

export default SentimentEmoji