/**************************
 * 10-30-2023 => SUDAM B CHAVAN =>TEXTING-1827 - Search Messages
 * 11-07-2023 => SUDAM B CHAVAN =>TEXTING-1842 - Sent and failed status text is still showing for message search results.
 * 12-14-2023 => SUDAM B CHAVAN =>TEXTING-1929 Undelivered status should show instead of failed status in search message results.
 * 12-18-2023 => ONKAR => TEXTING-1924 Member Initials for Customer messages avatar is not showing in conversation opened from Omni channel, History tab, Search messages.
*/
import React, { useState, useContext, useEffect } from "react";
import {
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    CircularProgress,
} from "@mui/material";
import { useTheme, experimentalStyled as styled } from "@mui/material/styles";
import { InvokePostServiceCall } from "../../api/serviceUtil";
import apiConfig from "../../api/apiConfig";
import { AuthContext } from "../../AuthContext";
import moment from "moment";
import SnackBar from "../../components/SnackBar";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { StyledDataGrid } from "../../components/styledComponents/StyledDataGrid";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
}));

function SearchMessages(props) {
    const theme = useTheme();
    const [msgList, setMsgList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const appContext = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [circularLoading, setCircularLoading] = useState(false);
    const [messageType, setMessageType] = useState("none");
    const [status, setStatus] = useState("none");
    const [agent, setAgent] = useState("");
    const [conversationId, setConversationId] = useState("");
    var todayDate = new Date();
    const [date, setDate] = useState({
        toDate: moment(todayDate).format('YYYY-MM-DD'),
        fromDate: moment(new Date(todayDate.setDate(todayDate.getDate() - 90))).format('YYYY-MM-DD')
    });
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const { messageApiToken } = useContext(AuthContext);
    const [userList, setUserList] = useState([]);
    const [teamList, setTeamList] = useState([]);
    const [teamId, setTeamId] = useState("");
    const [snackbarMsg, setSnackBarMsg] = useState("")
    const [teamName, setTeamName] = useState("");
    const [showTeamName, setShowTeamName] = useState(false);
    const [usersLoading, setUsersLoading] = useState(false);
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [phoneerr, setPhoneerr] = useState(false);
    const [emailerr, setEmailerr] = useState(false);
    const [dateErr, setDateErr] = useState({
        fromDateErr: false,
        toDateErr: false,
    });
    const emptyGUID = "00000000-0000-0000-0000-000000000000";

    const handleStatus = (event) => {
        setStatus(event.target.value);
    };

    const handleAgent = (event) => {
        setAgent(event.target.value);
    };
    const handleTeam = (event) => {
        setUserList([]);
        if (event.target.value != emptyGUID) {
            setTeamId(event.target.value);
            getUsers({ isActive: true, teamId: event.target.value, SortByName: true });
        } else {
            setTeamId(emptyGUID);
            setAgent(emptyGUID);
        }

    };
    const messageTypeList = [{
        type: "DOCUMENT",
        status: "Failed,InQueue,Scanning"
    }, {
        type: "OUTBOUND",
        status: "Undelivered,Delivered,Cancelled,Scheduled"
    }]

    const handleMessageType = (event) => {
        setMessageType(event.target.value);
        setStatus("none");
        var filterdList = messageTypeList.filter(s => s.type === event.target.value)[0]?.status.split(',');
        setStatusList(filterdList);
    };
    const dayInMonthComparator = (v1, v2) => {
        if (!v1) v1 = new Date(null);
        if (!v2) v2 = new Date(null);
        if (v1 && v2) {
            var date1 = moment(v1);
            var date2 = moment(v2);
            var diff = date1.diff(date2, 'seconds')
            return diff
        }
    }
    const columns = [
        {
            field: "customerName",
            headerName: "Contact Name",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "customerPhoneNumber",
            headerName: "Contact Phone",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "messageDate",
            headerName: "Message Date",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
            type: "date",
            valueGetter: (params) => {
                if (params.value) {
                    return new Date(params.value);
                }
                return null;
            },
            valueFormatter: (params) => {
                if (params.value instanceof Date) {
                    return params.value.toLocaleTimeString('en-US', {
                        month: '2-digit',
                        day: "2-digit",
                        year: "numeric",
                        hour: 'numeric',
                        minute: 'numeric'
                    });
                }
                return "";
            },
            sortComparator: dayInMonthComparator,
        },
        {
            field: "agentName",
            headerName: "Agent",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "teamName",
            headerName: "Team",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "messageBody",
            headerName: "Message",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "messageType",
            headerName: "Message Type",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
        },

    ];

    useEffect(() => {
        if (messageApiToken) {
            // getUsers({ isActive: true });
            getTeams();
        }
    }, [messageApiToken]);

    const getConversationsByFilter = () => {

        if (
            (date.fromDate !== "" && date.toDate === "") ||
            (date.fromDate === "" && date.toDate !== "")
        ) {
            setDateErr({
                ...date,
                fromDateErr: date.fromDate === "" ? true : false,
                toDateErr: date.toDate === "" ? true : false,
            });
            return;
        }
        else {
            let { dateS, dateE, diffDays } = "";
            dateS = moment(date.fromDate);
            dateE = moment(date.toDate);
            diffDays = dateE.diff(dateS, 'days')
            if (diffDays < 0) {
                setSnackBarMsg("From Date should not greater than To Date");
                setOpenSnackbar(true)
                return;
            }
            else if (diffDays > 90) {
                setSnackBarMsg("Date range should not be exceed more than 90 days");
                setOpenSnackbar(true)
                return;
            }

        }
        let chkmobile = false;
        const PHONE_REGEX = /^[+][0-9]{11}$/i
        let PHONE_US = /^\([0-9]{3}\) |[0-9]{3}-[0-9]{3}-[0-9]{4}$/i;
        const PHONE_INDIAN_REGEX = /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/i;
        if (phone?.startsWith("+91")) {
            if (PHONE_INDIAN_REGEX.test(phone)) {
                chkmobile = true;

            }
        }
        else {
            if (PHONE_REGEX.test(phone)) {
                chkmobile = true;

            } else if (PHONE_US.test(phone)) {
                chkmobile = true;

            }
        }
        if (!chkmobile && phone.length > 0) {
            setPhoneerr(true);
            return;
        }
        let regEmail = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i;
        if (email && !regEmail.test(email)) {
            setEmailerr(true);
            return;
        }
        setCircularLoading(true);
        let requestBody = {
            userId: agent === "" ? emptyGUID : agent,
            status: status === "none" ? "" : status,
            messageType: messageType === "none" ? "" : messageType,            
            conversationId: conversationId ? conversationId : emptyGUID,
            teamId: teamId === "none" || !teamId ? emptyGUID : teamId,
            fromDate: date.fromDate ? moment(date.fromDate).format("MM-DD-YYYY") : "",
            toDate: date.toDate ? moment(date.toDate).format("MM-DD-YYYY") : "",
            phoneNum: phone,
            email: email,
        };

        setLoading(true);
        setMsgList([]) //resetting the con data before calling api hence able to see the loader
        InvokePostServiceCall(apiConfig.GET_MESSAGES_BY_FILTER, requestBody)
            .then((data) => {
                console.log("Search messages : ", data);
                debugger
                let tempArr = [];
                let messages = data.data.messagesDetails;

                if (messages != null && messages.length > 0) {
                    messages.forEach((item, index) => {
                        item.id = index;
                        item.isReadOnly = true;
                        item.status = ((item.messageType === 'OUTBOUND' || item.messageType === 'SYSTEMMSG') && item.status === 'failed') ? 'undelivered' : item.status === 'sent' ? 'delivered' : item.status;
                        item.createdDate = moment(item.createdDate).local().format("MM-DD-YYYY") + ", " + moment(item.createdDate).local().format("LT")
                       // Check if customerName exists and is not "Unknown" 
                       if (item?.customerName && item?.customerName !="Unknown") {
                        // Split the customerName into an array of words
                           let nameArray = item?.customerName?.split(" ");
                           // Check if there is at least one word in the nameArray
                           //// Assign the first character of the first word to customerNameInitials
                           if (nameArray.length > 0) item.customerNameInitials = nameArray[0].charAt(0);
                           if (nameArray.length > 1)
                           item.customerNameInitials += "" + nameArray[1].charAt(0);
                       }
                        tempArr.push(item);
                    });
                    if (teamName) {
                        setShowTeamName(true);
                    } else {
                        setShowTeamName(false);
                    }
                    setMsgList(tempArr);
                } else {
                    setMsgList([]);//resetting old data if no records found for search criteria
                }

                setLoading(false);
                setCircularLoading(false);
            })
            .catch((err) => {
                console.log(err, "Error message");
                setSnackBarMsg(err.message);
                setOpenSnackbar(true);
                setLoading(false);
                setCircularLoading(false);
            });
    };

    const clearSearch = () => {
        setMessageType("none");
        setStatus("none");
        setAgent("");
        setPhone("");
        setTeamId("");
        setTeamName("");
        setPhoneerr("");
        setEmail("");
        setEmailerr("");
        setConversationId("");
        var todayDate = new Date();
        setDate({
            toDate: moment(todayDate).format('YYYY-MM-DD'),
            fromDate: moment(new Date(todayDate.setDate(todayDate.getDate() - 90))).format('YYYY-MM-DD')
        });
        setDateErr({ ...dateErr, fromDateErr: false, toDateErr: false });
        setMsgList([]);
        setUserList([]);
    };
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const getTeams = () => {
        setTeamList(appContext.userInfo.role == "Admin" ? appContext.userInfo.allTeams : appContext.userInfo.teams);
    };
    const getUsers = (payload) => {
        setUsersLoading(true);
        InvokePostServiceCall(apiConfig.GET_USERS, payload)
            .then((data) => {
                setUserList(data.data.userDetails);
                setUsersLoading(false);
            })
            .catch((error) => {
                setSnackBarMsg(error.message);
                setOpenSnackbar(true);
                setUsersLoading(false);
                console.log("usersApi", error);
            });
    };

    return (

        <>
            <SnackBar
                openSnackbar={openSnackbar}
                handleCloseSnackbar={handleCloseSnackbar}
                severity={"error"}
                userMessage={snackbarMsg}
            />

            <h2 style={{ marginLeft: "10px", marginTop: "4px" }}>Search Messages</h2>
            <Box
                sx={theme.customStyle.searchConvMainDiv}
            >
                <Box sx={{ flexGrow: 1 }}>
                    <Grid
                        container
                        spacing={{ xs: 3, md: 3 }}
                        columns={{ xs: 12, sm: 12, md: 12 }}
                    >
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Item sx={theme.customStyle.searchConvRemoveBox}>
                                <TextField
                                    sx={theme.customStyle.searchConvTextfield}
                                    error={phoneerr}
                                    helperText={phoneerr ? "Invalid Phone number." : ""}
                                    variant="standard"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onFocus={event => {
                                        event.target.setAttribute('autocomplete', 'new-password');
                                    }}
                                    label="Contact Phone"
                                    value={phone}
                                    onChange={(e) => {
                                        setPhoneerr(false);
                                        setPhone(e.target.value);
                                    }}
                                />
                            </Item>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Item sx={theme.customStyle.searchConvRemoveBox}>
                                <TextField
                                    sx={theme.customStyle.searchConvTextfield}
                                    error={emailerr}
                                    helperText={emailerr ? "Invalid email address." : ""}
                                    variant="standard"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onFocus={event => {
                                        event.target.setAttribute('autocomplete', 'new-password');
                                    }}
                                    label="Email"
                                    value={email}
                                    onChange={(e) => {
                                        setEmailerr(false);
                                        setEmail(e.target.value);
                                    }}
                                />
                            </Item>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Item sx={theme.customStyle.searchConvRemoveBox}>
                                <FormControl
                                    variant="standard"
                                    sx={theme.customStyle.searchConvSelect}

                                >
                                    <InputLabel shrink={true}>Message Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        value={messageType}
                                        onChange={handleMessageType}
                                        label="messageType"
                                    >
                                        <MenuItem value="none">None</MenuItem>
                                        <MenuItem value={"BOTMSG"}>BOTMSG</MenuItem>
                                        <MenuItem value={"DOCUMENT"}>DOCUMENT</MenuItem>
                                        <MenuItem value={"INBOUND"}>INBOUND</MenuItem>
                                        <MenuItem value={"OUTBOUND"}>OUTBOUND</MenuItem>
                                    </Select>
                                </FormControl>

                            </Item>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Item sx={theme.customStyle.searchConvRemoveBox}>
                                <FormControl
                                    variant="standard"
                                    sx={theme.customStyle.searchConvSelect}

                                >
                                    <InputLabel shrink={true}>Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        value={status}
                                        // defaultValue={"none"}
                                        onChange={handleStatus}
                                        label="Status"
                                    >

                                        <MenuItem value="none">None</MenuItem>
                                        {
                                            statusList && statusList.map(status => <MenuItem value={status}>{status}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>

                            </Item>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Item sx={theme.customStyle.searchConvRemoveBox}>
                                <FormControl
                                    variant="standard"
                                    sx={theme.customStyle.searchConvSelect}
                                >
                                    <InputLabel shrink={true}>Team</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        value={teamId === "" ? "00000000-0000-0000-0000-000000000000" : teamId}
                                        onChange={handleTeam}
                                        label="Team"

                                    >
                                        <MenuItem value="00000000-0000-0000-0000-000000000000" selected="true" onClick={() => { setTeamName("") }}>None</MenuItem>
                                        {teamList && teamList.map((option, index) => (
                                            <MenuItem key={option.teamId} value={option.teamId}
                                                onClick={() => { setTeamName(option.teamName); console.log("teamanem") }}
                                            >
                                                {option.teamName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Item>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Item sx={theme.customStyle.searchConvRemoveBox}>

                                <FormControl
                                    variant="standard"
                                    sx={theme.customStyle.searchConvSelect}
                                >
                                    <InputLabel shrink={true}>Agent</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        value={agent === "" || status == "UnAssigned" ? "00000000-0000-0000-0000-000000000000" : agent}
                                        onChange={handleAgent}
                                        label="Agent"
                                        disabled={status == "UnAssigned" ? true : false}

                                    >
                                        <MenuItem value={"00000000-0000-0000-0000-000000000000"}>None</MenuItem>
                                        {usersLoading && <div style={{ display: "flex", justifyContent: "center" }}><CircularProgress /></div>}
                                        {userList && userList.map((option, index) => (
                                            <MenuItem key={option.user.userId} value={option.user.userId}>
                                                {option.user.userName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>


                            </Item>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box
                sx={theme.customStyle.searchConvMainDiv}
            >
                <Box sx={{ flexGrow: 1 }}>
                    <Grid
                        container
                        spacing={{ xs: 3, md: 3 }}
                        columns={{ xs: 12, sm: 12, md: 12 }}
                    >
                      
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Item sx={theme.customStyle.searchConvRemoveBox}>
                                <TextField
                                    error={dateErr.fromDateErr}
                                    sx={theme.customStyle.searchConvTextfield}
                                    variant="standard"
                                    size="small"
                                    type={"date"}
                                    id="searchConversionfromDate"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onFocus={event => {
                                        event.target.setAttribute('autocomplete', 'new-password');
                                    }}
                                    value={date.fromDate}
                                    label="From Date"
                                    onChange={(e) => {
                                        setDate({
                                            ...date,
                                            fromDate: e.target.value,
                                        });
                                        setDateErr({ ...dateErr, fromDateErr: false, fromDate: false });
                                    }}
                                    helperText={dateErr.fromDateErr ? "Enter From Date" : ""}
                                />
                            </Item>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Item sx={theme.customStyle.searchConvRemoveBox}>
                                <TextField
                                    error={dateErr.toDateErr}
                                    sx={theme.customStyle.searchConvTextfield}
                                    variant="standard"
                                    size="small"
                                    type={"date"}
                                    value={date.toDate}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onFocus={event => {
                                        event.target.setAttribute('autocomplete', 'new-password');
                                    }}
                                    label="To Date"
                                    onChange={(e) => {
                                        setDate({
                                            ...date,
                                            toDate: e.target.value,
                                        });
                                        if (new Date(e.target.value) < new Date(date.fromDate)) {
                                            setDateErr({ ...dateErr, toDateErr: true });
                                        } else {

                                            setDateErr({ ...dateErr, toDateErr: false });
                                        }
                                    }}
                                    helperText={dateErr.toDateErr ? "Enter to Date" : ""}
                                />
                            </Item>
                        </Grid>

                    </Grid>

                </Box>
            </Box>
            <Box>
                <Button
                    sx={theme.customStyle.searchConvBtn}
                    variant="contained"
                    onClick={() => {
                        getConversationsByFilter();
                    }}
                >
                    Search
                </Button>
                <Button
                    sx={theme.customStyle.searchConvBtn}
                    variant="contained"
                    onClick={() => {
                        clearSearch();
                    }}
                >
                    Clear
                </Button>
            </Box>
            {circularLoading && (
                <div style={{ marginLeft: "46%", marginTop: "10%" }}>
                    <CircularProgress />
                </div>
            )}
            {!circularLoading && !loading && msgList.length < 1 && (
                <div style={{ marginLeft: "44%", marginTop: "10%" }}>
                    No Conversations
                </div>
            )}

            {msgList.length > 0 && !circularLoading ? (
                <Box sx={{ ...theme.customStyle.tableHead, marginTop: "10px" }}>
                    <StyledDataGrid
                        loading={loading}
                        rows={msgList}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        onRowDoubleClick={(e) => {
                            props.onRowSelect(e)
                        }}
                        columnVisibilityModel={{
                            // Hide Columns  
                            teamName: showTeamName ? true : false,
                        }}
                    // initialState={{
                    //     sorting: {
                    //         sortModel: [{ field: "createdDate", sort: "desc" }],
                    //     },
                    // }}
                    />
                    <div>* All timestamps are in Pacific Time.</div>
                </Box>
            ) : null}

        </>
    );
}

export default SearchMessages;
