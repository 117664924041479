import React from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
const browserHistory = createBrowserHistory({ basename: '' });
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: '6560fd3a-51e0-49b0-96c1-8881e7b003a8',//process.env.REACT_APP_APPINSIGHT_INSTRUMENTKEY,
        enableAutoRouteTracking: true,
        connectionString: 'InstrumentationKey=6560fd3a-51e0-49b0-96c1-8881e7b003a8;IngestionEndpoint=https://westus-0.in.applicationinsights.azure.com;LiveEndpoint=https://westus.livediagnostics.monitor.azure.com', //process.env.REACT_APP_APPINSIGHT_CONNECTIONSTRING,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        }
    }
}
);
appInsights.loadAppInsights();
export { reactPlugin, appInsights };