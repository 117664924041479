////12/09/2022 => ONKAR => Texting-913 Prompt the Templates to the User before start the conversation
////12/14/2022 => ONKAR => Texting-925 Texting-925 Active conversation is not starting from M360 form fixed.
////12/22/2022 => ONKAR => Display No Records Found msg if template list is empty.
////01-23-2022 => AV => Texting-1011  Show Entity Name on Start Conversation Dialogs
////27-04-2023 => SUDAM B CHAVAN => Texting - 1199 - Alert is not showing only loading symbol is showing.
////11-07-2023 => ONKAR => Texting - 1422 - Block Templates to be sent when there is a Irreplaceable token in it.
////09-01-2023 => SUDAM B CHAVAN =>TEXTING-1645 - Reporting - Track templates used
////09-15-2023 => ONKAR =>TEXTING-1697 - Client Req: Ability to view the chat history of the member without initiating the conversation
////09-18-2023 => ONKAR =>TEXTING-1720 - View history on start conversation UI observations.
////09-20-2023 => ONKAR =>TEXTING-1720 - View history on start conversation UI observations.
////09-21-2023 => ONKAR =>TEXTING-1720 - View history on start conversation UI observations.
import React,{useContext, useEffect, useState} from "react";

import {
    startConvWithTemplates,
    getConvAttributes,
    getCustomConvAttributes} from "../extensions/crmWrapper";

import {
  Typography,
  Button,
  DialogActions,
  DialogContent,
  CircularProgress,
  Divider,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  ListItem,
  TextField,
  InputAdornment,
  Box,
  Grid,
  Tab,
  Tooltip
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import SearchIcon from '@mui/icons-material/Search';
import { checkStartConversationEligibility } from "../api/messageServices";
import { getEntityDisplayName } from '../extensions/Util';
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import ChatHistory from "./ChatHistory";
import TabList from "@mui/lab/TabList";
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ConversationHistory from "./ConversationHistory";
import { AuthContext } from "../AuthContext";

function StartConvWithTemplatesDialog(props) {
  const {
    showTeamTemplateDialog,
    handleCloseTeams,
    templateLoading,
    showTemplatesDialog,
    selectedTemplateBody,
    setTemplateSearchVal,
    templatesList,
    templateSearchVal,
    startConvReqObjData,
    setStartConvReqObjData,
    setSelectedTemplateName,
    setSelectedTemplateBody,
    selectedTemplateName,
    sethasTeams,
    userInfo,
    setShowTemplatesDialog,
    setConvLoading,
    setTemplateMsg,
      templateMsg,
      crmProvider,
      setSeverity,
      setSnackbarMsg,
      setOpenSnackbar,
    } = props;
  const theme = useTheme();
  const {applicationSettings}=useContext(AuthContext)
  const [disableStartBtn,setDisableStartBtn]=useState(false);
  const [tabValue,setTabValue]=useState("1");
  const formatPhoneNumber = (inputPhoneNumber) => {
    let phoneNumber = "";
    if (inputPhoneNumber) {
      phoneNumber = inputPhoneNumber.replace(/[^0-9]/g, "");
      if (phoneNumber.length === 10) {
        phoneNumber = "+1" + phoneNumber;
      } else if (phoneNumber.length === 11) {
        phoneNumber = "+" + phoneNumber;
      }
    }
    return phoneNumber
  }
  function filterTemplates(val){
    let filteredData=templatesList.filter(item=>item.templateName.toLowerCase().includes(val))
    if(filteredData.length<1){
      setTemplateMsg(true);
    }else{
      setTemplateMsg(false);
    }
  }
  function handleChangeTab(e,selectedTabValue){
    setTabValue(selectedTabValue)
    setTemplateSearchVal("");//reset the filter when tab changed
    setTemplateMsg(false);
  }
  useEffect(()=>{
    //  When history tab is opened click on SMS icon again from CRM,
    //  Observe page is not resetting to Select teams page.
    //  to fix above issues set tab value to 1 when start conversation button is pressed on crm
    if(!showTemplatesDialog){
      setTabValue("1")
    }
  },
  [showTemplatesDialog])
  return (
    <>
      {showTeamTemplateDialog && (
        <>
          {showTemplatesDialog && (
            <Box
              sx={{
                pt: 0,
                pl: 1,
                pr: 1,
                pb: 0,
                ...theme.customStyle.convBoxHeader,
              }}
            >
              <div style={{ width: "50%" }}>
                <div
                  style={{
                    ...theme.customStyle.convBoxHeader,
                    justifyContent: "flex-start",
                  }}
                >
                  <Tooltip
                    title={`${startConvReqObjData?.contact?.firstName} ${startConvReqObjData?.contact?.lastName}`}
                  >
                    <span style={theme.customStyle.convHeaderCustName}>
                      {`${startConvReqObjData?.contact?.firstName} ${startConvReqObjData?.contact?.lastName}`}
                    </span>
                  </Tooltip>
                  <Tooltip title="SMS">
                    <PhoneIphoneIcon
                      color="primary"
                      sx={{ marginLeft: "5px" }}
                    />
                  </Tooltip>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={theme.customStyle.convHeaderEmailEllipsis}>
                    <Tooltip
                      title={`${formatPhoneNumber(
                        startConvReqObjData?.conversation?.customerPhoneNumber
                      )}`}
                    >
                      <span style={theme.customStyle.convHeaderCustPhone}>
                        {formatPhoneNumber(
                          startConvReqObjData?.conversation?.customerPhoneNumber
                        )}
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </div>
              {startConvReqObjData?.conversation && (
                <div style={theme.customStyle.convHeaderBtnDiv}>
                  <Button
                    variant="contained"
                    style={{
                      ...theme.customStyle.endConvBtn,
                      marginRight: "5px",
                    }}
                  >
                    {startConvReqObjData?.conversation
                      ? getEntityDisplayName(
                          JSON.parse(
                            startConvReqObjData?.conversation
                              ?.ConversationAttributes
                          ).EntityLogicalName,
                          startConvReqObjData?.conversation?.channel,
                          startConvReqObjData?.conversation?.source
                        )
                      : ""}
                  </Button>
                </div>
              )}
            </Box>
          )}
           <TabContext value={tabValue}>
            <Box sx={theme.customStyle.tabContextBox}>
            <TabList
                onChange={handleChangeTab}
                aria-label="lab API tabs example"
                centered={true}
                variant="fullWidth"
                sx={theme.customStyle.tabStyle}
            >
              <Tab
                        sx={theme.customStyle.tabStyle}
                        label={"Templates"}
                        value={"1"}
                    />
                    <Tab
                        sx={theme.customStyle.tabStyle}
                        label={"History"}
                        value={"2"}
                    />
            </TabList>
            </Box>
               <TabPanel value="1">
          <DialogContent sx={{ height:"calc(100vh - 245px)", padding:"0px" }}>
            {templateLoading && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15%",
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {showTemplatesDialog && !templateLoading &&(
                <>                                                 
                {!selectedTemplateBody && (
                  <>
                    <TextField
                      variant="standard"
                      placeholder="Search Templates"
                      autoComplete="off"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,
                      }}
                      onChange={(e) => {
                        setTemplateSearchVal(e.target.value.toLowerCase());
                        if(e.target.value){
                          filterTemplates(e.target.value.toLowerCase())
                        }else{
                          setTemplateMsg(false);
                        }
                      }}
                    />
                    {templateMsg && (
                      <Box sx={{ textAlign: "center", marginTop: "20px" }}>
                        No Records Found
                      </Box>
                    )}
                    <Box>
                      <Box
                        sx={{
                          maxHeight: "calc(100vh - 285px)",
                          marginTop: "5px",
                          overflowY: "auto",
                        }}
                      >
                        {templatesList?.map((item, index) => {
                          return (
                            <>
                              {item.templateName
                                .toLowerCase()
                                .includes(templateSearchVal) && (
                                  <>
                                    {index > 0 && <Divider />}
                                    <ListItem
                                      button
                                      sx={{
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        justifyContent: "center",
                                        paddingRight: "0px",
                                        paddingLeft: "0px",
                                      }}
                                      key={index}
                                      onClick={() => {
                                        if (item.templateBody.includes("##")) {
                                          setSeverity("warning");
                                          setSnackbarMsg("Some of the tokens in this template cannot be replaced with actual values. Please review and update manually.");
                                          setOpenSnackbar(true)
                                        }
                                        let conversationobj = startConvReqObjData;
                                        conversationobj.conversation.conversationBody =
                                          item.templateBody;
                                        conversationobj.templateId = item.templateId;
                                        setStartConvReqObjData(conversationobj);
                                        setSelectedTemplateName(
                                          item.templateName
                                        );
                                        setSelectedTemplateBody(
                                          item.templateBody
                                        );
                                        let strArr=item.templateBody.split(" ")
                                        for(let i=0;i<strArr.length;i++){
                                          if(strArr[i].startsWith("##") &&(strArr[i].endsWith("##")  || strArr[i].endsWith("##."))){
                                            setDisableStartBtn(true);    
                                            break;
                                          }
                                          setDisableStartBtn(false);    
                                        }
                                        setTemplateSearchVal("");
                                      }}
                                    >
                                      <span style={{ fontSize: "13px" }}>
                                        <strong>{item.templateName}</strong>
                                      </span>
                                      <Typography style={{ fontSize: "12px" }}>
                                        {item.templateBody}
                                      </Typography>
                                    </ListItem>
                                  </>
                                )}
                            </>
                          );
                        })}
                      </Box>
                    </Box>
                  </>
                )}
                {selectedTemplateBody && (
                  <>
                    <Box className="wcu_box_padding">
                      <FormControl
                        className="wcu_full_width"
                        variant="outlined"
                        sx={{ marginTop: "0.6rem" }}
                      >
                        <TextField
                          type={"text"}
                          value={selectedTemplateBody}
                          fullWidth
                          onChange={(e) => {
                            let conversationobj = startConvReqObjData;
                            conversationobj.conversation.conversationBody = e.target.value;
                            setSelectedTemplateBody(e.target.value);
                            setStartConvReqObjData(conversationobj);
                            let strArr=selectedTemplateBody.split(" ")
                            for(let i=0;i<strArr.length;i++){
                              if(strArr[i].startsWith("##") &&(strArr[i].endsWith("##")  || strArr[i].endsWith("##."))){
                                setDisableStartBtn(true);    
                                break;
                              }
                              setDisableStartBtn(false);    
                            }
                          }}
                          inputProps={{
                                maxLength: 512, style: { fontSize: "12px" }
                          }}
                          multiline
                          minRows={4}
                          maxRows={4}
                          label={"Input Message"}
                                              />
                        <Box sx={{ marginRight: "15px", marginTop: "17px", fontWeight: "bold", textAlign: "right"}}>
                                                  {startConvReqObjData?.conversation?.conversationBody ? startConvReqObjData?.conversation?.conversationBody.length : '0'}/512
                                              </Box>
                      </FormControl>
                    </Box>
                  </>
                )}
              </>
            )}
          </DialogContent>
          <DialogActions>
            {showTemplatesDialog && (
              <>
                {userInfo.teams.length > 1 || selectedTemplateBody ? <Button
                  onClick={() => {
                    if (selectedTemplateBody && selectedTemplateName) {
                      setSelectedTemplateName("");
                      setSelectedTemplateBody("");
                    } else {
                      setShowTemplatesDialog(false);
                      sethasTeams(true);
                      setTemplateMsg(false);
                    }
                    setTemplateSearchVal("")
                  }}
                >
                  <strong>Back</strong>
                </Button>:<></>}
                <Button
                  disabled={!selectedTemplateBody || disableStartBtn}
                  onClick={() => {
                    setConvLoading(true);
                    startConvWithTemplates(startConvReqObjData,crmProvider);
                    handleCloseTeams();
                  }}
                >
                  <strong>Start</strong>
                </Button>
                <Button onClick={handleCloseTeams}>
                  <strong>Cancel</strong>
                </Button>
              </>
            )}
          </DialogActions>
          </TabPanel>
          <TabPanel value="2">
          <DialogContent sx={theme.customStyle.startConvWithTempDContent}>
          {applicationSettings.ShowAllConversationHistory==="Y"?
                  <ConversationHistory
                    conversationDetails={{
                    customerPhoneNumber:startConvReqObjData?.conversation?.customerPhoneNumber,
                    channel:startConvReqObjData?.conversation?.channel,
                    customerName:startConvReqObjData?.contact?.firstName+" "+startConvReqObjData?.contact?.lastName,
                    email:startConvReqObjData?.contact?.email
                   }}
                   isFromCrm={true}
                  /> :
                   <ChatHistory  //conversation history component.
                  conversation={startConvReqObjData?.conversation?.customerPhoneNumber}
                  email={startConvReqObjData?.contact?.email}
                  isStartWithTemplate={true}
                />  }
            </DialogContent>
            <DialogActions>
              <>
                <Button onClick={handleCloseTeams}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <span>
                      <strong> Cancel</strong>
                    </span>
                  </div>
                </Button>
              </>
          </DialogActions>
          </TabPanel>
          </TabContext>
        </>
      )}
    </>
  );
}

export default StartConvWithTemplatesDialog;
