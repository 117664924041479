const BASE_URL = process.env.REACT_APP_BASE_URL;
const apiConfig = {
    GET_USERS: BASE_URL + "Users/GetUsers",
    GET_USER_BY_TEAM_ID: BASE_URL + "Users/GetUsers",
    GET_USER_INFO: BASE_URL + "Users/GetUserInfo",
    LOOKUP_USER: BASE_URL + "Users/LookupUser",
    ADD_UPDATE_USER: BASE_URL + "Users/SaveUser",
    GET_LOGIN_HISTORY: BASE_URL + "Users/GetLogInHistory",    
    GET_USER_PROFILE_IMAGE: BASE_URL + "Users/GetUserProfileImage",
    GET_TEAMS: BASE_URL + "Teams/GetTeams",
    GET_ASSIGN_CONVERSATION_ELIGIBLE_TEAMS: BASE_URL + "Teams/getAssignConversationEligibleTeams",
    GET_CONTACTS: BASE_URL + "Contacts/GetContacts",
    GET_CONTACTS_BY_TEAMS_ID: BASE_URL + "Contacts/GetContacts?teamId=",
    SAVE_CONTACT: BASE_URL + "Contacts/SaveContact",
    ADD_UPDATE_TEAM: BASE_URL + "Teams/SaveTeam",
    SAVE_TEMPLATE: BASE_URL + "Templates/SaveTemplate",
    GET_TEMPLATES: BASE_URL + "Templates/GetTemplates",
    GET_TEMPLATES_WITH_TOKENS: BASE_URL + "Templates/GetTemplatesWithTokens",
    GET_TEMPLATES_BY_CONTEXT: BASE_URL + "Templates/GetTemplatesByContext",
    SAVE_TOKEN: BASE_URL + "Tokens/SaveTokens",
    GET_TOKENS: BASE_URL + "Tokens/GetTokens",
    GET_MESSAGES: BASE_URL + "Messages/GetMessages",
    GET_MESSAGES_BY_FILTER: BASE_URL + "Messages/GetMessagesByFilter",
    SEND_MESSAGES: BASE_URL + "Messages/SendMessage",
    START_CONVERSATION: BASE_URL + "Conversations/StartConversation",
    END_CONVERSATION: BASE_URL + "Conversations/EndConversation",
    UPDATE_CONVERSATION: BASE_URL + "Conversations/UpdateConversation",
    GET_NOTIFICATIONS: BASE_URL + "Conversations/GetNotifications",
    GET_INACTIVE_CONVERSATIONS: BASE_URL + "Conversations/GetInActiveConverstaions",
    GET_ACTIVE_CONVERSATIONS_BY_USER_ID:
        BASE_URL + "Conversations/GetActiveConverstaions?userId=",
    ASSIGN_CONVERSATION: BASE_URL + "Conversations/AssignConversation",
    CHECK_START_CONV_ELIGIBILITY: BASE_URL + "Conversations/CheckStartConversationEligibility",
    GET_CONVERSATIONS_DASHBOARD_SUMMARY:
        BASE_URL + "Conversations/GetConversationsDashboardSummary",
    GET_CONVERSATION_BY_FILTER:
        BASE_URL + "Conversations/GetConversationsByFilter",
    GET_UNASSIGNED_CONVERSATIONS:
        BASE_URL + "Conversations/GetUnassignedConverstaions",
    START_VIDEO_CONF: BASE_URL + "Conversations/CreateVideoMeeting",
    LOGIN: BASE_URL + "Auth/Login",
    API_AD_SCOPE: "api://ef8c3539-7dc2-45c1-bd08-bdabe0780de6/acess_as_user",
    ACCEPT_REJECT_CONVERSATION: BASE_URL + "Conversations/AgentAcceptRejectConversation",
    UPDATE_CONVERSATION_AS_READ: BASE_URL + "Conversations/UpdateConversationAsRead",
    UPDATE_USER_STATUS: BASE_URL + "Users/UpdateUserStatus",
    GET_CONTACTS_BY_FILTER: BASE_URL + "Contacts/GetContactsByFilter",
    SEARCH_CRM_RECORDS: BASE_URL + "Contacts/SearchRecordsInCRM",
    GET_USERS_BY_FILTER: BASE_URL + "Users/GetUsersByFilter",
    SAVE_USER_ATTRIBUTES: BASE_URL + "Users/SaveUserAttributes",
    SAVE_USER_PREFERENCES: BASE_URL + "Users/SaveUserPreferences",
    GET_SETTINGS: BASE_URL + "Settings/GetSettings",
    GET_NONADMIN_SETTINGS: BASE_URL + "Settings/GetSettingsNonAdmin",
    SAVE_SETTINGS: BASE_URL + "Settings/SaveSettings",
    SAVE_CHATCONFIG: BASE_URL + "ChatConfig/SaveChatConfig",
    GET_CHATCONFIG: BASE_URL + "ChatConfig/GetChatConfigs",
    GET_THEAMS: BASE_URL + "ChatConfig/GetOrgTheams",
    GET_BUSINESS_HOURS_CONFIG: BASE_URL + "BusinessHours/GetBusinessHoursConfig",
    SAVE_BUSINESS_HOURS_CONFIG: BASE_URL + "BusinessHours/SaveBusinessHoursConfig",
    SAVE_TEAM_MEMBER: BASE_URL + "Teams/SaveTeamMember",
    DELETE_TEAM_MEMBER: BASE_URL + "Teams/DeleteTeamMember",
    GET_PHONE_NUMBER_OPT_OUT_LIST: BASE_URL + "PhoneNumbers/GetOptInOutList",
    GET_SEARCH_COMMUNICATION_LIST: BASE_URL + "CommunicationList/SearchCommunicationList",
    GET_COMMUNICATION_LIST: BASE_URL + "CommunicationList/GetCommunicationList",
    SAVE_COMMUNICATION_LIST: BASE_URL + "CommunicationList/SaveCommunicationList", 
    GET_CONTACT_LIST: BASE_URL + "CommunicationList/GetContactList", 
    UPLOAD_CONTACT_LIST_CSV: BASE_URL + "CommunicationList/UploadContactListCsv", 
    DELETE_CONTACT_LIST: BASE_URL + "CommunicationList/DeleteContactList", 
    UPDATE_COMMUNICATION_LIST_STATUS: BASE_URL + "CommunicationList/UpdateCommunicationListStatus", 
    GET_HOLIDAYS: BASE_URL + "holidays/GetHolidays",
    SAVE_HOLIDAYS: BASE_URL + "holidays/SaveHoliday",
    SAVE_WHITE_LISTED_PHONE_NUMBER: BASE_URL + "whiteListedNumbers/SaveWhiteListedPhoneNumber",
    GET_WHITE_LISTED_PHONE_NUMBER: BASE_URL + "whiteListedNumbers/GetWhiteListedPhoneNumbers",
    GET_USERS_SUMMERY: BASE_URL + "Users/GetUsersSummary",
    GET_USERS_SUMMARY_TEAMS: BASE_URL + "Users/GetUsersSummaryDashBoard",
    GET_ACCESS_TOKEN: BASE_URL + "DocuSign/GetAccessToken",
    CREATE_ENVELOPE: BASE_URL + "DocuSign/CreateEnvelope",
    SEND_SIGNINGURL: BASE_URL + "DocuSign/SendSigningUrl",
    CREATE_RECIPIENT_VIEW: BASE_URL + "DocuSign/CreateRecipentView",
    GET_VALIDATE_ACCESS_TOKEN: BASE_URL + "DocuSign/GetOrValidateToken",
    GET_CONVERSATIONS_EXCEL_REPORT: BASE_URL + "Conversations/GetConversationsExcelReport",
    SEARCH_TEMPLATE_WITH_TOKENS: BASE_URL + "Templates/SearchTemplateswithTokens",
    GET_MISSED_CONVERSTAIONS:  BASE_URL + "Conversations/GetMissedConverstaions",
    GET_CONVERSATION_ASSIGNMENT_HISTORY:  BASE_URL + "Conversations/GetConversationAssignmentHistory",
        GET_OBJECT_HISTORY: BASE_URL + "Templates/GetObjectHistory",
    GET_DOCUMENT_REQ_URL: BASE_URL + "Documents/GetDocumentReqURL",
    GENERATE_OTP: BASE_URL + "Documents/GenerateOTP",
    VALIDATE_OTP: BASE_URL + "Documents/ValidateOTP",
    UPLOAD_DOCUMENTS: BASE_URL + "Documents/UploadDocuments",
    UPLOAD_DOCUMENTS_WEBCHAT: BASE_URL + "Documents/UploadDocumentsWebChat",
    START_CONVERSATION_WEBCLIENT: BASE_URL + "Conversations/StartWebChatConversation",
    START_CONVERSATION_WEBCLIENT_AUTHORIZED: BASE_URL + "WebChat/StartWebChatConversation",
    SEND_MESSAGE_WEBCLIENT: BASE_URL + "Messages/HandleWebclientInboundMessage",
    GET_DOCUMENT_FROM_STORE: BASE_URL + "Documents/GetDocumentFromStore",
    VALIDATE_DOCUMENT_URL: BASE_URL + "Documents/ValidateDocumentURL",
    GET_DOCUMENT_STATUS: BASE_URL + "Documents/GetDocumentStatus",
    SEND_CONVERSATION_TRANSCRIPT_TO_CUSTOMER: BASE_URL + "Conversations/SendConversationTranscriptToCustomer",
    GET_MESSAGE_SUGGESTIONS: BASE_URL + "Messages/GetAutoSuggestionsWithAPI",
    END_WEBCHAT_CONVERSATION: BASE_URL + "Conversations/EndWebChatConversation",  
    GET_BOT_MESSAGES:BASE_URL + "BotServices/GetBotMessages", 
    AUTO_ASSIGN_CONVERSATION: BASE_URL + "Conversations/AutoAssignConversation",  
    GET_WEBCHAT_MESSAGES: BASE_URL + "Messages/GetMessagesForWebChat",
    SEND_PUBSUB_NOTIFICATION_FOR_WEBCHAT: BASE_URL + "Conversations/SendPubSubNotificationForWebChat",
    GET_TCPA: BASE_URL + "Tcpa/GetTcpa",
    UPDATE_TCPA: BASE_URL + "Tcpa/UpdateTcpa",
    GET_SENTIMENT: BASE_URL + "conversations/GetSentimentAnalysisFortheConversation",
    GET_TRANSCRIPT_SUMMARY_WITH_API: BASE_URL + "Messages/GetTranscriptSummaryWithAPI",
    GET_TEMPLATE_ASSOCIATED_TEAMS: BASE_URL + "Templates/GetTemplateAssociatedTeams",
    MANAGE_TEAMS_TO_TEMPLATE_ASSOCIATION: BASE_URL + "Templates/ManageTeamsToTemplateAssociation",
    GET_ALL_CONVERSATIONS_MESSAGES: BASE_URL + "Conversations/GetAllConversationsMessages",
    GET_MESSAGES_HISTORY_WEBCHAT: BASE_URL + "webchat/getmessages",
    GENERATE_POWERBI_ACCESS_TOKEN: BASE_URL + "Auth/GeneratePowerBIAccessToken",
    UPDATE_MESSAGE: BASE_URL + "Messages/UpdateMessage",    
    CREATE_PVA_BOT_CONVERSATION: BASE_URL + "BotServices/CreatePVABotConversation",
    GET_AI_CONVERSATIONAL_BOT_MESSAGES: BASE_URL + "BotServices/GetAIConversationalBotMessages",
    GET_SURVEY_TEMPLATES: BASE_URL + "Survey/GetSurveyTemplates",
    SAVE_SURVEY_TEMPLATE: BASE_URL + "Survey/SaveSurveyTemplate",
    GET_SURVEY_TEMPLATE_DETAILS: BASE_URL + "Survey/GetSurveyTemplateDetails",
    CREATE_SURVEY_QUESTION: BASE_URL + "Survey/CreateSurveyQuestion",
    GET_SURVEY_QUESTIONS: BASE_URL + "Survey/GetSurveyQuestions",
    ADD_QUESTIONS_TO_SURVEY_TEMPLATE: BASE_URL + "Survey/AddQuestionsToSurveyTemplate",
    GET_QUESTIONS_BY_SURVEY_TEMPLATE: BASE_URL + "Survey/GetQuestionsBySurveyTemplate",
    CREATE_SURVEY: BASE_URL + "Survey/CreateSurvey",
    SAVE_SURVEY_RESPONSE: BASE_URL + "Survey/SaveSurveyResponse",
    GET_SURVEY_RESPONSES: BASE_URL + "Survey/GetSurveyResponses",
    UPDATE_CONVERSATION_ATTRIBUTES: BASE_URL + "Conversations/UpdateConversationAttribute",
    GET_SURVEY_QUESTIONS_BY_SURVEYID: BASE_URL + "Survey/GetSurveyQuestionsBySurveyId",
    GET_SURVEY_RESPONSES_FOR_CONVERSATION: BASE_URL + "Survey/GetSurveyResponsesForConversation",    
    UPDATE_TEMPLATE_APPROVAL_STATUS: BASE_URL + "Templates/UpdateTemplateApprovalStatus",    
    GET_USERS_BY_ROLE: BASE_URL + "users/GetUsersByRole",    
    GET_ASSISTANCE_REQUEST_CONVERSATIONS: BASE_URL + "Conversations/GetAssistanceRequestConverstaions",    
    SEND_INTERNAL_MESSAGE: BASE_URL + "messages/SendInternalMessage",    
    GENERATE_WS_URL: BASE_URL + "WebChat/GenerateWSUrl",    
    START_REQUEST_ASSISTANT: BASE_URL + "Conversations/StartRequestAssistance",    
    END_REQUEST_ASSISTANT: BASE_URL + "Conversations/EndRequestAssistance",    
    GENERATE_WEBCHAT_TOKEN: BASE_URL + "Auth/GenerateWebChatToken",
    GET_VISITOR_INFO_BY_CONVERSATION_ID: BASE_URL + "Conversations/GetVisitorInfoByConversationId"
};
// 6bfeb121-7bf7-4523-b626-4acb4d8afa03

export default apiConfig;
