////11-04-2022 => Texting-753 cache clear added for version change.
////12-06-2022 => ONKAR => Texting-658 Load BrowserRoute or MemoryRoute conditionally.
////12-15-2022 => ONKAR => Texting-926 set crmMode in app level based on url.
////06-27-2022 => ONKAR => Texting-1378 TCPA UI Changes
////10-17-2023 => SUDAM B CHAVAN => TEXTING-1646 Reporting - Realtime Reports Design
////11-27-2023 => SUDAM B CHAVAN => TEXTING-1872 Chat Survey Questions Configuration - Admin UI
////12-01-2023 => SUDAM B CHAVAN => TEXTING - 1872 - Chat Survey Questions Configuration - Admin UI
////01-14-2023 => ONKAR => TEXTING - 1936 - Request Assistance : UI


import React, { Component } from "react";
import { BrowserRouter, Routes, Route, MemoryRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import Header from "./components/Header";
import HeaderUnAuth from "./components/HeaderUnAuth";
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from "@azure/msal-react";
import UsersPage from "./pages/users/UsersPage";
import TeamsPage from "./pages/teams/TeamsPage";
import ContactsPage from "./pages/contacts/ContactsPage";
import ConversationPage from "./pages/conversations/ConversationPage";
import SearchConversation from "./pages/conversations/SearchConversation";
import AddUpdateUser from "./pages/users/AddUpdateUser";
import AddUpdateTeam from "./pages/teams/AddUpdateTeam";
import AddUpdateContact from "./pages/contacts/AddUpdateContact";
import TemplatesPage from "./pages/templates/TemplatesPage";
import AddUpdateTemplate from "./pages/templates/AddUpdateTemplate";
import AddUpdateToken from "./pages/tokens/AddUpdateToken";
import AuthContextProvider from "./AuthContext";
import "./assets/style.css";
import VideoApp from "./video/VideoApp";
import { Provider } from "react-redux";
import chatStore from "./storage/localStore";
import wcuTheme from "./themes/wcuTheme";
import TokensPage from "./pages/tokens/TokensPage";
import UnAuthorized from "./UnAuthorized";
import ApplicationSettingsPage from "./pages/settings/ApplicationSettingsPage";
import AddUpdateSettings from "./pages/settings/AddUpdateSettings";
import ChatConfigPage from "./pages/chatConfig/ChatConfigPage";
import AddUpdateChatConfig from "./pages/chatConfig/AddUpdateChatConfig";
import BusinessHoursConfig from "./pages/businessHoursConfig/BusinessHoursConfig";
import AddUpdateBusinessHoursConfig from "./pages/businessHoursConfig/AddUpdateBusinessHoursConfig";
import PhoneNumberOptOut from "./pages/phoneNumberOptOut/PhoneNumberOptOut";
import Communications from "./pages/communications/Communications";
import AddUpdateCommunications from "./pages/communications/AddUpdateCommunications";
import LandingPage from "./pages/landingPage/LandingPage";
import HomePage from "./pages/home/HomePage";
import Holidays from "./pages/Holidays/Holidays";
import AddUpdateHolidays from "./pages/Holidays/AddUpdateHolidays";
import OmniChannnel from "./pages/omniChannel/OmniChannnel";
import DefaultMessage from "./components/DefaultMessage"
import AddUpdatePhoneNumber from "./pages/whiteListedPhoneNumber/addUpdatePhoneNumber";
import PhoneNumbers from "./pages/whiteListedPhoneNumber/PhoneNumbers";
import DocuSignMessage from "./pages/docuSign/DocuSignMessage";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./azure/appInsights";
import packageJson from '../package.json';
import TCPAPage from "./pages/tcpa/TCPAPage";
import PowerBIReport from "./components/PowerBIReport";
import SurveyTemplatesPage from "./pages/survey/surveyTemplatesPage";
import AddUpdateSurveyTemplate from "./pages/survey/addUpdateSurveyTemplate";
import SurveyQuestionsPage from "./pages/surveyQuestions/surveyQuestionsPage";
import AddUpdateSurveyQuestion from "./pages/surveyQuestions/addUpdateSurveyQuestion";
global.appVersion = packageJson.version;
//import UsersPage from "./pages/users/UsersPage";
//import TeamsPage from "./pages/teams/TeamsPage";
//import ContactsPage from "./pages/contacts/ContactsPage";
//import ConversationPage from "./pages/conversation/ConversationPage";

//import './custom.css'

// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);

    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());

        const b = Number(versionsB.shift());
        // eslint-disable-next-line no-continue
        if (a === b) continue;
        // eslint-disable-next-line no-restricted-globals
        return a > b || isNaN(b);
    }
    return false;
};
export default class App extends Component {
    static displayName = App.name;
    constructor(props) {
        super(props);
        var value = this.getQueryVariable('room');
        var documentid = this.getQueryVariable('documentid');

        this.state = {
            isDrawerOpen: true,
            currentTheme: wcuTheme,
            //marginVal: "59px",
            //bodyMarginVal: "300px",
            marginVal: "49px",
            bodyMarginVal: "329px",
            isCustomerMode: value != false ? true : false,
            crmEmbededView: this.getQueryVariable("crmEmbededView"),
            crmView:window.location.href.includes("/agentui")?true:false,
            refreshCacheAndReload: async () => {
                console.log(Date.now() + ' Cache Refresh-Clearing cache and hard reloading...')
                debugger;
                if (caches) {
                    console.log(Date.now() + ' Cache Refresh-' + caches);
                    console.log(caches);
                    const names = await caches.keys();
                    console.log(Date.now() + ' Cache Refresh-names:' + names);
                    await Promise.all(names.map(name => caches.delete(name)));
                    console.log(Date.now() + ' Cache Refresh-cache removed');
                    debugger;
                    await this.sleep(10000);
                    console.log(Date.now() + ' Cache Refresh-names after removed:' + names);
                    console.log(Date.now() + ' Cache Refresh-Reloading...');
                    //window.location.reload();
                    console.log(Date.now() + ' Cache Refresh-Reloaded');
                }

                // delete browser cache and hard reload
                // window.location.reload(true);
            }
        };
        this.handleDrawerOperation = this.handleDrawerOperation.bind(this);
        this.handleBodyContainerMargin = this.handleBodyContainerMargin.bind(this);
        this.sleep = this.sleep.bind(this);
    }

    handleDrawerOperation(value) {
        this.setState({ isDrawerOpen: value });
    }
    handleBodyContainerMargin(value, bodyMarVal) {
        this.setState({ marginVal: value, bodyMarginVal: bodyMarVal });
    }

    getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        console.log(query)//"app=article&act=news_content&aid=160990"
        // if(query.split("="))

        var vars = query.split("&");
        console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
            if (pair[0] == variable) { return pair[1]; }
        }
        return (false);
    }
    componentDidMount() {
        fetch("/meta.json", { cache: "no-store" })
            .then((response) => response.json())
            .then((meta) => {
                debugger;
                const latestVersion = meta.version;
                const currentVersion = global.appVersion;
                console.log('Cache Refresh-CurrentVersion: ' + currentVersion);
                console.log('Cache Refresh-LatestVersion: ' + latestVersion);
                const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
                if (shouldForceRefresh) {
                    console.log(`Cache Refresh-New Version Available- ${latestVersion}. Should force refresh`);
                    this.state.refreshCacheAndReload();
                } else {
                    console.log(`Cache Refresh-Already have the latest version - ${latestVersion}. No cache refresh needed.`);
                }
            });
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    RoutesComp=()=>{
      const bodyContainerMargin = this.state.isDrawerOpen
      ? this.state.bodyMarginVal
      : this.state.marginVal;
      const bodyContainerPaddingTop = this.state.crmEmbededView ? 0 : 68;
      return (
        <>
        <AuthenticatedTemplate>
                <AuthContextProvider crmView={this.state.crmView} context="ClickToMessage">
                  <Provider store={chatStore}>
                    <Header
                      handleDrawerOperation={this.handleDrawerOperation}
                      handleBodyContainerMargin={this.handleBodyContainerMargin}
                    />
                    <div

                      id="bodyContainer"
                      style={{ paddingTop: bodyContainerPaddingTop, marginLeft: bodyContainerMargin }}
                    >
                      <DefaultMessage />
                      <Routes>
                        {/* <Route path="/" element={<LandingPage />} /> */}
                        <Route path="/" element={<HomePage />} />
                        <Route path="/dashboard" element={<PowerBIReport />} />
                        <Route path="users" element={<UsersPage />} />
                        <Route path="addUpdateUser" element={<AddUpdateUser />} />
                        <Route path="addUpdateUser/:id" element={<AddUpdateUser />} />
                        <Route path="teams" element={<TeamsPage />} />
                        <Route path="addUpdateTeam" element={<AddUpdateTeam />} />
                        <Route path="addUpdateTeam/:id" element={<AddUpdateTeam />} />
                        <Route path="contacts" element={<ContactsPage />} />
                        <Route path="addUpdateContact" element={<AddUpdateContact />} />
                        <Route path="addUpdateContact/:id" element={<AddUpdateContact />} />
                        <Route
                          path="conversations"
                          element={<ConversationPage />}
                        />
                        <Route
                          path="conversations/:id"
                          element={<ConversationPage />}
                        />
                        <Route
                          path="supervisorChat/:id"
                          element={<ConversationPage />}
                        />
                        <Route
                          path="searchConversation"
                          element={<SearchConversation />}
                        />
                        <Route
                          path="omniChannel"
                          element={<OmniChannnel />}
                        />
                        <Route path="businessHoursConfig" element={<BusinessHoursConfig />} />
                        <Route path="addUpdateBusinessHoursConfig" element={<AddUpdateBusinessHoursConfig />} />
                        <Route path="addUpdateBusinessHoursConfig/:id" element={<AddUpdateBusinessHoursConfig />} />
                        <Route path="applicationSettings" element={<ApplicationSettingsPage />} />
                        <Route path="addUpdateSettings" element={<AddUpdateSettings />} />
                                  <Route path="chatConfigs/:id" element={<AddUpdateChatConfig />} />
                                  <Route path="chatConfigs" element={<ChatConfigPage />} />
                                  <Route path="addUpdatechatConfig" element={<AddUpdateChatConfig/>} />
                                  <Route path="addUpdatechatConfig/:id" element={<AddUpdateChatConfig />} />
                        <Route path="templates" element={<TemplatesPage />} />
                        <Route path="addUpdateTemplate" element={<AddUpdateTemplate />} />
                        <Route path="addUpdateTemplate/:id" element={<AddUpdateTemplate />} />

                    <Route path="surveyTemplate" element={<SurveyTemplatesPage />} /> {/* For list of all records */}
                    <Route path="addUpdateSurveyTemplate" element={<AddUpdateSurveyTemplate />} /> {/* for Add new record*/}
                    <Route path="addUpdateSurveyTemplate/:id" element={<AddUpdateSurveyTemplate />} /> {/* for update existing record */}
                    
                    <Route path="surveyQuestions" element={<SurveyQuestionsPage />} />
                    <Route path="addUpdateSurveyQuestions" element={<AddUpdateSurveyQuestion />} />
                    <Route path="addUpdateSurveyQuestions/:id" element={<AddUpdateSurveyQuestion />} />
                    
                        <Route path="communications" element={<Communications />} />
                        <Route path="addUpdateCommunications" element={<AddUpdateCommunications />} />
                        <Route path="addUpdateCommunications/:id" element={<AddUpdateCommunications />} />
                        <Route path="holidays" element={<Holidays />} />
                        <Route path="addUpdateHolidays" element={<AddUpdateHolidays />} />
                        <Route path="addUpdateHolidays/:id" element={<AddUpdateHolidays />} />
                        <Route path="phoneNumberOptOut" element={<PhoneNumberOptOut />} />
                        <Route path="addUpdateToken" element={<AddUpdateToken />} />
                        <Route path="addUpdateToken/:id" element={<AddUpdateToken />} />
                        <Route path="tokens" element={<TokensPage />} />
                        <Route path="UnAuthorized" element={<UnAuthorized />} />
                        <Route path="phoneNumbers" element={<PhoneNumbers />} />
                        <Route path="addUpdatePhoneNumber" element={<AddUpdatePhoneNumber />} />
                        <Route path="addUpdatePhoneNumber/:id" element={<AddUpdatePhoneNumber />} />
                        <Route path="tcpa" element={<TCPAPage />} />
                      </Routes>
                    </div>
                  </Provider>
                </AuthContextProvider>
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <HeaderUnAuth />
                <div className="App">
                  <h5 className="card-title">
                    You are not signed in! Please sign in.
                  </h5>
                </div>
              </UnauthenticatedTemplate>
              </>
      )
    }
    render() {
        return (
          <ThemeProvider theme={this.state.currentTheme}>
            {this.state.isCustomerMode == true ? (
              <div>
                <VideoApp></VideoApp>
              </div>
            ) : this.state.crmEmbededView || this.state.crmView ? (
              <MemoryRouter>
                <this.RoutesComp />
              </MemoryRouter>
            ) : (
              <BrowserRouter>
                <this.RoutesComp />
              </BrowserRouter>
            )}
          </ThemeProvider>
        );
    }
}
