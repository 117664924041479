import React, { Component, useState, useEffect } from 'react';
import apiConfig from "../../api/apiConfig";
import './UploadDoc.scss';
import { CircularProgress, Button, InputAdornment, Typography } from '@mui/material';
import UploadDocuments from './UploadDocuments'
import { Box, TextField, dir } from '@mui/material';
import { InvokePostServiceCallWithoutToken } from "../../api/serviceUtil";
import SnackBar from "../../components/SnackBar"

class UploadDocCustomerApp extends Component {
    constructor(props) {
        super(props)
        this.inputRef = React.createRef();
        this.state = {
            authKey: this.getQueryVariable('authKey'),
            documentRequestId: '',
            conversationId: '',
            customerPhoneNumber: '',
            isOTPGenrated: false,
            serviceResponseMsg: "",
            showInitLoader: false,
            isOTPVerified: false,
            otp: "",
            customerPhoneNumberErr: false,
            otpErr: false,
            documentTypes: [],
            token: "",
            openSnackbar: false,
            snackbarMsg: "",
            severity: "error",
            MaxSizeForOverAllUpload: 0,
            MaxSizeForEachDocument:0,
            isInvalidUrl: false,
            InvalidUrlErrMsg: "",
            showOTPGenerate: false,
            disabledGenOtpBtn: false,
            disabledVerifyOtpBtn: false,
            teamDocumentTypes: [],
            disabled: false,
            countdown: 30,

        }
        this.SendOtp = this.SendOtp.bind(this);
        this.verifyOtp = this.verifyOtp.bind(this);
        this.updateCustomerPhoneNumber = this.updateCustomerPhoneNumber.bind(this);
        this.onChangeHandle = this.onChangeHandle.bind(this);
        this.handleUploadDocModalClose = this.handleUploadDocModalClose.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
        this.getValidateUploadDocUrl = this.getValidateUploadDocUrl.bind(this);

    }
    componentDidMount() {
        this.getValidateUploadDocUrl();

    }
    getValidateUploadDocUrl = () => {
        this.setState({ ...this.state, showInitLoader: true });
        let requestBody =
        {
            authKey: this.state.authKey
        }

        InvokePostServiceCallWithoutToken(apiConfig.VALIDATE_DOCUMENT_URL, requestBody)
            .then((res) => {
                var data = res.data
                debugger;
                if (data.status === "FAILED") {
                    this.setState({
                        ...this.state,
                        InvalidUrlErrMsg: data.statusDescription,
                        isInvalidUrl: true,
                        showInitLoader: false,
                        showOTPGenerate: false
                    })
                } else {
                    this.setState({
                        ...this.state,
                        InvalidUrlErrMsg: "",
                        isInvalidUrl: false,
                        showInitLoader: false,
                        showOTPGenerate: true,
                        documentRequestId: data.documentRequestId
                    });
                }
            })
            .catch((err) => {
                this.setState({
                    ...this.state,
                    InvalidUrlErrMsg: err.response.data.title,
                    isInvalidUrl: true,
                    showInitLoader: false
                });
                console.log(err.response, "Error Message");
            });
    }

    async SendOtp() {
        debugger
        try {
            // let chkmobile = false;
            // const PHONE_REGEX = /^[+][0-9]{11}$/i
            // let PHONE_US = /^\([0-9]{3}\) |[0-9]{3}-[0-9]{3}-[0-9]{4}$/i;
            // const PHONE_INDIAN_REGEX = /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/i;
            // if (this.state.customerPhoneNumber?.startsWith("+91")) {
            //     if (PHONE_INDIAN_REGEX.test(this.state.customerPhoneNumber)) {
            //         chkmobile = true;
            //     }
            // }
            // else {
            //     if (PHONE_REGEX.test(this.state.customerPhoneNumber)) {
            //         chkmobile = true;
            //     } else if (PHONE_US.test(this.state.customerPhoneNumber)) {
            //         chkmobile = true;
            //     }
            // }

            // if (chkmobile) {
            if (this.state.customerPhoneNumber) {
                this.setState({ ...this.state, showInitLoader: true, disabledGenOtpBtn: true });
                let requestBody =
                {
                    authKey: this.state.authKey,
                    documentRequestId: this.state.documentRequestId,
                    PhoneNum: this.state.customerPhoneNumber
                }

                InvokePostServiceCallWithoutToken(apiConfig.GENERATE_OTP, requestBody)
                    .then((res) => {
                        debugger
                        console.log("sudam log ::", res)
                        var data = res.data
                        if (data.status === "FAILED") {
                            this.setState({
                                ...this.state,
                                snackbarMsg: data.statusDescription,
                                severity: "error",
                                openSnackbar: true,
                                showInitLoader: false,
                                disabledGenOtpBtn: false
                            })
                        } else {
                            this.setState({
                                ...this.state,
                                conversationId: data.conversationId,
                                isOTPGenrated: true,
                                showInitLoader: false,
                                disabledGenOtpBtn: false
                            });
                            this.setState({
                                disabled: true,
                            });
                            this.timer = setInterval(() => {
                                this.setState((prevState) => ({
                                    countdown: prevState.countdown - 1,
                                }), () => {
                                    if (this.state.countdown === 0) {
                                        clearInterval(this.timer);
                                        this.setState({
                                            disabled: false,
                                            countdown: 30,
                                        });
                                    }
                                });
                            }, 1000);
                        }
                    })
                    .catch((err) => {
                        this.setState({
                            ...this.state,
                            showInitLoader: false,
                            disabledGenOtpBtn: false
                        });
                        console.log(err, "Error Message");
                    });
            }
            else {
                this.setState({ ...this.state, customerPhoneNumberErr: true });
            }

        }
        finally {

        }

    }
    componentWillUnmount() {
        clearInterval(this.timer);
    }


    async verifyOtp() {
        debugger
        try {

            if (this.state.otp != "") {
                this.setState({ ...this.state, showInitLoader: true, disabledVerifyOtpBtn: true });
                let requestBody =
                {
                    documentRequestId: this.state.documentRequestId,
                    code: this.state.otp
                }

                InvokePostServiceCallWithoutToken(apiConfig.VALIDATE_OTP, requestBody)
                    .then((res) => {
                        console.log("sudam log ::", res)
                        if (res.data.status === "FAILED") {
                            this.setState({
                                ...this.state,
                                snackbarMsg: res.data.statusDescription,
                                severity: "error",
                                openSnackbar: true,
                                showInitLoader: false,
                                disabledVerifyOtpBtn: false
                            })
                        } else {
                            let teamDoc = res.data.teamSupportDocTypes ? res.data.teamSupportDocTypes?.split(',') : []
                            teamDoc = teamDoc?.filter(x => x)
                            this.setState({
                                ...this.state,
                                documentTypes: res.data.documentTypes,
                                teamDocumentTypes: teamDoc,
                                token: res.data.token,
                                MaxSizeForOverAllUpload: res.data.maxSizeForOverAllUpload,
                                MaxSizeForEachDocument: res.data.maxSizeForEachDocument,
                                isOTPGenrated: false,
                                isOTPVerified: true,
                                showInitLoader: false,
                                disabledVerifyOtpBtn: false
                            })
                        }

                    })
                    .catch((err) => {
                        this.setState({
                            ...this.state,
                            showInitLoader: false,
                            disabledVerifyOtpBtn: false
                        });
                        console.log(err, "Error Message");
                    });
            }
        }
        finally {

        }
    }
    async handleBack() {
        this.setState({ ...this.state, isOTPGenrated: false, otp: "" });
    }


    getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        console.log(query)//"app=article&act=news_content&aid=160990"
        var vars = query.split("&");
        console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
            if (pair[0] == variable) { return pair[1]; }
        }
        return (false);
    }


    updateCustomerPhoneNumber(event) {
        this.setState({
            ...this.state,
            customerPhoneNumber: event.target.value,

        });
    }
    onChangeHandle(event) {
        this.setState({
            ...this.state,
            otp: event.target.value,
        });
    }
    handleUploadDocModalClose(event) {
        this.setState({
            ...this.state,
            showUploadDoc: false,
        });

    }
    handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({ ...this.state, openSnackbar: false });
    };

    render() {
        const disabledPhone = this.state.customerPhoneNumber === '' ? true : false;
        const disabledOtp = this.state.otp === '' ? true : false;
        const { disabled, countdown } = this.state;
        return (
            <>
                <header id="header">
                    <div id="main-header" style={{ "width": "1348px;" }}>
                        <div className="header-wrapper small" style={{ "min-height": "83px" }}>
                            <div id="logo-container">
                                <div id="logo">
                                    <a href="https://ucla.wescom.org/" title="Wescom Home">
                                        <img aria-label="Wescom Credit Union Home Page" src="https://wescom.org/-/media/Marketing/Feature/PageContent/Base-Page/Header/Wescom-Logo.ashx" alt="Wescom Home" className="desktop" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                {this.state.isInvalidUrl &&
                    <Box sx={{ display: "flex", flexFlow: "column", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", flexFlow: "row", justifyContent: "flex-start" }}>
                            {this.state.InvalidUrlErrMsg && <h5 style={{ color: "red" }}>{this.state.InvalidUrlErrMsg}</h5>}
                        </Box>
                    </Box >}
                {this.state.showOTPGenerate &&
                    <div className="appUploadDocument">
                        <SnackBar
                            openSnackbar={this.state.openSnackbar}
                            handleCloseSnackbar={this.handleCloseSnackbar}
                            severity={this.state.severity}
                            userMessage={this.state.snackbarMsg}
                        />
                        {this.state.isOTPVerified == false ? (

                            <>
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <div>
                                        <div style={{ display: "flex" }}>
                                            <div><Typography>Phone Number</Typography></div>
                                        </div>
                                        <TextField

                                            className='userInput'
                                            size="small"
                                            defaultValue="Small"
                                            margin="normal"
                                            sx={{ input: { textAlign: "left" } }}
                                            error={this.state.customerPhoneNumberErr}
                                            value={this.state.customerPhoneNumber}
                                            label=""
                                            type="text"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: false,
                                            }}
                                            onChange={this.updateCustomerPhoneNumber}
                                            helperText={this.state.customerPhoneNumberErr ? "Invalid Phone Number" : ""}
                                        />
                                    </div>
                                </Box>
                                <br />
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <div>
                                        <div style={{ display: "flex" }}>
                                            <div> <Typography>Passcode</Typography></div>
                                            <div>{disabled && <div style={{ fontSize: "10px", textAlign: "right", marginTop: "6px", marginLeft: "132px" }}>Resend in {countdown} seconds</div>}</div>
                                        </div>
                                        <TextField

                                            className='userInput'
                                            size="small"
                                            defaultValue="Small"
                                            margin="dense"
                                            maxLength={6}
                                            sx={{ input: { textAlign: "left" } }}
                                            error={this.state.otpErr}
                                            value={this.state.otp}
                                            label=""
                                            type="text"
                                            variant="outlined"
                                            onChange={this.onChangeHandle}
                                            helperText={this.state.otpErr ? "Invalid Passcode" : ""}
                                            InputLabelProps={{
                                                shrink: false,
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Button sx={{ marginLeft: "25px", textalign: "right" }}
                                                            variant="text"
                                                            disabled={disabledPhone || this.state.disabledGenOtpBtn || disabled}
                                                            onClick={this.SendOtp}>Get Passcode</Button>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />

                                    </div>

                                </Box>

                                <Box sx={{ display: "flex", justifyContent: "center" }}>

                                    <Box><Button sx={{ margin: '10px 5px' }}
                                        variant="contained"
                                        color="primary" disabled={disabledOtp || this.state.disabledVerifyOtpBtn} onClick={this.verifyOtp}>Verify Passcode</Button></Box>
                                </Box>
                            </>
                        ) : (<div></div>)}






                        {this.state.serviceResponseMsg && <div style={{ textAlign: "center" }}>
                            <div style={{ marginTop: "5%" }}>
                                <h3>
                                    {this.state.serviceResponseMsg}
                                </h3>
                            </div>
                        </div>
                        }

                        {
                            this.state.isOTPVerified &&
                            <UploadDocuments
                                state={this.state}
                                handleUploadDocModalClose={this.handleUploadDocModalClose} />}

                    </div>}
                {
                    this.state.showInitLoader == true ?
                        <Box sx={{ display: 'flex', marginLeft: "50%", marginTop: "5%" }}>
                            <CircularProgress />
                        </Box> : <></>
                }
                <footer>
                    <div id="footer">
                        <div className="footer-wrapper">
                            <div className="footer-content">
                                <div className="footer-topbar">
                                    <a href="https://ucla.wescom.org/" rel="noopener noreferrer" title="Learn more about this partnership" target="_blank">
                                        <img src="https://wescom.org/-/media/Marketing/Feature/PageContent/Base-Page/Footer/Footer-Badges/Wescom-UCLA-Badge/Ucla_Wescom_logo.ashx?la=en&hash=F0BCC2136D728463EBF6C19EA526D1FB" alt="UCLA | Wescom" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

            </>
        );
    }

}

export default UploadDocCustomerApp;
