
import React, { Component, useContext, useEffect, useState }  from 'react';
import { AuthContext } from '../AuthContext';
export default function DefaultMessage() {
    const {
        userInfo,
        messageApiToken
        } = useContext(AuthContext);

    const[isAgentView,setIsAgentView]=useState(false);

    useEffect(()=>{
        teamschk();
        
    },[userInfo])

    const teamschk=()=>{
        if(!userInfo.teams || userInfo.teams.length==0)
        {
            if(userInfo.role=="Agent" || userInfo.role=="Supervisor")
            {
                setIsAgentView(true);
            }
        }
    };

    return (
        <>

            {isAgentView && (
                <p style={{ justifyContent: "center", display: "flex", backgroundColor: "#ddddd0", padding: "10px", color: "red" }}>You are not assigned to any team, Please contact your supervisor or administrator</p>
            )
            }
        </>
    );
}