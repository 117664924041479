////10/31/2022-> TEXTING-714 - passed team id from conversation object to load the Agents..
////05/16/2023->ONKAR=> TEXTING-1258 - Assign the Conversations across teams - Client Changes
////05/17/2023->ONKAR=> TEXTING-1258 - Assign the Conversations across teams - Client Changes - issues fixed
////05/18/2023->ONKAR=> TEXTING-1258 - Assign the Conversations across teams - Client Changes - issues fixed
////05/19/2023->ONKAR=> TEXTING-1275 - Assign accross teams observations. issue fixed.
////06/06/2023->SUDAM B CHAVAN=> TEXTING-1318 - Assign the conversation for same team user is not working properly for some teams.
////06/08/2023->SUDAM B CHAVAN => TEXTING-1320 - Select team dropdown is showing nonrelated team name for transfer and receive functionality disabled team.
////06/21/2023->SUDAM B CHAVAN => TEXTING-1348 - LOW PRIORITY: Able to transfer conversation from X team to Y Team, even if the conversation is in active with Y team
////09/06/2023->SUDAM B CHAVAN =>TEXTING-1523: Delay in loading and showing agents list when assigning a conversation
////29/06/2023->Prabhat =>Texting-1754 Admin user is not able to the assign the conversation from Search conversations and Omni channel that user not part of.
////10/04/2023->SUDAM B CHAVAN =>TEXTING-1776: Selected team users list is not showing when Team is disabled the 'Eligible to transfer the conversations'
////10/05/2023->SUDAM B CHAVAN =>TEXTING-1754 - Priority 1: Admin user is not able to the assign the conversation from Search conversations and Omni channel that user not part of.
import {
    Badge,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import React, { forwardRef, useImperativeHandle } from "react";
import apiConfig from "../api/apiConfig";
import { InvokePostServiceCall } from "../api/serviceUtil";
import { styled } from "@mui/material/styles";
import { dataService } from "../dataService";

export const AssignConversation = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        assignCoversationClick,
    }));

    const [eligibleToTransferConversation,setEligibleToTransferConversation]=React.useState(false);
    const [teams, setTeams] = React.useState([]);
    const [selectedTeam, setSelectedTeam] = React.useState("");

    const filterTeamsByTeamId = (teamId) => {
        if (props.state.appContext.userInfo.role === "Admin") {
            return props.state.appContext.userInfo.allTeams.filter((item) => item.teamId === teamId);
        } else {
            return props.state.appContext.userInfo.teams.filter((item) => item.teamId === teamId);
        }
    };
    const assignCoversationClick = () => {
        let conversationTeamId=props.conversation.teamId;
        let convTeam = filterTeamsByTeamId(conversationTeamId);
        if(convTeam[0].eligibleToTransferConversation){//check if team is eligible for transfer or not
            setSelectedTeam("");
            loadTeams();
            setEligibleToTransferConversation(true)
        }
        else{
            setEligibleToTransferConversation(false)
        }
        props.childState({ openAssignConv: !props.state.openAssignConv });
        setSelectedUser("");
        loadTeamAgents(props.state.conversation.teamId);
    };

    const [agents, setAgents] = React.useState([]);
    const [selectedUser, setSelectedUser] = React.useState();
    const [confirmAssignment, setConfirmAssignment] = React.useState(false);
    const [confirmAssignmentMsg, setConfirmAssignmentMsg] = React.useState("");

    const loadTeamAgents = (teamId) => {
        props.childState({ showLoader: true });
        // const convTeam = filterTeamsByTeamId(teamId);
        let msgReq = {
            teamId:teamId,//passing the team which ever passed to function.
            isActive: true,
            SortByName:true,
            SortByStatus:true,
            IsForAssignment:true
        };
        InvokePostServiceCall(apiConfig.GET_USERS, msgReq)
            .then((data) => {
                setAgents(data.data.userDetails);

                //setConvState(!convState);
                props.childState({ showLoader: false });
            })
            .catch((error) => {
                props.openMainSnackbar(error.message);
                props.childState({ showLoader: false });
                console.log(error.message);
            });
    };

    const handleChange = (event) => {
        setSelectedUser(event.target.value); //event.target.value.user.userId
    };

    const handleAssignConversation = (forceAssign) => (event) => {
        if (selectedUser != null) {
            props.childState({ showLoader: true });
            let msgReq = {
                conversation: {
                    conversationId: props.conversation.conversationId,
                    CustomerName: props.conversation.customerName,
                    CustomerPhoneNumber: props.conversation.customerPhoneNumber,
                    AssignedDate: new Date().toISOString(),
                    status: props.conversation.status,
                    PreAssigneeId: props.conversation.preAssigneeId,
                },
                teamId: eligibleToTransferConversation ? selectedTeam?.teamId : props.conversation.teamId,
                userId: selectedUser.user.userId,
                requestedUserId: props.state.appContext.userInfo.userId,
                forceAssign: forceAssign
            };

            InvokePostServiceCall(apiConfig.ASSIGN_CONVERSATION, msgReq)
                .then((data) => {

                    if (data.data.status.toLowerCase() === "success") {
                        props.onAssignConvSuccess(selectedUser.user.userId);
                    }
                    else if (data.data.status.toLowerCase() === 'failedtoassigncrossteam') {
                        dataService.setData({ showAlertMessage: data.data.statusDescription });
                    }
                    else if (data.data.status.toLowerCase() === "missedconvassigned") {
                        setConfirmAssignmentMsg(data.data.statusDescription);
                        setConfirmAssignment(true);
                    }
                    else if (props.conversation.status == 'InActive') {
                        props.onAssignConvSuccess(selectedUser.user.userId);
                    }                 
                    if (forceAssign) {
                        setConfirmAssignment(false);
                    }
                    props.childState({ showLoader: false, openAssignConv: false });
                    props.handleModalClose();

                })
                .catch((error) => {

                    props.openMainSnackbar(error.message);
                    props.childState({ showLoader: false, openAssignConv: false });
                    props.handleModalClose();
                    console.log(error.message);
                });
        }
    };

    const handleConfirmAssignClose = () => {
        setConfirmAssignment(false);
        setConfirmAssignmentMsg("");
    };



    ///Assign conversation with teams logic

    const loadTeams = () => { //load the teams which is eligible to transfer the conversation
        props.childState({ showLoader: true });
        
        InvokePostServiceCall(apiConfig.GET_ASSIGN_CONVERSATION_ELIGIBLE_TEAMS, {})
            .then((data) => {
                let teamDetails=data.data.teamDetails;
                let tempArr=[];
                let convTeam= teamDetails.filter((item)=>item.teamId===props.state.conversation.teamId);
                let convTeamDetails = filterTeamsByTeamId(props.state.conversation.teamId);//get team filtered from function by passing teamId
                    if(convTeam.length<1 && teamDetails.length>0){
                        tempArr.push({
                            teamName: convTeamDetails[0].teamName,
                            teamId: convTeamDetails[0].teamId,
                          });

                    }
                    setSelectedTeam({teamName: convTeamDetails[0].teamName,
                        teamId: convTeamDetails[0].teamId})
                setTeams([...teamDetails,...tempArr]);
                props.childState({ showLoader: false });
            })
            .catch((error) => {
                props.openMainSnackbar(error.message);
                props.childState({ showLoader: false });
                console.log(error.message);
            });
    };

    const handleChangeTeam = (event) => {
        setSelectedTeam(event.target.value); 
        loadTeamAgents(event.target.value.teamId)
        setSelectedUser("");
    };

    return (
        <>
            <Dialog
                open={props.state.openAssignConv}
                onClose={props.handleModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={props.dialogFullWidth}
            >
                <DialogContent>
               {eligibleToTransferConversation&&
                <FormControl fullWidth margin="dense">
                        <InputLabel id="demo-simple-select-label">Teams</InputLabel>
                        <Select
                            labelId="select-team"
                            id="select-team"
                            label="Teams"
                            value={selectedTeam}
                            onChange={handleChangeTeam}
                            disabled={teams.length<1}
                            renderValue={(selected) => selected.teamName}
                        >
                            {teams?.map((a) => {
                                return (
                                    <MenuItem key={a.teamId} value={a}>
                                        <div>
                                            <div>
                                                <span style={{ marginLeft: "10px" }}>
                                                    {a.teamName}
                                                </span>
                                            </div>
                                        </div>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>}
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="demo-simple-select-label">Agents</InputLabel>
                        <Select
                            disabled={(eligibleToTransferConversation && selectedTeam==="" && teams.length>0) ||props.state.showLoader}
                            labelId="select-agent"
                            id="select-agent"
                            label="Agents"
                            onChange={handleChange}
                            value={selectedUser}
                            renderValue={(selected) => selected.user.userName}
                        >
                            {agents?.map((a) => {
                                let badgeColor = "#b8bfb3";
                                if (a.user.status === "Available" && a.user.connectionStatus === true) {
                                    badgeColor = "#44b700"
                                } else if (a.user.status === "Busy" && a.user.connectionStatus === true) {
                                    badgeColor = "red"
                                } else {
                                    badgeColor = "#b8bfb3"
                                }
                                return (
                                    <MenuItem key={a.user.userId} value={a}>
                                        <div>
                                            <div>
                                                <div
                                                    style={{
                                                        position: "relative",
                                                        width: "10px",
                                                        height: "10px",
                                                        borderRadius: "6px",
                                                        backgroundColor: badgeColor,
                                                        display: "inline-block",
                                                    }}
                                                ></div>
                                                <span style={{ marginLeft: "10px" }}>
                                                    {a.user.userName}
                                                </span>
                                            </div>
                                            <div>
                                                <span
                                                    style={{
                                                        marginLeft: "20px",
                                                        fontSize: "13px",
                                                        color: "#8080809e",
                                                        position: "relative",
                                                        bottom: "5px",
                                                    }}
                                                >{`${a.activeConversationCount} Active Conversations`}</span>
                                            </div>
                                        </div>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <DialogContentText>
                        {props.state.showLoader && (
                            <Box sx={{ display: "flex", marginLeft: "40%" }}>
                                <CircularProgress />
                            </Box>
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button 
                    disabled={!selectedUser}
                    onClick={handleAssignConversation(false)}>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <span>
                                <strong> Assign</strong>
                            </span>
                        </div>
                    </Button>
                    <Button onClick={props.handleModalClose}>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <span>
                                <strong> Cancel</strong>
                            </span>
                        </div>
                    </Button>
                </DialogActions>
            </Dialog>
            {confirmAssignment && (
                <Dialog
                    open={confirmAssignment}
                    onClose={handleConfirmAssignClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={props.dialogFullWidth}
                >
                    <DialogTitle>Alert</DialogTitle>
                    <Divider />
                    <DialogContent>
                        <div style={{ display: "flex", padding: "5px" }}>
                            <Typography gutterBottom>{confirmAssignmentMsg}</Typography>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleAssignConversation(true)}>
                            <div style={{ display: "flex" }}>
                                <span>
                                    <strong> Assign </strong>
                                </span>
                            </div>
                        </Button>
                        <Button onClick={handleConfirmAssignClose}>
                            <div style={{ display: "flex" }}>
                                <span>
                                    <strong> Cancel</strong>
                                </span>
                            </div>
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
});