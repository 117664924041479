/***************
*03/17/2023 =>SUDAM B CHAVAN => TEXTING - 1092 - Show upload document page as mobile card view..
*09/08/2023 =>ONKAR => TEXTING - 1690 - Exists in Prod: SMS - Document type not supported error popup is showing error icon only.
***********/
import React, { useContext, useState, useEffect } from "react";
import {
    Box,
    Select,
    FormControl,
    MenuItem,
    Button,
    TextField,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    CircularProgress,
    DialogTitle,
    Divider,
    Stack,
    Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { InvokePostServiceCallWithoutToken, InvokePostServiceCall } from "../../api/serviceUtil";
import apiConfig from "../../api/apiConfig";
import { AuthContext } from "../../AuthContext";
import { DropzoneArea, DropzoneAreaBase } from 'mui-file-dropzone';
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import LoadingButton from '@mui/lab/LoadingButton';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import Backdrop from '@mui/material/Backdrop';
import ResetTvIcon from '@mui/icons-material/ResetTv';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import SnackBar from "../../components/SnackBar"
import DoneIcon from '@mui/icons-material/Done';
import CardView from './CardView';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';


const fileExtensionRegex = /(?:\.([^.]+))?$/;
function UploadDocuments(props) {
    const theme = useTheme();
    const { userInfo, selectedConv, crmMode } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [docName, setDocName] = useState("");
    const [uploadedFilesCount, setUploadedFilesCount] = useState(0);
    const [uploadedDocuments, setUploadedDocuments] = useState([]);
    const [openConfirmationDialog, SetOpenConfirmationDialog] = useState({
        open: false,
        title: "",
        mode: "",
        id: 0,
    });
    const [docTypes, setDocTypes] = useState(props.state.documentTypes);
    const [isMobileView, setIsMobileView] = useState(window.screen.width < 600);
    // const [docTypes, setDocTypes] = useState(
    //     [{ docName: "W-2 Forms" }, { docName: "Income Verification" }]
    // );
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [severity, setSeverity] = useState("error");

    const [selectUploadId, setSelectUploadId] = useState(0);
    const [totalDocSize, setTotalDocSize] = useState(0);
    const [showControls, setShowControls] = useState(true);
    const [disabledControls, setDisabledControls] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");
    const [openLoader, setOpenLoader] = React.useState({
        open: false,
        mode: "",
    });
    const [showDragAndDropCtr, setShowDragAndDropCtr] = useState(false);

    useEffect(() => {
        var tempUploadDocs = [];
        debugger
        docTypes.forEach((type, index) => {
            var uplDoc = {
                id: index + 1,
                type: type.docName,
                name: "",
                size: "",
                document: "",
                status: "",
                mediaType:"",
            }
            tempUploadDocs.push(uplDoc)
            if (index === docTypes.length - 1) {
                setUploadedDocuments(tempUploadDocs)
            }
        })
    }, [])
    useEffect(() => {
         //added to get total uploaded document size when ever uploadedDocuments array got updated
        getUploadedDocSize();
    }, [uploadedDocuments])
    let resizeWindow = () => {
        setIsMobileView(window.screen.width < 600 );
    };

    useEffect(() => { // added to show effect when screen gets resize
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);//remove event listener on component unmount
    }, []);
    async function getBase64(file, index, ismulti) {
        debugger
        let tempArr = uploadedDocuments;
        var reader = new FileReader();
        const temp = file;
        const tempIsmulti = ismulti;
        reader.readAsDataURL(file);
        reader.onload = await function () {
            debugger
            let fileData = reader.result.split(",");
            debugger
            tempArr[index].name = temp.name;
            tempArr[index].mediaType = temp.type;
            tempArr[index].size = (temp.size / (1024 * 1024)).toFixed(2);
            if (tempIsmulti) {
                tempArr[index].type = "Others";
            }
            tempArr[index].document = fileData[1];;
            setUploadedDocuments([...tempArr]);
            getUploadedDocSize();


            // return reader.result;
            return
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    }
    const handleFileChange = async function (event) {
        setOpenLoader({ open: true, mode: "upload" })
        const { files, name } = event.target;
        const rowId = name.split('_')[1];
        // let tempArr = uploadedDocuments;
        if (rowId && files.length > 0) {
            let file = files[0];
            let fileSize = (file.size / (1024 * 1024)).toFixed(2);
            if (Number(fileSize) > Number(props.state.MaxSizeForEachDocument))
            {
                setSeverity("error")
                setSnackbarMsg("File size should not be greater than " + props.state.MaxSizeForEachDocument +"mb.")
                setOpenSnackbar(true);
                setOpenLoader({ open: false })
                event.target.value = "";
                return;

            }
            if (file.type.match('image/*|image.*|application/pdf|application/msword|application/vnd.openxmlformats-officedocument.wordprocessingml.document')){
                await getBase64(files[0], (rowId - 1));
                setOpenLoader({ open: false })
            }
            else{
                setSeverity("error")
                setSnackbarMsg("Invalid file type selected. (Supported type:PDF,Word,Images")
                setOpenSnackbar(true);
                setOpenLoader({ open: false })
                event.target.value = "";
            }
        }
    };
    const handleMultiFileChange = async function (files) {
        if (files.length > uploadedDocuments.length) {
            setSeverity("error")
            setSnackbarMsg("Selected files should not more then rows.")
            setOpenSnackbar(true);
            return;

        }
        setOpenLoader({ open: true, mode: "upload" })
        let tempArr = uploadedDocuments;
        await files.forEach(async (item, index) => {
            let fileData = item.data.split(",");
            const temp = item.file;
            tempArr[index].name = temp.name;
            tempArr[index].size = (temp.size / (1024 * 1024)).toFixed(2);
            tempArr[index].type = "Others";
            tempArr[index].document = fileData[1];
            tempArr[index].mediaType = temp.type;         
        })
        setUploadedDocuments([...tempArr]);
        getUploadedDocSize();
        setOpenLoader({ open: false })

    };
    const handleAddRow = (event) => {
        debugger
        let tempArr = uploadedDocuments;
        let maxId = tempArr?.length > 0 ? (tempArr[tempArr.length - 1].id) + 1 : 1;
        let uplDocobj = {
            id: maxId,
            type: "Others",
            name: "",
            size: "",
            document: "",
            status: "",
            mediaType:"",
        }
        setUploadedDocuments([...tempArr, uplDocobj]);
    };
    const handleConfirmationDialog = () => {
        setOpenLoader({ open: true, mode: "action" })
        if (openConfirmationDialog?.mode === "clear") {
            let tempArr = uploadedDocuments;
            let docObj = uploadedDocuments.filter(x => x.id === openConfirmationDialog?.id)[0];
            docObj.document = "";
            docObj.name = "";
            docObj.size = "";
            docObj.status = "";
            docObj.mediaType = "";
            setUploadedDocuments(uploadedDocuments);
        }
        else if (openConfirmationDialog?.mode === "delete") {
            let tempArr = uploadedDocuments.filter(x => x.id !== openConfirmationDialog?.id);
            tempArr.forEach((item, index) => {
                item.id = index + 1;
            })
            setUploadedDocuments(tempArr);
        }
        SetOpenConfirmationDialog({ open: false })
        setOpenLoader({ open: false })
        getUploadedDocSize();
    }
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };
    const getUploadedDocSize = () => {
        let totalSize = uploadedDocuments.reduce((n, { size }) => Number(n) + Number(size), 0).toFixed(2);
        setTotalDocSize(totalSize);
    }
    const handleSubmit = () => {
        if (Number(totalDocSize) > Number(props.state.MaxSizeForOverAllUpload)) {
            setSeverity("error")
            setSnackbarMsg("Total files size should be less then " + props.state.MaxSizeForOverAllUpload +"MB")
            setOpenSnackbar(true);
            return;
        }
        const validation = uploadedDocuments.every(item => item.name && item.document && item.size);
        if (!validation) {
            setSeverity("error")
            setSnackbarMsg("Please upload document for all rows")
            setOpenSnackbar(true);
            return;
        }
        if (uploadedDocuments.some(x => x.status !== "Completed")) {
            //to make disable controls there should be 1 record with no Completed status
            //this will occure when we have partially uploaded document(from 2 doc 1 got failed)
            setDisabledControls(true);
        }
        uploadedDocuments.map((a,index)=>{ if(a.status !== "Completed"){ a.status = "uploading"}});
        setUploadedDocuments(uploadedDocuments);
        // setOpenLoader({ open: true, mode: "submit" })
        debugger

        uploadedDocuments.forEach(async (doc, index) => {
            if (doc.status !== "Completed"){
            await uploadDocument(doc, index)}
        });
    }
    const uploadDocument =  (doc, fileIndex) => {
        var requestBody = {
            authKey: props.state.authKey,
            conversationId: props.state.conversationId,
            documentRequestId: props.state.documentRequestId,
            Source: "MemberPortal",
            // conversationId: "77c8f90f-f382-4166-822d-2572c2e43f92",
            // docuemtRequestId: "b16d59c0-524c-4fba-9aaf-ae2308b1b343",

            documentRecord: {
                DocumentType: doc.type,
                FileExtension: fileExtensionRegex.exec(doc.name)[0],
                BaseDocument: doc.document,
                DocumentName:doc.name,
                MediaType: doc.mediaType,
            }
        }

        // let token = "eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJiMTZkNTljMC01MjRjLTRmYmEtOWFhZi1hZTIzMDhiMWIzNDMiLCJleHAiOjE2NzcwNjkyOTUsImlzcyI6IkFQSS53ZXNjb20ub3JnIiwiYXVkIjoiTWVzc2FnaW5nQVBJLndlc2NvbS5vcmcifQ.AJEs-TByeUAJrnmuXF5MRFe3Bse0z87G5K2y1s9VK0o"
        InvokePostServiceCall(apiConfig.UPLOAD_DOCUMENTS, requestBody, props.state.token)
       // InvokePostServiceCall(apiConfig.UPLOAD_DOCUMENTS, requestBody, token)
            .then((res) => {
                 //setUploadedFilesCount(fileIndex + 1);
                if (res.data.status === "SUCCESS") {
                    let docObj = uploadedDocuments.filter(a => a.id === doc.id)[0];
                    docObj.status = "Completed"
                    setUploadedDocuments(uploadedDocuments);
                    if (!uploadedDocuments.some(x => x.status !== "Completed")) {
                        //when all files are uploaded successfully then this block will execute
                        setShowControls(false);
                        setSeverity("success");
                        setSnackbarMsg("Documents are uploaded successfuly, you can close this window");
                        setSuccessMsg("Documents are uploaded successfully, you can close this window");
                        setOpenSnackbar(true);
                    }
                }
                else {
                    setSeverity("error")
                    setSnackbarMsg(res.data.statusDescription)
                    setOpenSnackbar(true);
                    setDisabledControls(false);
                    let docObj = uploadedDocuments.filter(a => a.id === doc.id)[0];
                    docObj.status = "Failed"
                    setUploadedDocuments(uploadedDocuments);

                }
            })
            .catch((err) => {
                setSeverity("error")
                setSnackbarMsg("something went wrong please try again")
                setOpenSnackbar(true);
                setDisabledControls(false);
                let docObj = uploadedDocuments.filter(a => a.id === doc.id)[0];
                docObj.status = "Failed"
                setUploadedDocuments(uploadedDocuments);
                console.log(err, "Error Message");
            });
    }
    const columns = [
        {
            field: "id",
            headerName: " Sr. No.",
            flex: 1,
            minWidth: 5,
            width: 5,
            headerClassName: "super-app-theme--header",
            sortable: false,
        },
        {
            headerName: "Document Types",
            field: "_",
            flex: 1,
            minWidth: 200,
            headerClassName: "super-app-theme--header",
            sortable: false,
            renderCell: (params) => {
                return (
                    <Box sx={{ ...styles.textRow, marginTop: 2.5 }}>
                        <TextField
                            size="small"
                            select
                            defaultValue={params.row.type}
                            value={params.row.type}
                            fullWidth
                            id="docType"
                            onChange={(e) => {
                                let tempArr = uploadedDocuments;
                                tempArr[params.row.id - 1].type = e.target.value;
                                setUploadedDocuments([...tempArr]);
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="standard"
                        >
                            {props.state.teamDocumentTypes?.map((item, index) => {
                                return (
                                    <MenuItem value={item}>
                                        {item}
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                    </Box>

                );
            },
        },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            minWidth: 300,
            headerClassName: "super-app-theme--header",
            sortable: false,
        },
        {
            field: "size",
            headerName: "Size(MB)",
            flex: 1,
            minWidth: 100,
            headerClassName: "super-app-theme--header",
            sortable: false,
        },
        {
            headerName: "Actions",
            flex: 1,
            minWidth: 50,
            headerClassName: "super-app-theme--header",
            sortable: false,
            renderCell: (params) => {
                return (
                    <>
                        {
                            params.row.status === "Completed"
                                ? <span style={{ marginLeft: "5px" }} title="Document uploaded" >
                                    <DoneIcon />
                                </span>
                                : params.row.status === "uploading"
                                    ? <span style={{ marginLeft: "5px" }} title="Uploading document" >
                                        <CircularProgress size={15} />
                                    </span>
                                    : <>
                                        {params.row.document ?
                                        <>
                                                {params.row.status === "Failed"
                                                && <span style={{ marginLeft: "5px" }} title="Failed to upload document" >
                                                    <ErrorOutlineIcon color={"error"} size={15} />
                                                </span>}
                                            <IconButton
                                                title="Clear file"
                                                onClick={(e) => {
                                                    SetOpenConfirmationDialog(
                                                        {
                                                            open: true,
                                                            title: "Are you sure to clear uploaded file?",
                                                            mode: "clear",
                                                            id: params.row.id
                                                        }
                                                    )

                                                }}
                                            >
                                                <ClearIcon />
                                            </IconButton></> : <IconButton
                                                title="Upload file"
                                                component="label"
                                            >
                                                <OpenInBrowserIcon />
                                                <input hidden accept="image/*,.pdf,.doc,.docx" type="file" name={params.row.name + "_" + params.row.id} onChange={handleFileChange} />
                                            </IconButton>}

                                        <IconButton
                                            title="Delete row"
                                            onClick={(e) => {

                                                SetOpenConfirmationDialog(
                                                    {
                                                        open: true,
                                                        title: "Are you sure to delete row?",
                                                        mode: "delete",
                                                        id: params.row.id
                                                    }
                                                )

                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </>
                        }
                    </>
                );
            },
        },
    ];

    return (
        <>
            <SnackBar
                openSnackbar={openSnackbar}
                handleCloseSnackbar={handleCloseSnackbar}
                severity={severity}
                userMessage={snackbarMsg}
            />
            <Box sx={{
                height:  isMobileView? 'auto' : uploadedDocuments?.length > 0 ? uploadedDocuments?.length  < 5 ? 'auto' : 350 : 150,
                width: "100%",
                paddingLeft: "10px",
                paddingRight: "10px",
                "& .super-app-theme--header": {
                    backgroundColor: "#034F84;",
                },
            }}>
                {isMobileView ?
                    <Box sx={{
                        width: "100%",
                        "& .super-app-theme--header": {
                            backgroundColor: "#034F84;",
                        },
                    }}>
                       
                            <CardView 
                             teamDocumentTypes = {props.state.teamDocumentTypes}
                                uploadedDocuments={uploadedDocuments}
                                setUploadedDocuments={setUploadedDocuments}
                                handleFileChange={handleFileChange}
                                SetOpenConfirmationDialog={SetOpenConfirmationDialog}/>
                       
                       
                    </Box> :
                    <DataGrid
                        components={{
                            NoRowsOverlay: CustomNoRowsOverlay,
                        }}
                        disableColumnMenu
                        rows={uploadedDocuments}
                        columns={columns}
                        hideFooter={true}
                        autoHeight={uploadedDocuments?.length > 0 && uploadedDocuments?.length < 5 ? true : false}
                    />}
            </Box>
            <Box sx={{ display: "flex", flexFlow: "column", justifyContent: "center", alignItems: "center" }}>
                <Box sx={{ display: "flex", flexFlow: "row", justifyContent: "flex-start", textAlign:'center' }}>
                    <span>*Note: can upload 5 documents at one request with size of {props.state.MaxSizeForOverAllUpload}MB. (Size of uploaded documents : {totalDocSize}/{props.state.MaxSizeForOverAllUpload} MB)</span>
                </Box>
                {showControls &&
                    <Box sx={{ display: "flex", flexFlow: "row", justifyContent: "flex-end" }}>
                        <Button onClick={handleAddRow}
                            sx={{ margin: '10px' }}
                            variant="contained"
                            color="primary"
                            disabled={disabledControls ? true: uploadedDocuments.length === 5}
                        >
                            <strong> Add Row</strong>
                        </Button>
                        <Button onClick={handleSubmit}
                            sx={{ margin: '10px' }}
                            autoFocus
                            variant="contained"
                            color="primary"
                            disabled={disabledControls ? true : uploadedDocuments?.length === 0}>

                            <strong> Submit</strong>
                        </Button>
                    </Box>}
                <Box sx={{ display: "flex", flexFlow: "row", justifyContent: "flex-start", textAlign: 'center' }}>
                    {successMsg && <h5 style={{ color: "green" }}>{successMsg}</h5>}
                </Box>
            </Box>
           
            {(uploadedDocuments?.length > 0 && showControls && showDragAndDropCtr) &&
                <DropzoneAreaBase
                    onAdd={handleMultiFileChange}
                    showAlerts={["error"]}
                    filesLimit={5}
                    acceptedFiles={['image/*', '.pdf', '.doc', '.docx']}
                />}
            <Box>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={openLoader.open}
                >
                    <Stack gap={1} justifyContent="center" alignItems="center">
                        <CircularProgress color="inherit" />
                        <Typography>{openLoader.mode === "submit" &&
                            <span>Uploaded files - {uploadedFilesCount}/{uploadedDocuments.length}</span>
                        }</Typography>
                    </Stack>
                </Backdrop>
            </Box>
            <Dialog
                open={openConfirmationDialog?.open}
                // onClose={props.handleDocuSignModalClose}
                fullWidth={false}
                maxWidth={"xs"}
            >
                <DialogTitle>{openConfirmationDialog?.title}</DialogTitle>
                <Divider />
                <DialogActions>
                    <Button
                        onClick={handleConfirmationDialog}
                    >
                        Ok
                    </Button>
                    <Button
                        onClick={() => {
                            SetOpenConfirmationDialog({
                                open: false
                            })
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>

            </Dialog>


            {/* notepad++ 10 */}
        </>
    );
}
let styles = {
    textRow: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 1.5,
        "& h2": {
            paddingLeft: "0",
        },
    },
    mrT: {
        marginTop: "10px",
    },
    bottomButton: {
        marginBottom: "20px",
    },
};
export default UploadDocuments;
