/********************************************************************************************************************************************
Name: RequestAssistance
=============================================================================================================================================
Purpose:
RequestAssistance component will display in agent active conversation page and supervisor conversation page.
Key funtionality Includes:
1. Agent can chat with supervisors internally.
2. Supervisors can chat with agents internally.

History:
VERSION     AUTHOR              DATE            DETAILS
1.0         ONKAR               01/15/2024      Initial creation of RequestAssistance component.
1.2         ONKAR               01/17/2024      Request Assistance : UI-improvements and bug fixes.
1.3         ONKAR               01/18/2024      Texting-1936 Request Assistance : UI-improvements and bug fixes.
                                                2.If input message is big in request assistance, then popup also increasing along with message sent and Vertical scroll bar is showing
                                                7. Supervisor users who is not part of active conversation team also showing on Request assistance supervisors drop down.
                                                10. Send button in request assistance can be disabled until entering the data in input message box.
1.4         ONKAR               01/25/2024      Texting-1936 Request Assistance : UI-improvements and bug fixes.
                                                2.Supervisor name is disappearing for Supervisor messages after request assistance is ended. 
                                                3.Supervisors order is not showing on Status and alphabetical order in request assistance supervisors dropdown
                                                7.Auto suggestions are showing on Input message box in request assistance popup
                                                8.After selecting the supervisor - Multiple click on send button in request assistance is sending duplicate messages.  

*/
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  IconButton,
  Popover,
  TextField,
  InputAdornment,
  Divider,
  Button,
} from "@mui/material";
import apiConfig from "../api/apiConfig";
import { InvokePostServiceCall } from "../api/serviceUtil";
import { useTheme } from "@mui/material/styles";
import HelpIcon from "@mui/icons-material/Help";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch } from "react-redux";
import { sendMessageSupervisor } from "../api/messageServices";
import {
  conversationsSelector,
} from "../storage/slices/messageSlice";
import { useSelector } from "react-redux";
import { AuthContext } from "../AuthContext";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

function RequestAssistance(props) {
  let { conversation } = props;
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  let isSupervisorChat = location?.pathname.includes("supervisorChat")
    ? true
    : false;
  const dispatch = useDispatch();
  const messagesEndRef=useRef();
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = React.useState();
  const [reqAssistantPopover, setReqAssistantPopover] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const openReqAssistantPopover = Boolean(reqAssistantPopover);
  const reqAssistantPopoverId = openReqAssistantPopover
    ? "request-assistant-popover"
    : undefined;

  const handleClosePopover = () => setReqAssistantPopover(null);
  const handleChange = (event) => setSelectedUser(event.target.value); //event.target.value.user.userId
  const {
    supervisorSelectConversation,
    selectConversation,
  } = useSelector(conversationsSelector);
  const { userInfo } = useContext(AuthContext);
  console.log(selectConversation, "supervisorCopnversations");
  console.log(
    supervisorSelectConversation.supervisorMessageList,
    "supervisorCopnversations"
  );

  // Function to get users by role
  async function getUsersByRole() {
    try {
      // Prepare payload with role, active status, and teamId from the conversation
      let payload = {
        Role: "Supervisor",
        IsActive: true,
        SortByName:true,
        SortByStatus:true,
        TeamId:isSupervisorChat?supervisorSelectConversation.teamId:selectConversation.teamId,
      };

      // Invoke a POST service call to retrieve users by role
      let result = await InvokePostServiceCall(
        apiConfig.GET_USERS_BY_ROLE,
        payload
      );

      // Update the user list with the retrieved user details
      setUserList(result.data.userDetails);
    } catch (error) {
      // Log any errors that occur during the API call
      console.log(error, "getUsersByRole Error");
    }
  }

  // Function to send an internal message
  function sendInternalMessage() {
    // Retrieve the message from the state
    let message = newMessage;
    setNewMessage("")
    // Check if the message is not null and not an empty string
    if (message !== null && message !== "") {
      // Check if the trimmed message has a length greater than 0
      if (message.trim().length > 0) {
        // Determine the recipient and message type based on chat type
        const recipientId = isSupervisorChat ? conversation.agentId : selectedUser?.user?.userId?selectedUser.user.userId:selectConversation.assistedBy;
        const messageType = isSupervisorChat ? "INBOUND" : "OUTBOUND";

        // Dispatch an action to send the internal message
        dispatch(
          sendMessageSupervisor(
            recipientId,
            conversation,
            message.trim(),
            messageType
          )
        );

        // Clear the newMessage state
        setNewMessage("");
      }
    }
  }

  // Async function to start a request assistance conversation
  async function startRequestAssistanceConv() {
    try {
      // Prepare payload with user ID, conversation ID, and message
      let payload = {
        UserId: selectedUser.user.userId,
        ConversationId: conversation.conversationId,
        Message: newMessage,
      };
      setNewMessage("")
      // Invoke a POST service call to start the request assistance conversation
      let result = await InvokePostServiceCall(
        apiConfig.START_REQUEST_ASSISTANT,
        payload
      );
      console.log(result);
      // Clear the newMessage state
      setNewMessage("")
    } catch (error) {
      // Log any errors that occur during the service call
      console.log(error);
    }
  }

  // Async function to end a request assistance conversation
  async function endRequestAssistanceConv() {
    try {
      let payload={};

      // Check if it's a supervisor chat
      if(isSupervisorChat){
        // Prepare payload for supervisor chat
        payload = {
          UserId: supervisorSelectConversation.agentId,
          ConversationId: supervisorSelectConversation.conversationId,
        };
      }else{
        // Prepare payload for agent chat
        payload = {
          UserId: selectedUser?.user?.userId?selectedUser.user.userId:selectConversation.assistedBy,
          ConversationId: selectConversation.conversationId,
        };
      }
      // Invoke a POST service call to end the request assistance conversation
      let result = await InvokePostServiceCall(
        apiConfig.END_REQUEST_ASSISTANT,
        payload
      );

      // Navigate to the home page
      navigate("/");
      console.log(result);
    } catch (error) {
      // Log any errors that occur during the service call
      console.log(error);
    }
  }

  const scrollToBottom = () => {
    if(!!messagesEndRef && !!messagesEndRef.current){
    messagesEndRef.current.scrollIntoView();
    }
  };

  useEffect(()=>{
    scrollToBottom();
  },[selectConversation.supervisorMessageList,supervisorSelectConversation.supervisorMessageList])

  useEffect(() => {
    if(openReqAssistantPopover){
    getUsersByRole();
    }
  }, [openReqAssistantPopover]);
  return (
    <>
      <IconButton
        aria-label="Insert Template"
        onClick={(e) => {
          setReqAssistantPopover(e.currentTarget);
        }}
        edge="end"
        className="wcu_iconButton"
        title="Request Assitance"
      >
        <HelpIcon sx={theme.customStyle.messangerIcons} color="primary" />
      </IconButton>

      <Popover
        id={reqAssistantPopoverId}
        open={openReqAssistantPopover}
        anchorEl={reqAssistantPopover}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      //   PaperProps={{
      //     elevation: 0,
      //     sx: {
      //         overflow: 'visible',
      //         filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
      //         '& .MuiAvatar-root': {
      //             width: 32,
      //             height: 32,
      //             ml: -0.5,
      //             mr: 1,
      //         },
      //         '&:before': {
      //             content: '""',
      //             display: 'block',
      //             position: 'absolute',
      //             bottom: "-10px",
      //             left: "14px",
      //             width: 10,
      //             height: 10,
      //             bgcolor: '#f0f8ff',
      //             transform: 'translateY(-50%) rotate(45deg)',
      //             zIndex: 0,
      //         },
      //     },
      // }}
      >
        <div
          style={{
            width: "320px",
            minHeight: "300px",
            position: "relative",
            padding: "5px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h5>
              <strong>Request Assistance</strong>
            </h5>
            {(isSupervisorChat || selectConversation.assistanceRequest) && (
              <Button
                variant="contained"
                size="small"
                sx={{
                  backgroundColor: "#d32f2f",
                  borderRadius: "10px",
                  marginBottom: "5px",
                  "&:hover": {
                    backgroundColor: "#a52e2e",
                  },
                }}
                onClick={() => {
                  endRequestAssistanceConv();
                }}
              >
                End
              </Button>
            )}
          </div>
          <Divider />
          {!isSupervisorChat && !selectConversation.assistanceRequest &&(
            <FormControl fullWidth margin="dense">
              <InputLabel id="demo-simple-select-label">Supervisors</InputLabel>
              <Select
                labelId="select-agent"
                id="select-agent"
                label="Supervisors"
                onChange={handleChange}
                disabled={selectConversation.assistanceRequest}
                value={selectedUser}
                renderValue={(selected) => selected.user.userName}
              >
                {userList?.map((item) => {
                  let badgeColor = "#b8bfb3";
                  if (
                    item.user.status === "Available" &&
                    item.user.connectionStatus === true
                  ) {
                    badgeColor = "#44b700";
                  } else if (
                    item.user.status === "Busy" &&
                    item.user.connectionStatus === true
                  ) {
                    badgeColor = "red";
                  } else {
                    badgeColor = "#b8bfb3";
                  }
                  return (
                    <MenuItem key={item.user.userId} value={item}>
                      <div>
                        <div>
                          <div
                            style={{
                              position: "relative",
                              width: "10px",
                              height: "10px",
                              borderRadius: "6px",
                              backgroundColor: badgeColor,
                              display: "inline-block",
                            }}
                          ></div>
                          <span style={{ marginLeft: "10px" }}>
                            {item.user.userName}
                          </span>
                        </div>
                      </div>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
          <div
            style={{
              minHeight: "190px",
              maxHeight: "30vh",
              overflow: "auto",
              marginTop: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                minHeight: "190px",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              {(isSupervisorChat
                ? supervisorSelectConversation
                : selectConversation
              ).supervisorMessageList?.map((item) => {
                let rightMsg = item.fromId === userInfo.userId ? true : false;
                let rightName=userInfo.userName;
                let leftName=isSupervisorChat?supervisorSelectConversation.agentName
                :selectedUser?.user?.userName?selectedUser.user.userName:selectConversation.assistedByName;
                return (
                  <>
                    {item.messages.map((msg) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: rightMsg
                              ? "flex-end"
                              : "flex-start",
                            margin:rightMsg?"0 0 0 10%":"0 10% 0 0"
                          }}
                        >
                          <div
                            style={{
                              ...styles.messageBubble,
                              ...(rightMsg
                                ? styles.messageBubbleRight
                                : styles.messageBubbleLeft),
                            }}
                          >
                            <Typography
                              sx={
                                rightMsg
                                  ? styles.agentNameFontSize
                                  : styles.agentNameFontColor
                              }
                            >
                              {item.fromIdName || msg.fromIdName}
                            </Typography>
                            <Typography sx={styles.agentNameFontSize}>
                              {" "}
                              {msg.messageBody}
                            </Typography>
                            <Typography sx={styles.messageDate}>
                              {" "}
                              {moment(msg.messageDate).format("LT")}
                            </Typography>
                          </div>
                        </div>
                      );
                    })}
                  </>
                );
              })}
            </div>
            <div ref={messagesEndRef} />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingTop: "10px",
            }}
          >
            <TextField
              variant="outlined"
              label="Input Message"
              sx={{ width: "100%" }}
              disabled={!isSupervisorChat && !selectConversation.assistanceRequest && !selectedUser?.user.userId}
              value={newMessage}
              autoComplete="off"
              onChange={(e) => {
                setNewMessage(e.target.value);
              }}
            />
            <div>
              <InputAdornment
                style={{ marginRight: "15px", fontWeight: "bold" }}
              >
                <IconButton
                  disabled={!newMessage}
                  aria-label="toggle password visibility"
                  onClick={() => {
                    if (
                      isSupervisorChat ||
                      selectConversation.assistanceRequest
                    ) {
                      sendInternalMessage();
                    } else {
                      startRequestAssistanceConv();
                    }
                  }}
                  edge="end"
                >
                  <SendIcon
                    sx={theme.customStyle.messangerIcons}
                    color={!newMessage?"disabled":"primary"}
                  />
                </IconButton>
              </InputAdornment>
            </div>
          </div>
        </div>
      </Popover>
    </>
  );
}

const styles = {
  messageBubble: {
    borderRadius: "14px",
    minHeight: "40px",
    minWidth: "100px",
    padding: "10px",
    fontFamily: "Roboto,Helvetica,Arial,sans-serif",
    marginBottom: "5px",
    fontSize: "12px",
    wordBreak:"break-word"
  },
  agentNameFontSize: {
    fontSize: "12px",
  },
  agentNameFontColor: {
    color: "#6191c4",
    fontSize: "12px",
  },
  messageDate: {
    fontSize: "11px",
    display: "flex",
    justifyContent: "flex-end",
  },
  messageBubbleRight: {
    backgroundColor: "#001950",
    color: "white",
  },
  messageBubbleLeft: {
    backgroundColor: "#e9e9e9",
    color: "black",
    borderLeft: "4px solid #005088",
  },
};
export default RequestAssistance;
