////10-25-2023 => ONKAR =>  TEXTING-1799 - Click on pub sub reconnect button is not refreshing the conversations count on Texting UI.
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  hasErrors: false, 
  unAssignedNotifList: []
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {    
      loadAssignmentNotifications: (state, { payload }) => {
          if (payload?.length > 0) {
              state.unAssignedNotifList = payload;
          }
      },
      removeNotificationfromList: (state, { payload }) => {
          let notificationState = state.unAssignedNotifList;
          let index = notificationState.findIndex(
              (x) => x.conversationId === payload
          );
          if (index > -1) {
              notificationState.splice(index, 1);
              state.unAssignedNotifList = notificationState;
          }
      },
      addNotificationToList: (state, { payload }) => {
          let notificationState = state.unAssignedNotifList;
         let present=state.unAssignedNotifList.some((item)=>item.conversationId===payload.conversationId)
        if(!present){
            notificationState.push(payload);
        }
          state.unAssignedNotifList = notificationState;
      },
      resetUnAssignedNotifList:(state,{payload})=>{
        state.unAssignedNotifList=[]
      }
  },
});

export const notificationSelector = (state) => state.notifications;
export const {
    loadAssignmentNotifications, addNotificationToList, removeNotificationfromList,resetUnAssignedNotifList
} = notificationsSlice.actions;
export default notificationsSlice.reducer;
