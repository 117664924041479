////11-09-2022 => Texting-775 Added Conversations tab in Omni channel to load the active conversations for the selected team in filter. (this is component for conversation tab)
// 15-09-2022 => Texting-802 Added filter for the conversations table
// 05-08-2023 => SUDAM B CHAVAN => TEXTING-1250 - Authentication indicator should be added to conversation page in Omni channel and Search conversations
// 05-15-2023 => SUDAM B CHAVAN => TEXTING-1251 - Conversation attributes is missing in info of webchat conversation in Omnichannel page.
// 07-25-2023 => ONKAR => TEXTING-1444 - Omnichannel Conversations UI should show Sentiment on the card.
// 08-29-2023 => ONKAR => TEXTING-1578 -REACT Upgrade 
// 10-04-2023 => ONKAR => TEXTING-1769 -Icons in Teams Conversation.
// 10-06-2023 => ONKAR => TEXTING-1786 -If 'Sentiment Calculation' is disabled then also History records is showing Sentiment emoji.
// 10-06-2023 => ONKAR => TEXTING-1787 -Hyphen(-) should be removed from table header in Team conversations icon coloumn.
// 10-12-2023 => ONKAR =>  TEXTING-1796 - Sentiment Re-Calculate button to calculate sentiment with latest messages.
// 10-16-2023 => ONKAR =>  TEXTING-1796 - Sentiment Re-Calculate button to calculate sentiment with latest messages.
////10-19-2023 => ONKAR =>TEXTING-1815 -Number 0 is showing on omni channel, History tab instead of Neutral emoji If overall sentiment of conversation is '0'.
////10-26-2023 => SUDAM B CHAVAN => TEXTING-1788 - Admin, Supervisor users not part of any Team opening the Omni channel Team conversations is showing error popup.
////12-20-2023 => ONKAR =>TEXTING-1924 -Member Initials for Customer messages avatar is not showing in conversation opened from Omni channel, History tab, Search messages.
import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Checkbox,
IconButton,
Tooltip
} from "@mui/material";
import AssignmentReturnOutlinedIcon from "@mui/icons-material/AssignmentReturnOutlined";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme, experimentalStyled as styled } from "@mui/material/styles";
import moment from "moment";
import SnackBar from "./SnackBar";
import { InvokePostServiceCall } from "../api/serviceUtil";
import apiConfig from "../api/apiConfig";
import { AuthContext } from "../AuthContext";
import { mapConversation } from "../extensions/Util";
import SearchConvChat from "./SearchConvChat";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
import {
  AssignConvFromTeamConversation,
} from "../components/AssignConvFromTeamConversation";
import { StyledDataGrid } from "../components/styledComponents/StyledDataGrid";
import SentimentEmoji from "./SentimentEmoji";
import TaskAltTwoToneIcon from '@mui/icons-material/TaskAltTwoTone';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ChatIcon from '@mui/icons-material/Chat';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';


function OmniChannelConversationsTable(props) {
  const theme = useTheme();
  const appContext = useContext(AuthContext);
  const { userInfo,enableSentimentCalculation } = useContext(AuthContext);
  const {
    messageList,
    chatLoading,
    filterData,
    getMesseges,
    onAssignConvSuccess,
    setSeacrhClicked,
    seacrhClicked,
    keyDeleted
  } = props;
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [conversationList, setConversationList] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState("");
  const [showConvDialog, setShowConvDialog] = useState(false);
  const [selectedConv, setSelectedConv] = useState("");
  const [showAssignConv, setshowAssignConv] = useState(false);
  const [state, setState] = useState({
    userId: userInfo.userId,
    conversation: selectedConversation,
    inputMessage: null,
    open: false,
    templateOptions: [],
    filteredTemplateOptions: [],
    templatePreviewText: null,
    showLoader: false,
    appContext: appContext,
    disableSend: false,
    openSnackBar: false,
    showInfo: false,
    showDocuSign: false,
    openAssignConv: false,
    showVideoChat: false,
    anchorEl: null,
    anchorElPopover: null,
    mentionText: "",
    selectedMention: 0,
    popperX: 0,
    popperY: 0,
    openTokenSnackbar: false,
    openMainSnackbar: false,
    snackbarMsg: "",
    codeVal: "",
    textInputHeight: 46,
    isIndividual:false,
  });
  const dayInMonthComparator = (v1, v2) => {  
    if (!v1) v1 = new Date(null);
    if (!v2) v2 = new Date(null);
    if (v1 && v2) {
      var date1 = moment(v1);
      var date2 = moment(v2);
      var diff = date1.diff(date2, 'seconds')
      return diff
    }
  }
  const columns = [
    {
      field: "checkBox",
      headerName: " ",
      flex: 1,
      minWidth: 50,
      filterable: false,
      align: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Checkbox onClick={(e) => {
            if (e.target.checked) {
              setState({ ...state, conversation: params.row })
              setSelectedConv(params.row);
            }
            else {
              setState({ ...state, conversation: null })
              setSelectedConv(null);
            }
          }}
            checked={state?.conversation?.conversationId === params.id}
            disabled={params.row?.teamType === "Individual"}
            value={params.id} />
        )
      }
    },
    {
      field: "sentimentIcon",
      headerName: " ",
      flex: 1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <>
          {params.row?.channel === 'Chat' ?
          (<ChatIcon color="primary" sx={{marginTop:"2px"}}  />)
          : (<PhoneIphoneIcon color="primary" />)}
          {enableSentimentCalculation === "Y" && (params.row?.overallSentiment || params.row?.overallSentiment===0 ) && <SentimentEmoji overallSentiment={params.row?.overallSentiment}/>}
          {params.row?.showNotification && 
          <Tooltip title="There are Un-Read Messages in the conversation">
          <AnnouncementOutlinedIcon color='warning' />
          </Tooltip>
          }
          </>
        )
      }
    },
    {
      field: "agentName",
      headerName: "Agent",
      flex: 1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "customerName",
      headerName: "Contact Name",
      flex: 1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
      renderCell:(params)=>{
        return(
          <>
          {params.row?.customerName}
           {(params.row?.channel === 'Chat' && params.row?.authenticationStatus === 'Authenticated')
              && 
              <Tooltip title="Authenticated">
               <TaskAltTwoToneIcon fontSize="small" color="success" sx={{ marginLeft: "3px" }} />
              </Tooltip>
            } 
          </>
        )
      }
    },
    {
      field: "customerPhoneNumber",
      headerName: "Contact Phone",
      flex: 1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "createdDate",
      headerName: "Conversation Date",
      flex: 1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
      type: "date",
      sortComparator: dayInMonthComparator,
      valueGetter: (params) => {
            return new Date(params.value);
        }
    },
    {
      field: "recentCustomerRespondedDate",
      headerName: "Customer Response Date",
      flex: 1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
      type: "date",
      sortComparator: dayInMonthComparator,
      
    },
    {
      field: "recentAgentRespondedDate",
      headerName: "Agent Response Date",
      flex: 1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
      type: "date",
      sortComparator: dayInMonthComparator,
    },
    {
      field: "teamName",
      headerName: "Team",
      flex: 1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
    },
  ];
  useEffect(() => {
    getActiveConersions();
  }, []);

  useEffect(() => {
    if (seacrhClicked || keyDeleted) {
      getActiveConersions();
    }

  }, [seacrhClicked, keyDeleted, filterData]);

  const getActiveConersions = () => {
    setSeacrhClicked(false)
    setLoading(true);
    setConversationList([]);
    var teamId = filterData?.teamId ? filterData.teamId : userInfo?.teamId;
    if (teamId) {
      InvokePostServiceCall(apiConfig.GET_ACTIVE_CONVERSATIONS_BY_USER_ID, {
        teamId: teamId
    })
        .then((data) => {
          let tempArr = [];
          let conversations = data.data.conversations;
          for (var i = 0; i < conversations.length; i++) {
            let tempObj = mapConversation(
              conversations[i],
              conversations[i].teamPhoneNumber
            );
            tempObj.id = conversations[i].conversationId;
            tempObj.createdDate = moment(conversations[i].createdDate).local().format("MM-DD-YYYY") + ", " + moment(conversations[i].createdDate).local().format("LT")
            tempObj.recentCustomerRespondedDate = conversations[i].recentCustomerRespondedDate ? moment(conversations[i].recentCustomerRespondedDate).local().format("MM-DD-YYYY") + ", " + moment(conversations[i].recentCustomerRespondedDate).local().format("LT") : "";
            tempObj.recentAgentRespondedDate = conversations[i].recentAgentRespondedDate ? moment(conversations[i].recentAgentRespondedDate).local().format("MM-DD-YYYY") + ", " + moment(conversations[i].recentAgentRespondedDate).local().format("LT") : "";
            tempArr.push(tempObj);
          }

          if (filterData && (filterData.name || filterData.contactName || filterData.contactPhone)) {
            {/* filtering the conversion data according to filter  */ }
            { /** if filter having data */ }
            setConversationList(tempArr.filter(data => {
              return (
                (filterData.name && filterData.name.length > 0 ? data.agentName && data.agentName.toLowerCase().includes(filterData.name.toLowerCase()) : data)
                &&
                (filterData.contactName && filterData.contactName.length > 0 ? data.customerName && data.customerName.toLowerCase().includes(filterData.contactName.toLowerCase()) : data)
                &&
                (filterData.contactPhone && filterData.contactPhone.length > 0 ? data.customerPhoneNumber && data.customerPhoneNumber.includes(filterData.contactPhone) : data)
              )
            }))
          }
          else setConversationList(tempArr)
          setLoading(false);
        })
        .catch((error) => {
          console.log(error, "Error Message");
          setSnackbarMsg(error.message);
          setOpenSnackbar(true);
          setLoading(false);
        });
    }
    else{
      console.log("TeamId is not available");
      setLoading(false);
    }
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  const handleCloseConvDialog = () => {
    if (selectedConversation) {
      //while closing the conversation dialog check if sentiment is refreshed or not and update the value in table.
      let teamConvListDetails = [...conversationList];
      for (let i = 0; i < teamConvListDetails.length; i++) {
            if (//match the conversation id if selected conversation with convertsation list from table.
              teamConvListDetails[i].conversationId ===
                selectedConversation?.conversationId &&
              teamConvListDetails[i].overallSentiment !==
                selectedConversation?.overallSentiment
            ) {
              teamConvListDetails[i].overallSentiment = selectedConversation?.overallSentiment;//update the overallSentiment with latest value.
              setConversationList([...teamConvListDetails]);
              break;
            }
      }
    }
    setShowConvDialog(false);
  };
  const handleAssignConvModalClose = () => {
    setshowAssignConv(false);
  };
  const openMainSnackbar = (msg) => {
    setSnackbarMsg(msg);
      setOpenSnackbar(true);
  }
  return (
      
    <>
      <SnackBar
        openSnackbar={openSnackbar}
        handleCloseSnackbar={handleCloseSnackbar}
        severity={"error"}
        userMessage={snackbarMsg}
      />
      {!state.isIndividual &&
      <Box sx={{ display:"flex", justifyContent:"flex-end",marginRight:"10px"}}>
      <IconButton
        aria-label="Assign Conversation"
        onClick={() => setshowAssignConv(true)}
        edge="end"
        className="wcu_iconButton"
        title="Assign Conversation"
          disabled={!selectedConv}
      >
                <AssignmentReturnOutlinedIcon sx={theme.customStyle.messangerIcons} color = {selectedConv ?"primary":""} />
      </IconButton>
      </Box>}
      <Box sx={{ ...theme.customStyle.tableHead, marginTop: "10px" }}>  
        <StyledDataGrid
          loading={loading}
          rows={conversationList}
          columns={columns}
          pageSize={10}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          rowsPerPageOptions={[10]}
          onRowDoubleClick={(e) => {
            let tempConv={...e.row}
            // Check if customerName exists and is not "Unknown" 
             if (tempConv?.customerName && tempConv?.customerName !="Unknown") {
             // Split the CustomerName into an array of words
                let nameArray = tempConv?.customerName?.split(" ");
                // Check if there is at least one word in the nameArray
                //// Assign the first character of the first word to customerNameInitials
                if (nameArray.length > 0) {
                    tempConv.customerNameInitials = nameArray[0].charAt(0);
                }
                if (nameArray.length > 1){
                    tempConv.customerNameInitials += "" + nameArray[1].charAt(0);
                }
             }             
            setSelectedConversation(tempConv);
            getMesseges(e.row);
            setShowConvDialog(true);
          }}
        />
      </Box>
      <SearchConvChat
        chatLoading={chatLoading}
        msgList={messageList}
        selectedConv={
          selectedConversation && selectedConversation.conversationId
        }
        showConvDialog={showConvDialog}
        setShowConvDialog={setShowConvDialog}
        convDetails={{
          isReadOnly: true,
          teamId: selectedConversation.teamId,
          conversationId:
            selectedConversation && selectedConversation.conversationId,
          customerName:
            selectedConversation && selectedConversation.customerName,
          customerPhoneNumber:
            selectedConversation && selectedConversation.customerPhoneNumber,
          createdDate: selectedConversation && selectedConversation.createdDate,
          createdBy: selectedConversation && selectedConversation.createdBy,
          source: selectedConversation && selectedConversation.source,
          agentName: selectedConversation && selectedConversation.agentName,
          teamName: selectedConversation && selectedConversation.teamName,
          userPhoneNumber: selectedConversation && selectedConversation.userPhoneNumber,
          agentId: selectedConversation && selectedConversation.agentId,
          channel: selectedConversation && selectedConversation.channel,
          authenticationStatus: selectedConversation && selectedConversation.authenticationStatus,
          authId: selectedConversation && selectedConversation.authId,
          conversationAttributes: selectedConversation && selectedConversation.conversationAttributes,
          email: selectedConversation && selectedConversation.email,
          overallSentiment: selectedConversation && selectedConversation.overallSentiment,
          customerNameInitials: selectedConversation && selectedConversation.customerNameInitials
        }}
        appContext={appContext}
        onAssignConvSuccess={onAssignConvSuccess}
        handleClose={handleCloseConvDialog}
        setConvDetails={setSelectedConversation}
      />
      {showAssignConv &&
      <AssignConvFromTeamConversation
        conversation={selectedConv}
        onAssignConvSuccess={onAssignConvSuccess}
        state={state}
        handleModalClose={handleAssignConvModalClose}
        dialogFullWidth={"md"}
        openMainSnackbar={openMainSnackbar}
      />}
    </>
  );
}

export default OmniChannelConversationsTable;
